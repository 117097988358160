import React from "react";
interface Props {
  title: string;
  sub?: string;
}
export default function Title({ title, sub }: Props) {
  return (
    <>
      <div className="das-info-title">
        <p>{title}</p>
        <span>{sub}</span>
      </div>
    </>
  );
}
