import Swal from "sweetalert2";
//@ts-ignore
import axios from "../../lib/axios.js";

export function filterData(
  data: any,
  key: string,
  setData: (data: any) => void
) {
  const filterCallback = () => {
    if (Array.isArray(data)) {
      const result = data.filter((item: any) => {
        return Object.values(item).some(
          (value) => typeof value === "string" && value.includes(key)
        );
      });
      setData(result);
    } else {
      console.error("Error: Provided data is not an array.");
    }
  };

  filterCallback();
}

export function inputChnage(
  key: string,
  rawData: any,
  setData: (data: any) => void,
  setKeyword: (key: string) => void
) {
  setKeyword(key);
  filterData(rawData, key, setData);
}

export function DeleteFun(link: string, param: object, navi: any) {
  Swal.fire({
    title: "삭제하시겠습니까?",
    showCancelButton: true,
    confirmButtonText: "확인",
    cancelButtonText: "취소",
  }).then((result) => {
    if (result.isConfirmed) {
      axios.post(link, param).then((res: any) => {
        console.log("res", res);
        if (res.status === 200) {
          Swal.fire({
            title: "삭제되었습니다.",
          }).then((result) => {
            window.history.back();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "실패하였습니다.",
          });
        }
      });
    }
  });
}

export function SubmitFun(navi: any) {
  navi("/Mng/Agent");
}

export function translate(kr: string, en: string, language: string) {
  return language === "KOR" ? kr : en;
}
