import PropTypes from "prop-types";

export default function Button({ buttonName, colorBTN, clickFun }) {
  return (
    <button
      type="button"
      className={`${colorBTN} common-btn undefined`}
      onClick={clickFun}
    >
      <span>{buttonName}</span>
    </button>
  );
}

Button.propTypes = {
  buttonName: PropTypes.string.isRequired,
  colorBTN: PropTypes.string,
  clickFun: PropTypes.func,
};
