import React from "react";
import { useMap } from "../../../context/MapContext";
import { useEffect } from "react";
import { useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
export default function MapTypeBtn() {
  const { language } = useContext(LanguageContext);
  const { naver } = window;
  const { mapState, mapDispatch } = useMap();
  const { map, satelliteMode } = mapState;
  //위성 지도
  const handleMapType = (e) => {
    mapDispatch({
      type: "SET_SATELLITE_MODE",
      payload: e.target.value === "NORMAL" ? false : true,
    });
  };
  useEffect(() => {
    map?.setMapTypeId(
      naver.maps.MapTypeId[satelliteMode ? "HYBRID" : "NORMAL"]
    );
  }, [satelliteMode, map, naver]);
  return (
    <div className="btn-group">
      <label className="group-item">
        <input
          type="radio"
          name="map-type"
          value="NORMAL"
          defaultChecked="checked"
          onChange={handleMapType}
        />
        <div className="control-btn">
          <i className="ri-map-2-line icon"></i>
          <div className="label">{language === "KOR" ? "일반" : "Map"}</div>
        </div>
      </label>
      <label className="group-item">
        <input
          type="radio"
          name="map-type"
          value="SATELLITE"
          onChange={handleMapType}
        />
        <div className="control-btn">
          <i className="ri-radar-line icon"></i>
          <div className="label">{language === "KOR" ? "위성" : "Sat."}</div>
        </div>
      </label>
    </div>
  );
}
