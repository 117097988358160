import React, { useEffect, useState } from "react";

interface RadioProps {
  setCount: (e: number[]) => void; // Update type of setCount to accept number array
  count: number;
  key: number;
  idx: string;
}

export default function Radio({ idx, setCount, count }: RadioProps) {
  const [box, setBox] = useState<any>({});
  const arrayBox = [];
  const idxNumber = parseInt(idx);
  for (let i = 1; i <= idxNumber; i++) {
    arrayBox.push(i);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedIdx = parseInt(e.target.value); // Convert string value to number
    const newCount = Array.from({ length: selectedIdx }, (_, i) => i + 1); // Generate array of numbers from 1 to selectedIdx
    setCount(newCount);
  };
  useEffect(() => {
    switch (arrayBox.length) {
      case 0:
        break;

      case 1:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-1'
              'box-1 box-1 '
            `,
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr 1fr",
        });
        break;
      case 2:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-2'
              'box-1 box-2 '
            `,
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr 1fr",
        });
        break;
      case 3:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-3'
              'box-2 box-3 '
            `,
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr 1fr",
        });
        break;
      case 4:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-2 '
              'box-3 box-4 '
            `,
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr 1fr",
        });
        break;
      case 5:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-2 box-3'
              'box-4 box-5 box-3'
            `,
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateRows: "1fr 1fr",
        });
        break;
      case 6:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-2 box-3'
              'box-4 box-5 box-6'
            `,
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateRows: "1fr 1fr",
        });
        break;
      case 8:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-2 box-3 box-4'
              'box-5 box-6 box-7 box-8'
            `,
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gridTemplateRows: "1fr 1fr",
        });
        break;
      default:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'top-left top-right'
              'bottom bottom'
            `,
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gridTemplateRows: "1fr 1fr  ",
        });
        break;
    }
  }, []);
  return (
    <>
      <label
        className="map-modal-select-body-row-item"
        style={box}
        htmlFor={idx}
      >
        {arrayBox.map((item, idx) => (
          <div
            key={idx}
            className="layout-map-box"
            style={{ gridArea: `box-${item}` }}
          ></div>
        ))}
        <input
          type="radio"
          name="box-layout"
          id={idx}
          value={idx}
          onChange={handleChange}
        />
      </label>
    </>
  );
}
