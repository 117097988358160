import React from "react";
interface Props {
  language: string;
  searchHandler: (e: any) => void;
  searchValue: string;
}
export default function Search({
  language,
  searchHandler,
  searchValue,
}: Props) {
  return (
    <>
      <form className="search-bar" onSubmit={(e) => e.preventDefault()}>
        <div className="custom-input">
          <input
            type="text"
            placeholder="사용자명, 사용자명, 회사코드, 사용자가 속한 소속명"
            onChange={(e) => searchHandler(e.target.value)}
            value={searchValue}
          />
        </div>
        {/* <button
          onClick={(e) => {
            searchHandler(e.target);
          }}
          type="button"
          className="submit-btn"
        >
          {language === "KOR" ? "검색" : "Search"}
        </button> */}
      </form>
    </>
  );
}
