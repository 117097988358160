import PropTypes from "prop-types";
import Title from "../atom/Title";
import TextArea from "../atom/TextArea";

export default function FormRowTextArea({
  title,
  id,
  value,
  inputName,
  changeFun,
}) {
  return (
    <>
      <div className="form-row">
        <Title title={title} />
        <TextArea
          id={id}
          value={value}
          inputName={inputName}
          changeFun={changeFun}
        />
      </div>
    </>
  );
}

FormRowTextArea.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  changeFun: PropTypes.func,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  inputName: PropTypes.string,
};
