import React, { useEffect, useRef, useState } from "react";
import RecordItem from "./RecordItem";
import { useMap } from "../../context/MapContext";
import { useLang } from "../../context/LanguageContext";

export default function RecordList({
    record,
    selecData,
    inviewref,
    totalCount,
    isLoading,
    scrollRef,
}) {
    const { language } = useLang();
    const { mapState, mapDispatch } = useMap();
    const { selectItem, selectPathItem, pathMode } = mapState;

    const [isDetail, setIsDetail] = useState({ state: false, idx: "" }); // pahtmarkderdetail에서는 row가 없기 때문에 idx로 변경

    const style = { center: { textAlign: "center" } };
    function openDetail(idx) {
        const ele = document.querySelector(`div [data-num="${idx}"]`);
        if(ele) {
            if(!ele.className.includes('on')) {
                ele.scrollIntoView({block:'center'});
                setIsDetail({ state: true, idx: idx });
            }
        } else {
            setIsDetail({ state: false, idx: idx });
        }
    }
    function closeDetail() {
        mapDispatch({ type: "BLUR" });
        // mapDispatch({ type: "SET_SELECT_PATH_ITEM", payload: null });
        setIsDetail({ state: false, idx: "" });
    }

    useEffect(() => {
        setIsDetail({ state: false, idx: "" });
    }, [selectItem]);

    useEffect(() => {
        if (selectPathItem) {
            openDetail(selectPathItem.idx);
        } else {
            setIsDetail({ state: false, idx: "" });
        }
    }, [record, selectPathItem]);
    
    return (
        <div className="track-list record-list">
            <ul className="list-body">
                {record &&
                    record.map((item) => (
                        <RecordItem
                            item={item}
                            selecData={selecData}
                            total={totalCount}
                            state={isDetail}
                            close={closeDetail}
                        />
                    ))}
                {isLoading && (
                    <div style={style.center}>
                        {language === "KOR" ? "로딩중입니다..." : "Loading..."}
                    </div>
                )}
                {!isLoading && (
                    <>
                        <div ref={inviewref} style={style.center}>
                            {record?.length === 0
                                ? language === "KOR"
                                ? "데이터가 없습니다."
                                    : "No Data."
                                : ""}
                        </div>
                        <div ref={scrollRef}></div>
                    </>
                )}
            </ul>
        </div>
    );
}
