import moment from "moment";
import React, { useEffect } from "react";
import axios from "../../../lib/axios.js";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../../../context/LanguageContext.jsx";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../../context/LoginContext";
import { useDaumPostcodePopup } from "react-daum-postcode";
import Header from "../../include/AppHeader";
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import CommonControl from "../../Atomic/organism/CommonControl";
import Email from "../../Atomic/atoms/Email.jsx";
import TelInput from "../../Atomic/atoms/Tel_Input";
import InputFilter from "../../Atomic/organism/InputFilter.jsx";
import { dataList } from "./ManagerMain.jsx";
export default function AgentAdd() {
  const navi = useNavigate();
  const location = useLocation();
  const { language } = React.useContext(LanguageContext);
  const postRef = React.useRef();
  const mainAddrRef = React.useRef();
  const subAddrRef = React.useRef();
  const { user } = useAuth();
  const open = useDaumPostcodePopup(
    "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );
  // State definition
  //==================================================================
  const [idx, setIdx] = React.useState("");
  const [branchList, setBranchList] = React.useState([]);
  const [CPN, setCPN] = React.useState("");
  const [isHead] = React.useState(true);
  const [rawData, setRawData] = React.useState({
    idx: idx,
    cmp_nm: `${user.nm}`,
    cmp_cd: `${user.org_cd}`,
    org_adm_nm: "",
    hp: "",
    postnum: "",
    addr: "",
    addr_dt: "",
    tel: "",
    bizno: "",
    email: "",
    fax: "",
    makedate: "",
    org_nm: "",
    brc_nm: "",
    editdate: "",
    user_idx: `${user.idx}`,
    type: `${user.type}`,
    org_idx: `${user.org_idx}`,
  });
  // Function definition
  //==================================================================
  function inputHandler(e) {
    const { name, value } = e.target;
    setRawData((prev) => ({ ...prev, [name]: value }));
  }
  const hpHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp = "";
    switch (name) {
      case "hp1":
        temp = rawData.hp.split("-");
        temp[0] = value;
        if (temp[0] === "000") {
          alert("0000은 입력할 수 없습니다.");
          temp[0] = "";
        }
        setRawData((prev) => ({ ...prev, hp: temp.join("-") }));
        break;
      case "hp2":
        temp = rawData.hp.split("-");
        temp[1] = value;
        if (temp[1] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[1] = "";
        }
        setRawData((prev) => ({ ...prev, hp: temp.join("-") }));
        break;
      case "hp3":
        temp = rawData.hp.split("-");
        temp[2] = value;
        if (temp[2] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[2] = "";
        }
        setRawData((prev) => ({ ...prev, hp: temp.join("-") }));
        break;
      default:
        break;
    }
  };
  const TelHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp = "";
    switch (name) {
      case "tel1":
        temp = rawData.tel.split("-");
        temp[0] = value;
        if (temp[0] === "000") {
          alert("0000은 입력할 수 없습니다.");
          temp[1] = "";
        }
        setRawData((prev) => ({ ...prev, tel: temp.join("-") }));
        break;
      case "tel2":
        temp = rawData.tel.split("-");
        temp[1] = value;
        if (temp[1] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[1] = "";
        }
        setRawData((prev) => ({ ...prev, tel: temp.join("-") }));
        break;
      case "tel3":
        temp = rawData.tel.split("-");
        temp[2] = value;
        if (temp[2] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[2] = "";
        }
        setRawData((prev) => ({ ...prev, tel: temp.join("-") }));
        break;
      default:
        break;
    }
  };
  const faxHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp = "";
    switch (name) {
      case "fax1":
        temp = rawData.fax.split("-");
        temp[0] = value;
        setRawData((prev) => ({ ...prev, fax: temp.join("-") }));
        break;
      case "fax2":
        temp = rawData.fax.split("-");
        temp[1] = value;
        if (temp[1] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[1] = "";
        }
        setRawData((prev) => ({ ...prev, fax: temp.join("-") }));
        break;
      case "fax3":
        temp = rawData.fax.split("-");
        temp[2] = value;
        if (temp[2] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[2] = "";
        }
        setRawData((prev) => ({ ...prev, fax: temp.join("-") }));
        break;
      default:
        break;
    }
  };
  const handleComplete = (data) => {
    var fullAddr = ""; // 최종 주소 변수
    var extraAddr = ""; // 조합형 주소 변수
    if (data.userSelectedType === "R") {
      fullAddr = data.roadAddress;
    } else {
      fullAddr = data.jibunAddress;
    }
    if (data.userSelectedType === "R") {
      if (data.bname !== "") {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? " (" + extraAddr + ")" : "";
    }
    postRef.current.value = data.zonecode;
    mainAddrRef.current.value = fullAddr;
    subAddrRef.current.focus();
    setRawData((prev) => ({
      ...prev,
      postnum: data.zonecode,
      addr: fullAddr,
    }));
  };
  const handleClick = () => {
    open({ onComplete: handleComplete });
  };
  function submitFun() {
    Swal.fire({
      title: "수정하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        createNew();
      }
    });
  }
  function createNew() {
    if (rawData.brc_nm === "") {
      Swal.fire({
        title: "지점명을 입력해주세요.",
      });
      return;
    }
    if (rawData.org_adm_nm === "") {
      Swal.fire({
        title: "관리자명을 입력해주세요.",
      });
      return;
    }
    if (rawData.hp.length < 13) {
      Swal.fire({
        title: "휴대폰 번호를 입력해주세요.",
      });
      return;
    }
    if (rawData.postnum === "") {
      Swal.fire({
        title: "주소를 입력해주세요.",
      });
      return;
    }
    if (rawData.addr === "") {
      Swal.fire({
        title: "주소를 입력해주세요.",
      });
      return;
    }
    if (rawData.addr_dt === "") {
      Swal.fire({
        title: "상세주소를 입력해주세요.",
      });
      return;
    }
    
    axios.post("/web/org/edit", rawData).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          title: "수정되었습니다.",
        }).then((result) => {
          navi("/Mng/Agent");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "수정에 실패하였습니다.",
        });
      }
    });
  }
  function deleteFun() {
    Swal.fire({
      title: "삭제하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post("/web/org/agent/del", {
            idx: idx,
            type: "A",
          })
          .then((res) => {
            
            if (res.status === 200) {
              Swal.fire({
                title: "삭제되었습니다.",
              }).then((result) => {
                navi("/Mng/Agent");
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "실패하였습니다.",
              });
            }
          });
      }
    });
  }
  function onClickFilter(e) {
    
    setRawData((prev) => ({
      ...prev,
      brc_idx: e.idx,
      brc_nm: e.org_nm,
    }));
    setCPN(`${e.org_nm}`);
  }
  const nameShow = (e) => {
    return e.org_nm;
  };
  const placeholder = () => {
    if (language === "KOR") {
      return "지점을 입력해주세요";
    } else if (language === "ENG") {
      return "enter branch  name";
    } else {
      return "Enter name"; // Default placeholder for other languages
    }
  };
  const resetBrc = () => {
    setRawData((prev) => ({
      ...prev,
      brc_idx: null,
      brc_nm: '',
    }));
    setCPN('');
  }
  // UseEffect definition
  //==================================================================
  useEffect(() => {
    if (location.state) {
      setIdx(location.state.idx);
    } else {
      setIdx("");
    }
  }, [location.state]);
  useEffect(() => {
    if (idx !== "") {
      axios.get(`/web/org/agent/detail?idx=${idx}`).then((res) => {
        setRawData(res.data);
      });
    }
  }, [idx]);
  // Data definition
  useEffect(() => {
    axios.get(`/web/org/branch/list?org_idx=${user.org_idx}`).then((res) => {
      const onlyBranch = res.data.map(({ org_nm, idx }) => ({
        org_nm,
        idx,
      }));
      setBranchList(onlyBranch);
    });
  }, [user]);
  useEffect(() => {
    if (rawData.brc_idx && branchList.length > 0) {
      const branch = branchList.filter((item) => item.idx === rawData.brc_idx);
      setCPN(branch[0].org_nm);
    }
  }, [rawData, branchList]);
  // Data definition
  // Render definition
  //==================================================================

  const buttonData = [
    {
      labelKR: "취소",
      labelEN: "Cancel",
      classN: "purple",
      clickFun: () => {
        navi("/Mng/Agent");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "삭제",
      labelEN: "Delete",
      classN: "white",
      icon: true,
      clickFun: () => {
        deleteFun();
      },
      position: " right",
    },
    {
      labelKR: "수정",
      labelEN: "Edit",
      classN: "purple",
      icon: true,
      clickFun: () => {
        submitFun();
      },
      position: "",
    },
  ];
  //==================================================================


  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Mng/Agent"} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "대리점 설정" : "Agent set"}
                </div>
              </div>
              <div className="sub-page-body">
                <table className="common-view-table">
                  <colgroup>
                    <col style={{ width: "19rem" }} />
                    <col />
                    <col style={{ width: "19rem" }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>{language === "KOR" ? "회사명" : "Com name"}</th>
                      <td>{user.org_nm}</td>
                      <th>{language === "KOR" ? "회사코드" : "Com code"}</th>
                      <td>{user.org_cd}</td>
                    </tr>
                    <tr>
                      <th name="brc_nm" className="required">
                        {language === "KOR" ? "지점명" : "Agent name"}
                      </th>
                      <td colSpan="">
                        <InputFilter
                          isHead={isHead}
                          language={language}
                          user={user}
                          companyData={branchList}
                          setState={setCPN}
                          setData={setRawData}
                          value={CPN}
                          onClickFilter={onClickFilter}
                          nameShow={nameShow}
                          placeholder={placeholder}
                          resetEvent={resetBrc}
                        />
                      </td>
                      <th name="org_nm" className="required">
                        {language === "KOR" ? "대리점명" : "Agent name"}
                      </th>
                      <td colSpan="">
                        <div className="flex">
                          <div className="custom-input">
                            <input
                              id="org_nm"
                              name="org_nm"
                              type="text"
                              onChange={inputHandler}
                              value={rawData.org_nm}
                              placeholder={
                                language === "KOR"
                                  ? "대리점을 입력해주세요."
                                  : "Please enter your agent name."
                              }
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th name="org_adm_nm" className="required">
                        {language === "KOR" ? "관리자명" : "Admin name"}
                      </th>
                      <td>
                        <div className="custom-input">
                          <input
                            id="org_adm_nm"
                            name="org_adm_nm"
                            type="text"
                            onChange={inputHandler}
                            value={rawData.org_adm_nm}
                            placeholder={
                              language === "KOR"
                                ? "관리자명을 입력해주세요."
                                : "Please enter your admin name."
                            }
                          />
                        </div>
                      </td>
                      <th name="hp" className="required">
                        {language === "KOR" ? "휴대폰 번호" : "Mobile"}
                      </th>
                      <td>
                        <div className="flex">
                          <TelInput
                            id="hp1"
                            name="hp1"
                            value={rawData.hp.split("-")[0]}
                            changeFun={hpHandler}
                            readOnly={false}
                            place="010"
                            max={3}
                            req={3}
                          />
                          -
                          <TelInput
                            id="hp2"
                            name="hp2"
                            value={rawData.hp.split("-")[1]}
                            changeFun={hpHandler}
                            place="0000"
                            max={4}
                            req={4}
                          />
                          -
                          <TelInput
                            id="hp3"
                            name="hp3"
                            value={rawData.hp.split("-")[2]}
                            changeFun={hpHandler}
                            place="0000"
                            max={4}
                            req={4}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th name="addr" className="required">
                        {language === "KOR" ? "주소" : "Addr"}
                      </th>
                      <td>
                        <div className="address">
                          <div className="row">
                            <div className="custom-input">
                              <input
                                id="postnum"
                                name="postnum"
                                type="text"
                                readOnly
                                ref={postRef}
                                value={rawData.postnum}
                                onChange={(e) => {
                                  console.log("");
                                }}
                              />
                            </div>
                            <button
                              onClick={handleClick}
                              type="button"
                              className="common-btn white sm"
                            >
                              <span>
                                {language === "KOR"
                                  ? "우편번호 검색"
                                  : "Addr search"}
                              </span>
                            </button>
                          </div>
                          <div className="row">
                            <div className="custom-input full">
                              <input
                                id="addr"
                                name="addr"
                                type="text"
                                ref={mainAddrRef}
                                value={rawData.addr}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="custom-input full">
                              <input
                                id="addr_dt"
                                name="addr_dt"
                                type="text"
                                ref={subAddrRef}
                                onChange={inputHandler}
                                value={rawData.addr_dt}
                                placeholder={
                                  language === "KOR"
                                    ? "상세주소를 입력해주세요."
                                    : "Please enter your detailed address."
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                      <th>{language === "KOR" ? "직통번호" : "Direct tel"}</th>
                      <td>
                        <div className="flex">
                          <TelInput
                            id="tel1"
                            name="tel1"
                            value={rawData.tel.split("-")[0]}
                            changeFun={TelHandler}
                            readOnly={false}
                            place="010"
                            max={3}
                            req={2}
                          />
                          -
                          <TelInput
                            id="tel2"
                            name="tel2"
                            value={rawData.tel.split("-")[1]}
                            changeFun={TelHandler}
                            place="0000"
                            max={4}
                            req={3}
                          />
                          -
                          <TelInput
                            id="tel3"
                            name="tel3"
                            value={rawData.tel.split("-")[2]}
                            changeFun={TelHandler}
                            place="0000"
                            max={4}
                            req={4}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        {language === "KOR"
                          ? "사업자 등록번호"
                          : "Official biz reg num"}
                      </th>
                      <td>
                        <div className="custom-input">
                          <input
                            name="bizno"
                            type="text"
                            onChange={inputHandler}
                            maxLength={10}
                            value={rawData.bizno}
                            placeholder={
                              language === "KOR"
                                ? "사업자 등록번호를 입력해주세요."
                                : "Please enter your official biz reg num."
                            }
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </td>
                      <th>E-mail</th>
                      <td>
                        <Email
                          id="email"
                          name="email"
                          value={rawData.email}
                          setRawData={setRawData}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{language === "KOR" ? "팩스번호" : "Fax"}</th>
                      <td colSpan={1}>
                        <div className="flex">
                          <TelInput
                            id="fax1"
                            name="fax1"
                            value={rawData.fax.split("-")[0]}
                            changeFun={faxHandler}
                            place="000"
                            max={3}
                            req={2}
                          />
                          -
                          <TelInput
                            id="fax2"
                            name="fax2"
                            value={rawData.fax.split("-")[1]}
                            changeFun={faxHandler}
                            place="0000"
                            max={4}
                            req={3}
                          />
                          -
                          <TelInput
                            id="fax3"
                            name="fax3"
                            value={rawData.fax.split("-")[2]}
                            changeFun={faxHandler}
                            place="0000"
                            max={4}
                            req={4}
                          />
                        </div>
                      </td>
                      <th>
                        {language === "KOR" ? "등록일" : "Registration date"}
                      </th>
                      <td>
                        <div className="custom-input">
                          <input
                            name="makedate"
                            type="text"
                            readOnly
                            value={
                              rawData.makedate
                                ? moment
                                    .utc(rawData.makedate)
                                    .format("YYYY-MM-DD HH:mm:ss")
                                : ""
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
