import React, { Children } from "react";

interface InputProps {
  place?: string;
  changeFun?: (e: any) => void;
  read?: boolean;
  val?: any;
  auto?: string;
  type?: string;
  name: string;
  valType?: string;
}

interface Props {
  changeFunClick: () => void;
  btnName: string;
  inputs?: InputProps[];
  title: string;
  children?: React.ReactNode;
}

export default function InputGroup({
  changeFunClick,
  btnName,
  inputs,
  title,
  children,
}: Props) {
  return (
    <>
      <th className="">{title}</th>
      <td
        style={{
          position: "relative",
        }}
      >
        <div className="flex">
          {children}
          {inputs !== undefined
            ? inputs.map((input, idx) => (
                <div key={idx} className="flex">
                  <div
                    className="custom-input"
                    style={{
                      width: "100%",
                    }}
                  >
                    <input
                      //@ts-ignore
                      onChange={input.changeFun && ((e) => input.changeFun(e))}
                      value={`${input.val}`}
                      type={`${input.type}`}
                      autoComplete={`${input.auto}`}
                      name={`${input.name}`}
                      readOnly={input.read}
                      placeholder={`${input.place}`}
                    />
                  </div>
                  <span>{input.valType}</span>
                </div>
              ))
            : ""}
          <button
            style={{
              backgroundColor: "#f8f9fa",
            }}
            type="button"
            className="common-btn white  sm"
            onClick={() => changeFunClick()}
          >
            <span>{btnName}</span>
          </button>
        </div>
      </td>
    </>
  );
}
