import React from "react";
//@ts-ignore
import AtomTable from "../../Atomic/organism/AtomTable";
interface Props {
  onClick: () => void;
  data: any;
  columns: any;
  title: string;
}
export default function Modal({ title, onClick, data, columns }: Props) {
  function move() {
    console.log("move");
  }

  return (
    <>
      <div className="common-modal on">
        <div
          className="modal-inner"
          style={{
            padding: "16rem 5rem",
          }}
        >
          <div className="modal-page">
            <div className="page-header">
              <div className="page-title">
                <span>{title}</span>
              </div>
              <button
                onClick={() => onClick()}
                type="button"
                className="modal-close"
              >
                <i className="ri-close-fill icon"></i>
              </button>
            </div>
            <div className="page-body">
              <div className="flex-container">
                <div className="common-table-wrapper">
                  <AtomTable data={data} columns={columns} move={move} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
