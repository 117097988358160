import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
//@ts-ignore
import { LanguageContext } from "../../../../../context/LanguageContext.jsx";
//@ts-ignore
import { dataList } from "../../../AdmMain";
import Page from "../../../../Atomic/template/PageTable";
import CommonControl from "../../../../Atomic/organism/CommonControl";
import SubNavi from "../../../../Atomic/molecules/SubNavi";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import axios from "../../../../../lib/axios.js";
//@ts-ignore
import { useAuth } from "../../../../../context/LoginContext.jsx";
//@ts-ignore
import TableAtom from "../../../../Atomic/organism/AtomTable";
import { filterData, inputChnage, translate } from "../../../../Atomic/exFunc";
import Form from "../../../../Atomic/organism/FormAtom";
import Modal from "../../../../Atomic/organism/Modal";
import Select from "../../../../Atomic/molecules/Select";
//@ts-ignore
import { subDataList } from "../../../../Admin/AdmMain";
export default function Table() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const navi = useNavigate();
  const [rawData, setRawData] = useState<any>([]);
  const { user } = useAuth();
  const inputRef = React.createRef<HTMLInputElement>();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState<any>([]);
  const [smsModal, setSmsModal] = useState(false);
  const [smsData, setSmsData] = useState<any>([]);
  const [smsModalUser, setSmsModalUser] = useState<any>({});
  const [smsCnt, setSmsCnt] = useState<any>({});

  // Function defines
  //===============================================================
  function move(e: any) {
    const i = e.instance.store.focus.rowIndex;
    if (i === undefined) {
      return;
    }
    const idx = e.instance.store.data.rawData[i].idx;
    const data = e.instance.store.data.rawData[i];
    if (e.columnName !== "sms_cnt" && e.targetType != "columnHeader") {
      navi(`/Adm/u-table/edit`, {
        state: {
          idx: idx,
        },
      });
    }
    if (e.columnName === "sms_cnt" && e.targetType != "columnHeader") {
      setSmsModal(true);
      getSmsData(idx);
      setSmsCnt((prev : any) => ({...prev, u_idx: idx}));
      setSmsModalUser(data);
    }
  }

  function moveSms(e: any) {
    return;
  }

  function submitFun(e: any) {
    e.preventDefault();
    filterData(rawData, keyword, setData);
  }

  function getSmsData(smsIdx: string) {
    axios
      .get(
        `/web/admin/user/sms/list?idx=${smsIdx}&type=${user.type}`
      )
      .then((res: any) => {
        // console.log(res.data);
        setSmsData(res.data);
      })
      .catch((err: any) => {
        setSmsData([]);
      });
  }

  function smsInuptChahe(e: any) {
    setSmsCnt({
      ...smsCnt,
      [e.target.name]: e.target.value,
    });
  }

  function smsCheck() {
    if (smsCnt.cnt_kind === "") {
      Swal.fire({
        title: `${translate(
          "추가/삭감 구분을 선택해주세요",
          "Please select Add / Delete",
          language
        )}`,
      });
      return false;
    }
    if (smsCnt.cnt === "") {
      Swal.fire({
        title: `${translate(
          "건수를 입력해주세요",
          "Please enter the count",
          language
        )}`,
      });
      return false;
    }
  }

  function smsComfirm() {
    Swal.fire({
      icon: "success",
      title: "Success",
      text: `${translate(
        "성공적으로 등록되었습니다",
        "Successfully registered",
        language
      )}`,
    });
  }

  function smsAsk(e: any) {
    Swal.fire({
      title: `${translate(
        "등록하시겠습니까?",
        "Do you want to register?",
        language
      )}`,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: `${translate("등록", "Submit", language)}`,
      cancelButtonText: `${translate("취소", "Cancel", language)}`,
    }).then((result) => {
      if (result.isConfirmed) {
        smsSubmit(e);
      } else if (result.isDenied) {
        Swal.fire(`${translate("취소", "Cancel", language)}`, "", "info");
      }
    });
  }

  function smsSubmit(e: any) {
    e.preventDefault();
    if (smsCheck() === false) {
      return;
    }
    console.log(smsCnt);

    axios
      .post(`/web/admin/user/sms/edit`, smsCnt)
      .then((res: any) => {
        if (res.status === 200) {
          smsComfirm();
          getSmsData(smsCnt.u_idx);
          setSmsCnt((prev : any) => ({
            ...prev,
            cnt: "",
            cnt_kind: "",
            memo: "",
          }));
        }
      });
  }
  function getData() {
    axios
      .get(`/web/admin/user/list?type=${user.type}`)
      .then((res: any) => {
        setRawData(res.data);
      })
  }
  // Data define
  //===============================================================

  const columns: any = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "구분" : "Division",
      name: "btoc_yn",
      width: 70,
      align: "center",
      filter: {
        type: "select",
        options: {
          listItems: [
            { text: language === "KOR" ? "개인" : "Individual", value: "Y" },
            { text: language === "KOR" ? "기업" : "Company", value: "N" },
          ],
        },
      },
      formatter: (name: any) => {
        if (name.row.btoc_yn === "Y") {
          return language === "KOR" ? "개인" : "Individual";
        } else if (name.row.btoc_yn === "N") {
          return language === "KOR" ? "기업" : "Company";
        }
      },
    },
    {
      header: language === "KOR" ? "등급" : "Grade",
      name: "u_type",
      width: 70,
      align: "center",
      filter: {
        type: "select",
        options: {
          listItems: [
            { text: language === "KOR" ? "회사" : "Company", value: "C" },
            { text: language === "KOR" ? "지점" : "Branch", value: "B" },
            { text: language === "KOR" ? "대리점" : "Agency", value: "A" },
          ],
        },
      },
      formatter: (name: any) => {
        if (name.row.u_type === "C") {
          return language === "KOR" ? "회사" : "CO";
        } else if (name.row.u_type === "B") {
          return language === "KOR" ? "지점" : "BRC";
        } else if (name.row.u_type === "A") {
          return language === "KOR" ? "대리점" : "AGT";
        }
      },
    },
    {
      header: language === "KOR" ? "회사명" : "Company name",
      name: "cmp_nm",
      align: "center",

      width: 170,
    },
    {
      header: language === "KOR" ? "지점명" : "Branch name",
      name: "brc_nm",

      width: 90,
      align: "center",
    },
    {
      header: language === "KOR" ? "대리점명" : "Agency name",
      name: "agt_nm",

      width: 90,
      align: "center",
    },
    {
      header: language === "KOR" ? "로그인" : "Mobile login grade",
      name: "grade",
      width: 70,
      align: "center",
      formatter: (name: any) => {
        if (name.row.grade === "CS") {
          return language === "KOR" ? "일반고객" : "Client";
        } else if (name.row.grade === "AD") {
          return language === "KOR" ? "관리자" : "ADM";
        } else if (name.row.grade === "DV") {
          return language === "KOR" ? "기사" : "Driver";
        } else if (name.row.grade === "FDV") {
          return language === "KOR" ? "고정기사" : "Fix Driver";
        }
      },
    },
    {
      header: language === "KOR" ? "회사코드/아이디" : "Code & ID",
      name: "",
      align: "center",
      formatter: (name: any) => name.row.org_cd + " / " + name.row.id,
    },
    {
      header: language === "KOR" ? "사용자명" : "User name",
      name: "nm",
      align: "center",
    },
    {
      header: language === "KOR" ? "부서" : "Department",
      name: "depart",
      align: "center",

      width: 75,
    },
    {
      header: language === "KOR" ? "핸드폰" : "Phone number",
      name: "hp",
      align: "center",

      width: 125,
    },
    {
      header: language === "KOR" ? "SMS" : "SMS",
      name: "sms_cnt",
      align: "center",
      formatter: (name: any) =>
        `<div class="link cursor" > ${
          name.value === null ? "0" : name.value
        }</div>`,
      width: 70,
    },
    {
      header: language === "KOR" ? "등록일" : "Reg. date",
      name: "makedate",

      align: "center",
    },
    {
      header: language === "KOR" ? "지오유" : "Geoyou",
      name: "sso_yn",
      align: "center",
      width: 70,
      formatter: (value: any) => (value.row.sso_yn === "Y" ? "Y" : "N"),
    },
  ];
  const smsColumns: any = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 50,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "구분" : "Type",
      name: "cnt_kind",
      width: 100,
      align: "center",
      formatter: (name: any) => {
        if (name.row.cnt_kind === "P") {
          return language === "KOR" ? "+ 추가" : "+ Add";
        } else if (name.row.cnt_kind === "M") {
          return language === "KOR" ? "- 삭감" : "- Delete";
        }
      },
    },
    {
      header: language === "KOR" ? "건수" : "Count",
      name: "cnt",
      width: 80,
      align: "center",
      sortable: true,
    },
    {
      header: language === "KOR" ? "메세지" : "Message",
      name: "msg",
      align: "center",
    },
    {
      header: language === "KOR" ? "메모" : "Memo",
      name: "memo",
      width: 140,
      align: "center",
    },
    {
      header: language === "KOR" ? "등록일" : "Make date",
      name: "makedate",
      width: 120,
      align: "center",
    },
  ];
  const buttonData = [
    {
      labelKR: "전체목록",
      labelEN: "View All",
      classN: "white",
      clickFun: () => {
        setKeyword("");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "등록",
      labelEN: "Add new",
      classN: "purple",
      icon: true,
      clickFun: () => {
        navi("/Adm/u-table/new");
      },
      position: "right",
    },
  ];

  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e: any) => inputChnage(e, rawData, setData, setKeyword),
    myref: inputRef,
    option: undefined,
    value: keyword,
  };

  // useEffect define
  //===============================================================
  useEffect(() => {
    axios
      .get(`/web/admin/user/list?type=${user.type}`)
      .then((res: any) => {
        setRawData(res.data);
        setSmsCnt({
          type: user.type,
          admin_idx: user.idx,
          cnt: "",
          cnt_kind: "",
          memo: "",
        });
      });
  }, [user]);
  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
      filterData(rawData, keyword, setData);
    }
  }, [rawData, keyword]);

  // logs define
  //===============================================================
  // console.log("smsSent", smsCnt);
  // console.log("smsModalUser", user);
  // console.log(smsData);

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "사용자 관리" : "User management"}`}
        menu="/Adm/o-table"
      >
        <SubNavi
          subDataList={subDataList}
          language={language}
          navi={navi}
          classPath="/Adm/u-table"
        />
        <Form formData={formData} inputRef={inputRef} />
        <TableAtom data={data} columns={columns} move={move} />
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
      {smsModal && (
        <Modal
          title={translate(
            `SMS 건수 내역 : 
            ${smsModalUser.cmp_nm}
            ${smsModalUser.brc_nm ? ` > ${smsModalUser.brc_nm}` : ''}
            ${smsModalUser.agt_nm ? ` > ${smsModalUser.agt_nm}` : ''}
            ${smsModalUser.nm ? ` > ${smsModalUser.nm}` : ''} (회사코드 : ${smsModalUser.org_cd})`,
            `SMS Log : 
            ${smsModalUser.cmp_nm}
            ${smsModalUser.brc_nm ? ` > ${smsModalUser.brc_nm}` : ''}
            ${smsModalUser.agt_nm ? ` > ${smsModalUser.agt_nm}` : ''} 
            ${smsModalUser.nm ? ` > ${smsModalUser.nm}` : ''}(org_cd : ${smsModalUser.org_cd})`,
            language
          )}
          closeModal={() => {
            getData()
            setSmsModal(false)
            setSmsCnt((prev : any) => ({
              ...prev,
              cnt: "",
              cnt_kind: "",
              memo: "",
            }));
          }}
        >
          <form className="search-bar" onSubmit={formData.submitHandler}>
            <Select
              id="cnt_kind"
              short={"N"}
              name="cnt_kind"
              options={[
                {
                  name: translate("추가/삭감 구분", "Add / Delete", language),
                  value: "",
                },
                {
                  name: `${translate("추가", "Add", language)}`,
                  value: "P",
                },
                {
                  name: `${translate("삭감", "Delete", language)}`,
                  value: "M",
                },
              ]}
              changeFun={(e) => smsInuptChahe(e)}
              def={smsCnt.cnt_kind || ""}
            />
            <div className="custom-input">
              <input
                type="text"
                id="cnt"
                name="cnt"
                placeholder={
                  language === "KOR"
                    ? "Sms 건수를 입력하세요"
                    : "Sms count to add or delete"
                }
                onChange={(e) => smsInuptChahe(e)}
                value={smsCnt.cnt || ""}
              />
            </div>
            <div className="custom-input">
              <input
                type="text"
                id="memo"
                name="memo"
                placeholder={
                  language === "KOR" ? "메모 입력하세요" : "Input memo"
                }
                onChange={(e) => smsInuptChahe(e)}
                value={smsCnt.memo || ""}
              />
            </div>
            <button
              type="button"
              className="submit-btn"
              onClick={(e) => smsAsk(e)}
            >
              {translate("등록", "Submit", language)}
            </button>
          </form>
          <TableAtom move={moveSms} data={smsData} columns={smsColumns} />
        </Modal>
      )}
    </>
  );
}
