import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DvcEditForm from "../DvcEditForm";
import { LanguageContext } from "../../../context/LanguageContext";
import { useAuth } from "../../../context/LoginContext";
import Footer from "../../include/AppFooter";
import Header from "../../include/AppHeader";
import Nav from "../../Atomic/organism/Nav";
import axios from "../../../lib/axios";
import { dataList } from "./TerminalMain";
export default function TerminalEdit() {
  // State definition
  const user = useAuth();
  const { language } = useContext(LanguageContext);
  const location = useLocation();

  const [detailData, setDetailData] = useState([]);
  const [unitId, setUnitId] = useState("");

  //===========================================================

  // Data definition

  //===========================================================
  // Function definition

  //===========================================================
  // useEffect definition
  useEffect(() => {
    if (unitId) {
      async function getData() {
        try {
          await axios
            .post(`/web/terminal/detail`, unitId)
            .then((res) => setDetailData(res));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      getData();
    }
  }, [unitId]);

  useEffect(() => {
    if (location.state) {
      setUnitId(location.state);
    }
  }, [location.state]);

  console.log(unitId);
  //===========================================================
  if (detailData.length === 0) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Header pages="4" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav dataList={dataList} menu="/Trm" language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "단말설정" : "Device settings"}
                </div>
              </div>
              <div className="sub-page-body">
                <div className="sub-page-notice">
                  <ul className="notice-list">
                    <li className="list-item">
                      {language === "KOR"
                        ? "단말 제어는 별도의 SMS 비용이 발생합니다."
                        : "The sms service is not free. You need extra cost."}
                    </li>
                    <li className="list-item">
                      {language === "KOR"
                        ? "국세청 운행일지는 입력한 계기판 거리 기준으로 생성되며 계기판 거리 변경 시 이전 기록은 소급 적용되지 않습니다"
                        : "NTS driving log is created from entered odo meter, and when odo meter is changed, previous logs are not applied"}
                    </li>
                  </ul>
                </div>
                <div className="common-table-wrapper">
                  <DvcEditForm data={detailData.data} user={user} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
