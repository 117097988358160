import React, { useContext, useEffect, useState } from "react";
import "../../assets/lib/datepicker/datepicker.min.css";
import DailyList from "../../components/map/DailyList";
import Multi from "../../components/map/Multi";
import TotalList from "../../components/map/TotalList";
import FoldBtn from "../../components/map/buttons/FoldBtn";
import { LanguageContext } from "../../context/LanguageContext";
import { useMap } from "../../context/MapContext";
import { useAuth } from "../../context/LoginContext";
export default function LeftListPanel({ data, typeData }) {
  const { language } = useContext(LanguageContext);
  const { mapDispatch } = useMap();

  const [tab, setTab] = useState("all");
  const { user } = useAuth();
  const [dataList, setDataList] = useState([{ tabName: "all", labelKor: "전체목록", labelEng: "Device List" }]);
  // 탭 변경
  const handleTab = (e) => {
    if (e === "multi") {
      window.open("./Screen", "_blank");
      return;
    }
    switch (e) {
      case "all":
        if (tab !== "all") {
          setTab("all");
          mapDispatch({ type: "BLUR" });
          // setViewData(data);
        }
        break;
      case "day":
        tab !== "day" && setTab("day");
        break;
      // case "multi":
      //   tab !== "multi" && setTab("multi");
      //   break;
      default:
        console.log("??");
    }
  };

  useEffect(() => {
    if(user) {
      if(user.auth.A200 === "Y") {
        setDataList((prev) => ([...prev, { tabName: "day", labelKor: "일별목록", labelEng: "Daily List" }]))
      }
      if(user.auth.A300 === "Y") {
        setDataList((prev) => ([...prev, { tabName: "multi", labelKor: "멀티관제", labelEng: "Multi View" }]))
      }
    }
  },[user.auth]);
  

  return (
    <>
      {/*<!--s: 왼쪽 리스트 판넬-->*/}
      <div className="track-list-panel">
        <div className="panel-inner">
          <div className="common-tab track-tab-wrapper">
            <div className="tab-nav">
              <div className="tab-inner">
                {dataList.map((data) => (
                  <button
                    style={{
                      border: "none",
                      background: "transparent",
                    }}
                    className={`nav-btn ${tab === data.tabName ? "on" : ""}`}
                    onClick={() => handleTab(data.tabName)}
                  >
                    {language === "KOR" ? data.labelKor : data.labelEng}
                  </button>
                ))}
              </div>
            </div>
            <div className="tab-panel-wrapper">
              {tab === "all" && (
                <TotalList data={data} typeData={typeData} tab={tab} />
              )}
              {tab === "day" && <DailyList tab={tab} />}
              {/* {tab === "multi" && <Multi tab={tab} />} */}
            </div>
          </div>
        </div>
        <FoldBtn />
      </div>
      {/*<!--e: 왼쪽 리스트 판넬-->*/}
    </>
  );
}
