import Title from "../atom/Title";
import CheckBox from "../atom/CheckBox";
import PropTypes from "prop-types";
export default function FormCheckBox({
  title,
  id,
  value,
  inputName,
  checked,
  state,
  onChangeFun,
}) {
  return (
    <>
      <div className="form-row color-picker-box">
        <Title title={title} />
        <input
          type="checkbox"
          id={id}
          name={inputName}
          value="Y"
          checked={state.allday === "Y"}
          onChange={(e) => onChangeFun(e)}
        />
      </div>
    </>
  );
}

FormCheckBox.propTypes = {
  checked: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  changeFun: PropTypes.func,
  classN: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  inputName: PropTypes.string,
};
