import { useContext, useEffect, useState, useRef } from "react";

import DatePicker from "vanillajs-datepicker/Datepicker";
import moment from "moment";

import { LanguageContext } from "../../../context/LanguageContext";
import { useAuth } from "../../../context/LoginContext.jsx";
import axios from "../../../lib/axios";

import AppHeader from "../../include/AppHeader";
import AppFooter from "../../include/AppFooter";
import EventTable from "../organism/EventTable";
import Nav from "../../Atomic/organism/Nav";
import CommonControl from "../../Atomic/organism/CommonControl";
import useExcelDown from "../../../hook/useExcelDown.jsx";
import useFetch from "../../../hook/useFetch.jsx";
import { dataList } from "./DeviceLogMain.tsx";
import { useInView } from "react-intersection-observer";
import { event } from "../../../lib/eventData";
function Event() {
  // ====================================================================================================
  const defalueDate = moment().format("YYYY-MM-DD");

  const { language } = useContext(LanguageContext);
  const { user } = useAuth();

  const [date, setDate] = useState({ from: defalueDate, to: defalueDate });
  const [keyword, setKeyword] = useState("");
  const [rawData, setRawData] = useState(null); // 주소가 업데이트 된 데이터
  const [data, setData] = useState(null); // 필터링이 된 데이터
  const [renderData, setRenderData] = useState(null); // 렌더링 데이터
  const [count, setCount] = useState(0);
  const [isSearch, setIsSearch] = useState(true);
  const [fromPicker, setFromPicker] = useState(null);
  const [toPicker, setToPicker] = useState(null);
  const [excelData, setExcelData] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);

  const renderCount = user.render_cnt ?? 20;
  const updateCount = 500;

  const inputRef = useRef(null);
  const fromRef = useRef(null);
  const toRef = useRef(null);
  const tableRef = useRef(null);
  const excelRef = useRef(null);
  const countRef = useRef(null);
  const rCountRef = useRef(null);
  const [ref, inView] = useInView();
  const [hasNext, setHasNext] = useState(true);

  const url = `/web/device/event/list?idx=${user.idx}&type=${user.type}&org_idx=${user.org_idx}&grade=${user.grade}&from=${date.from}&to=${date.to}&search=${isSearch}`;
  const { data: initData, isLoading } = useFetch(url);
  // ====================================================================================================
  useEffect(() => {
    if (fromPicker || toPicker) {
      fromPicker.destroy();
      toPicker.destroy();
    }

    DatePicker.locales.ko = {
      days: [
        "일요일",
        "월요일",
        "화요일",
        "수요일",
        "목요일",
        "금요일",
        "토요일",
      ],
      daysShort: ["일", "월", "화", "수", "목", "금", "토"],
      daysMin: ["일", "월", "화", "수", "목", "금", "토"],
      months: [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ],
      monthsShort: [
        "1월",
        "2월",
        "3월",
        "4월",
        "5월",
        "6월",
        "7월",
        "8월",
        "9월",
        "10월",
        "11월",
        "12월",
      ],
      today: "오늘",
      clear: "삭제",
      format: "yyyy-mm-dd",
      titleFormat: "yyyy년mm월",
      weekStart: 0,
    };
    
    const from = new DatePicker(fromRef.current, {
      language: language === "KOR" ? "ko" : "en",
      autohide: true,
      todayBtn: true,
      todayBtnMode: 1,
      format: "yyyy-mm-dd",
    });

    const to = new DatePicker(toRef.current, {
      language: language === "KOR" ? "ko" : "en",
      autohide: true,
      todayBtn: true,
      todayBtnMode: 1,
      format: "yyyy-mm-dd",
    });
    setFromPicker(from);
    setToPicker(to);
  }, [language]);
  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
      filterData(rawData, keyword);
    }
  }, [rawData, keyword]);
  useEffect(() => {
    if (isLoading) {
      setRawData(null);
    } else {
      if (initData) {
        setRawData(initData);
      } else {
        setRawData([]);
      }
    }
  }, [initData]);
  // 최초 렌더링 데이터 설정
  useEffect(() => {
    if (data?.length > 0) {
      fetchData(data.slice(0, renderCount)).then((res) => {
        setRenderData([...res]);
        setExcelData([...res]);
      });
    } else if (data?.length === 0) {
      setRenderData([]);
    } else {
      setRenderData(null);
    }
  }, [data]);
  // 스크롤 변화에 따른 데이터 설정
  useEffect(() => {
    if (data?.length > 0) {
      fetchData(
        data.slice(renderCount * count, renderCount * (1 + count))
      ).then((res) => {
        setRenderData((prev) => [...prev, ...res]);
        if (!excelLoading && data.length !== renderData.length) {
          setExcelData((prev) => [...prev, ...res]);
        }
      });
    }
  }, [count]);
  // 이벤트 리스너 추가
  useEffect(() => {
    if(inView) {
      bottomCheck();
    }
  },[inView]);
  // 엑셀데이터 주소변환
  useEffect(() => {
    if (excelLoading) {
      if (excelData.length !== data.length) {
        fetchData(
          data.slice(excelData.length, excelData.length + updateCount)
        ).then((res) => setExcelData((prev) => [...prev, ...res]));
      } else {
        alert("데이터 로딩을 완료하였습니다");
        setExcelLoading(false);
        excelDown();
      }
    }
  }, [excelData, excelLoading]);
  // ====================================================================================================
  function submitFun(e) {
    e.preventDefault();
    setRawData(null);
    setData(null);
    setRenderData(null);
    setExcelData([]);
    setCount(0);
    setDate({ from: fromRef.current.value, to: toRef.current.value });
    setIsSearch((prev) => !prev);
  }
  function inputChnage(key) {
    setKeyword(key);
    filterData(rawData, key);
  }
  function filterData(data, key) {
    const result = data.filter((item) => {
      return item.unitid.includes(key) || item.unit_nm.includes(key) || event(item.event_code, language).includes(key);
    });
    setData(result);
  }
  function bottomCheck() {
    // 데이터 추가 가능 확인
    if (
      countRef.current.value < Math.ceil(rCountRef.current.value / renderCount) - 1
    ) {
      setHasNext(true);
      setCount((prev) => prev + 1);
    } else {
      setHasNext(false);
    }
  }
  async function address(lat, lon) {
    const datasend = {
      latitude: lat,
      longitude: lon,
    };
    try {
      const response = await axios.post("/web/api/addr", datasend);
      return response.data.addr;
    } catch (err) {
      console.log(err);
      throw err; // Handle or propagate the error further as needed
    }
  }
  async function fetchData(data) {
    if (data.length > 0) {
      const newData = await Promise.all(
        data.map(async (item) => {
          item.addr = await address(item.latitude, item.longitude);
          return item;
        })
      );
      return newData;
    } else {
      return data;
    }
  }
  // ====================================================================================================
  function viewAll() {
    setKeyword("");
    inputRef.current.value = "";
  }
  const { excelDown } = useExcelDown(
    excelRef,
    `Event_list_${defalueDate}`,
    `Event_list_${defalueDate}`,
    initData
  );
  function customExcelDown() {
    if (!excelLoading && excelData.length === data?.length) {
      excelDown();
    } else if (excelLoading) {
      alert("데이터 로딩중입니다.");
    } else {
      alert("데이터를 로딩합니다.");
      setExcelLoading(true);
    }
  }
  // ====================================================================================================

  const buttonData = [
    {
      labelKR: "전체보기",
      labelEN: "View all",
      classN: "purple",
      clickFun: viewAll,
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "엑셀 다운로드",
      labelEN: "Excel download",
      classN: "white excel",
      icon: true,
      iconClass: "ri-file-excel-2-fill",
      clickFun: customExcelDown,
      position: "right",
    },
  ];
  return (
    <>
      <AppHeader />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu="/Dlog/Event" dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "실시간 이벤트" : "Events"}
                </div>
                <input
                  ref={countRef}
                  style={{ display: "none" }}
                  value={count}
                  readOnly
                />
                <input
                  ref={rCountRef}
                  style={{ display: "none" }}
                  value={data?.length}
                  readOnly
                />
              </div>
              <div className="sub-page-body">
                <form className="search-bar" onSubmit={submitFun}>
                  <div className="custom-input">
                    <input
                      id="keyword"
                      type="text"
                      placeholder={
                        language === "KOR"
                          ? "단말번호 텍스트 검색"
                          : "enter a USIM num"
                      }
                      ref={inputRef}
                      onChange={(e) => {
                        inputChnage(e.target.value);
                      }}
                    />
                  </div>
                  <div className="period-group">
                    <div className="custom-datepicker">
                      <input
                        ref={fromRef}
                        type="text"
                        name="from"
                        value={date.from}
                        readOnly
                      />
                    </div>
                    <span className="devider">~</span>
                    <div className="custom-datepicker">
                      <input
                        ref={toRef}
                        type="text"
                        name="to"
                        value={date.to}
                        readOnly
                      />
                    </div>
                  </div>

                  <button type="submit" className="submit-btn">
                    {language === "KOR" ? "검색" : "Search"}
                  </button>
                </form>
                <EventTable
                  data={renderData}
                  excelData={excelData}
                  tableRef={tableRef}
                  excelRef={excelRef}
                  excelLoading={excelLoading}
                  viewRef={ref}
                  hasNext={hasNext}
                />
                <CommonControl
                  buttonData={buttonData}
                  viewAll={viewAll}
                  excelDown={customExcelDown}
                  language={language}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
}
export default Event;
