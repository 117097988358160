import React, { useState, useEffect } from "react";
//@ts-ignore
import { LanguageContext } from "../../../../context/LanguageContext.jsx";
//@ts-ignore
import { dataList } from "../../../Admin/AdmMain";
import Page from "../../../Atomic/template/PageTable";
import CommonControl from "../../../Atomic/organism/CommonControl";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import axios from "../../../../lib/axios";
//@ts-ignore
import { useAuth } from "../../../../context/LoginContext.jsx";
//@ts-ignore
import TableAtom from "../../../Atomic/organism/AtomTable";
import { filterData, inputChnage, translate } from "../../../Atomic/exFunc";
import Form from "../../../Atomic/organism/FormAtom";
import moment from "moment";
//@ts-ignore
export default function Table() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const navi = useNavigate();
  const [rawData, setRawData] = useState<any>([]);
  const { user } = useAuth();
  const inputRef = React.createRef<HTMLInputElement>();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState<any>([]);
  // Function defines
  //===============================================================

  function move(e: any) {
    const i = e.instance.store.focus.rowIndex;
    if (i === undefined) {
      return;
    }
    const idx = e.instance.store.data.rawData[i].idx;

    if (e.columnName === "sms_cnt" && e.targetType != "columnHeader") {
      navi(`/Adm/sys-table/edit`, {
        state: {
          idx: idx,
        },
      });
    }
  }

  function submitFun(e: any) {
    e.preventDefault();
    filterData(rawData, keyword, setData);
  }

  // Data define
  //===============================================================

  const columns: any = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "제목" : "Title",
      name: "tit",
      align: "center",
    },
    {
      header: language === "KOR" ? "사용여부" : "Use",
      name: "act_yn",
      align: "center",
      width: 100,
      formatter: (name: any) => (name.value === "Y" ? "<span style='color:blue'>Y</span>" : "<span style='color:red'>N</span>")
    },
    {
      header: language === "KOR" ? "긴급여부" : "Emergamcy",
      name: "eme_yn",
      align: "center",
      width: 100,
      formatter: (name: any) => (name.value === "Y" ? "<span style='color:red;'>긴급</span>" : "일반"),
    },
    {
      header: language === "KOR" ? "노출순서" : "Order",
      name: "sort",
      align: "center",
      width: 100,
    },
    {
      header: language === "KOR" ? "작성자" : "Writer",
      name: "writer",
      align: "center",
      width: 140,
    },
    {
      header: language === "KOR" ? "등록일" : "Date",
      name: "makedate",
      align: "center",
      width: 200,
      formatter: (item: any) => moment.utc(item.value).format("YYYY-MM-DD"),
    },

    {
      header: language === "KOR" ? "수정" : "Edit",
      name: "sms_cnt",
      align: "center",
      formatter: (name: any) => `<div class="link cursor" >수정</div>`,
      width: 80,
    },
  ];

  const buttonData = [
    {
      labelKR: "전체목록",
      labelEN: "View All",
      classN: "white",
      clickFun: () => {
        setKeyword("");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "등록",
      labelEN: "Add new",
      classN: "purple",
      icon: true,
      clickFun: () => {
        navi("/Adm/sys-table/new");
      },
      position: "right",
    },
  ];

  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e: any) => inputChnage(e, rawData, setData, setKeyword),
    myref: inputRef,
    option: undefined, // Make option undefined
    value: keyword,
  };

  // useEffect define
  //===============================================================

  useEffect(() => {
    axios
      .get(`/web/admin/notice/list?type=${user.type}`)
      .then((res: any) => {
        setRawData(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [user]);
  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
      filterData(rawData, keyword, setData);
    }
  }, [rawData, keyword]);
  // logs define
  //===============================================================
  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "시스템 공지사항" : "System Notice"}`}
        menu="/Adm/sys-table"
      >
        <Form formData={formData} inputRef={inputRef} />
        <TableAtom data={data} columns={columns} move={move} />
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
