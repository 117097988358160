import React, { useContext } from "react";
// @ts-ignore
import useFetch from "../../hook/useFetch.jsx";
import { Outlet } from "react-router-dom";
import DasMain from "../../components/Das/DasMain";
//@ts-ignore
import { LanguageContext } from "../../context/LanguageContext.jsx";
//@ts-ignore
import { useAuth } from "../../context/LoginContext";

export default function DeviceLog() {
  //@ts-ignore
  const { language } = useContext(LanguageContext);
  const { user } = useAuth();
  const { count } = useAuth();
  const { data, isLoading } = useFetch(
    `/web/dashboard/data?type=${user.type}&org_idx=${user.org_idx}`,
    user.isRefresh
      ? {
          revalidateOnMount: true,
          refreshInterval: Number(user.refreshPeriod) * 1000,
          revalidateOnFocus: false,
        }
      : {
          revalidateOnMount: true,
          revalidateOnFocus: false,
        }
  );
  return (
    <>
      <DasMain
        language={language}
        user={user}
        count={count}
        data={data}
        isLoading={isLoading}
        // tableRef={tableRef}
        // excelDown={excelDown}
      />
      <Outlet />
    </>
  );
}
