export function event(code, lang){
    const event_content = {
        '07' : '과속이 감지되었습니다.',
        '08' : '즉시 보고',
        '09' : '긴급 SOS!!',
        '10' : 'zone에 진입하였습니다.',
        '11' : 'zone에서 이탈하였습니다.',
        '15' : '장치에 연결되었습니다.',
        '16' : '장치에서 분리되었습니다.',
        '17' : '내장 배터리 저전압',
        '18' : '차량 배터리 저전압',
        '20' : '문/보닛 열림',
        '21' : '문/보닛 닫힘',
        '30' : '(HTTP)페이지가 없습니다.',
        '31' : '(HTTP)페이지 내 충돌 발생',
        '32' : '(HTTP)요청이 잘못되었습니다.',
        '33' : '(HTTP)권한이 없습니다.',
        '34' : '(HTTP)요청시간이 초과되었습니다.',
        '35' : '(HTTP)내부 서버 오류 발생',
        '99' : 'Push 테스트!',
        'LS' : '위치를 공유하였습니다.'
    }
    const event_content_eng = {
        '07' : 'Speeding detected..',
        '08' : 'Immediately report!!',
        '09' : 'Emergency SOS!!',
        '10' : 'You have entered zone',
        '11' : 'You have moved away from zone',
        '15' : 'Connected to device.',
        '16' : 'Disconnected from device.',
        '17' : 'Device Battery low',
        '18' : 'Vehicle Battery low',
        '20' : 'Door/Bonnet open',
        '21' : 'Door/Bonnet close',
        '30' : '(HTTP)NOT FOUND',
        '31' : '(HTTP)ALREADY EXISTS',
        '32' : '(HTTP)INVALID BODY',
        '33' : '(HTTP)AUTHENTICATION FAILED',
        '34' : '(HTTP)TIME OUT',
        '35' : '(HTTP)INTERNAL ERROR',
        '99' : 'Push Test!',
        'LS' : 'You have shared your location'
    }
    return (lang === 'KOR' ? (event_content[code] || '') : (event_content_eng[code] || ''));
}

export const code = ['01','02','03','04','05'];