import React, { useState, useEffect } from "react";
import Radio from "../../../../Atomic/atoms/Radio";
import Swal from "sweetalert2";
//@ts-ignore
import { LanguageContext } from "../../../../../context/LanguageContext.jsx";
//@ts-ignore
import { useAuth } from "../../../../../context/LoginContext";
//@ts-ignore
import axios from "../../../../../lib/axios.js";
//@ts-ignore
import { dataList } from "../../../AdmMain";
import CommonControl from "../../../../Atomic/organism/CommonControl";
import Page from "../../../../Atomic/template/PageTable";
import TelInput from "../../../../Atomic/atoms/Tel_Input";
// @ts-ignore
import Email from "../../../../Atomic/atoms/Email.jsx";
import Select from "../../../../Atomic/molecules/Select";
import { useDaumPostcodePopup } from "react-daum-postcode";
// @ts-ignore
import { checkHp } from '../../../../../lib/common_func.js';

export default function Form() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  //@ts-ignore
  const { user } = useAuth();
  const postRef = React.useRef();
  const mainAddrRef = React.useRef();
  const subAddrRef = React.useRef();
  const open = useDaumPostcodePopup(
    "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );
  const refId = React.useRef<HTMLInputElement>(null);
  // State defines
  //=======================================================================
  const [isHead, setIsHead] = useState<any>(true);
  const [CPN, setCPN] = useState<any>("");
  const [CPNData, setCPNData] = useState<any>([]);
  const [companyData, setCompanyData] = useState<any>([]);
  const [isChanged, setIsChanged] = useState<any>(true);
  const [idReadOnly, setIdReadOnly] = useState(false);
  const [rawData, setRawData] = useState<any>({
    idx: user.idx,
    org_type: "",
    org_nm: "",
    org_cd: "",
    cmp_idx: null,
    brc_idx: null,
    agt_idx: null,
    org_adm_nm: "",
    hp: "",
    postnum: "",
    addr: "",
    addr_dt: "",
    tel: "",
    bizno: "",
    email: "",
    fax: "",
    d_control_yn: "N",
    login_set_yn: "N",
    act_yn: "Y",
    sso_yn: "N",
    type: "ADM",
  });
  const [skci, setSkci] = useState<any>({});
  const [codeIdx, setCodeIdx] = useState<any>(null);
  //=======================================================================
  // Function defines
  function checkID() {
    if(idReadOnly) return;
    if (refId.current?.value === "") {
      Swal.fire({
        title: "회사코드를 입력해주세요.",
      });
      return;
    }
    axios
      .post("/web/admin/org/check", {
        type: "ADM",
        org_cd: refId.current?.value,
      })
      .then((res: any) => {
        if (res.data === "N") {
          setIdReadOnly(true);
          setRawData((pre: any) => ({ ...pre, org_cd: refId.current?.value }));
          Swal.fire({
            title: "사용 가능한 코드입니다.",
          });
        } else {
          Swal.fire({
            title: "사용 불가능한 ID입니다.",
          });
          setIdReadOnly(false);
          refId.current?.focus();
        }
      });
  }
  function clearID() {
    setRawData((pre: any) => ({ ...pre, org_cd: "" }));
    refId.current!.value = "";
    setIdReadOnly(false);
  }
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const handleComplete = (data: any) => {
    var fullAddr = ""; // 최종 주소 변수
    var extraAddr = ""; // 조합형 주소 변수
    if (data.userSelectedType === "R") {
      fullAddr = data.roadAddress;
    } else {
      fullAddr = data.jibunAddress;
    }
    if (data.userSelectedType === "R") {
      if (data.bname !== "") {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? " (" + extraAddr + ")" : "";
    }
    if (postRef.current) {
      (postRef.current as HTMLInputElement).value = data.zonecode;
    }
    if (mainAddrRef.current) {
      (mainAddrRef.current as HTMLInputElement).value = fullAddr;
    }
    if (subAddrRef.current) {
      (subAddrRef.current as HTMLInputElement).focus();
    }
    setRawData((prev: any) => ({
      ...prev,
      postnum: data.zonecode,
      addr: fullAddr,
    }));
  };
  const handleClick = () => {
    //@ts-ignore
    open({ onComplete: handleComplete });
  };
  //=======================================================================
  const faxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp: string[] = rawData.fax.split("-");

    switch (name) {
      case "fax1":
        temp[0] = value;
        break;
      case "fax2":
        temp[1] = value;
        break;
      case "fax3":
        temp[2] = value;
        break;
      default:
        break;
    }

    if (temp.some((segment) => segment === "0000")) {
      alert("0000은 입력할 수 없습니다.");
      temp = temp.map((segment) => (segment === "0000" ? "" : segment));
    }

    setRawData((prev: any) => ({ ...prev, fax: temp.join("-") }));
  };
  const hpHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp: string[] = rawData.hp.split("-");

    switch (name) {
      case "hp1":
        temp[0] = value;
        break;
      case "hp2":
        temp[1] = value;
        break;
      case "hp3":
        temp[2] = value;
        break;
      default:
        break;
    }

    if (temp.some((segment) => segment === "0000")) {
      alert("0000은 입력할 수 없습니다.");
      temp = temp.map((segment) => (segment === "0000" ? "" : segment));
    }

    setRawData((prev: any) => ({ ...prev, hp: temp.join("-") }));
  };
  const TelHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp: string[] = rawData.tel.split("-");

    switch (name) {
      case "tel1":
        temp[0] = value;
        break;
      case "tel2":
      case "tel3":
        const index = parseInt(name.charAt(name.length - 1)) - 1;
        temp[index] = value;

        if (temp[index] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[index] = "";
        }
        break;
      default:
        break;
    }

    setRawData((prev: any) => ({ ...prev, tel: temp.join("-") }));
  };
  //=======================================================================

  function inputHandlerIDX(e: any) {
    const { name, value } = e.target;
    setRawData((pre: any) => ({ ...pre, [name]: parseInt(value) }));
  }
  function inputHandler(e: any) {
    const { name, value } = e.target;
    if( name === 'cmp_nm') {
      setCPN(value);
      if (value === "") {
        setCPNData([]);
        return;
      }
      const filteredData = companyData.filter((item: any) => {
        // return Object.values(item).some(
        //   (val) => typeof val=== "string" && val.includes(value)
        // );
        if(rawData.org_type === "A") {
          return item.org_type === "B" && typeof item.brc_nm === "string" && (item.cmp_nm.includes(value) || item.brc_nm.includes(value));
        } else {
          return item.org_type === "C" && typeof item.cmp_nm === "string" && item.cmp_nm.includes(value);
        }
      });
      setCPNData(filteredData);
    } else {
      setRawData((pre: any) => ({ ...pre, [name]: value }));
    }
  }
  function selectHandler(e: any) {
    clearID();
    setRawData((pre: any) => ({...pre, cmp_idx:null, brc_idx:null, agt_idx:null, org_nm: ''}));
    clearinput();
    if(e.target.value === 'B' || e.target.value === 'A') {
      setRawData((prev:any) => ({...prev, login_set_yn:'N', sso_yn:'N'}))
      setIsHead(false);
      setIdReadOnly(true);
    } else {
      setIsHead(true);
    }
    if (e.target.value === "ID") {
      setRawData((pre: any) => ({ ...pre, btoc_yn: "Y", org_type: "C" }));
    } else {
      setRawData((pre: any) => ({
        ...pre,
        btoc_yn: "N",
        org_type: e.target.value,
      }));
    }
  }
  function createNew() {
    if (rawData.org_type === "C" && rawData.btoc_yn === "N") {
      if (!rawData.I_IDX1) {
        Swal.fire({
          title: `${translate(
            "업종(대분류)을 선택해주세요.",
            "Please select type 1"
          )}`,
        });
        return;
      }
      if (!rawData.I_IDX2) {
        Swal.fire({
          title: `${translate(
            "업종(소분류)을 선택해주세요.",
            "Please select type 2"
          )}`,
        });
        return;
      }
    }
    if (rawData.org_type === "" || rawData.org_type === undefined) {
      Swal.fire({
        title: `${translate("조직구분을 선택해주세요.", "Please select type")}`,
      });
      return;
    }
    if (rawData.org_adm_nm === "" || rawData.org_adm_nm === undefined) {
      Swal.fire({
        title: `${translate("관리자명을 입력해주세요.", "Please enter admin name")}`,
      });
      return;
    }
    if (rawData.org_nm === "" || rawData.org_nm === undefined) {
      Swal.fire({
        title: `${translate("조직명을 입력해주세요.", "Please enter org name")}`,
      });
      return;
    }
    if (!rawData.hp || !checkHp(rawData.hp)) {
      Swal.fire({
        title: `${translate(
          "휴대폰 번호를 입력해주세요.",
          "Please enter mobile"
        )}`,
      });
      return;
    }
    if (rawData.org_cd === "" || rawData.org_cd === undefined) {
      Swal.fire({
        title: `${translate("회사코드를 입력해주세요.", "Please enter Com code")}`,
      });
      return;
    }
    if (rawData.postnum === "" || rawData.postnum === undefined) {
      Swal.fire({
        title: `${translate(
          "우편번호를 검색해주세요.",
          "Please search postnum"
        )}`,
      });
      return;
    }
    if (rawData.addr === "" || rawData.addr === undefined) {
      Swal.fire({
        title: `${translate("주소를 입력해주세요.", "Please enter address")}`,
      });
      return;
    }

    Swal.fire({
      title: `${translate("등록하시겠습니까?", "Do you want to register?")}`,
      showCancelButton: true,
      confirmButtonText: `${translate("등록", "Register")}`,
      cancelButtonText: `${translate("취소", "Cancel")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post("/web/admin/org/add", {
          ...rawData, 
          I_IDX1: rawData.I_IDX1 ? rawData.I_IDX1 : 0,
          I_IDX2: rawData.I_IDX2 ? rawData.I_IDX2 : 0,
          companyData: {
            cmp_idx: rawData.cmp_idx,
            brc_idx: rawData.brc_idx,
            agt_idx: rawData.agt_idx,
          }
        }).then((res: any) => {
          if (res.status === 200) {
            Swal.fire({
              title: `${translate("등록되었습니다.", "Created")}`,
            }).then((result) => {
              window.history.back();
            });
          } else {
            Swal.fire({
              title: `${translate("등록에 실패하였습니다.", "Failed")}`,
            });
          }
        });
      }
    });
  }
  function resetOrg() {
    //@ts-ignore
    setRawData((prev : any) => ({
      ...prev,
      agt_idx: null,
      brc_idx: null,
      cmp_idx: null,
      org_cd: "",
    }))
    setCPN("");
    refId.current!.value = "";
  }
  function clearinput() {
    setIsChanged(true);
    setCPN("");
    setCPNData([]);
  }
  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "white",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },

    {
      labelKR: "등록",
      labelEN: "Create",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];
  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    axios.get(`/web/category/ksci?type=ADM`).then((res: any) => {
      setSkci(res.data);
    });
    axios.get(`/web/admin/org/name?type=ADM`)
    .then((res: any) => {
      if(res.status === 200) {
        setCompanyData(res.data);
      }
    })
    .catch((err: any) => {
      console.log('err : ', err);
    })
  }, []);
  useEffect(() => {
    if (rawData.I_IDX1 !== null) {
      setCodeIdx(rawData.I_IDX1);
    } else {
      setCodeIdx(0);
    }
  }, [rawData, skci]);
  // Log defines
  //=======================================================================
  const liStyle = {
    padding: "10px",
    width: "100%",
    cursor: "pointer",
    borderBottom: "1px solid gray",
  };
  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "조직 관리" : "ORG management"}`}
        menu="/Adm/o-table"
      >
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th className="required">
                {language === "KOR" ? "조직구분" : "Org type"}
              </th>
              <td>
                <div className="custom-select">
                  <select
                    name="org_type"
                    id="org_type"
                    defaultValue={rawData.org_type}
                    onChange={(e: any) => selectHandler(e)}
                  >
                    <option value="">
                      {translate("선택 조직구분", "Slect type")}
                    </option>
                    <option value="C">{translate("회사", "Company")}</option>
                    <option value="B">{translate("지점", "Branch")}</option>
                    <option value="A">{translate("대리점", "Agancy")}</option>
                    <option value="ID">
                      {translate("개인", "Individual")}
                    </option>
                  </select>
                </div>
              </td>
              <th className="required">
                {language === "KOR" ? "관리자명" : "Admin name"}
              </th>
              <td>
                <div className="custom-input">
                  <input
                    id="org_adm_nm"
                    name="org_adm_nm"
                    type="text"
                    onChange={inputHandler}
                    value={rawData.org_adm_nm}
                    placeholder={
                      language === "KOR"
                        ? "관리자명을 입력해주세요."
                        : "Please enter your admin name."
                    }
                  />
                </div>
              </td>
            </tr>

            <tr>
              {
                isHead ? 
                  (
                    <>
                      <th className="required">
                        {language === "KOR" ? "조직명" : "Org name"}
                      </th>
                      <td>
                        <div className="custom-input">
                          <input
                            id="org_nm"
                            name="org_nm"
                            type="text"
                            onChange={inputHandler}
                            value={rawData.org_nm}
                            placeholder={
                              language === "KOR"
                                ? "조직명을 입력해주세요."
                                : "Please enter company name."
                            }
                            autoComplete="off"
                          />
                        </div>
                      </td>
                    </>
                  )
                  :
                  (
                    <>
                      <th className="required">
                        {language === "KOR" ? "상위 조직" : "Upper Org."}
                      </th>
                      <td>
                        <div className="flex">
                          <div className="custom-input wide">
                            <input
                              id="cmp_nm"
                              name="cmp_nm"
                              type="text"
                              onChange={inputHandler}
                              value={CPN || ""}
                              placeholder={
                                language === "KOR"
                                  ? "조직을 선택해주세요."
                                  : "Please select org."
                              }
                              readOnly={!isChanged}
                              autoComplete="off"
                            />
                          </div>
                          <button
                            type="button"
                            className="common-btn white sm"
                            onClick={() => {
                              resetOrg()
                              clearinput()
                            }}
                          >
                            <span>{translate("초기화", "Reset")}</span>
                          </button>
                        </div>
                        {isChanged && CPNData.length > 0 ? (
                            <ul
                              className="search-list"
                              style={{
                                position: "absolute",
                                backgroundColor: "white",
                                zIndex: 100,
                                width: "25%",
                                marginTop: "5px",
                                maxHeight: "200px",
                                overflow: "scroll",
                                border: "1px solid #cccc",
                                overflowX: "hidden",
                              }}
                            >
                              {CPNData.map((item: any, index: any) => (
                                <li
                                  style={liStyle}
                                  key={index}
                                  onClick={() => {
                                    setCPN(
                                      `${
                                        item.org_type === "C"
                                          ? translate("[회사]", "[Company]")
                                          : item.org_type === "B"
                                          ? translate("[지점]", "[Branch]")
                                          : item.org_type === "A"
                                          ? translate("[대리점]", "[Agent]")
                                          : ""
                                      } ${item.cmp_nm}${
                                        item.brc_nm === null ? "" : " > "
                                      }${item.brc_nm === null ? "" : item.brc_nm}${
                                        item.agt_nm === null ? "" : " > "
                                      }${item.agt_nm === null ? "" : item.agt_nm}`
                                    );
                                    setRawData((prev: any) => ({
                                      ...prev,
                                      agt_idx: item.agt_idx,
                                      brc_idx: item.brc_idx,
                                      cmp_idx: item.cmp_idx,
                                      org_cd: item.org_cd,
                                    }));
                                    setIsChanged(false);
                                    refId.current!.value = item.org_cd;
                                  }}
                                >
                                  {item.org_type === "C"
                                    ? translate("[회사]", "[Company]")
                                    : item.org_type === "B"
                                    ? translate("[지점]", "[Branch]")
                                    : item.org_type === "A"
                                    ? translate("[대리점]", "[Agent]")
                                    : ""}
                                  {` ${item.cmp_nm}`}
                                  {item.brc_nm === null ? "" : ` > ${item.brc_nm}`}
                                  {item.agt_nm === null ? "" : ` > ${item.agt_nm}`}
                                  {` (회사코드 : ${item.org_cd})`}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            ""
                          )}
                        </td>
                    </>
                  )
              }
              <th className="required">
                {language === "KOR" ? "회사코드" : "Com code"}
              </th>
              <td>
                <div className="flex">
                  <div className="custom-input">
                    <input
                      name="id"
                      type="text"
                      // onChange={inputHandler}
                      ref={refId}
                      // value={rawData.id}
                      defaultValue={rawData.org_cd}
                      readOnly={idReadOnly === true ? true : false}
                      autoComplete="off"
                    />
                  </div>
                  {
                    isHead && (
                      <>
                        <button
                          type="button"
                          className="common-btn purple sm"
                          onClick={(e) => checkID()}
                        >
                          <span>{translate("중복확인", "Check ID")}</span>
                        </button>
                        <button
                          type="button"
                          className="common-btn white  sm"
                          onClick={() => clearID()}
                        >
                          <span>{translate("초기화", "Reset")}</span>
                        </button>
                      </>
                    )
                  }
                </div>
              </td>
            </tr>
            {
              !isHead && (
                <tr>
                  <th className="required">
                    {language === "KOR" ? "조직명" : "Org name"}
                  </th>
                  <td colSpan={3}>
                      <div className="flex">
                        <div className="custom-input">
                          <input
                            id="org_nm"
                            name="org_nm"
                            type="text"
                            onChange={inputHandler}
                            value={rawData.org_nm}
                            placeholder={
                              language === "KOR"
                              ? "조직명을 입력해주세요."
                              : "Please enter company name."
                            }
                            autoComplete="off"
                            />
                        </div>
                      </div>
                  </td>
                </tr>
              )
            }
            <tr>
              <th className="required">
                {language === "KOR" ? "휴대폰 번호" : "Mobile"}
              </th>
              <td>
                <div className="flex">
                  <TelInput
                    id="hp1"
                    name="hp1"
                    value={rawData.hp === null ? "" : rawData.hp.split("-")[0]}
                    changeFun={hpHandler}
                    readOnly={false}
                    place="010"
                    max={3}
                    req={3}
                  />
                  -
                  <TelInput
                    id="hp2"
                    name="hp2"
                    value={rawData.hp === null ? "" : rawData.hp.split("-")[1]}
                    changeFun={hpHandler}
                    place="0000"
                    max={4}
                    req={4}
                  />
                  -
                  <TelInput
                    id="hp3"
                    name="hp3"
                    value={rawData.hp === null ? "" : rawData.hp.split("-")[2]}
                    changeFun={hpHandler}
                    place="0000"
                    max={4}
                    req={4}
                  />
                </div>
              </td>
              <th>{language === "KOR" ? "직통번호" : "Direct tel"}</th>
              <td>
                <div className="flex">
                  <TelInput
                    id="tel1"
                    name="tel1"
                    value={
                      rawData.tel === null ? "" : rawData.tel.split("-")[0]
                    }
                    changeFun={TelHandler}
                    readOnly={false}
                    place="010"
                    max={3}
                    req={2}
                  />
                  -
                  <TelInput
                    id="tel2"
                    name="tel2"
                    value={
                      rawData.tel === null ? "" : rawData.tel.split("-")[1]
                    }
                    changeFun={TelHandler}
                    place="0000"
                    max={4}
                    req={3}
                  />
                  -
                  <TelInput
                    id="tel3"
                    name="tel3"
                    value={
                      rawData.tel === null ? "" : rawData.tel.split("-")[2]
                    }
                    changeFun={TelHandler}
                    place="0000"
                    max={4}
                    req={4}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>{language === "KOR" ? "팩스번호" : "Fax"}</th>
              <td>
                <div className="flex">
                  <TelInput
                    id="fax1"
                    name="fax1"
                    value={
                      rawData.fax === undefined ? "" : rawData.fax.split("-")[0]
                    }
                    changeFun={faxHandler}
                    place="000"
                    max={3}
                    req={2}
                  />
                  -
                  <TelInput
                    id="fax2"
                    name="fax2"
                    value={
                      rawData.fax === undefined ? "" : rawData.fax.split("-")[1]
                    }
                    changeFun={faxHandler}
                    place="0000"
                    max={4}
                    req={3}
                  />
                  -
                  <TelInput
                    id="fax3"
                    name="fax3"
                    value={
                      rawData.fax === undefined ? "" : rawData.fax.split("-")[2]
                    }
                    changeFun={faxHandler}
                    place="0000"
                    max={4}
                    req={4}
                  />
                </div>
              </td>
              <th>E-mail</th>
              <td>
                <Email
                  id="email"
                  name="email"
                  value={rawData.email}
                  setRawData={setRawData}
                />
              </td>
            </tr>
            <tr>
              <th className="required" rowSpan={3}>
                {language === "KOR" ? "주소" : "Addr"}
              </th>
              <td rowSpan={3}>
                <div className="address">
                  <div className="row">
                    <div className="custom-input">
                      <input
                        id="postnum"
                        name="postnum"
                        type="text"
                        readOnly
                        ref={postRef as any}
                        value={rawData.postnum}
                        onChange={(e) => {
                          console.log(e.target.value);
                        }}
                      />
                    </div>
                    <button
                      onClick={handleClick}
                      type="button"
                      className="common-btn white sm"
                    >
                      <span>
                        {language === "KOR" ? "우편번호 검색" : "Addr search"}
                      </span>
                    </button>
                  </div>
                  <div className="row">
                    <div className="custom-input full">
                      <input
                        id="addr"
                        name="addr"
                        type="text"
                        ref={mainAddrRef as any}
                        value={rawData.addr}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="custom-input full">
                      <input
                        id="addr_dt"
                        name="addr_dt"
                        type="text"
                        ref={subAddrRef as any}
                        onChange={inputHandler}
                        value={rawData.addr_dt}
                        placeholder={
                          language === "KOR"
                            ? "상세주소를 입력해주세요."
                            : "Please enter your detailed address."
                        }
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                {language === "KOR"
                  ? "사업자 등록번호"
                  : "Official biz reg num"}
              </th>
              <td>
                <div className="custom-input">
                  <input
                    name="bizno"
                    type="text"
                    onChange={inputHandler}
                    value={rawData.bizno}
                    maxLength={10}
                    placeholder={
                      language === "KOR"
                        ? "사업자 등록번호를 입력해주세요."
                        : "Please enter your official biz reg num."
                    }
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>
                {language === "KOR"
                  ? "제어버튼 기능"
                  : "Geo control button function"}
              </th>
              <td>
                <div className="custom-checkbox">
                  <div className="custom-check-group">
                    <Radio
                      name={"d_control_yn"}
                      val={"Y"}
                      title={translate("사용", "Use")}
                      change={(e) => inputHandler(e)}
                      check={rawData.d_control_yn === "Y" ? true : false}
                    />
                    <Radio
                      name={"d_control_yn"}
                      val={"N"}
                      title={translate("미사용", "Not Use")}
                      change={(e) => inputHandler(e)}
                      check={rawData.d_control_yn === "N" ? true : false}
                    />
                  </div>
                </div>
              </td>
            </tr>
            {
              isHead && (
                <tr>
                  <th>
                    {language === "KOR"
                      ? "로그인 화면설정 기능	"
                      : "Login screen setting function"}
                  </th>
                  <td>
                    <div className="custom-checkbox">
                      <div className="custom-check-group">
                        <Radio
                          name={"login_set_yn"}
                          val={"Y"}
                          title={translate("사용", "Use")}
                          change={(e) => inputHandler(e)}
                          check={rawData.login_set_yn === "Y" ? true : false}
                        />
                        <Radio
                          name={"login_set_yn"}
                          val={"N"}
                          title={translate("미사용", "Not Use")}
                          change={(e) => inputHandler(e)}
                          check={rawData.login_set_yn === "N" ? true : false}
                        />
                      </div>
                    </div>
                  </td>
                  <th>
                    {language === "KOR" ? "sso 로그인 기능" : "sso login function"}
                  </th>
                  <td>
                    <div className="custom-checkbox">
                      <div className="custom-check-group">
                        <Radio
                          name={"sso_yn"}
                          val={"Y"}
                          title={translate("사용", "Use")}
                          change={(e) => inputHandler(e)}
                          check={rawData.sso_yn === "Y" ? true : false}
                        />
                        <Radio
                          name={"sso_yn"}
                          val={"N"}
                          title={translate("미사용", "Not Use")}
                          change={(e) => inputHandler(e)}
                          check={rawData.sso_yn === "N" ? true : false}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )
            }
            <tr>
              <th className={rawData?.org_type === "C" && rawData?.btoc_yn === "N" ? "required" : ""}>
                {language === "KOR"
                  ? "업종(대분류)"
                  : "Industry (large category)"}
              </th>
              <td>
                <Select
                  id="I_IDX1"
                  short={"N"}
                  name="I_IDX1"
                  options={
                    skci.first !== undefined
                      ? [
                          { name: `${translate("선택", "Select")}`, value: 0 },
                          ...skci.first.map((item: any) => ({
                            name: item.nm_kor,
                            value: parseInt(item.idx),
                          })),
                        ]
                      : []
                  }
                  changeFun={(e) => inputHandlerIDX(e)}
                  def={rawData.I_IDX1 || ""}
                />
              </td>

              <th className={rawData?.org_type === "C" && rawData?.btoc_yn === "N" ? "required" : ""}>
                {language === "KOR"
                  ? "업종(소분류)"
                  : "Type of business (sub-classification)"}
              </th>
              <td>
                <Select
                  id="I_IDX2"
                  short={"N"}
                  name="I_IDX2"
                  options={
                    skci.second !== undefined
                      ? [
                          { name: `${translate("선택", "Select")}`, value: "" },
                          ...skci.second
                            .filter(
                              (item: any) =>
                                item.CATEGORY_IDX === parseInt(codeIdx)
                            )
                            .map((item: any) => ({
                              name: item.nm_kor,
                              value: item.idx,
                            })),
                        ]
                      : []
                  }
                  changeFun={(e) => inputHandlerIDX(e)}
                  def={rawData.I_IDX2 || ""}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
