import React from "react";
interface Props {
  id: string;
  short: string;
  name: string;
  options: Array<any>;
  def?: any;

  changeFun: (e: any) => void;
}
export default function Select({
  id,
  short,
  changeFun,
  options,
  name,
  def,
}: Props) {
  const style = {
    minWidth: "12rem",
    width: "100%",
  };

  return (
    <div
      style={style}
      className={"custom-select" + (short === "Y" ? " short" : "")}
    >
      <select id={id} onChange={changeFun} name={name} value={def}>
        {options.map((option, index) => {
          return (
            <option value={option.value} key={index}>
              {option.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}
