import React from 'react';
import Outer from './Outer';

export default function Wire() {
    window.scrollTo({top:0});
    return (
        <>
            <Outer type={'wire'}>
                <div class='content-part'>
                    {/* <!-- nav bar --> */}
                    <aside class="nav-container">
                        <div class="nav-lists">
                            <h3 class="nav-title">유선형 FAQ</h3>
                            <ul>
                                <li>
                                    <a href="#wire" class="nav-link">
                                        유선형 제품 안내
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer1" class="nav-link">
                                        위치가 변경되지 않을 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer2" class="nav-link">
                                        각 LED의 의미
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer3" class="nav-link">
                                        A/S 택배 보내는 주소
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer4" class="nav-link">
                                        단말기에서 소리가 날 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer5" class="nav-link">
                                        관제가 안 보일 때(이전)
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer6" class="nav-link">
                                        단말기는 정상, 관제가 이상할 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer7" class="nav-link">
                                        설치 방법 (배선 관련)
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer8" class="nav-link">
                                        유심을 교체할 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer9" class="nav-link">
                                        단말기 전원이 안 들어올 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer10" class="nav-link">
                                        신형/구형 커넥터 구분 방법
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer11" class="nav-link">
                                        GPS 위치가 다를 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer12" class="nav-link">
                                        km 단위로 차이가 날 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer13" class="nav-link">
                                        장기 미관제 알림이 뜰 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer14" class="nav-link">
                                        운행 기록 보관 기간
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    {/* <!-- content --> */}
                    <section class="main-container">
                        {/* <!-- 문의 0 --> */}
                        <article id="wire">
                            <div class="article-title">
                                유선형 제품 안내
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/device_wire_front.png" alt="iotplex 4guard gps wire model front image" class="img-50" />
                                <img src="../../assets/img/Faq/device_wire_back.png" alt="iotplex 4guard gps wire model back image" class="img-50" />
                            </div>
                            <div class="article-point">
                                <p class="red-700">
                                    유심 커넥터, 유심
                                </p>
                                <p class="normal-500">
                                    유심 카드를 통해 LTE 통신을 하고 이를 이용하여 SMS 신호를 보내 원격으로 제어가 가능합니다.
                                </p>
                                <p class="blue-700">
                                    전원 스위치
                                </p>
                                <p class="normal-500">
                                    단말기 전원을 제어합니다.
                                </p>
                                <p class="green-700">
                                    LED
                                </p>
                                <p class="normal-500">
                                    3개의 LED가 있습니다. 각 동작 상태를 나타냅니다.
                                </p>
                                <p class="blue-700">
                                    전원 커넥터
                                </p>
                                <p class="normal-500">
                                    전원 커넥터를 통해 차량에서 전원을 공급받아 단말기를 동작시킵니다.
                                </p>
                                <p class="red-700">
                                    USB 5핀
                                </p>
                                <p class="normal-500">
                                    충전 시 사용합니다.
                                </p>
                                <p class="green-700">
                                    GPS 안테나
                                </p>
                                <p class="normal-500">
                                    GPS 위성 신호를 받는 GPS 안테나입니다. GPS 안테나가 차량 바깥쪽을 보게 해야 GPS 성능이 올라갑니다.
                                </p>
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/device_wire_box.png" alt="iotplex 4guard gps wire model box image" class="img-50" />
                                <img src="../../assets/img/Faq/device_wire_box_open.png" alt="iotplex 4guard gps wire model box open image" class="img-50" />
                            </div>
                        </article>
                        {/* <!-- 문의 1 --> */}
                        <article id="answer1">
                            <div class="article-title">
                                Q1. 위치가 변경되지 않을 때
                            </div>
                            {/* <!-- 유튜브 모달 버튼 들어갈 위치
                            <Link to="#" class="article-modal">
                            <p class="modal-txt">LED</p>
                            <img src="../../assets/img/Faq/youtube.png" alt="youtube logo" class="icon">
                            </a> --> */}
                            {/* <!-- 유튜브 반응형 영상 추가할 경우
                            <div class="youtube-embed-container">
                            <iframe width="560" height="315" src="유튜브 주소" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="youtube-embed"></iframe>
                            </div> --> */}
                            <div class="h2-bold">
                                통신료 미납으로 인한 정지인지 확인 후, 미납이 아닐 때
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/gps.png" alt="iotplex 4guard gps wire time error" class="img-30" />
                            </div>
                            <p class="article-bold">
                                1. 날짜가 일치하는 경우
                            </p>
                            <div class="article-txt">
                                <p>GPS 안테나 위치를 확인합니다.</p>
                                <p>위성을 통해 GPS 신호를 수신 받으므로 GPS 안테나가 차량 바깥쪽을 향하도록 단말기를 설치해야 합니다.</p>
                            </div>
                            <p class="article-bold">
                                2. 날짜가 일치하지 않는 경우
                            </p>
                            <div class="article-txt">
                                <p>단말기 LED 동작 상태를 확인합니다.</p>
                                <p>날짜가 일치하지 않는 경우 통신 문제일 확률이 높으며, LED 중간의 노란불이 계속 깜빡이거나
                                불이 안 들어오는 경우 통신이 되지 않아 데이터를 확인하지 못하는 경우입니다.</p>
                            </div>
                            <p class="article-bold">
                                3. 통신 모뎀에 이상이 있는 경우
                            </p>
                            <div class="article-txt">
                                <p>단말기 내부 통신 모뎀에 문제가 생겨 통신이 안되는 상황입니다.</p>
                            </div>
                            <p class="article-bold">
                                4. 유심이 휘어진 경우
                            </p>
                            <div class="article-txt">
                                <p>유심 금속면이 단말기 바닥면에 접촉되어야 통신이 가능하나, 유심이 아치 모양으로 휘어져 바닥면에 접촉이 되지 않아 통신이 불가한 경우입니다.</p>
                            </div>                 
                            <div class="article-point">
                                <p class="normal-500">유심이 휘어졌을 경우 유심을 발송하여 교체하는 방법과 단말기를 보내셔서 점검과 함께 유심을 교체하는 방법 2가지로 나뉩니다. 통신 모뎀에 이상이 있을 경우 무조건 A/S를 보내 통신 모뎀 교체가 필요합니다.</p>
                                <div class="article-point-img">
                                    <figure>
                                        <img src="../../assets/img/Faq/device_usim_wrong.png" alt="iotplex 4guard gps usim image" />
                                        <figcaption>유심이 휘어진 경우</figcaption>
                                    </figure>
                                    <figure>
                                        <img src="../../assets/img/Faq/device_usim_norm.png" alt="iotplex 4guard gps usim image" />
                                        <figcaption>유심이 정상인 경우</figcaption>
                                    </figure>
                                </div>
                                <p class="normal-500">유심이 휘어진 경우 접촉이 불안정해져 통신이 되지 않거나 통신이 되더라도 연결이 끊어졌다 되었다 할 수 있습니다.</p>
                            </div>
                            <p class="article-bold">
                                💎유심 교체 방법
                            </p>
                            <div class="article-point">
                                <p class="blue-700">1) 공유심(정보를 인식하기 전 유심)을 고객님께 발송, 원격으로 개통 및 정보 인식</p>
                                <p class="normal-500">🔔 특징 : 공유심을 받기 전까지 기존에 사용하던 유심을 이어서 사용하므로 관제에서 운행 기록을 지속적으로 확인할 수 있습니다. 다만 기존 유심에 문제가 있을 경우 통신이 끊어질 수 있어 큰 효과는 없습니다.</p>
                                <p class="normal-500">🔔 방법 : 전화로 주소를 불러주시면 해당 주소로 공유심을 보내드리며, 공유심 수령 후 단말기에 유심을 꽂아 전원을 켜고 전화주시면 원격으로 개통 처리가 가능하며 원격 개통은 약 10분 정도 소요됩니다.</p>
                                <p class="blue-700">2) 유심 자체를 변경한 후 고객님께 발송</p>
                                <p class="normal-500">🔔 특징 : 유심을 아예 변경한 후 발송, 수령 후 단말기에 유심을 꽂기 전까지는 운행 기록을 확인할 수 없습니다. 유심 교 체 후 테스트를 진행하여 보내드리므로 단말기 위치가 부산(본사 위치)으로 표시됩니다.</p>
                            </div>
                        </article>
                        <article id="answer2">
                            <div class="article-title">Q2. 각 LED의 의미</div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/led.png" alt="iotplex 4guard gps wire model led image" class="img-70" />
                            </div>
                            <div class="article-txt">
                                <p>정상적이라면 3개의 LED 깜빡임이 멈추고 20초 가량이 지나면 LED 불이 모두 꺼집니다.</p>
                                <p>LED 불이 꺼지지 않고 계속 깜빡이는 경우 LED가 켜진 부분에서 이상이 있는 경우입니다.</p>
                            </div>
                            <div class="article-point">
                                <p class="red-700">PWR : 빨간 불</p>
                                <p class="normal-500">차량과 전원 연결 시 불이 들어옵니다. 차량에서 전원을 공급하는 중이므로 깜빡이지 않습니다.</p>
                                <p class="green-700">MODEM : 초록 불</p>
                                <div class="normal-500">
                                    <p>LTE 통신 상태를 나타냅니다.</p>
                                    <p>깜빡이는 경우 서버로 데이터 전송이 되지 않아 운행 기록 확인이 불가한 경우입니다.</p>
                                </div>
                                <p class="blue-700">GPS : 파란 불</p>
                                <p class="normal-500">GPS 상태를 나타냅니다. 깜빡이는 경우 GPS와 연결이 되지 않은 상태입니다.</p>
                            </div>
                        </article>
                        <article id="answer3">
                            <div class="article-title">
                                Q3. A/S 택배 보내는 주소
                            </div>
                            <div class="h2-bold">회사 위치 및 주소</div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/map.png" alt="iotplex 4guard company map image" class="img-70" />
                            </div>
                            <div class="article-point">
                                <p class="normal-500">📫 주소 : 부산광역시 해운대구 센텀동로 99 벽산E센텀클래스원 601호 아이오티플렉스</p>
                                <p class="normal-500">🔗 수신인 : 아이오티플렉스</p>
                                <p class="normal-500">📞 연락처 : 010-2581-3333</p>
                            </div>
                        </article>
                        <article id="answer4">
                            <div class="article-title">
                                Q4. 단말기에서 소리가 날 때
                            </div>
                            <div class="article-txt">
                                <p>실드캔이 탈거되면 쇼트의 위험이 있습니다.</p>
                                <p>단말기에서 소리가 발생하면 차량과 단말기를 분리하신 후 A/S를 통한 점검이 필요합니다.</p>
                            </div>
                            <div class="article-point">
                                <div class="article-point-img">
                                    <figure>
                                    <img src="../../assets/img/Faq/device-sland-board.png" alt="iotplex 4guard device board" />
                                    <figcaption>내부 보드</figcaption>
                                    </figure>
                                    <figure>
                                    <img src="../../assets/img/Faq/device-sland-item.png" alt="iotplex 4guard gps can image" width="250px" />
                                    <figcaption>실드캔</figcaption>
                                    </figure>
                                </div>
                            </div>
                        </article>
                        <article id="answer5">
                            <div class="article-title">
                                Q5. 관제가 안 보일 때(이전)
                            </div>
                            <div class="article-img">
                                {/* <img src="../../assets/img/Faq/dash_old.png" alt="iotplex 4guard gps old site image" class="img-50" /> */}
                                <img src="../../assets/img/Faq/dash_new.png" alt="iotplex 4guard gps new site image"/>
                            </div>    
                            <div class="article-txt">
                                <p>단말기에 원격으로 신호를 보내서 신규 관제로 변경이 가능합니다.</p>
                                <p>원격으로 신호를 보낼 때는 반드시 단말기 전원이 켜져 있어야 하며, 간혹 오래된 단말기는 인식이 불가한 경우가 있습니다. 그럴 때는 제품을 보내주시면 제품 점검 및 관제 변경이 가능합니다.</p>
                            </div>
                        </article>
                        <article id="answer6">
                            <div class="article-title">
                                Q6. 단말기는 정상, 관제가 이상할 때
                            </div>
                            <div class="article-txt">
                                <p>택배 주소를 잘못 입력하면 택배가 엉뚱한 곳에 가는 것처럼, 단말기도 데이터를 엉뚱한 곳으로 보내면 단말기는 정상이더라도 운행 내역이 확인되지 않는 경우가 있습니다. 이러한 경우 원격으로 신호를 보내 정상적으로 사용할 수 있습니다. 만약 원격 신호를 보냈음에도 상태 변화가 없다면 제품을 보내셔서 A/S 및 점검을 받으시기 바랍니다.</p>
                            </div>
                        </article>
                        <article id="answer7">
                            <div class="article-title">
                                Q7. 설치 방법 (배선 관련)
                            </div>
                            <div class="article-point">
                                <p class="normal-700">하얀 선</p>
                                <p class="normal-500">VCC (상시 전원, 차량 시동을 걸지 않아도 동작하는 곳)</p>
                                <p class="red-700">붉은 선</p>
                                <p class="normal-500">ACC (차량 시동을 걸어야 동작하는 곳)</p>
                                <p class="green-700">녹색 선(검은 선)</p>
                                <p class="normal-500">GND (그라운드)</p>
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/car_install.png" alt="iotplex 4guard gps install image" class="img-50" />
                                <img src="../../assets/img/Faq/car-install-2.png" alt="iotplex 4guard gps install open image" class="img-50" />
                            </div>
                            <div class="article-bold">
                                녹색 선(검은 선)을 까서 그라운드에 선을 감습니다.
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/car-install-3.png" alt="iotplex 4guard gps install image" class="img-50" />
                                <img src="../../assets/img/Faq/car-install-4.png" alt="iotplex 4guard gps install open image" class="img-50" />
                            </div>
                            <div class="article-bold">
                                각 선에 맞는 퓨즈를 확인하고 퓨즈를 뽑습니다.
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/car-install-5.png" alt="iotplex 4guard gps install image" class="img-50" />
                                <img src="../../assets/img/Faq/car-install-6.png" alt="iotplex 4guard gps install open image" class="img-50" />
                            </div>
                            <div class="article-bold">
                                색상에 맞춰 선을 까서 퓨즈에 선을 감아주고, 원래 자리에 다시 꽂아줍니다.
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/car-install-7.png" alt="iotplex 4guard gps install image" class="img-50" />
                                <img src="../../assets/img/Faq/car-install-8.png" alt="iotplex 4guard gps install open image" class="img-50" />
                            </div>
                            <div class="article-bold">
                                단말기를 연결한 후 퓨즈박스에 단말기를 넣어줍니다.
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/car-install-9.png" alt="iotplex 4guard gps install image" class="img-70" />
                            </div>
                            <div class="article-txt">이 때 선 정리가 필요한 경우 케이블 타이 등으로 정리하시고, GPS 안테나 부분이 차량 바깥쪽을 향하도록 설치 바랍니다.</div>
                        </article>
                        <article id="answer8">
                            <div class="article-title">
                                Q8. 유심을 교체할 때
                            </div>
                            <div class="article-img">
                                <figure>
                                    <img src="../../assets/img/Faq/usim-take-out.png" alt="iotplex 4guard gps usim introduce" class="img-70" />
                                    <figcaption>눌러서 딸깍 하고 빼는 형식이 아닙니다.</figcaption>
                                </figure>
                            </div>
                            <div class="article-point">
                                <div class="normal-500">
                                    <p>🔔 유심을 꽂으실 때는 홈이 파인 부분이 삽입부 왼쪽을 향하게 넣어야 하며, 금속칩이 하단을 향하게 꽂아야 정상적으로 인식됩니다. 유심을 너무 강하게 꽂으실 경우 유심이 안쪽으로 들어가 인식이 안되는 것은 물론 내부 보드가 손상되어 유상 수리가 발생할 수 있습니다.</p>
                                </div>
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/usim-put-in-1.png" alt="iotplex 4guard gps usim image" class="img-50" />
                                <img src="../../assets/img/Faq/usim-put-in-2.png" alt="iotplex 4guard gps usim image" class="img-50" />
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/usim-put-in-3.png" alt="iotplex 4guard gps usim image" class="img-50" />
                                <img src="../../assets/img/Faq/usim-put-in-4.png" alt="iotplex 4guard gps usim image" class="img-50" />
                            </div>
                        </article>
                        <article id="answer9">
                            <div class="article-title">
                                Q9. 단말기 전원이 안 들어올 때
                            </div>
                            <div class="h2-bold">
                                전원이 켜져있는 지 확인
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/turn-on.png" alt="iotplex 4guard gps image" class="img-70" />
                            </div>
                            <div class="article-point">
                                <p class="normal-500">🔔 스위치 왼쪽 : 꺼짐(off)</p>
                                <p class="normal-500">🔔 스위치 오른쪽 : 켜짐(on)</p>
                                <p class="normal-700">스위치가 켜져 있는데도 반응이 없다면 왼쪽/오른쪽을 번갈아 움직여서 껐다가 켜주시면 됩니다.</p>
                            </div>
                            <div class="article-bold">
                                1. 유선 배선 설치
                            </div>
                            <div class="article-point-img">
                                <figure>
                                    <img src="../../assets/img/Faq/cabel.png" alt="iotplex 4guard gps image" />
                                    <figcaption>각 핀이 잘 연결되어 있는지 확인</figcaption>
                                </figure>
                                <figure>
                                    <img src="../../assets/img/Faq/blue-thing.png" alt="iotplex 4guard gps image" />
                                    <figcaption>각 핀이 잘 연결되어 있는지 확인</figcaption>
                                </figure>   
                            </div>
                            <div class="article-point">
                                <p class="normal-500">두 가지 모두 정상이라면 단말기 문제이므로 제품 A/S를 보내주세요.</p>
                            </div>
                            <div class="article-bold">
                                2. 시거잭 연결
                            </div>
                            <div class="article-point-img">
                                <figure>
                                    <img src="../../assets/img/Faq/cabel.png" alt="iotplex 4guard gps image" />
                                    <figcaption>각 핀이 잘 연결되어 있는지 확인</figcaption>
                                </figure>
                                <figure>
                                    <img src="../../assets/img/Faq/smoker.png" alt="iotplex 4guard gps image" />
                                    <figcaption>시거잭을 분해해 퓨즈가 끊어졌는지 확인</figcaption>
                                </figure>   
                            </div>
                            <div class="article-point-img">
                                <figure>
                                    <img src="../../assets/img/Faq/lamp.png" alt="iotplex 4guard gps image" />
                                    <figcaption>정상적인 퓨즈 예시</figcaption>
                                </figure>
                                <figure>
                                    <img src="../../assets/img/Faq/lamp-broke.png" alt="iotplex 4guard gps image"/>
                                    <figcaption>끊어진 퓨즈 예시</figcaption>
                                </figure>   
                            </div>
                            <div class="article-point">
                                <p class="normal-500">두 가지 모두 정상이라면 단말기 문제이므로 제품 A/S를 보내주세요.</p>
                            </div>
                        </article>
                        <article id="answer10">
                            <div class="article-title">
                                Q10. 신형/구형 커넥터 구분 방법
                            </div>
                            <div class="article-point-img">
                                <figure>
                                    <img src="../../assets/img/Faq/new-device.png" alt="iotplex 4guard gps image" />
                                    <figcaption>단말기 신형 커넥터</figcaption>
                                </figure>
                                <figure>
                                    <img src="../../assets/img/Faq/old-device.png" alt="iotplex 4guard gps image" />
                                    <figcaption>단말기 구형 커넥터</figcaption>
                                </figure>   
                            </div>
                            <div class="article-point-img">
                                <figure>
                                    <img src="../../assets/img/Faq/new-device-cable.png" alt="iotplex 4guard gps image" />
                                    <figcaption>하네스 신형 커넥터</figcaption>
                                </figure>
                                <figure>
                                    <img src="../../assets/img/Faq/old-device-cable.png" alt="iotplex 4guard gps image" />
                                    <figcaption>하네스 구형 커넥터</figcaption>
                                </figure>   
                            </div>
                            <div class="article-point-img">
                                <figure>
                                    <img src="../../assets/img/Faq/new-device-cable-in.png" alt="iotplex 4guard gps image" />
                                    <figcaption>신형 하네스/커넥터 결착 상태</figcaption>
                                </figure>
                                <figure>
                                    <img src="../../assets/img/Faq/old-device-cable-in.png" alt="iotplex 4guard gps image" />
                                    <figcaption>구형 하네스/커넥터 결착 상태</figcaption>
                                </figure>   
                            </div>
                        </article>
                        <article id="answer11">
                            <div class="article-title">
                                Q11. GPS 위치가 다를 때
                            </div>
                            <div class="article-txt">
                                GPS 신호는 원래 오차가 조금씩 발생하므로 위치 차이가 발생할 수 있습니다. 단말기 기본 신호 주기는 1분으로 되어 있어 GPS를 잡고 신호를 보내고 1분 이내에 아파트 단지 또는 지하주차장 등 GPS 음영 지역으로 이동할 경우 마지막으로 신호를 받았던 위치를 보여주기 때문에 실제 마지막 위치와 차이가 날 수 있습니다.
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/map-11.png" alt="iotplex 4guard gps image" class="img-30" />
                            </div>
                            <div class="article-point">
                                <p class="normal-500">위의 사진처럼 마지막으로 GPS가 잡혔던 위치가 사거리에 있고, 실제 위치는 지하주차장인 경우 관제 상에서는 다른 위치처럼 보입니다.</p>
                            </div>
                        </article>
                        <article id="answer12">
                            <div class="article-title">
                                Q12. km 단위로 차이가 날 때
                            </div>
                            <div class="article-txt">
                                GPS 신호를 보내는 주기는 기본 1분이므로 실제 운행 거리와 다소 차이가 발생할 수 있습니다. 중간에 통신이나 GPS가 잠시 끊겼다가 연결되는 경우 그 사이 거리를 일직선으로 연결하여 거리를 적산합니다.
                            </div>
                            <div class="article-point-img">
                                <figure>
                                    <img src="../../assets/img/Faq/map-gps-red.png" alt="iotplex 4guard gps image" />
                                    <figcaption>일직선으로 적산</figcaption>
                                </figure>
                                <figure>
                                    <img src="../../assets/img/Faq/map-red-2.png" alt="iotplex 4guard gps image" />
                                    <figcaption>10km 이하인 경우 적산</figcaption>
                                </figure>   
                            </div>
                            <div class="article-txt">
                                운행을 시작한 후 GPS 신호를 잡는 데 까지 보통 약 2~3분 정도 소요됩니다. 만약 GPS 신호를 잡지 못한 채 운행 시 마지막으로 GPS 신호를 잡았던 위치에서 현재 연결된 위치로 일직선으로 연결되는데, 이 때 거리가 10km 이하라면 적산 되고, 10km 초과인 경우 적산이 되지 않습니다. 이로 인해 실제 운행 km와 관제 상 km 차이가 발생할 수 있습니다.                    
                            </div>
                        </article>
                        <article id="answer13">
                            <div class="article-title">
                                Q13. 장기 미관제 알림이 뜰 때
                            </div>
                            <div class="article-img">
                                <img src="../../assets/img/Faq/gps-alert.png" alt="iotplex 4guard gps image" class="img-70" />
                            </div>
                            <div class="article-txt">
                                3개월 간 운행 기록이 없으면 장기 미관제 차량으로 표시됩니다. 다시 정상적으로 운행할 경우 장기 미관제 팝업은 사라집니다. 오랜 기간 사용하지 않으셨다면 제품을 A/S 보내서 점검 받은 후 사용하시길 권장합니다.
                            </div>
                        </article>
                        <article id="answer14" style={{paddingBottom:'10em'}}>
                            <div class="article-title">
                                Q14. 운행 기록 보관 기간
                            </div>
                            <div class="article-txt">
                                <p>운행 기록 데이터는 3개월까지 확인할 수 있습니다.</p>
                                <p>3개월 이후에는 데이터가 삭제되니 그 전에 필요한 기록을 확인하시기 바랍니다.</p>
                            </div>
                        </article>
                    </section>
                </div>
            </Outer>            
        </>
    );
}

