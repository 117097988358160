import React from 'react';
import { add_hyphen } from '../../../lib/common_func';
import moment from 'moment';
import { event } from '../../../lib/eventData';
import { useLang } from '../../../context/LanguageContext';

export default function EventTableExcel({ data, excelRef }) {
    const { language } = useLang();

    const style = {
        primary:{backgroundColor:'#DCE8F5'},
        color:{blue:{color:'blue'}},
        sort:{left:{textAlign:'left'},center:{textAlign:'center'},right:{textAlign:'right'}},
        row:{height:'30px',textAlign:'center'},
        border:{border:'1px solid #413c3c'}
    }

    return (
        <>
            <table className="common-table excel-table" ref={excelRef}>
                <thead>
                    <tr style={style.row}>
                        <th style={{...style.border,...style.primary}}>{language === "KOR" ? "번호" : "No"}</th>
                        <th style={{...style.border,...style.primary}}>{language === "KOR" ? "단말기명" : "Device name"}</th>
                        <th style={{...style.border,...style.primary}}>{language === "KOR" ? "단말번호" : "USIM num"}</th>
                        <th style={{...style.border,...style.primary}}>{language === "KOR" ? "시간" : "Time"}</th>
                        <th style={{...style.border,...style.primary}}>{language === "KOR" ? "이벤트명" : "Event name"}</th>
                        <th style={{...style.border,...style.primary}}>{language === "KOR" ? "주소" : "Address"}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    data &&
                    data.map((item, index) => {
                        const { row, unit_nm, unitid, date, event_code, addr } = item;
                        return (
                        <tr key={index} style={style.row}>
                            <td style={style.border}>{row}</td>
                            <td style={style.border}>{unit_nm}</td>
                            <td style={style.border}>{add_hyphen(unitid, language)}</td>
                            <td style={style.border}>{moment.utc(date).format("YYYY-MM-DD HH:mm")}</td>
                            <td style={style.border}>{event(event_code, language)}</td>
                            <td style={style.border}>{addr}</td>
                        </tr>
                        );
                    })}
                </tbody>
            </table>    
        </>
    );
}

