import React from "react";
import Page from "../Atomic/template/PageTable";
import { LanguageContext } from "../../context/LanguageContext";

export const dataList = [
  {
    permission: "J000",
    path: "/Adm/o-table",
    labelKR: "고객 관리",
    labelEN: "User management",
  },
  {
    permission: "J000",
    path: "/Adm/dev-table",
    labelKR: "단말기 관리",
    labelEN: "Device Management",
  },
  {
    permission: "J000",
    path: "/Adm/menu-table",
    labelKR: "메뉴 권한 관리",
    labelEN: "Manage Menu Permissions",
  },
  {
    permission: "J000",
    path: "/Adm/log-table",
    labelKR: "일지 확인",
    labelEN: "Check the log",
  },
  {
    permission: "J000",
    path: "/Adm/sys-table",
    labelKR: "시스템 공지사항",
    labelEN: "System Announcements",
  },
  {
    permission: "J000",
    path: "/Adm/def-table",
    labelKR: "고객불만관리",
    labelEN: "Customer Complaint Management",
  },
  {
    permission: "J000",
    path: "Login-Screen",
    labelKR: "로그인 화면설정",
    labelEN: "Login screen settings",
  },
  {
    permission: "J000",
    path: "/Adm/bas-table",
    labelKR: "기초코드 관리",
    labelEN: "Basic Code Management",
  },
];
export const subGadjet = [
  {
    path: "/Adm/dev-table",
    labelKR: "단말기 관리",
    labelEN: "Device Management",
  },
  {
    path: "/Adm/item-table",
    labelKR: "품목 관리",
    labelEN: "Item Management",
  },
];
export const subDataList = [
  {
    path: "/Adm/o-table",
    labelKR: "조직 관리",
    labelEN: "Organization management",
  },
  {
    path: "/Adm/u-table",
    labelKR: "사용자 관리",
    labelEN: "User management",
  },
  {
    path: "/Adm/d-table",
    labelKR: "부서 관리",
    labelEN: "Department management",
  },
];
export const subDataListMenu = [
  {
    path: "/Adm/menu-table/new",
    labelKR: "기본 정보",
    labelEN: "Basic information",
  },
];
export const subDataListMenuFull = [
  {
    path: "/Adm/menu-table/basic",
    labelKR: "기본 정보",
    labelEN: "Basic information",
  },
  {
    path: "/Adm/menu-table/perm",
    labelKR: "프로그램 권한",
    labelEN: "Program authority",
  },
  {
    path: "/Adm/menu-table/manage",
    labelKR: "사용자 관리",
    labelEN: "User Management",
  },
];
export default function AdmMain() {
  const { language } = React.useContext(LanguageContext);
  const formData = [];
  const inputRef = React.createRef();
  const data = [];
  const columns = [];
  const buttonData = [
    {
      labelKR: "전체목록",
      labelEN: "View all",
      classN: "white",
      clickFun: () => {
        console.log("전체목록");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
  ];
  function move() {}
  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        formData={formData}
        inputRef={inputRef}
        data={data}
        columns={columns}
        move={move}
        buttonData={buttonData}
        navi={"../../"}
        subDataList={subDataList}
      />
    </>
  );
}
