import React, { useState, useEffect } from "react";
//@ts-ignore
import { LanguageContext } from "../../../../../context/LanguageContext.jsx";
//@ts-ignore
import { dataList } from "../../../AdmMain.jsx";
import Page from "../../../../Atomic/template/PageTable";
import CommonControl from "../../../../Atomic/organism/CommonControl";
import SubNavi from "../../../../Atomic/molecules/SubNavi";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import axios from "../../../../../lib/axios";
//@ts-ignore
import { useAuth } from "../../../../../context/LoginContext";
//@ts-ignore
import TableAtom from "../../../../Atomic/organism/AtomTable";
import { filterData, inputChnage } from "../../../../Atomic/exFunc";
import Form from "../../../../Atomic/organism/FormAtom";
//@ts-ignore
import { subGadjet } from "../../../AdmMain.jsx";
// @ts-ignore
import { add_hyphen } from "../../../../../lib/common_func.js";
import Swal from "sweetalert2";
export default function Table() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const navi = useNavigate();
  const [rawData, setRawData] = useState<any>([]);
  const { user } = useAuth();
  const inputRef = React.createRef<HTMLInputElement>();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState<any>([]);

  // Function defines
  //===============================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //===============================================================

  function move(e: any) {
    const i = e.instance.store.focus.rowIndex;
    if (i === undefined) {
      return;
    }
    const idx = e.instance.store.data.rawData[i].idx;
    const unitid = e.instance.store.data.rawData[i].unitid;
    if (e.columnName !== "sms_cnt" && e.targetType != "columnHeader") {
      navi(`/Adm/dev-table/edit`, {
        state: {
          idx: idx,
          unitid: unitid,
        },
      });
    }
    if (e.columnName === "term_set_log" && e.targetType != "columnHeader") {
      navi(`/Adm/dev-table/control`, {
        state: {
          idx: idx,
          unitid: unitid,
        },
      });
    }
    if (e.columnName === "reset_function" && e.targetType != "columnHeader") {
      resetDevice(e.instance.store.data.rawData[i].unitid);
      navi(`/Adm/dev-table`);
    }
  }

  function submitFun(e: any) {
    e.preventDefault();
    filterData(rawData, keyword, setData);
  }

  function resetDevice(e: any) {
    Swal.fire({
      title: translate("초기화하시겠습니까?", "Do you want to reset?"),
      showCancelButton: true,
      confirmButtonText: translate("초기화", "Reset"),
      cancelButtonText: translate("취소", "Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`/web/admin/terminal/reset`, { type : user.type, unitid: e })
          .then((res: any) => {
            if (res.status === 200) {
              Swal.fire({
                title: `${translate("수정되었습니다", "Edit complete")}`,
              }).then((result) => {
                navi("/Adm/dev-table");
              });
            } else {
              Swal.fire({
                title: `${translate("수정에 실패했습니다", "Edit failed")}`,
              });
            }
          });
      }
    });
  }
  // Data define
  //===============================================================

  const columns: any = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },
    // {
    //   header: translate("구분", ""),
    //   name: "",
    //   align: "center",
    // },
    {
      header: translate("등급", ""),
      name: "org_type",
      align: "center",
      formatter: (name: any) => {
        if (name.row.org_type === "C") {
          return language === "KOR" ? `회사 / ${name.row.org_cd}` : "Company";
        } else if (name.row.org_type === "B") {
          return language === "KOR" ? `지점 / ${name.row.org_cd}` : "Branch";
        } else if (name.row.org_type === "A") {
          return language === "KOR" ? `대리점 / ${name.row.org_cd}` : "Agency";
        }
      },
    },
    {
      header: translate("회사명", "Co name"),
      name: "cmp_nm",
      align: "center",
    },
    {
      header: translate("지점명", "Branch name"),
      name: "brc_nm",
      align: "center",
    },
    {
      header: translate("대리점명", "Agency name"),
      name: "agt_nm",
      align: "center",
    },
    {
      header: translate("단말기번호", "Device No"),
      name: "unitid",
      align: "center",
      width: 150,
      formatter: (name: any) => add_hyphen(name.row.unitid),
    },
    {
      header: translate("단말기명", "Deivce Name"),
      name: "unit_nm",
      align: "center",
    },
    {
      header: translate("제품명", "Prod. name"),
      name: "prod_legal_nm",
      align: "center",
    },
    {
      header: translate("기자재명칭", "Equipment Name"),
      name: "prod_nm",
      align: "center",
    },
    {
      header: translate("단말기 등록일	", "Device Reg. Date"),
      name: "makedate",
      align: "center",
    },
    {
      header: translate("제어", "Term. control"),
      name: "term_set_log",
      align: "center",
      width: 50,
      formatter: () =>
        `<div class='link cursor'>${
          language === "KOR" ? "제어" : "Term"
        }</div>`,
    },
    {
      header: translate("설정이력", "Setting history"),
      name: "",
      width: 60,
      align: "center",
      formatter: () =>
        `<div class='link cursor'>${
          language === "KOR" ? "조회" : "Check"
        }</div>`,
    },
    {
      header: translate("데이터 초기화", "Reset data"),
      name: "reset_function",
      width: 90,
      align: "center",
      formatter: () =>
        `<div class='link cursor'>${
          language === "KOR" ? "초기화" : "Reset"
        }</div>`,
    },
  ];

  const buttonData = [
    {
      labelKR: "전체목록",
      labelEN: "View All",
      classN: "white",
      clickFun: () => {
        setKeyword("");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "등록",
      labelEN: "Add new",
      classN: "purple",
      icon: true,
      clickFun: () => {
        navi("/Adm/dev-table/new");
      },
      position: "right",
    },
  ];

  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e: any) => inputChnage(e, rawData, setData, setKeyword),
    myref: inputRef,
    option: undefined, // Make option undefined
    value: keyword,
  };

  // useEffect define
  //===============================================================

  useEffect(() => {
    axios
      .get(
        `/web/admin/terminal/list?type=${user.type}`
      )
      .then((res: any) => {
        setRawData(res.data);
      });
  }, [user]);

  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
      filterData(rawData, keyword, setData);
    }
  }, [rawData, keyword]);

  // logs define
  //===============================================================
  // console.log("smsSent", smsCnt);
  // console.log("smsModalUser", user);
  
  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "단말기 관리" : "Gadget management"}`}
        menu="/Adm/dev-table"
      >
        <SubNavi
          subDataList={subGadjet}
          language={language}
          navi={navi}
          classPath="/Adm/dev-table"
        />
        <Form formData={formData} inputRef={inputRef} />
        <TableAtom data={data} columns={columns} move={move} />
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
