// Import statements
import React, { useEffect, useRef } from "react";
// Import Components
// @ts-ignore
import Header from "../../include/AppHeader";
// @ts-ignore
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import CommonControl from "../../Atomic/organism/CommonControl";
import Form from "../../Atomic/organism/FormAtom";
import Select from "../../Atomic/molecules/Select";
// @ts-ignore
import Table from "../../Atomic/organism/Table";
// Functions
// --------------------------------------------------
//@ts-ignore
import axios from "../../../lib/axios.js";
import moment from "moment";
import useExcelDown from "../../../hook/useExcelDown.jsx";
import TerminalMainExcel from "./TerminalMainExcel.jsx";
// --------------------------------------------------
export const dataList = [
  {
    permission: "F100",
    path: "/Trm",
    labelKR: "단말설정",
    labelEN: "Device settings",
  },
  {
    permission: "F200",
    path: "/Trm/BlueTooth",
    labelKR: "블루투스 관리",
    labelEN: "BlueTooth Management",
  },
  {
    permission: "F300",
    path: "/Trm/GeoFence",
    labelKR: "지역설정",
    labelEN: "Geofence",
  },
];
// Component definition
export default function TerminalMain({ language, user }) {
  // const [keyword, setKeyword] = React.useState("");
  const [rawData, setRawData] = React.useState([]);
  const [option, setOption] = React.useState({
    keywordType: "unit_nm",
    keyword: "",
  });
  const inputRef = React.createRef();
  const [data, setData] = React.useState([]);
  // UseEffect definition

  const tableRef = useRef();
  const today = moment().format("YYYY-MM-DD");
  const { excelDown } = useExcelDown(
    tableRef,
    `Device_Info_List_${today}`,
    `Device_Info_List`,
    data
  );

  useEffect(() => {
    if (rawData && option.keyword === "") {
      setData(rawData);
    } else if (rawData && option.keyword !== "") {
      filterData();
    }
  }, [rawData]);
  useEffect(() => {
    axios
      .get(
        `/web/terminal/list?type=${user.type}&org_idx=${user.org_idx}`
      )
      .then((res) => {
        if(res.data !== '') {
          setRawData(res.data);
        }
      });
  }, [user]);

  // Data definition
  const buttonData = [
    {
      labelKR: "전체보기",
      labelEN: "View all",
      classN: "purple",
      // clickFun: clearInput(inputRef, () => {
      //   setKeyword("");
      // }),
      clickFun: () => {
        clearInput();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "엑셀 다운로드",
      labelEN: "Excel download",
      classN: "white excel",
      icon: true,
      iconClass: "ri-file-excel-2-fill",
      clickFun: excelDown,
      position: "right",
    },
  ];
  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e) =>  changeFun('keyword', e),

    myref: inputRef,
    option: (
      <Select
        id="keywordType"
        short="N"
        name="keywordType"
        options={[
          {
            name: language === "KOR" ? "단말기명" : "Device name",
            value: "unit_nm",
          },
          {
            name: language === "KOR" ? "단말번호" : "USIM num",
            value: "unitid",
          },
          { name: language === "KOR" ? "지점명" : "Branch", value: "brc_nm" },
          { name: language === "KOR" ? "대리점명" : "Agent", value: "agt_nm" },
        ]}
        changeFun={(e) => {
          changeFun("keywordType", e.target.value);
        }}
        def={option.keywordType}
      />
    ),
    value: option.keyword,
  };

  // Function definition
  // --------------------------------------------------

  async function submitFun(e) {
    e.preventDefault();
    filterData();
    // await getData();
  }
  // async function getData() {
  //   await axios
  //     .get(
  //       `/web/terminal/list?type=${user.type}&org_idx=${user.org_idx}`
  //     )
  //     .then((res) => {
  //       if(res.data !== '') {
  //         setRawData(res.data);
  //       }
  //     });
  // }
  function filterData() {
    if (option.keywordType === "") {
      clearInput();     
    } else {
      const result = rawData.filter((item) => {
        return item[option.keywordType].toLowerCase().includes(option.keyword.toLowerCase());
      });
      setData(result);
    }
  }
  function changeFun(name, value) {
    setOption({ ...option, [name]: value });
  }
  //--------------------------------------------------
  function clearInput() {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setData([...rawData]);
    changeFun("keyword","");
  }

  return (
    <>
      <Header />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Trm"} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "단말설정" : "Device settings"}
                </div>
              </div>
              <div className="sub-page-body">
                <Form formData={formData} inputRef={inputRef} />
                <Table language={language} data={data} />
                <TerminalMainExcel data={data} tableRef={tableRef} />
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

// Divider line
// --------------------------------------------------
