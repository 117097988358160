import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../lib/axios";
//@ts-ignore
import { dataList, subDataListMenuFull } from "../../../Admin/AdmMain";
import CommonControl from "../../../Atomic/organism/CommonControl";
import Page from "../../../Atomic/template/PageTable";
import SubNavi from "../../../Atomic/molecules/SubNavi";
import Select from "../../../Atomic/molecules/Select";
import Form from "../../../Atomic/organism/FormAtom";
import { filterData, inputChnage } from "../../../Atomic/exFunc";
//@ts-ignore
export default function Basic() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const navi = useNavigate();
  const { user } = useAuth();
  // State defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const [rawData, setRawData] = useState<any>([]);
  const [users, setUsers] = useState<any>([]);
  const [keyword, setKeyword] = useState("");
  const [authList, setAuthList] = useState<any>();
  const [data, setData] = useState<any>([]);
  const inputRef = React.createRef<HTMLInputElement>();
  //=======================================================================
  // Function defines
  //=======================================================================

  function inputHandler(val: any, item: any) {
    item.AG_IDX = Number(val.target.value);
    // console.log(item);

    const newUsers = users.map((user: any) => {
      if (user.idx === item.idx) {
        user.AG_IDX = Number(val.target.value);
      }
      return user;
    });
    setUsers(newUsers);
  }

  function edit(item: any) {
    Swal.fire({
      title: `${translate("저장하시겠습니까?", "Do you want to save?")}`,
      showCancelButton: true,
      confirmButtonText: `${translate("예", "Yes")}`,
      cancelButtonText: `${translate("아니오", "No")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`/web/admin/menu/user/edit`, item).then((res: any) => {
          if (res.status === 200) {
            Swal.fire({
              title: `${translate("저장되었습니다", "Saved successfully")}`,
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              navi(`/Adm/menu-table`, {
                state: {
                  idx: res.data.idx,
                },
              });
            });
          } else {
            Swal.fire({
              title: `${translate("저장에 실패하였습니다", "Failed to save")}`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
      }
    });
  }

  function del(item: any) {
    Swal.fire({
      title: `${translate(
        "삭제하시겠습니까?",
        "Are you sure you want to delete?"
      )}`,
      showCancelButton: true,
      confirmButtonText: `${translate("삭제", "Delete")}`,
      cancelButtonText: `${translate("취소", "Cancel")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`/web/admin/menu/user/del`, { idx: item.idx })
          .then((res: any) => {
            if (res.status === 200) {
              Swal.fire({
                title: `${translate("삭제되었습니다", "Deleted successfully")}`,
                showConfirmButton: false,
                timer: 1500,
              }).then(() => {
                navi(`/Adm/menu-table`, {
                  state: {
                    idx: res.data.idx,
                  },
                });
              });
            } else {
              Swal.fire({
                title: `${translate(
                  "삭제에 실패하였습니다",
                  "Failed to delete"
                )}`,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
      }
    });
  }

  function conf(item: any, type: string) {
    if (type === "del") {
      Swal.fire({
        title: `${translate(
          "삭제하시겠습니까?",
          "Are you sure you want to delete?"
        )}`,
        showCancelButton: true,
        confirmButtonText: `${translate("삭제", "Delete")}`,
        cancelButtonText: `${translate("취소", "Cancel")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          del(item);
        }
      });
    } else if (type === "edit") {
      Swal.fire({
        title: `${translate(
          "수정하시겠습니까?",
          "Are you sure you want to edit?"
        )}`,
        showCancelButton: true,
        confirmButtonText: `${translate("수정", "Edit")}`,
        cancelButtonText: `${translate("취소", "Cancel")}`,
      }).then((result) => {
        if (result.isConfirmed) {
          edit(item);
        }
      });
    }
  }

  function submitFun(e: any) {
    e.preventDefault();
    filterData(rawData, keyword, setData);
  }

  // Data definition
  //=======================================================================

  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        navi('../menu-table')
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
  ];
  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e: any) => inputChnage(e, rawData, setData, setKeyword),
    myref: inputRef,
    option: undefined,
    value: keyword,
  };

  // UseEffect defines
  //=======================================================================

  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
  }, [location]);
  useEffect(() => {
    if (idx !== "") {
      axios
        .get(
          `/web/admin/menu/user/list?idx=${idx}&type=${user.type}`
        )
        .then((res: any) => {
          setRawData(res.data);
          setUsers(res.data.users);
          setAuthList(res.data.auth);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      setRawData([]);
    }
  }, [idx, user.type]);

  useEffect(() => {
    if (rawData.users && keyword === "") {
      setData(rawData.users);
    } else if (rawData.users && keyword !== "") {
      filterData(rawData.users, keyword, setData);
    }
  }, [rawData, keyword]);

  // Log defines
  //=======================================================================
  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "메뉴 권한 관리" : "Menu Auth"}`}
        menu="/Adm/menu-table"
      >
        <SubNavi
          subDataList={subDataListMenuFull}
          language={language}
          navi={navi}
          idx={idx}
          classPath="/Adm/menu-table/manage"
        />
        <Form formData={formData} inputRef={inputRef} />
        <div
          style={{ height: "540px", overflow: "scroll", overflowX: "hidden" }}
        >
          <table className="common-view-table">
            <colgroup>
              <col style={{ width: "8rem" }} />
              <col style={{ width: "" }} />
              <col style={{ width: "18rem" }} />
              <col style={{ width: "18rem" }} />
              <col style={{ width: "12rem" }} />
              <col style={{ width: "8rem" }} />
              <col style={{ width: "18rem" }} />
              <col style={{ width: "12rem" }} />
            </colgroup>
            <thead
              style={{
                top: "0",
                position: "sticky",
                zIndex: "101",
              }}
            >
              <tr>
                <th>{translate("번호", "No")}</th>
                <th>{translate("권한그룹", "Authorization Group")}</th>
                <th>{translate("회사명", "Company Name")}</th>
                <th>{translate("지점명", "Branch Name")}</th>
                <th>{translate("대리점명", "Agancy Name")}</th>
                <th>UserID</th>
                <th>{translate("이름", "Name")}</th>
                <th>{translate("옵션", "Options")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <Select
                        id="AG_IDX"
                        short={"N"}
                        name="AG_IDX"
                        options={
                          authList
                            ? authList.map((item: any) => {
                                return {
                                  value: item.idx,
                                  name: item.nm,
                                };
                              })
                            : []
                        }
                        changeFun={(e) => inputHandler(e, item)}
                        def={item.AG_IDX}
                      />
                    </td>
                    <td>{item.cmp_nm}</td>
                    <td>{item.brc_nm}</td>
                    <td>{item.agt_nm}</td>
                    <td>{item.idx}</td>

                    <td>{item.nm}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "10rem",
                          gap: "0.5rem",
                        }}
                      >
                        {" "}
                        <button
                          style={{
                            width: "5rem",
                            padding: "0",
                            minWidth: "0",
                          }}
                          className="common-btn white"
                          onClick={() => {
                            conf(item, "del");
                          }}
                        >
                          {translate("삭제", "Delete")}
                        </button>
                        <button
                          style={{
                            width: "5rem",
                            padding: "0",
                            minWidth: "0",
                          }}
                          className="common-btn white"
                          onClick={() => {
                            conf(item, "edit");
                          }}
                        >
                          {translate("수정", "Edit")}
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
