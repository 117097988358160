import React, { useState, useEffect } from "react";
import Radio from "../../../../Atomic/atoms/Radio";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
//@ts-ignore
import { LanguageContext } from "../../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../../lib/axios.js";
//@ts-ignore
import { dataList } from "../../../AdmMain";
import { DeleteFun } from "../../../../Atomic/exFunc";
import CommonControl from "../../../../Atomic/organism/CommonControl";
import Page from "../../../../Atomic/template/PageTable";
import TelInput from "../../../../Atomic/atoms/Tel_Input";
// @ts-ignore
import Email from "../../../../Atomic/atoms/Email.jsx";
import Select from "../../../../Atomic/molecules/Select";
import { useDaumPostcodePopup } from "react-daum-postcode";
// @ts-ignore
import { checkHp } from "../../../../../lib/common_func.js";
export default function Form() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const navi = useNavigate();

  const postRef = React.useRef();
  const mainAddrRef = React.useRef();
  const subAddrRef = React.useRef();
  const open = useDaumPostcodePopup(
    "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );
  const refId = React.useRef<HTMLInputElement>(null);
  // State defines
  //=======================================================================
  const [rawData, setRawData] = useState<any>({});
  const [skci, setSkci] = useState<any>({});
  const [codeIdx, setCodeIdx] = useState<any>(null);
  //=======================================================================
  // Function defines

  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const handleComplete = (data: any) => {
    var fullAddr = ""; // 최종 주소 변수
    var extraAddr = ""; // 조합형 주소 변수
    if (data.userSelectedType === "R") {
      fullAddr = data.roadAddress;
    } else {
      fullAddr = data.jibunAddress;
    }
    if (data.userSelectedType === "R") {
      if (data.bname !== "") {
        extraAddr += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddr +=
          extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
      }
      fullAddr += extraAddr !== "" ? " (" + extraAddr + ")" : "";
    }
    if (postRef.current) {
      (postRef.current as HTMLInputElement).value = data.zonecode;
    }
    if (mainAddrRef.current) {
      (mainAddrRef.current as HTMLInputElement).value = fullAddr;
    }
    if (subAddrRef.current) {
      (subAddrRef.current as HTMLInputElement).focus();
    }
    setRawData((prev: any) => ({
      ...prev,
      postnum: data.zonecode,
      addr: fullAddr,
    }));
  };
  const handleClick = () => {
    //@ts-ignore
    open({ onComplete: handleComplete });
  };
  //=======================================================================
  const faxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp: string[] = rawData.fax.split("-");

    switch (name) {
      case "fax1":
        temp[0] = value;
        break;
      case "fax2":
        temp[1] = value;
        break;
      case "fax3":
        temp[2] = value;
        break;
      default:
        break;
    }

    if (temp.some((segment) => segment === "0000")) {
      alert("0000은 입력할 수 없습니다.");
      temp = temp.map((segment) => (segment === "0000" ? "" : segment));
    }

    setRawData((prev: any) => ({ ...prev, fax: temp.join("-") }));
  };
  const hpHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp: string[] = rawData.hp.split("-");

    switch (name) {
      case "hp1":
        temp[0] = value;
        break;
      case "hp2":
        temp[1] = value;
        break;
      case "hp3":
        temp[2] = value;
        break;
      default:
        break;
    }

    if (temp.some((segment) => segment === "0000")) {
      alert("0000은 입력할 수 없습니다.");
      temp = temp.map((segment) => (segment === "0000" ? "" : segment));
    }

    setRawData((prev: any) => ({ ...prev, hp: temp.join("-") }));
  };
  const TelHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp: string[] = rawData.tel.split("-");

    switch (name) {
      case "tel1":
        temp[0] = value;
        break;
      case "tel2":
      case "tel3":
        const index = parseInt(name.charAt(name.length - 1)) - 1;
        temp[index] = value;

        if (temp[index] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[index] = "";
        }
        break;
      default:
        break;
    }

    setRawData((prev: any) => ({ ...prev, tel: temp.join("-") }));
  };
  //=======================================================================

  function inputHandlerIDX(e: any) {
    const { name, value } = e.target;
    setRawData((pre: any) => ({ ...pre, [name]: parseInt(value) }));
  }
  function inputHandler(e: any) {
    const { name, value } = e.target;
    setRawData((pre: any) => ({ ...pre, [name]: value }));
  }
  function selectHandler(e: any) {
    if (e.target.value === "ID") {
      setRawData((pre: any) => ({ ...pre, btoc_yn: "Y", org_type: "C" }));
    } else {
      setRawData((pre: any) => ({
        ...pre,
        btoc_yn: "N",
        org_type: e.target.value,
      }));
    }
  }
  function createNew() {
    if (rawData.org_type === "C" && rawData.btoc_yn === "N") {
      if (!rawData.I_IDX1) {
        Swal.fire({
          title: `${translate(
            "업종(대분류)을 선택해주세요.",
            "Please select type 1"
          )}`,
        });
        return;
      }
      if (!rawData.I_IDX2) {
        Swal.fire({
          title: `${translate(
            "업종(소분류)을 선택해주세요.",
            "Please select type 2"
          )}`,
        });
        return;
      }
    }
    if (rawData.org_type === "" || rawData.org_type === undefined) {
      Swal.fire({
        title: `${translate("조직구분을 선택해주세요.", "Please select type")}`,
      });
      return;
    }
    if (rawData.org_adm_nm === "" || rawData.org_adm_nm === undefined) {
      Swal.fire({
        title: `${translate("관리자명을 입력해주세요.", "Please enter admin name")}`,
      });
      return;
    }
    if (rawData.org_nm === "" || rawData.org_nm === undefined) {
      Swal.fire({
        title: `${translate("조직명을 입력해주세요.", "Please enter org name")}`,
      });
      return;
    }
    if (!rawData.hp || !checkHp(rawData.hp)) {
      Swal.fire({
        title: `${translate(
          "휴대폰 번호를 입력해주세요.",
          "Please enter mobile"
        )}`,
      });
      return;
    }
    if (rawData.postnum === "" || rawData.postnum === undefined) {
      Swal.fire({
        title: `${translate(
          "우편번호를 입력해주세요.",
          "Please enter postnum"
        )}`,
      });
      return;
    }
    if (rawData.addr === "" || rawData.addr === undefined) {
      Swal.fire({
        title: `${translate("주소를 입력해주세요.", "Please enter address")}`,
      });
      return;
    }
    Swal.fire({
      title: `${translate("수정하시겠습니까?", "Do you want to edit?")}`,
      showCancelButton: true,
      confirmButtonText: `${translate("확인", "Confirm")}`,
      cancelButtonText: `${translate("취소", "Cancel")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post("/web/admin/org/edit", {
          ...rawData,
          I_IDX1: rawData.I_IDX1 ? rawData.I_IDX1 : 0,
          I_IDX2: rawData.I_IDX2 ? rawData.I_IDX2 : 0
        }).then((res: any) => {
          if (res.status === 200) {
            Swal.fire({
              title: "수정되었습니다.",
            }).then((result) => {
              window.history.back();
            });
          } else {
            Swal.fire({
              title: "등록에 실패하였습니다.",
            });
          }
        });
      }
    });
  }

  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "삭제",
      labelEN: "Delete",
      classN: "white",
      icon: true,
      clickFun: () => {
        DeleteFun(`/web/admin/org/del`, { idx: idx, type: "ADM", org_type: rawData.org_type }, navi);
      },
      position: " right",
    },
    {
      labelKR: "수정",
      labelEN: "Edit",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];
  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
  }, [location]);
  useEffect(() => {
    if (idx !== "") {
      axios
        .get(`/web/admin/org/detail?idx=${idx}&type=ADM`)
        .then((res: any) => {
          setRawData(res.data);
          setRawData((pre: any) => ({
            ...pre,
            type: "ADM",
          }));
        });
    } else {
      setRawData({});
    }
  }, [idx]);
  useEffect(() => {
    axios.get(`/web/category/ksci?type=ADM`).then((res: any) => {
      setSkci(res.data);
    });
  }, []);
  useEffect(() => {
    if (rawData.I_IDX1 !== null) {
      setCodeIdx(rawData.I_IDX1);
    } else {
      setCodeIdx(0);
    }
  }, [rawData, skci]);
  //=======================================================================

  // Log defines
  //=======================================================================

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "조직 관리" : "ORG management"}`}
        menu="/Adm/o-table"
      >
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th className="required">
                {language === "KOR" ? "조직구분" : "Org type"}
              </th>
              <td>
                <div className="custom-input">
                  <input
                    name="org_type"
                    id="org_type"
                    defaultValue={
                      rawData.org_type === "C" && rawData.btoc_yn === "N"
                        ? "회사"
                        : rawData.org_type === "B"
                        ? "지점"
                        : rawData.org_type === "A"
                        ? "대리점"
                        : rawData.org_type === "C" && rawData.btoc_yn === "Y"
                        ? "개인"
                        : ""
                    }
                    readOnly={true}
                    onChange={(e: any) => selectHandler(e)}
                  />
                </div>
              </td>
              <th className="required">
                {language === "KOR" ? "관리자명" : "Admin name"}
              </th>
              <td>
                <div className="custom-input">
                  <input
                    id="org_adm_nm"
                    name="org_adm_nm"
                    type="text"
                    onChange={inputHandler}
                    value={rawData.org_adm_nm || ""}
                    placeholder={
                      language === "KOR"
                        ? "관리자명을 입력해주세요."
                        : "Please enter your admin name."
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th className="required">
                {language === "KOR" ? "조직명" : "Org name"}
              </th>
              <td>
                <div className="flex">
                  <div className="custom-input">
                    <input
                      id="org_nm"
                      name="org_nm"
                      type="text"
                      onChange={inputHandler}
                      value={rawData.org_nm || ""}
                      placeholder={
                        language === "KOR"
                          ? "조직명 입력해주세요."
                          : "Please enter company name."
                      }
                    />
                  </div>
                </div>
              </td>
              <th>{language === "KOR" ? "회사코드" : "Com code"}</th>
              <td>
                <div className="flex">
                  <div className="custom-input">
                    <input
                      name="id"
                      type="text"
                      // onChange={inputHandler}
                      ref={refId}
                      // value={rawData.id}
                      defaultValue={rawData.org_cd || ""}
                      readOnly={true}
                    />
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <th className="required">
                {language === "KOR" ? "휴대폰 번호" : "Mobile"}
              </th>
              <td>
                <div className="flex">
                  <TelInput
                    id="hp1"
                    name="hp1"
                    value={
                      rawData.hp === undefined ? "" : rawData.hp.split("-")[0]
                    }
                    changeFun={hpHandler}
                    readOnly={false}
                    place="010"
                    max={3}
                    req={3}
                  />
                  -
                  <TelInput
                    id="hp2"
                    name="hp2"
                    value={
                      rawData.hp === undefined ? "" : rawData.hp.split("-")[1]
                    }
                    changeFun={hpHandler}
                    place="0000"
                    max={4}
                    req={4}
                  />
                  -
                  <TelInput
                    id="hp3"
                    name="hp3"
                    value={
                      rawData.hp === undefined ? "" : rawData.hp.split("-")[2]
                    }
                    changeFun={hpHandler}
                    place="0000"
                    max={4}
                    req={4}
                  />
                </div>
              </td>
              <th>{language === "KOR" ? "직통번호" : "Direct tel"}</th>
              <td>
                <div className="flex">
                  <TelInput
                    id="tel1"
                    name="tel1"
                    value={
                      rawData.tel === undefined ? "" : rawData.tel.split("-")[0]
                    }
                    changeFun={TelHandler}
                    readOnly={false}
                    place="010"
                    max={3}
                    req={2}
                  />
                  -
                  <TelInput
                    id="tel2"
                    name="tel2"
                    value={
                      rawData.tel === undefined ? "" : rawData.tel.split("-")[1]
                    }
                    changeFun={TelHandler}
                    place="0000"
                    max={4}
                    req={3}
                  />
                  -
                  <TelInput
                    id="tel3"
                    name="tel3"
                    value={
                      rawData.tel === undefined ? "" : rawData.tel.split("-")[2]
                    }
                    changeFun={TelHandler}
                    place="0000"
                    max={4}
                    req={4}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <th>{language === "KOR" ? "팩스번호" : "Fax"}</th>
              <td>
                <div className="flex">
                  <TelInput
                    id="fax1"
                    name="fax1"
                    value={
                      rawData.fax === undefined ? "" : rawData.fax.split("-")[0]
                    }
                    changeFun={faxHandler}
                    place="000"
                    max={3}
                    req={2}
                  />
                  -
                  <TelInput
                    id="fax2"
                    name="fax2"
                    value={
                      rawData.fax === undefined ? "" : rawData.fax.split("-")[1]
                    }
                    changeFun={faxHandler}
                    place="0000"
                    max={4}
                    req={3}
                  />
                  -
                  <TelInput
                    id="fax3"
                    name="fax3"
                    value={
                      rawData.fax === undefined ? "" : rawData.fax.split("-")[2]
                    }
                    changeFun={faxHandler}
                    place="0000"
                    max={4}
                    req={4}
                  />
                </div>
              </td>
              <th>E-mail</th>
              <td>
                <Email
                  id="email"
                  name="email"
                  value={rawData.email || ""}
                  setRawData={setRawData}
                />
              </td>
            </tr>

            <tr>
              <th className="required" rowSpan={3}>
                {language === "KOR" ? "주소" : "Addr"}
              </th>
              <td rowSpan={3}>
                <div className="address">
                  <div className="row">
                    <div className="custom-input">
                      <input
                        id="postnum"
                        name="postnum"
                        type="text"
                        readOnly
                        ref={postRef as any}
                        value={rawData.postnum || ""}
                        onChange={(e) => {
                          console.log(e.target.value);
                        }}
                      />
                    </div>
                    <button
                      onClick={handleClick}
                      type="button"
                      className="common-btn white sm"
                    >
                      <span>
                        {language === "KOR" ? "우편번호 검색" : "Addr search"}
                      </span>
                    </button>
                  </div>
                  <div className="row">
                    <div className="custom-input full">
                      <input
                        id="addr"
                        name="addr"
                        type="text"
                        ref={mainAddrRef as any}
                        value={rawData.addr || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="custom-input full">
                      <input
                        id="addr_dt"
                        name="addr_dt"
                        type="text"
                        ref={subAddrRef as any}
                        onChange={inputHandler}
                        value={rawData.addr_dt || ""}
                        placeholder={
                          language === "KOR"
                            ? "상세주소를 입력해주세요."
                            : "Please enter your detailed address."
                        }
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                {language === "KOR"
                  ? "사업자 등록번호"
                  : "Official biz reg num"}
              </th>
              <td>
                <div className="custom-input">
                  <input
                    name="bizno"
                    type="text"
                    onChange={inputHandler}
                    value={rawData.bizno || ""}
                    maxLength={10}
                    placeholder={
                      language === "KOR"
                        ? "사업자 등록번호를 입력해주세요."
                        : "Please enter your official biz reg num."
                    }
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>
                {language === "KOR"
                  ? "제어버튼 기능"
                  : "Geo control button function"}
              </th>
              <td>
                <div className="custom-checkbox">
                  <div className="custom-check-group">
                    <Radio
                      name={"d_control_yn"}
                      val={"Y"}
                      title={translate("사용", "Use")}
                      change={(e) => inputHandler(e)}
                      check={rawData.d_control_yn === "Y" ? true : false}
                    />
                    <Radio
                      name={"d_control_yn"}
                      val={"N"}
                      title={translate("미사용", "Not Use")}
                      change={(e) => inputHandler(e)}
                      check={rawData.d_control_yn === "N" ? true : false}
                    />
                  </div>
                </div>
              </td>
            </tr>
            { rawData?.org_type === "C" && (
              <tr>
                <th>
                  {language === "KOR"
                    ? "로그인 화면설정 기능	"
                    : "Login screen setting function"}
                </th>
                <td>
                  <div className="custom-checkbox">
                    <div className="custom-check-group">
                      <Radio
                        name={"login_set_yn"}
                        val={"Y"}
                        title={translate("사용", "Use")}
                        change={(e) => inputHandler(e)}
                        check={rawData.login_set_yn === "Y" ? true : false}
                      />
                      <Radio
                        name={"login_set_yn"}
                        val={"N"}
                        title={translate("미사용", "Not Use")}
                        change={(e) => inputHandler(e)}
                        check={rawData.login_set_yn === "N" ? true : false}
                      />
                    </div>
                  </div>
                </td>
                <th>
                  {language === "KOR" ? "sso 로그인 기능" : "sso login function"}
                </th>
                <td>
                  <div className="custom-checkbox">
                    <div className="custom-check-group">
                      <Radio
                        name={"sso_yn"}
                        val={"Y"}
                        title={translate("사용", "Use")}
                        change={(e) => inputHandler(e)}
                        check={rawData.sso_yn === "Y" ? true : false}
                      />
                      <Radio
                        name={"sso_yn"}
                        val={"N"}
                        title={translate("미사용", "Not Use")}
                        change={(e) => inputHandler(e)}
                        check={rawData.sso_yn !== "Y" ? true : false}
                      />
                    </div>
                  </div>
                </td>
              </tr>
            )}
            <tr>
              <th className={rawData?.org_type === "C" && rawData?.btoc_yn === "N" ? "required" : ""}>
                {language === "KOR"
                  ? "업종(대분류)"
                  : "Industry (large category)"}
              </th>
              <td>
                <Select
                  id="I_IDX1"
                  short={"N"}
                  name="I_IDX1"
                  options={
                    skci.first !== undefined
                      ? [
                          { name: `${translate("선택", "Select")}`, value: 0 },
                          ...skci.first.map((item: any) => ({
                            name: item.nm_kor,
                            value: parseInt(item.idx),
                          })),
                        ]
                      : []
                  }
                  changeFun={(e) => inputHandlerIDX(e)}
                  def={rawData.I_IDX1 || ""}
                />
              </td>

              <th className={rawData?.org_type === "C" && rawData?.btoc_yn === "N" ? "required" : ""}>
                {language === "KOR"
                  ? "업종(소분류)"
                  : "Type of business (sub-classification)"}
              </th>
              <td>
                <Select
                  id="I_IDX2"
                  short={"N"}
                  name="I_IDX2"
                  options={
                    skci.second !== undefined
                      ? [
                          { name: `${translate("선택", "Select")}`, value: "" },
                          ...skci.second
                            .filter(
                              (item: any) =>
                                item.CATEGORY_IDX === parseInt(codeIdx)
                            )
                            .map((item: any) => ({
                              name: item.nm_kor,
                              value: item.idx,
                            })),
                        ]
                      : []
                  }
                  changeFun={(e) => inputHandlerIDX(e)}
                  def={rawData.I_IDX2 || ""}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
