import React from "react";
import { ISODateToDateTime, add_hyphen } from "../../../lib/common_func";
import { useLang } from "../../../context/LanguageContext";

export default function DeviceLogMainExcel({ data, tableRef }) {
  const { language } = useLang();

  const colunm = [
    "번호",
    "단말번호",
    "단말기명",
    "기사",
    "일일 주행거리(km)",
    "누적 주행거리(km)",
    "최종 수신일시",
  ];
  const eng_colunm = [
    "No",
    "USIM num",
    "Device name",
    "Driver",
    "Today(km)",
    "Total(km)",
    "Last received",
  ];

  const style = {
    sort: {
      left: { textAlign: "left" },
      center: { textAlign: "center" },
      right: { textAlign: "right" },
    },
    border: { border: "1px solid #413c3c" },
    primary: { backgroundColor: "#DCE8F5" },
  };

  return (
    <>
      <table ref={tableRef} className="excel-table">
        <thead>
          <tr style={style.sort.center}>
            {language === "KOR"
              ? colunm.map((item, index) => (
                  <th key={index} style={{ ...style.primary, ...style.border }}>
                    {item}
                  </th>
                ))
              : eng_colunm.map((item, index) => (
                  <th key={index} style={{ ...style.primary, ...style.border }}>
                    {item}
                  </th>
                ))}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                <td style={{ ...style.sort.center, ...style.border }}>
                  {index + 1}
                </td>
                <td style={{ ...style.sort.center, ...style.border }}>
                  {add_hyphen(item.unitid)}
                </td>
                <td style={{ ...style.sort.right, ...style.border }}>
                  {item.unit_nm}
                </td>
                <td style={{ ...style.sort.right, ...style.border }}>
                  {item.u_nm ?? ""}
                </td>
                <td style={{ ...style.sort.right, ...style.border }}>
                  {parseFloat(item.day_dist).toFixed(2)}
                </td>
                <td style={{ ...style.sort.right, ...style.border }}>
                  {parseFloat(item.total_dist).toFixed(2)}
                </td>
                <td style={{ ...style.sort.center, ...style.border }}>
                  {item.date && ISODateToDateTime(item.date)}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}
