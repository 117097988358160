import PropTypes from "prop-types";
export default function Select({ inputName, options, id, value, changeFun }) {
  return (
    <>
      <div className="row-content">
        <div className="form-input">
          <select
            className="content select-event-calendar"
            id={id}
            name={inputName}
            value={value || ""}
            onChange={changeFun}
          >
            {options.map((option) => (
              <option key={option.idx} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

Select.propTypes = {
  inputName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      idx: PropTypes.number.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  changeFun: PropTypes.func,
  id: PropTypes.string,
};
