import React, { useEffect, useState } from "react";
import Table from "../organism/Table";
import Pagination from "../organism/Pagination";
import Search from "../organism/Search";
import Swal from "sweetalert2";
import "../style.sass";
interface Props {
  onClickFun: () => void;
  isOpen: boolean;
  language: string;
  axios: any;
  adminLogin: (e: any) => void;
  setIsOpen: (e: boolean) => void;
}

export default function Modal({
  onClickFun,
  adminLogin,
  isOpen,
  language,
  axios,
  setIsOpen,
}: Props) {
  const [loading, setLoading] = useState<boolean>(true);
  //main data
  const [data, setData] = useState<any[]>([]);
  // mutable data
  const [dataState, setDataState] = useState<any[]>([]);
  // pagination
  const [pages, setPages] = useState<any>([]);
  // search
  const [searchValue, setSearchValue] = useState<string>("");
  // Current Page
  const [currentPage, setCurrentPage] = useState<number>(1);
  // current page data
  const [currentData, setCurrentData] = useState<any[]>([]);
  // start and end of slice data Pagination rednder
  const [start, setStart] = useState<number>(10);
  // start and end of slice data Pagination rednder
  const [end, setEnd] = useState<number>(20);

  useEffect(() => {
    // upadate current page data what will be shown in one page
    const array: any[] = [];
    if (dataState.length > 0) {
      for (let i = 0; i < dataState.length; i++) {
        if (i >= currentPage * 10 - 10 && i < currentPage * 10) {
          array.push(dataState[i]);
        }
      }
    }
    setCurrentData(array);
  }, [currentPage, dataState]);

  useEffect(() => {
    if (searchValue.length > 0) {
      setDataState(Filter(data, searchValue));
    } else {
      setDataState(data);
    }
  }, [searchValue, data]);

  useEffect(() => {
    let array: any[] = [];
    const newNumberOfPages = Math.ceil(dataState.length / 10);
    // Split array into chunks of 10
    array = Array.from({ length: newNumberOfPages }, (_, index) => {
      const start = index * 10;
      return dataState.slice(start, start + 10);
    });
    setPages(array);
  }, [dataState]);

  useEffect(() => {
    if (data.length > 0) {
      setDataState(data.reverse());
    }
  }, [data]);

  useEffect(() => {
    if (isOpen) {
      axios
        .post("/web/admin/session/list")
        .then((response: { data: any[] }) => {
          const reversedData = response.data.reverse();
          setData(reversedData);
          setLoading(false);
        })
        .catch((error?: any) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [isOpen, axios]);

  function Filter(data: any[], searchValue: string) {
    const arr: any[] = [];
    data.filter((item) => {
      // Iterate through each object's properties
      for (const key in item) {
        if (typeof item[key] === "string" && item[key].includes(searchValue)) {
          return arr.push(item);
        }
      }
      setCurrentPage(1);
      return false;
    });

    return arr; // Return the filtered data
  }
  function searchHandler(value: string) {
    setSearchValue(value);
  }

  function pageClick(pageNumber: number) {
    setCurrentPage(pageNumber);
  }
  function next(pageNumber: number) {
    if (pageNumber > end && end + 10 <= pages.length) {
      // Check if moving forward is within bounds
    } else if (pageNumber < start && start - 10 >= 0) {
      // Check if moving backward is within bounds
      setStart(start - 10);
      setEnd(end - 10);
    }

    // Ensure that the current page doesn't go beyond the total number of pages
    if (pageNumber <= pages.length) {
      setCurrentPage(pageNumber);
    }
  }

  function lastPageSet() {
    setCurrentPage(pages.length);
    setStart(pages.length - 10);
    setEnd(pages.length);
  }
  function firstPageSet() {
    setCurrentPage(1);
    setStart(1);
    setEnd(10);
  }

  function loginInit(e: any) {
    Swal.fire({
      title:
        language === "KOR"
          ? "해당 계정으로 로그인하시겠습니까?"
          : "Are you sure you want to log in with that account?",
      confirmButtonText: language === "KOR" ? "확인" : "OK",
      showCancelButton: true,
      cancelButtonText: language === "KOR" ? "취소" : "Cancel",
      width: "400px",
      padding: "20px",
      heightAuto: false,
      icon: "question",
      customClass: {
        
        cancelButton: "swal-cancel-btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setIsOpen(false);
        adminLogin(e);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log(e);
      }
    });
  }
  return (
    <div id="crew-search-panel" className="common-modal on">
      <div className="modal-inner">
        <div className="modal-page">
          <div className="page-header">
            <div className="page-title">
              <span>{language === "KOR" ? "조직검색" : "Org.Search"}</span>
            </div>
            <button onClick={onClickFun} type="button" className="modal-close">
              <i className="ri-close-fill icon"></i>
            </button>
          </div>
          <div className="page-body">
            <div className="flex-container">
              <Search
                language={language}
                searchHandler={searchHandler}
                searchValue={searchValue}
              />
              <div className="common-table-wrapper">
                <Table
                  loading={loading}
                  language={language}
                  data={currentData}
                  adminLogin={loginInit}
                />
                <Pagination
                  pages={pages}
                  paginate={pageClick}
                  next={next}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  lastPageSet={lastPageSet}
                  firstPageSet={firstPageSet}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
