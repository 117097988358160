import React, { useState, useEffect } from "react";
import Radio from "../../../../Atomic/atoms/Radio";
import InputForm from "../../../../Atomic/atoms/InputForm";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../../lib/axios.js";
//@ts-ignore
import { dataList } from "../../../AdmMain";
import { DeleteFun } from "../../../../Atomic/exFunc";
import CommonControl from "../../../../Atomic/organism/CommonControl";
import Page from "../../../../Atomic/template/PageTable";
import moment from "moment";
import Select from "../../../../Atomic/molecules/Select";
import { ChangeEvent } from "react";
// @ts-ignore
export default function Form() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const navi = useNavigate();
  const { user } = useAuth();
  // State defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [rawData, setRawData] = useState<any>({});
  const [file, setFile] = useState<File>();
  //=======================================================================
  // Function defines
  //=======================================================================
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };
  const handleUploadClick = () => {
    if (!file) {
      return;
    }

    console.log(file);
  };

  function inputHandler(e: any) {
    const { name, value } = e.target;
    setRawData((pre: any) => ({ ...pre, [name]: value }));
  }

  function createNew(path: string) {
    if (rawData.prod_legal_nm === "" || rawData.prod_legal_nm === undefined) {
      Swal.fire({
        title: `${translate("제품명을 입력해주세요", "Please enter the name")}`,
      });
      return;
    }
    if (rawData.model_nm === "" || rawData.model_nm === undefined) {
      Swal.fire({
        title: `${translate(
          "모델명을 입력해주세요",
          "Please enter the model name"
        )}`,
      });
      return;
    }
    if (rawData.prod_nm === "" || rawData.prod_nm === undefined) {
      Swal.fire({
        title: `${translate(
          "기자재명칭을 입력해주세요",
          "Please enter the prod name"
        )}`,
      });
      return;
    }
    if (rawData.kc_num === "" || rawData.kc_num === undefined) {
      Swal.fire({
        title: `${translate(
          "KC 인증번호을 입력해주세요",
          "Please enter the KC Certification Number"
        )}`,
      });
      return;
    }
    console.log(rawData);

    // axios.post(path, rawData).then((res: any) => {
    //   if (res.status === 200) {
    //     Swal.fire({
    //       title: "등록되었습니다.",
    //     }).then((result) => {
    //       navi("/Adm/d-table");
    //     });
    //   } else {
    //     Swal.fire({
    //       title: "등록에 실패하였습니다.",
    //     });
    //   }
    // });
  }

  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "삭제",
      labelEN: "Delete",
      classN: "white",
      icon: true,
      clickFun: () => {
        DeleteFun(`/web/admin/user/del`, { idx: idx, type: "ADM" }, navi);
      },
      position: " right",
    },
    {
      labelKR: "수정",
      labelEN: "Edit",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew(`/web/admin/prod/edit`);
      },
      position: "",
    },
  ];
  const buttonDataAdd = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "수정",
      labelEN: "Edit",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew(`/web/admin/prod/add`);
      },
      position: "",
    },
  ];

  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
  }, [location]);
  useEffect(() => {
    if (idx !== "") {
      axios
        .get(
          `/web/admin/prod/detail?idx=${idx}&type=${user.type}`
        )
        .then((res: any) => {
          setRawData(res.data);
          setIsAdd(false);
        });
    } else {
      setRawData({ service_yn_2: "Y" });
      setIsAdd(true);
    }
  }, [idx, user.type]);

  // Log defines
  //=======================================================================

  // console.log("rawData", rawData);
  // console.log("state", location.state);
  // console.log("idx", idx);
  // console.log("CompanyData", companyData);
  // console.log("user", user.type);
  // console.log("refId", refId.current?.value);

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "품목 등록" : "Item registration"}`}
        menu="/Adm/dev-table"
      >
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <InputForm
                required="required"
                label={translate("제품명", "Item name")}
                auto="off"
                type="text"
                val={rawData.prod_legal_nm}
                changeFun={inputHandler}
                name="prod_legal_nm"
                readonly={false}
              />
              <InputForm
                required="required"
                label={translate("기자재명칭", "Equipment Name")}
                auto="off"
                type="text"
                val={rawData.prod_nm}
                changeFun={inputHandler}
                name="prod_nm"
                readonly={false}
              />
            </tr>
            <tr>
              <InputForm
                required="required"
                label={translate("모델명	", "Model Name")}
                auto="off"
                type="text"
                val={rawData.model_nm}
                changeFun={inputHandler}
                name="model_nm"
                readonly={false}
              />
              <InputForm
                label={translate("KC 인증번호", "KC Certification Number")}
                name="kc_num"
                val={rawData.kc_num}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
            </tr>
            <tr>
              <th>{translate("서비스 유무", "Service availability")}</th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"service_yn_2"}
                    val={"Y"}
                    title={translate("유", "Use")}
                    change={(e) => inputHandler(e)}
                    check={rawData.service_yn_2 === "Y" ? true : false}
                  />
                  <Radio
                    name={"service_yn_2"}
                    val={"N"}
                    title={translate("무", "Not use")}
                    change={(e) => inputHandler(e)}
                    check={rawData.service_yn_2 === "N" ? true : false}
                  />
                </div>
              </td>
              <InputForm
                label={translate("거래처	", "Client Name")}
                name="made_company"
                val={rawData.made_company}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required=""
                readonly={false}
              />
            </tr>
            <tr>
              <InputForm
                label={translate("제조국", "Made in")}
                name="made_nation"
                val={rawData.made_nation}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required=""
                readonly={false}
              />
              <InputForm
                label={translate("제조번호", "Serial number")}
                name="prod_num"
                val={rawData.prod_num}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required=""
                readonly={false}
              />
            </tr>
            <tr>
              <InputForm
                label={translate("제조년월", "Made date")}
                name="prod_date"
                val={`${moment.utc(rawData.prod_date).format("YYYY-MM-DD")}`}
                auto="off"
                type="date"
                changeFun={inputHandler}
                required=""
                readonly={false}
              />

              <th className="required">
                {translate("단말기 보증기간", "Product warranty period")}
              </th>
              <td>
                <Select
                  id="device_warranty"
                  short={"N"}
                  name="device_warranty"
                  options={[
                    {
                      name: "6개월",
                      value: "6개월",
                    },
                    {
                      name: "12개월",
                      value: "12개월",
                    },
                    {
                      name: "18개월",
                      value: "18개월",
                    },
                    {
                      name: "24개월",
                      value: "24개월",
                    },
                    {
                      name: "30개월",
                      value: "30개월",
                    },
                    {
                      name: "36개월",
                      value: "36개월",
                    },
                  ]}
                  changeFun={(e) => inputHandler(e)}
                  def={rawData.I_IDX1 || ""}
                />
              </td>
            </tr>
            <tr>
              <th className="required">
                {translate("배터리 보증기간", "Battery warranty period")}
              </th>
              <td>
                <Select
                  id="battery_warranty"
                  short={"N"}
                  name="battery_warranty"
                  options={[
                    {
                      name: "6개월",
                      value: "6개월",
                    },
                    {
                      name: "12개월",
                      value: "12개월",
                    },
                    {
                      name: "18개월",
                      value: "18개월",
                    },
                    {
                      name: "24개월",
                      value: "24개월",
                    },
                    {
                      name: "30개월",
                      value: "30개월",
                    },
                    {
                      name: "36개월",
                      value: "36개월",
                    },
                  ]}
                  changeFun={(e) => inputHandler(e)}
                  def={rawData.I_IDX1 || ""}
                />
              </td>

              <th className="required">
                {translate("수리용부품 보유기간	", "Spare parts holding period")}
              </th>
              <td>
                <Select
                  id="parts_warranty"
                  short={"N"}
                  name="parts_warranty"
                  options={[
                    {
                      name: "6개월",
                      value: "6개월",
                    },
                    {
                      name: "12개월",
                      value: "12개월",
                    },
                    {
                      name: "18개월",
                      value: "18개월",
                    },
                    {
                      name: "24개월",
                      value: "24개월",
                    },
                    {
                      name: "30개월",
                      value: "30개월",
                    },
                    {
                      name: "36개월",
                      value: "36개월",
                    },
                  ]}
                  changeFun={(e) => inputHandler(e)}
                  def={rawData.I_IDX1 || ""}
                />
              </td>
            </tr>
            <tr>
              <InputForm
                label={translate("분류", "Classification")}
                name="category"
                val={rawData.category}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required=""
                readonly={false}
              />
              <InputForm
                label={translate(
                  "초판 생산년",
                  "First edition production year"
                )}
                name="prototype_date"
                val={rawData.prototype_date}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required=""
                readonly={false}
              />
            </tr>
            <tr>
              <InputForm
                label={translate("Revistion No.", "Revistion No.")}
                name="rev_no"
                val={rawData.rev_no}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required=""
                readonly={false}
              />
              <th>{translate("도면 첨부", "Attaching Drawings")}</th>
              <td>
                <div className="flex">
                  <input type="file" onChange={handleFileChange} />
                  <button
                    className="common-btn white "
                    onClick={handleUploadClick}
                  >
                    Upload
                  </button>
                </div>
              </td>
            </tr>
            <tr>
              <InputForm
                label={translate("상호명", "Trade Name")}
                name="company_nm"
                val={rawData.company_nm}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required=""
                readonly={false}
              />
              <InputForm
                label={translate("신호 암호화 비트", "Signal encryption bit")}
                name="encrypt_bit"
                val={rawData.encrypt_bit}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required=""
                readonly={false}
              />
            </tr>
            <tr>
              <th>{translate("무선/유선", "Wireless / Wired")}</th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"wire_type"}
                    val={"Y"}
                    title={translate("유선", "Wireless")}
                    change={(e) => inputHandler(e)}
                    check={rawData.wire_type === "Y" ? true : false}
                  />
                  <Radio
                    name={"wire_type"}
                    val={"N"}
                    title={translate("무선", "Wired")}
                    change={(e) => inputHandler(e)}
                    check={rawData.wire_type === "N" ? true : false}
                  />
                </div>
              </td>
              <th>{translate("카메라 유/무", "Camera ")}</th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"camera_yn"}
                    val={"Y"}
                    title={translate("유", "Wireless")}
                    change={(e) => inputHandler(e)}
                    check={rawData.camera_yn === "Y" ? true : false}
                  />
                  <Radio
                    name={"camera_yn"}
                    val={"N"}
                    title={translate("무", "Wired")}
                    change={(e) => inputHandler(e)}
                    check={rawData.camera_yn === "N" ? true : false}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {isAdd ? (
          <CommonControl buttonData={buttonDataAdd} language={language} />
        ) : (
          <CommonControl buttonData={buttonData} language={language} />
        )}
      </Page>
    </>
  );
}
