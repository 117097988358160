import React from "react";
import { NavLink } from "react-router-dom";
interface GNBProps {
  language: string;
  pages: string;
  labelKr: string;
  labelEn: string;
  icon: string;
  link: string;
}
export default function GNB({
  language,
  pages,
  labelKr,
  labelEn,
  icon,
  link,
}: GNBProps) {
  return (
    <>
      <li className="list-item">
        <NavLink
          to={`${link}`}
          className={`nav-btn ${link === pages ? "active" : ""}`}
        >
          <div className="btn-icon">
            <i className={`icon ${icon}`}></i>
          </div>
          <div className="btn-label">
            {language === "KOR" ? `${labelKr}` : `${labelEn}`}
          </div>
        </NavLink>
      </li>
    </>
  );
}
