const { naver } = window;

export function getState(key) {
    const _key = String(key).slice(-1);
    const statusType = {
        0: 'start',
        1: 'pause',
        2: 'end',
        4: 'start',
        5: 'pause',
        6: 'end',
        7: 'start',
        8: 'pause',
        9: 'end',
    };
    return statusType[_key] || 'etc';
}

export function getStateKOR(key) {
    const _key = getState(key);
    const statusType = {
        start: '주행',
        pause: '정차',
        end: '종료',
    };
    return statusType[_key];
}

export function getMarkerType(key) {
    const makerType = {
        IC0100 : 'car start',
        IC0110 : 'car pause',
        IC0120 : 'car end',
        IC0101 : 'car start bluetooth',
        IC0111 : 'car pause bluetooth',
        IC0121 : 'car end bluetooth',
        
        IC0200 : 'motorcycle start',
        IC0210 : 'motorcycle pause',
        IC0220 : 'motorcycle end',
        IC0201 : 'motorcycle start bluetooth',
        IC0211 : 'motorcycle pause bluetooth',
        IC0221 : 'motorcycle end bluetooth',

        IC0300 : 'human start',
        IC0310 : 'human pause',
        IC0320 : 'human end',
        IC0301 : 'human start bluetooth',
        IC0311 : 'human pause bluetooth',
        IC0321 : 'human end bluetooth',
        
        IC0400 : 'bike start',
        IC0410 : 'bike pause',
        IC0420 : 'bike end',
        IC0401 : 'bike start bluetooth',
        IC0411 : 'bike pause bluetooth',
        IC0421 : 'bike end bluetooth',

        IC0500 : 'truck start',
        IC0510 : 'truck pause',
        IC0520 : 'truck end',
        IC0501 : 'truck start bluetooth',
        IC0511 : 'truck pause bluetooth',
        IC0521 : 'truck end bluetooth',

        IC0600 : 'trash start',
        IC0610 : 'trash pause',
        IC0620 : 'trash end',
        IC0601 : 'trash start bluetooth',
        IC0611 : 'trash pause bluetooth',
        IC0621 : 'trash end bluetooth',

        IC0700 : 'school start',
        IC0710 : 'school pause',
        IC0720 : 'school end',
        IC0701 : 'school start bluetooth',
        IC0711 : 'school pause bluetooth',
        IC0721 : 'school end bluetooth',

        IC0800 : 'airplane start',
        IC0810 : 'airplane pause',
        IC0820 : 'airplane end',
        IC0801 : 'airplane start bluetooth',
        IC0811 : 'airplane pause bluetooth',
        IC0821 : 'airplane end bluetooth',

        IC0900 : 'ship start',
        IC0910 : 'ship pause',
        IC0920 : 'ship end',
        IC0901 : 'ship start bluetooth',
        IC0911 : 'ship pause bluetooth',
        IC0921 : 'ship end bluetooth',

        IC1000 : 'crane start',
        IC1010 : 'crane pause',
        IC1020 : 'crane end',
        IC1001 : 'crane start bluetooth',
        IC1011 : 'crane pause bluetooth',
        IC1021 : 'crane end bluetooth',

        IC1100 : 'pet start',
        IC1110 : 'pet pause',
        IC1120 : 'pet end',
        IC1101 : 'pet start bluetooth',
        IC1111 : 'pet pause bluetooth',
        IC1121 : 'pet end bluetooth',
    };
    return makerType[key] || '';
}
export function getIconNumber(iconnum) {
    const icon = String(iconnum).slice(0,4);
    const name = {
        IC01: 'car',
        IC02: 'motorcycle',
        IC03: 'human',
        IC04: 'bike',
        IC05: 'truck',
        IC06: 'trash',
        IC07: 'school',
        IC08: 'airplane',
        IC09: 'ship',
        IC10: 'crane',
        IC11: 'pet',
    };

    return name[icon] || '';
}
export function getPhoneNumber(key) {
    return key.substr(2, 3) + '-' + key.substr(5, 4) + '-' + key.substr(9, 4);
}

export function getCenter(data) {
    const y = [];
    const x = [];

    data.forEach((item) => {
        y.push(+item.latitude);
        x.push(+item.longitude);
    });

    const maxY = Math.max.apply(Math, y);
    const minY = Math.min.apply(Math, y);
    const maxX = Math.max.apply(Math, x);
    const minX = Math.min.apply(Math, x);

    const centerY = minY + (maxY - minY) / 2;
    const centerX = minX + (maxX - minX) / 2;

    return { centerY, centerX };
}

export function searchCoordToAddress(position) {
    return new Promise((resolve, reject) => {
        naver.maps.Service.reverseGeocode(
            {
                coords: position,
                orders: [
                    naver.maps.Service.OrderType.ADDR,
                    naver.maps.Service.OrderType.ROAD_ADDR,
                ].join(','),
            },
            function (status, response) {
                if (status === naver.maps.Service.Status.ERROR) {
                    reject();
                } else {
                    resolve(response.v2.address);
                }
            }
        );
    });
}

export async function regionSearchByCoords(position) {
    return new Promise((resolve, reject) => {
        naver.maps.Service.reverseGeocode(
            {
                coords: position,
                orders: [
                    naver.maps.Service.OrderType.ADDR,
                    naver.maps.Service.OrderType.ROAD_ADDR,
                ].join(','),
            },
            function (status, response) {
                if (status === naver.maps.Service.Status.ERROR) {
                    reject();
                } else {
                    searchByAddr(response.v2.address, reject, resolve);
                }
            }
        );
    });
}

export function searchByAddr(address, reject, resolve) {
    if (!address) {
        reject();
    }

    naver.maps.Service.geocode(
        {
            query: address.jibunAddress,
        },
        function (status, response) {
            if (status === naver.maps.Service.Status.ERROR) {
                reject();
            } else {
                if (response.v2.meta.totalCount === 0) {
                    alert('검색된 결과가 없습니다.');
                    reject();
                } else {
                    resolve(response.v2.addresses[0]);
                }
            }
        }
    );
}

export function searchByDaumPost(address) {
    return new Promise((resolve, reject) => {
        naver.maps.Service.geocode(
            {
                query: address,
            },
            function (status, response) {
                if (status === naver.maps.Service.Status.ERROR) {
                    reject();
                } else {
                    if (response.v2.meta.totalCount === 0) {
                        alert('검색된 결과가 없습니다.');
                        reject();
                    } else {
                        resolve(response.v2.addresses[0]);
                    }
                }
            }
        );
    });
}
