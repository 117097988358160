import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../../lib/axios.js";
//@ts-ignore
import { dataList } from "../../../AdmMain";
import Select from "../../../../Atomic/molecules/Select";
import CommonControl from "../../../../Atomic/organism/CommonControl";
import Page from "../../../../Atomic/template/PageTable";
import TelInput from "../../../../Atomic/atoms/Tel_Input";
import InputForm from "../../../../Atomic/atoms/InputForm";
// @ts-ignore
import Email from "../../../../Atomic/atoms/Email.jsx";
import Radio from "../../../../Atomic/atoms/Radio";
//@ts-ignores
import InputFilter from "../../../../Atomic/organism/InputFilter";
export default function Form() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const { user } = useAuth();

  // State defines
  //=======================================================================
  const [rawData, setRawData] = useState<any>({
    type: user.type,
    AG_IDX: "",
    act_yn: "Y",
    all_yn: "Y",
    depart: "",
    email: "",
    grade: "",
    hp: "",
    id: "",
    idx: user.idx,
    manager: "",
    nm: "",
    org_idx: null,
    org_nm: "",
    refresh_time: "60",
    sso_yn: "",
    tel: "",
    u_type: "",
  });
  const [idReadOnly, setIdReadOnly] = useState<boolean>(false);
  function checkID() {
    if (refId.current?.value === "") {
      Swal.fire({
        title: "ID를 입력해주세요.",
      });
      return;
    }
    axios
      .post("/web/org/users/checkID", {
        id: refId.current?.value,
      })
      .then((res: any) => {
        if (res.data === "OK") {
          setIdReadOnly(true);
          setRawData((pre: any) => ({ ...pre, id: refId.current?.value }));
          Swal.fire({
            title: "사용 가능한 ID입니다.",
          });
        } else {
          Swal.fire({
            title: "사용 불가능한 ID입니다.",
          });
          setIdReadOnly(false);
          refId.current?.focus();
        }
      });
  }
  function clearID() {
    setRawData((pre: any) => ({ ...pre, id: "" }));
    setIdReadOnly(false);
  }
  //=======================================================================
  const [CPN, setCPN] = useState<any>("");
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [CPNData, setCPNData] = useState<any>([]);
  const [companyData, setCompanyData] = useState<any>([]);
  const [isChanged, setIsChanged] = useState<boolean>(true);
  const [buso, setBuso] = useState<any>([]);
  const [busoDirect, setBusoDirect] = useState<boolean>(false);
  const [DI, setDI] = useState();
  const [deviceData, setDeviceData] = useState([]);
  //=======================================================================
  const refId = React.useRef<HTMLInputElement>(null);
  // Function defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const hpHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp: string[] = rawData.hp.split("-");
    switch (name) {
      case "hp1":
        temp[0] = value;
        break;
      case "hp2":
        temp[1] = value;
        break;
      case "hp3":
        temp[2] = value;
        break;
      default:
        break;
    }
    if (temp.some((segment) => segment === "0000")) {
      alert("0000은 입력할 수 없습니다.");
      temp = temp.map((segment) => (segment === "0000" ? "" : segment));
    }
    setRawData((prev: any) => ({ ...prev, hp: temp.join("-") }));
  };
  const TelHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp: string[] = rawData.tel.split("-");

    switch (name) {
      case "tel1":
        temp[0] = value;
        break;
      case "tel2":
      case "tel3":
        const index = parseInt(name.charAt(name.length - 1)) - 1;
        temp[index] = value;

        if (temp[index] === "0000") {
          alert("0000은 입력할 수 없습니다.");
          temp[index] = "";
        }
        break;
      default:
        break;
    }

    setRawData((prev: any) => ({ ...prev, tel: temp.join("-") }));
  };
  //=======================================================================
  function onChangeFilter(e: any) {
    setCPN(e.target.value);
    handleFilterChange(e);
  }
  function handleFilterChange(e: any) {
    const searchText = e.target.value;

    if (searchText === "") {
      setCPNData([]);
      return;
    }
    const filteredData = companyData.filter((item: any) => {
      return Object.values(item).some(
        (value) => typeof value === "string" && value.includes(searchText)
      );
    });
    setCPNData(filteredData);
  }
  function editCPN(e: any) {
    if (!isReadOnly) {
      setIsChanged(true);
    }
  }
  function clearinput() {
    setIsReadOnly(false);
    setCPN("");
    setCPNData([]);
  }
  function inputHandler(e: any) {
    const { name, value } = e.target;
    setRawData((pre: any) => ({ ...pre, [name]: value }));
  }
  function createNew() {
    if(rawData.org_idx === null) {
      Swal.fire({
        title: "소속을 선택해주세요.",
      });
      return;
    }
    if (rawData.id === "") {
      Swal.fire({
        title: "ID을 입력해주세요.",
      });
      return;
    }
    if (rawData.nm === "") {
      Swal.fire({
        title: "사용자명을 입력해주세요.",
      });
      return;
    }    
    if (rawData.grade === "") {
      Swal.fire({
        title: "모바일 로그인 등급을 선택해주세요.",
      });
      return;
    }
    if (rawData.pwd !== "" && rawData.pwd2) {
      if (rawData.pwd.length < 4) {
        Swal.fire({
          title: "비밀번호는 4자리 이상 입력해주세요.",
        });
        return;
      }
      if (rawData.pwd2 === undefined) {
        Swal.fire({
          title: "비밀번호를 입력해주세요.",
        });
        return;
      }
      if (rawData.pwd !== rawData.pwd2) {
        Swal.fire({
          title: "비밀번호가 일치하지 않습니다.",
        });
        return;
      }
    } else {
      Swal.fire({
        title: "비밀번호를 입력해주세요.",
      })
      return;
    }
    if (rawData.hp.length < 13) {
      Swal.fire({
        title: "휴대폰 번호를 입력해주세요.",
      });
      return;
    }
    if (rawData.act_yn === "") {
      Swal.fire({
        title: "사용여부를 선택해주세요.",
      });
      return;
    }
    if (rawData.all_yn === "") {
      Swal.fire({
        title: "접속허가를 선택해주세요.",
      });
      return;
    }
    if (rawData.AG_IDX === "") {
      Swal.fire({
        title: "대메뉴 권한그룹을 선택해주세요.",
      });
      return;
    }
    // if (rawData.menu_auth === "") {
    //   Swal.fire({
    //     title: "대메뉴 권한 그룹 입력해주세요.",
    //   });
    //   return;
    // }

    Swal.fire({
      title: `${language === "KOR" ? "등록하시겠습니까?" : "Create?"}`,
      showCancelButton: true,
      confirmButtonText: `${language === "KOR" ? "등록" : "Create"}`,
      cancelButtonText: `${language === "KOR" ? "취소" : "Cancel"}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post("/web/admin/user/add", rawData)
          .then((res: any) => {
            Swal.fire({
              title: `${language === "KOR" ? "등록되었습니다." : "Created"}`,
            });
            window.history.back();
          })
          .catch((err: any) => {
            Swal.fire({
              title: `${
                language === "KOR" ? "등록에 실패하였습니다." : "Failed"
              }`,
            });
            console.log(err);
          });
      }
    });
  }
  function onClickFilterDevice(e: any) {
    setRawData((prev: any) => ({
      ...prev,
      unitid: e.unitid,
    }));
    setDI(e.unitid);
  }
  const DeviceShow = (e: any) => {
    return `${e.unitid}`;
  };

  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },

    {
      labelKR: "등록",
      labelEN: "Create",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];
  const liStyle = {
    padding: "10px",
    width: "100%",
    cursor: "pointer",
    borderBottom: "1px solid gray",
  };
  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
  }, [location]);

  useEffect(() => {
    function getCompanyData() {
      axios
        .get(`/web/admin/org/name?type=ADM`)
        .then((res: any) => {
          setCompanyData(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    axios.get(`/web/admin/depart/list?type=ADM`).then((res: any) => {
      setBuso(res.data);
    });
    getCompanyData();
  }, [user]);

  useEffect(() => {
    if (idx !== "") {
      axios
        .get(`/web/admin/user/detail?idx=${idx}&type=${user.type}`)
        .then((res: any) => {
          setRawData(res.data);
          setCPN(
            `${
              res.data.u_type === "C"
                ? "회사"
                : res.data.u_type === "B"
                ? "지점"
                : res.data.u_type === "A"
                ? "대리점"
                : ""
            } > ${res.data.org_nm}`
          );
        });
    } else {
    }
  }, [idx, user.type]);

  useEffect(() => {
    if (rawData.depart === "직접입력") {
      setBusoDirect(true);
    }
  }, [rawData.depart]);

  // Log defines
  //=======================================================================

  // console.log("rawData", rawData);
  // console.log(deviceData);

  // console.log("state", location.state);
  // console.log("idx", idx);
  // console.log("CompanyData", companyData);
  // console.log(CPNData);
  // console.log("companyData", companyData);
  // console.log("user", user.type);
  // console.log("refId", refId.current?.value);
  function resetOrg() {
    //@ts-ignore
    setDI("");
    setRawData((prev : any) => ({
      ...prev,
      org_idx: null,
      agt_idx: null,
      brc_idx: null,
      cmp_idx: null,
      u_type: "",
      unitid: "",
      sso_yn: "",
      grade: prev.grade === "FDV" ? "DV" : prev.grade,
    }))
  }
  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "사용자 관리" : "User management"}`}
        menu="/Adm/o-table"
      >
        <table
          className="common-view-table"
          style={{
            marginBottom: "50px",
          }}
        >
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th className="required">
                {translate("회사/지점/대리점명", "CO/BRC/AGT Name")}
              </th>
              <td
                colSpan={3}
                style={{
                  position: "relative",
                }}
              >
                <div className="flex">
                  <div className="custom-input wide">
                    <input
                      // onBlur={setIsChanged(false)}
                      name="org_nm"
                      type="text"
                      placeholder={translate(
                        "회사/지점/대리점명을 입력해주세요",
                        "Enter Company/Branch/Agent name"
                      )}
                      onChange={onChangeFilter}
                      onFocus={(e) => editCPN(e)}
                      readOnly={isReadOnly}
                      value={CPN || ""}
                      autoComplete="off"
                    />
                  </div>
                  <button
                    type="button"
                    className="common-btn white sm"
                    onClick={() => {
                      resetOrg()
                      clearinput()
                    }}
                  >
                    <span>{translate("초기화", "Reset")}</span>
                  </button>
                </div>
                {isChanged && CPNData.length > 0 ? (
                  <ul
                    className="search-list"
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      zIndex: 100,
                      width: "80%",
                      marginTop: "5px",
                      maxHeight: "200px",
                      overflow: "scroll",
                      border: "1px solid #cccc",
                      overflowX: "hidden",
                    }}
                  >
                    {CPNData.map((item: any, index: any) => (
                      <li
                        style={liStyle}
                        key={index}
                        onClick={() => {
                          setRawData((prev: any) => ({
                            ...prev,
                            org_type: item.org_type,
                            btoc_yn: item.btoc_yn,
                          }));
                          setCPN(
                            `${
                              item.org_type === "C"
                                ? translate("[회사]", "[Company]")
                                : item.org_type === "B"
                                ? translate("[지점]", "[Branch]")
                                : item.org_type === "A"
                                ? translate("[대리점]", "[Agent]")
                                : ""
                            } ${item.cmp_nm}${
                              item.brc_nm === null ? "" : " > "
                            }${item.brc_nm === null ? "" : item.brc_nm}${
                              item.agt_nm === null ? "" : " > "
                            }${item.agt_nm === null ? "" : item.agt_nm
                            } (회사코드 : ${item.org_cd})`
                          );
                          setRawData((prev: any) => ({
                            ...prev,
                            org_idx: item.idx,
                            agt_idx: item.agt_idx,
                            brc_idx: item.brc_idx,
                            cmp_idx: item.cmp_idx,
                            u_type: item.org_type,
                            sso_yn: item.sso_yn === "Y" ? "Y" : "N",
                          }));
                          setIsChanged(false);
                          setIsReadOnly(true);
                        }}
                      >
                        {item.org_type === "C"
                          ? translate("[회사]", "[Company]")
                          : item.org_type === "B"
                          ? translate("[지점]", "[Branch]")
                          : item.org_type === "A"
                          ? translate("[대리점]", "[Agent]")
                          : ""}
                        {` ${item.cmp_nm}`}
                        {item.brc_nm === null ? "" : ` > ${item.brc_nm}`}
                        {item.agt_nm === null ? "" : ` > ${item.agt_nm}`}
                        {` (회사코드 : ${item.org_cd})`}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </td>
            </tr>
            <tr>
              <th className="required">{translate("사용자 ID", "ID")}</th>
              <td>
                <div className="flex">
                  <div className="custom-input">
                    <input
                      name="id"
                      type="text"
                      // onChange={inputHandler}
                      ref={refId}
                      // value={rawData.id}
                      defaultValue={rawData.id}
                      readOnly={idReadOnly === true ? true : false}
                    />
                  </div>
                  <button
                    type="button"
                    className="common-btn purple sm"
                    onClick={(e) => checkID()}
                  >
                    <span>{translate("중복확인", "Check ID")}</span>
                  </button>
                  <button
                    type="button"
                    className="common-btn white  sm"
                    onClick={() => clearID()}
                  >
                    <span>{translate("초기화", "Reset")}</span>
                  </button>
                </div>
              </td>
              <th>{translate("Map 화면갱신주기", "Map Refresh Time")}</th>
              <td>
                <Select
                  id="refresh_time"
                  short={"N"}
                  name="refresh_time"
                  options={[
                    {
                      name: translate("미설정", "No"),
                      value: "9999",
                    },
                    {
                      name: `10 ${translate("초", "sec")}`,
                      value: "10",
                    },
                    {
                      name: `20 ${translate("초", "sec")}`,
                      value: "20",
                    },
                    {
                      name: `30 ${translate("초", "sec")}`,
                      value: "30",
                    },
                    {
                      name: `1 ${translate("분", "min")}`,
                      value: "60",
                    },
                    {
                      name: `2 ${translate("분", "min")}`,
                      value: "120",
                    },
                    {
                      name: `3 ${translate("분", "min")}`,
                      value: "180",
                    },
                  ]}
                  changeFun={(e) => inputHandler(e)}
                  def={rawData.refresh_time}
                />
              </td>
            </tr>
            <tr>
              <InputForm
                required={"required"}
                label={translate("사용자명", "User Name")}
                auto={"off"}
                type={"text"}
                val={rawData.nm}
                changeFun={(e) => inputHandler(e)}
                name={"nm"}
              />
              <th className="required">
                {translate("모바일 로그인 등급", "Mobile Login Grade")}
              </th>
              <td>
                <div
                  className="flex"
                  style={{
                    position: "relative",
                  }}
                >
                  <Select
                    id="grade"
                    short={""}
                    name="grade"
                    options={[
                      {
                        name: translate("-- 등급선택 --", "-- Select --"),
                        value: "",
                      },
                      {
                        name: translate("일반 고객", "User"),
                        value: "CS",
                      },
                      {
                        name: translate("관리자", "Admin"),
                        value: "AD",
                      },
                      {
                        name: translate("기사", "Driver"),
                        value: "DV",
                      },
                    ]}
                    changeFun={(e) => inputHandler(e)}
                    def={rawData.grade}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th className="required">{translate("비밀번호", "Password")}</th>
              <td>
                <div className="custom-input">
                  <input
                    type="password"
                    id="pwd"
                    name="pwd"
                    onChange={inputHandler}
                    value={rawData.password}
                  />
                </div>
              </td>
              <th className="required">
                {translate("비밀번호 확인", "Password Confirm")}
              </th>
              <td>
                <div className="custom-input">
                  <input
                    name="pwd2"
                    type="password"
                    onChange={inputHandler}
                    value={rawData.password}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>{translate("부서", "Depart")}</th>
              <td>
                <Select
                  id="depart"
                  short="N"
                  options={[
                    {
                      name: language === "KOR" ? "===========" : "===========",
                      value: "",
                    },
                    ...buso.map((item: any) => ({
                      name: item.nm,
                      value: item.nm,
                    })),
                    {
                      name: language === "KOR" ? "직접입력" : "Direct",
                      value: "직접입력",
                    },
                  ]}
                  def={rawData.depart === null ? "" : rawData.depart}
                  name="depart"
                  changeFun={(e) => inputHandler(e)}
                />
                {busoDirect ? (
                  <div
                    style={{
                      display: "flex",
                      gap: 10,
                      flexDirection: "row",
                      marginTop: 10,
                      width: "100%",
                    }}
                  >
                    <div
                      className="custom-input"
                      style={{
                        width: "100%",
                      }}
                    >
                      <input
                        name="depart"
                        type="text"
                        onChange={inputHandler}
                        value={rawData.depart || ""}
                      />
                    </div>
                    <button
                      name="org_nm"
                      type="button"
                      className="common-btn white sm"
                      onClick={() => {
                        setBusoDirect(false);
                        setRawData((prev: any) => ({
                          ...prev,
                          depart: "",
                        }));
                      }}
                    >
                      초기화
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </td>
              <th className="required">
                {translate("휴대폰 번호", "Phone Number")}
              </th>
              <td>
                <div className="flex">
                  <TelInput
                    id="hp1"
                    name="hp1"
                    value={rawData.hp.split("-")[0]}
                    changeFun={hpHandler}
                    readOnly={false}
                    place="010"
                    max={3}
                    req={3}
                  />
                  -
                  <TelInput
                    id="hp2"
                    name="hp2"
                    value={rawData.hp.split("-")[1]}
                    changeFun={hpHandler}
                    place="0000"
                    max={4}
                    req={4}
                  />
                  -
                  <TelInput
                    id="hp3"
                    name="hp3"
                    value={rawData.hp.split("-")[2]}
                    changeFun={hpHandler}
                    place="0000"
                    max={4}
                    req={4}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>{translate("직통번호", "Direct number")}</th>
              <td>
                <div className="flex">
                  <TelInput
                    id="tel1"
                    name="tel1"
                    value={
                      rawData.tel === null ? 51 : rawData.tel.split("-")[0]
                    }
                    changeFun={TelHandler}
                    readOnly={false}
                    place="010"
                    max={3}
                    req={2}
                  />
                  -
                  <TelInput
                    id="tel2"
                    name="tel2"
                    value={
                      rawData.tel === null ? "" : rawData.tel.split("-")[1]
                    }
                    changeFun={TelHandler}
                    place="0000"
                    max={4}
                    req={3}
                  />
                  -
                  <TelInput
                    id="tel3"
                    name="tel3"
                    value={
                      rawData.tel === null ? "" : rawData.tel.split("-")[2]
                    }
                    changeFun={TelHandler}
                    place="0000"
                    max={4}
                    req={4}
                  />
                </div>
              </td>

              <th>{translate("E-mail", "E-mail")}</th>
              <td>
                <Email
                  id="email"
                  name="email"
                  value={rawData.email || ""}
                  setRawData={setRawData}
                />
              </td>
            </tr>
            <tr>
              <th className="required">{translate("사용여부", "Use status")}</th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"act_yn"}
                    val={"Y"}
                    title={translate("사용", "Use")}
                    change={(e) => inputHandler(e)}
                    check={rawData.act_yn === "Y" ? true : false}
                  />
                  <Radio
                    name={"act_yn"}
                    val={"N"}
                    title={translate("미사용", "Not Use")}
                    change={(e) => inputHandler(e)}
                    check={rawData.act_yn === "N" ? true : false}
                  />
                </div>
              </td>
              <InputForm
                required={"off"}
                label={translate("담당자명", "Manager")}
                auto={"off"}
                type={"text"}
                val={rawData.manager || ""}
                changeFun={(e) => inputHandler(e)}
                name={"manager"}
              />
            </tr>
          </tbody>
        </table>
        <div className="sub-page-header">
          <div className="header-title">
            {language === "KOR"
              ? "권한 그룹 설정"
              : "Setting up permission groups"}
          </div>
        </div>
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th className="required">
                {translate("접속허가", "Access Permission")}
              </th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"all_yn"}
                    val={"Y"}
                    title={translate("허용", "Allow")}
                    change={(e) => inputHandler(e)}
                    check={rawData.all_yn === "Y" ? true : false}
                  />
                  <Radio
                    name={"all_yn"}
                    val={"N"}
                    title={translate("불가", "Not Allowed")}
                    change={(e) => inputHandler(e)}
                    check={rawData.all_yn === "N" ? true : false}
                  />
                </div>
              </td>
              <th className="required">{translate("대메뉴 권한그룹", "")}</th>
              <td>
                <Select
                  id="AG_IDX"
                  short={"N"}
                  name="AG_IDX"
                  options={[
                    {
                      name: translate("-- 등급선택 --", "-- Select --"),
                      value: "",
                    },
                    {
                      name: translate(
                        "회사권한 또는 관리자",
                        "Company or Admin"
                      ),
                      value: 11,
                    },
                    {
                      name: translate("지점 권한", "Branch Authority"),
                      value: 12,
                    },
                    {
                      name: translate("기사권한", "Driver Authority"),
                      value: 14,
                    },
                    {
                      name: translate("개인권한", "Personal Authority"),
                      value: 14,
                    },
                    {
                      name: translate(
                        "판매대리점 권한",
                        "Sales Agent Authority"
                      ),
                      value: 17,
                    },
                    {
                      name: translate("대리점 권한", "Agent Authority"),
                      value: 18,
                    },
                  ]}
                  changeFun={(e) => inputHandler(e)}
                  def={rawData.AG_IDX}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
