import axios from '../../lib/axios.js';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import SsoRegist from './SsoRegistModal';
import { useAuth } from '../../context/LoginContext';

export default function Sso() {
    const navi = useNavigate();
    const { handleLogin, user } = useAuth();
    const [searchParams, setSearchParams] = useSearchParams();
    const [isRegist, setIsRegist] = useState(null);
    const [view, setView] = useState(false);
    const [userInfo, setUserInfo] = useState(null);

    const id = searchParams.get('id');
    const cd = searchParams.get('cd');
    const pwd = searchParams.get('pwd');

    useEffect(() => {
        const getIp = async () => {
            await axios.get("https://jsonip.com")
            .then((res) => {
                setUserInfo((prev) => ({...prev, ip: res.data.ip}));
            }).catch((err) => {
                console.log('ip err : ', err);
            });
        }
        getIp();
        
        if(!id || !cd || !pwd) {
            Swal.fire({
                title:'권한이 없거나 로그인 정보가 올바르지 않습니다.'
            }).then(()=>{
                window.close();
            })

        } else {
            setUserInfo((prev) => ({...prev, cd: cd, id: id, pwd: pwd}));
        }    
    },[])

    useEffect(() => {
        const ssoCheck = async () => {
            if(userInfo?.cd && userInfo?.id && userInfo?.pwd && userInfo?.ip) {    
                await axios.post(`/web/sso/check`,{companyCode:userInfo.cd, userId:userInfo.id, password:userInfo.pwd})
                .then((res) => {
                    if(res.data === 'OK') {                    
                        handleLogin({companyCode: userInfo.cd, userId: userInfo.id, password: atob(userInfo.pwd), ip: userInfo.ip});
                    } else {
                        const {sso, id, acc, auth, del, cd } = res.data;
                        if(sso === 'Y') {
                            setIsRegist("U");
                            setView(true);
                        } else if(cd === 'Y' && id === 'N' && acc === 'N' && auth === 'N' && del === 'N' && sso === 'N') {
                            setIsRegist("I");
                            setView(true);
                        } else {
                            Swal.fire({
                                title:'로그인 정보가 올바르지 않습니다.\n문의 : 1599-2737'
                            }).then(()=>{
                                window.close();
                            })
                        }
                    }
                }).catch((err) => {
                    console.log('sso check err : ', err);
                });
            }
        }
        ssoCheck();
    },[userInfo])

    useEffect(() => {
        if (user) {
            navi("/");
        }
    }, [user]);

    return (
        <>
            {
            view && (isRegist !== null) ? 
            <SsoRegist setUserInfo={setUserInfo} userInfo={userInfo} isRegist={isRegist}/> 
            :
            <div>isLoading...</div>
            }            
        </>
    );
}

