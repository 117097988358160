import React from 'react';
import { ISODateToDate, add_hyphen } from '../../../lib/common_func';
import { useLang } from '../../../context/LanguageContext';

export default function TerminalMainExcel({ data, tableRef }) {
    const { language } = useLang();

    const colunm = ['번호','단말기명','제품명','단말 번호','지점명','대리점명','단말정보 등록일','누적 주행거리(km)'];
    const eng_colunm = ['No','Device name','Product','USIM num','Branch','Agent','Create','Total(km)'];

    const style = {
        sort:{left:{textAlign:'left'}, center:{textAlign:'center'}, right:{textAlign:'right'}},
        border:{border:'1px solid #413c3c'},
        primary:{backgroundColor:'#DCE8F5'}
    }    

    return (
        <>
            <table ref={tableRef} className='excel-table'>
                <thead>
                    <tr style={{...style.sort.center}}>
                        {language === 'KOR' ? colunm.map((item) => <th style={{...style.primary,...style.border}}>{item}</th>) : 
                                              eng_colunm.map((item) => <th style={{...style.primary,...style.border}}>{item}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {data && data.map((item, index) => (
                        <tr>
                            <td style={{...style.sort.center,...style.border}}>{data?.length - index}</td>
                            <td style={{...style.sort.right,...style.border}}>{item.unit_nm}</td>
                            <td style={{...style.sort.center,...style.border}}>{item.prd_nm}</td>
                            <td style={{...style.sort.center,...style.border}}>{add_hyphen(item.unitid)}</td>
                            <td style={{...style.sort.center,...style.border}}>{item.brc_nm}</td>
                            <td style={{...style.sort.center,...style.border}}>{item.agt_nm}</td>
                            <td style={{...style.sort.center,...style.border}}>{ISODateToDate(item.makedate)}</td>
                            <td style={{...style.sort.right,...style.border}}>{parseFloat(item.total_dist).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

