import React, { useRef, useEffect } from "react";
import { useMap } from "../../../context/MapContext";
import { MarkerClustering, clusterIcons } from "../../../lib/mapCluster";

export default function NaverMap({ children, handleCursorMove }) {
  const { naver } = window;
  const { mapState, mapDispatch } = useMap();
  const { map, cluster, streetMode, initialMapOptions, pathMode, zoom } = mapState;
  const mapEl = useRef(null);
  const zoomEl = useRef(null);

  useEffect(() => {
    if (mapEl.current && !map) {
      const naverMap = new naver.maps.Map(mapEl.current, {
        center: new naver.maps.LatLng(
          initialMapOptions.lat,
          initialMapOptions.lng
        ),
        zoom: initialMapOptions.zoom,
        disableDoubleClickZoom: true,
      });   
      const handleZoom = () => {
        if(zoomEl?.current.value != naverMap.getZoom()){
            mapDispatch({
                type: "SET_ZOOM",
                payload: naverMap.getZoom(),
            });
        }        
      }
        
      mapDispatch({
        type: "SET_MAP",
        payload: naverMap,
      });

      new naver.maps.Event.addListener(naverMap, 'zoom_changed', handleZoom);      
    }

  }, [map, naver, initialMapOptions, mapDispatch]);

  useEffect(() => {
    if (map && !cluster) {
      mapDispatch({
        type: "SET_CLUSTER",
        payload: new MarkerClustering({
          minClusterSize: 2,
          maxZoom: 13,
          map: map,
          markers: [],
          disableClickZoom: false,
          gridSize: 120,
          icons: clusterIcons,
          indexGenerator: [10, 100, 200, 500, 1000],
          stylingFunction: function (clusterMarker, count) {
            clusterMarker.getElement().querySelector(".cluster").innerHTML =
              count;
          },
        }),
      });
    }
    if (pathMode) {
      cluster?.setMap(null);

      mapDispatch({
        type: "SET_CLUSTER",
        payload: null,
      });
    }
  }, [map, cluster, naver, pathMode, mapDispatch]);

  useEffect(() => {
    if (map && streetMode) {
      const listenter = new naver.maps.Event.addListener(
        map,
        "click",
        function (e) {
          mapDispatch({ type: "SET_PANO_ITEM", payload: e.coord });
          naver.maps.Event.removeListener(listenter);
        }
      );
    }
  }, [map, streetMode, naver, mapDispatch]);

  useEffect(() => {
    if (map && !pathMode) {
      new naver.maps.Event.addListener(map, "click", function (e) {
        mapDispatch({ type: "BLUR" });
      });
    }
  }, [map, naver, mapDispatch]);

  return (
    <div
      id="map"
      ref={mapEl}
      onMouseMove={handleCursorMove}
      style={{ width: "100%", height: "100%" }}
    >
      <input ref={zoomEl} value={zoom} style={{display:'none'}} />
      {children}
    </div>
  );
}
