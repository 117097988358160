import React, { useEffect, useRef } from "react";
import Header from "../../include/AppHeader";
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import { LanguageContext } from "../../../context/LanguageContext";
import Form from "../../Atomic/organism/FormAtom";
import CommonControl from "../../Atomic/organism/CommonControl";
import Select from "../../Atomic/molecules/Select";
import axios from "../../../lib/axios.js";
import { useAuth } from "../../../context/LoginContext";
import { useCallback } from "react";
import Table from "../../Atomic/organism/AtomTable";
import { useNavigate } from "react-router-dom";
import {
  add_hyphen,
  distance,
  deviceType,
  trimType,
} from "../../../lib/common_func";
import moment from "moment";
import useExcelDown from "../../../hook/useExcelDown.jsx";
import TeminalblueToothExcel from "./TeminalblueToothExcel.jsx";
import { dataList } from "./TerminalMain";
export default function TeminalBlueTooth() {
  const [keyword, setKeyword] = React.useState("");
  const [rawData, setRawData] = React.useState([]);
  const [option, setOption] = React.useState("");
  const inputRef = React.createRef();
  const [data, setData] = React.useState([]);
  const { language } = React.useContext(LanguageContext);
  const user = useAuth();
  const navi = useNavigate();

  const tableRef = useRef();
  const today = moment().format("YYYY-MM-DD");
  const { excelDown } = useExcelDown(
    tableRef,
    `Bluetooth_Info_List_${today}`,
    `Bluetooth_Info_List`,
    data
  );

  const buttonData = [
    {
      labelKR: "전체보기",
      labelEN: "View all",
      classN: "purple",
      // clickFun: clearInput(inputRef, () => {
      //   setKeyword("");
      // }),
      clickFun: () => {
        clearInput();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "엑셀 다운로드",
      labelEN: "Excel download",
      classN: "white excel",
      icon: true,
      iconClass: "ri-file-excel-2-fill",
      clickFun: excelDown,
      position: "right",
    },
  ];
  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e) => inputChnage(e),

    myref: inputRef,
    option: (
      <Select
        id="keywordType"
        short="N"
        name="keywordType"
        options={[
          { name: language === "KOR" ? "검색구분" : "=======", value: "" },
          {
            name: language === "KOR" ? "블루투스" : "Bluetooth",
            value: "bt_id",
          },
          { name: language === "KOR" ? "사용자명" : "Agent", value: "nm" },
        ]}
        changeFun={(e) => {
          changeFun("keywordType", e.target.value);
        }}
      />
    ),
    value: keyword,
  };
  function clearInput() {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setKeyword("");
  }
  async function submitFun(e) {
    e.preventDefault();
    await getData();
  }
  async function getData() {
    await axios
      .get(
        `/web/bluetooth/list?type=${user.user.type}&org_idx=${user.user.org_idx}`
      )
      .then((res) => {
        setRawData(res.data);
      });
  }
  function inputChnage(key) {
    setKeyword(key);
    filterData(rawData, key, option.keywordType);
  }
  const filterData = useCallback(
    (data, key, option = "org_nm") => {
      const filterCallback = () => {
        if (option === "") {
          clearInput();
          return;
        } else {
          const result = data.filter((item) => {
            return item[option].includes(key);
          });
          setData(result);
        }
      };

      filterCallback();
    },
    [clearInput, setData]
  );
  function changeFun(name, value) {
    setOption({ ...option, [name]: value });
  }

  //===========================================================================
  useEffect(() => {
    axios
      .get(
        `/web/bluetooth/list?type=${user.user.type}&org_idx=${user.user.org_idx}`
      )
      .then((res) => {
        setRawData(res.data);
      });
  }, [user.user.type, user.user.org_idx]);
  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
      filterData(rawData, keyword, option.keywordType);
    }
  }, [rawData, keyword, option]);
  //===========================================================================
  const columns = [
    {
      header: language === "KOR" ? "블루투스" : "Bluetooth",
      name: "bt_id",
      sortable: true,
      resizable: true,
      width: 200,
    },
    {
      header: language === "KOR" ? "소속" : "Org",
      name: "org_nm",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "부서" : "Depart",
      name: "department",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "사용자명" : "User",
      name: "nm",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "최종 연결일시" : "Last Connected",
      name: "lastdate_bt",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "등록일" : "Create",
      name: "makedate",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "수정" : "Edit",
      name: "btn",
      align: "center",
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "수정" : "Edit"
        }</div>`,
    },
  ];
  const move = (e) => {
    const i = e.instance.store.focus.rowIndex;
    if (i !== null) {
      const idx = trimType(e.instance.store.data.rawData[i].idx);
      if (e.columnName === "btn" && e.targetType != "columnHeader") {
        navi(`/Trm/BlueTooth/Detail`, {
          state: {
            unitid: idx,
          },
        });
      }
    }
  };

  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav
              menu={"/Trm/BlueTooth"}
              dataList={dataList}
              language={language}
            />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR"
                    ? "블루투스 관리"
                    : "Bluetooth Management"}
                </div>
              </div>
              <div className="sub-page-body">
                <Form formData={formData} inputRef={inputRef} />
                <Table
                  language={language}
                  data={data}
                  columns={columns}
                  move={move}
                />
                <TeminalblueToothExcel data={data} tableRef={tableRef} />
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
