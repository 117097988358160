import React, { useEffect } from "react";
import axios from "../../../lib/axios.js";
import { trimType } from "../../../lib/common_func";
import { useCallback } from "react";

import { useNavigate } from "react-router-dom";
import Header from "../../include/AppHeader";
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import CommonControl from "../../Atomic/organism/CommonControl";

import Form from "../../Atomic/organism/FormAtom";
import Table from "../.././Atomic/organism/AtomTable";
import { useAuth } from "../../../context/LoginContext.jsx";
import { LanguageContext } from "../../../context/LanguageContext.jsx";
import { dataList } from "./ManagerMain.jsx";
export default function UserMain() {
  const navi = useNavigate();
  const inputRef = React.createRef();
  const { user } = useAuth();
  const { language } = React.useContext(LanguageContext);
  // State definition
  //==================================================================
  const [rawData, setRawData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [keyword, setKeyword] = React.useState("");
  // Function definition
  //==================================================================
  async function submitFun(e) {
    e.preventDefault();
    await getData();
  }
  async function getData() {
    await axios
      .get(`/web/org/users/list?type=${user.type}&org_idx=${user.org_idx}`)
      .then((res) => {
        setRawData(res.data.filter((item) => item.idx !== user.idx));
      });
  }
  function clearInput() {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setKeyword("");
  }
  function inputChnage(key) {
    setKeyword(key);
    filterData(rawData, key);
  }
  const filterData = useCallback(
    (data, key) => {
      const filterCallback = () => {
        const result = data.filter((item) => {
          // Check if any key in the object includes the search key
          return Object.values(item).some(
            (value) => typeof value === "string" && value.includes(key)
          );
        });
        setData(result);
      };

      filterCallback();
    },
    [setData]
  );
  const move = (e) => {
    const i = e.instance.store.focus.rowIndex;
    if (i !== null) {
      const idx = trimType(e.instance.store.data.rawData[i].idx);
      // const cmp_nm = trimType(e.instance.store.data.rawData[i].cmp_nm);
      // const cmp_cd = trimType(e.instance.store.data.rawData[i].cmp_cd);
      if (e.columnName === "btn" && e.targetType != "columnHeader") {
        navi(`/Mng/User/Edit`, {
          state: {
            idx: idx,
            // cmp_nm: cmp_nm,
            // cmp_cd: cmp_cd,
          },
        });
      }
    }
  };

  // UseEffect definition
  //==================================================================
  useEffect(() => {
    axios
      .get(`/web/org/users/list?type=${user.type}&org_idx=${user.org_idx}`)
      .then((res) => {
        setRawData(res.data.filter((item) => item.idx !== user.idx));
      });
  }, [user]);

  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
      filterData(rawData, keyword);
    }
  }, [rawData, keyword, filterData]);

  // Data definition
  //==================================================================

  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e) => inputChnage(e),
    myref: inputRef,
    //option: (),
    value: keyword,
  };
  const buttonData = [
    {
      labelKR: "전체보기",
      labelEN: "View all",
      classN: "purple",
      // clickFun: clearInput(inputRef, () => {
      //   setKeyword("");
      // }),
      clickFun: () => {
        clearInput();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "등록",
      labelEN: "Registration",
      classN: "purple",
      icon: true,
      clickFun: () => {
        navi("/Mng/User/Add");
      },
      position: "right",
    },
  ];
  const columns = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 50,
      align: "center",
      formatter: (name) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "구분" : "Type",
      name: "type",
      sortable: true,
      resizeable: true,
    },
    {
      header: language === "KOR" ? "회사명" : "Company name",
      name: "cmp_nm",
      sortable: true,
      resizeable: true,
    },

    {
      header: language === "KOR" ? "지점명" : "Branch name",
      name: "brc_nm",
      sortable: true,
      resizeable: true,
    },
    {
      header: language === "KOR" ? "대리점명" : "Agent name",
      name: "agt_nm",
      sortable: true,
      resizeable: true,
      width: 100,
    },
    {
      header: language === "KOR" ? "아이디" : "ID",
      name: "id",
      sortable: true,
      resizeable: true,
      width: 100,
    },
    {
      header: language === "KOR" ? "이메일" : "Email",
      name: "email",
      sortable: true,
      resizeable: true,
      width: 150,
    },
    {
      header: language === "KOR" ? "사용자명" : "Admin name",
      name: "nm",
      sortable: true,
      resizeable: true,
    },
    {
      header: language === "KOR" ? "로그인 등급" : "Login level",
      name: "grade",
      sortable: true,
      resizeable: true,
      formatter: (name) => {
        if (name.row.grade === "CS") {
          return language === "KOR" ? "일반고객" : "Customer";
        } else if (name.row.grade === "AD") {
          return language === "KOR" ? "관리자" : "Admin";
        } else if (name.row.grade === "DV") {
          return language === "KOR" ? "기사" : "Driver";
        } else if (name.row.grade === "FDV") {
          return language === "KOR" ? "고정기사" : "Fixed Driver";
        }
      },
    },
    {
      header: language === "KOR" ? "휴대폰번호" : "Phone number",
      name: "hp",
      sortable: true,
      resizeable: true,
    },

    {
      header: language === "KOR" ? "등록일" : "Registration date",
      name: "makedate",
      sortable: true,
      resizeable: true,
    },
    {
      header: language === "KOR" ? "수정" : "Edit",
      name: "btn",
      sortable: true,
      resizeable: true,
      align: "center",
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "수정" : "Edit"
        }</div>`,
    },
  ];
  // Log definition
  //==================================================================
  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Mng/User"} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "사용자 설정" : "User set"}
                </div>
              </div>
              <div className="sub-page-body">
                <Form formData={formData} inputRef={inputRef} />

                <Table data={data} columns={columns} move={move} />
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
