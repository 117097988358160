import React from "react";
import "./sliderStyle.css";
export default function ModalLeft({
  language,
  data,
  openClose,
  fenceData,
  changeFun,
  sumbitFun,
}) {
  return (
    <>
      <div className="right">
        <div className="modal-page">
          <div className="page-header">
            <div className="page-title">
              <span>
                {language === "KOR" ? "진입/이탈 설정" : "Fencing info"}
              </span>
            </div>
            <button
              onClick={openClose}
              className="modal-close"
              data-modal-id="geo-panel"
            >
              <i className="ri-close-fill icon"></i>
            </button>
          </div>
          <div className="page-body">
            <div className="common-form-table geo-info">
              <div className="row">
                <div className="row-label">
                  {language === "KOR" ? "선택된 위치정보" : "Your location"}
                </div>
                <div className="row-content">
                  {data ? (
                    <ul className="info-list">
                      <li>
                        <div className="item-label">
                          {language === "KOR" ? "위치정보" : "Coordinates"}
                        </div>
                        <div className="item-content">
                          <span className="y">{data.y ? data.y : "-"} °N</span>
                          <span className="x">{data.x ? data.x : ""} °E</span>
                        </div>
                      </li>
                      <li>
                        <div className="item-label">
                          {language === "KOR" ? "도로명" : "Road addr"}
                        </div>
                        <div className="item-content">
                          {data.roadAddress ? data.roadAddress : "-"}
                        </div>
                      </li>
                      <li>
                        <div className="item-label">
                          {language === "KOR" ? "지번" : "Region addr"}
                        </div>
                        <div className="item-content">
                          {data.jibunAddress ? data.jibunAddress : "-"}
                        </div>
                      </li>
                      <li>
                        <div className="item-label">
                          {language === "KOR" ? "영문명" : "Eng addr"}
                        </div>
                        <div className="item-content">
                          {data.englishAddress ? data.englishAddress : "-"}
                        </div>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="common-form-table">
              <div className="row">
                <div className="row-label">
                  {language === "KOR" ? "지역명" : "Fence name"}
                </div>
                <div className="row-content">
                  <div className="custom-input">
                    <input
                      type="text"
                      name="region_nm"
                      placeholder={
                        language === "KOR"
                          ? "지역명을 입력하세요"
                          : "enter fence name"
                      }
                      defaultValue={
                        fenceData.region_nm === undefined
                          ? ""
                          : fenceData.region_nm
                      }
                      onChange={changeFun}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="row-label">
                  {language === "KOR" ? "진입/이탈 설정" : "Alarm type"}
                </div>
                <div className="row-conetnt">
                  <div className="custom-radio-group ">
                    <label className="custom-radio">
                      <input
                        id="BothN"
                        value="N"
                        type="radio"
                        name="in_out_type"
                        checked={fenceData.in_out_type === "N"}
                        onChange={changeFun}
                      />
                      <div className="label-geofnce">
                        {language === "KOR" ? "설정해제" : "Alarm off"}
                      </div>
                    </label>
                    <label className="custom-radio">
                      <input
                        id="In"
                        type="radio"
                        name="in_out_type"
                        value="E"
                        checked={fenceData.in_out_type === "E"}
                        onChange={changeFun}
                      />
                      <div className="label-geofnce">
                        {language === "KOR" ? "진입" : "In"}
                      </div>
                    </label>
                    <label className="custom-radio">
                      <input
                        id="Out"
                        type="radio"
                        name="in_out_type"
                        value="D"
                        checked={fenceData.in_out_type === "D"}
                        onChange={changeFun}
                      />
                      <div className="label-geofnce">
                        {language === "KOR" ? "이탈" : "Out"}
                      </div>
                    </label>
                    <label className="custom-radio">
                      <input
                        id="BothY"
                        value="B"
                        type="radio"
                        name="in_out_type"
                        checked={fenceData.in_out_type === "B"}
                        onChange={changeFun}
                      />
                      <div className="label-geofnce">
                        {language === "KOR" ? "진입/이탈" : "In/Out"}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="row-label">
                  {language === "KOR" ? "반경" : "Radius"}
                </div>
                <div className="row-content">
                  <div className="custom-range">
                    <input
                      className="input-range"
                      type="range"
                      name="radius"
                      onChange={changeFun}
                      min="500"
                      max="10000"
                      step="100"
                      value={fenceData.radius}
                    />
                    <span>{fenceData.radius}</span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="row-label">
                  {language === "KOR" ? "지도표시" : "View on map"}
                </div>
                <div className="row-content">
                  <div className="custom-radio-group">
                    <label className="custom-radio">
                      <input
                        id="actY"
                        type="radio"
                        name="map_view_yn"
                        value="Y"
                        onChange={changeFun}
                        checked={fenceData.map_view_yn === "Y"}
                      />
                      <div className="label">
                        {language === "KOR" ? "표시" : "Display"}
                      </div>
                    </label>
                    <label className="custom-radio">
                      <input
                        id="actN"
                        type="radio"
                        name="map_view_yn"
                        value="N"
                        onChange={changeFun}
                        checked={fenceData.map_view_yn === "N"}
                      />
                      <div className="label">
                        {language === "KOR" ? "미표시" : "Don't Display"}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="page-footer">
            <button
              onClick={openClose}
              className="common-btn white modal-close"
              data-modal-id="geo-panel"
            >
              <span>{language === "KOR" ? "취소" : "Cancel"}</span>
            </button>
            <button
              onClick={sumbitFun}
              className="common-btn purple modal-close"
              data-modal-id="geo-panel"
            >
              <span>{language === "KOR" ? "확인" : "Save"}</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
