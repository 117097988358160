import axios from 'axios';
import React, { useRef } from 'react';
import Swal from 'sweetalert2';

export default function SsoRegistModal({ setUserInfo, userInfo, isRegist }) {
    const hpRef = useRef();

    const regist = async (value) => {
        await axios.post('/web/sso/regist',{companyCode: userInfo.cd, userId: userInfo.id, password: userInfo.pwd, type: isRegist, hp: value})
        .then((res) => {
            setUserInfo((prev) => ({...prev}));
        }).catch((err) => {
            Swal.fire({
                title:'등록에 실패하였습니다. 1599-2737로 문의해주세요.'
            })
            console.log('err : ', err);
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let value = hpRef?.current?.value;
        if(value) {
            value = value.replaceAll('-','');
    
            if(value.length === 11) {
                value = value.substr(0,3) + '-' + value.substr(3,4) + '-' + value.substr(7,4);
                if( /^010-?([0-9]{3,4})-?([0-9]{4})$/.test(value)) {
                    regist(value);
                } else {
                    Swal.fire({
                        title: "휴대폰 번호는 010으로 시작하는 11자리의 숫자만 가능합니다."
                    })
                }
            } else {
                Swal.fire({
                    title: "휴대폰 번호는 010으로 시작하는 11자리의 숫자만 가능합니다."
                })
                hpRef.current.value = '';
            }	
        }
    }
    return (
        <>
            <div id="crew-search-panel" className="common-modal on">
                <div className="modal-inner">
                    <div className="modal-page sm">
                    <div className="page-header">
                        <div className="page-title">
                        <span className='red'>신규 등록</span>
                        </div>
                    </div>
                    <div className="page-body short2">
                        <form onSubmit={handleSubmit}>
                            <div className='m_form vertical'>
                                <div>
                                    <span className='key'>아이디</span>
                                    <div className='value'>{userInfo.id || ''}</div>
                                </div>
                                <div className='space-2'>
                                    <span className='key'>휴대폰 번호</span>
                                    <div className='value'>
                                        <input type="text" ref={hpRef}/>
                                    </div>
                                </div>
                            </div>
                            <button type='submit'>확인</button>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}

