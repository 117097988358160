import React from "react";
interface titleObj {
  line_1: string;
  line_2: string;
}

interface ItemSlideProps {
  language: string;
  titleKR: titleObj;
  title: titleObj;
  subENG: string;
  descENG: string;
  subKR: string;
  descKR: string;
  img: string;
}
export default function ItemSlide({
  language,
  titleKR,
  title,
  subENG,
  descENG,
  subKR,
  descKR,
  img,
}: ItemSlideProps) {
  return (
    <div
      className="swiper-slide slide-item"
      data-title={language === "KOR" ? `${titleKR}` : `${title}`}
    >
      <div className="item-content">
        <div className="item-logo">
          <img src="../../../assets/img/login/logo_dark.png" alt="4guard" />
        </div>
        <div className="item-title" style={{ color: "#fff" }}>
          <p>{language === "KOR" ? `${titleKR.line_1}` : `${title.line_1}`}</p>
          <p>{language === "KOR" ? `${titleKR.line_2}` : `${title.line_2}`}</p>
        </div>

        <div className="item-sub" style={{ color: "#fff" }}>
          <p>{language === "KOR" ? `${subKR}` : `${subENG}`}</p>
          <p> {language === "KOR" ? `${descKR}` : `${descENG}`}</p>
        </div>
      </div>
      <div className="item-bg">
        <img src={img} alt="slide" />
      </div>
    </div>
  );
}
