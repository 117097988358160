import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from '../../../context/LanguageContext';

export default function DetailTabNav({ state, setState }) {
    const {language} = useContext(LanguageContext);    

    const handleChange = (t) => {
        setState((prev) => {
            return {...prev, isRecent: t};
        })
    }
    
    return (
        <div className="tab-nav">
            <div className="tab-inner">
                <Link to={''} className={`nav-btn ${state.isRecent ? 'on' : ''}`} data-target-tab="recent" onClick={()=>handleChange(true)}>{(language === 'KOR' ? '최근정보' : 'Latest Info.' )}</Link>
                <Link to={''} className={`nav-btn ${state.isRecent ? '' : 'on'}`} data-target-tab="record" onClick={()=>handleChange(false)}>{(language === 'KOR' ? '주행기록' : 'Driv. Log' )}</Link>
            </div>
        </div>
    );
}

