import React from "react";
import PropTypes from "prop-types";
interface InputTextProps {
  value: string;
  chageFun: (event: any) => void;
  placeholder: string;
  title: string;
  id: string;
  name: string;
  type: string;
}
export default function InputText({
  value,
  chageFun,
  placeholder,
  title,
  id,
  name,
  type,
}: InputTextProps) {
  return (
    <>
      <div className="form-item">
        <label>{title}</label>
        <input
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={chageFun}
          placeholder={placeholder}
        />
      </div>
    </>
  );
}

InputText.propTypes = {
  value: PropTypes.string,
  chnageFun: PropTypes.func,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
};
