import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../lib/axios";
//@ts-ignore
import { dataList, subDataListMenu } from "../../../Admin/AdmMain";
import CommonControl from "../../../Atomic/organism/CommonControl";
import Page from "../../../Atomic/template/PageTable";
import InputForm from "../../../Atomic/atoms/InputForm";
import Radio from "../../../Atomic/atoms/Radio";
import SubNavi from "../../../Atomic/molecules/SubNavi";

export default function Form() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const navi = useNavigate();
  const { user } = useAuth();
  // State defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [rawData, setRawData] = useState<any>({});

  //=======================================================================
  // Function defines
  //=======================================================================

  function inputHandler(e: any) {
    const { name, value } = e.target;
    setRawData((pre: any) => ({ ...pre, [name]: value }));
  }
  function createNew() {
    if (rawData.cd === "" || rawData.cd === undefined) {
      Swal.fire({
        title: `${translate(
          "권한그룹 코드을 입력해주세요",
          "Please enter the Code"
        )}`,
      });
      return;
    }
    if (rawData.nm === "" || rawData.nm === undefined) {
      Swal.fire({
        title: `${translate(
          "권한그룹 명을 입력해주세요",
          "Please enter the Name"
        )}`,
      });
      return;
    }
    if (rawData.nm_txt === "" || rawData.nm_txt === undefined) {
      Swal.fire({
        title: `${translate(
          "그룹설명 을 입력해주세요",
          "Please enter the discription"
        )}`,
      });
      return;
    }

    Swal.fire({
      title: `${translate("등록하시겠습니까?", "Do you want to add?")}`,
      showCancelButton: true,
      confirmButtonText: `${translate("확인", "Confirm")}`,
      cancelButtonText: `${translate("취소", "Cancel")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post("/web/admin/menu/add", rawData)
          .then((res: any) => {
            if (res.status === 200) {
              Swal.fire({
                title: `${translate("등록되었습니다.", "Added")}`,
                confirmButtonText: `${translate("확인", "Confirm")}`,
              }).then(() => {
                navi("/Adm/menu-table");
              });
            } else {
              Swal.fire({
                title: `${translate("등록에 실패하였습니다.", "Failed")}`,
                confirmButtonText: `${translate("확인", "Confirm")}`,
              });
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    });
  }

  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "white",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },

    {
      labelKR: "등록",
      labelEN: "Add new",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];

  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
    setRawData((pre: any) => ({ ...pre, type: user.type }));
  }, [location, user]);

  // Log defines
  //=======================================================================

  // console.log("rawData", rawData);
  // console.log("state", location.state);
  // console.log("idx", idx);
  // console.log("CompanyData", companyData);
  // console.log("user", user.type);
  // console.log("refId", refId.current?.value);

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "메뉴 권한 관리" : "Menu Auth"}`}
        menu="/Adm/menu-table"
      >
        <SubNavi
          subDataList={subDataListMenu}
          language={language}
          navi={navi}
          classPath="/Adm/menu-table/new"
        />
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <InputForm
                label={translate("권한그룹 코드", "Auth Group Code")}
                name="cd"
                val={rawData.cd}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
              <InputForm
                label={translate("권한그룹 명", "Auth Group Name")}
                name="nm"
                val={rawData.nm}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
            </tr>
            <tr>
              <InputForm
                label={translate("그룹설명	", "Group Description")}
                name="nm_txt"
                val={rawData.nm_txt}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
              <th>{translate("접속허가", "Access permission")}</th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"all_yn"}
                    val={"Y"}
                    title={translate("예", "Yes")}
                    change={(e) => inputHandler(e)}
                    check={rawData.all_yn === "Y" ? true : false}
                  />
                  <Radio
                    name={"all_yn"}
                    val={"N"}
                    title={translate("아니오", "Not use")}
                    change={(e) => inputHandler(e)}
                    check={rawData.all_yn === "N" ? true : false}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
