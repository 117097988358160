import React, { useEffect } from "react";
import { LanguageContext } from "../../../context/LanguageContext.jsx";
import { useNavigate } from "react-router-dom";
import Header from "../../include/AppHeader";
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import CommonControl from "../../Atomic/organism/CommonControl";
import { dataList } from "./SetMain";
import moment from "moment";
import { useAuth } from "../../../context/LoginContext";
import axios from "../../../lib/axios.js";
import { useLocation } from "react-router-dom";
export default function SetAdd() {
  const navi = useNavigate();
  const { language } = React.useContext(LanguageContext);
  const { user } = useAuth();
  const location = useLocation();
  // State definition
  //==================================================================
  const [rawData, setRawData] = React.useState({
    tit: "",
    ctnt: "",
    eme_yn: "N",
    writer: `${user.nm}`,
  });
  const style = {
    width: "100%",
    height: "100%",
    resize: "none",

    focus: "none",
    padding: "10px",
  };
  // Effect definition
  //=======================================

  // Function definition
  //==================================================================

  function inputChnage(e) {
    setRawData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  // UseEffect definition
  //==================================================================
  useEffect(() => {
    if (location.state.idx !== undefined) {
      axios
        .get(`/web/settings/notice/detail/?idx=${location.state.idx}`)
        .then((res) => {
          if (res.status === 200) {
            setRawData(res.data);
          }
        });
    }
  }, [location.state.idx, user]);
  // Data definition
  //==================================================================

  const buttonData = [
    {
      labelKR: "뒤로가기",
      labelEN: "Back",
      classN: "white",
      clickFun: () => {
        navi(`/Set`);
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
  ];

  // Log definition
  //==================================================================

  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Set"} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "공지사항" : "Notice"}
                </div>
              </div>
              <div className="sub-page-body">
                <table className="common-view-table">
                  <tbody>
                    <tr>
                      <th className="required">{`${
                        language === "KOR" ? "제목" : "Title"
                      }`}</th>
                      <td colSpan={3}>
                        <div className="custom-input">
                          <input
                            type="text"
                            name="tit"
                            id="tit"
                            value={rawData.tit}
                            maxlength="150"
                            onChange={inputChnage}
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>조직명</th>
                      <td>
                        <div className="custom-input">
                          <input
                            type="text"
                            name="writer"
                            id="writer"
                            value={rawData.writer}
                            onChange={inputChnage}
                            readOnly
                          />
                        </div>
                      </td>
                      <th>{`${language === "KOR" ? "등록일" : "Date"}`}</th>
                      <td>
                        <div className="custom-input">
                          <input
                            type="text"
                            name="makedate"
                            id="makedate"
                            value={moment
                              .utc(rawData.makedate)
                              .format("YYYY-MM-DD")}
                            onChange={inputChnage}
                            readOnly
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span class="red">*</span>내용
                      </th>
                      <td colSpan={3}>
                        <div
                          className="custom-input"
                          style={{
                            border: "none",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <textarea
                            name="ctnt"
                            id="ctnt"
                            style={style}
                            value={rawData.ctnt}
                            onChange={inputChnage}
                            rows={20}
                            readOnly
                          ></textarea>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
