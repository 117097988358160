import React, { useRef } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { useContext } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import DatePicker from "vanillajs-datepicker/Datepicker";
import { Link } from "react-router-dom";
import { add_hyphen } from "../../../../src/lib/common_func";
import DrivingDayTable from "../organism/DrivingDayTable";
import Nav from "../../Atomic/organism/Nav";
import AppHeader from "../../include/AppHeader";
import AppFooter from "../../include/AppFooter";
import moment from "moment";
import useFetch from "../../../hook/useFetch";
import useExcelDown from "../../../hook/useExcelDown";
import axios from "axios";
import { dataList } from "./DeviceLogMain";
import { useAuth } from "../../../context/LoginContext";
export default function Terminal() {
  const { language } = useContext(LanguageContext);
  const { user } = useAuth();
  const fromRef = useRef();
  const toRef = useRef();
  const location = useLocation();
  const today = moment().format("YYYY-MM-DD");

  // state
  const [unitid, setUnitid] = useState();
  const [unit_nm, setUnit_nm] = useState();
  const [date, setDate] = useState(null);
  const [data, setData] = useState(null);
  const [totalDist, setTotalDist] = useState(0);
  const [drivingDay, setDrivingDay] = useState(null);
  const [sort, setSort] = useState(true); // 정렬
  const [isSearch, setIsSearch] = useState(true);

  // fetch data
  const url =
    date &&
    `/web/device/log/period?unitid=${location.state?.unitid || unitid}&from=${
      date.s
    }&to=${date.e}&search=${isSearch}&org_idx=${user.org_idx}`;
  const { data: initData, isLoading } = useFetch(url);

  // excel down
  const tableRef = useRef();
  const { excelDown } = useExcelDown(
    tableRef,
    `Device_Log_Periodic_${today} ${location.state?.unit_nm || unit_nm}`,
    `Device_Log_Periodic_${today} ${location.state?.uni_nm || unit_nm}`,
    data
  );

  // calendar
  useEffect(() => {
    if (drivingDay) {
      // datePicker language
      DatePicker.locales.ko = {
        days: [
          "일요일",
          "월요일",
          "화요일",
          "수요일",
          "목요일",
          "금요일",
          "토요일",
        ],
        daysShort: ["일", "월", "화", "수", "목", "금", "토"],
        daysMin: ["일", "월", "화", "수", "목", "금", "토"],
        months: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        monthsShort: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        today: "오늘",
        clear: "삭제",
        format: "yyyy-mm-dd",
        titleFormat: "yyyy년mm월",
        weekStart: 0,
      };

      const customPicker_s = new DatePicker(fromRef.current, {
        language: language === "KOR" ? "ko" : "en",
        autohide: true,
        todayBtn: true,
        todayBtnMode: 0,
        format: "yyyy-mm-dd",
        beforeShowDay:
          drivingDay &&
          function (date) {
            const _date = new Intl.DateTimeFormat("fr-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(date);
            const hasData = drivingDay.includes(_date);

            return {
              classes: hasData ? "hasData" : "disabled",
            };
          },
      });
      const customPicker_e = new DatePicker(toRef.current, {
        language: language === "KOR" ? "ko" : "en",
        autohide: true,
        todayBtn: true,
        todayBtnMode: 0,
        format: "yyyy-mm-dd",
        beforeShowDay:
          drivingDay &&
          function (date) {
            const _date = new Intl.DateTimeFormat("fr-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(date);
            const hasData = drivingDay.includes(_date);

            return {
              classes: hasData ? "hasData" : "disabled",
            };
          },
      });
    }
  }, [language, drivingDay]);

  // location reset 방지 state 관리
  useEffect(() => {
    const ui =
      location.state?.unitid ||
      JSON.parse(sessionStorage.getItem("state")).unitid;
    const un =
      location.state?.unit_nm ||
      JSON.parse(sessionStorage.getItem("state")).unit_nm;

    setUnitid(ui);
    setUnit_nm(un);

    (async () => {
      try {
        const result = await axios.get(
          `/web/map/tracking/path/day?unitid=${ui}`
        );

        if (result.data === "") {
          setDrivingDay([]);
          setDate({ s: today, e: today });
        } else {
          const drivingDayArr = result.data.map((item) => item.date);
          setDrivingDay(drivingDayArr);
          setDate({
            s: drivingDayArr[drivingDayArr.length - 1],
            e: drivingDayArr[drivingDayArr.length - 1],
          });
        }
      } catch (error) {
        console.log("err : ", error);
      }
    })();

    const setState = () => {
      sessionStorage.setItem(
        "state",
        JSON.stringify({ unitid: ui, unit_nm: un })
      );
    };
    window.addEventListener("beforeunload", setState);

    return () => {
      window.removeEventListener("beforeunload", setState);
    };
  }, []);

  useEffect(() => {
    if (unitid) {
      sessionStorage.removeItem("state");
    }
  }, [unitid]);

  useEffect(() => {
    if (isLoading || !date) {
      setData(null);
      setTotalDist(0);
    } else {
      if (initData) {
        setData(initData);
        let dist = 0;
        initData.map((item) => {
          dist += item.total_cal_dist;
        });
        setTotalDist(dist.toFixed(2));
      } else {
        setData([]);
        setTotalDist(0);
      }
    }
  }, [initData]);

  // search option filter
  const filter = () => {
    const s = new Date(fromRef.current.value);
    const e = new Date(toRef.current.value);
    // 올바른 날짜 필터링
    if (s > e) {
      if (language === "KOR") {
        alert("시작 날짜가 더 작도록 해주세요.");
      } else {
        alert("Please make the start date smaller.");
      }
      return false;
    }
    // 올바른 기간 필터링
    const timeDiff = Math.ceil(e.getTime() - s.getTime()) / (1000 * 3600 * 24);

    if (timeDiff > 31) {
      if (language === "KOR") {
        alert("31일 이하의 기간만 검색이 가능합니다.");
      } else {
        alert("Search is only possible for a period of 31 days or less.");
      }
      return false;
    }
    return true;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (filter()) {
      setDate({ s: fromRef.current.value, e: toRef.current.value });
      setIsSearch((prev) => !prev);
      setData(null);
      setTotalDist(0);
    }
  };

  const changeSort = () => {
    setSort((prev) => !prev);
  };

  return (
    <>
      <AppHeader pages="2" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Dlog"} language={language} dataList={dataList} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR"
                    ? "단말일지 (기간별)"
                    : "Driving log (periodic sum)"}
                </div>
              </div>
              <div className="sub-page-body">
                <div className="sub-page-notice">
                  <ul className="notice-list">
                    <li className="list-item">
                      {language === "KOR"
                        ? `KEY ON 날짜기준으로 운행거리,
                                            운행시간을 집계하기 때문에 실제 일별
                                            총 운행거리, 총 운행시간과는 다를 수
                                            있습니다.`
                        : `The mileage and hours of operation are counted based on the KEYON date,
                                            so it may be defferent from the actual total daily mileage and total hours of operation.`}
                    </li>
                    <li className="list-item">
                      {language === "KOR"
                        ? `KEY ON 정보가 정상적으로 수신되지
                                            않는 차량일 경우에도 정보가 표시되지
                                            않습니다.`
                        : `Information will not be displayed even if the KEY ON information is not received properly.`}
                    </li>
                  </ul>
                </div>
                <form className="search-bar" onSubmit={submitHandler}>
                  <div className="period-group">
                    <div className="custom-datepicker">
                      <input
                        ref={fromRef}
                        type="text"
                        value={date?.s || today}
                        readOnly
                        className="datepicker-input"
                      />
                    </div>
                    <span className="devider">~</span>
                    <div className="custom-datepicker">
                      <input
                        ref={toRef}
                        type="text"
                        value={date?.e || today}
                        readOnly
                        className="datepicker-input"
                      />
                    </div>
                  </div>
                  <button type="submit" className="submit-btn">
                    {language === "KOR" ? "검색" : "Search"}
                  </button>
                </form>
                <div className="common-table-wrapper">
                  <div className="common-control">
                    <div className="left">
                      <div className="period">
                        <span className="date">{date?.s || today}</span>
                        <div className="devider">~</div>
                        <span className="date">{date?.e || today}</span>
                      </div>
                      <div className="info">
                        <span className="label">{unit_nm}</span>
                        <span className="label emp">
                          {unitid && add_hyphen(unitid)}
                        </span>
                      </div>
                      <div className="info">
                        <span className="label">
                          {language === "KOR" ? "운행기록" : "Driving log"}
                        </span>
                        <span>
                          {language === "KOR"
                            ? `(총 운행거리 : ${totalDist} km)`
                            : `(Total : ${totalDist} km)`}
                        </span>
                      </div>
                    </div>
                    <div className="right">
                      <div className="custom-radio-group">
                        <label className="custom-radio">
                          <input
                            type="radio"
                            name="filter"
                            onChange={changeSort}
                            checked={!sort}
                          />
                          <div className="label">
                            {language === "KOR" ? "오름차순" : "Ascend"}
                          </div>
                        </label>
                        <label className="custom-radio">
                          <input
                            type="radio"
                            name="filter"
                            onChange={changeSort}
                            checked={sort}
                          />
                          <div className="label">
                            {language === "KOR" ? "내림차순" : "Descend"}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <DrivingDayTable
                    data={data}
                    sort={sort}
                    tableRef={tableRef}
                    isLoading={isLoading}
                    date={date}
                    unitid={unitid}
                    unit_nm={unit_nm}
                    totalDist={totalDist}
                  />
                  <div className="common-control">
                    <div className="left"></div>
                    <div className="right">
                      <Link
                        to=""
                        className="common-btn white excel"
                        onClick={excelDown}
                      >
                        <i className="ri-file-excel-2-fill icon"></i>
                        <span>
                          {language === "KOR"
                            ? "엑셀 다운로드"
                            : "Excel download"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
}
