import React from "react";
interface StatProps {
  language: string;
  titleKR: string;
  titleEN: string;
  value: number;
  classN: string;
}
export default function Stat({
  language,
  titleKR,
  titleEN,
  value,
  classN,
}: StatProps) {
  return (
    <>
      <div className={`status-item ${classN}`}>
        <div className="item-label">
          {language === "KOR" ? `${titleKR}` : `${titleEN}`}
        </div>
        <div className="item-content">{`${value}`}</div>
      </div>
    </>
  );
}
