import PropTypes from "prop-types";
import Title from "../atom/Title";
import InputTime from "../atom/InputTime";
export default function FormDate({ title, type, value, changeFun, id, name }) {
  return (
    <>
      <div className="form-row">
        <Title title={title} />
        <InputTime
          type={type}
          value={value}
          changeFun={changeFun}
          id={id}
          name={name}
        />
      </div>
    </>
  );
}
FormDate.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  changeFun: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
};
