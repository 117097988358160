import React, { useEffect } from "react";
import axios from "../../../lib/axios.js";
import { trimType } from "../../../lib/common_func";
import { useCallback } from "react";

import { useNavigate } from "react-router-dom";
import Header from "../../include/AppHeader";
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import CommonControl from "../../Atomic/organism/CommonControl";

import Form from "../../Atomic/organism/FormAtom";
import Table from "../.././Atomic/organism/AtomTable";
export const dataList = [
  {
    permission: "E100",
    path: "/Mng",
    labelKR: "지점 설정",
    labelEN: "Branch Settings",
  },
  {
    permission: "E200",
    path: "/Mng/Agent",
    labelKR: "대리점 설정",
    labelEN: "Agent Settings",
  },
  {
    permission: "E300",
    path: "/Mng/User",
    labelKR: "사용자 설정",
    labelEN: "User Settings",
  },
];
export default function MngMain({ language, user }) {
  const navi = useNavigate();
  const inputRef = React.createRef();

  // State definition
  //==================================================================
  const [rawData, setRawData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [keyword, setKeyword] = React.useState("");
  // Function definition
  //==================================================================
  async function submitFun(e) {
    e.preventDefault();
    await getData();
  }
  async function getData() {
    await axios
      .get(`/web/org/branch/list?org_idx=${user.org_idx}`)
      .then((res) => {
        setRawData(res.data);
      });
  }
  function clearInput() {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setKeyword("");
  }
  function inputChnage(key) {
    setKeyword(key);
    filterData(rawData, key);
  }

  const filterData = useCallback(
    (data, key) => {
      const filterCallback = () => {
        const result = data.filter((item) => {
          // Check if any key in the object includes the search key
          return Object.values(item).some(
            (value) => typeof value === "string" && value.includes(key)
          );
        });
        setData(result);
      };

      filterCallback();
    },
    [setData]
  );

  const move = (e) => {
    const i = e.instance.store.focus.rowIndex;
    if (i !== null) {
      const idx = trimType(e.instance.store.data.rawData[i].idx);
      // const cmp_nm = trimType(e.instance.store.data.rawData[i].cmp_nm);
      // const cmp_cd = trimType(e.instance.store.data.rawData[i].cmp_cd);
      if (e.columnName === "btn" && e.targetType != "columnHeader") {
        navi(`/Mng/Edit`, {
          state: {
            idx: idx,
            // cmp_nm: cmp_nm,
            // cmp_cd: cmp_cd,
          },
        });
      }
    }
  };

  // UseEffect definition
  //==================================================================
  useEffect(() => {
    axios.get(`/web/org/branch/list?org_idx=${user.org_idx}`).then((res) => {
      setRawData(res.data);
    });
  }, [user]);

  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
      filterData(rawData, keyword);
    }
  }, [rawData, keyword, filterData]);

  // Data definition

  //==================================================================

  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e) => inputChnage(e),
    myref: inputRef,
    //option: (),
    value: keyword,
  };
  const buttonData = [
    {
      labelKR: "전체보기",
      labelEN: "View all",
      classN: "purple",
      // clickFun: clearInput(inputRef, () => {
      //   setKeyword("");
      // }),
      clickFun: () => {
        clearInput();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "등록",
      labelEN: "Registration",
      classN: "purple",
      icon: false,
      clickFun: () => {
        navi("/Mng/Add");
      },
      position: "right",
    },
  ];
  const columns = [
    {
      header: language === "KOR" ? "번호" : "Number",
      name: "rowKey" + 1,
      width: 50,
      align: "center",
      formatter: (name) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "회사명" : "Company name",
      name: "cmp_nm",
      sortable: true,
      resizeable: true,
    },
    {
      header: language === "KOR" ? "회사코드" : "Company code",
      name: "org_cd",
      sortable: true,
      resizeable: true,
      width: 100,
    },
    {
      header: language === "KOR" ? "지점명" : "Branch name",
      name: "org_nm",
      sortable: true,
      resizeable: true,
    },
    {
      header: language === "KOR" ? "이메일" : "Email",
      name: "email",
      sortable: true,
      resizeable: true,
      width: 200,
    },
    {
      header: language === "KOR" ? "관리자명" : "Admin name",
      name: "org_adm_nm",
      sortable: true,
      resizeable: true,
    },
    {
      header: language === "KOR" ? "휴대폰번호" : "Phone number",
      name: "hp",
      sortable: true,
      resizeable: true,
    },
    {
      header: language === "KOR" ? "직통번호" : "Direct number",
      name: "hp",
      sortable: true,
      resizeable: true,
    },
    {
      header: language === "KOR" ? "사업자등록번호" : "Business number",
      name: "tel",
      sortable: true,
      resizeable: true,
    },
    {
      header: language === "KOR" ? "등록일" : "Registration date",
      name: "makedate",
      sortable: true,
      resizeable: true,
    },
    {
      header: language === "KOR" ? "수정" : "Edit",
      name: "btn",
      sortable: true,
      resizeable: true,
      align: "center",
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "수정" : "Edit"
        }</div>`,
    },
  ];
  // Log definition
  //==================================================================
  //
  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Mng"} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "지점 설정" : "Branch set"}
                </div>
              </div>
              <div className="sub-page-body">
                <Form formData={formData} inputRef={inputRef} />
                <Table data={data} columns={columns} move={move} />
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
