import React, { useContext, useState } from "react";
import { LanguageContext } from "../../context/LanguageContext";
import { useMap } from "../../context/MapContext";
import { getIconNumber, getMarkerType } from "../../lib/mapHelper";
import { code, event } from "../../lib/eventData";
import { getBattState, getGPSState } from "../../lib/map_common";
import { ISODateToDateTime } from "../../lib/common_func";

export default function RightDailyPanel() {
    const { language } = useContext(LanguageContext);
    const { mapState, mapDispatch } = useMap();
    const { selectDailyItem, selectPathItem } = mapState;

    const handleClose = () => {
        mapDispatch({ type: "BLUR" });
    };

    return (
        <>
            {/*<!--s: 오른쪽 상세정보판넬-->*/}
            <div className={`right-info-panel detail-info-panel on`}>
                <div className="detail-inner">
                    <div className="detail-top">
                        <div className="detail-header">
                            <div className="header-title">
                                <i className="ri-information-fill icon"></i>
                                {language === "KOR"
                                    ? "운행정보"
                                    : "Driving Info."}
                            </div>
                            <div className="header-control">
                                <button
                                    type="button"
                                    className="close"
                                    onClick={handleClose}
                                ></button>
                            </div>
                        </div>
                        {selectPathItem ? (
                            <div className="detail-body">
                                <div className="detail-summary">
                                    <div className="left">
                                        {/*<!--car | motorcycle | human | bike | truck | trash | school | aireplane | crane | pet-->*/}
                                        {/*<!--start | pause | end-->*/}
                                        <div
                                            className={
                                                "marker " +
                                                (selectPathItem
                                                    ? code.indexOf(selectPathItem.event_code) === -1 ? 
                                                        'event' :
                                                        getMarkerType(selectPathItem.iconnum)
                                                    : "")
                                            }
                                        >
                                            <div className="icon">
                                                <i className={selectPathItem && code.indexOf(selectPathItem.event_code) === -1 ? 'ri-alert-fill' : ''}></i>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                selectPathItem?.unit_nm.length >
                                                20
                                                    ? "name-s"
                                                    : "name" || "name"
                                            }
                                        >
                                            {selectPathItem?.unit_nm || ""}
                                        </div>
                                        {/* <div className={mapState.selectItem?.unit_nm.length > 20 ? 'name-s' : 'name' || 'name'}>{mapState.selectItem?.unit_nm || ''}</div> */}
                                    </div>
                                    <div className="right">
                                        {/*<!--grey | red | orange | green-->*/}
                                        <div
                                            className={`battery ${
                                                getBattState(selectPathItem.inner_battery)
                                            }`}
                                        >
                                            {(
                                                (selectPathItem?.inner_battery ?? 0) /
                                                10
                                            ).toFixed(1)}
                                            v
                                        </div>
                                        {selectDailyItem?.wire_type === "Y" && (
                                            <div className="battery car">
                                                {(
                                                    (selectPathItem?.vehicle_battery ?? 0) /
                                                    10
                                                ).toFixed(1)}
                                                v
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="detail-content">
                                    <div className="row">
                                        <div className="detail-item">
                                            <div className="item-label">
                                                {language === "KOR"
                                                    ? "단말기번호"
                                                    : "Device Num"}
                                            </div>
                                            <div className="item-content">
                                                {selectPathItem?.unitid.replace(
                                                    "@@",
                                                    ""
                                                ) || ""}
                                            </div>
                                        </div>
                                        <div className="detail-item">
                                            <div className="item-label">
                                                {language === "KOR"
                                                    ? "속도"
                                                    : "Speed"}
                                            </div>
                                            <div className="item-content">
                                                {selectPathItem &&
                                                    parseInt(
                                                        selectPathItem?.speed
                                                    )}{" "}
                                                km/h
                                            </div>
                                        </div>
                                        <div className="detail-item">
                                            <div className="item-label">
                                                {language === "KOR"
                                                    ? "GPS개수"
                                                    : "GPS Rec."}
                                            </div>
                                            <div className="item-content">
                                                {/*<!--grey | red | orange | yellow | green-->*/}
                                                <i
                                                    className={`icon-gps ${
                                                        getGPSState(selectPathItem.gps_sv)
                                                    }`}
                                                >
                                                    {language === "KOR"
                                                        ? `${selectPathItem?.gps_sv}개`
                                                        : `${selectPathItem?.gps_sv}`}
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        {selectPathItem && code.indexOf(selectPathItem.event_code) === -1 && (
                                            <div className="detail-item">
                                                <div className="item-label">
                                                    {language === 'KOR' ? '이벤트' : 'Event'}
                                                </div>
                                                <div className="item-content fixed">
                                                    {event(selectPathItem.event_code,language)}
                                                </div>
                                            </div>)
                                        }                                        
                                        <div className="detail-item">
                                            <div className="item-label">
                                                {language === "KOR"
                                                    ? "주소"
                                                    : "Address"}
                                            </div>
                                            <div className="item-content fixed">
                                                {selectPathItem?.addr}
                                            </div>
                                        </div>
                                        <div className="detail-item">
                                            <div className="item-label">
                                                {language === "KOR"
                                                    ? "수신일시"
                                                    : "Received"}
                                            </div>
                                            <div className="item-content fixed">
                                                {ISODateToDateTime(selectPathItem?.makedate)}
                                            </div>
                                        </div>
                                        {selectPathItem?.type === 8 && (
                                            <div className="detail-item">
                                                <div className="item-label">
                                                    Bluetooth
                                                </div>
                                                <div className="item-content fixed">
                                                    {selectPathItem?.bt_nm || (language === "KOR"
                                                                                ? "블루투스 사용자 없음"
                                                                                : "No Access")}
                                                </div>
                                            </div>)
                                        }                                        
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="detail-body">
                                <div className="detail-summary">
                                    <div className="left">
                                        {/*<!--car | motorcycle | human | bike | truck | trash | school | aireplane | crane | pet-->*/}
                                        {/*<!--start | pause | end-->*/}
                                        <div
                                            className={
                                                "marker " +
                                                (selectDailyItem
                                                    ? getIconNumber(
                                                          selectDailyItem.icon_code
                                                      )
                                                    : "")
                                            }
                                        >
                                            <div className="icon">
                                                <i></i>
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                selectDailyItem?.unit_nm.length > 20
                                                    ? "name-s"
                                                    : "name" || "name"
                                            }
                                        >
                                            {selectDailyItem?.unit_nm || ""}
                                        </div>
                                    </div>
                                </div>

                                <div className="detail-content">
                                    <div className="col">
                                        <div className="detail-item">
                                            <div className="item-label">
                                                {language === "KOR"
                                                    ? "단말기번호"
                                                    : "Device Num"}
                                            </div>
                                            <div className="item-content">
                                                {selectDailyItem?.unitid.replace(
                                                    "@@",
                                                    ""
                                                ) || ""}
                                            </div>
                                        </div>
                                        <div className="detail-item">
                                            <div className="item-label">
                                                {language === "KOR"
                                                    ? "운행시간"
                                                    : "Driving Time"}
                                            </div>
                                            <div className="item-content">
                                                {selectDailyItem?.total_time}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="detail-content">
                                    <div className="row">
                                        <div className="detail-item">
                                            <div className="item-label">
                                                {language === "KOR"
                                                    ? "운행거리"
                                                    : "Driving Dist"}
                                            </div>
                                            <div className="item-content">
                                                {selectDailyItem &&
                                                    selectDailyItem?.total_calc_dist.toFixed(
                                                        2
                                                    )}{" "}
                                                km/h
                                            </div>
                                        </div>
                                        <div className="detail-item">
                                            <div className="item-label">
                                                {language === "KOR"
                                                    ? "평균속도"
                                                    : "Average Speed"}
                                            </div>
                                            <div className="item-content">
                                                {selectDailyItem &&
                                                    selectDailyItem?.avg_speed}{" "}
                                                km/h
                                            </div>
                                        </div>
                                        <div className="detail-item">
                                            <div className="item-label">
                                                {language === "KOR"
                                                    ? "발생 이벤트 수"
                                                    : "Event Count"}
                                            </div>
                                            <div className="item-content">
                                                {selectDailyItem &&
                                                    selectDailyItem?.eve_count}
                                                {language === "KOR" ? "개" : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/*<!--e: 오른쪽 상세정보판넬-->*/}
        </>
    );
}
