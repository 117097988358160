import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@toast-ui/react-grid";
import TuiGrid from "tui-grid";
import axios from "axios";
import { useAuth } from "../../context/LoginContext";
import { useLang } from "../../context/LanguageContext";
import tableStyle from "../run/organism/RunStyledTable";
import Form from "../Atomic/organism/FormAtom";
import CommonControl from "../Atomic/organism/CommonControl";
import { ISODateToDate } from "../../lib/common_func";
import Swal from 'sweetalert2';

export default function Table() {
    const { language } = useLang();
    const { user } = useAuth();
    const navi = useNavigate();
    const inputRef = React.createRef();
    const gridRef = useRef();
    const header = {
        //tui grid
        height: 50,
    };
    const [columns, setColumns] = useState([
        {
            width: 70,
            header: "Dday",
            name: "Dday",
            align: "center",
            resizable: true,
            sortable: true,
            className: 'red',
            defaultValue: '-',
            formatter: (item) => `<div style='color:red;'>${item.value}</div>`
            
        },
        {
            width: 80,
            header: '사용자',
            name: 'nm',
            align: 'center',
            resizable: true,
            sortable: true,
        },
        !user.org_cd ? 
        {
            width: 110,
            header: '구매처',
            name: 'place',
            align: 'center',
            resizable: true,
            sortable: true,
            
        } : {},
        {
            width: 90,
            header: '구매상품',
            name: 'prod_type',
            align: 'center',
            resizable: true,
            sortable: true,
            formatter : (value) => {
                let returnValue = '';
                if (value.value == '1') {
                    returnValue = '무선(6개월)'
                } else if(value.value == '2') {
                    returnValue = '무선(12개월)';
                }
                return returnValue;
            },
            /*
            filter: {
                type: 'select',
                options: {
                    listItems: [
                        {text: '무선(6개월)', value: 1},
                      {text: '무선(12개월)', value: 2},
                    ]
                },
           },
           */
        },
        {
            header: '배송주소',
            name: 'rv_addr',
            align: 'center',
            resizable: true,
            sortable: true,
            formatter : (value) => value.value.trim() === '' ? '-' : value.value,
        },
        {
            width: 70,
            header: '문자',
            name: 'sms_status',
            align: 'center',
            resizable: true,	  
            sortable: true,
            formatter: (value) => value.value == 'N' ? 'X' : 'O',
            /*
            filter: {
                type: 'select',
                options: {
                    listItems: [
                        {text: 'O', value: 'Y'},
                      {text: 'X', value: 'N'},
                    ]
                },
            },
            */
            validation: {
                validatorFn: (value,row, columnName) => value !== 'N',
            }
        },
        {
            width: 70,
            header: '인증',
            name: 'identity_status',
            align: 'center',
            resizable: true,	  
            sortable: true,
            formatter: (value) => value.value == 'N' ? 'X' : 'O',
            /*
            filter: {
                type: 'select',
                options: {
                    listItems: [
                    {text: 'O', value: 'Y'},
                    {text: 'X', value: 'N'},
                    ]
                },
            },
            */
            validation: {
                validatorFn: (value,row, columnName) => value !== 'N',
            }
        },
        {
            width: 70,
            header: '출고',
            name: 'status',
            align: 'center',
            resizable: true,	  
            sortable: true,
            formatter: (value) => value.value == 'OUT' ? `발송` : `미발송`,
            /*
            filter: {
                type: 'select',
                options: {
                    listItems: [
                        {text: '발송', value: 'OUT'},
                      {text: '미발송', value: 'IN'},
                    ]
                },
            },
            */
            validation: {
                validatorFn: (value,row, columnName) => value === 'OUT',
            }
        },
        {
            width: 95,
            header: '시작일',
            name: 'fromDate',
            align: "center",
            sortable: true,
            resizable: true,
            defaultValue: '-',
            formatter: (item) => item.value && ISODateToDate(item.value)
        },
        {
            width: 95,
            header: '만료일',
            name: 'toDate',
            align: "center",
            sortable: true,
            resizable: true,
            defaultValue: '-',
            formatter: (item) => item.value && ISODateToDate(item.value)
        },
        {
            width: 95,
            header: '등록일시',
            name: 'makedate',
            align: "center",
            sortable: true,
            resizable: true,
        },
        {
            width: 30,
            header: 'sms',
            name: 'send',
            align: "center",
            width: 80,
            formatter: () =>
            `<div class='link cursor'>전송</div>`,
        },
        {
            width: 30,
            header: '수정',
            name: 'edit',
            align: "center",
            width: 80,
            formatter: () =>
                `<div class='link cursor'>수정</div>`,
        }, 
        {
            width: 30,
            header: '삭제',
            name: 'delete',
            align: "center",
            width: 80,
            formatter: () =>
                `<div class='link cursor'>삭제</div>`,
        }
    ]);  

    // Hook
    TuiGrid.applyTheme(null, tableStyle);

    // State definition
    //==================================================================
    const [rawData, setRawData] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [keyword, setKeyword] = React.useState("");
    const [isRefresh, setIsRefresh] = useState(true);
    const bodyHeight = data.length < 10 ? data.length * 50 : 520;
    // Function definition
    //==================================================================
    async function submitFun(e) {
        e.preventDefault();
    }    
    function clearInput() {
        if (inputRef.current) {
        inputRef.current.value = "";
        }
        setKeyword("");
    }
    function inputChnage(key) {
        setKeyword(key);
        filterData(rawData, key);
    }
    const filterData = useCallback(
        (data, key) => {
        const filterCallback = () => {
            const result = data.filter((item) => {
            // Check if any key in the object includes the search key
            return Object.values(item).some(
                (value) => typeof value === "string" && value.includes(key)
            );
            });
            setData(result);
        };

        filterCallback();
        },
        [setData]
    )
    const sendSMS = (idx) => {
        Swal.fire({
            title:'문자를 전송하시겠습니까?',
            showCancelButton: true,
        }).then((res) => {
            if(res.isConfirmed) {
                axios.post('/web/order/edit',{type:'send', idx:idx}).then((res) => {
                    if(res.status === 200) {
                        Swal.fire({
                            title:'문자가 전송되었습니다.',
                        })
                    } else {
                        Swal.fire({
                            title:'전송에 실패하였습니다.',
                        })
                    }
                })
            }
        })

    }
    const delItem = (idx) => {
        Swal.fire({
            title:'삭제하시겠습니까?',
            showCancelButton: true,
        }).then((res) => {
            if(res.isConfirmed) {
                axios.post('/web/order/edit',{type:'del', idx:idx}).then((res) => {
                    if(res.status === 200) {
                        setIsRefresh(prev => !prev);
                    }
                })
            }
        })
    }
    const addItem = () => {
        navi('/Order/Detail', {state: { type: 'Add'}});
    }
    const checkOut = () => {
        Swal.fire({
            title:'일괄 발송 처리하시겠습니까?',
            showCancelButton: true,
        }).then((res) => {
            if(res.isConfirmed) {
                const rows = gridRef.current.gridInst.getCheckedRows();
                let value = '';
                for(let row of rows) {
                    if(value === '') {
                        value += row.idx;
                    } else {
                        value += ", " + row.idx;
                    }
                }
                if(value === '') {
                    Swal.fire({
                        title:'변경 할 항목이 없습니다.',
                    })
                } else {
                    axios.post('/web/order/edit',{type:'checkout', idx: value}).then((res) => {
                        if(res.status === 200) {
                            setIsRefresh(prev => !prev);
                        } else {
                            Swal.fire({
                                title:'요청이 실패하였습니다.',
                            })
                        }
                    })
                }
            }
        })
    }
    const checkIn = () => {
        Swal.fire({
            title:'일괄 미발송 처리하시겠습니까?',
            showCancelButton: true,
        }).then((res) => {
            if(res.isConfirmed) {
                const rows = gridRef.current.gridInst.getCheckedRows();
                let value = '';
                for(let row of rows) {
                    if(value === '') {
                        value += row.idx;
                    } else {
                        value += ", " + row.idx;
                    }
                }
                if(value === '') {
                    Swal.fire({
                        title:'변경 할 항목이 없습니다.',
                    })
                } else {
                    axios.post('/web/order/edit',{type:'checkin', idx: value}).then((res) => {
                        if(res.status === 200) {
                            setIsRefresh(prev => !prev);
                        } else {
                            Swal.fire({
                                title:'요청이 실패하였습니다.',
                            })
                        }
                    })
                }
            }
        })
    }
    const move = (e) => {
        if(e.targetType !== "columnHeader" && e.targetType !== "rowHeader") {
            const i = e.instance.store.focus.rowIndex;        
            const idx = e.instance.store.data.rawData[i].idx;
    
            switch (e.columnName) {
            case "edit":
                navi(`/Order/Detail`, { state: { type: 'Edit', idx: idx } });
                break;
            case "delete":
                delItem(idx);
                break;
            case "send":
                sendSMS(idx);
                break;
            default:
                break;
            }
        }
    };

    // UseEffect definition
    //==================================================================
    useEffect(() => {
        axios.get(`/web/order/list?adm_flag=${user.org_cd ? 'N' : 'Y'}&auth=${user.auth.I000 === 'Y' ? 'Y' : 'N'}&u_idx=${user.idx}`).then((res) => {
        setRawData(res.data);
        });

    }, [user, isRefresh]);

    useEffect(() => {
        if (rawData && keyword === "") {
        setData(rawData);
        } else if (rawData && keyword !== "") {
        filterData(rawData, keyword);
        }
    }, [rawData, keyword, filterData]);

    // Data definition
    //==================================================================
    const formData = {
        lan: language,
        inputId: "keyword",
        type: "text",
        placeKR: "검색어를 입력하세요",
        placeEN: "Please enter a search term",
        submitHandler: submitFun,
        filter: (e) => inputChnage(e),
        myref: inputRef,
        value: keyword,
    };
    const buttonData = [
        {
            labelKR: "전체목록",
            labelEN: "전체목록",
            classN: "purple",
            clickFun: () => {
                clearInput();
            },
            position: "left",
            icon: false,
            iconClass: "",
        },
        {
            labelKR: "등록",
            labelEN: "등록",
            classN: "purple",
            clickFun: () => {
                addItem();
            },
            position: "",
            icon: false,
            iconClass: "",
        },
        {
            labelKR: "일괄발송",
            labelEN: "일괄발송",
            classN: "purple",
            clickFun: () => {
                checkOut();
            },
            position: "",
            icon: false,
            iconClass: "",
        },
        {
            labelKR: "일괄미발송",
            labelEN: "일괄미발송",
            classN: "purple",
            clickFun: () => {
                checkIn();
            },
            position: "",
            icon: false,
            iconClass: "",
        }
    ];

    return (
        <>
            <Form formData={formData} inputRef={inputRef} />
            <div className="common-table-wrapper">
                <div className="common-grid">
                <Grid
                    ref={gridRef}
                    data={data}
                    columns={columns}
                    rowHeight={50}
                    rowHeaders={[{type: 'checkbox', aign: 'center', valign: 'middle'},{ type: 'rowNum', align: 'center', valign: 'middle'}]}
                    scrollX={false}
                    scrollY={true}
                    bodyHeight={bodyHeight}
                    header={header}
                    onClick={move}
                />
                </div>
            </div>
            <CommonControl buttonData={buttonData} language={language} />
        </>
    );
}