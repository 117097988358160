import React from "react";
import TerminalMain from "../../components/term/pages/TerminalMain";
import { LanguageContext } from "../../context/LanguageContext.jsx";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../context/LoginContext";
// import useFetch from "../../hook/useFetch";

export default function Terminal() {
  const { language } = useContext(LanguageContext);
  const { user } = useAuth();
  return (
    <>
      <TerminalMain language={language} user={user} />
      <Outlet />
    </>
  );
}
