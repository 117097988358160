import Title from "../atom/Title";
import PropTypes from "prop-types";

export default function ColorPicker({
  state,
  handlerClick,
  isOpen,
  onChange,
  title,
}) {
  function handleColorChange(color) {
    onChange("color", color);
    handlerClick(false);
  }
  return (
    <>
      <div className="color-picker-box">
        <Title title={title} />
        <span
          className={`${state.color} color-picker-item`}
          onClick={(e) => handlerClick(true)}
          // style={{ backgroundColor: state.color }}
        ></span>
        {isOpen && (
          <div className="color-picker-box">
            <button
              className="color-picker-item"
              style={{ backgroundColor: "red" }}
              onClick={() => handleColorChange("Red")}
            ></button>
            <button
              className="color-picker-item"
              style={{ backgroundColor: "blue" }}
              onClick={() => handleColorChange("Blue")}
            ></button>
            <button
              className="color-picker-item"
              style={{ backgroundColor: "green" }}
              onClick={() => handleColorChange("Green")}
            ></button>
            <button
              className="color-picker-item"
              style={{ backgroundColor: "yellow" }}
              onClick={() => handleColorChange("Yellow")}
            ></button>
            <button
              className="color-picker-item"
              style={{ backgroundColor: "#ff008c" }}
              onClick={() => handleColorChange("Pink")}
            ></button>
          </div>
        )}
      </div>
    </>
  );
}

ColorPicker.propTypes = {
  state: PropTypes.object.isRequired,
  handlerClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};
