import React from "react";
import { Link } from "react-router-dom";
interface Auth extends Object {
  I000: string
}
interface UserInfoProps {
  user: {
    nm: string;
    org_nm: string;
    type: string;
    auth: Auth;
  };  
}
export default function UserInfo({ user }: UserInfoProps) {

  return (
    <>
      <Link to={user.auth.I000 === 'Y' ? "/Order" : "/Set/Custom"} className="user-info">
        <figure className="user-avatar">
          <img
            src="../../../assets/img/header/icon-user.svg"
            alt="user-avatar"
          />
        </figure>
        <div>
          <div
            title={user.nm}
            className={
              user.nm.length > 15
                ? "user-name-s w-ellipsis"
                : user.nm.length > 10
                ? "user-name-m"
                : "user-name"
            }
          >
            {user.nm}
          </div>
          {user.type !== "ADM" && (
            <div className="org-name">({user.org_nm})</div>
          )}{" "}
          {/** 추후 길이에 따른 조절이 필요 */}
        </div>
      </Link>
    </>
  );
}
