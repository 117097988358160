import moment from 'moment';
import React from 'react';
import { useLang } from '../../../context/LanguageContext';
import { add_hyphen } from '../../../lib/common_func';

export default function DayTableExcel({ data, totalCount, sort, excelRef, column, date, unitid, unit_nm, totalDist }) {
    const { language } = useLang();

    const style = {
        primary:{backgroundColor:'#DCE8F5'},
        color:{blue:{color:'blue'}},
        sort:{left:{textAlign:'left'},center:{textAlign:'center'},right:{textAlign:'right'}},
        row:{height:'30px',textAlign:'center'},
        border:{border:'1px solid #413c3c'}
    }
    let activeCount = 0;
    Object.values(column).map((item) => {
        if(item.active) {
        activeCount++;
        }
    })

    return (
        <>
            <table className="common-table excel-table" ref={excelRef}>
                <thead>
                    <tr>
                        <td colSpan={activeCount} style={{...style.row,...style.border}}>
                            <h2>
                                <span>
                                    {`${date} ${unit_nm} : `}
                                </span>
                                <span style={style.color.blue}>
                                    {unitid && add_hyphen(unitid)}
                                </span>
                                <span>
                                    {`  운행기록`}
                                </span>
                            </h2>
                        </td>
                    </tr>
                    <tr>
                    {Object.values(column).map((item, index) => {
                        return (item.active && (
                        <th key={index} style={{...style.primary, ...style.border}}>
                            <label className="custom-checkbox">
                                <div className="label">{language === 'KOR' ? item.ko : item.eng}</div>
                            </label>
                        </th>
                        ));
                    })}
                    </tr>          
                </thead>
                <tbody>
                    {data &&
                    data.map((item, index) => {
                        const {
                        driver,
                        makedate_cmbd,
                        car_status,
                        speed,
                        inner_battery,
                        vehicle_battery,
                        gps_sv,
                        gps_sv2,
                        sum_calc_dist,
                        lat,
                        lon,
                        addr,
                        wire_type,
                        } = item;
                        return (
                        <tr key={index}>
                            {column.no.active && <td style={{...style.row,...style.border}}>{sort ? totalCount - index : index + 1}</td>}
                            {column.driver.active && <td style={{...style.row,...style.border}}>{driver === null ? "" : driver}</td>}
                            {column.makedate.active && <td style={{...style.row,...style.border}}>
                            {moment
                                .utc(`${makedate_cmbd}`)
                                .format("HH:mm:ss")}
                            </td>}
                            {column.c_status.active && <td style={{...style.row,...style.border}}>{car_status}</td>}
                            {column.speed.active && <td style={{...style.row,...style.border}}>{speed}</td>}
                            {column.d_voltage.active && <td style={{...style.row,...style.border}}>{inner_battery} V</td>}
                            {(wire_type === "Y" && column.v_voltage.active) && (<td style={{...style.row,...style.border}}>{vehicle_battery} V</td>)}
                            {column.g_status.active && <td style={{...style.row,...style.border}}>{gps_sv}</td>}
                            {column.g_count.active && <td style={{...style.row,...style.border}}>{gps_sv2}</td>}
                            {column.dist.active && <td style={{...style.row,...style.border}}>{sum_calc_dist.toFixed(2)}</td>}
                            {column.lat.active && <td style={{...style.row,...style.border}}>{lat}</td>}
                            {column.lon.active && <td style={{...style.row,...style.border}}>{lon}</td>}
                            {column.addr.active && <td style={{...style.row,...style.border}}>{addr}</td>}
                        </tr>
                        );
                    })}
                    <tr style={style.row}>
                        <td colSpan={2} style={{...style.primary,...style.border}}>총 운행거리</td>
                        <td colSpan={2} style={style.border}>{totalDist} km</td>
                        <td colSpan={activeCount - 4} style={style.border}></td>
                    </tr>
                </tbody>
            </table> 
        </>
    );
}

