import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../lib/axios";
//@ts-ignore
import { dataList } from "../../../Admin/AdmMain";
import { DeleteFun } from "../../../Atomic/exFunc";
import CommonControl from "../../../Atomic/organism/CommonControl";
import Page from "../../../Atomic/template/PageTable";
import InputForm from "../../../Atomic/atoms/InputForm";
import Radio from "../../../Atomic/atoms/Radio";
import Select from "../../../Atomic/molecules/Select";
import moment from "moment";
import FitlerForm from "../../../Atomic/molecules/FitlerForm";
export default function Form() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const navi = useNavigate();
  const { user } = useAuth();
  // State defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [rawData, setRawData] = useState<any>({});
  const [deviceData, setDeviceData] = useState<any>([]);

  //=======================================================================
  // Function defines
  //=======================================================================

  function setOnclick(item: any) {
    setRawData((prev: any) => ({
      ...prev,
      unitid: item.unitid,
      d_nm: item.unit_nm,
    }));
  }
  //=======================================================================

  function inputHandler(e: any) {
    const { name, value } = e.target;
    setRawData((pre: any) => ({ ...pre, [name]: value }));
  }
  function checkHandler(e: any) {
    const { name } = e.target;
    setRawData((pre: any) => ({
      ...pre,
      [name]: pre[name] === "Y" ? "N" : "Y",
    }));
  }

  function createNew() {
    if (rawData.d_nm === "") {
      Swal.fire({
        title: `${translate(
          "단말명칭을 입력해주세요",
          "Please enter the device name"
        )}`,
      });
      return;
    }
    if (rawData.defect_u_nm === "") {
      Swal.fire({
        title: `${translate(
          "요청자(고객명)을 입력해주세요",
          "Please enter the customer's name"
        )}`,
      });
      return;
    }
    if (rawData.unitid === "") {
      Swal.fire({
        title: `${translate(
          "단말기번호을 입력해주세요",
          "Please enter the device number"
        )}`,
      });
      return;
    }
    if (rawData.fix_u_nm === "") {
      Swal.fire({
        title: `${translate(
          "조치자을 입력해주세요",
          "Please enter the person in charge"
        )}`,
      });
      return;
    }
    if (rawData.memo === "") {
      Swal.fire({
        title: `${translate("비고을 입력해주세요", "Please enter the memo")}`,
      });
      return;
    }
    Swal.fire({
      title: `${translate("저장하시겠습니까?", "Do you want to save?")}`,
      showCancelButton: true,
      confirmButtonText: `${translate("확인", "Confirm")}`,
      cancelButtonText: `${translate("취소", "Cancel")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        if (isAdd) {
          axios
            .post(`/web/admin/complain/add`, rawData)
            .then((res: any) => {
              if (res.status === 200) {
                Swal.fire({
                  title: "등록되었습니다.",
                }).then((result) => {
                  navi("/Adm/def-table");
                });
              } else {
                Swal.fire({
                  title: "등록에 실패하였습니다.",
                });
              }
            })
            .catch((err: any) => {
              console.log(err);
            });
        } else {
          axios
            .post(`/web/admin/complain/edit`, rawData)
            .then((res: any) => {
              if (res.status === 200) {
                Swal.fire({
                  title: "등록되었습니다.",
                }).then((result) => {
                  navi("/Adm/def-table");
                });
              } else {
                Swal.fire({
                  title: "등록에 실패하였습니다.",
                });
              }
            })
            .catch((err: any) => {
              console.log(err);
            });
        }
      }
    });
  }

  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "삭제",
      labelEN: "Delete",
      classN: "white",
      icon: true,
      clickFun: () => {
        DeleteFun(`/web/admin/complain/del`, { idx: idx, type: "ADM" }, navi);
      },
      position: " right",
    },
    {
      labelKR: isAdd ? "등록" : "수정",
      labelEN: isAdd ? "Add" : "Edit",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];
  const buttonDataAdd = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },

    {
      labelKR: isAdd ? "등록" : "수정",
      labelEN: isAdd ? "Add" : "Edit",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];

  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
  }, [location]);
  useEffect(() => {
    if (idx !== "") {
      axios
        .get(
          `/web/admin/complain/detail?idx=${idx}&type=${user.type}`
        )
        .then((res: any) => {
          setRawData(res.data);
          setRawData((pre: any) => ({ ...pre, type: user.type }));
          setIsAdd(false);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      setIsAdd(true);
      setRawData((pre: any) => ({
        ...pre,
        type: user.type,
        uidx: user.idx,
        fix_kind: "1",
        d_nm: "",
        defect_acc: "Y",
        defect_ctnt: "",
        defect_dmg: "",
        defect_etc: "",
        defect_gps: "",
        defect_net: "",
        defect_sumr: "",
        defect_u_nm: "",
        defect_ymd: `${moment().format("YYYY-MM-DD")}`,
        etc: "",
        fix_ctnt: "",
        fix_u_nm: "",
        fix_ymd: `${moment().format("YYYY-MM-DD")}`,
        memo: "",
        unitid: "",
      }));
    }
  }, [idx, user]);

  useEffect(() => {
    axios
      .get(
        `/web/admin/terminal/list?type=${user.type}`
      )
      .then((res: any) => {
        setDeviceData(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [user]);

  // Log defines
  //=======================================================================

  // console.log("rawData", rawData);
  // console.log("deviceData", deviceData);
  // console.log("state", location.state);
  // console.log("idx", idx);
  // console.log("devdeviceDataiceData", );
  // console.log("user", user.type);
  // console.log("refId", refId.current?.value);

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "시스템 공지사항" : "System Notice"}`}
        menu="/Adm/def-table"
      >
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <InputForm
                label={translate("접수일자", "Recipe Date")}
                name="defect_ymd"
                val={moment.utc(rawData.defect_ymd).format("YYYY-MM-DD") || ""}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required=""
                readonly={true}
              />
              <InputForm
                label={translate("처리일자", "Process Date")}
                name="fix_ymd"
                val={moment.utc(rawData.fix_ymd).format("YYYY-MM-DD") || ""}
                auto="off"
                type="date"
                changeFun={inputHandler}
                required=""
                readonly={false}
              />
            </tr>
            <tr>
              <FitlerForm
                rawData={rawData}
                title={translate("단말명칭", "Device Name")}
                place={translate("단말명칭", "Device Name")}
                button={translate("초기화", "Reset")}
                deviceData={deviceData}
                setRawData={setOnclick}
                inName={"d_nm"}
                filter={`unit_nm`}
              />

              <FitlerForm
                rawData={rawData}
                title={translate("단말기번호", "Device No")}
                place={translate("단말기번호", "Device No")}
                button={translate("초기화", "Reset")}
                deviceData={deviceData}
                setRawData={setOnclick}
                inName={"unitid"}
                filter={`unitid`}
              />
            </tr>
            <tr>
              <InputForm
                label={translate("요청자(고객명)	", "Requester (customer name)")}
                name="defect_u_nm"
                val={rawData.defect_u_nm || ""}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
              <th>{translate("기타", "Option")}</th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"etc"}
                    val={"Y"}
                    title={translate("유상", "Paid")}
                    change={(e) => inputHandler(e)}
                    check={rawData.etc === "Y" ? true : false}
                  />
                  <Radio
                    name={"etc"}
                    val={"N"}
                    title={translate("무상", "Free of charge")}
                    change={(e) => inputHandler(e)}
                    check={rawData.etc === "N" ? true : false}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          className="sub-page-header"
          style={{
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          <div className="header-title">
            {translate("불량 유형", "Defct Type")}
          </div>
        </div>
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th>{translate("불량 유형", "Defect type")}</th>
              <td colSpan={3}>
                <div className="custom-check-group">
                  <Radio
                    type="checkbox"
                    name={"defect_net"}
                    val={"Y"}
                    title={translate("통신불량", "Communication defect")}
                    change={(e) => checkHandler(e)}
                    check={rawData.defect_net === "Y" ? true : false}
                  />
                  <Radio
                    type="checkbox"
                    name={"defect_gps"}
                    val={"Y"}
                    title={translate("GPS 불량", "GPS defect")}
                    change={(e) => checkHandler(e)}
                    check={rawData.defect_gps === "Y" ? true : false}
                  />
                  <Radio
                    type="checkbox"
                    name={"defect_acc"}
                    val={"Y"}
                    title={translate("전원불량", "Power defect")}
                    change={(e) => checkHandler(e)}
                    check={rawData.defect_acc === "Y" ? true : false}
                  />
                  <Radio
                    type="checkbox"
                    name={"defect_dmg"}
                    val={"Y"}
                    title={translate("파손 ", "Damage")}
                    change={(e) => checkHandler(e)}
                    check={rawData.defect_dmg === "Y" ? true : false}
                  />
                  <Radio
                    type="checkbox"
                    name={"defect_sumr"}
                    val={"Y"}
                    title={translate("침수", "Flood")}
                    change={(e) => checkHandler(e)}
                    check={rawData.defect_sumr === "Y" ? true : false}
                  />
                  <Radio
                    type="checkbox"
                    name={"defect_etc"}
                    val={"Y"}
                    title={translate("기타", "Etc")}
                    change={(e) => checkHandler(e)}
                    check={rawData.defect_etc === "Y" ? true : false}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>{translate("불만내용", "Complaint content")}</th>
              <td colSpan={3} style={{ height: "200px" }}>
                <div className="custom-input" style={{ height: "100%" }}>
                  <textarea
                    style={{ height: "100%", width: "100%", padding: "1.5rem" }}
                    onChange={inputHandler}
                    value={rawData.defect_ctnt || ""}
                    name="defect_ctnt"
                    placeholder={translate("내용을 입력해주세요", "Content")}
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <th>{translate("조치내용", "Action details")}</th>
              <td colSpan={3} style={{ height: "200px" }}>
                <div className="custom-input" style={{ height: "100%" }}>
                  <textarea
                    style={{ height: "100%", width: "100%", padding: "1.5rem" }}
                    onChange={inputHandler}
                    value={rawData.fix_ctnt || ""}
                    name="fix_ctnt"
                    placeholder={translate("내용을 입력해주세요", "Content")}
                  ></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <InputForm
                label={translate("조치자", "Person in charge")}
                name="fix_u_nm"
                val={rawData.fix_u_nm || ""}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
              <th>{translate("처리유무", "Processing Status")}</th>
              <td>
                <Select
                  id="fix_kind"
                  short={"N"}
                  name="fix_kind"
                  options={[
                    {
                      name: translate("입고중", "In stock"),
                      value: "1",
                    },
                    {
                      name: translate("선출고", "Pre-release"),
                      value: "2",
                    },
                    {
                      name: `${translate("처리중", "Processing")}`,
                      value: "3",
                    },
                    {
                      name: `${translate("완료", "Complete")}`,
                      value: "4",
                    },
                  ]}
                  changeFun={(e) => inputHandler(e)}
                  def={rawData.org_type || ""}
                />
              </td>
            </tr>
            <tr>
              <InputForm
                label={translate("비고", "Note")}
                name="memo"
                val={rawData.memo || ""}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
            </tr>
          </tbody>
        </table>

        <CommonControl
          buttonData={isAdd ? buttonDataAdd : buttonData}
          language={language}
        />
      </Page>
    </>
  );
}
