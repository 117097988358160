import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../../lib/axios.js";
//@ts-ignore
import { dataList } from "../../../AdmMain";
import { DeleteFun } from "../../../../Atomic/exFunc";
import CommonControl from "../../../../Atomic/organism/CommonControl";
import Page from "../../../../Atomic/template/PageTable";
import moment from "moment";
// @ts-ignore
export default function Form() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const navi = useNavigate();
  const { user } = useAuth();
  // State defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [rawData, setRawData] = useState<any>({
    nm: "",
    memo: "",
    type: user.type,
  });

  //=======================================================================
  // Function defines
  //=======================================================================

  function inputHandler(e: any) {
    const { name, value } = e.target;
    setRawData((pre: any) => ({ ...pre, [name]: value }));
  }
  function createNew() {
    if (rawData.nm === "") {
      Swal.fire({
        title: `${translate(
          "부서명을 입력해주세요",
          "Please enter the depart name"
        )}`,
      });
      return;
    }
    console.log("rawData", rawData);

    Swal.fire({
      title: `${translate("정보를 수정하시겠습니까?", "Do you want to edit?")}`,
      showCancelButton: true,
      confirmButtonText: `${translate("수정", "Edit")}`,
      cancelButtonText: `${translate("취소", "Cancel")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`/web/admin/depart/${isAdd === true ? "add" : "edit"}`, rawData)
          .then((res: any) => {
            if (res.status === 200) {
              Swal.fire({
                title: `${translate("수정되었습니다.", "Edited.")}`,
              }).then((result) => {
                window.history.back();
              });
            } else {
              Swal.fire({
                title: `${translate(
                  `수정에 실패하였습니다.`,
                  "Edition failed."
                )}`,
              });
            }
          });
      }
    });
  }

  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },

    {
      labelKR: "삭제",
      labelEN: "Delete",
      classN: "white",
      icon: true,
      clickFun: () => {
        DeleteFun(`/web/admin/depart/del`, { idx: idx, type: "ADM" }, navi);
      },
      position: " right",
    },
    {
      labelKR: "수정",
      labelEN: "Edit",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];

  const buttonDataAdd = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },

    {
      labelKR: "등록",
      labelEN: "Create",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];

  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
  }, [location]);
  useEffect(() => {
    if (idx !== "") {
      axios
        .get(`/web/admin/depart/detail?idx=${idx}&type=${user.type}`)
        .then((res: any) => {
          setRawData(res.data);
          setIsAdd(false);
          setRawData((pre: any) => ({ ...pre, type: user.type }));
        });
    } else {
      setIsAdd(true);
      setRawData({
        nm: "",
        memo: "",
        type: user.type,
        idx: user.idx,
      });
    }
  }, [idx, user]);

  // Log defines
  //=======================================================================

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "조직 관리" : "ORG Management"}`}
        menu="/Adm/o-table"
      >
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th className="required">{`${translate(
                "부서명",
                "Depart Name"
              )}`}</th>
              <td>
                <div className="custom-input">
                  <input
                    type="text"
                    name="nm"
                    value={rawData.nm}
                    onChange={inputHandler}
                  />
                </div>
              </td>
              <th>{`${translate("부서설명", "Depart Description")}`}</th>
              <td>
                <div className="custom-input">
                  <input
                    type="text"
                    name="memo"
                    value={rawData.memo}
                    onChange={inputHandler}
                  />
                </div>
              </td>
            </tr>
            {!isAdd && (
              <tr>
                <th>{`${translate("일시", "Registration Date")}`}</th>
                <td colSpan={1}>
                  <div className="custom-input">
                    <input
                      type="text"
                      value={`${moment.utc(rawData.makedate)
                        .utc()
                        .format(`YYYY-MM-DD`)}`}
                      readOnly
                    />
                  </div>
                </td>
                <th>{`${translate("수정일", "Edit date")}`}</th>
                <td colSpan={1}>
                  <div className="custom-input">
                    <input
                      type="text"
                      value={`${moment.utc(rawData.makedate)
                        .utc()
                        .format(`YYYY-MM-DD`)}`}
                      readOnly
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isAdd ? (
          <CommonControl buttonData={buttonDataAdd} language={language} />
        ) : (
          <CommonControl buttonData={buttonData} language={language} />
        )}
      </Page>
    </>
  );
}
