import React, { useState } from "react";
interface ControlProps {
  changeMapType: () => void;
  changeStreetMode: () => void;
  reloadMap: () => void;
  isNormal: boolean;
  isStreet: boolean;
}
export default function Control({
  changeMapType,
  isNormal,
  isStreet,
  changeStreetMode,
  reloadMap,
}: ControlProps) {
  const [isOn, setIsOn] = useState(false);

  return (
    <div className="map-box-ctr-wrap">
      <div className="map-box-ctr-btn">
        {isOn && (
          <>
            <button
              onClick={() => {
                changeStreetMode();
              }}
              type="button"
              className={`control-btn street ${isStreet ? "active" : ""}`}
            >
              <i className="ri-webcam-line icon"></i>
            </button>
            {isNormal ? (
              <button
                type="button"
                className="control-btn street "
                onClick={() => {
                  changeMapType();
                }}
              >
                <i className="ri-map-2-line icon"></i>
              </button>
            ) : (
              <button
                type="button"
                className="control-btn street active"
                onClick={() => {
                  changeMapType();
                }}
              >
                <i className="ri-radar-line icon"></i>
              </button>
            )}

            <button
              type="button"
              className="control-btn street"
              onClick={() => {
                reloadMap();
              }}
            >
              <i className="ri-refresh-line icon"></i>
            </button>
          </>
        )}
        <button
          onClick={() => setIsOn(!isOn)}
          type="button"
          className={`control-btn ${isOn ? "active" : ""}`}
        >
          <i className="ri-dashboard-line"></i>
        </button>
      </div>
    </div>
  );
}
