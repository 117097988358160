import React, { useState } from "react";
interface ModalProps {
  addr: string;
  on: string;
  off: string;
  last: string;
  day: string;
  total: string;
  rawData?: any;
  lang?: string;
}
export default function Modal({
  lang,
  addr,
  on,
  off,
  last,
  day,
  total,
  rawData,
}: ModalProps) {
  const [modal, setModal] = useState(false);
  function inOut(type: string) {
    if (type === "N") {
      return lang === "KOR" ? "설정해제" : "Alarm off";
    } else if (type === "E") {
      return lang === "KOR" ? "진입" : "In";
    } else if (type === "D") {
      return lang === "KOR" ? "이탈" : "Out";
    } else if (type === "B") {
      return lang === "KOR" ? "진입/이탈" : "In/Out";
    }
  }
  return (
    <>
      <div className="map-box-modal-wrap">
        <div className="map-box-modal">
          {modal && (
            <div className="map-box-modal-body">
              <div className="map-box-modal-row">
                {rawData.zone_id === undefined && (
                  <>
                    <div className="map-box-modal-item">
                      <p>
                        {lang === "KOR" ? "최근시동 ON : " : "Recent Start ON : "}
                      </p>
                      <p>{on}</p>
                    </div>
                    <div className="map-box-modal-item">
                      <p>
                        {lang === "KOR" ? "최근시동 OFF : " : "Recent End Off : "}
                      </p>
                      <p>{off}</p>
                    </div>
                    <div className="map-box-modal-item">
                      <p>
                        {lang === "KOR" ? "최종수신일시 : " : "Last Received : "}
                      </p>
                      <p>{last}</p>
                    </div>
                  </>
                )}
                {rawData.zone_id !== undefined && (
                  <>
                    <div className="map-box-modal-item">
                      <p>{lang === "KOR" ? "반경 : " : "Radius : "}</p>
                      <p>{rawData.radius}m</p>
                    </div>
                    <div className="map-box-modal-item">
                      <p>{lang === "KOR" ? "알림종류 : " : "Alarm type : "}</p>
                      <p>{`${inOut(rawData.in_out_type)}`}</p>
                    </div>
                  </>
                )}
              </div>
              {rawData.zone_id === undefined && (
                <div className="map-box-modal-row">
                  <div className="map-box-modal-item">
                    <p>
                      {lang === "KOR" ? "일주행거리 : " : "Today Mileage : "}
                    </p>
                    <p>{parseInt(day).toFixed(2)} km</p>
                  </div>
                  <div className="map-box-modal-item">
                    <p>
                      {lang === "KOR" ? "총주행거리 : " : "Total Mileage : "}
                    </p>
                    <p>{parseInt(total).toFixed(2)} km</p>
                  </div>
                </div>
              )}
              {rawData.zone_id !== undefined && (
                <div className="map-box-modal-row">
                  <div className="map-box-modal-item">
                    <p>{lang === "KOR" ? "지번 : " : "Number Name : "}</p>
                    <p>{rawData.number_addr}</p>
                  </div>
                  <div className="map-box-modal-item">
                    <p>{lang === "KOR" ? "도로명 : " : "Road Name : "}</p>
                    <p>{rawData.road_addr}</p>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="map-box-modal-footer">
            <div className="map-box-modal-footer-title">
              <i className="ri-information-line"></i>
              <span>{addr}</span>
            </div>
            <div className="map-box-modal-footer-close">
              <button
                onClick={() => setModal(!modal)}
                type="button"
                className="modal-close-btn"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
