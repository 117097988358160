import { useState, useEffect } from "react";
import { useMap } from "../../../context/MapContext";
import {  getMarkerType } from "../../../lib/mapHelper";

export default function Marker({ item, index, circle, setCircle }) {
  const { naver } = window;
  const { mapState, mapDispatch } = useMap();
  const { map, cluster, selectItem, pathData, selectPathItem, trackingList, zoom } =
    mapState;
  const [marker, setMarker] = useState(null);

  const radius = {
    21:[12,15],
    20:[16,20],
    19:[20,25],
    18:[36,45],
    17:[72,90],
    16:[140,175],
    15:[300,375],
    14:[600,750],
    13:[1200,1500],
  }

  useEffect(() => {
    if (!marker) {
      setMarker(new naver.maps.Marker());
    }

    // map이 언마운트될때 삭제
    return () => {
      if (marker) {
        circle.c1?.setMap(null);
        circle.c2?.setMap(null);
        marker.eventTarget.parentNode.classList.remove("active");
        marker.setMap(null);
      }
    };
  }, [marker, naver]);

  useEffect(() => {
    if (map && marker) {
      marker.setOptions({
        position: new naver.maps.LatLng(item.latitude, item.longitude),
        map: map,
        icon: {
          content: `
                    <div class="marker ${getMarkerType(item.iconnum)}">
                        <div class="marker-icon" style="transform: rotate(${
                          180 + parseInt(item.direction)
                        }deg)">
                            <span class="icon" style="transform: rotate(${
                              180 - parseInt(item.direction)
                            }deg)">
                                <i></i>
                            </span>
                        </div>
                        <div title="${item.unit_nm}" class="${
            item.unit_nm.length > 12
              ? "marker-name-s w-ellipsis"
              : "marker-name"
          }">${item.unit_nm}</div>
                    </div>`,
          size: new naver.maps.Size(54, 54),
          anchor: new naver.maps.Point(27, 54),
        },
        markerId: item.unitid,
      });

      marker.eventTarget.parentNode.classList.add("size");

      //클러스터에 등록후 갱신(클러스터 내부 매서드이용해서 업데이트
      cluster?.setMarker(marker);
      // cluster?._updateClusters();
      if (trackingList.length === index + 1) {
        cluster?._redraw();
      }

      //마커의 클릭에는 selected state변경만 유도
      const listener = naver.maps.Event.addListener(marker, "click", (e) => {
        mapDispatch({
          type: "SET_SELECT_ITEM",
          payload: item,
        });
        // naver.maps.Event.removeListener(listener);
      });
    }
  }, [map, marker, naver, item, cluster, mapDispatch]);
  // }, [map, marker, naver, item, cluster, selectItem, mapDispatch]);

  useEffect(() => {
    //selected state변경이되면 마커위치로 이동하고 클래스 토글
    if (marker && !selectPathItem) {
      if (selectItem && selectItem.unitid === item.unitid) {
        map.setZoom(17, false);
        if(zoom != 17) {
          mapDispatch({type:'SET_ZOOM', action:17})
        }
        marker.setOptions({
          position: new naver.maps.LatLng(
            item.latitude,
            item.longitude
          ),
          icon: {
            content: `
                          <div class="marker ${getMarkerType(item.iconnum)}">
                          <div class="marker-icon" style="transform: rotate(${
                            180 + parseInt(item.direction)
                          }deg)">
                              <span class="icon" style="transform: rotate(${
                                180 - parseInt(item.direction)
                              }deg)">
                                  <i></i>
                              </span>
                          </div>
                          <div title="${item.unit_nm}" class="${
                            item.unit_nm.length > 12
                ? "marker-name-s w-ellipsis"
                : "marker-name"
              }">${item.unit_nm}</div>
                      </div>`,
            size: new naver.maps.Size(54, 54),
            anchor: new naver.maps.Point(27, 54),
          },
          markerId: item.unitid,
        });
        map.panTo(
          {
            ...marker.position,
            y: marker.position.y - 0.00012, //약간 위로 보이게 보정
          },
          {
            duration: 300,
            easing: "easeOutCubic",
          }
        );
        marker.eventTarget.parentNode.classList.add("active");
        circle.c1?.setMap(null);
        circle.c2?.setMap(null);
        setCircle((prev) => ({
          c1: new naver.maps.Circle({
            map: map,
            center: marker.position,
            radius: radius[17][0],
            fillColor: "rgba(44,38,133,0.13)",
            strokeOpacity: 0,
          }),
          c2: new naver.maps.Circle({
            map: map,
            center: marker.position,
            radius: radius[17][1],
            fillColor: "rgba(44,38,133,0.13)",
            strokeOpacity: 0,
          }),
        }));
        // handleDetailPanel();
      } else {
        marker.eventTarget.parentNode.classList.remove("active");
        circle.c1?.setMap(null);
        circle.c2?.setMap(null);
      }
    }
  }, [
    map,
    item,
    marker,
    selectItem,
    cluster,
    pathData,
    selectPathItem,
    trackingList,
  ]);

  useEffect(() => {
    if(marker && selectItem && selectItem.unitid === item.unitid ) {
      circle.c1?.setMap(null);
      circle.c2?.setMap(null);
      if(zoom > 12) {
        setCircle(() => ({
          c1: new naver.maps.Circle({
            map: map,
            center: marker.position,
            radius: radius[zoom][0],
            fillColor: "rgba(44,38,133,0.13)",
            strokeOpacity: 0,
          }),
          c2: new naver.maps.Circle({
            map: map,
            center: marker.position,
            radius: radius[zoom][1],
            fillColor: "rgba(44,38,133,0.13)",
            strokeOpacity: 0,
          }),
        }))
      }
    }
  },[zoom])
        

  useEffect(() => {
    circle.c1?.setMap(null);
    circle.c2?.setMap(null);
  }, [pathData]);
  return null;
}
