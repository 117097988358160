import PropTypes from "prop-types";
export default function TextArea({ value, changeFun, id, inputName }) {
  return (
    <>
      <div className="row-content">
        <div className="">
          <textarea
            className="text-area-event-calendar"
            id={id}
            name={inputName}
            value={value}
            onChange={changeFun}
          ></textarea>
        </div>
      </div>
    </>
  );
}

TextArea.propTypes = {
  value: PropTypes.string.isRequired,
  changeFun: PropTypes.func,
  id: PropTypes.string,
  inputName: PropTypes.string,
};
