import React from 'react';
import { useLang } from '../../context/LanguageContext';
import { getMarkerType } from '../../lib/mapHelper';
import { ISODateToDateTime, add_hyphen } from '../../lib/common_func';
import { code, event } from '../../lib/eventData';
import { getBattState, getGPSState } from '../../lib/map_common';

export default function Detailbody({ data, selectData, isPathMode }) {
    const { language } = useLang();

    return (
        <div className="detail-body">
            <div className="detail-summary">
                <div className="left">
                    <div className={"marker " + (data ? (isPathMode && code.indexOf(data.event_code) === -1)  ? 'event' : getMarkerType(data.iconnum) : '')}>
                        <div className="icon">
                            <i className={data && (isPathMode && code.indexOf(data.event_code) === -1)  ? 'ri-alert-fill' : ''}></i>
                        </div>
                    </div>
                    <div
                        className={
                            selectData?.unit_nm.length > 20
                                ? "name-s"
                                : "name" || "name"
                        }
                    >
                        {selectData?.unit_nm || ""}
                    </div>
                </div>
                <div className="right">
                    <div
                        className={`${
                            getBattState(data?.inner_battery)
                        }`}
                    >
                        {((data?.inner_battery ?? 0) / 10).toFixed(1)}v
                    </div>
                    {selectData?.wire === "Y" && (
                        <div className="battery car">
                            {((data?.vehicle_battery ?? 0) / 10).toFixed(1)}v
                        </div>
                    )}
                </div>
            </div>
            <div className="detail-content">
                <div className="row">
                    <div className="detail-item">
                        <div className="item-label">
                            {language === "KOR"
                                ? "단말기번호"
                                : "Device Num"}
                        </div>
                        <div className="item-content">
                            {selectData && add_hyphen(selectData.unitid)}
                        </div>
                    </div>
                    <div className="detail-item">
                        <div className="item-label">
                            {language === "KOR"
                                ? "속도"
                                : "Speed"}
                        </div>
                        <div className="item-content">
                            {`${parseInt((data?.speed ?? 0))} km/h`}
                        </div>
                    </div>
                    <div className="detail-item">
                        <div className="item-label">
                            {language === "KOR"
                                ? "GPS개수"
                                : "GPS Rec."}
                        </div>
                        <div className="item-content">
                            <i
                                className={`${
                                    getGPSState(data?.gps_sv)
                                }`}
                            >
                                {`${(data?.gps_sv ?? 0)}${language === "KOR" ? "개" : ""}`}
                            </i>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="detail-item">
                        <div className="item-label">
                            {language === "KOR"
                                ? "주소"
                                : "Address"}
                        </div>
                        <div className="item-content fixed">
                            {data?.addr ?? ""}
                        </div>
                    </div>
                    {isPathMode &&
                        (<div className="detail-item">
                            <div className="item-label">
                                {language === "KOR"
                                    ? "수신일시"
                                    : "Received"}
                            </div>
                            <div className="item-content fixed">
                                {ISODateToDateTime(data.makedate)}
                            </div>
                        </div>)
                    }
                    {isPathMode && data && (code.indexOf(data.event_code) === -1) &&
                        (<div className="detail-item">
                            <div className="item-label">
                                {language === "KOR"
                                    ? "이벤트"
                                    : "Event"}
                            </div>
                            <div className="item-content fixed">
                                {event(data.event_code, language)}
                            </div>
                        </div>)
                    }
                    {selectData?.bt_yn === 'Y' && 
                        (<div className="detail-item">
                            <div className="item-label">
                                Bluetooth
                            </div>
                            <div className="item-content fixed">
                                {data?.bt_nm || (language === "KOR"
                                                    ? "블루투스 사용자 없음"
                                                    : "No Access")}
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div>
    );
}
