import React, { useState, useEffect } from "react";
//@ts-ignore
import { LanguageContext } from "../../../../../context/LanguageContext.jsx";
//@ts-ignore
import { dataList } from "../../../AdmMain";
import Page from "../../../../Atomic/template/PageTable";
import CommonControl from "../../../../Atomic/organism/CommonControl";
import SubNavi from "../../../../Atomic/molecules/SubNavi";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import axios from "../../../../../lib/axios.js";
//@ts-ignore
import { useAuth } from "../../../../../context/LoginContext.jsx";
//@ts-ignore
import TableAtom from "../../../../Atomic/organism/AtomTable";
import { filterData, inputChnage } from "../../../../Atomic/exFunc";
import Form from "../../../../Atomic/organism/FormAtom";
//@ts-ignore
import { subDataList } from "../../../../Admin/AdmMain";
export default function Table() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const navi = useNavigate();
  const [rawData, setRawData] = useState<any>([]);
  const { user } = useAuth();
  const inputRef = React.createRef<HTMLInputElement>();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState<any>([]);
  // Function defines
  //===============================================================

  function move(e: any) {
    if (e.targetType === "columnHeader") {
      return;
    }
    const i = e.instance.store.focus.rowIndex;
    if (i === undefined) {
      return;
    }
    const idx = e.instance.store.data.rawData[i].idx;
    if (e.columnName !== "sms_cnt") {
      navi(`/Adm/o-table/edit`, {
        state: {
          idx: idx,
        },
      });
    }
  }
  function submitFun(e: any) {
    e.preventDefault();
    filterData(rawData, keyword, setData);
  }
  // Data define
  //===============================================================
  const columns: any = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "구분" : "Division",
      name: "btoc_yn",
      width: 70,
      align: "center",
      formatter: (name: any) => {
        if (name.row.btoc_yn === "Y") {
          return language === "KOR" ? "개인" : "Individual";
        } else if (name.row.btoc_yn === "N") {
          return language === "KOR" ? "기업" : "Company";
        }
      },
    },
    {
      header: language === "KOR" ? "등급" : "Grade",
      name: "org_type",
      width: 70,
      align: "center",
      formatter: (name: any) => {
        if (name.row.org_type === "C") {
          return language === "KOR" ? "회사" : "CO";
        } else if (name.row.org_type === "B") {
          return language === "KOR" ? "지점" : "BRC";
        } else if (name.row.org_type === "A") {
          return language === "KOR" ? "대리점" : "AGT";
        }
      },
    },
    {
      header: language === "KOR" ? "회사명" : "Company name",
      name: "cmp_nm",
      align: "center",
      width: 170,
    },
    {
      header: language === "KOR" ? "회사코드" : "Company code",
      name: "",
      align: "center",
      width: 90,
      formatter: (name: any) => name.row.org_cd,
    },
    {
      header: language === "KOR" ? "지점명" : "Branch name",
      name: "brc_nm",
      width: 90,
      align: "center",
    },
    {
      header: language === "KOR" ? "대리점명" : "Agency name",
      name: "agt_nm",
      width: 90,
      align: "center",
    },
    {
      header: language === "KOR" ? "관리자명" : " Manager",
      name: "nm",
      align: "center",
    },
    {
      header: language === "KOR" ? "핸드폰" : "Phone number",
      name: "hp",
      align: "center",
      width: 125,
    },
    {
      header: language === "KOR" ? "등록일" : "Registration date",
      name: "makedate",
      align: "center",
    },
    {
      header: language === "KOR" ? "지오유" : "Geoyou",
      name: "sso_yn",
      align: "center",
      width: 70,
      formatter: (value: any) => (value.row.sso_yn === "Y" ? "Y" : "N"),
    },
  ];
  const buttonData = [
    {
      labelKR: "전체목록",
      labelEN: "View All",
      classN: "white",
      clickFun: () => {
        setKeyword("");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "등록",
      labelEN: "Add new",
      classN: "purple",
      icon: true,
      clickFun: () => {
        navi("/Adm/o-table/new");
      },
      position: "right",
    },
  ];
  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e: any) => inputChnage(e, rawData, setData, setKeyword),
    myref: inputRef,
    option: undefined,
    value: keyword,
  };
  // useEffect define
  //===============================================================
  useEffect(() => {
    axios.get(`/web/admin/org/list?type=${user.type}`).then((res: any) => {
      setRawData(res.data);
    });
  }, [user]);
  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
      filterData(rawData, keyword, setData);
    }
  }, [rawData, keyword]);
  // logs define
  //===============================================================
  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${
          language === "KOR" ? "조직 관리" : "Organization management"
        }`}
        menu="/Adm/o-table"
      >
        <SubNavi
          subDataList={subDataList}
          language={language}
          navi={navi}
          classPath="/Adm/o-table"
        />
        <Form formData={formData} inputRef={inputRef} />
        <TableAtom data={data} columns={columns} move={move} />
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
