import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
interface Props {
  value: string;
  id: string;
  name: string;
  changeFun: (e: any) => void;
  readOnly?: boolean;
  place?: string;
  max: number;
  req: number;
}

export default function Tel_Input({
  readOnly,
  value,
  id,
  name,
  changeFun,
  place,
  max,
  req,
}: Props) {
  const [error, setError] = React.useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const inputValue = inputRef.current?.value || "";
    const isValid =
      inputValue === "" || (inputValue && inputValue.length >= req);
    setError(!isValid);
  }, [req, inputRef.current?.value]);

  const style = {
    borderColor: error ? "red" : "",
    color: error ? "red" : "black",
  };
  return (
    <div>
      <div className="custom-input" style={style}>
        <input
          style={style}
          readOnly={readOnly}
          type="text"
          id={id}
          name={name}
          value={value === undefined ? "" : value}
          onChange={changeFun}
          maxLength={max}
          pattern="[0-9]*"
          inputMode="numeric"
          placeholder={place}
          ref={inputRef}
        />
      </div>
    </div>
  );
}
