import Grid from "@toast-ui/react-grid";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TuiGrid from "tui-grid";
import moment from "moment";
import "../../../assets/lib/tui/tui-grid.css";
import {
  add_hyphen,
  distance,
  deviceType,
  trimType,
} from "../../../lib/common_func";
import tableStyle from "../../run/organism/RunStyledTable";

export default function DvcTable({ data, language }) {
  // context state
  // variable
  const header = {
    height: 50,
  };
  const [columns, setColumns] = useState([
    {
      header: language === "KOR" ? "단말기명" : "Device name",
      name: "unit_nm",
      sortable: true,
      resizable: true,
      width: 200,
    },
    {
      header: language === "KOR" ? "제품명" : "Product",
      name: "prd_nm",
      sortable: true,
      align: "center",
      formatter: (item) => deviceType(item.value, language),
    },
    {
      header: language === "KOR" ? "단말번호" : "USIM num",
      name: "unitid",
      sortable: true,
      align: "center",
      formatter: (item) => add_hyphen(item.value, language),
    },
    {
      header: language === "KOR" ? "지점명" : "Branch",
      name: "brc_nm",
      align: "center",
    },
    {
      header: language === "KOR" ? "대리점명" : "Agent",
      name: "agt_nm",
      align: "center",
    },
    {
      header: language === "KOR" ? "단말정보 등록일" : "Create",
      name: "makedate",
      sortable: true,
      align: "center",
      formatter: (item) => moment.utc(item.value).format("YYYY-MM-DD"),
    },
    {
      header: language === "KOR" ? "누적 주행거리(km)" : "Total(km)",
      name: "total_dist",
      sortable: true,
      align: "right",
      formatter: (value) => distance(value),
    },
    {
      header: language === "KOR" ? "수정" : "Edit",
      name: "btn",
      align: "center",
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "수정" : "Edit"
        }</div>`,
    },
  ]);

  TuiGrid.applyTheme(null, tableStyle);
  // Hook
  const navi = useNavigate();
  const gridRef = useRef();

  // function
  const move = (e) => {
    const i = e.instance.store.focus.rowIndex;
    if (i !== null) {
      const unitid = trimType(e.instance.store.data.rawData[i].unitid);
      if (e.columnName === "btn" && e.targetType != "columnHeader") {
        navi(`/Trm/Edit`, {
          state: {
            unitid: unitid,
          },
        });
      }
    }
  };
  const bodyHeight = data.length < 10 ? data.length * 50 : 520;

  useEffect(() => {
    setColumns([
      {
        header: language === "KOR" ? "단말기명" : "Device name",
        name: "unit_nm",
        sortable: true,
        resizable: true,
        width: 200,
      },
      {
        header: language === "KOR" ? "제품명" : "Product",
        name: "prd_nm",
        sortable: true,
        align: "center",
        formatter: (item) => deviceType(item.value, language),
      },
      {
        header: language === "KOR" ? "단말번호" : "USIM num",
        name: "unitid",
        sortable: true,
        align: "center",
        formatter: (item) => add_hyphen(item.value, language),
      },
      {
        header: language === "KOR" ? "지점명" : "Branch",
        name: "brc_nm",
        align: "center",
      },
      {
        header: language === "KOR" ? "대리점명" : "Agent",
        name: "agt_nm",
        align: "center",
      },
      {
        header: language === "KOR" ? "단말정보 등록일" : "Create",
        name: "makedate",
        sortable: true,
        align: "center",
        formatter: (item) => moment.utc(item.value).format("YYYY-MM-DD"),
      },
      {
        header: language === "KOR" ? "누적 주행거리(km)" : "Total(km)",
        name: "total_dist",
        sortable: true,
        align: "right",
        formatter: (value) => distance(value),
      },
      {
        header: language === "KOR" ? "수정" : "Edit",
        name: "btn",
        align: "center",
        formatter: () =>
          `<div class="link cursor">${
            language === "KOR" ? "수정" : "Edit"
          }</div>`,
      },
    ])
  }, [language])
  return (
    <div className="common-table-wrapper">
      <div className="common-grid">
        <Grid
          ref={gridRef}
          data={data}
          columns={columns}
          rowHeight={50}
          rowHeader={[{ type: "rowNum" }]}
          scrollX={false}
          scrollY={true}
          bodyHeight={bodyHeight}
          header={header}
          onClick={move}
        />
      </div>
    </div>
  );
}
