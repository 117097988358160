import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "../../lib/axios";
export default function BlueToothForm({ data, language }) {
  const navi = useNavigate();
  // State definition
  // ===========================================================
  const [editData, setEditData] = useState(data);
  // State definition
  // ===========================================================
  useEffect(() => {}, []);
  // Function definition
  // ===========================================================
  function submitHandler(e) {
    e.preventDefault();
    if (editData.nm === "" || editData.nm === null) {
      Swal.fire({
        title:
          language === "KOR"
            ? "사용자명을 입력해주세요."
            : "Please enter the agent.",
        icon: "warning",
        confirmButtonText: language === "KOR" ? "확인" : "Confirm",
        width: "400px",
        padding: "20px",
        customClass: {
          confirmButton: "submit-btn",
        },
      });
      return;
    }
    if (editData.bt_id === "" || editData.bt_id === null) {
      Swal.fire({
        title:
          language === "KOR"
            ? "블루투스 ID를 입력해주세요."
            : "Please enter the Bluetooth ID.",
        icon: "warning",
        confirmButtonText: language === "KOR" ? "확인" : "Confirm",
        width: "400px",
        padding: "20px",
        customClass: {
          confirmButton: "submit-btn",
        },
      });
      return;
    }
    if (editData.department === "" || editData.department === null) {
      Swal.fire({
        title:
          language === "KOR"
            ? "부서를 입력해주세요."
            : "Please enter the department.",
        icon: "warning",
        confirmButtonText: language === "KOR" ? "확인" : "Confirm",
        width: "400px",
        padding: "20px",
        customClass: {
          confirmButton: "submit-btn",
        },
      });
      return;
    }
    if (editData.org_nm === "" || editData.org_nm === null) {
      Swal.fire({
        title:
          language === "KOR"
            ? "소속을 입력해주세요."
            : "Please enter the affiliation.",
        icon: "warning",
        confirmButtonText: language === "KOR" ? "확인" : "Confirm",
        width: "400px",
        padding: "20px",
        customClass: {
          confirmButton: "submit-btn",
        },
      });
      return;
    }
    Swal.fire({
      title:
        language === "KOR"
          ? "수정하시겠습니까?"
          : "Are you sure you want to edit it?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: language === "KOR" ? "확인" : "Confirm",
      cancelButtonText: language === "KOR" ? "취소" : "Cancel",
      width: "400px",
      padding: "20px",
      customClass: {
        // confirmButton: "submit-btn",
        cancelButton: "cancel-btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // 수정 API
        // ===========================================================
        // console.log("수정 API", editData);
        sendData();
        Swal.fire({
          title:
            language === "KOR" ? "수정되었습니다." : "It has been modified.",
          icon: "success",
          confirmButtonText: language === "KOR" ? "확인" : "Confirm",
          width: "400px",
          padding: "20px",
          customClass: {
            confirmButton: "submit-btn",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.href = "/Terminal/BlueTooth";
          }
        });
      }
    });
  }
  function changeHandler(e) {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  }
  async function sendData() {
    axios.post(`/web/bluetooth/edit`, editData).then((res) => {
      console.log(res.status);
      if (res.status === 200) {
        Swal.fire({
          title: language === "KOR" ? "수정되었습니다." : "Saved",
          confirmButtonText: language === "KOR" ? "확인" : "OK",
          width: "400px",
          padding: "20px",
          heightAuto: false,
          icon: "success",
          customClass: {
            confirmButton: "submit-btn",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            navi("/Trm/BlueTooth");
          }
        });
      } else {
        Swal.fire({
          title: language === "KOR" ? "수정에 실패하였습니다." : "Failed",
          confirmButtonText: language === "KOR" ? "확인" : "OK",
          width: "400px",
          padding: "20px",
          heightAuto: false,
          icon: "error",
          customClass: {
            confirmButton: "submit-btn",
          },
        });
      }
    });
  }
  // Logs definition
  // ===========================================================
  // console.log("BlueToothForm data", data);
  // console.log("BlueToothForm EditData", editData);
  return (
    <>
      <table className="common-view-table">
        <colgroup>
          <col style={{ width: "19rem" }} />
          <col />
          <col style={{ width: "19rem" }} />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>{language === "KOR" ? "블루투스 ID	" : "Bluetooth ID"}</th>
            <td>
              <div className="custom-input">
                <input
                  type="text"
                  name="bt_id"
                  id="bt_id"
                  placeholder={
                    language === "KOR" ? "블루투스 ID" : "Bluetooth ID"
                  }
                  defaultValue={data.bt_id}
                  onChange={(e) => changeHandler(e)}
                  readOnly
                />
              </div>
            </td>
            <th>{language === "KOR" ? "소속" : "Affiliation"}</th>
            <td>
              <div className="custom-input">
                <input
                  type="text"
                  name="org_nm"
                  id="org_nm"
                  placeholder={language === "KOR" ? "소속" : "Affiliation"}
                  defaultValue={data.org_nm}
                  onChange={(e) => changeHandler(e)}
                  readOnly
                />
              </div>
            </td>
          </tr>
          <tr>
            <th className="required">{language === "KOR" ? "사용자명" : "Agent"}</th>
            <td>
              <div className="custom-input">
                <input
                  type="text"
                  name="nm"
                  id="nm"
                  placeholder={language === "KOR" ? "사용자명" : "Agent"}
                  defaultValue={data.nm}
                  onChange={(e) => changeHandler(e)}
                />
              </div>
            </td>
            <th>{language === "KOR" ? "등록일" : "Made date"}</th>
            <td>
              <div className="custom-input">
                <input
                  type="text"
                  name="makedate"
                  id="makedate"
                  placeholder={language === "KOR" ? "등록일" : "Made date"}
                  defaultValue={data.makedate}
                  readOnly
                />
              </div>
            </td>
          </tr>
          <tr>
            <th className="required">{language === "KOR" ? "부서" : "Department"}</th>
            <td>
              <div className="custom-input">
                <input
                  type="text"
                  name="department"
                  id="department"
                  placeholder={language === "KOR" ? "부서" : "Department"}
                  defaultValue={data.department}
                  onChange={(e) => changeHandler(e)}
                />
              </div>
            </td>
            <th>
              {language === "KOR" ? "최종접속일" : "Last connection date"}
            </th>
            <td>
              <div className="custom-input">
                <input
                  type="text"
                  name="lastdate"
                  id="lastdate"
                  placeholder={
                    language === "KOR" ? "최종접속일" : "Last connection date"
                  }
                  defaultValue={data.lastdate_bt}
                  readOnly
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="common-control">
        <div className="left">
          <Link to={"/Trm/BlueTooth"} className="common-btn white">
            <span>{language === "KOR" ? "목록" : "List"}</span>
          </Link>
        </div>
        <div className="right">
          <button className="common-btn purple" onClick={submitHandler}>
            <span>{language === "KOR" ? "수정" : "Save"}</span>
          </button>
        </div>
      </div>
    </>
  );
}
