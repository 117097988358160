import React, { useEffect, useState } from "react";

interface Props {
  pages: any[];
  currentPage: number;
  paginate: (pageNumber: number) => void;
  firstPageSet: () => void;
  lastPageSet: () => void;
  next: (pageNumber: number) => void;
  setCurrentPage: (currentPage: number) => void;
}

export default function Pagination({
  setCurrentPage,
  pages,
  currentPage,
  paginate,
}: Props) {
  const pageNumbers = [];
  const maxPagesToShow = 10; // Number of pages to show in the pagination

  // Calculate the start page based on the current page and the maximum pages to show
  let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
  let endPage = startPage + maxPagesToShow - 1;

  // Ensure the end page doesn't exceed the total pages
  if (endPage > pages.length) {
    endPage = pages.length;
    startPage = Math.max(1, endPage - maxPagesToShow + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  function firstPageSet() {
    // Update the `pages` state variable to the first page
    setCurrentPage(1);
  }

  function lastPageSet() {
    // Update the `pages` state variable to the last page
    setCurrentPage(pages.length);
  }

  function next(currentPage: number) {
    // Update the `pages` state variable to the next page
    setCurrentPage(currentPage);
  }

  return (
    <>
      <div className="common-pager">
        <ul>
          <li>
            <button type="button" className="first" onClick={firstPageSet}>
              <span className="a11y">처음</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              className="prev"
              onClick={() => next(currentPage === 1 ? 1 : currentPage - 1)}
            >
              <span className="a11y">이전</span>
            </button>
          </li>

          {pageNumbers.map((number) => (
            <li key={number}>
              <button
                type="button"
                onClick={() => paginate(number)}
                className={currentPage === number ? "on" : ""}
              >
                {number}
              </button>
            </li>
          ))}

          <li>
            <button
              type="button"
              className="next"
              onClick={() =>
                next(
                  currentPage === pages.length ? pages.length : currentPage + 1
                )
              }
            >
              <span className="a11y">다음</span>
            </button>
          </li>
          <li>
            <button
              type="button"
              onClick={() => {
                lastPageSet();
              }}
              className="last"
            >
              <span className="a11y">마지막</span>
            </button>
          </li>
        </ul>
      </div>
    </>
  );
}
