import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { dataList } from "../pages/VLogMain";
import { LanguageContext } from "../../../context/LanguageContext";
import { useAuth } from "../../../context/LoginContext";
import axios from "../../../lib/axios";
import moment from "moment";
import "./style/style.sass";
import HeaderCalendar from "./components/organism/HeaderCalendar";
import CalendarBox from "./components/organism/CalendarBox";
import EventListBox from "./components/organism/EventListBox";
import Page from "../../Atomic/template/PageTable";
import EditEvent from "./components/page/EditEvent";
import AddEvemt from "./components/page/AddEvent";
export const App = ({ data, isLoading }) => {
  const { user } = useAuth();
  const { language } = React.useContext(LanguageContext);
  const navigate = useNavigate();
  // Data Defenition
  // =====================================================================================

  const [rawData, setRawData] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [todayDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedDate, setSelectedDate] = useState(todayDate);
  const [date, setDate] = useState(moment());
  const [idx, setIdx] = useState();
  const [isOn, setIsOn] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  // Function Defenition
  // =====================================================================================

  const nextMonth = () => {
    setDate(date.clone().add(1, "month"));
  };
  const prevMonth = () => {
    setDate(date.clone().subtract(1, "month"));
  };
  const todayButton = () => {
    const currentDate = moment();
    setDate(currentDate);
  };

  // UseEffect Defenition
  // =====================================================================================
  useEffect(() => {
    axios
      .get(
        `/web/schedule/list?type=${user.type}&org_idx=${user.org_idx}`
      )
      .then((res) => {
        setRawData(res.data.list);
      });
  }, [user]);
  useEffect(() => {
    if (rawData) {
      setEventList(rawData);
    }
  }, [rawData]);

  if (data === undefined && isLoading === true) {
    return (
      <div className="load-more">
        <img src={"LoadingIcon"} alt="loading" />
      </div>
    );
  }
  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "차량 예약" : "Vehicle Reservation"}`}
        menu="/VLog/Cal"
      >
        <div id="app-cal">
          <div className="app-container">
            <div className="app-safearea">
              <div className="list-page-wrapper view-mode">
                <div className="page-header">
                  <HeaderCalendar
                    year={date.format("YYYY")}
                    month={date.format("MM")}
                    titleText={`${date.format("YY년 MM월")}`}
                    onClickFun={{
                      next: nextMonth,
                      prev: prevMonth,
                      today: todayButton,
                      setDate,
                    }}
                    lang={language}
                  />
                  <EventListBox
                    selectedDate={selectedDate}
                    eventList={eventList}
                    todayDate={todayDate}
                    navigate={navigate}
                    lang={language}
                    setIdx={setIdx}
                    setIsOn={setIsOn}
                    setIsAdd={setIsAdd}
                  />
                </div>
                <div className="page-body">
                  <div className="app-inner">
                    <div>
                      <CalendarBox
                        eventList={eventList}
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        date={date}
                        lang={language}
                        user={user}
                        data={rawData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
      {isOn && <EditEvent idx={idx} rawData={rawData} setIsOn={setIsOn} />}
      {isAdd && <AddEvemt setIsAdd={setIsAdd} />}
    </>
  );
};

export default App;
