import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import EventItem from "../../components/map/EventItem";
import { LanguageContext } from "../../context/LanguageContext";
import { useAuth } from "../../context/LoginContext";
import { useMap } from "../../context/MapContext";
import useInfinite from "../../hook/useInfinite";

export default function RightEventPanel() {
  const { language } = useContext(LanguageContext);
  const { mapState, mapDispatch } = useMap();
  const { user } = useAuth();
  const { totalEventMode, selectEventItem } = mapState;
  const style = { center: { textAlign: "center" } };

  const fromRef = useRef();
  const toRef = useRef();
  const typeRef = useRef();
  const keywordRef = useRef();
  const clickRef = useRef();

  const [hours, setHours] = useState([]);
  const [searchOption, setSearchOption] = useState({
    date: moment().format("YYYY-MM-DD"),
    from: 0,
    to: 23,
    type: "unit_nm",
    keyword: "",
    isSearch: false,
  });

  const url = hours.length > 0 ? `/web/event/list` : null;
  const { data, isLoading, ref, totalCount, search } = useInfinite(
    url,
    user.render_cnt ?? 20,
    user.org_idx,
    null,
    searchOption.from,
    searchOption.to,
    searchOption.date,
    searchOption.isSearch,
    user.type,
    searchOption.type,
    searchOption.keyword 
  );

  // 이벤트 패널 닫기
  const handleClose = () => {
    mapDispatch({ type: "SET_TOTAL_EVENT_MODE", payload: false });
  };

  // 검색 핸들러
  const submitHandler = (e) => {
    e.preventDefault();
    setSearchOption((prev) => ({
      ...prev,
      from: fromRef.current.value,
      to: toRef.current.value,
      isSearch: !searchOption.isSearch,
      type: typeRef.current.value,
      keyword: keywordRef.current.value,
    }));
  };

  useEffect(() => {
    const hours = [];
    for (let i = 0; i < 24; i++) {
      hours.push(i);
    }
    setHours(hours);
  }, []);

  useEffect(() => {
    if (selectEventItem) {
      clickRef?.current?.scrollIntoView({ block: "center" });
    }
  }, [selectEventItem]);

  return (
    <>
      {/*<!--s: 오른쪽 이벤트 판넬-->*/}
      <div
        className={`right-info-panel event-info-panel ${
          totalEventMode ? "on" : ""
        }`}
      >
        <div className="detail-inner">
          <div className="detail-top">
            <div className="detail-header">
              <div className="header-title">
                <i className="ri-information-fill icon"></i>
                {language === "KOR" ? "상세정보" : "Detail Info."}
              </div>
              <div className="header-control">
                <button
                  type="button"
                  className="close"
                  onClick={handleClose}
                ></button>
              </div>
            </div>
            <div className="detail-body">
              <form onSubmit={submitHandler}>
                <div className="search-module">
                  <div className="row">
                    <div className="custom-select">
                      <select id="from" ref={fromRef}>
                        {hours.map((hour) => {
                          return (
                            <option
                              value={hour}
                              selected={searchOption.from == hour}
                            >
                              {language === "KOR" ? `${hour}시` : `${hour}hr`}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="custom-select">
                      <select id="to" ref={toRef}>
                        {hours.map((hour) => {
                          return (
                            <option
                              value={hour}
                              selected={searchOption.to == hour}
                            >
                              {language === "KOR" ? `${hour}시` : `${hour}hr`}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="custom-select">
                      <select id="type" ref={typeRef}>
                        <option
                          value={"unit_nm"}
                          selected={searchOption.type === "unit_nm"}
                        >
                          {language === "KOR" ? "단말기명" : "Device Name"}
                        </option>
                        {/* <option value={'unitid'} selected={searchOption.type === 'unitid'}>{(language === 'KOR' ? '단말번호' : 'Device Num' )}</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="custom-input">
                      <input
                        id="keyword"
                        ref={keywordRef}
                        type="text"
                        placeholder={
                          language === "KOR"
                            ? "검색어를 입력하세요"
                            : "Enter a search keyword"
                        }
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <button type="submit" className="submit-btn">
                    {language === "KOR" ? "검색" : "Search"}
                  </button>
                </div>
              </form>
              <div className="track-list">
                <ul className="list-body">
                  {data?.map((item) => (
                    <EventItem item={item} clickRef={clickRef} />
                  ))}
                  {isLoading && <div>loading</div>}
                  {!isLoading && (
                    <div ref={ref} style={style.center}>
                      {data?.length === 0
                        ? language === "KOR"
                          ? "데이터가 없습니다."
                          : "No Data."
                        : ""}
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!--e: 오른쪽 이벤트 판넬--> */}
    </>
  );
}
