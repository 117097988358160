import React from "react";
interface SubMenuProps {
  type: string;
  language: string;
  clickFun: (e: string) => void;
  titleKR: string;
  titleNG: string;
  subMenu: subMenuProps[];
}
interface subMenuProps {
  titleKR: string;
  titleEN: string;
  type: string;
}
export default function SubMenu({
  type,
  language,
  clickFun,
  titleKR,
  titleNG,
  subMenu,
}: SubMenuProps) {
  return (
    <>
      <div className={`util-btn more cursor`}>
        {language === "KOR" ? `${titleKR}` : `${titleNG}`}
      </div>
      <div className="sub-menu">
        {subMenu.map((item, index) => {
          return (
            <div key={index} className="sub-menu-item cursor">
              <div
                className="menu-btn cursor"
                onClick={() => clickFun(item.type)}
              >
                <div>
                  {language === "KOR" ? `${item.titleKR}` : `${item.titleEN}`}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
