import Grid from "@toast-ui/react-grid";
import React, { useEffect, useRef, useState } from "react";
import TuiGrid from "tui-grid";
import "../../../assets/lib/tui/tui-grid.css";
import { LanguageContext } from "../../../context/LanguageContext";
import tableStyle from "../../run/organism/RunStyledTable";

export default function DvcTable({ data, columns, move }) {
  // context state
  // variable
  const { language } = React.useContext(LanguageContext);
  const header = {
    height: 50,
  };

  TuiGrid.applyTheme(null, tableStyle);
  // Hook
  const gridRef = useRef();
  // function
  const bodyHeight = data.length < 10 ? data.length * 50 : 520;
  const [localColumns, setLocalColumns] = useState(columns);

  useEffect(() => {
    setLocalColumns(columns);
  }, [language])

  return (
    <div className="common-table-wrapper">
      <div className="common-grid">
        {data === "" || data === undefined ? (
          <div>
            {" "}
            <table className="common-table">
              <colgroup>
                <col style={{ width: "120px" }} />
                <col />
                <col />
                <col />
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>{language === "KOR" ? "번호" : "No"}</th>
                  <th>{language === "KOR" ? "단말기명" : "Device name"}</th>
                  <th>{language === "KOR" ? "단말번호" : "USIM num"}</th>
                  <th>{language === "KOR" ? "시간" : "Time"}</th>
                  <th>{language === "KOR" ? "이벤트명" : "Event name"}</th>
                  <th>
                    {language === "KOR" ? "이벤트 분류코드" : "Event code"}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="6">
                    <div className="empty">
                      {language === "KOR"
                        ? "검색된 데이터가 없습니다."
                        : "No data."}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <Grid
            ref={gridRef}
            data={data}
            columns={localColumns}
            rowHeight={50}
            rowHeader={[{ type: "rowNum" }]}
            scrollX={false}
            scrollY={true}
            bodyHeight={bodyHeight}
            header={header}
            onClick={move}
          />
        )}
      </div>
    </div>
  );
}
