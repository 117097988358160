import React, { useEffect } from 'react';
import { useAuth } from '../../context/LoginContext';
import { useNavigate } from 'react-router-dom';

export default function Demo() {
    const { user, handleDemoLogin } = useAuth();
    const navi = useNavigate();

    useEffect(() => {
        handleDemoLogin();
    },[])

    useEffect(() => {
        if (user) {
          if(user.auth.J000 === 'Y') {
            navi("/Adm/u-table");
          } else if(user.auth.I000 === 'Y') {
            navi("/Order");
          } else {
            navi("/Map");
          }
        }
      }, [user]);

    return (
        <>
            
        </>
    );
}

