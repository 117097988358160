import React from "react";
interface Props {
  language: string;
  labelKR: string;
  labelEN: string;
  classN: string;
  icon: boolean;
  iconClass: string;
  clickFun: () => void;
  style: any;
}
export default function Button({
  language,
  clickFun,
  labelKR,
  labelEN,
  classN,
  icon,
  iconClass,
  style,
}: Props) {
  return (
    <>
      <button
        onClick={clickFun}
        className={`common-btn ${classN} `}
        style={style}
      >
        {icon && <i className={`${iconClass} icon`}></i>}
        <span>{language === "KOR" ? labelKR : labelEN}</span>
      </button>
    </>
  );
}
