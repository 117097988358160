import React from "react";

interface GeoMarkerProps {
  name: string;
}
export default function GeoMarkerAtom({ name }: GeoMarkerProps) {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const styleTitle = {
    bottom: `${isSafari ? "-70px" : "-50px"}`,
    left: `${isSafari ? "100%" : "50%"}`,
  };
  return (
    <>
      <div className="marker geo">
        <div className="marker-icon">
          <span className="icon">
            <i className="ri-community-fill"></i>
          </span>
        </div>
        <div style={styleTitle} className="marker-name">
          {name}
        </div>
      </div>
    </>
  );
}
