import React from "react";
import { useLang } from "../../../context/LanguageContext";
import { ISODateToDateTime, add_hyphen } from "../../../lib/common_func";

export default function DrivingDayTableExcel({ data, sort, tableRef, column, date, unitid, unit_nm, totalDist }) {
  const { language } = useLang();

  const style = {
    primary:{backgroundColor:'#DCE8F5'},
    color:{blue:{color:'blue'}},
    sort:{left:{textAlign:'left'},center:{textAlign:'center'},right:{textAlign:'right'}},
    row:{height:'30px',textAlign:'center'},
    border:{border:'1px solid #413c3c'}
  }
  let activeCount = 0;
  Object.values(column).map((item) => {
    if(item.active && (item.key !== 'key_on' && item.key !== 'key_off')) {
      activeCount++;
    }
  })

  return (
    <>
      <div
        className="common-table"
      >
        <table className="common-table excel-table" ref={tableRef}>
          <colgroup></colgroup>
          <thead>
            <tr>
              <th colSpan={activeCount} style={{...style.border,...style.sort.center}}>
                <h2>
                  <span>
                    {`${date && date.s} ~ ${date && date.e} ${unit_nm} : `}
                  </span>
                  <span style={style.color.blue}>
                    {unitid && add_hyphen(unitid)}
                  </span>
                  <span>
                    {`  운행기록`}
                  </span>
                </h2>
              </th>
            </tr>
            <tr>
              {Object.values(column).map((item) => (item.active && (item.key !== 'key_on' && item.key !== 'key_off')) && (
                <th style={{...style.primary,...style.row, ...style.border}}>
                  <label className="custom-checkbox">
                    <div className="label">
                      {language === "KOR" ? item.ko : item.eng}
                    </div>
                  </label>
                </th>
              ))}              
            </tr>
          </thead>
          <tbody>
            {(!data || data.length === 0) ? (
                <tr>
                  <td colSpan="12">
                    <div className="empty">검색된 데이터가 없습니다.</div>
                  </td>
                </tr>
              ) : (
                data &&
                data.map((item, index) => {
                  const {
                    bt_nm,
                    bt_id,
                    total_cal_dist,
                    dr_hhmiss,
                    key_on,
                    key_on_lon,
                    key_on_lat,
                    addStart,
                    key_off,
                    key_off_lat,
                    key_off_lon,
                    addEnd,
                  } = item;
                  //  /운행거리(km)/운행시간/운전자/출발시각/출발위치/출발위도/출발경도/도착시각/도착위치/도착위도/도착경도
                  return (
                    <tr key={index} style={style.row}>
                      {column.no.active && <td style={style.border}>{sort ? data.length - index : index + 1}</td>}
                      {column.dr_dist.active && <td style={{...style.sort.right,...style.border}}>{total_cal_dist}</td>}
                      {column.dr_hhmiss.active && <td style={style.border}>{dr_hhmiss}</td>}
                      {column.u_nm.active && <td style={style.border}>{bt_nm || bt_id || ""}</td>}
                      {column.key_on_date.active && <td style={style.border}>{key_on ? ISODateToDateTime(key_on).substring(0,10) : ''}</td>}
                      {column.key_on_time.active && <td className="formatText" style={style.border}>{key_on ? ISODateToDateTime(key_on).substring(11,19) : ''}</td>}
                      {column.addStart.active && <td style={style.border}>{addStart === undefined ? "No Data" : addStart}</td>}
                      {column.key_on_lat.active && <td style={style.border}>{key_on_lat}</td>}
                      {column.key_on_lon.active && <td style={style.border}>{key_on_lon}</td>}
                      {column.key_off_date.active && <td style={style.border}>{key_off ? ISODateToDateTime(key_off).substring(0,10) : ''}</td>}
                      {column.key_off_time.active && <td className="formatText" style={style.border}>{key_off ? ISODateToDateTime(key_off).substring(11,19) : ''}</td>}
                      {column.addEnd.active && <td style={style.border}>{addEnd === undefined ? "No Data" : addEnd}</td>}
                      {column.key_off_lat.active && <td style={style.border}>{key_off_lat}</td>}
                      {column.key_off_lon.active && <td style={style.border}>{key_off_lon}</td>}
                    </tr>
                  );
                })
              )}
              <tr style={style.row}>
                <td colSpan={2} style={{...style.primary,...style.border}}>총 운행거리</td>
                <td colSpan={2} style={style.border}>{totalDist} km</td>
                <td colSpan={activeCount - 4} style={style.border}></td>
              </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
