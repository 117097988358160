//@ts-ignore
import useFetch from "../../hook/useFetch.jsx";
import React, { useEffect, useState } from "react";
import Modal from "./atoms/Modal";

interface NewsData {
  tit: string;
  eme_yn: string;
  idx: number;
}

export default function HeaderNew() {
  const { data, isLoading } = useFetch(`/web/main/notice`);
  const [isOpen, setIsOpen] = React.useState(true);
  const [current, setCurrent] = React.useState<NewsData | undefined>();
  const [newsModal, setNewsModal] = React.useState(false);
  const [modal, setModal] = useState({});
  const nextCurrent = () => {
    if (data && current) {
      const currentIndex = data.indexOf(current);
      const nextIndex = (currentIndex + 1) % data.length;
      const newIndex = nextIndex === 0 ? 0 : nextIndex;
      

      setCurrent(data[newIndex]);
    }
  };

  function closeNews(e: any) {
    setNewsModal(!newsModal);
  }
  function openNews(e: number) {
    setNewsModal(!newsModal);
    // @ts-ignore
    const selectedData = data.find((item) => item.idx === e);
    if (selectedData) {
      setModal(selectedData);
      
    } else {
      console.log("Data not found for index " + e);
    }
  }
  const prevCurrent = () => {
    if (data && current) {
      const currentIndex = data.indexOf(current);
      const nextIndex = (currentIndex - 1 + data.length) % data.length;
      const newIndex = currentIndex === 0 ? data.length - 1 : nextIndex;

      setCurrent(data[newIndex]);
    }
  };

  useEffect(() => {
    if (data && isLoading === false) {
      setCurrent(data[0]);
    }
  }, [data, isLoading]);



  return (
    <>
      {isOpen && current ? (
        <div className="news-wrap">
          <div className="news-block">
            <div className="item-block">
              <div>
                <img src="../../assets/img/login/speaker.svg" alt="" />
              </div>
              <span className="title">시스템 공지사항</span>
            </div>
            <div className="anonce-block">
              <div
                className="news"
                onClick={() => {
                  openNews(current?.idx);
                }}
              >
                <span className="news-mark">
                  {current.eme_yn === "Y" ? "[긴급]" : ""}
                </span>
                <span>{current.tit}</span>
              </div>
              <div className="btn-control-block">
                <button className="btn-control" onClick={prevCurrent}>
                  <img src="../../assets/img/login/button-left.svg" alt="" />
                </button>
                <button className="btn-control" onClick={nextCurrent}>
                  <img src="../../assets/img/login/button-right.svg" alt="" />
                </button>
              </div>
            </div>
            <button className="news-close" onClick={() => setIsOpen(!isOpen)}>
              <img src="../../assets/img/login/close.svg" alt="" />
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      <Modal data={modal} isOpen={newsModal} closeNews={closeNews} />
    </>
  );
}
