import React from 'react';
import { ISODateToDateTime, add_hyphen } from '../../../lib/common_func';

export default function VlogExcel({ table, data, searchOption, unit_nm, unitid }) {
    const renderData = [...data].reverse();

    const style = {
        row:{textAlign:'center', fontSize:'12px',height:'30px'},
        header:{fontSize:'24px', fontWeight:'bold', textAlign:'center', border:'1px solid #413c3c', height: '30px'},
        title:{border:'1px solid #413c3c', fontWeight:'bold', backgroundColor:'#DCE8F5'},
        content:{border:'1px solid #413c3c', height: '30px'},
        sectionTitle:{height:'60px', fontWeight:'bold', verticalAlign:'bottom'},
        emptyBox:{backgroundColor:'#e5e5e5', border:'1px solid #413c3c', fontWeight:'bold'},
        sort:{right:{textAlign:'right'}}
    }
  
    return (
        <>
            <table className='common-table excel-table' ref={table}>
                <thead>
                    <tr >
                        <td colSpan={18} style={{...style.header}}>
                            {`${searchOption.from.substring(0,4)}년 ${searchOption.from.substring(5,7)}월 ~ ${searchOption.to.substring(0,4)}년 ${searchOption.to.substring(5,7)}월 ${unit_nm} : ${add_hyphen(unitid)} 운행기록`}
                        </td>
                    </tr>
                    <tr style={style.row}>
                        <td style={{...style.title}}>번호</td>
                        <td style={{...style.title}}>상호명</td>
                        <td style={{...style.title}}>운전자명</td>
                        <td style={{...style.title}}>부서</td>
                        <td style={{...style.title}}>주행시작시각</td>
                        <td style={{...style.title}}>주행시작위치</td>
                        <td style={{...style.title}}>주행종료시각</td>
                        <td style={{...style.title}}>주행종료위치</td>
                        <td style={{...style.title}}>주행 전 계기판의<br/>거리(Km)</td>
                        <td style={{...style.title}}>주행 후 계기판의<br/>거리(Km)</td>
                        <td style={{...style.title}}>주행거리(Km)</td>
                        <td style={{...style.title}}>출퇴근용(km)</td>
                        <td style={{...style.title}}>일반업무용(km)</td>
                        <td style={{...style.title}}>주유여부</td>
                        <td style={{...style.title}}>주유비용(원)</td>
                        <td style={{...style.title}}>통행비용(원)</td>
                        <td style={{...style.title}}>주차비용(원)</td>
                        <td style={{...style.title}}>기타비용(원)</td>
                        <td style={{...style.title}}>비고</td>
                    </tr>
                </thead>
                <tbody>
                    {renderData.map((item, index) => {
                        const st_mileage = parseFloat(item.st_mileage);
                        const ed_mileage = parseFloat(item.ed_mileage);

                        return (
                            <tr style={style.row}>
                                <td style={style.content}>{data.length - index}</td>
                                <td style={style.content}>{item.org_nm ?? ''}</td>
                                <td style={style.content}>{item.u_nm ?? ''}</td>
                                <td style={style.content}>{item.department ?? ''}</td>
                                <td style={style.content}>{ISODateToDateTime(item.st_time)}</td>
                                <td style={style.content}>{item.st_addr ?? ''}</td>
                                <td style={style.content}>{ISODateToDateTime(item.ed_time)}</td>
                                <td style={style.content}>{item.ed_addr ?? ''}</td>
                                <td style={{...style.content,...style.sort.right}}>{st_mileage.toLocaleString() ?? 0}</td>
                                <td style={{...style.content,...style.sort.right}}>{ed_mileage.toLocaleString() ?? 0}</td>
                                <td style={{...style.content,...style.sort.right}}>{item.dist ?? 0}</td>
                                <td style={{...style.content,...style.sort.right}}>{item.d_commute ?? 0}</td>
                                <td style={{...style.content,...style.sort.right}}>{item.d_general ?? 0}</td>
                                <td style={style.content}>{item.gas_costs > 0 ? <span style={{color:'blue'}}>O</span> : <span style={{color:'red'}}>X</span>}</td>
                                <td style={{...style.content,...style.sort.right}}>{item.gas_costs ?? 0}</td>
                                <td style={{...style.content,...style.sort.right}}>{item.tolls ?? 0}</td>
                                <td style={{...style.content,...style.sort.right}}>{item.parking_costs ?? 0}</td>
                                <td style={{...style.content,...style.sort.right}}>{item.etc_costs ?? 0}</td>
                                <td style={style.content}>{item.memo ?? ''}</td>
                            </tr>
                        )
                    })}
                </tbody>
                
            </table>
        </>
    );
}

