import React from "react";

interface Props {
  id: string;
  type: string;
  placeHolder: string;
  changeFun: (e: any) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  ref: React.RefObject<HTMLInputElement>;
  value?: string;
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  ({ placeHolder, changeFun, id, type, inputRef, value }, ref) => {
    return (
      <>
        <input
          id={id}
          type={type}
          placeholder={placeHolder}
          onChange={changeFun}
          ref={ref}
          value={value}
        ></input>
      </>
    );
  }
);

export default Input;
