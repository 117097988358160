import React from "react";

import Nav from "../../Atomic/organism/Nav";
import Footer from "../../include/AppFooter";
import Header from "../../include/AppHeader";
import { useLang } from "../../../context/LanguageContext";
import { Outlet } from "react-router-dom";

export default function VLogMain({ children }) {
  const { language } = useLang();
  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/VLog"} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR"
                    ? "운행상세(국세청용)"
                    : "Driving Log For Nation Tax Service"}
                </div>
              </div>
              <div className="sub-page-body">{children}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Outlet />
    </>
  );
}

export const dataList = [
  {
    permission: "C100",
    path: "/VLog",
    labelKR: "운행상세(국세청용)",
    labelEN: "Driving Log For National Tax Service",
  },
  {
    permission: "C200",
    path: "/VLog/Cal",
    labelKR: "차량 예약",
    labelEN: "Vehicle Reservation",
  },
];
