import React from 'react';
import { useLang } from '../../../context/LanguageContext';

export default function AddModal({ start, end, closeAddModal, addLog, addRef }) {
    const { language } = useLang();
    return (
        <>
            <div id="crew-search-panel" className="common-modal" ref={addRef}>
                <div className="modal-inner">
                    <div className="modal-page sm">
                    <div className="page-header">
                        <div className="page-title">
                        <span className='red'>{language === "KOR" ? "* 합산 후 데이터 복구가 불가능합니다." : "* Once Added, it cannot be restored"}</span>
                        </div>
                        <button onClick={closeAddModal} type="button" className="modal-close">
                            <i className="ri-close-fill icon"></i>
                        </button>
                    </div>
                    <div className="page-body short2">
                        <form onSubmit={addLog}>
                            <div className='m_form'>
                                <div>
                                    <span>시작 번호</span>
                                    <input type="text" ref={start}/>
                                </div>
                                <div className='space-2'>
                                    <span>마지막 번호</span>
                                    <input type="text" ref={end}/>
                                </div>
                            </div>
                            <button type='submit'>{language === 'KOR' ? '합산' : 'Add'}</button>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}

