import React from "react";
import { useState } from "react";
interface Props {
  title: string;
  place: string;
  button: string;
  deviceData: [];
  setRawData: (e: any) => void;
  inName: string;
  filter: string;
  rawData: any;
}
export default function FitlerForm({
  title,
  place,
  button,
  deviceData,
  setRawData,
  inName,
  filter,
  rawData,
}: Props) {
  // state Define
  // ==========================================================================

  const [CPN, setCPN] = useState<string>();
  const [CPNData, setCPNData] = useState<any>([]);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  // function define
  //===========================================================================

  function onChangeFilter(e: any) {
    setCPN(e.target.value);
    handleFilterChange(e);
  }

  function handleFilterChange(e: any) {
    const searchText = e.target.value;

    if (searchText === "") {
      setCPNData([]);
      return;
    }
    const filteredData = deviceData.filter((item: any) => {
      return item[filter].toLowerCase().includes(searchText.toLowerCase());
    });

    setCPNData(filteredData);
  }

  function editCPN(e: any) {
    if (!isReadOnly) {
      setIsChanged(true);
    }
  }
  function clearinput() {
    setIsReadOnly(!isReadOnly);
    setCPN("");
    setCPNData([]);
  }

  const liStyle = {
    padding: "10px",
    width: "100%",
    cursor: "pointer",
    borderBottom: "1px solid gray",
  };
  // useEffect define
  //===========================================================================
  React.useEffect(() => {
    if (rawData[inName]) {
      setCPN(rawData[inName]);
    }
  }, [rawData, inName]);
  return (
    <>
      <th className="required">{title}</th>
      <td
        style={{
          position: "relative",
        }}
      >
        <div className="flex">
          <div className="custom-input wide">
            <input
              name={inName}
              type="text"
              placeholder={place}
              onChange={onChangeFilter}
              onFocus={(e) => editCPN(e)}
              readOnly={isReadOnly}
              value={CPN || ""}
              autoComplete="off"
            />
          </div>
          <button
            type="button"
            className="common-btn white sm"
            onClick={() => clearinput()}
          >
            <span>{button}</span>
          </button>
        </div>
        {isChanged && CPNData.length > 0 ? (
          <ul
            className="search-list"
            style={{
              position: "absolute",
              backgroundColor: "white",
              zIndex: 100,
              width: "80%",
              marginTop: "5px",
              maxHeight: "200px",
              overflow: "scroll",
              border: "1px solid #cccc",
              overflowX: "hidden",
            }}
          >
            {CPNData.map((item: any, index: any) => (
              <li
                style={liStyle}
                key={index}
                onClick={() => {
                  setRawData(item);
                  setCPN(item[filter]);
                  setIsChanged(false);
                }}
              >
                {item[filter]}
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </td>
    </>
  );
}
