import React, { useState, useEffect } from "react";
//@ts-ignore
import { LanguageContext } from "../../../../context/LanguageContext.jsx";
//@ts-ignore
import { dataList } from "../../../Admin/AdmMain";
import Page from "../../../Atomic/template/PageTable";
import CommonControl from "../../../Atomic/organism/CommonControl";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import axios from "../../../../lib/axios";
//@ts-ignore
import { useAuth } from "../../../../context/LoginContext.jsx";
//@ts-ignore
import TableAtom from "../../../Atomic/organism/AtomTable";
import { filterData, inputChnage } from "../../../Atomic/exFunc";
import Form from "../../../Atomic/organism/FormAtom";
//@ts-ignore
export default function Table() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const navi = useNavigate();
  const [rawData, setRawData] = useState<any>([]);
  const { user } = useAuth();
  const inputRef = React.createRef<HTMLInputElement>();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState<any>([]);
  // Function defines
  //===============================================================

  function move(e: any) {
    const i = e.instance.store.focus.rowIndex;
    if (i === undefined) {
      return;
    }
    const idx = e.instance.store.data.rawData[i].idx;
    // console.log("idx", rawData);

    if (e.columnName !== "sms_cnt" && e.targetType != "columnHeader") {
      navi(`/Adm/menu-table/basic`, {
        state: {
          idx: idx,
        },
      });
    }
  }

  function submitFun(e: any) {
    e.preventDefault();
    filterData(rawData, keyword, setData);
  }

  // Data define
  //===============================================================

  const columns: any = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "권한그룹 명" : "Permission Group Name",
      name: "nm",
      align: "center",
    },
    {
      header:
        language === "KOR" ? "그룹 설명(코드)" : "Group Description (code)",
      name: "ctnt",
      align: "center",
    },
    {
      header: language === "KOR" ? "그룹 사용자(명)	" : "Group User (Name)",
      name: "cnt",
      align: "center",
    },
    {
      header: language === "KOR" ? "접속 허가" : "Permission to access",
      name: "all_yn",
      align: "center",
      width: 70,
    },
  ];

  const buttonData = [
    {
      labelKR: "전체목록",
      labelEN: "View All",
      classN: "white",
      clickFun: () => {
        setKeyword("");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "등록",
      labelEN: "Add new",
      classN: "purple",
      icon: true,
      clickFun: () => {
        navi("/Adm/menu-table/new");
      },
      position: "right",
    },
  ];

  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e: any) => inputChnage(e, rawData, setData, setKeyword),
    myref: inputRef,
    option: undefined, // Make option undefined
    value: keyword,
  };

  // useEffect define
  //===============================================================

  useEffect(() => {
    axios
      .get(`/web/admin/menu/list?type=${user.type}`)
      .then((res: any) => {
        setRawData(res.data);
      })
      .catch((err: any) => {
        console.log(`Error Link  ${err.config.url}`);
      });
  }, [user]);
  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
      filterData(rawData, keyword, setData);
    }
  }, [rawData, keyword]);

  // logs define
  //===============================================================
  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "메뉴 권한 관리" : "Menu Auth"}`}
        menu="/Adm/menu-table"
      >
        <Form formData={formData} inputRef={inputRef} />
        <TableAtom data={data} columns={columns} move={move} />
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
