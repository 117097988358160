import React, { useEffect } from "react";
import ControlMap from "../../components/map/ControlMap";
import { useAuth } from "../../context/LoginContext";
import { MapProvider } from "../../context/MapContext";
import AppFooter from "../../components/include/AppFooter";
import AppHeader from "../../components/include/AppHeader";
import useFetch from "../../hook/useFetch";

export default function Control({ isFull, on, off }) {
  const { user } = useAuth();
  const { data, isLoading } = useFetch(
    `web/map/tracking?idx=${user.idx}&org_idx=${user.org_idx}&type=${user.type}&grade=${user.grade}`,
    user.isRefresh
      ? {
          revalidateOnMount: true,
          refreshInterval: Number(user.refreshPeriod) * 1000,
          revalidateOnFocus: true,
        }
      : {
          revalidateOnMount: true,
          revalidateOnFocus: true,
        }
  );
  const { data: geoData } = useFetch(
    `web/map/tracking/geofence?grade=${user.grade}&org_idx=${user.org_idx}&type=${user.type}`,
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    }
  );
  const { data: typeData } = useFetch(`/web/category/device`);

  useEffect(() => {
    if (data) {

    }
  }, [data]);

  useEffect(() => {
    
  }, [geoData]);
  useEffect(() => {
    if (document.querySelector(`script[src="../assets/js/ui.js"]`)) {
      return;
    }
    const script = document.createElement("script");
    script.src = "../assets/js/ui.js";
    document.body.appendChild(script);
  }, []);
  return (
    <>
      <AppHeader pages="1" />
      <div className="app-body">
        <div className="app-page map">
          <MapProvider>
            <ControlMap
              data={data}
              geoData={geoData}
              typeData={typeData}
              isLoading={isLoading}
            />
          </MapProvider>
        </div>
      </div>
      <AppFooter />
    </>
  );
}
