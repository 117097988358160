import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../lib/axios";
//@ts-ignore
import { dataList } from "../../../Admin/AdmMain";
import CommonControl from "../../../Atomic/organism/CommonControl";
import Page from "../../../Atomic/template/PageTable";
import InputForm from "../../../Atomic/atoms/InputForm";
import Radio from "../../../Atomic/atoms/Radio";
import Select from "../../../Atomic/molecules/Select";
export default function Form() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const navi = useNavigate();
  const { user } = useAuth();
  // State defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [rawData, setRawData] = useState<any>({});

  //=======================================================================
  // Function defines
  //=======================================================================

  function inputHandler(e: any) {
    const { name, value } = e.target;
    setRawData((pre: any) => ({ ...pre, [name]: value }));
  }
  function createNew() {
    if (rawData.nm_kor === "" || rawData.nm_kor === undefined) {
      Swal.fire({
        title: `${translate(
          "분류명(Korean)을 입력해주세요",
          "Please enter the category name(Korean)"
        )}`,
      });
      return;
    }
    if (rawData.nm_eng === "" || rawData.nm_eng === undefined) {
      Swal.fire({
        title: `${translate(
          "분류명(Englsih)을 입력해주세요",
          "Please enter the category name(English)"
        )}`,
      });
      return;
    }

    Swal.fire({
      title: `${translate("등록하시겠습니까?", "Would you like to register?")}`,
      showCancelButton: true,
      confirmButtonText: `${translate("등록", "Register")}`,
      cancelButtonText: `${translate("취소", "Cancel")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`/web/admin/basic/add`, rawData)
          .then((res: any) => {
            if (res.status === 200) {
              Swal.fire({
                title: "등록되었습니다.",
              }).then((result) => {
                navi("/Adm/bas-table");
              });
            } else {
              Swal.fire({
                title: "등록에 실패하였습니다.",
              });
            }
          });
      }
    });
  }

  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },

    {
      labelKR: "등록",
      labelEN: "Register",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];

  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
  }, [location]);
  useEffect(() => {
    if (idx !== "") {
      axios
        .get(
          `/web/admin/notice/detail?idx=${idx}&type=${user.type}`
        )
        .then((res: any) => {
          setRawData(res.data);
          setIsAdd(false);
        });
    } else {
      setIsAdd(true);
      setRawData({
        type: user.type,
      });
    }
  }, [idx, user.type]);

  // Log defines
  //=======================================================================

  // console.log("rawData", rawData);
  // console.log("state", location.state);
  // console.log("idx", idx);
  // console.log("CompanyData", companyData);
  // console.log("user", user.type);
  // console.log("refId", refId.current?.value);

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${
          language === "KOR" ? "기초코드 관리" : "Basic code management"
        }`}
        menu="/Adm/bas-table"
      >
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th className="required">
                {language === "KOR" ? "년도별 관리" : "Management by year"}
              </th>
              <td colSpan={1}>
                <Select
                  id="c_year"
                  short={""}
                  name="c_year"
                  options={[
                    {
                      name: "2024",
                      value: "2024",
                    },
                    {
                      name: "2023",
                      value: "2023",
                    },
                    {
                      name: "2022",
                      value: "2022",
                    },
                    {
                      name: "2021",
                      value: "2021",
                    },
                  ]}
                  changeFun={(e) => inputHandler(e)}
                  def={rawData.c_year || ""}
                />
              </td>
              <th>
                {translate("년도별 관리 적용", "Yearly Management Application")}
              </th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"kind"}
                    val={"Y"}
                    title={translate("네", "Yes")}
                    change={(e) => inputHandler(e)}
                    check={rawData.kind === "Y" ? true : false}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <InputForm
                label={translate("분류명(Korean)", "Category(Korean)")}
                name={"nm_kor"}
                val={rawData.nm_kor || ""}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
              <InputForm
                label={translate("분류명(English)", "Category(English)")}
                name={"nm_eng"}
                val={rawData.nm_eng || ""}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
            </tr>
            <tr>
              <InputForm
                label={translate("분류설명", "Category Description")}
                name="info"
                val={rawData.info || ""}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required=""
                readonly={false}
              />
              <th>
                {translate(
                  "2차 분류코드 입력방식",
                  "Secondary classification code Input method"
                )}
              </th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"auto"}
                    val={"Y"}
                    title={translate("자동입력", "Automatic input")}
                    change={(e) => inputHandler(e)}
                    check={rawData.auto === "Y" ? true : false}
                  />
                  <Radio
                    name={"auto"}
                    val={"N"}
                    title={translate("직접입력", "Direct input")}
                    change={(e) => inputHandler(e)}
                    check={rawData.auto === "N" ? true : false}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
