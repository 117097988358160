import React, { useContext, useEffect, useState } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { LanguageContext } from "../../../context/LanguageContext";
import { useAuth } from "../../../context/LoginContext";

export default function RegionalSetModal({ setData, fenceData, setFenceData }) {
  const { language } = useContext(LanguageContext);
  const [data] = useState("");
  const [addr, setAddr] = useState("");
  const { user } = useAuth();
  const open = useDaumPostcodePopup(
    "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
  );
  const handleComplete = async (data) => {
    var fullAddr = ""; // 최종 주소 변수
    if (data.userSelectedType === "R") {
      fullAddr = data.roadAddress;
    } else {
      fullAddr = data.jibunAddress;
    }
    setAddr(fullAddr);
  };

  const OpenZipSearch = () => {
    open({ onComplete: handleComplete });
  };

  useEffect(() => {
    const daumAddrInput = document.querySelector("#daum-post-addr"); // 상단 주소 입력
    const geoInfoContainer = document.querySelector(".geo-info .info-list"); // 우측 주소 정보
    const radiusBtn = document.querySelector('[name="radius"]'); // 반경 선택 selectbox

    let initalPosition = new window.naver.maps.LatLng(35.1768, 129.1253); // 초기 위치

    if (fenceData.lat && fenceData.lng) {
      initalPosition = new window.naver.maps.LatLng(
        fenceData.lat,
        fenceData.lng
      );
    }

    const mapDiv = document.getElementById("map");
    const mapOptions = {
      center: initalPosition,
      zoomControl: true,
      zoomControlOptions: {
        style: window.naver.maps.ZoomControlStyle.SMALL,
        position: window.naver.maps.Position.TOP_RIGHT,
      },
      zoom: data.radius > 500 ? 14 : 16,
    };

    // 맵 객체
    const map = new window.naver.maps.Map(mapDiv, mapOptions);
    // 마커 객체
    const marker = new window.naver.maps.Marker({
      position: initalPosition,
      map: map,
      icon: {
        content: `
                    <div class="marker geo">
                        <div class="marker-icon">
                            <span class="icon">
                                <i class="ri-community-fill"></i>
                            </span>
                        </div>
                    </div>
                `,
        size: new window.naver.maps.Size(54, 54),
        anchor: new window.naver.maps.Point(27, 54),
      },
    });
    // 서클 객체
    let circle = new window.naver.maps.Circle({
      map: map,
      center: initalPosition,
      radius: fenceData.radius !== "" ? Number(fenceData.radius) : 500,
      fillColor: "#2C2685",
      fillOpacity: 0.14,
      strokeWeight: 4,
      strokeColor: "#2C2685",
    });

    // 검색한 주소가 있다면 검색한 정보로 업데이트 없다면 초기위치로 업데이트
    if (addr != "") {
      (async () => {
        const addressInfo = await searchByDaumPost(addr);
        updateInfo(addressInfo);
        moveMarker(addressInfo.y, addressInfo.x);
      })();
    } else {
      updateByCoord(initalPosition);
    }

    // 다음 주소를 naver 주소로 변환(지오코딩 (주소 -> 도로명주소/지번주소/영어주소/좌표))
    function searchByDaumPost(address) {
      return new Promise((resolve, reject) => {
        window.naver.maps.Service.geocode(
          {
            query: address,
          },
          function (status, response) {
            if (status === window.naver.maps.Service.Status.ERROR) {
              reject();
            } else {
              if (response.v2.meta.totalCount === 0) {
                alert("검색된 결과가 없습니다.");
                reject();
              } else {
                resolve(response.v2.addresses[0]);
              }
            }
          }
        );
      });
    }

    //위치정보 업데이트 함수
    async function updateByCoord(position) {
      geoInfoContainer.innerHTML = "";
      const data = await searchCoordToAddress(position);
      updateInfo(data);
      setFenceData((prevData) => ({
        ...prevData,
        addr: data.jibunAddress,
        lat: parseFloat(data.y).toFixed(5),
        lng: parseFloat(data.x).toFixed(5),
        road_addr: data.roadAddress,
        number_addr: data.jibunAddress,
        eng_addr: data.englishAddress,
        user: user,
      }));
    }

    // 주소 정보 업데이트
    function updateInfo(data) {
      //오른쪽판넬 정보업데이트
      // why did you do this?
      geoInfoContainer.innerHTML = `
                <li>
                    <div class="item-label">${
                      language === "KOR" ? "위치정보" : "Coordinates"
                    }</div>
                    <div class="item-content">
                        <span class="y">${data.y} °N</span>
                        <span class="x">${data.x} °E</span>
                    </div>
                </li>
                <li>
                    <div class="item-label">${
                      language === "KOR" ? "도로명" : "Road addr"
                    }</div>
                    <div class="item-content">${
                      data.roadAddress ? data.roadAddress : "-"
                    }</div>
                </li>
                <li>
                    <div class="item-label">${
                      language === "KOR" ? "지번" : "Region addr"
                    }</div>
                    <div class="item-content">${
                      data.jibunAddress ? data.jibunAddress : "-"
                    }</div>
                </li>
                <li>
                    <div class="item-label">${
                      language === "KOR" ? "영문명" : "Eng addr"
                    }</div>
                    <div class="item-content">${
                      data.englishAddress ? data.englishAddress : "-"
                    }</div>
                </li>
            `;

      //주소검색바 업데이트
      daumAddrInput.value = data.roadAddress
        ? data.roadAddress
        : data.jibunAddress;
      setData(data);
    }

    // 리버스 지오코딩(좌표 => 주소)
    function searchCoordToAddress(position) {
      return new Promise((resolve, reject) => {
        window.naver.maps.Service.reverseGeocode(
          {
            coords: position,
            orders: [
              window.naver.maps.Service.OrderType.ADDR,
              window.naver.maps.Service.OrderType.ROAD_ADDR,
            ].join(","),
          },
          function (status, response) {
            if (status === window.naver.maps.Service.Status.ERROR) {
              reject();
            } else {
              searchByAddr(response.v2.address, reject, resolve);
            }
          }
        );
      });
    }

    // 지오코딩 (주소 -> 도로명주소/지번주소/영어주소/좌표)
    function searchByAddr(address, reject, resolve) {
      if (!address) {
        reject();
      }

      window.naver.maps.Service.geocode(
        {
          query: address.jibunAddress,
        },
        function (status, response) {
          if (status === window.naver.maps.Service.Status.ERROR) {
            reject();
          } else {
            if (response.v2.meta.totalCount === 0) {
              alert("검색된 결과가 없습니다.");
              reject();
            } else {
              resolve(response.v2.addresses[0]);
            }
          }
        }
      );
    }

    //마커이동 함수
    function moveMarker(len, lat) {
      const position = new window.naver.maps.LatLng(len, lat);
      map.panTo(position);
      marker.setPosition(position);
      moveCircle(position);
      updateByCoord(position);
    }

    //반경이동 함수
    function moveCircle(position) {
      circle.setCenter(position);
    }

    //반경업데이트 함수
    function changeCircleRadius(radius) {
      circle.setRadius(radius);

      switch (true) {
        case radius <= 500:
          map.setZoom(16, false);
          break;
        case radius <= 1500:
          map.setZoom(15, false);
          break;
        case radius <= 3000:
          map.setZoom(14, false);
          break;
        case radius <= 5500:
          map.setZoom(13, false);
          break;
        case radius <= 10000:
          map.setZoom(12, false);
          break;
        default:
          map.setZoom(16, false); // default zoom if radius exceeds the defined values
      }
    } //지도에 클릭이벤트 생성 (마커이동, 반경이동, 위치정보업데이트)

    //반경조절버튼에 이벤트 걸기
    radiusBtn.addEventListener("change", function () {
      changeCircleRadius(this.value);
    });

    //지도에 클릭이벤트 생성 (마커이동, 반경이동, 위치정보업데이트)
    window.naver.maps.Event.addListener(map, "click", function (e) {
      marker.setPosition(e.coord);
      moveMarker(e.coord);
      moveCircle(e.coord);
      updateByCoord(e.coord);
    });
  }, [addr]);

  return (
    <>
      <div className="left">
        <div className="addr-search-bar">
          <i className="ri-map-pin-2-fill pin-icon"></i>
          <div className="custom-input">
            <input
              type="text"
              id="daum-post-addr"
              placeholder={
                language === "KOR" ? "주소를 검색해주세요" : "enter addr"
              }
              readOnly
            />
            <button className="del-btn">
              <i className="ri-close-circle-fill icon"></i>
            </button>
          </div>
          <button
            type="button"
            className="common-btn purple find-btn"
            onClick={OpenZipSearch}
          >
            <span>{language === "KOR" ? "주소 검색" : "Addr search"}</span>
          </button>
        </div>
        <div id="map"></div>
      </div>
    </>
  );
}
