import { LanguageContext } from "../../../context/LanguageContext";
import React, { useEffect } from "react";
import Header from "../../include/AppHeader";
import Modal from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import PropTypes from "prop-types";
import Form from "../../Atomic/organism/Form";
import CommonControl from "../../Atomic/organism/CommonControl";
import { useAuth } from "../../../context/LoginContext";
export default function PageTemplate({
  titleKR,
  titleEN,
  menu,
  viewAll,
  excelDown,
  buttonData,
  form,
  dataList,
  table,
  inputRef,
}) {
  const { language } = React.useContext(LanguageContext);
  const { stopRefresh } = useAuth();

  useEffect(() => {
    stopRefresh();
  }, [])

  return (
    <>
      <Header pages="2" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav language={language} menu={menu} dataList={dataList} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? titleKR : titleEN}
                </div>
              </div>
              <div className="sub-page-body">
                <Form
                  language={form.language}
                  id={form.id}
                  type={form.type}
                  option={form.option}
                  placeholderKR={form.placeholderKR}
                  placeholderEN={form.placeholderEN}
                  submitHandler={form.submitHandler}
                  onChange={form.onchange}
                  filter={form.filter}
                  inputRef={inputRef}
                  value={form.value}
                />
                {table}
                <CommonControl
                  buttonData={buttonData}
                  viewAll={viewAll}
                  excelDown={excelDown}
                  language={language}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </>
  );
}

PageTemplate.propTypes = {
  titleKR: PropTypes.string,
  titleEN: PropTypes.string,
  menu: PropTypes.string,
  filter: PropTypes.func,
};
