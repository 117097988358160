import React, { ReactNode } from "react";
import CustomInput from "../molecules/CustomInput";

interface Props {
  formData: FormData;
  inputRef?: React.RefObject<HTMLInputElement>;
}

interface FormData {
  lan: string;
  inputId: string;
  type: string;
  option?: ReactNode;
  placeKR: string;
  placeEN: string;
  submitHandler: (e: any) => void;
  filter: (value: string) => void;
  value?: string;
}

export default function Form({ formData, inputRef }: Props) {
  return (
    <>
      <form className="search-bar" onSubmit={formData.submitHandler}>
        {formData.option && (
          <div className="custom-group">{formData.option}</div>
        )}
        {inputRef && (
          <CustomInput
            id={formData.inputId}
            type={formData.type}
            placeHolder={
              formData.lan === "KOR" ? formData.placeKR : formData.placeEN
            }
            changeFun={(e) => {
              formData.filter(e.target.value);
            }}
            inputRef={inputRef}
            value={formData.value}
          />
        )}

        <button type="submit" className="submit-btn">
          {formData.lan === "KOR" ? "검색" : "Search"}
        </button>
      </form>
    </>
  );
}
