import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/Faq/style.css';
import '../../assets/css/Faq/reset.css';
import '../../assets/css/Faq/media.css';

export default function Outer({children, type}) {
    return (
        <>
            <div className='faq-outer'>
                <header className="header">
                    <div className="header-container">
                        <a href={type === 'wire' ? '#wire' : '#wireless'}>
                            <div className="header-logo"></div>
                        </a>
                        <nav className="header-menu">
                            <ul className="header-lists">
                                <li>
                                    <Link to={'/FAQ'} className={`header-item${type === 'wire' ? '-line' : ''}`}>유선형</Link>
                                </li>
                                <li>
                                    <Link to={'/FAQ/wireless'} className={`header-item${type === 'wireless' ? '-line' : ''}`}>무선형</Link>
                                </li>
                                <li>
                                    <div className="header-item-not">라우터/DTG</div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </header>
                {children}
                <a href={type === 'wire' ? '#wire' : '#wireless'}>
                    <div class="float-top">
                        <img src="../../assets/img/Faq/arrow-top.svg" class="float-top-svg" />
                    </div>
                </a>
                <div class="float-banner">
                    <a href="https://www.iotlbs.com/" target="_blank">
                        <p class="normal-700">💻 K LBS 통합위치관제</p>
                    </a>
                    <a href="https://smartstore.naver.com/iotplex" target="_blank">
                        <p class="normal-700">🎁 네이버 스마트스토어</p>
                    </a>
                    <a href="https://blog.naver.com/iotplex20" target="_blank">
                        <p class="normal-700">🚘 아이오티플렉스 블로그</p>
                    </a>
                </div>
            </div>
        </>
    );
}

