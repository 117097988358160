import { useEffect, useState } from "react";
import React from "react";
import FormRowInput from "../molucule/FormRowInput";
import ButtonWrap from "../molucule/ButtonWrap";
import FormRowSelect from "../molucule/FormRowSelect";
import FormRowTextArea from "../molucule/FormRowTextArea";
import ColorPicker from "../molucule/ColorPicker";
import FormCheckBox from "../molucule/FormCheckBox";
import FormDate from "../molucule/FormDate";
import PropTypes from "prop-types";
import { LanguageContext } from "../../../../../context/LanguageContext.jsx";
import DatePicker from "../molucule/DatePicker";

import axios from "../../../../../lib/axios";
import { useAuth } from "../../../../../context/LoginContext";

export default function EventForm({
  isOpen,
  handlerClick,
  state,
  updateState,
  cancelFun,
  submitFun,
  deleltetitle,
}) {
  const { language } = React.useContext(LanguageContext);
  const { user } = useAuth();
  const buttons = [
    {
      idx: 1,
      buttonName: deleltetitle || `${language === "KOR" ? "취소" : "Cancel"}`,
      colorBTN: "white",
      type: "button",
      clickFun: () => {
        cancelFun();
      },
    },
    {
      idx: 2,
      buttonName: `${language === "KOR" ? "저장" : "Save"}`,
      colorBTN: "black",
      type: "submit",
      clickFun: (e) => {
        submitFun(e);
      },
    },
  ];
  const [deviceData, setDeviceData] = useState([
    {
      idx: 0,
      name: "Device 1",
      value: "",
      label: `${language === "KOR" ? "단말기명" : "Select Device"}`,
    },
  ]);
  function handleCheckboxChange(e) {
    const { name, checked } = e.target;
    updateState(name, checked === true ? "Y" : "N");
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/web/schedule/list?type=${user.type}&org_idx=${user.org_idx}`
        );
        const transformedData = response.data.device.map((item) => ({
          idx: item.idx,
          value: item.unitid,
          label: item.unit_nm,
        }));
        setDeviceData([deviceData[0], ...transformedData]);
      } catch (error) {
        console.error("Error fetching device data: ", error);
      }
    };

    fetchData();
  }, [user.u_type, user.org_idx]);

  const getCurrentLocalTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0"); // Get hours (0-23) and ensure it's zero-padded
    const minutes = now.getMinutes().toString().padStart(2, "0"); // Get minutes and ensure it's zero-padded
    return `${hours}:${minutes}`;
  };
  useEffect(() => {
    // This code will run whenever 'state' is updated
    if (!state.fromDate) {
      state.fromDate = new Date().toISOString().slice(0, 10);
    }
    if (!state.toDate) {
      state.toDate = new Date().toISOString().slice(0, 10);
    }
    if (!state.fromTime) {
      state.fromTime = getCurrentLocalTime();
    }
    if (!state.toTime) {
      state.toTime = getCurrentLocalTime();
    }
    // You can perform any side effects here, like making an API request, etc.
  }, [state]);

  const styleWrap = {
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    width: "100%",
    height: "100%",
    gap: "20px",
    marginTop: "50px",
  };
  const styleItem = {
    display: "flex",
    // justifyContent: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  };
  const styleItemBottom = {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    marginTop: "45px",
  };
  return (
    <form onSubmit={(e) => submitFun(e)}>
      <div style={styleWrap}>
        <div style={styleItem}>
          <FormRowInput
            title={language === "KOR" ? "일정 제목" : "Event Name"}
            id={"event-title"}
            value={state.title || ""}
            inputName={"title"}
            changeFun={(e) => updateState("title", e.target.value)}
            placeholder={
              language === "KOR" ? "일정 제목을 입력해주세요" : "Event Name"
            }
          />

          <FormRowInput
            title={language === "KOR" ? "운전자명" : "Select Driver"}
            id={"event-driver"}
            value={state.driver || ""}
            inputName={"driver"}
            placeholder={
              language === "KOR" ? "운전자명을 입력해주세요" : "Driver Name"
            }
            changeFun={(e) => updateState("driver", e.target.value)}
          />
          <FormRowSelect
            title={language === "KOR" ? "단말기명" : "Select Device"}
            id={"event-device"}
            value={state.unitid || ""}
            inputName={"device"}
            changeFun={(e) => updateState("unitid", e.target.value)}
            options={deviceData}
          />
          <FormCheckBox
            id={"allDayVal"}
            inputName={"allday"}
            value={state.allday || "Y"}
            checked={state.allday === "Y" ? true : false}
            title={language === "KOR" ? "종일" : "All Day"}
            state={state}
            onChangeFun={handleCheckboxChange}
          />
          <ColorPicker
            title={language === "KOR" ? "일정 색상" : "Event Color"}
            state={state}
            isOpen={isOpen}
            handlerClick={handlerClick}
            onChange={updateState}
          />
        </div>

        <div style={styleItem}>
          <DatePicker
            value={state.fromDate || ""}
            changeFun={updateState}
            title={`fromDate`}
            titleName={language === "KOR" ? "시작일" : "Start Date"}
            language={language}
          />
          {state.allday === "N" && (
            <FormDate
              type={"time"}
              title={language === "KOR" ? "시작시간" : "Start Time"}
              id={"fromTime"}
              value={state.fromTime || ""}
              inputName={"fromTime"}
              changeFun={(e) => updateState("fromTime", e.target.value)}
            />
          )}
          <DatePicker
            value={state.toDate || ""}
            changeFun={updateState}
            title={`toDate`}
            titleName={language === "KOR" ? "종료일" : "End Date"}
          />
          {state.allday === "N" && (
            <FormDate
              type={"time"}
              title={language === "KOR" ? "종료시간" : "End Time"}
              id={"toTime"}
              value={state.toTime || ""}
              inputName={"toTime"}
              changeFun={(e) => updateState("toTime", e.target.value)}
            />
          )}
        </div>
      </div>
      <div style={styleItemBottom}>
        <FormRowTextArea
          title={language === "KOR" ? "메모" : "Memo"}
          id={"event-memo"}
          value={state.memo || ""}
          inputName={"memo"}
          changeFun={(e) => updateState("memo", e.target.value)}
        />
        <ButtonWrap btnArray={buttons} />
      </div>
    </form>
  );
}

EventForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handlerClick: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};
