import React, { useEffect } from "react";
import Swal from "sweetalert2";
import axios from "../../../lib/axios";
import { add_hyphen, trimType } from "../../../lib/common_func";
import { useLocation } from "react-router-dom";
import { LanguageContext } from "../../../context/LanguageContext";
import { useNavigate } from "react-router-dom";
// Atoms
import Footer from "../../include/AppFooter";
import Header from "../../include/AppHeader";
import Nav from "../../Atomic/organism/Nav";
import Table from "../../Atomic/organism/AtomTable";
import CommonControl from "../../Atomic/organism/CommonControl";
import { dataList } from "./TerminalMain";
export default function GeoFenceList() {
  const navi = useNavigate();
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();

  // State definition
  //==================================================================
  const [rawData, setRawData] = React.useState([]);
  const [detaiData, setDetaiData] = React.useState([]);
  const [list, setList] = React.useState([]);
  const [unitId, setUnitId] = React.useState("");
  const [fence, setFence] = React.useState({});
  //==================================================================

  const columns = [
    {
      header: "No",
      name: "rowKey",
      resizable: false,
      width: 50,
      formatter: (name) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "단말번호" : "USIM num",
      name: "unitid",
      resizable: true,
      align: "center",
      sortable: true,
      formatter: (item) => add_hyphen(item.value, language),
    },
    {
      header: language === "KOR" ? "단말명" : "Unit Name",
      name: "unit_nm",
      resizable: true,
      align: "center",
      sortable: true,
    },

    {
      header: language === "KOR" ? "연결 일시" : "Connection date and time",
      name: "makedate",
      resizable: true,
      align: "center",
    },
  ];
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",

      clickFun: () => {
        navi("/Trm/GeoFence");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
  ];
  //==================================================================
  useEffect(() => {
    if (location.state.unitid) {
      setUnitId(location.state.unitid);
    }
  }, [location.state.unitid]);

  useEffect(() => {
    if (unitId) {
      async function getData() {
        try {
          await axios
            .get(`/web/region/conn/list?idx=${unitId}`)
            .then((res) => setRawData(res.data));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      getData();
    }
  }, [unitId]);
  useEffect(() => {
    if (unitId) {
      fetchData(unitId).then((res) => setFence(res.data));
    }
  }, [unitId]);
  useEffect(() => {
    if (rawData) {
      if (rawData.list) {
        setList(rawData.list);
      }
      setDetaiData(rawData.data);
    }
  }, [rawData]);

  // Function definition
  //==================================================================
  const fetchData = async (unitId) => {
    try {
      if (unitId) {
        const response = await axios.get(`/web/region/detail?idx=${unitId}`);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const move = (e) => {
    const i = e.instance.store.focus.rowIndex;
    if (i !== null) {
      const idx = trimType(e.instance.store.data.rawData[i].idx);
      if (e.columnName === "conn") {
        navi(`/Trm/GeoFence/Detail`, {
          state: {
            unitid: idx,
          },
        });
      }
      if (e.columnName === "disconn") {
        navi(`/Trm/GeoFence/Detail`, {
          state: {
            unitid: idx,
          },
        });
      }
    }
  };
  // Log Debugging
  //==================================================================
  //
  return (
    <>
      <Header pages="4" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav dataList={dataList} menu="/Trm/GeoFence" language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR"
                    ? `${fence.region_nm} (${fence.radius}m) 지역에 연결된 단말 목록`
                    : `List of units connected to ${fence.region_nm} (${fence.radius}m) area`}
                </div>
              </div>
              <div className="sub-page-body">
                <div className="common-table-wrapper">
                  {list.length === 0 ? (
                    <div className="no-data">
                      {language === "KOR"
                        ? "데이터가 존재하지 않습니다."
                        : "No data exists."}
                    </div>
                  ) : (
                    <>
                      <Table
                        language={language}
                        data={list}
                        columns={columns}
                        move={move}
                      />
                      <CommonControl
                        buttonData={buttonData}
                        language={language}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
