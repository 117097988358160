import React, { useState, useEffect, useRef } from "react";
import "../atoms/GeoMarkerAtom";
import { createRoot } from "react-dom/client";
//@ts-ignore
import { useMap } from "../../../context/MapContext.jsx";
import GeoMarkerAtom from "../atoms/GeoMarkerAtom";

interface GeoMarkerProps {
  item: any;
  map: any;
  circle: any;

  setCircle: (e: any) => void;
}
export default function GeoMarker({
  item,
  map,
  circle,

  setCircle,
}: GeoMarkerProps) {
  //@ts-ignore
  const { naver } = window;
  const { mapDispatch } = useMap();
  const [geoMarker, setGeoMarker] = useState<any>(null);
  const geoMarkerRef = useRef<any>(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (!geoMarker) {
      setGeoMarker(new naver.maps.Marker());
    }
    return () => {
      if (geoMarker) {
        geoMarker.eventTarget.parentNode.classList.remove("active");
        geoMarker.setMap(null);
      }
    };
  }, [geoMarker, naver]);

  useEffect(() => {
    if (map && geoMarker) {
      const markerContentElement = document.createElement("div");
      geoMarkerRef.current = markerContentElement;
      const root = createRoot(markerContentElement);
      root.render(<GeoMarkerAtom name={item.region_nm} />);

      geoMarker.setOptions({
        position: new naver.maps.LatLng(item.latitude, item.longitude - 0.002),
        map: map,
        icon: {
          content: geoMarkerRef.current,
          size: new naver.maps.Size(54, 54),
          anchor: new naver.maps.Point(27, 54),
        },
        markerId: item.idx,
      });
      geoMarker.eventTarget.parentNode.classList.add("size");
    }
  }, [geoMarker, item, map, naver, mapDispatch]);

  useEffect(() => {
    let newCircle;
    // Check if geoMarker and map exist
    if (geoMarker && map) {
      // Create a new circle with updated radius
      newCircle = new naver.maps.Circle({
        map: map,
        center: geoMarker.position,
        radius: item.radius,
        fillColor: "#2C2685",
        fillOpacity: 0.14,
        strokeWeight: 4,
        strokeColor: "#2C2685",
      });
      setCircle({ g1: newCircle });
    }
    if (circle.g1) {
      circle.g1.setMap(null);
    }
  }, [item, geoMarker, map, naver]);

  useEffect(() => {
    if (!marker) {
      setMarker(new naver.maps.Marker());
    }
    return () => {
      if (marker) {
        //@ts-ignore
        marker.eventTarget.parentNode.classList.remove("active");
        //@ts-ignore
        marker.setMap(null);
      }
    };
  }, [marker, naver, item, map]);

  return null;
}
