import { useDownloadExcel } from 'react-export-table-to-excel';
import { useLang } from '../context/LanguageContext';

export default function useExcelDown(ref, filename, sheet, data, con=true) {
    const { language } = useLang();
    const { onDownload } = useDownloadExcel({
        currentTableRef: ref.current,
        filename: filename,
        sheet: sheet
    })
    const excelDown = () => {
        if(!con) {
            alert(language === 'KOR' ? '데이터 로딩중입니다.' : 'Loading');
        }else if(data?.length > 0) {
            onDownload();
        } else {
            alert(language === 'KOR' ? '데이터가 존재하지 않습니다.' : 'No data')
        }
    }
    return {excelDown};
}

