import React, { useState } from "react";
import "../style.sass";
interface Props {
  language: string;
  loading: boolean;
  data: any[];
  adminLogin: (e: any) => void;
}
export default function Table({ language, loading, data, adminLogin }: Props) {
  const [hoveredRow, setHoveredRow] = useState(0);

  if (loading) {
    return (
      <div className="loading">
        <div className="loading-bar">Loading</div>
      </div>
    );
  }
  return (
    <>
      <table className="common-table">
        <colgroup>
          <col style={{ width: "8%" }} />
          <col style={{ width: "8%" }} />
          <col style={{ width: "8%" }} />
          <col style={{ width: "12%" }} />
          <col />
          <col />
          <col style={{ width: "8%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>{language === "KOR" ? "번호" : "No"}</th>
            <th>{language === "KOR" ? "구분" : "Type"}</th>
            <th>{language === "KOR" ? "등급" : "Grade"}</th>
            <th>{language === "KOR" ? "회사코드" : "Com code"}</th>
            <th>{language === "KOR" ? "소속" : "Org"}</th>
            <th>{language === "KOR" ? "사용자명(아이디)" : "Username(ID)"}</th>
            <th>{language === "KOR" ? "차량수" : "Device"}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              onClick={() => adminLogin(item)}
              key={index}
              className={index === hoveredRow ? "hover-effect" : ""}
              onMouseEnter={() => setHoveredRow(index)}
              onMouseLeave={() => setHoveredRow(0)}
            >
              <td>{item.idx}</td>
              <td>{item.btoc_yn === "Y" ? "기업" : "개인"}</td>
              <td>
                {item.u_type === "C"
                  ? "회사"
                  : item.u_type === "B"
                  ? "지점"
                  : item.u_type === "A"
                  ? "대리점"
                  : ""}
              </td>
              <td>{item.companyCode}</td>
              <td>{item.companyName}</td>
              <td>
                {item.nm} ({item.userId})
              </td>
              <td>{item.dv_count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
