import React, { useEffect } from "react";
import axios from "../../../lib/axios";
import { trimType } from "../../../lib/common_func";
import { useAuth } from "../../../context/LoginContext";
import { LanguageContext } from "../../../context/LanguageContext.jsx";
import { useNavigate } from "react-router-dom";
import Header from "../../include/AppHeader";
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import CommonControl from "../../Atomic/organism/CommonControl";
import Table from "../../Atomic/organism/AtomTable";
import { dataList } from "./SetMain";
import { subDataList } from "./SetMain";
import SubNavi from "../../Atomic/molecules/SubNavi";
export default function Alarm() {
  const navi = useNavigate();
  const { language } = React.useContext(LanguageContext);
  const { user } = useAuth();
  // State definition
  //==================================================================
  const [rawData, setRawData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [count, setCount] = React.useState("");
  // Function definition
  //==================================================================

  const move = (e) => {
    const i = e.instance.store.focus.rowIndex;
    if (i !== null) {
      const idx = trimType(e.instance.store.data.rawData[i].idx);
      // const cmp_nm = trimType(e.instance.store.data.rawData[i].cmp_nm);
      // const cmp_cd = trimType(e.instance.store.data.rawData[i].cmp_cd);
      if (e.columnName === "btn" && e.targetType != "columnHeader") {
        navi(`/Set/Alarm/Edit`, {
          state: {
            idx: idx,
            // cmp_nm: cmp_nm,
            // cmp_cd: cmp_cd,
          },
        });
      }
    }
  };

  // UseEffect definition
  //==================================================================

  useEffect(() => {
    axios
      .get(
        `/web/settings/alarm/list?org_idx=${user.org_idx}&idx=${user.idx}&type=${user.type}`
      )
      .then((res) => {
        
        setRawData(res.data.list);
        setCount(res.data.count.cnt);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  useEffect(() => {
    if (rawData) {
      setData(rawData);
    }
  }, [rawData]);

  // Data definition
  //==================================================================

  const buttonData = [
    {
      path: "/Set/Purchase",
      labelKR: "Sms 구입내역",
      labelEN: "Sms Purchase",
      classN: "white",
      clickFun: () => {
        navi("/Set/Purchase");
      },
    },
    {
      labelKR: "등록",
      labelEN: "Registration",
      classN: "purple",
      icon: true,
      clickFun: () => {
        navi("/Set/Alarm/Add");
      },
      position: "right",
    },
  ];
  const columns = [
    {
      header: language === "KOR" ? "번호" : "Number",
      name: "rowKey" + 1,
      width: 50,
      align: "center",
      formatter: (name) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "수신자" : "Recipients",
      name: "u_nm",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "수신방법" : "Receiving method",
      name: "kind",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "이벤트" : "Event",
      name: "event",
      sortable: true,
      align: "center",
      ellipsis: true,
      formatter: (item) => {
        const eventKey = {
          evecd_07 : '제한속도 보고',
          evecd_09 : '긴급보고',
          evecd_10 : '지역진입보고',
          evecd_11 : '지역이탈보고',
          evecd_16 : '주전원 off-12V',
          evecd_17 : '내장배터리 저전압',
          evecd_18 : '차량배터리 저전압',
          evecd_20 : '차량 문 열림',
          evecd_21 : '차량 문 닫힘',
        }
        const event = Object.keys(item.row).filter((e) => e.includes('evecd'));
        const active = event.filter((e) => item.row[e] === 'Y')
                            .map((e) => eventKey[e]);
                
        return `${active.length}개 (${active.join(',')})`;
      }
    },
    {
      header: language === "KOR" ? "등록자" : "Registrant",
      name: "make_unm",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "등록일" : "Date of registration",
      name: "makedate",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "수정일" : "Date of modification",
      name: "editdate",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "수정" : "Edit",
      name: "btn",
      sortable: true,
      resizeable: true,
      align: "center",
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "수정" : "Edit"
        }</div>`,
    },
  ];
  // Log definition
  //==================================================================

  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Set/Alarm"} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "Push/Sms 관리" : "Push/Sms management"}
                </div>
              </div>
              <div className="sub-page-body">
                <div>
                  <p>
                    ※ SMS는 한 달에 30건씩, 1년 총 360건을 한 번에 무료로
                    지급합니다. 추가적인 SMS 전송 기능을 사용하기 위해서는 SMS
                    를 구입하셔야 합니다. (현재 남은 SMS 횟수 : {count})
                  </p>
                  <br />
                  <p>
                    ※ Push 기능을 위해서는 모바일 APP을 휴대전화에 설치하셔야
                    합니다. 문의 : 1599-2737
                  </p>
                  <br />
                </div>
                <SubNavi
                  subDataList={subDataList}
                  language={language}
                  navi={navi}
                  classPath="/Set/Alarm"
                />
                <Table data={data} columns={columns} move={move} />
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
