import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../lib/axios";
//@ts-ignore
import { dataList } from "../../../Admin/AdmMain";
import CommonControl from "../../../Atomic/organism/CommonControl";
import Page from "../../../Atomic/template/PageTable";
import InputForm from "../../../Atomic/atoms/InputForm";
import Radio from "../../../Atomic/atoms/Radio";
export default function Form() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const navi = useNavigate();
  const { user } = useAuth();
  // State defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const [rawData, setRawData] = useState<any>({});
  const [data, setData] = useState<any>([]);
  const [codeList, setCodeList] = useState<any>([]);
  //=======================================================================
  // Function defines
  //=======================================================================
  function addNew() {
    setCodeList((prev: any) => {
      const lastIdx = prev.length > 0 ? prev[prev.length - 1].idx : 0;
      const newIndex = lastIdx + 1;
      return [
        ...prev,
        {
          [`code`]: "",
          [`nm_kor`]: "",
          [`nm_eng`]: "",
          [`cnt`]: 0,
          idx: newIndex,
        },
      ];
    });
  }
  function inputHandler(e: any) {
    const { name, value } = e.target;
    setData((pre: any) => ({ ...pre, [name]: value }));
  }
  function inputHandlerList(e: React.ChangeEvent<HTMLInputElement>, item: any) {
    const { name, value } = e.target;

    setCodeList((prevList: any) => {
      const updatedList = prevList.map((i: any) =>
        i.idx === item.idx ? { ...i, [name]: value } : i
      );
      return updatedList;
    });
  }
  function deleteItem(index: number) {
    setCodeList((prev: any) => {
      return prev.filter((item: any) => item.idx !== index);
    });
  }
  function createNew() {
    if (rawData.name_kor === "") {
      Swal.fire({
        title: `${translate(
          "분류명(Korean)을 입력해주세요",
          "Please enter the category name(Korean)"
        )}`,
      });
      return;
    }

    axios
      .post(`/web/admin/notice/add`, rawData)
      .then((res: any) => {
        if (res.status === 200) {
          Swal.fire({
            title: "등록되었습니다.",
          }).then((result) => {
            navi("/Adm/bas-table");
          });
        } else {
          Swal.fire({
            title: "등록에 실패하였습니다.",
          });
        }
      });
  }

  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "취소",
      labelEN: "Cancel",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },

    {
      labelKR: "등록",
      labelEN: "Register",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];
  const addButton = [
    {
      labelKR: "추가",
      labelEN: "Add",
      classN: "white",
      clickFun: () => {
        addNew();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
  ];
  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
  }, [location]);
  useEffect(() => {
    if (idx !== "") {
      axios
        .get(
          `/web/admin/basic/detail?idx=${idx}&type=${user.type}`
        )
        .then((res: any) => {
          setRawData(res.data);
          setData(res.data.category1);
          setCodeList(res.data.category2);
        });
    } else {
      setRawData({});
    }
  }, [idx, user.type]);

  useEffect(() => {
    setRawData((pre: any) => ({ ...pre, category1: data }));
  }, [data]);
  // Log defines
  //=======================================================================

  // console.log("rawData", rawData);
  // console.log("state", location.state);
  // console.log("idx", idx);
  // console.log("CompanyData", companyData);
  // console.log("user", user.type);
  // console.log("refId", refId.current?.value);
  // console.log(data);
  console.log("codeList", codeList);

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${
          language === "KOR" ? "기초코드 관리" : "Basic code management"
        }`}
        menu="/Adm/bas-table"
      >
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <InputForm
                label={translate("년도별 관리", "Management by year")}
                name={"info"}
                val={
                  data.kind === "Y"
                    ? `${translate(
                        "년도별 관리 적용",
                        "Yearly management applied"
                      )}`
                    : `${translate(
                        "년도별 관리 적용안함",
                        "Yearly management not applied"
                      )}`
                }
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={true}
                colSpan={3}
              />
            </tr>
            <tr>
              <InputForm
                label={translate("분류명(Korean)", "Category(Korean)")}
                name={"nm_kor"}
                val={data.nm_kor || ""}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
              <InputForm
                label={translate("분류명(English)", "Category(English)")}
                name={"nm_eng"}
                val={data.nm_eng || ""}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
            </tr>
            <tr>
              <InputForm
                label={translate("분류설명", "Category Description")}
                name="info"
                val={data.info || ""}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required=""
                readonly={false}
              />
              <th>
                {translate(
                  "2차 분류코드 입력방식",
                  "Secondary classification code Input method"
                )}
              </th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"auto"}
                    val={"Y"}
                    title={translate("자동입력", "Automatic input")}
                    change={(e) => inputHandler(e)}
                    check={data.auto === "Y" ? true : false}
                  />
                  <Radio
                    name={"auto"}
                    val={"N"}
                    title={translate("직접입력", "Direct input")}
                    change={(e) => inputHandler(e)}
                    check={data.auto === "N" ? true : false}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          className="sub-page-header"
          style={{
            marginTop: "2rem",
          }}
        >
          <div className="header-title">
            <span>2차 분류</span>
            <span
              style={{
                fontSize: "1.2rem",
                color: "#888",
                marginLeft: "1rem",
              }}
            >
              오른쪽 [+추가] 버튼을 클릭 시 2차 분류 입력란이 추가됩니다. + 추가
            </span>
          </div>
        </div>
        <table className="common-view-table">
          <thead>
            <tr>
              <th style={{ width: "100px" }}>
                {translate("분류코드", "Classification code")}
              </th>
              <th>
                {translate("분류명 (Korean)", "Classification Name (Korean)")}
              </th>
              <th>
                {translate("분류명 (English)", "Classification Name (English)")}
              </th>
              <th style={{ width: "100px" }}>
                {translate("정렬순서", "Sort order")}
              </th>
              <th style={{ width: "130px" }}>{translate("옵션", "Option")}</th>

              <th style={{ width: "100px" }}>
                {translate("등록수", "Number of registrations")}
              </th>
            </tr>
          </thead>
          <tbody>
            {codeList.map((item: any, index: number) => {
              return (
                <tr key={index}>
                  <td>
                    <div className="custom-input">
                      <input
                        type="text"
                        name="code"
                        value={item.code}
                        onChange={(e) => inputHandlerList(e, item)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="custom-input">
                      <input
                        type="text"
                        name="nm_kor"
                        value={item.nm_kor}
                        onChange={(e) => inputHandlerList(e, item)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="custom-input">
                      <input
                        type="text"
                        name="nm_eng"
                        value={item.nm_eng}
                        onChange={(e) => inputHandlerList(e, item)}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="custom-input">
                      <input
                        type="text"
                        name="sort"
                        value={item.idx}
                        onChange={(e) => inputHandlerList(e, item)}
                      />
                    </div>
                  </td>
                  <td>
                    <div>
                      <button
                        className="common-btn white"
                        onClick={() => {
                          deleteItem(item.idx);
                        }}
                      >
                        {translate("삭제", "Delete")}
                      </button>
                    </div>
                  </td>
                  <td style={{ textAlign: "center" }}>{item.cnt}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <CommonControl buttonData={addButton} language={language} />
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
