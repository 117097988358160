import React from "react";
interface CheckBoxProps {
  userInfo: object;
  changeFun: (event: any) => void;
  language: string;
  name: string;
  value: boolean;
  title: string;
}
export default function CheckBox({
  userInfo,
  changeFun,
  language,
  name,
  value,
  title,
}: CheckBoxProps) {
  return (
    <label className="custom-checkbox">
      <input type="checkbox" name={name} onChange={changeFun} checked={value} />
      <span className="checkbox"></span>
      <div className="label">{title}</div>
    </label>
  );
}
