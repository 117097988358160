import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import { useAuth } from "../../context/LoginContext";
import { useState } from "react";
import Stats from "./atomsHead/Stats";
import GNB from "./atomsHead/GNB";
import UserInfro from "./atomsHead/UserInfo";
import SubMenu from "./atomsHead/SubMenu";
import useFetch from "../../hook/useFetch";
import Modal from "./Modal/template/Modal";
import axios from "axios";

export default function AppHeader(props) {
  const navi = useNavigate();
  const { language, changeLanguage } = useContext(LanguageContext);
  const { user, handleLogout, startRefresh, count, refreshCount } = useAuth();
  const { handleChangeUser } = useAuth();
  const { data, isLoading } = useFetch(
    `/web/header/count?org_idx=${user.org_idx}&type=${user.type}&idx=${user.idx}&grade=${user.grade}`,
    user.isRefresh
      ? {
          revalidateOnMount: true,
          refreshInterval: Number(user.refreshPeriod) * 1000,
          revalidateOnFocus: false,
        }
      : {
          revalidateOnMount: true,
          revalidateOnFocus: false,
        }
  );

  const winSize = window.innerWidth;

  const [isOpen, setIsOpen] = useState(false);
  const manualDownload = (type) => {
    if (type === "MB") {
      window.open("../../uploads/manual/klbs_mob_manual.pdf", "_blank");
    }
    if (type === "PC") {
      window.open("../../uploads/manual/klbs_pc_manual.pdf", "_blank");
    }
    if (type === "FAQ") {
      window.open("/FAQ", "_blank");
    }
  };

  const statsData = [
    {
      titleKR: "전체",
      titleEN: "Total",
      value: count.all === undefined ? "0" : count.all,
      classN: "total",
    },
    {
      titleKR: "주행",
      titleEN: "Driving",
      value: count.drive === undefined ? "0" : count.drive,
      classN: "driving",
    },
    {
      titleKR: "정차",
      titleEN: "Pause",
      value: count.pause === undefined ? "0" : count.pause,
      classN: "stop",
    },
    {
      titleKR: "종료",
      titleEN: "End",
      value: count.end === undefined ? "0" : count.end,
      classN: "end",
    },
  ];

  function managerLink(user) {
    if (user.auth.E100 !== "Y") {
      return "/Mng/Agent";
    } else if (user.auth.E200 !== "Y") {
    }
  }

  const GNBData = [
    {
      userPermit: user.auth.A000,
      titleKR: "위치관제",
      titleEN: "Map",
      link: "/Map",
      icon: "ri-compass-discover-fill",
    },
    {
      userPermit: user.auth.B000,
      titleKR: "단말일지",
      titleEN: "Device",
      link: "/Dlog",
      icon: "ri-calendar-line",
    },
    {
      userPermit: user.auth.C000,
      titleKR: "차량일지",
      titleEN: "Vehicle",
      link: "/Vlog",
      icon: "ri-calendar-1-line",
    },
    {
      userPermit: user.auth.F000,
      titleKR: "단말관리",
      titleEN: "Terminal",
      link: "/Trm",
      icon: "ri-cpu-line",
    },

    {
      userPermit: user.auth.D000,
      titleKR: "대시보드",
      titleEN: "Board",
      link: "/Das",
      icon: "ri-pie-chart-line",
    },
    {
      userPermit: user.auth.E000,
      titleKR: "조직관리",
      titleEN: "Org.",
      link: user.auth.E100 === "Y" ? "/Mng" : (user.auth.E200 === "Y" ? "/Mng/Agent" : "/Mng/User"),
      // link: "/Mng",
      icon: "ri-organization-chart",
    },
    {
      userPermit: user.auth.G000,
      titleKR: "환경설정",
      titleEN: "Settings",
      link: "/Set",
      icon: "ri-settings-2-line",
    },
    {
      userPermit: user.auth.H000,
      titleKR: "미관제조회",
      titleEN: "Unreported",
      link: "/Lost",
      icon: "ri-signal-wifi-off-line",
    },
    {
      userPermit: user.auth.I000,
      titleKR: "판매관리",
      titleEN: "Sale",
      link: "/Order",
      icon: "ri-file-list-line",
    },
    {
      userPermit: user.auth.J000,
      titleKR: "관리자",
      titleEN: "Admin",
      link: "/Adm/o-table",
      icon: "ri-lock-2-fill",
    },
  ];
  function toggleModal() {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    if (!isLoading && data) {
      refreshCount(data);
    }
  }, [data, isLoading]);

  function adminLogin(e) {
    handleChangeUser(e);
  }
  useEffect(() => {
    if (user && props.pages !== "1") {
      // make to much refreshes
      startRefresh();
    }
    document.querySelector("html").classList.remove("full-mode");
  }, []);

  return (
    <>
      <header
        className="app-header"
        style={{
          zIndex: "1000",
        }}
      >
        <div className="app-inner">
          <div className="header-left">
            <h1 className="app-logo">
              <a href="/Map">
                <img
                  src="../../../assets/img/header/logo-klbs.svg"
                  alt="klbs"
                />
              </a>
            </h1>

            {/*<!-- S: status 전체/주행/정차/종료 -->*/}
            <div className="app-status">
              {statsData.map((data, index) => (
                <Stats
                  key={index}
                  titleKR={data.titleKR}
                  titleEN={data.titleEN}
                  value={data.value}
                  classN={data.classN}
                  language={language}
                />
              ))}
            </div>
            {/*<!-- E: status 전체/주행/정차/종료 -->*/}
          </div>

          {/*<!-- S: GNB -->*/}
          <nav className="global-nav">
            <ul className="gnb-list">
              {GNBData.filter((data) => data.userPermit === "Y").map(
                (data, index) => (
                  <GNB
                    key={index}
                    labelKr={data.titleKR}
                    labelEn={data.titleEN}
                    link={data.link}
                    icon={data.icon}
                    pages={props.pages}
                    language={language}
                  />
                )
              )}
            </ul>
          </nav>
          {/*<!-- E: GNB -->*/}

          {/*<!-- S: UTIL -->*/}
          <div className="header-util">
            <ul className="util-list">
              <li className="util-item user">
                <UserInfro user={user} />
              </li>
              <li
                className="util-item"
                style={
                  user.adm_flag === "Y"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <div
                  className=" util-btn cursor"
                  onClick={toggleModal}
                  title="조직검색 팝업 열기"
                >
                  {language === "KOR" ? "조직검색" : "Org change"}
                </div>
              </li>
              <li className="util-item">
                <SubMenu
                  language={language}
                  titleKR={"매뉴얼"}
                  titleNG={"Manual"}
                  clickFun={manualDownload}
                  subMenu={[
                    {
                      titleKR: "모바일 매뉴얼 다운로드",
                      titleEN: "Mobile manual download",
                      type: "MB",
                    },
                    {
                      titleKR: "PC 매뉴얼 다운로드",
                      titleEN: "PC manual download",
                      type: "PC",
                    },
                    {
                      titleKR: "FAQ",
                      titleEN: "FAQ",
                      type: "FAQ",
                    },
                  ]}
                />
              </li>
              <li className="util-item">
                <div className={`util-btn more cursor`}>
                  {language === "KOR" ? `KOR` : `ENG`}
                </div>
                <div className="sub-menu">
                  <div className="sub-menu-item cursor">
                    <div
                      className="menu-btn cursor"
                      onClick={(e) => changeLanguage(e)}
                    >
                      KOR
                    </div>
                  </div>
                  <div className="sub-menu-item cursor">
                    <div
                      className="menu-btn cursor"
                      onClick={(e) => changeLanguage(e)}
                    >
                      ENG
                    </div>
                  </div>
                </div>
              </li>

              <li className="util-item mobile">
                <div className={`util-btn more cursor`}>
                  {language === "KOR" ? `메뉴` : `Menu`}
                </div>
                <div className="sub-menu">
                  <div className="sub-menu-item cursor">
                    <div
                      className="menu-btn cursor"
                      onClick={(e) => changeLanguage(e)}
                    >
                      KOR
                    </div>
                  </div>
                  <div className="sub-menu-item cursor">
                    <div
                      className="menu-btn cursor"
                      onClick={(e) => changeLanguage(e)}
                    >
                      ENG
                    </div>
                  </div>
                  <div className="sub-menu-item cursor">
                    <div
                      className="menu-btn cursor"
                      onClick={(e) => manualDownload("MB")}
                    >
                      {language === "KOR" ? "모바일 매뉴얼 다운로드" : "Mobile"}
                    </div>
                  </div>
                  <div className="sub-menu-item cursor">
                    <div
                      className="menu-btn cursor"
                      onClick={(e) => manualDownload("PC")}
                    >
                      {language === "KOR" ? "PC 매뉴얼 다운로드" : "PC"}
                    </div>
                  </div>
                  <div className="sub-menu-item cursor">
                    <div
                      className="menu-btn cursor"
                      onClick={(e) => manualDownload("FAQ")}
                    >
                      {language === "KOR" ? "FAQ" : "FAQ"}
                    </div>
                  </div>
                </div>
              </li>
              <li className="util-item">
                <Link to="" className="util-btn" onClick={handleLogout}>
                  {language === "KOR" ? "로그아웃" : "Sign out"}
                </Link>
              </li>
            </ul>
          </div>
          {/*<!-- E: UTIL -->*/}
        </div>
      </header>
      {isOpen && (
        <Modal
          onClickFun={toggleModal}
          isOpen={isOpen}
          axios={axios}
          language={language}
          adminLogin={adminLogin}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
}
