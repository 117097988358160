import moment from "moment";
moment.locale("ko", {
  weekdaysShort: ["일", "월", "화", "수", "목", "금", "토"],
});

export default function WeekDays() {
  return (
    <>
      <tr className="calendar-weekdays">
        {moment.weekdaysShort().map((day, index) => (
          <th
            key={day}
            className={`calendar-weekday ${
              day === "일" ? "calendar-weekday-sun" : ""
            } ${day === "토" ? "calendar-weekday-sat" : ""}`}
          >
            {moment().day(index).format("ddd")}
          </th>
        ))}
      </tr>
    </>
  );
}
