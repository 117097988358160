import React, { useEffect, useRef, useState } from "react";
import axios from "../../../lib/axios.js";
import { ISODateToDateTime, add_hyphen } from "../../../lib/common_func";

import { useLocation, useNavigate } from "react-router-dom";
import CommonControl from "../../Atomic/organism/CommonControl";

import Grid from "@toast-ui/react-grid";
import TuiGrid from "tui-grid";
import tableStyle from "../../run/organism/RunStyledTable.jsx";
import VLogMain from "./VLogMain.jsx";
import { useLang } from "../../../context/LanguageContext.jsx";
import { useAuth } from "../../../context/LoginContext.jsx";
import moment from "moment";
import AddModal from "./AddModal.jsx";
import Swal from "sweetalert2";
import useExcelDown from "../../../hook/useExcelDown.jsx";
import VlogTaxExcel from "./VlogTaxExcel.jsx";
import VlogExcel from "./VlogExcel.jsx";
import FixModal from "./FixModal.jsx";
import Loading from "../../include/Loading.jsx";

export default function VLogDetail() {
  const { language } = useLang();
  const { user } = useAuth();

  const navi = useNavigate();
  const location = useLocation();
  const gridRef = useRef();
  const fromYear = useRef();
  const fromMonth = useRef();
  const toYear = useRef();
  const toMonth = useRef();
  const startRef = useRef();
  const endRef = useRef();
  const taxTableRef = useRef();
  const tableRef = useRef();

  // Hook
  TuiGrid.applyTheme(null, tableStyle);
  TuiGrid.setLanguage(language === "KOR" ? "ko" : 'en');  
  
  // State definition
  //==================================================================
  const today = moment().format("YYYY-MM");
  const month = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  let year = [];
  for (let i = 2022; i <= parseInt(today.substring(0, 4)); i++) {
    year.push(i);
  }
  const [data, setData] = React.useState([]);
  const [selectData, setSelectData] = useState();
  const [searchOption, setSearchOption] = useState({ from: today, to: today });
  const [isSearch, setIsSearch] = useState(true);
  const [fixModal, setFixModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [prevSelected, setPrevSelected] = useState(null);
  const addRef = useRef(null);

  // Function definition
  //==================================================================
  async function address(lat, lon) {
    const datasend = {
      latitude: lat,
      longitude: lon,
    };
    try {
      const response = await axios.post("/web/api/addr", datasend);
      return response.data.addr;
    } catch (err) {
      console.log(err);
      throw err; // Handle or propagate the error further as needed
    }
  }
  async function submitFun() {
    const prev = moment(`${fromYear?.current.value ?? today.substring(0, 4)}-${
      fromMonth?.current.value ?? today.substring(5, 7)}-01`);
    const after = moment(`${toYear?.current.value ?? today.substring(0, 4)}-${
      toMonth?.current.value ?? today.substring(5, 7)}-01`);
    if(prev <= after) {
      setData([]);
      setIsSearch((prev) => !prev);
      setSearchOption({
        from: `${fromYear?.current.value ?? today.substring(0, 4)}-${
          fromMonth?.current.value ?? today.substring(5, 7)
        }`,
        to: `${toYear?.current.value ?? today.substring(0, 4)}-${
          toMonth?.current.value ?? today.substring(5, 7)
        }`,
      });
    } else {
      Swal.fire({
        title: language === "KOR" ? "기간을 알맞게 선택해주세요" : "Please Select Appropriate Period"
      })
    }
  }
  function openFixModal() {
    setFixModal(true);
  }
  function closeFixModal() {
    setFixModal(false);
  }
  function openAddModal() {
    if(addRef.current) {
      addRef.current.classList.add('on');
    }
  }
  function closeAddModal() {
    if(addRef.current) {
      addRef.current.classList.remove('on');
      startRef.current.value = '';
      endRef.current.value = '';
    }
  }
  async function addLog(e) {
    e.preventDefault();
    Swal.fire({
      title: '합산하시겠습니까?\n합산 한 경우 복구가 불가능합니다',
      showCancelButton: true,
      confirmButtonText: '확인',
      cancelButtonText: '취소',
    }).then(async (res) => {
      if(res.isConfirmed) {
        const startNum = parseInt(startRef?.current.value);
        const endNum = parseInt(endRef?.current.value);
    
        if (Number.isNaN(startNum) || Number.isNaN(endNum)) {
          Swal.fire({
            title: language === "KOR" ? "숫자를 입력해주세요" : "",
          });
          return;
        }
        if (startNum < 1 || endNum < 1) {
          Swal.fire({
            title: "존재하지 않는 번호가 있습니다",
          });
          return;
        }
        if (startNum >= endNum) {
          Swal.fire({
            title: "시작번호가 더 낮아야 합니다",
          });
          return;
        }
        if (data.length <= startNum || data.length < endNum) {
          Swal.fire({
            title: "존재하지 않는 번호가 있습니다",
          });
          return;
        }
        if (user.org_idx == 1350) {
          Swal.fire({
            title: "데모체험에서는 제공되지 않는 기능입니다",
          });
          return;
        }
        await axios
          .post(`/web/driving/data/sum`, {
            unitid: location?.state?.unitid,
            type: user.type,
            org_idx: user.org_idx,
            from: searchOption.from,
            to: searchOption.to,
            startNo: startNum,
            endNo: endNum,
            startIdx: data[data.length - startNum].idx,
            endIdx: data[data.length - endNum].idx,
          })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                title : '합산되었습니다',
                confirmButtonText : '확인',
              }).then((res) => {
                closeAddModal();
                setIsSearch((prev) => !prev);
              })
            } else {
              Swal.fire({
                title: "합산에 실패하였습니다",
              });
            }
          })
          .catch((res) => {
            Swal.fire({
              title: "합산에 실패하였습니다",
            });
          });
      }
    })
  }
  const move = (e) => {
    if (e.columnName === "btn1" && e.targetType != "columnHeader") {
      const i = e.instance.store.focus.rowIndex;
      const row = e.instance.store.data.rawData[i].row;

      setPrevSelected(i);
      setSelectData(data[data.length - row]);
      openFixModal();
    }
  };
  const { excelDown: taxExcelDown } = useExcelDown(
    taxTableRef,
    `운행기록_국세청용_${today} ${location.state?.unit_nm}`,
    `운행기록_국세청용_${today} ${location.state?.unit_nm}`,
    data
  );
  const { excelDown } = useExcelDown(
    tableRef,
    `운행기록_관리용_${today} ${location.state?.unit_nm}`,
    `운행기록_관리용_${today} ${location.state?.unit_nm}`,
    data
  );
  const customExcelDown = () => {
    data.map((item) => {
      if (!item.st_addr || !item.ed_addr) {
        Swal.fire({
          title: "주소 데이터를 로딩중입니다. 잠시 후 다시 시도해주세요.",
        });
        return;
      }
    });
    excelDown();
  };

  // UseEffect definition
  //==================================================================
  useEffect(() => {
    setIsLoading(true);
    axios
      .post(`/web/driving/detail`, {
        type: user.type,
        org_idx: user.org_idx,
        unitid: location?.state?.unitid,
        from: searchOption.from,
        to: searchOption.to,
      })
      .then(async (res) => {
        if (res.data) {
          const newData = await Promise.all(
            res.data.map(async (item) => {
              if (item.st_addr) {
              } else {
                item.st_addr = await address(item.st_lat, item.st_lon);
              }
              if (item.ed_addr) {
              } else {
                item.ed_addr = await address(item.ed_lat, item.ed_lon);
              }
              return item;
            })
          );
          setIsLoading(false);
          setData(newData);
        } else {
          setIsLoading(false);
          setData([]);
        }
      });
  }, [user, searchOption, isSearch]);
  
  // Data definition
  //==================================================================
  class CustomColumnHeader {
    constructor(props) {
      const columnInfo = props.columnInfo;
      const el = document.createElement("div");
      let html = "";

      for (const ele of columnInfo.header) {
        html += `${ele}</br>`;
      }

      el.innerHTML = html.slice(0, -5);
      this.el = el;
    }

    getElement() {
      return this.el;
    }

    render(props) {
      //this.el.innerHTML = props.columnInfo.header;
    }
  }
  class RowNumRenderer {
    constructor(props) {
      const el = document.createElement("span");
      el.innerHTML = props.grid.getRowCount() - props.formattedValue + 1;
      this.el = el;
    }

    getElement() {
      return this.el;
    }

    render(props) {
      this.el.innerHTML = props.grid.getRowCount() - props.formattedValue + 1;
    }
  }
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "Log list",
      classN: "purple",
      clickFun: () => {
        navi("/Vlog");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "합산",
      labelEN: "View all",
      classN: "red",
      clickFun: () => {
        openAddModal();
      },
      position: "",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "국세청 양식 다운로드",
      labelEN: "View all",
      classN: "purple",
      clickFun: () => {
        taxExcelDown();
      },
      position: "",
      icon: false,
      iconClass: "",
    }
  ];
  if((user.org_cd === '1905' || user.org_cd === '4371') && user.grade === 'FDV') {
  } else {
    buttonData.push({
      labelKR: "관리 데이터 다운로드",
      labelEN: "View all",
      classN: "purple",
      clickFun: () => {
        customExcelDown();
      },
      position: "",
      icon: false,
      iconClass: "",
    })
  }

  const header = {
    //tui grid
    height: 50,
    columns: [
      {
        name: "st_mileage",
        renderer: CustomColumnHeader,
      },
      {
        name: "ed_mileage",
        renderer: CustomColumnHeader,
      },
      {
        name: "dist",
        renderer: CustomColumnHeader,
      },
      {
        name: "d_commute",
        renderer: CustomColumnHeader,
      },
      {
        name: "d_general",
        renderer: CustomColumnHeader,
      },
      {
        name: "gas_costs",
        renderer: CustomColumnHeader,
      },
    ],
  };
  const [columns, setColumns] = useState([
    {
      header: language === "KOR" ? "운전자명" : "User",
      name: "u_nm",
      align: "center",
      resizable: true,
      ellipsis: true,
      width: 80,
    },
    {
      header: language === "KOR" ? "부서" : "Depart.",
      name: "department",
      align: "center",
      resizable: true,
      ellipsis: true,
      width: 80,
    },
    {
      header: language === "KOR" ? "주행 시작시간" : "Today(km)",
      name: "st_time",
      align: "center",
      resizable: true,
      ellipsis: true,
      width: 160,
      formatter: (item) =>
        item.value === null ? "" : ISODateToDateTime(item.value),
    },
    {
      header: language === "KOR" ? "주행 종료시간" : "Total(km)",
      name: "ed_time",
      align: "center",
      resizable: true,
      ellipsis: true,
      width: 160,
      formatter: (item) =>
        item.value === null ? "" : ISODateToDateTime(item.value),
    },
    {
      header:
        language === "KOR" ? ["주행 전 계기판의", "거리(km)"] : ["Start Mileage", "(km)"],
      name: "st_mileage",
      align: "center",
      resizable: true,
      width: 120,
      formatter: (item) => (item.value ? parseFloat(item.value).toFixed(2) : 0) + "km",
    },
    {
      header:
        language === "KOR" ? ["주행 후 계기판의", "거리(km)"] : ["End Mileage", "(km)"],
      name: "ed_mileage",
      align: "center",
      resizable: true,
      width: 120,
      formatter: (item) => (item.value ? parseFloat(item.value).toFixed(2) : 0) + "km",
    },
    {
      header: language === "KOR" ? ["주행거리", "(km)"] : ["Dist(km)"],
      name: "dist",
      align: "center",
      resizable: true,
      width: 80,
      formatter: (item) => (item.value ? parseFloat(item.value).toFixed(2) : 0) + "km",
    },
    {
      header: language === "KOR" ? ["출퇴근용", "(km)"] : ["Commute", "(km)"],
      name: "d_commute",
      align: "center",
      resizable: true,
      width: 80,
      formatter: (item) => (item.value ? parseFloat(item.value).toFixed(2) : 0) + "km",
    },
    {
      header: language === "KOR" ? ["일반업무용", "(km)"] : ["Business" ,"(km)"],
      name: "d_general",
      align: "center",
      resizable: true,
      width: 90,
      formatter: (item) => (item.value ? parseFloat(item.value).toFixed(2) : 0) + "km",
    },
    {
      header: language === "KOR" ? ["주유", "여부"] : ["Gas"],
      name: "gas_costs",
      align: "center",
      resizable: true,
      width: 50,
      formatter: (item) =>
        item.value > 0
          ? `<div style="color:red;">
                O
            </div>`
          : `<div style="color:blue;">
                X
            </div>`,
    },
    {
      header: language === "KOR" ? "비고" : "Memo",
      name: "memo",
      align: "center",
      resizable: true,
      ellipsis: true,
    },
    {
      header: language === "KOR" ? "상세" : "Detail",
      name: "btn1",
      align: "center",
      width: 60,
      formatter: () =>
        `<div class='link cursor'>
                ${language === "KOR" ? "수정" : "Edit"}
            </div>`,
    },
  ]);
  const rowHeaders = {
    type: "rowNum",
    renderer: {
      type: RowNumRenderer,
    },
  };
  const bodyHeight = data.length < 10 ? data.length * 50 : 430;

  useEffect(() => {
    setColumns([
      {
        header: language === "KOR" ? "운전자명" : "User",
        name: "u_nm",
        align: "center",
        resizable: true,
        ellipsis: true,
        width: 80,
      },
      {
        header: language === "KOR" ? "부서" : "Depart.",
        name: "department",
        align: "center",
        resizable: true,
        ellipsis: true,
        width: 80,
      },
      {
        header: language === "KOR" ? "주행 시작시간" : "Today(km)",
        name: "st_time",
        align: "center",
        resizable: true,
        ellipsis: true,
        width: 160,
        formatter: (item) =>
          item.value === null ? "" : ISODateToDateTime(item.value),
      },
      {
        header: language === "KOR" ? "주행 종료시간" : "Total(km)",
        name: "ed_time",
        align: "center",
        resizable: true,
        ellipsis: true,
        width: 160,
        formatter: (item) =>
          item.value === null ? "" : ISODateToDateTime(item.value),
      },
      {
        header:
          language === "KOR" ? ["주행 전 계기판의", "거리(km)"] : ["Start Mileage", "(km)"],
        name: "st_mileage",
        align: "center",
        resizable: true,
        width: 120,
        formatter: (item) => (item.value ? parseFloat(item.value).toFixed(2) : 0) + "km",
      },
      {
        header:
          language === "KOR" ? ["주행 후 계기판의", "거리(km)"] : ["End Mileage", "(km)"],
        name: "ed_mileage",
        align: "center",
        resizable: true,
        width: 120,
        formatter: (item) => (item.value ? parseFloat(item.value).toFixed(2) : 0) + "km",
      },
      {
        header: language === "KOR" ? ["주행거리", "(km)"] : ["Dist(km)"],
        name: "dist",
        align: "center",
        resizable: true,
        width: 80,
        formatter: (item) => (item.value ? parseFloat(item.value).toFixed(2) : 0) + "km",
      },
      {
        header: language === "KOR" ? ["출퇴근용", "(km)"] : ["Commute", "(km)"],
        name: "d_commute",
        align: "center",
        resizable: true,
        width: 80,
        formatter: (item) => (item.value ? parseFloat(item.value).toFixed(2) : 0) + "km",
      },
      {
        header: language === "KOR" ? ["일반업무용", "(km)"] : ["Business" ,"(km)"],
        name: "d_general",
        align: "center",
        resizable: true,
        width: 90,
        formatter: (item) => (item.value ? parseFloat(item.value).toFixed(2) : 0) + "km",
      },
      {
        header: language === "KOR" ? ["주유", "여부"] : ["Gas"],
        name: "gas_costs",
        align: "center",
        resizable: true,
        width: 50,
        formatter: (item) =>
          item.value > 0
            ? `<div style="color:red;">
                  O
              </div>`
            : `<div style="color:blue;">
                  X
              </div>`,
      },
      {
        header: language === "KOR" ? "비고" : "Memo",
        name: "memo",
        align: "center",
        resizable: true,
        ellipsis: true,
      },
      {
        header: language === "KOR" ? "상세" : "Detail",
        name: "btn1",
        align: "center",
        width: 60,
        formatter: () =>
          `<div class='link cursor'>
                  ${language === "KOR" ? "수정" : "Edit"}
              </div>`,
      },
    ])
  }, [language])

  useEffect(() => {
    if(gridRef?.current && prevSelected) {
      gridRef.current.getInstance().focusAt(prevSelected, 0, true)
    }
  }, [gridRef?.current, prevSelected])

  return (
    <>
      <VLogMain>
        <div className="common-table-wrapper">
          <div className="search-bar between">
            <div className="title">
              <span className="date">
                {`${searchOption.from} ~ ${searchOption.to}`}
              </span>
              <span className="space-2">
                {`${location?.state?.unit_nm} : `}
              </span>
              <span className="unitid">
                {add_hyphen(location?.state?.unitid)}
              </span>
              <span className="space-1">운행기록</span>
            </div>
            <div className="search-bar">
              <div className="custom-group noflex">
                <div className="custom-select">
                  <select
                    ref={fromYear}
                    defaultValue={parseInt(searchOption.from.substring(0, 4))}
                  >
                    {year.map((item) => (
                      <option value={item}>{item}년</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="custom-group noflex">
                <div className="custom-select">
                  <select
                    ref={fromMonth}
                    defaultValue={searchOption.from.substring(5, 7)}
                  >
                    {month.map((item) => (
                      <option value={item}>{parseInt(item)}월</option>
                    ))}
                  </select>
                </div>
              </div>
              <span style={{ lineHeight: "4.8rem" }}>~</span>
              <div className="custom-group noflex">
                <div className="custom-select">
                  <select
                    ref={toYear}
                    defaultValue={parseInt(searchOption.to.substring(0, 4))}
                  >
                    {year.map((item) => (
                      <option value={item}>{item}년</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="custom-group noflex">
                <div className="custom-select">
                  <select
                    ref={toMonth}
                    defaultValue={searchOption.to.substring(5, 7)}
                  >
                    {month.map((item) => (
                      <option value={item}>{parseInt(item)}월</option>
                    ))}
                  </select>
                </div>
              </div>
              <button type="button" className="submit-btn" onClick={submitFun}>
                {language === "KOR" ? "검색" : "Search"}
              </button>
            </div>
          </div>
          <div className="common-grid">
            {isLoading ? 
            (
              <Loading />
            ) : 
            (
              <Grid
                ref={gridRef}
                data={data}
                columns={columns}
                rowHeight={50}
                rowHeaders={[rowHeaders]}
                scrollX={true}
                scrollY={true}
                bodyHeight={bodyHeight}
                header={header}
                onClick={move}
              />
            )
            }
          </div>
        </div>
        <CommonControl buttonData={buttonData} language={language} />
        <AddModal
          start={startRef}
          end={endRef}
          addLog={addLog}
          closeAddModal={closeAddModal}
          addRef={addRef}
        />
        {fixModal && (
          <FixModal
            data={selectData}
            searchOption={searchOption}
            unit_nm={location?.state?.unit_nm}
            unitid={location?.state?.unitid}
            closeModal={closeFixModal}
            search={setIsSearch}
          />
        )}
        <VlogTaxExcel
          table={taxTableRef}
          data={data}
          searchOption={searchOption}
          unit_nm={location?.state?.unit_nm}
        />
        <VlogExcel
          table={tableRef}
          data={data}
          searchOption={searchOption}
          unit_nm={location?.state?.unit_nm}
          unitid={location?.state?.unitid}
        />
      </VLogMain>
    </>
  );
}
