import React from "react";
interface Props {
  icon: string;
}
export default function Icon({ icon }: Props) {
  return (
    <>
      <div className="das-icon-large">
        <i className={icon}>
          <img src={`../../../assets/img/dashboard/${icon}.svg`} alt="Icon" />
        </i>
      </div>
    </>
  );
}
