import React, { useRef } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { useContext } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import DatePicker from "vanillajs-datepicker/Datepicker";
import "../../../assets/css/style.min.css";
import "../../../assets/lib/datepicker/datepicker.min.css";
import { Link } from "react-router-dom";
import DayTable from "../organism/DayTable";
import useFetch from "../../../hook/useFetch";
import AppHeader from "../../include/AppHeader";
import AppFooter from "../../include/AppFooter";
import axios from "../../../lib/axios";

import Nav from "../../Atomic/organism/Nav";
import moment from "moment";
import useExcelDown from "../../../hook/useExcelDown";
import { add_hyphen } from "../../../lib/common_func";
import { useAuth } from "../../../context/LoginContext";
import { dataList } from "./DeviceLogMain";
import { useInView } from "react-intersection-observer";
import Swal from "sweetalert2";
function DrivingDay() {
  // context state
  const { language } = useContext(LanguageContext);
  const { user } = useAuth();

  // variable
  const today = moment().format("YYYY-MM-DD"); // 오늘 날짜
  const renderCount = user.render_cnt ?? 20;
  const updateCount = 500;
  const tableRef = useRef(); // 스크롤 타겟
  const pickerRef = useRef(); // 캘린더
  const excelRef = useRef(); // 엑셀 테이블
  const countRef = useRef(); // count 확인 in eventListener
  const rCountRef = useRef();

  // state
  const [unitid, setUnitid] = useState();
  const [unit_nm, setUnit_nm] = useState();
  const [date, setDate] = useState(null);
  const [data, setData] = useState(null); // 10개씩 랜더링 해 줄 데이터
  const [count, setCount] = useState(0); // 스크롤 카운트
  const [sort, setSort] = useState(true); // 정렬
  const [drivingDay, setDrivingDay] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [totalDist, setTotalDist] = useState(0);
  const [isSearch, setIsSearch] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);
  const [ref, inView] = useInView();
  const [hasNext, setHasNext] = useState(true);

  // Hook
  const location = useLocation();

  const url =
    date &&
    `/web/device/log/daily?unitid=${
      location.state?.unitid || unitid
    }&date=${date}&search=${isSearch}`;
  const { data: initData, isLoading } = useFetch(url);
  const { excelDown } = useExcelDown(
    excelRef,
    `Device_log_Daily_${today} ${location.state?.unit_nm || unit_nm}`,
    `Device_log_Daily_${today} ${location.state?.unit_nm || unit_nm}`,
    initData,
    initData?.length === totalData?.length
  );
  function customExcelDown() {
    if (!excelLoading && excelData?.length === totalData.length) {
      excelDown();
    } else if (excelLoading) {
      Swal.fire({
        title : language === "KOR" ? "데이터 로드중입니다." : "Data Loading..."
      });
    } else {
      Swal.fire({
        title : language === "KOR" ? "데이터를 로딩합니다." : 'Start Loading Data'
      })
      setExcelLoading(true);
    }
  }

  // calendar
  useEffect(() => {
    if (drivingDay) {
      // datePicker language
      DatePicker.locales.ko = {
        days: [
          "일요일",
          "월요일",
          "화요일",
          "수요일",
          "목요일",
          "금요일",
          "토요일",
        ],
        daysShort: ["일", "월", "화", "수", "목", "금", "토"],
        daysMin: ["일", "월", "화", "수", "목", "금", "토"],
        months: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        monthsShort: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        today: "오늘",
        clear: "삭제",
        format: "yyyy-mm-dd",
        titleFormat: "yyyy년mm월",
        weekStart: 0,
      };

      const customPicker_s = new DatePicker(pickerRef.current, {
        language: language === "KOR" ? "ko" : "en",
        autohide: true,
        todayBtn: true,
        todayBtnMode: 0,
        format: "yyyy-mm-dd",
        beforeShowDay:
          drivingDay &&
          function (date) {
            const _date = new Intl.DateTimeFormat("fr-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(date);
            const hasData = drivingDay.includes(_date);

            return {
              classes: hasData ? "hasData" : "disabled",
            };
          },
      });
    }
  }, [language, drivingDay]);
  // location reset 방지 state 관리
  useEffect(() => {
    const ui =
      location.state?.unitid ||
      JSON.parse(sessionStorage.getItem("state")).unitid;
    const un =
      location.state?.unit_nm ||
      JSON.parse(sessionStorage.getItem("state")).unit_nm;

    setUnitid(ui);
    setUnit_nm(un);

    (async () => {
      try {
        const result = await axios.get(
          `/web/map/tracking/path/day?unitid=${ui}`
        );

        if (result.data === "") {
          setDrivingDay([]);
          setDate(today);
        } else {
          const drivingDayArr = result.data.map((item) => item.date);
          setDrivingDay(drivingDayArr);
          setDate(drivingDayArr[drivingDayArr.length - 1]);
        }
      } catch (error) {
        console.log("err : ", error);
      }
    })();

    const setState = () => {
      sessionStorage.setItem(
        "state",
        JSON.stringify({ unitid: ui, unit_nm: un })
      );
    };
    window.addEventListener("beforeunload", setState);

    return () => {
      window.removeEventListener("beforeunload", setState);
    };
  }, []);
  useEffect(() => {
    if(inView) {
      bottomCheck();
    }
  },[inView])
  useEffect(() => {
    if (isLoading || !date) {
      setTotalData(null);
      setTotalDist(0);
    } else {
      if (initData) {
        setTotalData(initData);
        setTotalDist(parseFloat(initData[0].sum_calc_dist).toFixed(2));
      } else {
        setTotalData([]);
        setTotalDist(0);
      }
    }
  }, [initData]);
  // 최초 렌더링 데이터 설정
  useEffect(() => {
    if (totalData?.length > 0) {
      fetchData(totalData.slice(0, data?.length || renderCount)).then((res) => {
        setData([...res]);
        setExcelData([...res]);
      });
    } else if (totalData?.length === 0) {
      setData([]);
    } else {
      setData(null);
    }
  }, [totalData]);
  // 스크롤 변화에 따른 데이터 설정
  useEffect(() => {
    if (totalData?.length > 0) {
      fetchData(
        totalData.slice(renderCount * count, renderCount * (1 + count))
      ).then((res) => {
          setData((prev) => [...prev, ...res]);
          if (!excelLoading && totalData.length !== data.length) {
            setExcelData((prev) => [...prev, ...res]);
          }
      });
    }
  }, [count]);
  // 정렬 변화에 따른 데이터 설정
  useEffect(() => {
    if (totalData?.length > 0) {
      setTotalData([...totalData.reverse()]);
    }
  }, [sort]);
  useEffect(() => {
    if (unitid) {
      sessionStorage.removeItem("state");
    }
  }, [unitid]);
  // 엑셀데이터 주소변환
  useEffect(() => {
    if (excelLoading) {
      if (excelData.length !== totalData.length) {
        fetchData(
          totalData.slice(excelData.length, excelData.length + updateCount)
        ).then((res) => setExcelData((prev) => [...prev, ...res]));
      } else {
        Swal.fire({
          title : language === "KOR" ? "데이터 로딩을 완료하였습니다." : "Data loading Complete"
        });
        setExcelLoading(false);
        excelDown();
      }
    }
  }, [excelData, excelLoading]);

  async function address(lat, lon) {
    const datasend = {
      latitude: lat,
      longitude: lon,
    };
    try {
      const response = await axios.post("/web/api/addr", datasend);
      return response.data.addr;
    } catch (err) {
      console.log(err);
      throw err; // Handle or propagate the error further as needed
    }
  }
  const fetchData = async (d) => {
    if (d.length > 0) {
      const newData = await Promise.all(
        d.map(async (item) => {
          if (item.addr) {
          } else {
            item.addr = await address(item.lat, item.lon);
          }
          return item;
        })
      );
      return newData;
    }
  };
  const submitHandler = (e) => {
    // Make the function asynchronous
    e.preventDefault();
    setDate(pickerRef.current.value);
    setIsSearch((prev) => !prev);
    setTotalData(null);
    setData(null);
    setTotalDist(0);
    setCount(0);
  };
  const bottomCheck = () => {
    // 데이터 추가 가능 확인
    if (
      countRef.current.value < Math.ceil(rCountRef.current.value / renderCount) - 1
    ) {
      setHasNext(true);
      setCount((prev) => prev + 1);
    } else {
      setHasNext(false);
    }
  };
  const changeSort = () => {
    // 정렬 확인
    setSort((prev) => !prev);
  };

  return (
    <>
      <AppHeader />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Dlog"} language={language} dataList={dataList} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR"
                    ? "단말일지 (일별)"
                    : "Driving log (daily)"}
                </div>
                <input
                  style={{ display: "none" }}
                  ref={countRef}
                  value={count}
                />
                <input
                  style={{ display: "none" }}
                  ref={rCountRef}
                  value={initData?.length}
                />
              </div>
              <div className="sub-page-body">
                <div className="sub-page-notice">
                  <ul className="notice-list">
                    <li className="list-item">
                      {language === "KOR"
                        ? `KEY ON 날짜기준으로 운행거리,
                                            운행시간을 집계하기 때문에 실제 일별
                                            총 운행거리, 총 운행시간과는 다를 수
                                            있습니다.`
                        : `The mileage and hours of operation are counted based on the KEYON date,
                                            so it may be defferent from the actual total daily mileage and total hours of operation.`}
                    </li>
                    <li className="list-item">
                      {language === "KOR"
                        ? `KEY ON 정보가 정상적으로 수신되지
                                            않는 차량일 경우에도 정보가 표시되지
                                            않습니다.`
                        : `Information will not be displayed even if the Signal is not received properly.`}
                    </li>
                  </ul>
                </div>
                <form className="search-bar" onSubmit={submitHandler}>
                  <div className="period-group">
                    <div className="custom-datepicker">
                      <input
                        ref={pickerRef}
                        type="text"
                        value={date || today}
                        readOnly
                        className="datepicker-input"
                      />
                    </div>
                  </div>
                  <button type="submit" className="submit-btn">
                    {language === "KOR" ? "검색" : "Search"}
                  </button>
                </form>
                <div className="common-table-wrapper">
                  <div className="common-control">
                    <div className="left">
                      <div className="period">
                        <span className="date">{date || today}</span>
                      </div>
                      <div className="info">
                        <span className="label">{unit_nm || ""}</span>
                        <span className="label emp">
                          {unitid && add_hyphen(unitid)}
                        </span>
                      </div>
                      <div className="info">
                        <span className="label">
                          {language === "KOR" ? "운행기록" : "Driving log"}
                        </span>
                        <span>
                          {language === "KOR"
                            ? `(총 운행거리 : ${totalDist} km)`
                            : `(Total : ${totalDist} km)`}
                        </span>
                      </div>
                    </div>
                    <div className="right">
                      <div className="custom-radio-group">
                        <label className="custom-radio">
                          <input
                            type="radio"
                            name="filter"
                            onChange={changeSort}
                            checked={!sort}
                          />
                          <div className="label">
                            {language === "KOR" ? "오름차순" : "Ascend"}
                          </div>
                        </label>
                        <label className="custom-radio">
                          <input
                            type="radio"
                            name="filter"
                            onChange={changeSort}
                            checked={sort}
                          />
                          <div className="label">
                            {language === "KOR" ? "내림차순" : "Descend"}
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <DayTable
                    data={data}
                    excelData={excelData}
                    totalCount={initData?.length}
                    sort={sort}
                    tableRef={tableRef}
                    excelRef={excelRef}
                    date={date}
                    unitid={unitid}
                    unit_nm={unit_nm}
                    totalDist={totalDist}
                    excelLoading={excelLoading}
                    viewRef={ref}
                    hasNext={hasNext}
                  />
                  <div className="common-control">
                    <div className="left"></div>
                    <div className="right">
                      <Link
                        to=""
                        className="common-btn white excel"
                        onClick={customExcelDown}
                      >
                        <i className="ri-file-excel-2-fill icon"></i>
                        <span>
                          {language === "KOR"
                            ? "엑셀 다운로드"
                            : "Excel download"}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
}
export default DrivingDay;
