import React, { useState, useEffect } from "react";
//@ts-ignore
import { LanguageContext } from "../../../../../context/LanguageContext.jsx";
//@ts-ignore
import { dataList } from "../../../AdmMain.jsx";
import Page from "../../../../Atomic/template/PageTable";
import CommonControl from "../../../../Atomic/organism/CommonControl";
import SubNavi from "../../../../Atomic/molecules/SubNavi";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import axios from "../../../../../lib/axios";
//@ts-ignore
import { useAuth } from "../../../../../context/LoginContext";
//@ts-ignore
import TableAtom from "../../../../Atomic/organism/AtomTable";
import { filterData, inputChnage } from "../../../../Atomic/exFunc";
import Form from "../../../../Atomic/organism/FormAtom";
//@ts-ignore
import { subGadjet } from "../../../AdmMain.jsx";
// @ts-ignore
import moment from "moment";
export default function Table() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const navi = useNavigate();
  const [rawData, setRawData] = useState<any>([]);
  const { user } = useAuth();
  const inputRef = React.createRef<HTMLInputElement>();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState<any>([]);

  // Function defines
  //===============================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //===============================================================

  function move(e: any) {
    const i = e.instance.store.focus.rowIndex;
    if (i === undefined) {
      return;
    }
    const idx = e.instance.store.data.rawData[i].idx;
    const unitid = e.instance.store.data.rawData[i].unitid;
    if (e.columnName !== "sms_cnt" && e.targetType != "columnHeader") {
      navi(`/Adm/item-table/edit`, {
        state: {
          idx: idx,
          unitid: unitid,
        },
      });
    }
  }

  function submitFun(e: any) {
    e.preventDefault();
    filterData(rawData, keyword, setData);
  }

  // Data define
  //===============================================================

  const columns: any = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },
    {
      header: translate("제품명", ""),
      name: "model_nm",
      align: "center",
    },
    {
      header: translate("기자재 명칭", ""),
      name: "prod_nm",
      align: "center",
    },
    {
      header: translate("모델명", ""),
      name: "prod_legal_nm",
      align: "center",
    },
    {
      header: translate("인증번호	", ""),
      name: "kc_num",
      align: "center",
    },
    {
      header: translate("거래처		", ""),
      name: "made_company",
      align: "center",
    },
    {
      header: translate("제조년월		", ""),
      name: "prod_date",
      align: "center",
      formatter: (name: any) => {
        return moment.utc(name.row.prod_date).format("YYYY-MM-DD");
      },
    },
    {
      header: translate("등록일		", ""),
      name: "makedate",
      align: "center",
    },
    {
      header: translate("사용여부		", ""),
      name: "act_yn",
      align: "center",
    },
  ];

  const buttonData = [
    {
      labelKR: "전체목록",
      labelEN: "View All",
      classN: "white",
      clickFun: () => {
        setKeyword("");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "등록",
      labelEN: "Add new",
      classN: "purple",
      icon: true,
      clickFun: () => {
        navi("/Adm/item-table/new");
      },
      position: "right",
    },
  ];

  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e: any) => inputChnage(e, rawData, setData, setKeyword),
    myref: inputRef,
    option: undefined, // Make option undefined
    value: keyword,
  };

  // useEffect define
  //===============================================================

  useEffect(() => {
    axios
      .get(`/web/admin/prod/list?type=${user.type}`)
      .then((res: any) => {
        setRawData(res.data);
      });
  }, [user]);

  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
      filterData(rawData, keyword, setData);
    }
  }, [rawData, keyword]);

  // logs define
  //===============================================================
  // console.log("smsSent", smsCnt);
  // console.log("smsModalUser", user);
  // console.log("rawData", rawData);
  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "단말기 관리" : "Gadget management"}`}
        menu="/Adm/dev-table"
      >
        <SubNavi
          subDataList={subGadjet}
          language={language}
          navi={navi}
          classPath="/Adm/item-table"
        />
        <Form formData={formData} inputRef={inputRef} />
        <TableAtom data={data} columns={columns} move={move} />
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
