import React from "react";
import Button from "../atoms/Button";
interface Props {
  language: string;
  buttonData: Array<any>;
}

export default function CommonControl({ language, buttonData }: Props) {
  const style: any = {}

  return (
    <>
      <div className="common-control">
        {buttonData.map((data, index) => {
          return (
            <div key={index} className={data.position}>
              <Button
                style={style}
                language={language}
                labelKR={data.labelKR}
                labelEN={data.labelEN}
                classN={data.classN}
                icon={data.icon}
                iconClass={data.iconClass}
                clickFun={data.clickFun}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}
