import PropTypes from "prop-types";
import ButtonAdd from "../atom/AtomBTN";
import { ISODateToDate } from "../../../../../lib/common_func";
import { getIconNumber } from "../../../../../lib/mapHelper";

export default function EventListBox({
  selectedDate,
  eventList,
  todayDate,
  navigate,
  lang,
  setIdx,
  setIsOn,
  setIsAdd,
}) {
  // Filter events that match the selected date
  const eventsOnSelectedDate = eventList.filter(
    (event) => event.fromDate <= selectedDate && event.toDate >= selectedDate
  );

  const addEvent = () => {
    setIdx(null);
    setIsAdd(true);
    setIsOn(false);
  };
  const editEvent = (idx) => {
    setIdx(idx);
    setIsOn(true);
    setIsAdd(false);
  };

  console.log(eventsOnSelectedDate);
  return (
    <>
      <div className="date-box">
        <div className="date-header">
          <span className="event-couner">
            {!selectedDate ? todayDate : selectedDate}
          </span>
          <span className="event-couner">
            {lang === "KOR" ? "이벤트" : "Events"} {eventsOnSelectedDate.length}
          </span>
        </div>
        <div className="date-body">
          <ul className="list">
            {eventsOnSelectedDate.map((event, index) => (
              <li
                className="item terminal"
                key={index}
                id={`${index}`}
                onClick={() => editEvent(event.idx)}
              >
                <div className="event-box">
                  <div className="icon-event-list custom-check">
                    <div className={`label-icon ${event.color}`}>
                      <img
                        src={`../../../assets/img/Calendar/${getIconNumber(
                          event.icon_code
                        )}.svg`}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="event-color-line">|</div>
                  <div className="m-event-body">
                    <div className="event-header">
                      <span>{event.title}</span>
                    </div>
                    <div className="m-event-body">
                      <span>
                        {ISODateToDate(event.fromDate)} -{" "}
                        {ISODateToDate(event.toDate)}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="date-footer  flex">
          <div className="">
            <div className="input-group-row">
              <ButtonAdd
                classN="btn-add-event"
                onClick={() =>
                  addEvent(!selectedDate ? todayDate : selectedDate)
                }
              >
                +
              </ButtonAdd>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

EventListBox.propTypes = {
  selectedDate: PropTypes.string,
  eventList: PropTypes.array,
  todayDate: PropTypes.string,
  navigate: PropTypes.func,
};
