import Button from "../atom/Button";
import HeaderTitle from "../molucule/HeaderTitle";
import PropTypes from "prop-types";
import { useState } from "react";

export default function HeaderCalendar({ onClickFun, year, month, lang }) {
  const [isOpen, setIsOpen] = useState(false);

  const clickHandlerTitle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="header-calendar">
      <HeaderTitle
        year={year}
        month={month}
        clickHandlerTitle={clickHandlerTitle}
        isOpen={isOpen}
        selectedDate={onClickFun.setDate}
        lang={lang}
      ></HeaderTitle>
      {!isOpen && (
        <div className="header-calendar-btn">
          <Button
            classN="prev-month calendar-btn"
            clickFun={onClickFun.prev}
            buttonName={"<"}
            colorBTN={"white"}
            type={"button"}
          />
          <Button
            classN="btn-today calendar-btn"
            clickFun={onClickFun.today}
            buttonName={lang === "KOR" ? "오늘" : "Today"}
            colorBTN={"white"}
            type={"button"}
          />
          <Button
            classN="next-month calendar-btn"
            clickFun={onClickFun.next}
            buttonName={">"}
            colorBTN={"white"}
            type={"button"}
          />
        </div>
      )}
    </div>
  );
}

HeaderCalendar.propTypes = {
  onClickFun: PropTypes.object,
  year: PropTypes.string,
  month: PropTypes.string,
};
