import React, { useEffect, useState } from "react";
import { useMap } from "../../context/MapContext";
import { ISODateToDateTime } from "../../lib/common_func";
import { event } from "../../lib/eventData";
import axios from "axios";
import { useLang } from "../../context/LanguageContext";

export default function EventItem({ item, clickRef }) {
  const { mapState, mapDispatch } = useMap();
  const { language } = useLang();
  const { selectEventItem } = mapState;

  const [coords, setCoords] = useState(null);
  useEffect(() => {
    const getGoords = async () => {
      const result = await axios.get(
        `/web/event/detail?unitid=${item.unitid}&date=${item.date}`
      );
      setCoords({...result.data, row: item.row});
    };
    if (!coords) {
      getGoords();
    }
  }, [item]);
  // 이벤트 클릭
  const handelEventItem = async () => {
    if (selectEventItem && selectEventItem.row === item.row) {
      mapDispatch({ type: "BLUR" });
    } else {
      mapDispatch({ type: "SET_SELECT_EVENT_ITEM", payload: coords });
    }
  };
  return (
    <>
      <li onClick={handelEventItem}>
        <div className={`card ${selectEventItem && selectEventItem.row === item.row ? "on" : ""}`}
             ref={selectEventItem && selectEventItem.row === item.row ? clickRef : null}
        >
          <div className="card-status">
            <div className="top">
              <div className="item-num">{item.row}</div>
              <div className="item-name">{item.unit_nm}</div>
              <div className="item-time">
                {ISODateToDateTime(item.date).substring(11, 19)}
              </div>
            </div>
            <div className="bottom">
              <div className="item-desc">
                {event(item.event_code, language)}
              </div>
            </div>
          </div>
        </div>
      </li>
    </>
  );
}
