import React from "react";
import { NavLink } from "react-router-dom";
interface Props {
  path: string;
  children?: React.ReactNode;
  classN?: string;
  style?: React.CSSProperties;
}
export default function Link({ path, children, classN, style }: Props) {
  return (
    <>
      <NavLink to={path} className={classN} style={style}>
        {children}
      </NavLink>
    </>
  );
}
