import React, { useContext, useEffect } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import moment from "moment";
import { useState } from "react";
import DayTableExcel from "./DayTableExcel";
export default function DayTable({ data, excelData, totalCount, sort, tableRef, excelRef, date, unitid, unit_nm, totalDist, excelLoading, viewRef, hasNext }) {
  const { language } = useContext(LanguageContext);

  const [column, setcolumn] = useState({
    no:{key:'no',ko:'번호',eng:'No',active:true,view:true,width:'70px'},
    driver:{key:'driver',ko:'운전자',eng:'Driver',active:true,view:true,width:'110px'},
    makedate:{key:'makedate',ko:'시간',eng:'Time',active:true,view:true,width:'90px'},
    c_status:{key:'c_status',ko:'차량상태',eng:'Status',active:true,view:true,width:'90px'},
    speed:{key:'speed',ko:'속도',eng:'Speed',active:true,view:true,width:'70px'},
    d_voltage:{key:'d_voltage',ko:'단말전압',eng:'Device voltage',active:true,view:true,width:'90px'},
    v_voltage:{key:'v_voltage',ko:'차량전압',eng:'Car voltage',active:false,view:false,width:'90px'},
    g_status:{key:'g_status',ko:'GPS 상태',eng:'GPS status',active:true,view:true,width:'110px'},
    g_count:{key:'g_count',ko:'GPS 개수',eng:'GPS count',active:true,view:true,width:'90px'},
    dist:{key:'dist',ko:'이동거리 (km)',eng:'Mileage',active:true,view:true,width:'110px'},
    lat:{key:'lat',ko:'위도',eng:'Latitude',active:true,view:true,width:'90px'},
    lon:{key:'lon',ko:'경도',eng:'Longitude',active:true,view:true,width:'90px'},
    addr:{key:'addr',ko:'주소',eng:'Address',active:true,view:true},    
  })

  useEffect(() => {
    if(data?.length > 0) {
      if (!column.v_voltage.view && data[0].wire_type === "Y") {
        setcolumn((prev) => ({...prev, v_voltage:{...prev.v_voltage, active:true, view:true}}));
      };
    }
  }, [data]);  

  const handleColumn = (e) => {
    setcolumn((prev) => ({...prev, [e.target.name]:{...prev[e.target.name], active:(e.target.checked ? true : false)}}))
  }
  
  return (
    <>
      <div
        ref={tableRef}
        className="common-table"
        style={{ overflow: "auto", maxHeight: "25em"}}
      >
        <table className="common-table">
          <thead>
            <tr>
              {Object.values(column).map((item, index) => {
                return (item.view && (
                  <th key={index} style={{width:(item.width || 'auto')}}>
                    <label className="custom-checkbox">
                      <input type="checkbox" checked={item.active} name={item.key} onChange={handleColumn}/>
                      <span className="checkbox"></span>
                      <div className="label">{language === 'KOR' ? item.ko : item.eng}</div>
                    </label>
                  </th>
                ));
              })}
            </tr>          
          </thead>
          <tbody>
            {(!data || excelLoading) ? (
              <tr>
                <td colSpan={Object.values(column).filter((item) => item.view).length}>
                  <div className="empty">{language === 'KOR' ? '로딩중입니다.' : 'Loading...'}</div>
                </td>
              </tr>
            ) :
            data && data.length === 0 ? (
              <tr>
                <td colSpan={Object.values(column).filter((item) => item.view).length}>
                  <div className="empty">{language === 'KOR' ? '검색된 데이터가 없습니다.' : 'No Data.'}</div>
                </td>
              </tr>
            ) : (
              data && (
                <>
                  {
                    data.map((item, index) => {
                      const {
                        driver,
                        makedate_cmbd,
                        car_status,
                        speed,
                        inner_battery,
                        vehicle_battery,
                        gps_sv,
                        gps_sv2,
                        sum_calc_dist,
                        lat,
                        lon,
                        addr,
                        wire_type,
                      } = item;
                      return (
                        <tr key={index}>
                          <td>{sort ? totalCount - index : index + 1}</td>
                          <td>{driver === null ? "" : driver}</td>
                          <td>
                            {moment
                              .utc(`${makedate_cmbd}`)
                              .format("HH:mm:ss")}
                          </td>
                          <td>{car_status}</td>
                          <td>{speed}</td>
                          <td>{inner_battery} V</td>
                          {(wire_type === "Y") && (<td>{vehicle_battery} V</td>)}
                          <td>{gps_sv}</td>
                          <td>{gps_sv2}</td>
                          <td>{sum_calc_dist.toFixed(2)}</td>
                          <td>{lat}</td>
                          <td>{lon}</td>
                          <td>{addr}</td>
                        </tr>
                      );
                    })
                  }
                  <div ref={viewRef} style={{height:'3px'}}></div>
                </>
              )
            )}
          </tbody>
        </table>
      </div>
      <DayTableExcel data={excelData} totalCount={totalCount} sort={sort} excelRef={excelRef} column={column} date={date} unitid={unitid} unit_nm={unit_nm} totalDist={totalDist} />
    </>
  );
}
