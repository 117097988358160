import React from "react";

interface Props {
  value: string;
}
export default function Value({ value }: Props) {
  return (
    <>
      <div className="das-info-value">
        <span>{value}</span>
      </div>
    </>
  );
}
