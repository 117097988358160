import React from 'react';
import { useAuth } from '../../../context/LoginContext';
import { ISODateToDate } from '../../../lib/common_func';
import moment from 'moment';

export default function VlogTaxExcel({ table, data, searchOption, unit_nm }) {
    const renderData = [...data].reverse();

    let total_dist = 0;
    let total_busi_dist = 0;
    renderData.map((item) => {
        total_dist += item.dist;
        total_busi_dist += item.d_commute + item.d_general;
    })
    const busi_rate = (total_busi_dist / total_dist) * 100;

    const style = {
        row:{textAlign:'center', fontSize:'12px',height:'30px'},
        biggerFont:{fontSize:'14px'},
        title:{border:'1px solid #413c3c', fontWeight:'bold'},
        content:{border:'1px solid #413c3c'},
        sectionTitle:{height:'60px', fontWeight:'bold', verticalAlign:'bottom'},
        emptyBox:{backgroundColor:'#e5e5e5', border:'1px solid #413c3c', fontWeight:'bold'}
    }
  
    return (
        <>
            <table className='common-table excel-table' ref={table}>
                <tr style={style.row}>
                    <td colSpan={2} rowSpan={2} style={{...style.title,...style.biggerFont}}>과세기간</td>
                    <td colSpan={2} rowSpan={2} style={{...style.content,...style.biggerFont}}>{`${searchOption.from} ~ ${searchOption.to}`}</td>
                    <td colSpan={3} rowSpan={2} style={{...style.title,...style.biggerFont}}>업무용 승용차 운행 기록부</td>
                    <td style={style.title}>상호명</td>
                    <td style={style.content}>{data.length > 0 && data[0].org_nm}</td>
                </tr>
                <tr style={style.row}>
                    <td style={style.title}>사업자 등록번호</td>
                    <td style={style.content}>{data.length > 0 && (data[0].bizno || '')}</td>
                </tr>

                <tr>
                    <td colSpan={9} style={style.sectionTitle}>1. 기본 정보</td>
                </tr>

                <tr style={style.row}>
                    <td colSpan={2} style={style.title}>차 종</td>
                    <td colSpan={2} style={style.title}>자동차 등록번호</td>
                    <td colspan={5} rowSpan={2}></td>
                </tr>
                <tr style={style.row}>
                    <td colSpan={2} style={style.content}>{data.length > 0 && (data[0].model || '')}</td>
                    <td colSpan={2} style={style.content}>{unit_nm}</td>
                </tr>

                <tr>
                    <td colSpan={9} style={style.sectionTitle}>2. 업무용 사용비율 계산</td>
                </tr>
                
                <tr style={style.row}>
                    <td rowSpan={3} style={{...style.title, width:'110px'}}>사용일자(요일)</td>
                    <td colSpan={2} style={style.title}>사용자</td>
                    <td colSpan={6} style={style.title}>운행 내역</td>
                </tr>
                <tr style={style.row}>
                    <td rowSpan={2} style={{...style.title, width:'84px'}}>부서</td>
                    <td rowSpan={2} style={{...style.title, width:'84px'}}>성명</td>
                    <td rowSpan={2} style={{...style.title, width:'100px'}}>주행 전 계기판의 거리(Km)</td>
                    <td rowSpan={2} style={{...style.title, width:'100px'}}>주행 후 계기판의 거리(Km)</td>
                    <td rowSpan={2} style={{...style.title, width:'100px'}}>주행거리(Km)</td>
                    <td colSpan={2} style={style.title}>업무용 사용거리(Km)</td>
                    <td rowSpan={2} style={{...style.title,width:'130px'}}>비고</td>
                </tr>
                <tr style={style.row}>
                    <td style={{...style.title, width:'120px'}}>출퇴근용</td>
                    <td style={{...style.title, width:'120px'}}>일반 업무용</td>
                </tr>

                {renderData.map((item) => {
                    const st_mileage = parseFloat(item.st_mileage);
                    const ed_mileage = parseFloat(item.ed_mileage);

                    return (
                        <tr style={{...style.row}}>
                        <td style={style.content}>{(`${ISODateToDate(item.st_time)}(${(day[moment(ISODateToDate(item.st_time)).day()])})`)}</td>
                        <td style={style.content}>{item.department ?? ''}</td>
                        <td style={style.content}>{item.u_nm ?? ''}</td>
                        <td style={style.content}>{st_mileage.toLocaleString() ?? 0}</td>
                        <td style={style.content}>{ed_mileage.toLocaleString() ?? 0}</td>
                        <td style={style.content}>{item.dist ?? 0}</td>
                        <td style={style.content}>{item.d_commute ?? 0}</td>
                        <td style={style.content}>{item.d_general ?? 0}</td>
                        <td style={style.content}>{item.memo ?? ''}</td>
                    </tr>
                    )
                }
                )}

                <tr style={style.row}>
                    <td colSpan={3} rowSpan={2} style={style.emptyBox}></td>
                    <td colSpan={3} style={style.title}>과세기간 총 주행거리(km)</td>
                    <td colSpan={2} style={style.title}>과세기간 업무용 사용거리(km)</td>
                    <td style={style.title}>업무사용비율(%)</td>
                </tr>
                <tr style={style.row}>
                    <td colSpan={3} style={style.content}>{total_dist}</td>
                    <td colSpan={2} style={style.content}>{total_busi_dist}</td>
                    <td style={style.content}>{busi_rate}</td>
                </tr>
            </table>
        </>
    );
}

const day = ['일','월','화','수','목','금','토'];