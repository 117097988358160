import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";
import moment from "moment";
import Swal from "sweetalert2";
import axios from "../../lib/axios";
export default function DvcEditForm({ data, user }) {
  const { language } = useContext(LanguageContext);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [editData, setEditData] = useState({ ...data });

  const [CPN, setCPN] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [companyData, setCompanyData] = useState();
  const [CPNData, setCPNData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isLogbook, setIsLogbook] = useState(false);
  const [speed, setSpeed] = useState(0);
  const [prevIsAuto, setPrevIsAuto] = useState(data.auto_yn);
  // ============================================================
  // useEffect definition
  console.log(data);
  useEffect(() => {
    if (editData.auto_yn === "Y") {
      setIsLogbook(true);
    } else {
      setIsLogbook(false);
      setEditData((prev) => ({
        ...prev,
        fuel_type: null,
        mileage: null,
        model: null,
      }));
    }
  }, [editData.auto_yn]);

  useEffect(() => {
    function getCompanyData() {
      axios
        .get(
          `/web/org/name/list?type=${user.user.type}&org_idx=${user.user.org_idx}`
        )
        .then((res) => {
          setCompanyData(res.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
    if (!companyData) {
      getCompanyData();
    }
  }, [companyData, user.user.org_idx, user.user.type]);

  useEffect(() => {
    if (companyData) {
      setIsLoaded(true);
    }
  }, [companyData]);

  useEffect(() => {
    if (companyData?.length > 0 && data.org_idx !== null) {
      const result = companyData.filter(
        (item) => item.idx === data.org_idx && item.org_type === data.org_type
      );
      const orgTtype =
        result[0].org_type === "C"
          ? "회사"
          : result[0].org_type === "B"
          ? "지점"
          : result[0].org_type === "A"
          ? "대리점"
          : "";

      setCPN(`${orgTtype} > ${result[0].org_nm}`);
      setEditData((prev) => ({
        ...prev,
        org_idx: result[0].idx,
        org_type: result[0].org_type,
        agt_idx: result[0].agt_idx,
        brc_idx: result[0].brc_idx,
        cmp_idx: result[0].cmp_idx,
      }));
    }
  }, [companyData, data]);

  useEffect(() => {
    if (isReadOnly) {
      setIsChanged(false);
    }
  }, [isReadOnly]);

  useEffect(() => {
    if (searchText === "") {
      setCPNData([]);
    }
  }, [searchText]);

  // ============================================================

  // ============================================================
  // Function definition
  function speedSetinputHandler(e) {
    const value = e.target.value;
    if (value > 49) {
      setSpeed(value);
    } else {
      Swal.fire({
        title:
          language === "KOR"
            ? "50 이상의 숫자를 입력해주세요."
            : "Please enter a number greater than 50.",
        confirmButtonText: language === "KOR" ? "확인" : "OK",
        width: "400px",
        padding: "20px",
        heightAuto: false,
        customClass: {
          confirmButton: "submit-btn",
        },
      });
    }
  }
  function speedSet() {
    Swal.fire({
      title:
        language === "KOR"
          ? "과속 설정 값을 전송하시겠습니까?"
          : "Would you like to send the speedy setting?",
      confirmButtonText: language === "KOR" ? "확인" : "OK",
      showCancelButton: true,
      cancelButtonText: language === "KOR" ? "취소" : "Cancel",
      width: "400px",
      padding: "20px",
      heightAuto: false,

      customClass: {
        // confirmButton: "submit-btn",
        cancelButton: "swal-cancel-btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`/web/terminal/set/speedy`, {
            unitid: data.unitid,
            value: speed,
            type: user.user.type,
            idx: user.user.idx,
          })
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                title:
                  language === "KOR"
                    ? "전송되었습니다.\n단말 전원 ON 시 적용됩니다."
                    : "It has been sent. \nThis applies when the terminal is powered on.",
                confirmButtonText: language === "KOR" ? "확인" : "OK",
                width: "400px",
                padding: "20px",
                heightAuto: false,

                customClass: {
                  confirmButton: "submit-btn",
                },
              });
            } else {
              Swal.fire({
                title:
                  language === "KOR"
                    ? "전송에 실패하였습니다."
                    : "Failed to send.",
                confirmButtonText: language === "KOR" ? "확인" : "OK",
                width: "400px",
                padding: "20px",
                heightAuto: false,

                customClass: {
                  confirmButton: "submit-btn",
                },
              });
            }
          });
      }
    });
  }
  function clearinput() {
    setIsReadOnly(!isReadOnly);
    setCPN("");
    setCPNData([]);
  }
  const inputHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setEditData((prev) => ({ ...prev, [name]: value }));
  };
  async function sendData() {
    let sendData = { ...editData };
    sendData.auto_flag = prevIsAuto == editData.auto_yn ? "N" : "Y";
    axios.post(`/web/terminal/edit`, sendData).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          title: language === "KOR" ? "수정되었습니다." : "Saved",
          confirmButtonText: language === "KOR" ? "확인" : "OK",
          width: "400px",
          padding: "20px",
          heightAuto: false,
          icon: "success",
          customClass: {
            confirmButton: "submit-btn",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/Trm";
          }
        });
      } else {
        Swal.fire({
          title: language === "KOR" ? "수정에 실패하였습니다." : "Failed",
          confirmButtonText: language === "KOR" ? "확인" : "OK",
          width: "400px",
          padding: "20px",
          heightAuto: false,
          icon: "error",
          customClass: {
            confirmButton: "submit-btn",
          },
        });
      }
    });
  }
  function submitHandler(e) {
    if (isLogbook) {
      if (editData.fuel_type === "F00" || editData.fuel_type === null) {
        Swal.fire({
          title:
            language === "KOR"
              ? "연료타입을 선택해주세요."
              : "Please select a fuel type.",
          confirmButtonText: language === "KOR" ? "확인" : "OK",
          width: "400px",
          padding: "20px",
          heightAuto: false,
          icon: "warning",
          customClass: {
            confirmButton: "submit-btn",
          },
        });
        return;
      }
      if (editData.mileage === "" || editData.mileage === null) {
        Swal.fire({
          title:
            language === "KOR"
              ? "계기판 주행거리를 입력해주세요."
              : "Please enter the mileage.",
          confirmButtonText: language === "KOR" ? "확인" : "OK",
          width: "400px",
          padding: "20px",
          heightAuto: false,
          icon: "warning",
          customClass: {
            confirmButton: "submit-btn",
          },
        });
        return;
      }
      if (editData.model === "" || editData.model === null) {
        Swal.fire({
          title:
            language === "KOR"
              ? "차종을 입력해주세요."
              : "Please enter the model.",
          confirmButtonText: language === "KOR" ? "확인" : "OK",
          width: "400px",
          padding: "20px",
          heightAuto: false,
          icon: "warning",
          customClass: {
            confirmButton: "submit-btn",
          },
        });
        return;
      }
    }
    e.preventDefault();
    Swal.fire({
      title:
        language === "KOR" ? "저장하시겠습니까?" : "Would you like to save?",
      confirmButtonText: language === "KOR" ? "확인" : "OK",
      showCancelButton: true,
      cancelButtonText: language === "KOR" ? "취소" : "Cancel",
      width: "400px",
      padding: "20px",
      heightAuto: false,
      icon: "question",
      customClass: {
        // confirmButton: "submit-btn",
        cancelButton: "swal-cancel-btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        sendData();
      }
    });
  }
  //==============================================================
  // Compnay name filter
  function editCPN(e) {
    if (!isReadOnly) {
      setIsChanged(true);
    }
  }
  function onChangeFilter(e) {
    setCPN(e.target.value);

    handleFilterChange(e);
  }
  function handleFilterChange(e) {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText === "") {
      setCPNData([]);
      return;
    }
    const filteredData = companyData.filter((item) => {
      return item.org_nm.toLowerCase().includes(searchText.toLowerCase());
    });

    setCPNData(filteredData);
  }

  //==============================================================
  if (!isLoaded) {
    return <div>Loading...</div>;
  }
  //==============================================================
  const style = {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "40px",
    background: "white",
    border: "1px solid gray",
    padding: "10px",
    width: "40.5%",
    zIndex: "100",
    flexDirection: "column",
  };
  const liStyle = {
    padding: "10px",
    width: "100%",
    cursor: "pointer",
    borderBottom: "1px solid gray",
  };
  const dis = {
    position: "relative",
  };
  const select = {
    width: "100%",
    padding: "10px",
  };

  //==============================================================
  return (
    <>
      <table className="common-view-table">
        <colgroup>
          <col style={{ width: "19rem" }} />
          <col />
          <col style={{ width: "19rem" }} />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>
              {language === "KOR" ? "회사/지점/대리점명" : "Com/Branch/Agent"}
            </th>
            <td colSpan={3}>
              <div className="flex" style={dis}>
                <div className="custom-input wide">
                  <input
                    // onBlur={setIsChanged(false)}
                    name="org_nm"
                    type="text"
                    placeholder={
                      language === "KOR"
                        ? "회사/지점/대리점명을 입력해주세요"
                        : "enter com/branch/agent name"
                    }
                    onChange={onChangeFilter}
                    onFocus={(e) => editCPN(e)}
                    readOnly={isReadOnly}
                    value={CPN}
                  />
                </div>
                <button
                  type="button"
                  className="common-btn white sm"
                  onClick={() => clearinput()}
                >
                  <span>{language === "KOR" ? "초기화" : "Reset"}</span>
                </button>
                {isChanged && CPNData.length > 0 ? (
                  <ul className="search-list" style={style}>
                    {CPNData.map((item, index) => (
                      <li
                        style={liStyle}
                        key={index}
                        onClick={() => {
                          setEditData((prev) => ({
                            ...prev,
                            org_idx: item.idx,
                            org_type: item.org_type,
                          }));
                          setCPN(
                            `${
                              item.org_type === "C"
                                ? "회사"
                                : item.org_type === "B"
                                ? "지점"
                                : item.org_type === "A"
                                ? "대리점"
                                : ""
                            } > ${item.org_nm}`
                          );
                          setEditData((prev) => ({
                            ...prev,
                            org_idx: item.idx,
                            org_type: item.org_type,
                            agt_idx: item.agt_idx,
                            brc_idx: item.brc_idx,
                            cmp_idx: item.cmp_idx,
                          }));
                          setIsReadOnly(true);
                        }}
                      >
                        {item.org_type === "C"
                          ? "회사"
                          : item.org_type === "B"
                          ? "지점"
                          : item.org_type === "A"
                          ? "대리점"
                          : ""}
                        {" > "}
                        {item.org_nm}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </td>
          </tr>
          <tr>
            <th>{language === "KOR" ? "단말이름" : "device name"}</th>
            <td>
              <div className="custom-input">
                <input
                  name="unit_nm"
                  type="text"
                  defaultValue={data.unit_nm}
                  placeholder={
                    language === "KOR"
                      ? "단말이름을 입력해주세요"
                      : "enter device name"
                  }
                  onChange={inputHandler}
                />
              </div>
            </td>
            <th>{language === "KOR" ? "단말번호" : "USIM num"}</th>
            <td>
              <div className="custom-input">
                <input
                  name="unitid"
                  type="text"
                  defaultValue={data.unitid.replace("@@", "")}
                  disabled
                  onChange={inputHandler}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>{language === "KOR" ? "특이사항" : "Status"}</th>
            <td>
              <div className="custom-radio-group">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="status"
                    onChange={inputHandler}
                    value="정상"
                    defaultChecked={data.status === "정상" || !data.status}
                  />
                  <div className="label">
                    {language === "KOR" ? "정상" : "Good"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="status"
                    onChange={inputHandler}
                    value="차량고장"
                    defaultChecked={data.status === "차량고장"}
                  />
                  <div className="label">
                    {language === "KOR" ? "차량고장" : "Car trouble"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="status"
                    onChange={inputHandler}
                    value="점검"
                    defaultChecked={data.status === "점검"}
                  />
                  <div className="label">
                    {language === "KOR" ? "점검" : "check"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="status"
                    onChange={inputHandler}
                    value="미운행"
                    defaultChecked={data.status === "미운행"}
                  />
                  <div className="label">
                    {language === "KOR" ? "미운행" : "Unoperated"}
                  </div>
                </label>
              </div>
            </td>
            <th>{language === "KOR" ? "사용여부" : "Active Y/N"}</th>
            <td>
              <div className="custom-radio-group">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="act_yn"
                    onChange={inputHandler}
                    defaultChecked={data.act_yn === "Y"}
                    value="Y"
                  />
                  <div className="label">
                    {language === "KOR" ? "사용" : "Yes"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="act_yn"
                    onChange={inputHandler}
                    defaultChecked={data.act_yn === "N"}
                    value="N"
                  />
                  <div className="label">
                    {language === "KOR" ? "미사용" : "No"}
                  </div>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>{language === "KOR" ? "총 주행거리" : "Total mileage"}</th>
            <td>{data.total_dist.toFixed(2)} Km</td>
            <th>{language === "KOR" ? "과속 알림 설정" : "Speedy Setting"}</th>
            <td>
              <div className="flex">
                <div className="custom-input">
                  <input
                    name="speed_cnt"
                    type="text"
                    maxLength={3}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    defaultValue={data?.spd ? data.spd : 110}
                    onChange={speedSetinputHandler}
                  />
                </div>
                km/h
                <button
                  className="common-btn purple"
                  onClick={() => speedSet()}
                >
                  <span>{language === "KOR" ? "전송" : "Send"}</span>
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              {language === "KOR"
                ? "API 업데이트 사용여부"
                : "API update function Y/N"}
            </th>
            <td>
              <div className="custom-radio-group">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="api_upt_allowed"
                    onChange={inputHandler}
                    defaultChecked={data.api_upt_allowed === "Y"}
                    value="Y"
                  />
                  <div className="label">
                    {language === "KOR" ? "사용" : "Yes"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="api_upt_allowed"
                    onChange={inputHandler}
                    defaultChecked={data.api_upt_allowed !== "Y"}
                    value="N"
                  />
                  <div className="label">
                    {language === "KOR" ? "미사용" : "No"}
                  </div>
                </label>
              </div>
            </td>
            <th>{language === "KOR" ? "등록일" : "create"}</th>
            <td>{moment.utc(data.makedate).format("YYYY-MM-DD")}</td>
          </tr>
          <tr>
            <th>
              {language === "KOR"
                ? "자동 운행일지 생성여부"
                : "Automatic driving log generation status"}
            </th>
            <td colSpan={isLogbook !== true ? 3 : 0}>
              <div className="custom-radio-group">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="auto_yn"
                    onChange={inputHandler}
                    defaultChecked={data.auto_yn === "Y"}
                    value="Y"
                  />
                  <div className="label">
                    {language === "KOR" ? "사용" : "Yes"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="auto_yn"
                    onChange={inputHandler}
                    defaultChecked={data.auto_yn !== "Y"}
                    value="N"
                  />
                  <div className="label">
                    {language === "KOR" ? "미사용" : "No"}
                  </div>
                </label>
              </div>
            </td>
            {isLogbook ? (
              <>
                <th className="required">계기판 주행거리(km)</th>
                <td>
                  <div className="custom-input">
                    <input
                      type="text"
                      name={"mileage"}
                      onChange={inputHandler}
                      defaultValue={
                        data?.mileage && parseFloat(data.mileage).toFixed(2)
                      }
                    />
                  </div>
                </td>
              </>
            ) : (
              ""
            )}
          </tr>
          {isLogbook ? (
            <>
              <tr>
                <th className="required">{language === "KOR" ? "연료 타입" : "Fuel Type"}</th>
                <td>
                  <div className="custom-input">
                    <select
                      name="fuel_type"
                      id="fuel_type"
                      style={select}
                      onChange={inputHandler}
                    >
                      <option value="F00">선택</option>
                      <option
                        value="F01"
                        selected={data.fuel_type === "F01" ? "selected" : ""}
                      >
                        휘발유 1800cc 이상
                      </option>
                      <option
                        value="F02"
                        selected={data.fuel_type === "F02" ? "selected" : ""}
                      >
                        휘발유 1800cc 미만
                      </option>
                      <option
                        value="F03"
                        selected={data.fuel_type === "F03" ? "selected" : ""}
                      >
                        경유
                      </option>
                      <option
                        value="F04"
                        selected={data.fuel_type === "F04" ? "selected" : ""}
                      >
                        LPG
                      </option>
                      <option
                        value="F05"
                        selected={data.fuel_type === "F05" ? "selected" : ""}
                      >
                        하이브리드 휘발유
                      </option>
                      <option
                        value="F06"
                        selected={data.fuel_type === "F06" ? "selected" : ""}
                      >
                        하이브리드 LPI
                      </option>
                      <option
                        value="F07"
                        selected={data.fuel_type === "F07" ? "selected" : ""}
                      >
                        전기
                      </option>
                      <option
                        value="F08"
                        selected={data.fuel_type === "F08" ? "selected" : ""}
                      >
                        수소
                      </option>
                    </select>
                  </div>
                </td>
                <th>{language === "KOR" ? "차종" : "Model"}</th>
                <td>
                  <div className="custom-input">
                    <input
                      name="model"
                      type="text"
                      defaultValue={data.model == null ? "" : data.model}
                      onChange={inputHandler}
                    />
                  </div>
                </td>
              </tr>
              <tr></tr>
            </>
          ) : (
            ""
          )}

          <tr>
            <th>{language === "KOR" ? "아이콘" : "Icon"}</th>
            <td colSpan="3">
              <div className="custom-radio-group marker-icon">
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="icon_code"
                    onChange={inputHandler}
                    defaultChecked={
                      data.icon_code === "IC01" || !data.icon_code
                    }
                    value="IC01"
                  />
                  <div className="label">
                    <img
                      src="../assets/img/markers/icon-car-1.svg"
                      alt="승용차"
                    />
                  </div>
                  <div className="label-name">
                    {language === "KOR" ? "승용차" : "Car"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="icon_code"
                    onChange={inputHandler}
                    defaultChecked={data.icon_code === "IC02"}
                    value="IC02"
                  />
                  <div className="label">
                    <img
                      src="../assets/img/markers/icon-motorcycle-1.svg"
                      alt=""
                    />
                  </div>
                  <div className="label-name">
                    {language === "KOR" ? "오토바이" : "MotorBike"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="icon_code"
                    onChange={inputHandler}
                    defaultChecked={data.icon_code === "IC03"}
                    value="IC03"
                  />
                  <div className="label">
                    <img src="../assets/img/markers/icon-human-1.svg" alt="" />
                  </div>
                  <div className="label-name">
                    {language === "KOR" ? "사람" : "Human"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="icon_code"
                    onChange={inputHandler}
                    defaultChecked={data.icon_code === "IC04"}
                    value="IC04"
                  />
                  <div className="label">
                    <img src="../assets/img/markers/icon-bike-1.svg" alt="" />
                  </div>
                  <div className="label-name">
                    {language === "KOR" ? "자전거" : "Bike"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="icon_code"
                    onChange={inputHandler}
                    defaultChecked={data.icon_code === "IC05"}
                    value="IC05"
                  />
                  <div className="label">
                    <img src="../assets/img/markers/icon-truck-1.svg" alt="" />
                  </div>
                  <div className="label-name">
                    {language === "KOR" ? "물류차량" : "Logistics"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="icon_code"
                    onChange={inputHandler}
                    defaultChecked={data.icon_code === "IC06"}
                    value="IC06"
                  />
                  <div className="label">
                    <img src="../assets/img/markers/icon-trash-1.svg" alt="" />
                  </div>
                  {language === "KOR" ? (
                    <div className="label-name">폐기물차량</div>
                  ) : (
                    <div className="label-name">
                      Garbage
                      <br />
                      vehicle
                    </div>
                  )}
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="icon_code"
                    onChange={inputHandler}
                    defaultChecked={data.icon_code === "IC07"}
                    value="IC07"
                  />
                  <div className="label">
                    <img src="../assets/img/markers/icon-school-1.svg" alt="" />
                  </div>
                  <div className="label-name">
                    {language === "KOR" ? "통학버스" : "Schoolbus"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="icon_code"
                    onChange={inputHandler}
                    defaultChecked={data.icon_code === "IC08"}
                    value="IC08"
                  />
                  <div className="label">
                    <img
                      src="../assets/img/markers/icon-airplane-1.svg"
                      alt=""
                    />
                  </div>
                  <div className="label-name">
                    {language === "KOR" ? "항공" : "Flight"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="icon_code"
                    onChange={inputHandler}
                    defaultChecked={data.icon_code === "IC09"}
                    value="IC09"
                  />
                  <div className="label">
                    <img src="../assets/img/markers/icon-ship-1.svg" alt="" />
                  </div>
                  <div className="label-name">
                    {language === "KOR" ? "선박" : "Ship"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="icon_code"
                    onChange={inputHandler}
                    defaultChecked={data.icon_code === "IC10"}
                    value="IC10"
                  />
                  <div className="label">
                    <img src="../assets/img/markers/icon-crane-1.svg" alt="" />
                  </div>
                  <div className="label-name">
                    {language === "KOR" ? "굴삭기" : "Excavator"}
                  </div>
                </label>
                <label className="custom-radio">
                  <input
                    type="radio"
                    name="icon_code"
                    onChange={inputHandler}
                    defaultChecked={data.icon_code === "IC11"}
                    value="IC11"
                  />
                  <div className="label">
                    <img src="../assets/img/markers/icon-pet-1.svg" alt="" />
                  </div>
                  <div className="label-name">
                    {language === "KOR" ? "반려동물" : "Pet"}
                  </div>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="common-control">
        <div className="left">
          <Link to={"/Trm"} className="common-btn white">
            <span>{language === "KOR" ? "목록" : "List"}</span>
          </Link>
        </div>
        <div className="right">
          <button className="common-btn purple" onClick={submitHandler}>
            <span>{language === "KOR" ? "수정" : "Save"}</span>
          </button>
        </div>
      </div>
    </>
  );
}
