import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../lib/axios";
//@ts-ignore
import { dataList, subDataListMenuFull } from "../../../Admin/AdmMain";
import CommonControl from "../../../Atomic/organism/CommonControl";
import Page from "../../../Atomic/template/PageTable";
import Radio from "../../../Atomic/atoms/Radio";
import SubNavi from "../../../Atomic/molecules/SubNavi";
import { DeleteFun } from "../../../Atomic/exFunc";

export default function Basic() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const navi = useNavigate();
  const { user } = useAuth();
  // State defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [rawData, setRawData] = useState<any>([]);
  const [sendData, setSendData] = useState<any>([]);
  //=======================================================================
  // Function defines
  //=======================================================================

  function inputHandler(item: any, index: any) {
    setRawData((prevData: any) => {
      const updatedData = [...prevData];
      updatedData[index].auth = item.auth === "Y" ? "N" : "Y";
      return updatedData;
    });

    setSendData((prevData: any) => {
      const updatedData = prevData.map((obj: any) => {
        if (obj.idx === item.idx) {
          // If the object with the same idx exists, update it
          return { ...obj, auth: item.auth };
        }
        return obj;
      });

      // If the object with the same idx doesn't exist, add a new one
      if (!updatedData.some((obj: any) => obj.idx === item.idx)) {
        updatedData.push({ idx: item.idx, auth: item.auth });
      }

      return updatedData;
    });
  }

  function createNew() {
    if (rawData.cd === null) {
      Swal.fire({
        title: `${translate(
          "권한그룹 코드 입력해주세요",
          "Please enter the Code"
        )}`,
      });
      return;
    }
    if (rawData.nm_txt === null) {
      Swal.fire({
        title: `${translate(
          "그룹설명  입력해주세요",
          "Please enter the Name"
        )}`,
      });
      return;
    }
    if (rawData.nm === null) {
      Swal.fire({
        title: `${translate(
          "권한그룹 명 입력해주세요",
          "Please enter the Name"
        )}`,
      });
      return;
    }
    Swal.fire({
      title: `${translate("저장하시겠습니까?", "Do you want to save?")}`,
      showCancelButton: true,
      confirmButtonText: `${translate("예", "Yes")}`,
      cancelButtonText: `${translate("아니오", "No")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`/web/admin/menu/auth/edit`, rawData).then((res: any) => {
          if (res.status === 200) {
            Swal.fire({
              title: `${translate("저장되었습니다", "Saved successfully")}`,
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              navi(`/Adm/menu-table/basic`, {
                state: {
                  idx: res.data.idx,
                },
              });
            });
          } else {
            Swal.fire({
              title: `${translate("저장에 실패하였습니다", "Failed to save")}`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
      }
    });
  }

  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        navi('../menu-table')
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "삭제",
      labelEN: "Delete",
      classN: "white",
      icon: true,
      clickFun: () => {
        DeleteFun(`/web/admin/menu/del`, { idx: idx, type: "ADM" }, navi);
      },
      position: " right",
    },
    {
      labelKR: "수정",
      labelEN: "Edit",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];

  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
  }, [location]);
  useEffect(() => {
    if (idx !== "") {
      axios
        .get(
          `/web/admin/menu/auth/list?idx=${idx}&type=${user.type}`
        )
        .then((res: any) => {
          setRawData(res.data);
          setIsAdd(false);
        })
        .catch((err: any) => {
          console.log(`Error Link  ${err.config.url}`);
        });
    } else {
      setIsAdd(true);
      setRawData([]);
    }
  }, [idx, user.type]);

  // Log defines
  //=======================================================================

  console.log("rawData", rawData);
  // console.log("state", location.state);
  // console.log("idx", idx);
  // console.log("CompanyData", companyData);
  // console.log("user", user.type);
  // console.log("refId", refId.current?.value);
  // console.log(sendData);

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "메뉴 권한 관리" : "Menu Auth"}`}
        menu="/Adm/menu-table"
      >
        <SubNavi
          subDataList={subDataListMenuFull}
          language={language}
          navi={navi}
          idx={idx}
          classPath="/Adm/menu-table/perm"
        />
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th colSpan={1}>{translate("위치관제", "Location Control")}</th>
              <td>
                <div className="custom-check-group">
                  {rawData.length !== 0 &&
                    rawData.map((item: any, index: any) => {
                      if (item.pri_nm === "위치관제") {
                        return (
                          <Radio
                            type="checkbox"
                            key={index}
                            name={item.code}
                            val={"N"}
                            title={
                              item.sec_nm === null ? item.pri_nm : item.sec_nm
                            }
                            change={() => {
                              inputHandler(item, index);
                            }}
                            check={item.auth === "Y"}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              </td>

              <th colSpan={1}>{translate("단말일지", "Location Control")}</th>
              <td>
                <div className="custom-check-group">
                  {rawData.length !== 0 &&
                    rawData.map((item: any, index: any) => {
                      if (item.pri_nm === "단말일지") {
                        return (
                          <Radio
                            type="checkbox"
                            key={index}
                            name={item.code}
                            val={item.pri_cd}
                            title={
                              item.ter_nm !== null
                                ? item.ter_nm
                                : item.sec_nm === null
                                ? item.pri_nm
                                : item.sec_nm
                            }
                            change={() => inputHandler(item, index)}
                            check={item.auth === "Y" ? true : false}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={1}>{translate("차량일지", "Location Control")}</th>
              <td>
                <div className="custom-check-group">
                  {rawData.length !== 0 &&
                    rawData.map((item: any, index: any) => {
                      if (item.pri_nm === "차량일지") {
                        return (
                          <Radio
                            type="checkbox"
                            key={index}
                            name={item.code}
                            val={item.pri_cd}
                            title={
                              item.sec_nm === null ? item.pri_nm : item.sec_nm
                            }
                            change={() => inputHandler(item, index)}
                            check={item.auth === "Y" ? true : false}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              </td>

              <th colSpan={1}>{translate("대시보드", "Location Control")}</th>
              <td>
                <div className="custom-check-group">
                  {rawData.length !== 0 &&
                    rawData.map((item: any, index: any) => {
                      if (item.pri_nm === "대시보드") {
                        return (
                          <Radio
                            type="checkbox"
                            key={index}
                            name={item.code}
                            val={item.pri_cd}
                            title={
                              item.sec_nm === null ? item.pri_nm : item.sec_nm
                            }
                            change={() => inputHandler(item, index)}
                            check={item.auth === "Y" ? true : false}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={1}>{translate("조직관리", "Location Control")}</th>
              <td>
                <div className="custom-check-group">
                  {rawData.length !== 0 &&
                    rawData.map((item: any, index: any) => {
                      if (item.pri_nm === "조직관리") {
                        return (
                          <Radio
                            type="checkbox"
                            key={index}
                            name={item.code}
                            val={item.pri_cd}
                            title={
                              item.sec_nm === null ? item.pri_nm : item.sec_nm
                            }
                            change={() => inputHandler(item, index)}
                            check={item.auth === "Y" ? true : false}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              </td>

              <th colSpan={1}>{translate("단말관리", "Location Control")}</th>
              <td>
                <div className="custom-check-group">
                  {rawData.length !== 0 &&
                    rawData.map((item: any, index: any) => {
                      if (item.pri_nm === "단말관리") {
                        return (
                          <Radio
                            type="checkbox"
                            key={index}
                            name={item.code}
                            val={item.pri_cd}
                            title={
                              item.sec_nm === null ? item.pri_nm : item.sec_nm
                            }
                            change={() => inputHandler(item, index)}
                            check={item.auth === "Y" ? true : false}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={1}>{translate("환경설정", "Location Control")}</th>
              <td>
                <div className="custom-check-group">
                  {rawData.length !== 0 &&
                    rawData.map((item: any, index: any) => {
                      if (item.pri_nm === "환경설정") {
                        return (
                          <Radio
                            type="checkbox"
                            key={index}
                            name={item.code}
                            val={item.pri_cd}
                            title={
                              item.sec_nm === null ? item.pri_nm : item.sec_nm
                            }
                            change={() => inputHandler(item, index)}
                            check={item.auth === "Y" ? true : false}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              </td>

              <th colSpan={1}>{translate("미관제확인", "Location Control")}</th>
              <td>
                <div className="custom-check-group">
                  {rawData.length !== 0 &&
                    rawData.map((item: any, index: any) => {
                      if (item.pri_nm === "미관제확인") {
                        return (
                          <Radio
                            type="checkbox"
                            key={index}
                            name={item.code}
                            val={item.pri_cd}
                            title={
                              item.sec_nm === null ? item.pri_nm : item.sec_nm
                            }
                            change={() => inputHandler(item, index)}
                            check={item.auth === "Y" ? true : false}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              </td>
            </tr>
            <tr>
              <th colSpan={1}>{translate("판매관리", "Location Control")}</th>
              <td>
                <div className="custom-check-group">
                  {rawData.length !== 0 &&
                    rawData.map((item: any, index: any) => {
                      if (item.pri_nm === "판매관리") {
                        return (
                          <Radio
                            type="checkbox"
                            key={index}
                            name={item.code}
                            val={item.pri_cd}
                            title={
                              item.sec_nm === null ? item.pri_nm : item.sec_nm
                            }
                            change={() => inputHandler(item, index)}
                            check={item.auth === "Y" ? true : false}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              </td>

              <th colSpan={1}>{translate("관리자", "Location Control")}</th>
              <td>
                <div className="custom-check-group">
                  {rawData.length !== 0 &&
                    rawData.map((item: any, index: any) => {
                      if (item.pri_nm === "관리자") {
                        return (
                          <Radio
                            type="checkbox"
                            key={index}
                            name={item.code}
                            val={item.pri_cd}
                            title={
                              item.sec_nm === null ? item.pri_nm : item.sec_nm
                            }
                            change={() => inputHandler(item, index)}
                            check={item.auth === "Y" ? true : false}
                          />
                        );
                      }
                      return null;
                    })}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
