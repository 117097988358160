import React, { useState, useEffect } from "react";
import "../style-boxMap.sass";
import Header from "../organs/Header";
//@ts-ignore
import Marker from "../molecules/Marker";
import GeoMarker from "../molecules/GeoMarker";
import Stat from "../molecules/Stat";
import Control from "../molecules/Control";
import Modal from "../organs/Modal";
import moment from "moment";
// @ts-ignore
import { add_hyphen } from "../../../lib/common_func.js";
//@ts-ignore
import axios from "../../../lib/axios";
//@ts-ignore
import { LanguageContext } from "../../../context/LanguageContext";
interface BoxProps {
  idx: number;
  map: any;
  naver: any;
  list: Array<any>;
  user: any;
  count: Array<number>;
}
export default function Box({
  idx,
  naver,
  map,
  list,
  user,
  count,
}: BoxProps) {
  // State
  // ==========================================================================
  const [selected, setSelected] = useState(Number);
  const [rawData, setRawtData] = useState<any>({});
  const [isNormal, setIsNormal] = useState(true);
  const [isStreetMode, setIsStreetMode] = useState(false);
  const [streetLayer] = useState(new naver.maps.StreetLayer());
  const [coord, setCoord] = useState<any>({ lat: 0, lng: 0 });
  const [circle, setCircle] = useState({ g1: null });
  const [geoMarker, setGeoMarker] = useState<any>({});
  const [refresh, setRefresh] = useState(false);
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);

  // Functions
  // ==========================================================================
  function changeMapType() {
    setIsNormal(!isNormal);
  }
  function changeStreetMode() {
    setIsStreetMode(!isStreetMode);
  }
  function reloadMap() {
    if (map && naver) {
      // Set the map's center and zoom level again
      map.setCenter(new naver.maps.LatLng(rawData.latitude, rawData.longitude));
      map.setZoom(14); // Example zoom level, adjust as needed
    }
  }
  function getStatSize() {
    switch(count.length) {
      case(1) :
        return 's-1';
      case(2) :
        return 's-1';
      case(3) :
        if(idx === 3) return 's-1';
      case(5) :
        if(idx === 3) return 's-1';
      default :
        return 's-2';
    }
  }
  // ==========================================================================
  // useEffect
  // ==========================================================================
  useEffect(() => {
    if (map && naver && rawData) {
      map.setCenter(new naver.maps.LatLng(rawData.latitude, rawData.longitude));
      // map.setZoom(14);
      if (isNormal) {
        map.setMapTypeId(naver.maps.MapTypeId.NORMAL);
      } else {
        map.setMapTypeId(naver.maps.MapTypeId.HYBRID);
      }
    }
  }, [map, naver, rawData, isNormal, isStreetMode, streetLayer]);
  useEffect(() => {
    if (isStreetMode) {
      streetLayer.setMap(map);
    }
    if (!isStreetMode) {
      if (streetLayer.getMap()) {
        streetLayer.setMap(null);
      }
    }
  }, [isStreetMode, streetLayer, map]);
  useEffect(() => {
    if (map && naver && isStreetMode) {
      // Add a click event listener to the map
      const clickListener = naver.maps.Event.addListener(
        map,
        "click",
        function (e: any) {
          const latlng = e.coord;
          setCoord({ lat: latlng.lat(), lng: latlng.lng() });
        }
      );

      if (coord.lat !== 0 && coord.lng !== 0 && isStreetMode) {
        const pano = new naver.maps.Panorama("pano", {
          position: coord,
          pov: {
            pan: 180,
            tilt: 0,
            fov: 90,
          },
        });
      }

      return () => {
        naver.maps.Event.removeListener(clickListener);
      };
    }
  }, [map, naver, coord, isStreetMode]);
  useEffect(() => {
    if (selected === 0) {
      setRawtData({});
      //@ts-ignore
      circle.g1?.setMap(null);
    }

    if (selected !== undefined && list !== undefined) {
      const selectedData = list.find((item) => item.idx === selected);
      // console.log(selectedData, "selectedData");

      if (selectedData?.unitid) {
        axios
          .get(
            `/web/map/multi/device?type=${user.type}&org_idx=${user.org_idx}&unitid=${selectedData.unitid}`
          )
          .then((res: any) => {
            setRawtData(res.data);
          })
          .catch((err: any) => {
            console.log(err);
          });
        return;
      }

      if (selectedData?.region_nm) {
        axios
          .get(
            `/web/map/multi/geofence?idx=${selectedData.idx}`
          )
          .then((res: any) => {
            if(res.status === 200) {
              setRawtData(res.data.geo);
              setGeoMarker(res.data.dev);
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    }
  }, [selected, list, user, refresh]);

  // console.log(parseInt(user.refreshPeriod), "user.refreshPeriod");
  useEffect(() => {
    setTimeout(() => {
      setRefresh(!refresh);
    }, parseInt(user.refreshPeriod) * 1000);

    // Cleanup timeout on component unmount or when data changes
  }, [rawData, user.refreshPeriod, refresh]);
  // console.log(refresh, "refresh");
  // console.log(rawData, "rawData");

  useEffect(() => {
    if(map) {
      map.setZoom(14);
    }
  },[selected])

  return (
    <>
      <div
        className="screen-box"
        style={{
          gridArea: `box-${idx}`,
        }}
      >
        <Header
          selected={selected}
          list={list}
          setSelected={setSelected}
          sn={rawData.unitid !== undefined ? add_hyphen(rawData.unitid) : ""}
          lang={language}
        >
          <Modal
            lang={language}
            rawData={rawData}
            addr={rawData.addr || rawData.road_addr}
            on={
              rawData.e_on
                ? moment(rawData.e_on).utc().format("YYYY-MM-DD HH:mm:ss")
                : "-"
            }
            off={
              rawData.e_off
                ? moment(rawData.e_off).utc().format("YYYY-MM-DD HH:mm:ss")
                : "-"
            }
            last={
              rawData.last
                ? moment(rawData.last).utc().format("YYYY-MM-DD HH:mm:ss")
                : "-"
            }
            day={rawData.day_dist ? rawData.day_dist : "00.0"}
            total={rawData.total_dist ? rawData.total_dist : "00.0"}
          />
        </Header>
        {rawData.unitid !== undefined && (
          <>
            <Stat
              speed={rawData.speed}
              innerBattery={parseInt(rawData.inner_battery) / 10}
              vehicleBattery={parseInt(rawData.vehicle_battery) / 10}
              gpsSv={rawData.gps_sv}
              battery={rawData.inner_battery}
              statSize={getStatSize()}
            />
            {map !== undefined && rawData !== undefined && (
              <Marker
                item={rawData}
                map={map}
                circle={circle}
                setCircle={setCircle}
                circleOn={false}
              />
            )}
          </>
        )}
        {rawData.region_nm !== undefined && (
          <>
            <GeoMarker
              item={rawData}
              map={map}
              setCircle={setCircle}
              circle={circle}
            />
            {map !== undefined &&
              geoMarker !== undefined &&
              geoMarker.map((item: any, index: number) => {
                return (
                  <Marker
                    key={index}
                    item={item}
                    map={map}
                    circle={circle}
                    setCircle={setCircle}
                    circleON={true}
                  />
                );
              })}
          </>
        )}
        <Control
          changeMapType={changeMapType}
          isNormal={isNormal}
          changeStreetMode={changeStreetMode}
          isStreet={isStreetMode}
          reloadMap={reloadMap}
        />
        <div id="map">
          <div id={`map_${idx}`} className="box-map"></div>
        </div>
      </div>
      {coord.lat !== 0 && coord.lng !== 0 && isStreetMode && (
        <div
          id="pano"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <button
            style={{
              position: "absolute",
              top: "85%",
              right: "50%",
              zIndex: 9999,
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              backgroundColor: "#2c2685",
              color: "white",
              fontSize: "30px",
            }}
            onClick={() => setCoord({ lat: 0, lng: 0 })}
          >
            X
          </button>
        </div>
      )}
    </>
  );
}
