import React from "react";
// @ts-ignore
import Header from "../../include/AppHeader";
// @ts-ignore
import Footer from "../../include/AppFooter";
import Nav from "../organism/Nav";

interface IProps {
  title: string;
  language: string;
  menu: string;
  dataList?: any;
  children: any;
}
export default function Page({
  language,
  dataList,
  children,
  title,
  menu,
}: IProps) {
  const style = {
    display: "flex",
    justifyContent: "center",
  };
  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div
            className="sub-page-wrapper"
            style={dataList.length === 0 ? style : {}}
          >
            <Nav menu={menu} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">{title}</div>
              </div>
              <div className="sub-page-body">{children}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
