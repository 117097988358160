import React from "react";
import { Link } from "react-router-dom";
import InputText from "./atoms/InputText";
import CheckBox from "./atoms/CheckBox";

interface LoginProps {
  language: string;
  companyCode: string;
  userId: string;
  password: string;
  changeFun: (event: any) => void;
  userInfo: object;
  submitFun: (event: any) => void;
  recent: Recent;
  close: () => void;
}
interface DataInput {
  title: string;
  id: string;
  value: string;
  placeholder: string;
  name: string;
}
interface Slogan {
  KOR: string;
  ENG: string;
  subKOR: string;
  subENG: string;
}
interface UserInfo {
  companyCode: string;
  userId: string;
  password: string;
  autoChk: boolean;
}
interface Recent {
  map(arg0: (data: any, index: any) => React.JSX.Element): React.ReactNode;
  idx: number;
  tit: string;
  date: string;
  ctnt: string;
  eme_yn: string;
}

export default function Login({
  language,
  userInfo,
  recent,
  changeFun,
  submitFun,
  close,
}: LoginProps) {
  // Modal State
  // Modal Open
  // Data Input
  const dataInputs: DataInput[] = [
    {
      title: language === "KOR" ? "회사코드" : "Comp.",
      id: "companyCode",
      value: (userInfo as UserInfo)["companyCode"],
      name: "companyCode",
      placeholder:
        language === "KOR"
          ? "회사코드를 입력하세요"
          : "Enter Your Company Code",
    },
    {
      title: language === "KOR" ? "사용자 ID" : "User ID",
      id: "userId",
      value: (userInfo as UserInfo)["userId"],
      placeholder:
        language === "KOR" ? "사용자 ID를 입력하세요" : "Enter User ID",
      name: "userId",
    },
    {
      title: language === "KOR" ? "비밀번호" : "Password",
      id: "password",
      value: (userInfo as UserInfo)["password"],
      placeholder:
        language === "KOR" ? "비밀번호를 입력하세요" : "Enter Password",
      name: "password",
    },
  ];
  // Data Slogan
  const slogan: Slogan = {
    KOR: "완벽한 위치관제를 위한 명품 솔루션",
    ENG: "Perfect Real-Time LBS Solution",
    subKOR: "명품 솔루션",
    subENG: "Solution",
  };
  // Data Recent

  // Modal Open

  // Modal Close

  return (
    <>
      <div className="intro-panel-wrapper">
        <form className="intro-module login-module">
          <div className="module-header">
            <div className="header-title">
              <i className="ri-shield-keyhole-fill icon"></i>
              {language === "KOR" ? "관제시스템 로그인" : "Sign in to LBS"}
            </div>
            <button className="close-btn" type="button" onClick={() => close()}>
              <i className="ri-close-fill"></i>
            </button>
          </div>
          <div className="module-body">
            {dataInputs.map((data, index) => {
              return (
                <InputText
                  key={index}
                  title={data["title"]}
                  id={data["id"]}
                  value={data["value"]}
                  placeholder={data["placeholder"]}
                  chageFun={changeFun}
                  name={data["name"]}
                  type={data["id"] === "password" ? "password" : "text"}
                />
              );
            })}
            <div className="etc">
              <CheckBox
                userInfo={userInfo}
                changeFun={changeFun}
                language={language}
                name="autoChk"
                value={(userInfo as { autoChk: boolean })["autoChk"]}
                title={language === "KOR" ? "아이디 저장" : "Remember ID"}
              />

              <Link to="/Privacy" className="link">
                {language === "KOR" ? "개인정보처리방침" : "Privacy"}
              </Link>
            </div>
          </div>
          <div className="module-footer">
            <button
              className="submit-btn"
              onClick={(e) => {
                submitFun(e);
              }}
            >
              {language === "KOR" ? "로그인" : "Sign in"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
