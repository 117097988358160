import React from "react";
import "./privacy.css";
export default function Privacy() {
  return (
    <>
      <main class="main-private">
        <section class="section-private">
          <div class="logo-p">
            <img src="../../assets/img/login/logo-klbs.svg" alt="" />
          </div>
          <h1 class="title-private">개인정보 및 개인위치정보 처리방침</h1>
          <p>
            주식회사 아이오티플렉스(이하 “회사”라고 합니다)는 정보통신 서비스
            제공자가 준수하여야 할 관련 법령상의 개인위치정보보호 규정을
            준수하며 관련 법령에 의거한 개인위치정보처리 방침을 정하여 이 용자의
            권익 보호에 최선을 다하고 있습니다. 회사는 이용자의 개인위치정보를
            보호하고 개인위치정보 와 관련한 이용자의 고충을 원활하게 처리 할 수
            있도록 개인위치정보처리방침을 제정, 시행하며 회사 가 개인위치정보
            처리방침을 개정하는 경우 '홈페이지(APP/WEB)' 의 공지사항(또는
            개별공지)을 통하 여 공지합니다.
          </p>
        </section>
        <section class="section-private">
          <h2>제 1 조 (총칙)</h2>
          <ol class="main-list">
            <li>
              <p>
                개인정보란 생존하는 개인에 관한 정보로서 성명 등에 의하여 특정한
                개인을 알아볼 수 있는 부호, 문자, 음성, 음향 및 영상 등의
                정보(해당 정보만으로는 특정 개인을 알아볼 수 없어도 다른 정보 와
                쉽게 결합하여 알아 볼 수 있는 경우에는 그 정보를 포함)를
                말합니다.
              </p>
            </li>
            <li>
              <p>
                개인위치정보란 특정 개인의 위치정보(위치정보만으로는 특정 개인의
                위치를 알 수 없는 경우에도 다른 정보와 용이하게 결합하여 특정
                개인의 위치를 알 수 있는 것을 포함함)를 말합니다.
              </p>
            </li>
            <li>
              <p>
                회사는 고객의 개인정보 및 개인위치정보를 매우 중요시하며, 법령
                및 방송통신위원회의 개인정보 보호지침상 개인위치정보 보호규정을
                준수하고 있습니다.
              </p>
            </li>
            <li>
              <p>
                회사는 개인위치정보취급방침을 통하여 고객의 개인정보 및
                개인위치정보가 어떤 용도와 방식으로 이용되고 있으며 개인위치정보
                보호를 위하여 어떤 조치가 취해지고 있는지 알려드립니다.
              </p>
            </li>
            <li>
              <p>
                회사는 개인위치정보취급방침을 서비스 첫 화면 또는 홈페이지에
                공개함으로써 고객이 언제나 쉽게 보고 확인할 수 있도록 조치하고
                있습니다.
              </p>
            </li>
            <li>
              <p>
                회사는 개인위치정보취급방침을 관련 법령 및 방송통신위원회의
                개인위치정보보호지침의 변경 또는 내부 운영규정의 변경에 따라
                개정할 수 있으며, 개인위치정보취급방침을 개정하는 경우 버전번호
                등을 부여하여 개정된 사항을 고객이 쉽게 알아볼 수 있도록 하고
                있습니다.
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 2 조 (개인정보의 수집항목 및 이용목적)</h2>
          <ol class="main-list">
            <li>
              <p>
                회사는 고객의 개인정보를 수집하는 경우 관련 법령에 따라
                가입신청서 또는 이용약관 등을 통하여 그 수집범위 및 수집 이용
                목적을 사전에 고지합니다.
              </p>
            </li>
            <li>
              <p>
                회사는 회원가입, 본인확인 및 원활한 고객상담, 각종 서비스의 제공
                등을 위해 아래와 같이 최소 한의 개인정보를 수집하고 있습니다.
              </p>
              <table class="table-private">
                <tbody>
                  <tr>
                    <th colspan="2">
                      회원 가입을 위해 수집하는 개인정보의 범위
                    </th>
                  </tr>
                  <tr>
                    <td>서비스 제목</td>
                    <td>수집 항목</td>
                  </tr>
                  <tr>
                    <td>스마트 폰 어플리케이션</td>
                    <td>
                      휴대폰 번호, 사회관계망 서비스 인증에 필요한 기본정보,
                      이메일 주소
                    </td>
                  </tr>
                  <tr>
                    <td>인터넷 회원 서비스</td>
                    <td>아이디, 전화 번호, 이메일 주소</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <p>
                회사는 이용자가 직접 제공 한 정보 외에 이용자가 회사가 제공하는
                서비스를 이용하는 과정에서 정보를 수집 할 수 있습니다.
              </p>
              <table class="table-private">
                <tbody>
                  <tr>
                    <th colspan="2">서비스 이용 시 수집하는 정보</th>
                  </tr>
                  <tr>
                    <td>수집 구분</td>
                    <td>수집 항목</td>
                  </tr>
                  <tr>
                    <td>
                      이용자 ,이용동의 확인 및 원활한 서비스 제공을 위한
                      기본정보
                    </td>
                    <td>
                      이름(닉네임), 휴대전화번호, 이메일, 프로필사진, 차량번호,
                      장비 식별자, 운영 체제, 하드웨어 버전, 장비 설정, 브라우저
                      종류 및 설정
                    </td>
                  </tr>
                  <tr>
                    <td>위치기반 서비스 제공을 위한 정보</td>
                    <td>
                      <p>- 개인/사물 GPS, WiFi 또는 기지국 기반 위치 정보</p>
                      <p>
                        - 위치정보 및 조회기록, 즐겨찾기 정보, 출발지/목적지
                        위치정보, 주행 경로, 차량위치정보
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>유료서비스 결제정보</td>
                    <td>신용카드정보, 은행계좌정보, 결제기록</td>
                  </tr>
                  <tr>
                    <td>통계 및 지표분석을 위한 정보</td>
                    <td>
                      <p>- 익명화 된 위치 데이터 수집</p>
                      <p>
                        - 광고, 조사 및 분석 목적으로 제 3 자와 위치 정보 공유
                        할 수 있음
                      </p>
                      <p>
                        - 위치 데이터 수집을 거부하려면 모바일 장치의 설정에서
                        회사가 서비 스하는 앱에 대한 위치 서비스를 비활성화 할
                        수 있음.
                      </p>
                      <p>
                        - 회사가 서비스하는 앱에서 계정을 삭제하면 위치 데이터가
                        파기됨.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>로그 정보</td>
                    <td>
                      IP 주소, 쿠키, 방문일시, 서비스 이용기록, 불량/비정상
                      이용기록, 접속로그
                    </td>
                  </tr>
                  <tr>
                    <td>개인정보 수집방법</td>
                    <td>
                      홈페이지, 서면양식, 팩스, 전화, 상담게시판, 이메일,
                      협력회사로부터의 제공
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table-private">
                <tbody>
                  <tr>
                    <td>고객 상담 시 수집하는 정보</td>
                  </tr>
                  <tr>
                    <td>성명, 연락처, 이메일 주소, 아이디</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <p>
                회사는 아래와 같은 목적으로 서비스 제공을 위해 최소한의
                개인위치정보만을 수집하며, 목적 외로 사용하지 않습니다.
              </p>
              <p>- 회원 관리 및 식별</p>
              <p>
                - 서비스의 무단 또는 부정 사용 또는 남용을 탐지 및 방지하기 위해
              </p>
              <p>
                - 사용자가 요구하는 서비스 제공에 대한 계약 이행, 서비스 요금
                지불 및 서비스 요금 정산
              </p>
              <p>- 기존 서비스 개선 및 신규 서비스 개발</p>
              <p>
                - 회사 사이트 또는 애플리케이션의 기능 또는 정책 변경 사항에
                대한 공지
              </p>
              <p>
                - 귀하가 이미 알고있는 다른 사용자와의 연결을 돕고 귀하의
                허락하에 다른 사용자가 귀하와 연결 하도록 허용합니다.
              </p>
              <p>
                - 회원의 서비스 이용 통계를 작성하고, 통계적 특성에 따라
                서비스를 제공하고 광고를 게재하기 위해
              </p>
              <p>
                - 프로모션 이벤트에 대한 정보를 제공하기 위해 참여 기회 제공
              </p>
              <p>
                - 프로모션 이벤트에 대한 정보를 제공하기 위해 참여 기회 제공
              </p>
              <p>- 이용자의 사전 동의하에 정보 이용 (예 : 마케팅 광고 활용)</p>
              <p>
                당사는 회사가 본 정책에 명시적으로 명시된 정보 이외의 정보를
                사용하기를 원하는 경우 사용자의 동 의를 얻는다는 데 동의합니다.
                [별 첨1] 개인 정보 보호 정책 부록 &lt;2&gt; 'GDPR에 따른 개인
                정보의 적법한 처리'선택 옵션
              </p>
            </li>
            <li>
              <p>
                회사는 법령의 규정 또는 고객의 동의 없이는 사상, 신념, 과거의
                병력 등 고객의 권리 또는 이익 이나 사생활을 뚜렷하게 침해할
                우려가 있는 개인위치정보를 수집하지 않습니다.
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 3 조 (개인위치정보의 수집방법)</h2>
          <ol class="main-list">
            <li>
              <p>
                회사의 모바일단말기용 애플리케이션에서 회원가입을 하는 경우,
                특정 서비스 화면상으로 동의한 개인위치정보를 수집합니다.
              </p>
              <p>
                - 애플리케이션, 웹페이지, 서면양식, 팩스, 전화, 이메일,
                문의하기, 배송요청
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 4 조 (개인위치정보의 이용 및 제공)</h2>
          <ol class="main-list">
            <li>
              <p>
                회사는 고객의 개인위치정보를 이용약관, 개인위치정보취급방침의
                개인위치정보 수집 및 이용 목적 상 고지한 범위 내에서 이용 및
                제공하며, 그 범위를 넘어서 이용하거나 제공하지 않습니다. 특히,
                다음의 경우는 주의를 기울여 개인위치정보를 이용 및 제공할
                것입니다.
              </p>
              <ol class="main-list">
                <li>
                  <p>
                    서비스 내에서 본인의 프로필
                    정보(성명/사진/전화번호/나이/성별/닉네임 및 본인이 작성한
                    게시물 은 다른 회원에게 공유됩니다.
                  </p>
                </li>
                <li>
                  <p>
                    매각/인수합병 등 회사의 서비스제공자로서의 권리와 의무가
                    완전 승계 또는 이전되는 경우 사전에 개인위치정보를
                    이전하려는 사실, 개인위치정보의 이전을 받는 자(이하
                    "영업양수자등")의 성명(법인 인 경우 법인의
                    명칭)/주소/전화번호 그 밖에 연락처, 고객이 개인위치정보의
                    이전을 원하지 아니하 는 경우 그 동의를 철회할 수 있는 방법과
                    절차에 대해 상세하게 고지할 것이며 고객의 개인위치정 보에
                    대한 동의 철회의 선택권을 부여합니다.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                고객의 동의가 있거나, 고객의 동의가 없더라도 요금정산을 위하여
                필요한 경우, 국세기본법, 지방 세법, 통신비밀보호법, 금융실명거래
                및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률,
                전기통신기본법, 전기통신사업법, 소비자기본법, 한국은행법,
                형사소송법 등 관련 법령에 특 별한 규정이 있는 경우에는,
                제(1)항의 규정에도 불구하고 회사는 고객의 개인위치정보 수집 시에
                고객에게 고지한 범위 또는 이용약관에 명시한 범위를 넘어서
                이용하거나 제3자에게 제공할 수 있 습니다. 다만, 관련 법령에 의한
                경우에도 고객의 개인위치정보를 무조건 제공하는 것은 아니며 법
                령에 정해진 절차와 방법에 따라 제공합니다.
              </p>
            </li>
            <li>
              <p>
                회사는 서비스의 제공에 관한 계약을 이행하기 위하여 필요한
                개인위치정보로서 경제적/기술적인 사유로 통상적인 동의를 받는
                것이 뚜렷하게 곤란한 경우에는 고객의 동의가 없더라도 개인위치정
                보를 수집/이용할 수 있습니다.
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 5 조 (개인위치정보의 이용 및 보관 기간)</h2>
          <ol class="main-list">
            <li>
              <p>
                고객(휴면 고객 포함)의 개인위치정보는 서비스 가입기간 동안
                이용/보관함을 원칙으로 하되, 불법 이용자의 재가입 방지, 명예훼손
                등 권리침해 분쟁 및 수사협조 등을 위하여 회원탈퇴 후 3개월간
                보존합니다.
              </p>
              <p>가. 회사 내부 방침에 의한 정보 보유 사유</p>
              <table class="table-private">
                <tbody>
                  <tr>
                    <th>보존 근거</th>
                    <th>보존 기록</th>
                    <th>보존 기간</th>
                  </tr>
                  <tr>
                    <td>부정이용 방지</td>
                    <td>부정이용기록(불량, 비정상 이용기록)</td>
                    <td>2년</td>
                  </tr>
                  <tr>
                    <td>민원처리 등</td>
                    <td>회원 탈퇴 기록</td>
                    <td>탈퇴일로부터 3개월</td>
                  </tr>
                </tbody>
              </table>
              <p>나. 관련 법령에 의한 정보보유 사유</p>
              <table class="table-private">
                <tbody>
                  <tr>
                    <th>보존 근거</th>
                    <th>보존 기록</th>
                    <th>보존 기간</th>
                  </tr>
                  <tr>
                    <td>통신비밀보호법</td>
                    <td>웹사이트 방문기록</td>
                    <td>3개월</td>
                  </tr>
                  <tr>
                    <td rowspan="5">
                      전자상거래 등에서 소비자 보호에 관한 법률
                    </td>
                  </tr>
                  <tr>
                    <td>계약 또는 청약철회 등에 관한 기록</td>
                    <td>5년</td>
                  </tr>
                  <tr>
                    <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                    <td>5년</td>
                  </tr>
                  <tr>
                    <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                    <td>3년</td>
                  </tr>
                  <tr>
                    <td>표시/광고에 관한 기록</td>
                    <td>6개월</td>
                  </tr>
                  <tr>
                    <td>전자금융거래법</td>
                    <td>전자금융에 관한 기록</td>
                    <td>5년</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <p>
                고객은 개인위치정보의 공개기간을 설정할 수 있으며, 공개기간을
                도래한 경우 회사는 고객의 게시 물 등의 개인위치정보를 블라인드
                처리합니다.
              </p>
            </li>
            <li>
              <p>
                회원이 본인이 회사의 회원임을 증빙하기 위해 회사에 발송한 신분증
                사본 등 서류일체는 본인확인 후 즉시 파기합니다.
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>
            제 6 조 (위치정보 수집·이용·제공 사실 확인자료의 보유 근거 및 보유
            기간의 보존 및 파기)
          </h2>
          <ol class="main-list">
            <li>
              <p>
                회사는 위치정보의 보호 및 이용 등에 관한 법률 제16조 제2항에
                근거하여 타사업자 또는 고객과의 요금정산 및민원처리를 위하여
                위치정보 수집·이용·제공 사실 확인 자료를 자동으로 기록하여
                보존합니다.
              </p>
            </li>
            <li>
              <p>
                회사는 제1항의 위치정보 수집·이용·제공 사실 확인 자료와
                위치정보시 스템에 접근 사실을 서비스별로 6개월간 보존하며, 그
                이외의 개인위치정보는 즉시 파기합니다. 단, 국세기본법, 법인세법,
                부가가치세법, 상법 등 관계 법령의 규정에 의하여 보존할 필요성이
                있는 경우에는 그에 따라 위치적 보 수집·이용·제공 사실 확인
                자료를 보존합니다.
              </p>
            </li>
            <li>
              <p>
                회사는 제2항 외 위치상이 등의 고객 민원 처리 및 요금 정산에
                의하여 개인위치정보를 보존할 필요성이 있어 개인위치정보를
                6개월간 보존하고 6개월 후 즉시 파기합니다.
              </p>
            </li>
            <li>
              <p>
                사용자는 쿠키를 설치할 수 있는 옵션이 있습니다. 따라서 웹
                브라우저에서 옵션을 설정하여 모든 쿠키를 허용하거나, 쿠키가
                저장될 때마다 각 쿠키를 확인하거나, 모든 쿠키의 저장을 거부할 수
                있습니다. 단, 사용자가 쿠키 설치를 거부할 경우 회사에서 제공하는
                서비스의 일부를 사용하기 어 려울 수 있습니다.
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 7조 (쿠키 및 유사 기술 수집)</h2>
          <ol class="main-list">
            <li>
              <p>
                회사는 '쿠키'를 통해 집단적이고 비인칭적인 정보를 수집할 수
                있습니다. 쿠키는 회사의 웹 사이트 운영에 사용되는 서버가
                사용자의 브라우저로 보내는 매우 작은 텍스트 파일이며 사용자
                컴퓨터의 하드 디스크에 저장됩니다.
              </p>
              <p>
                이 기능은 회사가 사용자에게 훨씬 개선된 서비스를 제공할 수
                있도록 서비스를 평가, 개선하고 사용 자 경험을 설정하는 데
                사용됩니다. 회사가 수집하는 쿠키의 품목 및 수집 목적은 다음과
                같습니다.
              </p>
              <table class="table-private">
                <tbody>
                  <tr>
                    <td>범주</td>
                    <td>쿠키 사용 이유 및 추가 정보</td>
                  </tr>
                  <tr>
                    <td>필수적 쿠키 necessary cookies</td>
                    <td>
                      <p>
                        이 쿠키는 이용자가 회사의 웹 사이트 기능을 이용하기 위해
                        반드시 필요한 일종의 쿠 키입니다. 이용자가 이 쿠키를
                        허용하지 않으면 장바구니, 전자 요금 결제 등의 서비 스를
                        제공 할 수 없습니다. 이 쿠키는 이용자가 방문한 사이트의
                        마케팅이나 기억에 사용될 수 있는 정보를 수집하지
                        않습니다.
                      </p>
                      <p>- 웹 사이트 로그인 여부 확인</p>
                      <p>
                        - 회사가 웹사이트 운영방식을 변경하는 동안 사용자가
                        회사의 웹사이트의 올바른 서 비스에 연결되어 있는지 확인
                      </p>
                      <p>
                        - 이용자를 특정 애플리케이션 또는 서비스 서버와 연결
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>향상된 쿠키</td>
                    <td>
                      <p>
                        이 쿠키는 사용자가 가장 많이 방문하는 페이지 정보 등
                        사용자가 회사의 웹 사이트를 사용하는 방법에 대한 정보를
                        수집합니다. 이 데이터는 사용자가 해당 웹 사이트를 보 다
                        편안하게 검색할 수 있도록 회사가 웹 사이트를 최적화하는
                        데 도움이 됩니다. 이 쿠키는 사용자인 정보를 수집하지
                        않습니다. 이 쿠키로 수집된 모든 정보는 일괄 처리 되며
                        익명성은 보장됩니다.
                      </p>
                      <p>- 웹분석 : 웹사이트 이용방법 통계자료 제공</p>
                      <p>- 웹분석 : 웹사이트 이용방법 통계자료 제공</p>
                      <p>- 설계시험 : 당사 홈페이지의 기타설계 시험</p>
                    </td>
                  </tr>
                  <tr>
                    <td>선택적 쿠키</td>
                    <td>
                      <p>
                        이 쿠키는 회사가 서비스를 제공하고 사용자의 방문을
                        개선하기 위해 설정을 암기하는 데 사용됩니다. 이 쿠키로
                        수집된 정보는 사용자를 개별적으로 식별하지 않습니다.
                      </p>
                      <p>
                        - 레이아웃, 텍스트 크기, 기본 설정, 색상 등 적용된 설정
                        기억- 레이아웃, 텍스트 크기, 기본 설정, 색상 등 적용된
                        설정 기억
                      </p>
                      <p>- 당사에서 실시한 설문조사에 고객이 응답할 때 기억</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <p>
                사용자는 쿠키를 설치할 수 있는 옵션이 있습니다. 따라서 웹
                브라우저에서 옵션을 설정하여 모든 쿠키를 허용하거나, 쿠키가
                저장될 때마다 각 쿠키를 확인하거나, 모든 쿠키의 저장을 거부할 수
                있습니다. 단, 사용자가 쿠키 설치를 거부할 경우 회사에서 제공하는
                서비스의 일부를 사용하기 어 려울 수 있습니다.
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 9 조 (고객의 권리와 그 행사방법)</h2>
          <ol class="main-list">
            <li>
              <p>
                고객은 언제든지 회사가 보유하는 개인위치정보, 개인위치정보의
                이용/제공내역, 수집/이용/제공에 대한 동의내역을 열람하거나
                정정할 수 있습니다. 해당 개인위치정보에 오류가 있거나 보존기간이
                경과한 것으로 판명되는 등 정정/삭제를 할 필요가 있다고 인정되는
                경우에는 회사는 지체 없이 이를 수정합니다.
              </p>
            </li>
            <li>
              <p>
                온라인 가입정보의 열람 및 정정을 하고자 하는 고객의 경우에는
                "회원정보변경"을 클릭하여 직접 열람 및 정정을 하거나 사이버
                고객센터 온라인 문의 또는 웹마스터에게 이메일로 연락하는 경우
                에도 회사는 지체 없이 필요한 조치를 취합니다.
              </p>
            </li>
            <li>
              <p>
                회사는 대리인이 방문하여 열람증명을 요구하는 경우에는 적법한
                위임을 받았는지 확인할 수 있는 위임장 및 본인의 인감증명서와
                대리인의 신분증 등을 제출 받아 정확히 대리인 여부를 확인합니다.
              </p>
            </li>
            <li>
              <p>
                고객이 개인위치정보의 오류에 대한 정정을 요청한 경우, 정정이
                완료되기 전까지 개인위치정보를 이용 또는 제공하지 않습니다.
              </p>
            </li>
            <li>
              <p>
                회사는 잘못된 개인위치정보를 이미 제3자에게 제공한 경우 그 정정
                처리결과를 제3자에게 지체 없이 통지하여 정정하도록 합니다.
              </p>
            </li>
            <li>
              <p>
                고객은 개인위치정보를 최신의 상태로 정확하게 입력하고 변동
                사항이 있는 경우, 이를 회사에 통 보하여야 하며, 스스로 부정확한
                정보를 입력하거나, 회사에 통보하지 않아서 회사가 알 수 없는
                고객정보의 변동으로 인한 책임은 고객 자신에게 귀속됩니다.
              </p>
            </li>
            <li>
              <p>
                고객이 타인 정보의 도용이나 침해, 허위정보를 입력하는 경우
                서비스 해지 및 회원자격이 상실될 수 있으며 법 등 관련 법령에
                따라 처벌받을 수 있습니다.
              </p>
            </li>
            <li>
              <p>
                고객이 개인위치정보의 열람/제공을 반복적으로 요구하는 등 업무에
                지장을 줄 우려가 있거나 그 분량이 상당하여 비용이 드는 경우,
                회사는 고객의 요구를 연기 또는 거절하거나 업무처리에 따른
                실비(복사비 등)를 고객에게 청구할 수 있습니다.
              </p>
            </li>
            <li>
              <p>
                이용자 또는 법정 대리인은 정보의 주체로서 회사의 개인 정보 수집,
                이용, 공유와 관련하여 다음 과 같은 권리를 행사할 수 있습니다.
              </p>
              <p>- 개인위치정보에 대한 접근 권한을 행사</p>
              <p>- 수정 또는 삭제</p>
              <p>- 개인 정보의 처리를 일시적으로 중단</p>
            </li>
            <li>
              <p>
                권리를 행사하기 위하여 이용자는 '회원 정보 수정'메뉴를
                이용하거나 문서 나 이메일을 보내거나 전화로 회사에 연락하는 경우
                이전에 제공 한 동의 철회를 요청합니다. 회사(또는 개인 정보 관리
                책임자 또는 대리인)에 대해 회사는 지체없이 조치를 취합니다.
              </p>
              <p>
                단, 법률에서 정한 적절한 사유 또는 이에 상응하는 사유가 존재하는
                경우에만 회사가 귀하의 요청 을 거부할 수 있습니다.
              </p>
              <p>
                [별 첨1] 개인 정보 보호 정책 부록 &lt;2&gt; 'GDPR 적용 시 사용자
                권리' 선택 옵션
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 10 조 (개인위치정보 주체의 위치정보 이용에 관한 동의)</h2>
          <ol class="main-list">
            <li>
              <p>
                회사가 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는
                미리 개인위치정보 주체의 동 의를 얻어 시행합니다.
              </p>
            </li>
            <li>
              <p>
                개인위치정보 주체는 제1항의 규정에 의한 동의를 하는 경우
                이용약관의 내용 중 일부에 대하여 동 의를 유보할 수 있습니다.
              </p>
            </li>
            <li>
              <p>
                회사가 개인위치정보를 개인위치정보 주체가 지정하는 제3자에게
                제공하는 서비스를 하고자 하는 경우에는 개인위치정보 주체가 가입
                동의한 그룹내의 사용자들에게 자신의 위치정보를 제공함으로 써
                그룹의 안전 및 중요한 물건의 분실을 예방하기 위한 목적으로
                사용할 수
              </p>
              있습니다.
            </li>
            <li>
              <p>
                제3항의 규정에 의하여 회사가 개인위치정보를 개인위치정보 주체가
                지정하는 제3자에게 제공하는 경우에는 매회 개인위치정보 주체에게
                제공받는 자, 제공 일시 및 제공 목적을 즉시 통보함. 개인 위치정보
                주체는 제1항 및 제3항의 규정에 의한 동의를 하는 경우
                개인위치정보의 이용 및 제공목 적, 제공받는 자의 범위 및
                위치기반서비스의 일부에 대하여 동의를 유보할 수 있습니다.
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 11 조 (개인위치정보 등의 이용ㆍ제공의 제한 등)</h2>
          <p>
            회사는 개인위치정보 주체의 동의가 있거나 다음 각 호의 1에 해당하는
            경우를 제외하고 개인위치정보 또는 위치정보 이용ㆍ제공사실 확인자료를
            제9조 제1항 및 제3항에 의하여 이용 약관에 명시 또는 고 지한 범위를
            넘어 이용하거나 제3자에게 제공하지 않습니다.
          </p>
          <ol class="main-list">
            <li>
              위치기반 서비스 등의 제공에 따른 요금 정산을 위하여 위치정보
              이용ㆍ제공사실 확인자료가 필요 한 경우
            </li>
            <li>
              통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수
              없는 형태로 가공하여 제공하 는 경우
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 12 조 (개인위치정보 주체의 권리 등)</h2>
          <ol class="main-list">
            <li>
              <p>
                개인위치정보 주체는 회사에 대하여 언제든지 제9조 제1항 및
                제3항의 규정에 의한 동의의 전부 또는 일부를 철회할 수 있습니다.
              </p>
            </li>
            <li>
              <p>
                개인위치정보 주체는 회사에 대하여 언제든지 개인위치정보의 이용
                또는 제공의 일시적인 중지를 요구할 수 있음. 이 경우 회사는
                요구를 거절하지 아니하며, 이를 위한 기술적 조치를 취합니다.
              </p>
            </li>
            <li>
              <p>
                개인위치정보 주체는 회사에 대하여 언제든지 개인위치정보의 이용
                또는 제공의 일시적인 중지를 요구할 수 있음. 이 경우 회사는
                요구를 거절하지 아니하며, 이를 위한 기술적 조치를 취합니다.
              </p>
              <ol class="main-list">
                <li>
                  <p>본인에 대한 위치정보 이용ㆍ제공사실 확인 자료</p>
                </li>
                <li>
                  <p>본인의 개인위치정보가 제3자에게 제공된 이유 및 내용</p>
                </li>
              </ol>
            </li>
            <li>
              <p>본인의 개인위치정보가 제3자에게 제공된 이유 및 내용</p>
            </li>
            <li>
              <p>
                개인위치정보 주체는 본 조의 권리행사를 위하여 방문, 전화, 인터넷
                등의 방법으로 회사에 대한 요구사항을 신청할 수 있습니다.
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 13 조 (법정대리인의 권리)</h2>
          <ol class="main-list">
            <li>
              <p>
                회사가 14세 미만의 아동으로부터 제9조 제1항 및 제3항 또는
                제10조의 규정에 의하여 개인위치정 보를 이용 또는 제공하고자 하는
                경우(개인위치정보주체가 지정하는 제3자에게 제공하는 서비스를
                하고자 하는 경우 포함)에는 그 법정 대리인의 동의를 얻어야
                합니다.
              </p>
            </li>
            <li>
              <p>
                회사는 14세 미만의 아동의 개인위치정보 또는 위치정보
                이용?제공사실 확인자료를 이용약관에 명 시 또는 고지한 범위를
                넘어 이용하거나 제3자에게 제공하고자 하는 경우에는 14세미만의
                아동과 그 법정대리인의 동의를 받아야 합니다. 단, 아래의 경우는
                제외합니다.
              </p>
              <ol class="main-list">
                <li>
                  <p>
                    위치정보 및 위치기반서비스 등의 제공에 따른 요금정산을
                    위하여 위치정보 이용, 제공사실 확인자 료가 필요한 경우
                  </p>
                </li>
                <li>
                  <p>
                    통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼
                    수 없는 형태로 가공하여 제공하 는 경우
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                제9조 제2항, 제11조의 규정은 본 조 제1항의 규정에 의하여 법정
                대리인이 동의를 하는 경우에 이를 준용하며 이 경우 개인위치정보
                주체는 법정대리인으로 봅니다.
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 14 조 (8세 이하의 아동 등의 보호를 위한 위치정보 이용)</h2>
          <ol class="main-list">
            <li>
              <p>
                다음 각 호의 1에 해당하는 자(이하 “8세 이하의 아동 등”이라 함)의
                보호의무자가 8세 이하의 아 동 등의 생명 또는 신체의 보호를
                위하여 8세 이하의 아동 등의 개인위치정보의 이용 또는 제공에
                동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.
              </p>
              <ol class="main-list">
                <li>
                  <p>8세 이하의 아동</p>
                </li>
                <li>
                  <p>금치산자</p>
                </li>
                <li>
                  <p>
                    장애인복지법 제2조 제2항 제2호의 규정에 의한 정신적 장애를
                    가진 자로서 장애인고용촉진 및 직 업재활법 제2조 제2호의
                    규정에 의한 중증장애인에 해당하는 자(장애인복지법 제32조의
                    규정에 의 하여 장애인등록을 한 자에 한 함)
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                제1항의 규정에 의한 8세 이하의 아동 등의 보호의무자는 8세 이하의
                아동 등을 사실상 보호하는 자로서 다음 각 호의 1에 해당하는 자를
                말합니다.
              </p>
              <ol class="main-list">
                <li>
                  <p>
                    8세 이하의 아동의 법정대리인 또는 보호시설에 있는 미성년자의
                    후견직무에 관한 법률 제3조의 규 정에 의한 후견인
                  </p>
                </li>
                <li>
                  <p>금치산자의 법정대리인</p>
                </li>
                <li>
                  <p>
                    제1항 제3호의 자의 법정대리인 또는 장애인복지법 제58조 제1항
                    제1호의 규정에 의한 장애인생활 시설(국가 또는 지방자치단체가
                    설치ㆍ운영하는 시설에 한 함)의 장, 정신보건법 제3조 제4호의
                    규 정에 의한 정신질환자 사회복귀시설(국가 또는
                    지방자치단체가 설치ㆍ운영하는 시설에 한 함)의 장, 정신보건법
                    제3조 제5호의 규정에 의한 정신요양시설의 장
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                제9조 내지 제11조의 규정은 제2항의 규정에 의하여 보호의무자가
                동의를 하는 경우에 이를 준용 하며 이 경우 개인위치정보 주체는
                보호의무자로 봅니다.
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 15 조 (개인위치정보 수집/이용/제공에 대한 동의철회)</h2>
          <ol class="main-list">
            <li>
              <p>
                개인위치정보의 수집/이용/제공에 대해 고객은 동의한 내용을
                언제든지 철회할 수 있습니다. 동의 철회는 서비스 내 설정 화면의
                "계정삭제"를 클릭하거나 개인위치정보관리책임자 및 담당자에게 서
                면, 전화, 이메일 등으로 연락하면 하실 수 있으며 회사는 지체 없이
                개인위치정보의 삭제 등 필요 한 조치를 합니다
              </p>
            </li>
            <li>
              <p>
                회사는 고객이 동의철회를 하여 개인위치정보를 파기하는 등의
                조치를 취한 경우 고객의 요청에 따 라 그 사실을 고객에게
                통지합니다.
              </p>
            </li>
            <li>
              <p>
                회사는 개인위치정보의 수집에 대한 동의철회(계정삭제)를
                개인위치정보를 수집하는 방법보다 쉽게 하겠습니다.
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 16 조 (개인위치정보보호를 위한 기술적/관리적 대책)</h2>
          <ol class="main-list">
            <li>
              <p>
                기술적 대책 회사는 개인위치정보를 취급함에 있어 분실, 도난,
                누출, 변조 또는 훼손되지 않도록 안전성 확보를 위 하여 다음과
                같은 기술적 대책을 강구하고 있습니다.
              </p>
              <ol class="main-list">
                <li>
                  <p>접속기록의 위/변조 방지 조치를 취하고 있습니다.</p>
                </li>
                <li>
                  <p>
                    개인위치정보는 그 종류에 따라 관련 법령이 요구하는 수준의
                    암호화 저장 또는 전송 시 암호화 기 술의 적용하여 관리합니다.
                  </p>
                </li>
                <li>
                  <p>
                    백신프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를
                    방지하기 위한 조치를 취하고 있으며, 백 신프로그램을
                    주기적으로 업데이트하고 갑작스러운 바이러스 출현 시에 백신이
                    나오는 즉시 이를 제공함으로써 개인위치정보가 침해되는 것을
                    방지하고 있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    암호알고리즘을 이용하여 네트워크상 개인위치정보를 안전하게
                    전송하는 보안장치를 채택하고 있습 니다.
                  </p>
                </li>
                <li>
                  <p>
                    해킹 등 외부 침입에 대비하여 각 서버마다 침입차단시스템 및
                    취약점분석시스템을 이용하여 보안 에 최선을 다하고 있습니다.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>관리적 대책</p>
              <ol class="main-list">
                <li>
                  <p>
                    회사는 개인위치정보 보호에 관한 내부관리계획을 수립,
                    시행하고 있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    회사는 개인위치정보에 대한 접근권한을 필요 최소한의 인원으로
                    제한하고 있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    회사는 개인위치정보를 취급하는 직원을 대상으로 새로운
                    보안기술 습득 및 개인위치정보 보호의무 등에 관해 정기적인
                    사내 교육 및 외부 위탁 교육을 실시합니다.
                  </p>
                </li>
                <li>
                  <p>
                    회사는 직원 입사 시 보안서약서를 통하여 사람에 의한
                    정보유출을 사전에 방지하고 개인위치정보 취급방침에 대한
                    이행사항 및 직원의 준수 여부를 감사하기 위한 내부절차를
                    마련하고 있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    개인위치정보 취급자의 업무 인수인계는 보안이 유지된 상태에서
                    철저하게 이루어지도록 하며 입사 및 퇴사 후 개인위치정보
                    사고에 관한 책임을 명확히 합니다.
                  </p>
                </li>
                <li>
                  <p>
                    개인위치정보와 일반 데이터는 혼합하여 보관하지 않고 분리하여
                    보관합니다.
                  </p>
                </li>
                <li>
                  <p>
                    전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여 출입을
                    통제합니다.
                  </p>
                </li>
                <li>
                  <p>
                    회사는 고객의 실수나 기본적인 인터넷의 위험성 때문에
                    일어나는 일들에 대해 책임을 지지 않습니 다. 고객 스스로가
                    본인의 개인위치정보를 보호하기 위해서 자신의 아이디와
                    패스워드를 적절하게 관리하고 스스로 책임을 져야 합니다.
                  </p>
                </li>
                <li>
                  <p>
                    회사는 내부 관리자의 실수나 기술관리상 사고로 인하여
                    개인위치정보의 도난, 유출, 변조, 훼손이 유발될 경우 적절한
                    대책과 보상을 강구합니다.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 17 조 (서비스의 게시물)</h2>
          <ol class="main-list">
            <li>
              <p>
                회사는 고객의 게시물을 소중하게 생각하며 변조, 훼손, 삭제되지
                않도록 최선을 다하여 보호합니다. 그러나 다음의 경우 게시물에
                대하여 삭제, 차단, 변경, 삭제/변경 요구, 경고, 이용정지, 기타 적
                절한 조치를 취할 수 있습니다.
              </p>
              <ol class="main-list">
                <li>
                  <p>
                    스팸성 게시물(예: 행운의 편지, 8억 메일, 특정사이트의 광고
                    등)
                  </p>
                </li>
                <li>
                  <p>
                    타인을 비방할 목적으로 사실 또는 허위 사실을 유포하여 타인의
                    명예를 훼손하는 글
                  </p>
                </li>
                <li>
                  <p>
                    동의 없는 신상공개, 초상권 또는 저작권 등 타인의 권리를
                    침해하는 내용
                  </p>
                </li>
                <li>
                  <p>
                    기타 법령에 반하거나 미풍양속을 저해 또는 게시판의 주제와
                    다른 내용의 게시물
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                회사는 건전한 게시물 문화를 활성화하기 위하여 동의 없는 타인의
                신상공개 시 특정부분을 삭제하 거나 기호 등으로 수정하여 게시할
                수 있습니다.
              </p>
            </li>
            <li>
              <p>
                그 외의 경우 명시적 또는 개별적인 경고 후 삭제 조치할 수
                있습니다.
              </p>
            </li>
            <li>
              <p>
                원칙적으로 게시물에 관련된 제반 권리와 책임은 작성자 개인에게
                있습니다. 또 게시물을 통해 자 발적으로 공개된 정보는 보호받기
                어려우므로 정보게시 전에 심사숙고 하기 바랍니다.
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 18 조 (개인위치정보 제 3자 제공)</h2>
          <p>
            회사는 개인위치정보를 수집할 때 고객에게 알린 범위 또는 서비스
            이용약관에 밝힌 범위를 넘어 개인 위치정보를 이용하거나 제 3 자에게
            제공하지 않습니다.
          </p>
          <p>
            다만, 고객의 동의가 있거나 법령의 규정에 의한 경우는 예외로 합니다.
            특히 다음의 경우는 주의를 기울여 개인위치정보를 이용 및 제공 할 것
            입니다.
          </p>
          <ol class="main-list">
            <li>
              <p>
                회사가 계열사, 협력업체 및 서비스 제공업체에 정보를 공개할 때
              </p>
              <p>
                - 당사의 계열사, 협력업체 및 서비스 제공업체가 당사에 대한
                청구서 지불, 주문의 실행, 제품 인도, 분쟁 해결(지급 및 인도 시
                분쟁 포함) 등의 서비스를 대행하는 경우
              </p>
            </li>
            <li>
              <p>사용자가 사전 공개에 동의한 경우</p>
              <p>
                - 사용자가 특정 회사의 제품 및 서비스 정보를 해당 회사와
                공유하여 제공받기로 선택한 경우
              </p>
              <p>
                - 소셜네트워크서비스(SNS) 등 타사의 사이트나 플랫폼과
                개인위치정보를 공유할 수 있도록 사용자 가 선택한 경우
              </p>
              <p>
                - 그 밖에 개인위치정보를 공유하기 위해 사전 동의를 하는 경우
              </p>
            </li>
            <li>
              <p>법률에 의해 공개가 요구되는 경우:</p>
              <p>- 법령에 의해 공개되어야 할 경우</p>
              <p>
                - 법령에 규정된 절차 및 방법에 따라 수사기관이 범죄 적발 시
                공개하도록 요구하는 경우
              </p>
            </li>
          </ol>
        </section>
        <section class="section-private">
          <h2>제 19조 (개인위치정보 취급위탁)</h2>
          <p>
            회사는 향상된 서비스 제공을 위하여 전문업체에 일부 서비스를 위탁
            처리하고 있습니다. 회사는 위탁 시 관련법령에 따라 위탁 계약 시
            개인위치정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고
            있습니다.
          </p>
          <table class="table-private">
            <tbody>
              <tr>
                <td>수탁업체</td>
                <td>위탁업무 내용</td>
                <td>보유 및 이용기간</td>
              </tr>
              <tr>
                <td>토스페이</td>
                <td>전자결제 수단 제공</td>
                <td>회원탈퇴 혹은 위탁 계약 종료시까지</td>
              </tr>
              <tr>
                <td>롯데택배</td>
                <td>물품 배송</td>
                <td>회원탈퇴 혹은 위탁 계약 종료시까지</td>
              </tr>
            </tbody>
          </table>
          <p>
            회사가 회원님의 개인정보를 위탁하는 경우는 주문 상품 배송 업무상
            최소한의 배송정보를 제공하는 경우와 특정 서비스 제공을 위해 제공되는
            경우입니다.
          </p>
        </section>
        <section class="section-private">
          <h2>제 20 조 (개인위치정보 관리책임자 및 상담신고)</h2>
          <p>
            고객의 개인위치정보를 보호하고 개인위치정보와 관련된 불만을 처리하기
            위하여 당사는 개인위치정보 관리책임자를 두고 있습니다.
          </p>
          <p>
            고객의 개인위치정보와 관련한 문의사항이 있으시면 아래의 개인위치정보
            관리책임자 또는 개인위치정 보 관리담당자에게 연락 주시기 바랍니다.
          </p>
          <p>
            - 개인위치정보 관리책임자: 박문수(대표이사), 070-7430-9900,
            pms@iotplex.co.kr
          </p>
          <p>
            - 개인위치정보 관리담당자 : 최휘민(선임연구원), 070-4304-0219,
            rnd@iotplex.co.kr
          </p>
          <p>
            기타 개인위치정보에 관한 상담이 필요한 경우에는
            개인위치정보침해신고센터, 정보보호마크 인증위원 회, 대검찰청
            인터넷범죄수사센터, 경찰청 사이버범죄수사대 등으로 문의하실 수
            있습니다.
          </p>
          <p>
            - 개인위치정보침해신고센터: 전화 118 / 이메일 118@kisa.or.kr /
            홈페이지 http://www.118.or.kr
          </p>
          <p>
            - 정보보호마크 인증위원회: 전화 02-580-0533 / 홈페이지
            http://www.privacymark.or.kr
          </p>
          <p>
            - 대검찰청 인터넷범죄수사센터: 전화 02-3480-3600 / 홈페이지
            http://icic.sppo.go.kr
          </p>
          <p>
            - 경찰청 사이버테러 대응센터: 전화 02-392-0330 / 홈페이지
            http://www.police.go.kr
          </p>
        </section>
        <section class="section-private">
          <h2>제 21 조 (기타)</h2>
          <p>- 타사 사이트 및 서비스</p>
          <p>
            [별 첨1] 개인 정보 보호 정책 부록 &lt;3&gt; 제 3 자 사이트 및 서비스
            회사
          </p>
        </section>
        <section class="section-private">
          <h2>제 22 조 (부칙)</h2>
          <p>
            개인위치정보취급방침은 2016년 12월 21일에 제정되었으며, 정부의 정책
            또는 보안기술의 변경에 따라 내용의 추가·삭제 및 수정이 있을 시에는
            개정 전에 APP, 웹페이지의 "공지" 란을 통해 고지합니다.
          </p>
          <p>- 본 개인위치정보 취급방침은 2016년 12월 21일부터 시행함.</p>
          <p>- 본 개인위치정보 취급방침은 2021년 10월 15일부터 시행함.</p>
        </section>
        <section class="section-private">
          <h2>[별 첨1] 개인 정보 보호 정책 부록</h2>
          <ol class="main-list">
            <li>
              <h3>
                GDPR에 따른 개인정보의 적법한 처리 회사의 개인정보 처리는 다음
                중 적어도 하나에 해당하는 경우에만 적법합니다.
              </h3>
              <ul class="main-list-ul">
                <li>
                  <p>사용자가 자신의 개인정보 처리에 동의 한 경우.</p>
                </li>
                <li>
                  <p>
                    사용자가 당사자 인 계약을 이행하거나 계약을 체결하기 전
                    이용자의 요청에 따라 조치를 취하기 위해 처리가 필요합니다.
                  </p>
                  <p>- 회원 관리, 본인 확인 등</p>
                  <p>
                    - 관련 계약 이행 이용자가 필요로 하는 서비스 제공, 요금 결제
                    및 정산 등.
                  </p>
                </li>
                <li>
                  <p>회사의 법적 의무 준수를 위해 처리가 필요합니다.</p>
                  <p>- 관련 법률, 규정, 법적 절차, 정부의 요청 준수</p>
                </li>
                <li>
                  <p>
                    사용자 또는 기타 자연인의 중요한 이익을 보호하기 위해 처리가
                    필요합니다.
                  </p>
                  <p>
                    - 사기, 남용, 보안 위험의 탐지, 예방 및 대응, 사용자 또는
                    기타 자연인에게 해를 끼칠 수있는 기술적 문제
                  </p>
                </li>
                <li>
                  <p>
                    공익을 위해 수행되는 작업을 수행하거나 회사에 부여 된 공식
                    권한을 사용하기 위해 처리가 필요합니다.
                  </p>
                </li>
                <li>
                  <p>
                    회사 또는 제 3자가 추구하는 정당한 이익을 위해 처리가
                    필요합니다. (개인 데이터 보호가 필요한 데이터 주 체의
                    이익이나 기본적 권리 및 자유에 의해 그러한 이익이 우선하는
                    경우, 특히 데이터 주체는 아동 임)
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <h3>GDPR 적용시 이용자의 권리</h3>
              <p>
                이용자 또는 법정대리인은 정보의 주체로서 회사의 개인정보 수집,
                이용 및 공유와 관련하여 다음과 같은 권리 를 행사할 수 있습니다.
              </p>
              <ul class="main-list-ul">
                <li>
                  <p>
                    • 개인정보에 대한 접근권 이용자 또는 법정대리인은 해당
                    정보에 접근하여 관련법에 따라 정보의 수집, 이용 및 공유에
                    대한 기록을 확인할 수 있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    수정할 권리; 이용자 또는 법정대리인은 부정확하거나 불완전한
                    정보의 정정을 요청할 수 있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    수정할 권리; 이용자 또는 법정대리인은 부정확하거나 불완전한
                    정보의 정정을 요청할 수 있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    처리 제한에 대한 권리; 이용자 또는 법정대리인은 정보의
                    정확성, 정보 처리의 적법성 등에 관한 분쟁이 있거 나 정보의
                    보유가 필요한 경우 개인정보의 처리를 일시적으로 중지 할 수
                    있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    데이터 이동권리; 사용자 또는 법정대리인은 정보 제공 또는
                    이전을 요청할 수 있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    반대할 권리; 이용자 또는 법정대리인은 직접 마케팅, 합리적인
                    이익, 공무 및 권한 행사, 조사·통계 등의 목적 으로 개인정보를
                    이용하는 경우에는 개인정보 처리를 중지 할 수 있습니다.
                  </p>
                </li>
                <li>
                  <p>
                    프로파일링을 포함한 자동화 된 개인 의사 결정 권한; 사용자
                    또는 법정대리인은 개인정보에 중대한 영향을 미 치거나 법적
                    영향을 미치는 프로파일링을 포함한 개인정보의 자동화 된
                    처리를 중단하도록 요청할 수 있습니 다. 위의 권리를 행사하기
                    위하여 이용자는 '웹 페이지 회원 정보 수정'메뉴를 이용하거나
                    문서 나 이메일을 보내거나 회사에 전화로 연락을 하는 경우
                    (담당자) 회사는 개인정보의 관리 또는 대리인)에 대해 지체없이
                    조 치를 취합니다. 다만, 회사는 법령에서 정한 정당한 사유
                    또는 이에 준하는 사유가 있는 경우에만 귀하의 요 청을 거부 할
                    수 있습니다.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <h3>제 3 자 사이트 및 서비스</h3>
              <p>
                회사의 웹 사이트, 상품, 서비스에는 제 3 자 링크가 포함될 수
                있으며, 제 3 자 사이트의 개인 정보 보호 정책 이 다를 수
                있습니다. 따라서 이용자는 회사의 사이트에 링크 된 제 3 자
                사이트의 정책을 추가적으로 확인해야 합니다
              </p>
            </li>
          </ol>
        </section>
      </main>
    </>
  );
}
