import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Certify from "../../components/certify/Certify";
import useFetch from "../../hook/useFetch";

export default function CertifyPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useFetch(
    `/api/order?access=${searchParams.get("access")}`
  );
  
    useEffect(()=>{
      console.log(data?.count);
      if(!isLoading && data?.count === 0){
        alert("접근이 만료된 페이지입니다")
        window.location.href = "https://4guard.co.kr/"
      }
    },[data, isLoading])

  return(
    <>
    {/* {isLoading && <Loading />} */}

    {!isLoading && (data.count !== 0) && <Certify data={data} /> }
    </>
  )  
}
