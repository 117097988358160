import React from "react";
interface Props {
  auto: string;
  type: string;
  label: string;
  required?: string;
  val: any;
  changeFun: (e: any) => void;
  name: string;
  readonly?: boolean;
  colSpan?: number;
}
export default function InputForm({
  required,
  label,
  auto,
  type,
  val,
  changeFun,
  name,
  readonly,
  colSpan,
}: Props) {
  return (
    <>
      <th className={`${required}`}>{label}</th>
      <td colSpan={colSpan}>
        <div className="custom-input">
          <input
            onChange={(e) => changeFun(e)}
            value={`${val ? val : ""}`}
            type={`${type}`}
            autoComplete={`${auto}`}
            name={`${name}`}
            readOnly={readonly}
          />
        </div>
      </td>
    </>
  );
}
