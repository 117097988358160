import React, { useEffect, useState } from "react";
import Select from "../../Atomic/molecules/Select";
// @ts-ignore
import Table from "../organism/Table";
// @ts-ignore
import PageTemplate from "../template/PageTemplate";
//@ts-ignore

interface Props {
  language: any;
  data: any;
  isLoading: boolean;
  tableRef: any;
  excelDown: Function;
}
export const dataList: Array<any> = [
  {
    permission: "B000",
    path: "/Dlog",
    labelKR: "단말일지",
    labelEN: "Driving log",
  },
  {
    permission: "B200",
    path: "/Dlog/Event",
    labelKR: "이벤트",
    labelEN: "Events",
  },
];

export default function DeviceLogMain({
  language,
  data,
  isLoading,
  tableRef,
  excelDown,
}: Props) {
  const inputRef: React.RefObject<HTMLInputElement> = React.createRef();

  const [dataTable, setDataTable] = React.useState<any[]>([]);

  const [totalData, setTotalData] = useState([]); // 초기 데이터 전체
  const [searchData, setSearchData] = useState([]); // 검색 데이터 전체
  const [options, setOptions] = useState({ keywordType: "unit_nm", keyword: "" }); // 검색 옵션

  const optionHandler = (e: any) => {
    // 검색 옵션 핸들러
    const option = e.target.id;
    const value = e.target.value;
    switch (option) {
      case "keywordType":
        setOptions((prev: any) => ({ ...prev, keywordType: value }));
        break;
      case "keyword":
        setOptions((prev: any) => ({ ...prev, keyword: value }));
        break;
      default:
        alert("어떤 옵션인지 파악이 되지 않습니다.");
        break;
    }
  };

  useEffect(() => {
    if (data) {
      setDataTable(data);
    }
  }, [data]);
  useEffect(() => {
    if (dataTable) {
      // @ts-ignore
      setTotalData(dataTable);
      // @ts-ignore
      setSearchData(dataTable);
    }
  }, [dataTable]);
  // @ts-ignore
  const submitHandler = (e) => {
    // 검색 핸들러
    e.preventDefault();
    const filterData = filter();
    resetTable(filterData);
  };
  const filter = () => {
    // 필터링
    if (options.keyword === "") {
      return totalData;
    }
    if (options.keywordType === "") {
      language === "KOR"
        ? alert("검색부류를 선택해주세요")
        : alert("Please select a search category");
      return searchData;
    }
    let filterData = totalData.filter((data) => {
      switch (options.keywordType) {
        case "unitid":
          //@ts-ignore
          if (data.unitid != null) {
            //@ts-ignore
            return data.unitid.includes(options.keyword);
          }
          break;
        case "unit_nm":
          // @ts-ignore
          if (data.unit_nm != null) {
            // @ts-ignore
            return data.unit_nm.toLowerCase().includes(options.keyword.toLowerCase());
          }
          break;
        default:
          break;
      }});
    return filterData;
  };
  const resetTable = (data: any) => {
    // 테이블 데이터 세팅
    setSearchData(data);
  };

  const viewAll = () => {
    // 전체목록
    resetTable(totalData);
    const selectBoxes = document.querySelectorAll(".custom-select select");
    for (let i = 0; i < selectBoxes.length; i++) {
      // @ts-ignore
      selectBoxes[i].value = selectBoxes[i].querySelector("option").value;
    }
    setOptions({ keywordType: "unit_nm", keyword: "" });
    // @ts-ignore
    document.getElementById("keyword").value = "";
  };

  if (isLoading) {
    return <div>loading...</div>;
  }
  const dataPage = {
    titleKR: "단말일지",
    titleEN: "Driving log",
    menu: "/Dlog",
    viewAll: viewAll,
    excelDown: excelDown,
    buttonData: [
      {
        labelKR: "전체보기",
        labelEN: "View all",
        classN: "purple",
        clickFun: viewAll,
        position: "left",
        icon: false,
        iconClass: "",
      },
      {
        labelKR: "엑셀 다운로드",
        labelEN: "Excel download",
        classN: "white excel",
        icon: true,
        iconClass: "ri-file-excel-2-fill",
        clickFun: excelDown,
        position: "right",
      },
    ],
    form: {
      language: language,
      id: "keyword",
      type: "text",
      placeHolderKR: "검색어를 입력하세요",
      placeHolderEN: "Please enter a search term",
      // @ts-ignore
      submitHandler: submitHandler,
      filter: filter,
      option: (
        <Select
          id="keywordType"
          short="N"
          name="keywordType"
          options={[
            {
              name: language === "KOR" ? "단말기명" : "Device name",
              value: "unit_nm",
            },
            {
              name: language === "KOR" ? "단말번호" : "USIM num",
              value: "unitid",
            },
          ]}
          changeFun={(e: any) => {
            optionHandler(e);
          }}
          def={options.keywordType}
        />
      ),
      onchange: optionHandler,
      value: options.keyword,
    },
  };
  return (
    <>
      <PageTemplate
        titleKR={dataPage.titleKR}
        titleEN={dataPage.titleEN}
        menu={dataPage.menu}
        viewAll={dataPage.viewAll}
        excelDown={dataPage.excelDown}
        buttonData={dataPage.buttonData}
        form={dataPage.form}
        dataList={dataList}
        inputRef={inputRef}
        table={<Table data={searchData} tableRef={tableRef} />}
      />
    </>
  );
}
