import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import DatePicker from "vanillajs-datepicker/Datepicker";
import Detailbody from "../../components/map/Detailbody";
import RecordList from "../../components/map/RecordList";
import DetailMinBtn from "../../components/map/buttons/DetailMinBtn";
import PanelCloseBtn from "../../components/map/buttons/PanelCloseBtn";
import PathBtn from "../../components/map/buttons/PathBtn";
import DetailTabNav from "../../components/map/nav/DetailTabNav";
import { LanguageContext } from "../../context/LanguageContext";
import { useAuth } from "../../context/LoginContext";
import { useMap } from "../../context/MapContext";
import useFetch from "../../hook/useFetch";
import useInfinite from "../../hook/useInfinite";
import { ISODateToDateTime } from "../../lib/common_func";
import Swal from "sweetalert2";

export default function RightDetailPanel({ mapData }) {
  const today = moment().format("YYYY-MM-DD");
  const { user } = useAuth();
  const { language } = useContext(LanguageContext);
  const { mapState, mapDispatch } = useMap();
  const { selectItem, pathMode, selectPathItem } = mapState;
  const dateRef = useRef();
  const fromRef = useRef();
  const toRef = useRef();
  const scrollRef = useRef();
  const [state, setState] = useState({
    isOpen: false,
    isRecent: true,
    isMin: false,
  });

  const [path, setPath] = useState(null);
  const [searchOption, setSearchOption] = useState({
    date: selectItem.makedate_cmbd.substring(0, 10) ?? today,
    from: parseInt(selectItem.makedate_cmbd.substring(11, 13)) ?? 0,
    to: parseInt(selectItem.makedate_cmbd.substring(11, 13)) ?? 23,
    isSearch: false,
  });

  const [timeArr, setTimeArr] = useState(null);
  const [selected, setSelected] = useState(null);
  const [selectData, setSelectData] = useState(null);
  const [datepicker, setDatepicker] = useState(null);

  const url = selected
    ? `web/map/tracking/detail?unitid=${selected.unitid}&makedate=${selected.makedate_cmbd}`
    : null;
  const pathUrl =
    state.isRecent || !selected || selectItem.unitid !== selected.unitid
      ? null
      : "/web/map/tracking/path";
  const coordsUrl =
    mapState.pathMode &&
    `/web/map/tracking/coords?unitid=${selected.unitid}&date=${searchOption.date}&from=${searchOption.from}&to=${searchOption.to}`;
  const drivingDayUrl = !state.isRecent
    ? `/web/map/tracking/path/day?unitid=${selected.unitid}`
    : null;
  // const drivingHourUrl =
  //   !state.isRecent && !timeArr
  //     ? `/web/map/tracking/path/hour?unitid=${selected.unitid}&date=${searchOption.date}`
  //     : null;

  const { data } = useFetch(url);
  const { data: pathCoords } = useFetch(coordsUrl);
  const { data: drivingDay } = useFetch(drivingDayUrl);
  // const { data: drivingHour } = useFetch(drivingHourUrl);
  const drivingDayArr = drivingDay && drivingDay.map((item) => item.date);
  // const drivingHourArr = drivingHour && drivingHour.map((item) => item.hh);

  const {
    data: pathData,
    isLoading,
    ref,
    totalCount,
  } = useInfinite(
    pathUrl,
    user.render_cnt ?? 20,
    user.org_idx,
    selectItem.unitid,
    searchOption.from,
    searchOption.to,
    searchOption.date,
    searchOption.isSearch
  );

  // 이벤트 검색옵션 핸들러
  const handleSubmit = (e) => {
    setSearchOption({
      date: dateRef.current.value,
      from: fromRef.current.value,
      to: toRef.current.value,
      isSearch: !searchOption.isSearch,
    });
  };
  const handlePathMarker = (e) => {
    mapDispatch({ type: "SET_HIDE_PATH_MARKER" });
  };
  const moveBottom = () => {
    if(isLoading) {
      // Swal.fire({
      //   title : language === "KOR" ? "로딩중입니다" : "Loading..."
      // });
      return ;
    }
    if(scrollRef?.current) {
      scrollRef.current.scrollIntoView();
    }
  }
  useEffect(() => {
    setSelected(selectItem);
    if(selectItem && selected && (selected.unitid !== selectItem.unitid)) {
      setSearchOption((prev) => ({
        ...prev,
        date: selectItem.makedate_cmbd.substring(0, 10),
        from: parseInt(selectItem.makedate_cmbd.substring(11, 13)),
        to: parseInt(selectItem.makedate_cmbd.substring(11, 13)),
      }));
      
      setState({
        isOpen: true,
        isRecent: true,
        isMin: false,
      });
      setTimeArr(null);
    }

  }, [selectItem]);
  useEffect(() => {
    if (data && selectItem) {
      if (data.unitid !== "0") {
        const list = mapState.trackingList;

        const idx = list.findIndex((e) => e.unitid === data.unitid);

        list[idx].latitude = data.latitude;
        list[idx].longitude = data.longitude;
        list[idx].iconnum = data.iconnum;
        list[idx].makedate_cmbd = data.makedate;
        list[idx].day_dist = data.day_dist;
        list[idx].direction = data.direction;
        mapDispatch({ type: "SET_TRACKING_LIST", payload: [...list] });       
        setSelectData(data);
        setState((prev) => ({ ...prev, isOpen: true }));
      } else {
        setState((prev) => ({ ...prev, isOpen: false }));
        setSelectData(null);
        alert("장기 미관제 차량입니다.");
        mapDispatch({ type: "BLUR" });
      }
    }
  }, [data]);

  // 검색 시간 배열 생성
  // useEffect(() => {
  //   if (!timeArr && drivingHourArr) {
  //     setTimeArr(drivingHourArr);
  //   }
  // }, [drivingHourArr]);

  useEffect(() => {
    const itemData =
      mapData && selectItem
        ? mapData.data.filter((item) => item.unitid === selectItem.unitid)[0]
        : null;
    setSelected(itemData);
  }, [mapData]);

  useEffect(() => {
    if (pathCoords) {
      mapDispatch({ type: "SET_PATH_DATA", payload: pathCoords });
    }
  }, [pathCoords]);
  useEffect(() => {
    if (state.isRecent) {
      setDatepicker(null)
    } else if (!state.isRecent && !datepicker && drivingDayArr !== undefined) {
      DatePicker.locales.ko = {
        days: [
          "일요일",
          "월요일",
          "화요일",
          "수요일",
          "목요일",
          "금요일",
          "토요일",
        ],
        daysShort: ["일", "월", "화", "수", "목", "금", "토"],
        daysMin: ["일", "월", "화", "수", "목", "금", "토"],
        months: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        monthsShort: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        today: "오늘",
        clear: "삭제",
        format: "yyyy-mm-dd",
        titleFormat: "yyyy년mm월",
        weekStart: 0,
      };

      const customPicker = new DatePicker(dateRef.current, {
        language: language === "KOR" ? "ko" : "en",
        autohide: true,
        todayBtn: true,
        todayBtnMode: 0,
        format: "yyyy-mm-dd",
        beforeShowDay:
          drivingDayArr &&
          function (date) {
            const _date = new Intl.DateTimeFormat("fr-CA", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }).format(date);
            const hasData = drivingDayArr.includes(_date);

            return {
              classes: hasData ? "hasData" : "disabled",
            };
          },
      });

      setDatepicker(customPicker);

      // 날짜 변경시 운행 여부 구분 및 데이터 세팅
      // dateRef.current.addEventListener("changeDate", async () => {
      //   const result = await axios.get(
      //     `/web/map/tracking/path/hour?unitid=${
      //       selected.unitid
      //     }&date=${customPicker.getDate("yyyy-mm-dd")}`
      //   );
      //   const arr = result.data.map((item) => item.hh);

      //   setTimeArr(arr);
      //   setSearchOption((prev) => ({
      //     ...prev,
      //     date: customPicker.getDate("yyyy-mm-dd"),
      //     from: arr[arr.length > 3 ? arr.length - 1 : 0],
      //     to: arr[arr.length - 1],
      //   }));
      // });
    }
    return () => {
      // select Item이 바뀌는 경우 변경을 위해 기존 calendar 제거
      if (datepicker) {
        setDatepicker(null);
        datepicker.destroy();
      }
    };
  }, [language, state.isRecent, drivingDay]);
  useEffect(() => {
    if(!state.isRecent && selected) {
      axios.get(`/web/map/tracking/path/hour?unitid=${selected.unitid}&date=${searchOption.date}`)
      .then((res) => {
        if(res.status === 200 && res.data.length > 0) {
          // setTimeArr(res.data.map((item) => item.hh));
          const arr = res.data.map((item) => item.hh)
          setTimeArr(arr);
          setSearchOption((prev) => ({
            ...prev,
            date: dateRef.current.value,
            from: arr[arr.length - 1],
            to: arr[arr.length - 1],
          }));
        }
      })
      .catch((err) => {
        console.log('err : ', err);
      })
    }
  }, [state.isRecent, searchOption.date, selected?.unitid])
  useEffect(() => {
    if(dateRef) {
      // 날짜 변경시 운행 여부 구분 및 데이터 세팅
      dateRef.current.addEventListener("changeDate", async () => {
        setSearchOption((prev) => ({...prev, date: dateRef.current.value}))
      });
    }
  },[dateRef])

  return (
    <>
      {/*<!--s: 오른쪽 상세정보판넬-->*/}
      <div
        className={`right-info-panel detail-info-panel ${
          state.isOpen ? "on" : ""
        } ${state.isMin ? "minimize" : ""} ${state.isRecent ? "" : "fold"} ${
          pathMode && selectPathItem ? "path" : ""
        }`}
      >
        <div className="detail-inner">
          <div className="detail-top">
            <div className="detail-header">
              <div className="header-title">
                <i className="ri-information-fill icon"></i>
                {language === "KOR" ? "상세정보" : "Detail Info."}
              </div>
              <div className="header-control">
                <DetailMinBtn state={state} setState={setState} />
                {/* <DetailCloseBtn state={state} setState={setState} /> */}
                <PanelCloseBtn state={state} setState={setState} />
              </div>
            </div>
            <Detailbody
              data={
                !state.isRecent && pathMode && selectPathItem
                  ? selectPathItem
                  : selectData
              }
              selectData={selectData}
              isPathMode={
                !state.isRecent && pathMode && selectPathItem ? true : false
              }
            />
          </div>
          <div className="detail-bottom">
            <div className="common-tab detail-tab-wrapper">
              <DetailTabNav state={state} setState={setState} />
              <div className="tab-panel-wrapper">
                <div
                  className={`tab-panel ${state.isRecent ? "on" : ""}`}
                  selectData-tab="recent"
                >
                  <div className="recent-info">
                    <div className="info-item">
                      <div className="item-label">
                        {language === "KOR" ? "최근시동 ON" : "Latest Turn ON"}
                      </div>
                      <div className="item-content">{selectData?.e_on}</div>
                    </div>
                    <div className="info-item">
                      <div className="item-label">
                        {language === "KOR"
                          ? "최근시동 OFF"
                          : "Latest Trun OFF"}
                      </div>
                      <div className="item-content">{selectData?.e_off}</div>
                    </div>
                    <div className="info-item">
                      <div className="item-label">
                        {language === "KOR" ? "최종수신일시" : "Last Received"}
                      </div>
                      <div className="item-content">
                        {selectData
                          ? ISODateToDateTime(selectData.makedate)
                          : ""}
                      </div>
                    </div>
                    <div className="info-item">
                      <div className="item-label">
                        {language === "KOR" ? "일주행거리" : "Today Mileage"}
                      </div>
                      <div className="item-content">
                        {selectData?.day_dist} km
                      </div>
                    </div>
                    <div className="info-item">
                      <div className="item-label">
                        {language === "KOR" ? "총주행거리" : "Total Mileage"}
                      </div>
                      <div className="item-content">
                        {selectData?.total_dist} km
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-panel ${state.isRecent ? "" : "on"}`}
                  data-tab="record"
                >
                  <div className="search-module">
                    <div className="row">
                      <div className="custom-select custom-datepicker">
                        <input
                          ref={dateRef}
                          id="date"
                          type="text"
                          value={searchOption.date}
                          readOnly
                        />
                      </div>
                      <div className="custom-select">
                        <select id="from" ref={fromRef}>
                          {timeArr &&
                            timeArr.map((time) => {
                              return (
                                <option
                                  value={time}
                                  selected={
                                    parseInt(searchOption.from) ===
                                    parseInt(time)
                                  }
                                >
                                  {language === "KOR"
                                    ? `${time}시`
                                    : `${time}hr`}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="custom-select">
                        <select id="to" ref={toRef}>
                          {timeArr &&
                            timeArr.map((time) => {
                              return (
                                <option
                                  value={time}
                                  selected={
                                    parseInt(searchOption.to) === parseInt(time)
                                  }
                                >
                                  {language === "KOR"
                                    ? `${time}시`
                                    : `${time}hr`}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="submit-btn"
                      onClick={handleSubmit}
                    >
                      {language === "KOR" ? "검색" : "Search"}
                    </button>
                  </div>
                  <div className="search-control">
                    <label className="custom-checkbox marker-option">
                      <input
                        type="checkbox"
                        checked={mapState.pathMarkerShow ? "checked" : ""}
                        onChange={handlePathMarker}
                      />
                      <div className="checkbox"></div>
                      <div className="label">
                        {language === "KOR" ? "마커보기" : "View Markers"}
                      </div>
                    </label>
                    <button onClick={moveBottom} className="btn-scroll-bottom">
                      <i className="ri-arrow-down-s-line"></i>
                    </button>
                    <PathBtn path={path} />
                  </div>
                  {!state.isRecent && (
                    <RecordList
                      record={pathData}
                      selecData={selectData}
                      inviewref={ref}
                      totalCount={totalCount}
                      isLoading={isLoading}
                      scrollRef={scrollRef}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<!--e: 오른쪽 상세정보판넬-->*/}
    </>
  );
}
