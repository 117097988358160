import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "vanillajs-datepicker/Datepicker";
import { useLang } from "../../context/LanguageContext";
import { useAuth } from "../../context/LoginContext";
import { useMap } from "../../context/MapContext";
import useFetch from "../../hook/useFetch";
import { getIconNumber } from "../../lib/mapHelper";

export default function DailyList({ tab }) {
    const { language } = useLang();
    const { user } = useAuth();
    const { mapState, mapDispatch } = useMap();
    const { selectDailyItem, selectPathItem } = mapState;

    const today = moment().format("YYYY-MM-DD");
    const calendarRef = useRef();

    const [datepicker, setDatepicker] = useState(null);
    const [searchDate, setSearchDate] = useState(null);

    const { data: dailyDate } = useFetch(
        `/web/map/daily/date?type=${user.type}&grade=${user.grade}&org_idx=${user.org_idx}&idx=${user.idx}`
    );
    const renderDate =
        (dailyDate &&
            dailyDate.length > 0 &&
            dailyDate[dailyDate.length - 1].date) ??
        today;

    const dvcUrl = searchDate
        ? `/web/map/daily/list?type=${user.type}&grade=${user.grade}&org_idx=${user.org_idx}&date=${searchDate}&idx=${user.idx}`
        : null;
    const { data: dvcList } = useFetch(dvcUrl);

    const handleItem = async (e) => {
        const result = await axios.post(`/web/map/daily/detail`, {
            date: searchDate,
            unitid: e.unitid,
        });

        mapDispatch({ type: "SET_SELECT_DAILY_ITEM", payload: result.data });
    };

    // 캘린더
    useEffect(() => {
        if (!datepicker && tab === "day" && dailyDate) {
            DatePicker.locales.ko = {
                days: [
                    "일요일",
                    "월요일",
                    "화요일",
                    "수요일",
                    "목요일",
                    "금요일",
                    "토요일",
                ],
                daysShort: ["일", "월", "화", "수", "목", "금", "토"],
                daysMin: ["일", "월", "화", "수", "목", "금", "토"],
                months: [
                    "1월",
                    "2월",
                    "3월",
                    "4월",
                    "5월",
                    "6월",
                    "7월",
                    "8월",
                    "9월",
                    "10월",
                    "11월",
                    "12월",
                ],
                monthsShort: [
                    "1월",
                    "2월",
                    "3월",
                    "4월",
                    "5월",
                    "6월",
                    "7월",
                    "8월",
                    "9월",
                    "10월",
                    "11월",
                    "12월",
                ],
                today: "오늘",
                clear: "삭제",
                format: "yyyy-mm-dd",
                titleFormat: "yyyy년mm월",
                weekStart: 0,
            };

            const picker = new DatePicker(calendarRef.current, {
                language: language === "KOR" ? "ko" : "en",
                todayBtn: true,
                todayBtnMode: 0,
                format: "yyyy-mm-dd",
                beforeShowDay: function (date) {
                    const _date = new Intl.DateTimeFormat("fr-CA", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                    }).format(date);
                    const hasData = dailyDate
                        ?.map((item) => item.date)
                        .includes(_date);
                    return {
                        classes: hasData ? "hasData" : "disabled",
                    };
                },
            });
            setDatepicker(picker);

            calendarRef.current.addEventListener("changeDate", async () => {
                setSearchDate(picker.getDate("yyyy-mm-dd"));
            });
        } else if (datepicker && tab !== "day") {
            datepicker.destroy();
        }
        return () => {
            if (datepicker) {
                datepicker.destroy();
            }
        };
    }, [tab, dailyDate]);

    useEffect(() => {
        if (dailyDate?.length > 0) {
            setSearchDate(dailyDate[dailyDate.length - 1].date);
        }
    }, [dailyDate]);

    useEffect(() => {
        selectPathItem && mapDispatch({type:"BLUR"})
    },[selectDailyItem])
    
    return (
        <>
            <div className={`tab-panel ${tab === "day" ? "on" : ""}`}>
                <div className="calendar-module">
                    <div
                        ref={calendarRef}
                        className="day-calendar"
                        data-date={searchDate || renderDate}
                    ></div>
                </div>
                <div className="hr"></div>
                <div className="track-list day">
                    <div className="list-header">
                        <div className="selected-date">
                            {language === "KOR"
                                ? searchDate &&
                                  searchDate
                                      .replace("-", "년 ")
                                      .replace("-", "월 ") + "일"
                                : moment.utc(renderDate).format("MMMM Do YYYY")}
                        </div>
                        <div className="total">
                            <span className="label">
                                {language === "KOR" ? "주행" : "Driv."}
                            </span>
                            <div className="count">{dvcList?.length}</div>
                        </div>
                    </div>
                    <ul className="list-body">
                        {dvcList?.map((item) => (
                            <li onClick={() => handleItem(item)}>
                                <div
                                    className={`item-card ${selectDailyItem?.unitid === item.unitid ? 'on' : ''}`}
                                >
                                    <div
                                        className={`marker ${getIconNumber(
                                            item.icon_code
                                        )} normal`}
                                    >
                                        <div className="marker-icon">
                                            <span className="icon">
                                                <i></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="item-info">
                                        <div className="item-name">
                                            {item.unit_nm}
                                        </div>
                                        <div className="item-distance">
                                            {item.total_calc_dist.toFixed(2)} km
                                        </div>
                                    </div>
                                    {/* <div className="item-num">722</div> */}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}
