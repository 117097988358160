import React from "react";

interface HeaderProps {
  children?: React.ReactNode;
  list: Array<any>;
  setSelected: (e: any) => void;
  selected: any;
  sn?: string;
  lang?: string;
}
export default function Header({
  children,
  list,
  setSelected,
  selected,
  sn,
  lang,
}: HeaderProps) {
  // console.log("Header", lang);

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div className="map-box-header-wrap">
        <div className="map-box-header">
          {list.length > 0 && (
            <select
              className="map-box-header-title"
              value={selected.idx}
              onChange={(e) => setSelected(parseInt(e.target.value))}
            >
              <option value="0">
                {lang === "KOR" ? "선택해주세요" : "Please Select"}
              </option>
              {list.map((item, idx) =>
                item.select ? (
                  <option
                    key={idx}
                    value={idx}
                    disabled
                    style={{
                      background: "#2c2685",
                      color: "#fff",
                    }}
                  >
                    {item.unit_nm === "Device"
                      ? `${lang === "KOR" ? "장치" : "Device"}`
                      : `${lang === "KOR" ? "지역" : "Region"}  `}
                  </option>
                ) : (
                  <option key={idx} value={item.idx}>
                    {item.unit_nm !== undefined ? item.unit_nm : item.region_nm}
                  </option>
                )
              )}
            </select>
          )}
          <div className="map-box-header-title-sub">
            <p>{sn}</p>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}
