import React from "react";
import { Location } from "react-router-dom";
interface IProps {
  navi: (path: any, state?: object) => void;
  subDataList: { path: string; labelKR: string; labelEN: string }[];
  language: string;
  classPath: string;
  idx?: string;
}

export default function SubNavi({
  navi,
  subDataList,
  language,
  classPath,
  idx,
}: IProps) {
  const style = {
    margin: "0px 0px 25px 0px",
    gap: "5px",
  };
  return (
    <>
      <div className="flex" style={style}>
        {subDataList.map((item, index) => {
          return (
            <button
              className={`common-btn ${
                item.path === `${classPath}` ? "purple" : "white"
              }`}
              key={index}
              onClick={() => {
                navi(item.path, { state: { idx: idx } });
              }}
            >
              <span>{language === "KOR" ? item.labelKR : item.labelEN}</span>
            </button>
          );
        })}
      </div>
    </>
  );
}
