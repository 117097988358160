import React from 'react';
import { useLang } from '../../../context/LanguageContext';

export default function TeminalblueToothExcel({ data, tableRef }) {
    const { language } = useLang();

    const colunm = ['번호','Bluetooth','소속','부서','사용자명','최종 연결일시','등록일'];
    const eng_colunm = ['No','Bluetooth','Org','Depart','User','Last Connected','Create'];
    
    const style = {
        sort:{left:{textAlign:'left'}, center:{textAlign:'center'}, right:{textAlign:'right'}},
        border:{border:'1px solid #413c3c'},
        primary:{backgroundColor:'#DCE8F5'}
    }   

    return (
        <>
            <table ref={tableRef} className='excel-table'>
                <thead>
                    <tr style={style.sort.center}>
                        {language === 'KOR' ? colunm.map((item) => <th style={{...style.primary,...style.border}}>{item}</th>) : 
                                              eng_colunm.map((item) => <th style={{...style.primary,...style.border}}>{item}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {data && data.map((item, index) => (
                        <tr>
                            <td style={{...style.sort.center,...style.border}}>{data?.length - index}</td>
                            <td style={{...style.sort.center,...style.border}}>{item.bt_id}</td>
                            <td style={{...style.sort.center,...style.border}}>{item.org_nm}</td>
                            <td style={{...style.sort.center,...style.border}}>{item.department}</td>
                            <td style={{...style.sort.center,...style.border}}>{item.nm}</td>
                            <td style={{...style.sort.center,...style.border}}>{item.lastdate_bt}</td>
                            <td style={{...style.sort.center,...style.border}}>{item.makedate}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

