import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/LoginContext";
import axios from "axios";
import CommonControl from "../Atomic/organism/CommonControl";
import { useLang } from "../../context/LanguageContext";
import { useLocation, useNavigate } from "react-router";
import { ISODateToDate, ISODateToDateTime } from "../../lib/common_func";
import Tel_Input from "../Atomic/atoms/Tel_Input";
import { useDaumPostcodePopup } from "react-daum-postcode";
import Swal from "sweetalert2";

export default function DetailForm() {

    // context
    const { user } = useAuth();
    const { language } = useLang();

    // hooks
    const location = useLocation();
    const navi = useNavigate();
    const postRef = useRef(null);
    const addrRef = useRef(null);
    const subAddrRef = useRef(null);
    const postRvRef = useRef(null);
    const addrRvRef = useRef(null);
    const subAddrRvRef = useRef(null);
    const typeRef = useRef(null);
    const fromRef = useRef(null);

    // state & function
    const [data, setData] = useState({type:'add'});
    const [isRefresh, setIsRefresh] = useState(true);
    const open = useDaumPostcodePopup(
        "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
    );
    const handleSubmit = () => {
        const requireKey = ['nm','prod_type','place'];
        const tempData = data;
        for(let key of requireKey) {
            if(!data[key]) {
                Swal.fire({
                    title:'필수값을 입력해주세요',
                })
                return ;
            }
        }

        if(tempData.hp === '--') {
            Swal.fire({
                title:'필수값을 입력해주세요',
            })
            return ;
        } else {
            if(!/^(01[016789]{1})-[0-9]{3,4}-[0-9]{4}$/.test(tempData.hp)) {
                Swal.fire({
                    title:'올바르지 않은 값이 있습니다.'
                })
                return ;
            }
        }
        
        if(tempData.rv_hp && tempData.rv_hp === '--') {
            delete tempData.rv_hp
        }

        if(tempData.rv_hp &&!/^(01[016789]{1})-[0-9]{3,4}-[0-9]{4}$/.test(tempData.rv_hp)) {
            Swal.fire({
                title:'올바르지 않은 값이 있습니다.'
            })
            return ;
        }        

        axios.post('/web/order/edit', tempData).then((res) => {
            if(res.status === 200) {
                Swal.fire({
                    title: location?.state?.idx ? '수정되었습니다.' : '등록이 완료되었습니다.'
                }).then(()=> {
                    location?.state?.idx ? setIsRefresh((prev) => !prev) : navi('/Order');
                })
            } else {
                Swal.fire({
                    title: location?.state?.idx ? '수정에 실패하였습니다.' : '등록에 실패하였습니다.'
                })
            }
        }).catch((err) => {
            console.log('err : ', err)
        })
    }
    const handleData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const type = typeRef.current.value;
        const from = fromRef.current.value;
        setData((prev) => ({...prev, [name] : value}));

        // retrun toDate
        if(name === 'prod_type') {
            if(value && from) {
                const date = new Date(from);
                if (value == "1") {
                    date.setDate(date.getDate() + 183);
                } else if (value == "2") {
                    date.setFullYear(date.getFullYear() + 1);
                    date.setDate(date.getDate() - 1);
                } else {
                    return;
                }
                const result = date.toLocaleDateString(undefined,{
                    year:'numeric',
                    month:'2-digit',
                    day:'2-digit'
                });
                setData((prev) => ({...prev, toDate: result.split('.').slice(0,3).map(e => e.trim()).join('-').trim()}));
            }
        }
        if(name === 'fromDate') {
            if(type && value) {
                const date = new Date(value);
                if (data.prod_type == "1") {
                    date.setDate(date.getDate() + 183);
                } else if (data.prod_type == "2") {
                    date.setFullYear(date.getFullYear() + 1);
                    date.setDate(date.getDate() - 1);
                } else {
                    return;
                }
                const result = date.toLocaleDateString(undefined,{
                    year:'numeric',
                    month:'2-digit',
                    day:'2-digit'
                });
                setData((prev) => ({...prev, toDate: result.split('.').slice(0,3).map(e => e.trim()).join('-').trim()}));
            }
        }
    }
    const returnAddr = (data) => {
        var fullAddr = ""; // 최종 주소 변수
        var extraAddr = ""; // 조합형 주소 변수
        if (data.userSelectedType === "R") {
          fullAddr = data.roadAddress;
        } else {
          fullAddr = data.jibunAddress;
        }
        if (data.userSelectedType === "R") {
          if (data.bname !== "") {
            extraAddr += data.bname;
          }
          if (data.buildingName !== "") {
            extraAddr +=
              extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
          }
          fullAddr += extraAddr !== "" ? " (" + extraAddr + ")" : "";
        }
        postRef.current.value = data.zonecode;
        addrRef.current.value = fullAddr;
        subAddrRef.current.focus();
        setData((prev) => ({
          ...prev,
          postCode: data.zonecode,
          addr: fullAddr,
        }));
    };
    const returnAddrRv = (data) => {
        var fullAddr = ""; // 최종 주소 변수
        var extraAddr = ""; // 조합형 주소 변수
        if (data.userSelectedType === "R") {
          fullAddr = data.roadAddress;
        } else {
          fullAddr = data.jibunAddress;
        }
        if (data.userSelectedType === "R") {
          if (data.bname !== "") {
            extraAddr += data.bname;
          }
          if (data.buildingName !== "") {
            extraAddr +=
              extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
          }
          fullAddr += extraAddr !== "" ? " (" + extraAddr + ")" : "";
        }
        postRvRef.current.value = data.zonecode;
        addrRvRef.current.value = fullAddr;
        subAddrRvRef.current.focus();
        setData((prev) => ({
          ...prev,
          rv_postCode: data.zonecode,
          rv_addr: fullAddr,
        }));
    };   
    const handleClick = (e) => {
        open({ onComplete: e.target.name === 'addrBtn' ? returnAddr : returnAddrRv });
    };
    const TelHandler = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let temp = "";
        let temp2 = "";
        switch (name) {
            case "tel1":
                temp = data.hp.split("-");
                temp[0] = value;
                if (temp[0] === "000") {
                    Swal.fire({
                        title : "0000은 입력할 수 없습니다.",
                    });
                    temp[0] = "";
                }
                setData((prev) => ({ ...prev, hp: temp.join("-") }));
                break;
            case "tel2":
                temp = data.hp.split("-");
                temp[1] = value;
                if (temp[1] === "0000") {
                    alert("0000은 입력할 수 없습니다.");
                    temp[1] = "";
                }
                setData((prev) => ({ ...prev, hp: temp.join("-") }));
                break;
            case "tel3":
                temp = data.hp.split("-");
                temp[2] = value;
                if (temp[2] === "0000") {
                    alert("0000은 입력할 수 없습니다.");
                    temp[2] = "";
                }
                setData((prev) => ({ ...prev, hp: temp.join("-") }));
                break;
            case "tel4":
                temp2 = data.rv_hp.split("-");
                temp2[0] = value;
                if (temp2[0] === "000") {
                    alert("0000은 입력할 수 없습니다.");
                    temp2[0] = "";
                }
                setData((prev) => ({ ...prev, rv_hp: temp2.join("-") }));
                break;
            case "tel5":
                temp2 = data.rv_hp.split("-");
                temp2[1] = value;
                if (temp2[1] === "0000") {
                    alert("0000은 입력할 수 없습니다.");
                    temp2[1] = "";
                }
                setData((prev) => ({ ...prev, rv_hp: temp2.join("-") }));
                break;
            case "tel6":
                temp2 = data.rv_hp.split("-");
                temp2[2] = value;
                if (temp2[2] === "0000") {
                    alert("0000은 입력할 수 없습니다.");
                    temp2[2] = "";
                }
                setData((prev) => ({ ...prev, rv_hp: temp2.join("-") }));
                break;
            default:
                break;
        }
    };
    const buttonData = [
        {
          labelKR: "목록",
          labelEN: "Order list",
          classN: "white",
          clickFun: () => {
            navi("/Order");
          },
          position: "left",
          icon: false,
          iconClass: "",
        },
        {
          labelKR: location.state.idx ? "수정" : "등록",
          labelEN: location.state.idx ? "Edit" : "Add",
          classN: "purple",
          clickFun: () => {
            handleSubmit();
          },
          position: "",
          icon: false,
          iconClass: "",
        },
    ];

    // effect
    useEffect(()=> {
        if(location?.state?.idx) {
            axios.get(`/web/order/detail?idx=${location.state.idx}`).then((res) => {
                if(res.status === 200) {
                    setData({...res.data, rv_hp: res.data.rv_hp ? res.data.rv_hp : '--', type: 'edit', idx:location.state.idx});
                }
            })
        } else {
            setData((prev) => ({...prev, prod_type: 1, hp:'--', rv_hp:'--', status:'IN', u_idx: user.adm_flag ? 0 : user.idx}))
        }
    },[isRefresh])

    return (
        <>
            <div className="common-table-wrapper">
                <table className="common-view-table">
                    <colgroup>
                        <col style={{width:'19rem'}}/>
                        <col />
                        <col style={{width:'19rem'}}/>
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th className="required">사용자</th>
                            <td>
                                <div className="custom-input">
                                    <input type="text" name="nm" value={data?.nm} onChange={handleData} maxLength={50} required/>
                                </div>
                            </td>
                            <th className="required">구매상품</th>
                            <td>
                                <div className="custom-select">
                                    <select name="prod_type" value={data?.prod_type || 1} onChange={handleData} required ref={typeRef}>
                                        <option value="1">무선형(6無혜택 6개월)</option>
                                        <option value="2">무선형(6無혜택 12개월)</option>
                                    </select>
                                </div>
                            </td>
                        </tr>                
                        <tr>
                            <th className="required">구매처</th>
                            <td>
                                <div className="custom-input">
                                    <input type="text" name="place" value={data?.place} onChange={handleData} required/>
                                </div>
                            </td>
                            <th>주문번호</th>
                            <td>
                                <div className="custom-input">
                                    <input type="text" name="order_num" value={data?.order_num || ''} onChange={handleData} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th className="required">휴대폰 번호</th>
                            <td>
                                <div className="flex">
                                    <Tel_Input
                                        id="tel1"
                                        name="tel1"
                                        value={
                                        data?.hp
                                            ? data.hp.split("-")[0]
                                            : ""
                                        }
                                        changeFun={TelHandler}
                                        readOnly={false}
                                        place="010"
                                        max={3}
                                        req={3}
                                    />
                                    -
                                    <Tel_Input
                                        id="tel2"
                                        name="tel2"
                                        value={
                                        data?.hp 
                                            ? data.hp.split("-")[1]
                                            : ""
                                        }
                                        changeFun={TelHandler}
                                        readOnly={false}
                                        place="0000"
                                        max={4}
                                        req={3}
                                    />
                                    -
                                    <Tel_Input
                                        id="tel3"
                                        name="tel3"
                                        value={
                                        data?.hp 
                                            ? data.hp.split("-")[2]
                                            : ""
                                        }
                                        changeFun={TelHandler}
                                        readOnly={false}
                                        place="0000"
                                        max={4}
                                        req={4}
                                    />
                                </div>
                            </td>
                            <th>생년월일</th>
                            <td>
                                <div className="custom-input">
                                    <input type="date" name="birth" value={data?.birth && ISODateToDate(data.birth)} onChange={handleData} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>주소</th>
                            <td>
                                <div className="address">
                                    <div className="row">
                                        <div className="custom-input">
                                        <input
                                            type="text"
                                            readOnly
                                            ref={postRef}
                                            value={data?.postCode}
                                        />
                                        </div>
                                        <button
                                        name="addrBtn"
                                        onClick={handleClick}
                                        type="button"
                                        className="common-btn white sm"
                                        >
                                        <span>
                                            {language === "KOR"
                                            ? "우편번호 검색"
                                            : "Addr search"}
                                        </span>
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="custom-input full">
                                        <input
                                            type="text"
                                            ref={addrRef}
                                            value={data?.addr}
                                            readOnly
                                        />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="custom-input full">
                                        <input
                                            name="addr_dt"
                                            type="text"
                                            ref={subAddrRef}
                                            onChange={handleData}
                                            value={data?.addr_dt}
                                            placeholder={
                                            language === "KOR"
                                                ? "상세주소를 입력해주세요."
                                                : "Please enter your detailed address."
                                            }
                                        />
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <th>수령 주소</th>
                            <td>
                            <div className="address">
                                    <div className="row">
                                        <div className="custom-input">
                                        <input
                                            type="text"
                                            readOnly
                                            ref={postRvRef}
                                            value={data?.rv_postCode}
                                        />
                                        </div>
                                        <button
                                        name="addrBtnRv"
                                        onClick={handleClick}
                                        type="button"
                                        className="common-btn white sm"
                                        >
                                        <span>
                                            {language === "KOR"
                                            ? "우편번호 검색"
                                            : "Addr search"}
                                        </span>
                                        </button>
                                    </div>
                                    <div className="row">
                                        <div className="custom-input full">
                                        <input
                                            type="text"
                                            ref={addrRvRef}
                                            value={data?.rv_addr}
                                            readOnly
                                        />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="custom-input full">
                                        <input
                                            name="rv_addr_dt"
                                            type="text"
                                            ref={subAddrRvRef}
                                            onChange={handleData}
                                            value={data?.rv_addr_dt}
                                            placeholder={
                                            language === "KOR"
                                                ? "상세주소를 입력해주세요."
                                                : "Please enter your detailed address."
                                            }
                                        />
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>수령인</th>
                            <td>
                                <div className="custom-input">
                                    <input type="text" name="rv_nm" value={data?.rv_nm} onChange={handleData} />
                                </div>
                            </td>
                            <th>수령인 전화번호</th>
                            <td>
                            <div className="flex">
                                    <Tel_Input
                                        id="tel4"
                                        name="tel4"
                                        value={
                                        data?.rv_hp 
                                            ? data.rv_hp.split("-")[0]
                                            : ""
                                        }
                                        changeFun={TelHandler}
                                        readOnly={false}
                                        place="010"
                                        max={3}
                                        req={3}
                                    />
                                    -
                                    <Tel_Input
                                        id="tel5"
                                        name="tel5"
                                        value={
                                        data?.rv_hp 
                                            ? data.rv_hp.split("-")[1]
                                            : ""
                                        }
                                        changeFun={TelHandler}
                                        readOnly={false}
                                        place="0000"
                                        max={4}
                                        req={3}
                                    />
                                    -
                                    <Tel_Input
                                        id="tel6"
                                        name="tel6"
                                        value={
                                        data?.rv_hp 
                                            ? data.rv_hp.split("-")[2]
                                            : ""
                                        }
                                        changeFun={TelHandler}
                                        readOnly={false}
                                        place="0000"
                                        max={4}
                                        req={4}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>문자발송</th>
                            <td>{data?.sms_status === 'Y' ? (<span className="true">O</span>) : (<span className="false">X</span>)}</td>
                            <th>인증상태</th>
                            <td>{data?.identity_status === 'Y' ? (<span className="true">O</span>) : (<span className="false">X</span>)}</td>
                        </tr>
                        <tr>
                            <th>발송상태</th>
                            <td>
                                <div className="custom-select">
                                    <select name="status" value={data?.status || "IN"} onChange={handleData}>
                                        <option value="OUT">발송</option>
                                        <option value="IN">미발송</option>
                                    </select>
                                </div>
                            </td>
                            <th>사용유무</th>
                            <td>{data?.act_yn === 'Y' ? (<span className="true">O</span>) : (<span className="false">X</span>)}</td>
                        </tr>
                        <tr>
                            <th>시작일</th>
                            <td>
                                <div className="custom-input">
                                    <input type="date" name="fromDate" value={data?.fromDate && ISODateToDate(data.fromDate)} onChange={handleData} ref={fromRef}/>
                                </div>
                            </td>
                            <th>만료일</th>
                            <td>
                                <div className="custom-input">
                                    <input type="date" name="toDate" value={data?.toDate && ISODateToDate(data.toDate)} onChange={handleData} />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>수정일</th>
                            <td>{data?.editdate ? ISODateToDateTime(data.editdate) : '-'}</td>
                            <th>등록일</th>
                            <td>{data?.makedate ? ISODateToDateTime(data.makedate) : '-'}</td>
                        </tr>                                                
                    </tbody>
                </table>
                <CommonControl buttonData={buttonData} language={language}/>
            </div>
        </>
    );
}
