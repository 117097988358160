import moment from "moment";
import React, { useContext, useRef } from "react";
import { Outlet } from "react-router-dom";
import DeviceLogMain from "../../components/run/pages/DeviceLogMain";
import { LanguageContext } from "../../context/LanguageContext.jsx";
import { useAuth } from "../../context/LoginContext";
import useExcelDown from "../../hook/useExcelDown.jsx";
import useFetch from "../../hook/useFetch";

export default function DeviceLog() {
  const { language } = useContext(LanguageContext);
  const { user } = useAuth();

  const tableRef = useRef();
  const today = moment().format('YYYY-MM-DD');

  const { data, isLoading } = useFetch(
    `web/device/log/list?org_idx=${user.org_idx}&type=${user.type}&idx=${user.idx}&grade=${user.grade}`
  );
  const { excelDown } = useExcelDown(tableRef, `Device_Log_List_${today}`, `Device_Log_List`, data);
  
  return (
    <>
      <DeviceLogMain language={language} data={data} isLoading={isLoading} tableRef={tableRef} excelDown={excelDown}/>
      <Outlet />
    </>
  );
}
