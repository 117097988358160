import Swal from "sweetalert2";

export default function FileUploadSingle({ imgRef, name, fileSize, changeFun, style={}}) {
  
  const handleConfirm = (e) => {
    if(e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 1024 * 1024 * fileSize) {
        Swal.fire({
          title:`파일 사이즈가 너무 큽니다. (${fileSize}MB 제한)`
        })
        return;
      }
      if (e.target.type.includes("image")) {
        alert("이미지 파일만 업로드 가능합니다.");
        return;
      }
    }
    changeFun(e);
  };

  return (
      <input ref={imgRef} name={name} type="file" accept="image/png, image/jpeg" style={style} onChange={handleConfirm} />
  );
}
