import React from "react";
import { useLang } from "../../../context/LanguageContext";
export default function FullBtn() {
  const { isFull, activeFull: on, inacativeFull: off } = useLang();

  const fullBtn = () => {
    isFull ? off() : on();
  };
  return (
    <button type="button" className="control-btn full" onClick={fullBtn}>
      <i className="ri-fullscreen-fill icon max"></i>
      <i className="ri-fullscreen-exit-fill icon origin"></i>
    </button>
  );
}
