import PropTypes from "prop-types";
export default function InputTime({ type, value, changeFun, id, name }) {
  return (
    <>
      <div className="row-content">
        <div className="form-input">
          <input
            type={type}
            value={value}
            onChange={changeFun}
            id={id}
            name={name}
          />
        </div>
      </div>
    </>
  );
}

InputTime.propTypes = {
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  changeFun: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
};
