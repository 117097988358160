import React from 'react';
import { Outlet } from 'react-router';
import Nav from '../../components/Atomic/organism/Nav';
import AppFooter from '../../components/include/AppFooter';
import AppHeader from '../../components/include/AppHeader';
import DetailForm from '../../components/order/DetailForm';
import { useLang } from '../../context/LanguageContext';

export default function OrderInfo() {
    const { language } = useLang();

    const dataList = [
        {
            permission: "I000",
            path: "/Order",
            labelKR: "판매관리",
            labelEN: "Sale Manage",
        },
    ];

    return (
        <>
            <AppHeader />
            <div className="app-body">
                <div className="app-page run">
                <div className="sub-page-wrapper">
                    <Nav menu={"/Order"} dataList={dataList} language={language} />
                    <div className="sub-page">
                    <div className="sub-page-header">
                        <div className="header-title">
                        {language === "KOR"
                            ? "판매관리"
                            : "Sale Manage"}
                        </div>
                    </div>
                    <div className="sub-page-body">
                        <DetailForm />
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <AppFooter />
            <Outlet />
        </>
    );
}

