import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';

export default function TypeSelector({ data, onChange }) {
    const {language} = useContext(LanguageContext);

    return (
        <div className="custom-select">
            <select id='unit_type' onChange={onChange}>
                <option value=''>{(language === 'KOR' ? '분류' : 'Type' )}</option>
                {data?.map((e) => {
                    return <option value={e.code}>{(language === 'KOR' ? e.nm_kor : e.nm_eng)}</option>
                })}
            </select>
        </div>
    );
}

