import PropTypes from "prop-types";
import Button from "../atom/Button";

import { useState } from "react";
import moment from "moment";
export default function AtomTitle({
  year,
  month,
  isOpen,
  clickHandlerTitle,
  selectedDate,
  lang,
}) {
  const [yearValue, setYearValue] = useState(year);
  const [monthValue, setMonthValue] = useState(month);
  const years = ["2020", "2021", "2022", "2023", "2024", "2025"];
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const date = moment(`${yearValue}-${monthValue}-01`, "YYYY-MM-DD");
  const submit = () => {
    selectedDate(date);
    clickHandlerTitle();
    return;
  };
  return (
    <>
      {isOpen && (
        <div className="title-header-box-body">
          <div className="title-header-box-body-select">
            <div className="title-header-box-body-year">
              <select
                className="content select-event-calendar"
                name="year"
                id="header-yaer"
                onChange={(e) => setYearValue(e.target.value)}
              >
                <option value={year} defaultValue>
                  {year}
                </option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className="title-header-box-body-month">
              <select
                className="select-event-calendar"
                name="month"
                id="header-month"
                onChange={(e) => setMonthValue(e.target.value)}
              >
                <option value={month} defaultValue>
                  {month}
                </option>
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="title-header-box-body-btn" style={{ width: "100%" }}>
            <Button
              clickFun={submit}
              buttonName={lang === "KOR" ? "확인" : "Submit"}
            />
          </div>
        </div>
      )}
      {!isOpen && (
        <Button
          classN="title-header"
          clickFun={clickHandlerTitle}
          buttonName={`${year}년 ${month}월`}
          colorBTN={"white"}
        />
      )}
    </>
  );
}

AtomTitle.propTypes = {
  year: PropTypes.string,
  month: PropTypes.string,
  isOpen: PropTypes.bool,
  clickHandlerTitle: PropTypes.func,
  selectedDate: PropTypes.func,
};
