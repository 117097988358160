import React from "react";
import { Outlet } from "react-router-dom";
import Safearea from "../../components/include/Safearea";

export default function FAQ() {
  return (
    <Safearea>
      <Outlet />
    </Safearea>
  );
}
