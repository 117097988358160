import React from "react";
import Input from "../atoms/Input";

interface Props {
  id: string;
  type: string;
  placeHolder: string;
  changeFun: (e: any) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  value?: string;
}

export default function CustomInput({
  id,
  type,
  placeHolder,
  changeFun,
  inputRef,
  value,
}: Props) {
  return (
    <div className="custom-input">
      {inputRef && (
        <Input
          id={id}
          type={type}
          placeHolder={placeHolder}
          changeFun={changeFun}
          inputRef={inputRef}
          value={value}
        />
      )}
    </div>
  );
}
