import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import axios from "../../../lib/axios";
import DrivingDayTableExcel from "./DrivingDayTableExcel";
import { ISODateToDateTime } from "../../../lib/common_func";

export default function DrivingDayTable({ data, sort, tableRef, isLoading, date, unitid, unit_nm, totalDist }) {  
  const { language } = useContext(LanguageContext);
  const [updateDate, setUpdateDate] = useState(data);  
  const [column, setcolumn] = useState({
    no:{key:'no',ko:'번호',eng:'No',active:true,width:'65px',view:true},
    dr_dist:{key:'dr_dist',ko:'운행거리(km)',eng:'Distance (km)',active:true,width:'100px',view:true},
    dr_hhmiss:{key:'dr_hhmiss',ko:'운행시간',eng:'Time',active:true,width:'120px',view:true},
    u_nm:{key:'u_nm',ko:'운전자',eng:'Driver',active:true,width:'100px',view:true},
    key_on:{key:'key_on',ko:'출발시각',eng:'Start Time',active:true,width:'105px',view:true},
    key_on_date:{key:'key_on_date',ko:'출발일',eng:'Start Date',active:true,width:'100px',view:false},
    key_on_time:{key:'key_on_time',ko:'출발시각',eng:'Start Time',active:true,width:'100px',view:false},
    addStart:{key:'addStart',ko:'출발위치',eng:'Start Addr',active:true,view:true},
    key_on_lat:{key:'key_on_lat',ko:'출발위도',eng:'Latitude',active:true,width:'90px',view:true},
    key_on_lon:{key:'key_on_lon',ko:'출발경도',eng:'Longitude',active:true,width:'95px',view:true},
    key_off:{key:'key_off',ko:'도착시각',eng:'End Time',active:true,width:'105px',view:true},
    key_off_date:{key:'key_off_date',ko:'도착일',eng:'End Date',active:true,width:'100px',view:false},
    key_off_time:{key:'key_off_time',ko:'도착시각',eng:'End Time',active:true,width:'100px',view:false},
    addEnd:{key:'addEnd',ko:'도착위치',eng:'End Addr',active:true,view:true},
    key_off_lat:{key:'key_off_lat',ko:'도착위도',eng:'Latitude',active:true,width:'90px',view:true},
    key_off_lon:{key:'key_off_lon',ko:'도착경도',eng:'Longitude',active:true,width:'95px',view:true},
  })

  useEffect(() => {
    if (data && data.length > 0) {
      async function fetchData() {
        const newData = await Promise.all(
          data.map(async (item) => {
            item.addStart = await address(item.key_on_lat, item.key_on_lon);
            item.addEnd = await address(item.key_off_lat, item.key_off_lon);
            return item;
          })
        );
        setUpdateDate(newData);
      }

      fetchData();
    }
  }, [data]);

  useEffect(() => {
    if(updateDate) {
      setUpdateDate(prev => [...prev.reverse()])
    }
  },[sort])

  // 주소 요청
  async function address(lat, lon) {
    const datasend = {
      latitude: lat,
      longitude: lon,
    };
    try {
      const response = await axios.post("/web/api/addr", datasend);
      return response.data.addr;
    } catch (err) {
      console.log(err);
      throw err; // Handle or propagate the error further as needed
    }
  }

  // 컬럼 체크 확인
  const handleColumn = (e) => {
    if(e.target.name === 'key_on') {
      setcolumn((prev) => ({...prev, key_on_date:{...prev.key_on_date, active:(e.target.checked ? true : false)}}))
      setcolumn((prev) => ({...prev, key_on_time:{...prev.key_on_time, active:(e.target.checked ? true : false)}}))
    } else if(e.target.name === 'key_off') {
      setcolumn((prev) => ({...prev, key_off_date:{...prev.key_off_date, active:(e.target.checked ? true : false)}}))
      setcolumn((prev) => ({...prev, key_off_time:{...prev.key_off_time, active:(e.target.checked ? true : false)}}))
    }
    setcolumn((prev) => ({...prev, [e.target.name]:{...prev[e.target.name], active:(e.target.checked ? true : false)}}))
  }

  return (
    <>
      <div
        className="common-table"
        style={{ overflow: "auto", maxHeight: "25em" }}
      >
        <table className="common-table">
          <thead>
            <tr>
              {Object.values(column).map((item) => item.view && (
                <th style={{width:(item.width || 'auto')}}>
                  <label className="custom-checkbox">
                    <input type="checkbox" checked={item.active} name={item.key} onChange={handleColumn}/>
                    <span className="checkbox"></span>
                    <div className="label">
                      {language === "KOR" ? item.ko : item.eng}
                    </div>
                  </label>
                </th>
              ))}              
            </tr>
          </thead>
          <tbody>
            {(isLoading || !data) ? (
              <tr>
                <td colSpan={Object.values(column).filter((item) => item.view).length}>
                  <div className="empty">{language === 'KOR' ? '로딩중입니다...' : 'Loading...'}</div>
                </td>
              </tr>) :
              (data.length === 0 ) ? (
                <tr>
                  <td colSpan={Object.values(column).filter((item) => item.view).length}>
                    <div className="empty">{language === 'KOR' ? '검색된 데이터가 없습니다.' : 'No Data'}</div>
                  </td>
                </tr>
              ) : (
                updateDate &&
                  updateDate.map((item, index) => {
                    const {
                      bt_nm,
                      bt_id,
                      total_cal_dist,
                      dr_hhmiss,
                      key_on,
                      key_on_lon,
                      key_on_lat,
                      addStart,
                      key_off,
                      key_off_lat,
                      key_off_lon,
                      addEnd,
                    } = item;
                    return (
                      <tr key={index}>
                        <td>{sort ? data.length - index : index + 1}</td>
                        <td>{total_cal_dist}</td>
                        <td>{dr_hhmiss}</td>
                        <td>{bt_nm || bt_id || ""}</td>
                        <td>{key_on ? ISODateToDateTime(key_on) : ''}</td>
                        <td style={{wordBreak:'keep-all'}}>{addStart === undefined ? "No Data" : addStart}</td>
                        <td>{key_on_lat}</td>
                        <td>{key_on_lon}</td>
                        <td>{key_off ? ISODateToDateTime(key_off) : ''}</td>
                        <td style={{wordBreak:'keep-all'}}>{addEnd === undefined ? "No Data" : addEnd}</td>
                        <td>{key_off_lat}</td>
                        <td>{key_off_lon}</td>
                      </tr>
                    );
                  })
              )}
          </tbody>
        </table>
      </div>
      <DrivingDayTableExcel data={updateDate} sort={sort} tableRef={tableRef} column={column} date={date} unitid={unitid} unit_nm={unit_nm} totalDist={totalDist}/>
    </>
  );
}
