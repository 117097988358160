import React, { useState, useEffect, useRef } from "react";
import AtomMarker from "../atoms/MarkerAtom";
import { useMap } from "../../../context/MapContext.jsx";
import { createRoot } from "react-dom/client";

export default function Marker({ item, map, circle, setCircle, circleOn }) {
  const { naver } = window;
  const { mapDispatch } = useMap();
  const [marker, setMarker] = useState(null);
  const markerContentRef = useRef(null);
  const { setMap } = useMap();
  useEffect(() => {
    if (!marker) {
      setMarker(new naver.maps.Marker());
    }
    return () => {
      if (marker) {
        marker.eventTarget.parentNode.classList.remove("active");
        marker.setMap(null);
      }
    };
  }, [marker, naver, item, map]);

  useEffect(() => {
    if (map && marker) {
      const markerContentElement = document.createElement("div");
      markerContentRef.current = markerContentElement;
      const root = createRoot(markerContentElement);
      root.render(
        <div className="marker">
          <AtomMarker
            iconnum={item.iconnum}
            direction={item.direction}
            unit_nm={item.unit_nm !== "" ? item.unit_nm : ""}
          />
        </div>
      );
      marker.setOptions({
        position: new naver.maps.LatLng(item.latitude, item.longitude),
        map: map,
        icon: {
          content: markerContentRef.current,
          size: new naver.maps.Size(54, 54),
          anchor: new naver.maps.Point(27, 54),
        },
        markerId: item.unitid,
      });
      marker.eventTarget.parentNode.classList.add("size");
    }
  }, [map, marker, naver, item, mapDispatch]);

  useEffect(() => {
    if (circle.g1 && circleOn === false) {
      circle.g1.setMap(null);
    }
  }, [item, marker, circle, circleOn]);

  return null;
}
