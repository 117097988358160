import React, { useContext } from "react";
import { Link } from "react-router-dom";
//@ts-ignore
import { LanguageContext } from "../../context/LanguageContext";
export default function FooterNews() {
  //@ts-ignore
  const { language, changeLanguage } = useContext(LanguageContext);
  return (
    <>
      <div className="login-footer">
        <div className="footer-block">
          <div className="footer-item">
            <svg
              width="180"
              height="60"
              viewBox="0 0 180 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_33_784)">
                <path
                  d="M47.9479 17.4164L29.8588 7L9.57882 18.627L9 18.9564L27.8351 29.8091C28.281 30.067 28.6514 30.4368 28.9094 30.8819C29.1674 31.327 29.3041 31.8317 29.3057 32.3459V54.0043L34.3221 51.1125V30.2754C34.3214 29.5126 34.1198 28.7633 33.7375 28.1027C33.3553 27.442 32.8058 26.8931 32.1441 26.511L19.0714 18.9778L21.944 17.3223L34.4936 24.5389C35.3139 25.0119 35.9951 25.6916 36.4692 26.5099C36.9432 27.3282 37.1935 28.2565 37.1948 29.2017V49.4527L42.2112 46.5652V25.7281C42.2101 24.9664 42.0088 24.2184 41.6274 23.5586C41.2459 22.8988 40.6977 22.3503 40.0374 21.968L26.9647 14.4348L29.8374 12.7793L42.4427 20.0515C43.2472 20.5174 43.9153 21.1857 44.3802 21.9896C44.8451 22.7935 45.0907 23.7049 45.0924 24.633V44.9225L50.1088 42.035V21.1808C50.1089 20.4199 49.9096 19.6721 49.5305 19.0117C49.1514 18.3513 48.6058 17.8014 47.9479 17.4164Z"
                  fill="#828282"
                />
                <path
                  d="M29.3057 48.2421V54.0043L11.1909 43.5964C10.5314 43.215 9.98335 42.6681 9.60126 42.01C9.21917 41.3518 9.01632 40.6054 9.01286 39.8448V18.965L14.0293 21.8525V37.7701C14.0289 38.2847 14.1642 38.7904 14.4216 39.2364C14.6789 39.6824 15.0493 40.053 15.4956 40.3111L29.3057 48.2421ZM17.8838 38.262L26.3431 43.1259V33.3896C26.3416 33.075 26.2578 32.7661 26.0999 32.4937C25.9419 32.2213 25.7154 31.9949 25.4427 31.8368L16.9748 26.9601V36.7092C16.9762 37.0249 17.061 37.3346 17.2206 37.6072C17.3801 37.8798 17.6089 38.1057 17.8838 38.262Z"
                  fill="#828282"
                />
                <path
                  d="M91.7793 44.4349V45.3246C91.7549 45.9638 91.967 46.5896 92.3753 47.0828C92.7149 47.5129 93.1913 47.8147 93.7259 47.9384L93.117 48.4603C92.6791 48.4132 92.2625 48.2475 91.9122 47.9812C91.562 47.7591 91.2906 47.4329 91.1362 47.0486C90.9917 47.4339 90.7213 47.7594 90.3687 47.9726C90.0168 48.2347 89.6035 48.4026 89.1682 48.4603L88.5422 47.9384C89.084 47.8206 89.5676 47.518 89.91 47.0828C90.3131 46.5889 90.5219 45.9654 90.4974 45.3289V44.4392L91.7793 44.4349ZM94.2532 49.7693H89.8628V49.0506H95.5052V51.9167H94.2532V49.7693ZM95.5181 48.4859H94.2532V44.4392H95.5181V48.4859Z"
                  fill="#828282"
                />
                <path
                  d="M97.2545 45.0637H98.8752V44.1226H100.136V45.0466H101.765V45.7567H100.907C101.077 45.9087 101.214 46.0935 101.311 46.3C101.41 46.5085 101.461 46.7365 101.461 46.9673C101.462 47.3421 101.331 47.7054 101.092 47.994C100.849 48.2979 100.52 48.5228 100.149 48.6399V49.2089H101.113C101.364 49.2106 101.615 49.1935 101.864 49.1575C102.056 49.1296 102.247 49.0881 102.434 49.0335V44.4349H103.72V51.9167H102.434V49.6837C102.235 49.772 102.026 49.838 101.812 49.8805C101.568 49.9286 101.319 49.9529 101.07 49.9532H97.2116V49.2431H98.8795V48.657C98.5084 48.5411 98.1811 48.3159 97.9405 48.0111C97.704 47.721 97.5752 47.3583 97.5761 46.9844C97.5758 46.7536 97.627 46.5257 97.7261 46.3171C97.8237 46.1115 97.9606 45.927 98.1292 45.7738H97.2717L97.2545 45.0637ZM98.9995 46.2187C98.8567 46.4479 98.781 46.7124 98.781 46.9823C98.781 47.2522 98.8567 47.5167 98.9995 47.7459C99.0483 47.8408 99.121 47.9215 99.2104 47.98C99.2999 48.0385 99.403 48.0728 99.5097 48.0795C99.6174 48.0744 99.7218 48.0407 99.8122 47.9821C99.9026 47.9235 99.9758 47.842 100.024 47.7459C100.164 47.5157 100.238 47.2516 100.238 46.9823C100.238 46.713 100.164 46.4489 100.024 46.2187C99.9746 46.1242 99.901 46.0444 99.8107 45.9874C99.7203 45.9303 99.6166 45.8979 99.5097 45.8936C99.3987 45.8943 99.2899 45.9249 99.1949 45.9821C99.0998 46.0393 99.0219 46.1211 98.9695 46.2187H98.9995Z"
                  fill="#828282"
                />
                <path
                  d="M107.759 44.4349V45.8337C107.74 46.5135 107.948 47.1803 108.351 47.7288C108.672 48.1994 109.158 48.5323 109.714 48.6613L109.097 49.2046C108.648 49.156 108.223 48.9742 107.879 48.6827C107.525 48.4355 107.259 48.0818 107.12 47.6732C106.988 48.0804 106.726 48.4334 106.374 48.6784C106.024 48.967 105.599 49.1495 105.148 49.2046L104.543 48.6656C105.098 48.5371 105.583 48.202 105.898 47.7288C106.3 47.1813 106.508 46.5162 106.49 45.838V44.4349H107.759ZM111.288 46.0348H112.548V46.7449H111.288V51.9167H110.001V44.4349H111.288V46.0348Z"
                  fill="#828282"
                />
                <path
                  d="M119.348 45.0209C119.557 45.218 119.723 45.4555 119.837 45.7188C119.95 45.9821 120.009 46.2657 120.009 46.5524C120.009 46.839 119.95 47.1227 119.837 47.386C119.723 47.6493 119.557 47.8867 119.348 48.0838C118.919 48.4878 118.352 48.7129 117.762 48.7129C117.172 48.7129 116.604 48.4878 116.175 48.0838C115.967 47.8867 115.801 47.6493 115.687 47.386C115.573 47.1227 115.515 46.839 115.515 46.5524C115.515 46.2657 115.573 45.9821 115.687 45.7188C115.801 45.4555 115.967 45.218 116.175 45.0209C116.603 44.615 117.171 44.3886 117.762 44.3886C118.352 44.3886 118.92 44.615 119.348 45.0209ZM117.076 47.5876C117.147 47.7109 117.247 47.8145 117.369 47.8892C117.49 47.9639 117.628 48.0073 117.77 48.0154C117.912 48.0094 118.05 47.9669 118.17 47.8919C118.29 47.8169 118.389 47.7121 118.456 47.5876C118.658 47.2808 118.76 46.9192 118.748 46.5524C118.759 46.1883 118.658 45.8297 118.456 45.5257C118.392 45.398 118.293 45.2907 118.17 45.2157C118.048 45.1407 117.907 45.101 117.764 45.101C117.62 45.101 117.48 45.1407 117.357 45.2157C117.235 45.2907 117.136 45.398 117.071 45.5257C116.869 45.8354 116.767 46.1999 116.78 46.5695C116.768 46.9315 116.872 47.288 117.076 47.5876ZM121.955 46.0989H123.22V46.8176H121.955V51.9167H120.694V44.4349H121.955V46.0989Z"
                  fill="#828282"
                />
                <path
                  d="M127.653 45.0209C127.862 45.218 128.028 45.4555 128.141 45.7188C128.255 45.9821 128.314 46.2657 128.314 46.5524C128.314 46.839 128.255 47.1227 128.141 47.386C128.028 47.6493 127.862 47.8867 127.653 48.0838C127.222 48.4877 126.652 48.7125 126.06 48.7125C125.469 48.7125 124.899 48.4877 124.467 48.0838C124.259 47.8867 124.093 47.6493 123.979 47.386C123.866 47.1227 123.807 46.839 123.807 46.5524C123.807 46.2657 123.866 45.9821 123.979 45.7188C124.093 45.4555 124.259 45.218 124.467 45.0209C124.898 44.6141 125.468 44.3874 126.06 44.3874C126.653 44.3874 127.223 44.6141 127.653 45.0209ZM125.368 47.5876C125.439 47.7115 125.541 47.8155 125.663 47.8902C125.785 47.9649 125.924 48.008 126.067 48.0154C126.208 48.0082 126.345 47.9652 126.465 47.8903C126.585 47.8154 126.684 47.7113 126.753 47.5876C126.958 47.2821 127.061 46.9199 127.049 46.5524C127.062 46.1874 126.959 45.8277 126.753 45.5257C126.681 45.4018 126.58 45.2978 126.458 45.2231C126.336 45.1484 126.197 45.1053 126.054 45.0979C125.913 45.1074 125.777 45.1514 125.657 45.226C125.537 45.3007 125.438 45.4036 125.368 45.5257C125.162 45.8341 125.058 46.1992 125.072 46.5695C125.06 46.9315 125.164 47.288 125.368 47.5876ZM130.419 51.9167H129.132V44.4349H130.419V51.9167Z"
                  fill="#828282"
                />
                <path
                  d="M138.805 50.766H131.812V50.0473H134.676V48.5544C134.149 48.4438 133.671 48.168 133.313 47.7673C133.126 47.5635 132.982 47.3241 132.891 47.0633C132.799 46.8026 132.762 46.5261 132.781 46.2505C132.799 45.9749 132.874 45.706 133 45.4601C133.126 45.2142 133.301 44.9964 133.514 44.8199C134.014 44.3972 134.649 44.1652 135.304 44.1652C135.96 44.1652 136.594 44.3972 137.094 44.8199C137.326 45.0128 137.512 45.2542 137.639 45.5271C137.767 45.7999 137.833 46.0974 137.832 46.3984C137.832 46.9057 137.641 47.3946 137.296 47.7673C136.941 48.1685 136.466 48.4446 135.941 48.5544V50.0473H138.805V50.766ZM134.415 45.3247C134.18 45.6321 134.053 46.0078 134.053 46.3941C134.053 46.7804 134.18 47.1561 134.415 47.4635C134.519 47.5994 134.654 47.7093 134.808 47.785C134.963 47.8607 135.132 47.9 135.304 47.9C135.476 47.9 135.646 47.8607 135.8 47.785C135.954 47.7093 136.089 47.5994 136.194 47.4635C136.428 47.1561 136.555 46.7804 136.555 46.3941C136.555 46.0078 136.428 45.6321 136.194 45.3247C136.089 45.1881 135.955 45.0774 135.8 45.0012C135.646 44.925 135.476 44.8854 135.304 44.8854C135.132 44.8854 134.962 44.925 134.808 45.0012C134.654 45.0774 134.519 45.1881 134.415 45.3247Z"
                  fill="#828282"
                />
                <path
                  d="M171 50.766H164.007V50.0473H171V50.766ZM168.136 44.4349V45.2477C168.131 45.6126 168.212 45.9736 168.373 46.3013C168.534 46.6291 168.77 46.9144 169.062 47.1342C169.572 47.5886 170.216 47.865 170.897 47.9213L170.335 48.4603C169.735 48.4463 169.146 48.2878 168.62 47.9983C168.119 47.7907 167.713 47.403 167.484 46.9117C167.263 47.4066 166.859 47.7973 166.357 48.0025C165.83 48.2892 165.242 48.4461 164.642 48.4603L164.08 47.9213C164.761 47.865 165.405 47.5886 165.915 47.1342C166.207 46.9144 166.443 46.6291 166.604 46.3013C166.765 45.9736 166.846 45.6126 166.841 45.2477V44.4349H168.136Z"
                  fill="#828282"
                />
                <path
                  d="M160.967 44.4393V48.6657H159.78V46.6894H158.995V46.8605H157.318V47.8401H159.261V48.5502H156.062V46.9503C156.056 46.8432 156.073 46.736 156.112 46.6358C156.15 46.5356 156.209 46.4445 156.285 46.3685C156.456 46.2192 156.68 46.1438 156.907 46.1589H157.764V45.1793H155.994V44.4606H158.995V45.9835H159.771V44.4606L160.967 44.4393Z"
                  fill="#828282"
                />
                <path
                  d="M162.716 44.4349H161.529V48.6656H162.716V44.4349Z"
                  fill="#828282"
                />
                <path
                  d="M156.851 49.9104H161.452V51.9167H162.716V49.1918H156.851V49.9104Z"
                  fill="#828282"
                />
                <path
                  d="M154.279 51.2194V51.9167H148.885V50.8344C148.881 50.7218 148.9 50.6094 148.942 50.5048C148.984 50.4001 149.048 50.3055 149.129 50.227C149.214 50.1469 149.313 50.0847 149.422 50.0442C149.531 50.0038 149.648 49.9859 149.764 49.9917H152.842V49.4613H148.799V48.7597H154.107V50.6676H150.15V51.2194H154.279Z"
                  fill="#828282"
                />
                <path
                  d="M154.279 46.2615H153.288V45.1322H154.197V44.4306H148.581V45.1322H149.498V46.2615H148.495V46.9545H154.279V46.2615ZM152.023 46.2615H150.763V45.1322H152.023V46.2615Z"
                  fill="#828282"
                />
                <path
                  d="M154.986 47.4978H147.826V48.1993H154.986V47.4978Z"
                  fill="#828282"
                />
                <path
                  d="M146.42 44.4392V51.9167H145.159V48.8282C144.971 48.9244 144.772 48.999 144.567 49.0506C144.323 49.1102 144.073 49.1403 143.821 49.1404H140.263V45.2519C140.258 45.1418 140.276 45.032 140.317 44.9295C140.358 44.827 140.419 44.7343 140.499 44.6573C140.583 44.5806 140.682 44.5213 140.789 44.4831C140.897 44.4449 141.011 44.4285 141.124 44.4349H144.147V45.1407H141.528V46.424H144.1V47.117H141.528V48.4004H143.894C144.132 48.4017 144.37 48.3846 144.606 48.349C144.794 48.323 144.979 48.2785 145.159 48.2164V44.4178L146.42 44.4392Z"
                  fill="#828282"
                />
                <path
                  d="M80.7861 48.5287V49.2345H83.7788V51.9167H85.0436V49.2345H88.0277V48.5287H80.7861Z"
                  fill="#828282"
                />
                <path
                  d="M87.8219 47.1727L87.2217 47.7117C86.6454 47.7004 86.0781 47.5662 85.5581 47.3181C85.083 47.1383 84.6826 46.8037 84.4219 46.3684C84.1713 46.8098 83.7717 47.1479 83.2943 47.3224C82.7714 47.5703 82.2011 47.7031 81.6222 47.7117L81.0262 47.1727C81.7012 47.1583 82.3542 46.9306 82.8913 46.5224C83.1331 46.3769 83.3387 46.1784 83.4924 45.942C83.6462 45.7056 83.7441 45.4375 83.7788 45.1578H81.5579V44.4392H87.286V45.1578H85.0651C85.1009 45.4377 85.1997 45.7058 85.3541 45.9421C85.5086 46.1785 85.7146 46.3769 85.9569 46.5224C86.4946 46.9293 87.1472 47.1568 87.8219 47.1727Z"
                  fill="#828282"
                />
                <path
                  d="M135.306 26.1559V28.6755H148.208V32.0293H135.306V34.4976C135.364 35.0607 135.629 35.5823 136.051 35.9611C136.472 36.3398 137.02 36.5485 137.587 36.5467H148.208V40.0117H136.953C136.394 40.0131 135.839 39.9294 135.306 39.7636C134.143 39.4134 133.123 38.7023 132.393 37.7338C131.662 36.7653 131.261 35.5897 131.246 34.3778V26.2928C131.261 25.081 131.662 23.9054 132.393 22.9369C133.123 21.9683 134.143 21.2573 135.306 20.9071C135.839 20.7392 136.394 20.6555 136.953 20.659H148.208V24.124H137.587C137.023 24.1217 136.477 24.328 136.056 24.7032C135.635 25.0784 135.368 25.5959 135.306 26.1559Z"
                  fill="#828282"
                />
                <path
                  d="M70.3974 25.6768H70.3717C68.4678 25.678 66.6422 26.4334 65.2963 27.777C63.9504 29.1206 63.1944 30.9425 63.1944 32.8421V32.8678C63.1955 34.7666 63.9521 36.5874 65.2978 37.9301C66.6436 39.2728 68.4685 40.0276 70.3717 40.0288H70.3974C72.301 40.0288 74.1265 39.2743 75.4726 37.9314C76.8186 36.5884 77.5748 34.767 77.5748 32.8678V32.8421C77.5753 31.9014 77.3901 30.9697 77.0296 30.1004C76.6692 29.2311 76.1406 28.4412 75.4741 27.7757C74.8075 27.1103 74.0161 26.5825 73.1451 26.2223C72.274 25.8622 71.3403 25.6768 70.3974 25.6768ZM74.1019 32.8678C74.1019 33.8473 73.7122 34.7867 73.0184 35.4797C72.3246 36.1727 71.3834 36.5626 70.4017 36.5638C69.4192 36.5638 68.477 36.1744 67.7823 35.4812C67.0876 34.7881 66.6973 33.848 66.6973 32.8678V32.8378C66.6995 31.8642 67.0867 30.9308 67.7747 30.2404C68.4627 29.55 69.3959 29.1583 70.3717 29.1504C71.3534 29.1515 72.2946 29.5414 72.9884 30.2344C73.6821 30.9274 74.0718 31.8669 74.0718 32.8464L74.1019 32.8678Z"
                  fill="#828282"
                />
                <path
                  d="M60.8534 20.6592H56.7974V40.029H60.8534V20.6592Z"
                  fill="#828282"
                />
                <path
                  d="M129.411 36.5638V40.0288H118.135C117.577 40.0298 117.021 39.9462 116.488 39.7807C115.325 39.4305 114.305 38.7194 113.575 37.7509C112.844 36.7824 112.443 35.6068 112.428 34.3949V20.659H116.488V34.5147C116.547 35.0774 116.812 35.5985 117.234 35.9771C117.655 36.3556 118.202 36.5647 118.769 36.5638H129.411Z"
                  fill="#828282"
                />
                <path
                  d="M108.595 22.3059C108.069 21.7808 107.443 21.3644 106.756 21.0803C106.068 20.7963 105.331 20.6502 104.586 20.6504H93.34V40.0202H97.3917V31.9395H104.518C105.649 31.9711 106.763 31.6612 107.715 31.0506C108.667 30.4399 109.412 29.5568 109.852 28.5169C110.293 27.4769 110.408 26.3285 110.184 25.2219C109.96 24.1152 109.406 23.1019 108.595 22.3145V22.3059ZM103.96 28.4659H97.3917V24.124H103.96C104.256 24.1131 104.551 24.1616 104.828 24.2666C105.105 24.3715 105.357 24.5309 105.571 24.7351C105.785 24.9393 105.956 25.1844 106.073 25.4556C106.19 25.7268 106.252 26.0188 106.254 26.3142C106.251 26.6081 106.189 26.8985 106.072 27.1679C105.954 27.4373 105.783 27.6802 105.568 27.882C105.354 28.0837 105.101 28.2402 104.825 28.342C104.549 28.4439 104.254 28.4889 103.96 28.4745V28.4659Z"
                  fill="#828282"
                />
                <path
                  d="M91.5692 20.659H74.0504V24.1197H80.7861V40.0288H84.8378V24.1197H91.5692V20.659Z"
                  fill="#828282"
                />
                <path
                  d="M155.989 20.659L160.598 26.6094L171 40.0288H165.212L160.598 34.0784L155.989 40.0288H150.197L157.709 30.3439L150.197 20.659H155.989Z"
                  fill="#828282"
                />
                <path
                  d="M160.598 26.6094L166.391 26.6094L171 20.659H165.212L160.598 26.6094Z"
                  fill="#828282"
                />
              </g>
              <defs>
                <clipPath id="clip0_33_784">
                  <rect
                    width="162"
                    height="47"
                    fill="white"
                    transform="translate(9 7)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="footer-item">
            <p>부산광역시 해운대구 센텀동로 99</p>
            <p>벽산e센텀클래스원 601호</p>
            <p>gps@iotplex.co.kr</p>
          </div>
          <div className="footer-item">
            <p>사업자등록번호 256-81-00086 </p>
            <p>통신판매신고 제2015-부산해운-0419호 </p>
          </div>
          <div className="footer-links">
            <Link
              to={`https://smartstore.naver.com/iotplex`}
              className="footer-icon"
            >
              <img
                src="../../assets/img/login/naver.png"
                alt=""
                style={{ width: "46px" }}
              />
            </Link>
            <Link
              to={`https://blog.naver.com/iotplex20`}
              className="footer-icon"
            >
              <img src="../../assets/img/login/store.png" alt="" />
            </Link>
            <button className="footer-btn" onClick={(e) => changeLanguage(e)}>
              <span>ENG</span>
              <img src="../../assets/img/login/eng_btn.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
