import React, { useEffect } from "react";
import { useState } from "react";
import { MapProvider } from "../../context/MapContext.jsx";
import axios from "../../lib/axios.js";
import Box from "./template/Box";
import { useAuth } from "../../context/LoginContext";
import Radio from "./atoms/Radio";

export default function ScreeSplit() {
  // State definition
  //==================================================================
  const [count, setCount] = useState([]);
  const [list, setList] = useState([]);
  const [isSelect, setIsSelect] = useState(true);
  const [device, setDevice] = useState([]);
  const [geo, setGeo] = useState([]);
  const [box, setBox] = useState({});
  const [map, setMap] = useState([]);
  const { user } = useAuth();
  const { naver } = window;

  // UseEffect definition
  //==================================================================
  useEffect(() => {
    switch (count.length) {
      case 0:
        break;

      case 1:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-1'
              'box-1 box-1 '
            `,
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr 1fr",
          height: "100vh",
        });
        break;
      case 2:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-2'
              'box-1 box-2 '
            `,
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr 1fr",
          height: "100vh",
        });
        break;
      case 3:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-3'
              'box-2 box-3 '
            `,
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr 1fr",
          height: "100vh",
        });
        break;
      case 4:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-2 '
              'box-3 box-4 '
            `,
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr 1fr",
          height: "100vh",
        });
        break;
      case 5:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-2 box-3'
              'box-4 box-5 box-3'
            `,
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateRows: "1fr 1fr",
          height: "100vh",
        });
        break;
      case 6:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-2 box-3'
              'box-4 box-5 box-6'
            `,
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateRows: "1fr 1fr",
          height: "100vh",
        });
        break;
      case 8:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'box-1 box-2 box-3 box-4'
              'box-5 box-6 box-7 box-8'
            `,
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gridTemplateRows: "1fr 1fr",
          height: "100vh",
        });
        break;
      default:
        setBox({
          display: "grid",
          gridTemplateAreas: `
              'top-left top-right'
              'bottom bottom'
            `,
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
          gridTemplateRows: "1fr 1fr  ",
          height: "100vh",
        });
        break;
    }
  }, [count]);
  useEffect(() => {
    if (count.length !== 0) {
      // Create an array to store map objects
      const newMaps = [];

      count.forEach((idx) => {
        setTimeout(() => {
          const { naver } = window;
          const mapOptions = {
            // center: new naver.maps.LatLng(37.3595704, 127.105399),
            zoom: 14,
          };
          // Create a new map object and add it to the array
          newMaps.push(new naver.maps.Map(`map_${idx}`, mapOptions));
          // If all map objects have been created, update the state
          if (newMaps.length === count.length) {
            setMap(newMaps);
          }
        }, 500);
      });
    }
    setIsSelect(!isSelect);
  }, [count]);
  useEffect(() => {
    function getDataList() {
      axios
        .get(
          `/web/map/multi/list?type=${user.type}&org_idx=${user.org_idx}`
        )
        .then((res) => {
          setDevice(res.data.dev);
          setGeo(res.data.geo);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (user) {
      getDataList();
    }
  }, [user]);
  useEffect(() => {
    if (device && device.length > 0) {
      setList((prev) => ([...prev,
        { select: true, unit_nm: `Device` },
        ...device,
      ]));
    }
    if (geo && geo.length > 0) {
      setList((prev) => ([...prev,
        { select: true, unit_nm: `Geo Fance` },
        ...geo,
      ]));
    }
  }, [device, geo]);
  // Data definition
  //==================================================================

  // Log definition
  //==================================================================
  // console.log(map);
  // console.log(geo, "geo");
  // console.log(device, "device");
  // console.log(list, "list");
  // console.log(isSelect, "isSelect");

  return (
    <>
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        {!isSelect && (
          <div className="map-modal-select">
            <div className="map-modal-select-body">
              <div style={{fontSize:'30px'}}>화면 개수를 선택해주세요</div>
              <div className="map-modal-select-body-row">
                {[1, 2, 3, 4, 5, 6, 8].map((idx, index) => {
                  return (
                    <Radio
                      key={index}
                      idx={idx}
                      setCount={setCount}
                      count={count}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        )}
        <MapProvider>
          <div style={box}>
            {count.map((idx, index) => {
              return (
                <Box
                  idx={idx}
                  key={index}
                  map={map[index]}
                  naver={naver}
                  list={list}
                  user={user}
                  count={count}
                />
              );
            })}
          </div>
        </MapProvider>
      </div>
    </>
  );
}
