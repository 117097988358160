import PropTypes from "prop-types";
import AtomBTN from "../atom/AtomBTN";
import Line from "../atom/EventLine";
import moment from "moment";
import WeekDays from "../molucule/WeekDays";
export default function CalendarBox({
  eventList,
  date,
  setSelectedDate,
  lang,
}) {
  let dayCounter = 1;
  const calendarCells = [];
  const firstDayOfMonth = date.clone().startOf("month");
  const startOffset = firstDayOfMonth.day();
  const handleDayClick = (value) => {
    setSelectedDate(value);
  };
  const daysInMonth = date.daysInMonth();
  const limitedEvents = eventList;
  for (let i = 0; i < 6; i++) {
    const calendarRow = [];
    for (let j = 0; j < 7; j++) {
      const currentDate = date
        .clone()
        .startOf("month")
        .add(i * 7 + j - startOffset, "days")
        .format("YYYY-MM-DD");
      const dayOfWeek = moment(currentDate).day();
      let cellColor = "";
      if (dayOfWeek === 6) {
        // Saturday (Sat)
        cellColor = "orange";
      } else if (dayOfWeek === 0) {
        // Sunday (Sun)
        cellColor = "red";
      }
      if (i === 0 && j < startOffset) {
        // Calculate the day value for the previous month
        const prevMonthDays = firstDayOfMonth
          .clone()
          .subtract(startOffset - j, "days");
        calendarRow.push(
          <td key={`prev-${j}`} className="calendar-day disabled">
            <AtomBTN
              classN={`calendar-day-btn disabled`}
              value={`${currentDate}`}
              onClick={(e) => handleDayClick(e.target.value)}
              disabled
            >
              <span
                style={{ color: cellColor }}
                className="calendar-text days-prevmonth"
              >
                {prevMonthDays.date()}
              </span>
              <Line eventList={limitedEvents} currentDate={currentDate} />
            </AtomBTN>
          </td>
        );
      } else if (dayCounter <= daysInMonth) {
        const isToday =
          dayCounter === moment().date() && date.isSame(moment(), "month");
        calendarRow.push(
          <td
            key={`current-${dayCounter}`}
            className={`calendar-day ${isToday ? "today" : ""}`}
          >
            <AtomBTN
              classN="calendar-day-btn"
              value={`${currentDate}`}
              onClick={(e) => handleDayClick(e.target.value)}
            >
              <span
                style={{ color: isToday ? "red" : cellColor, width: 20 }}
                className="calendar-text "
              >
                {dayCounter}
              </span>
              <Line eventList={limitedEvents} currentDate={currentDate} />
            </AtomBTN>
          </td>
        );
        dayCounter++;
      } else {
        // Calculate the day value for the next month
        const nextMonthDays = firstDayOfMonth
          .clone()
          .add(dayCounter - daysInMonth - 1, "days");
        calendarRow.push(
          <td key={`next-${j}`} className="calendar-day disabled">
            <AtomBTN
              classN={`calendar-day-btn disabled`}
              value={`${currentDate}`}
              onClick={(e) => handleDayClick(e.target.value)}
              disabled
            >
              <span
                style={{ color: cellColor }}
                className="calendar-text days-prevmonth"
              >
                {nextMonthDays.date()}
              </span>
              <Line eventList={limitedEvents} currentDate={currentDate} />
            </AtomBTN>
          </td>
        );
        dayCounter++;
      }
    }
    calendarCells.push(<tr key={i}>{calendarRow}</tr>);
  }
  return (
    <>
      <table className="calendar-box">
        <thead className="calendar-weekdays">
          <WeekDays lang={lang} />
        </thead>
        <tbody className="calendar-days">{calendarCells}</tbody>
      </table>
    </>
  );
}
CalendarBox.propTypes = {
  eventList: PropTypes.arrayOf(PropTypes.object),
  date: PropTypes.object,
  setSelectedDate: PropTypes.func,
};
