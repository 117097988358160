import React from "react";
import Icon from "../Atom/Icon";
import Info from "../Molecule/Info";
interface Props {
  icon: string;
  title: string;
  value: string;
  sub?: string;
  onClick?: () => void;
}
export default function Card({ icon, title, value, sub, onClick }: Props) {
  return (
    <>
      <div className="das-card" onClick={onClick}>
        <Icon icon={icon} />
        <Info title={title} value={value} sub={sub} />
      </div>
    </>
  );
}
