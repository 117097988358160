import { useState, useEffect } from "react";
import { useMap } from "../../../context/MapContext";
import { getCenter, getIconNumber, getMarkerType } from "../../../lib/mapHelper";
import { v4 } from "uuid";
import axios from "axios";
import { code } from "../../../lib/eventData";
export default function PathMarker() {
  const { naver } = window;
  const { mapState } = useMap();
  const { map, pathData, pathMarkerShow, selectItem } = mapState;

  const { centerY: y, centerX: x } = getCenter(pathData);
  const latitude = [];
  const longitude = [];

  useEffect(() => {
    const fit = new naver.maps.LatLngBounds(
      new naver.maps.LatLng(
        Math.min.apply(null, latitude),
        Math.min.apply(null, longitude)
      ),
      new naver.maps.LatLng(
        Math.max.apply(null, latitude),
        Math.max.apply(null, longitude)
      )
    );
    if (map) {
      map.fitBounds(fit, { duration: 300, easing: "easeOutCubic" });
    }
  }, [x, y, map]);

  return (
    <>
      {pathMarkerShow &&
        pathData.map((item) => {
          latitude.push(item.latitude);
          longitude.push(item.longitude);
          return <Marker key={item.idx} item={item} />;
        })}

      <PolyLine item={pathData} />
    </>
  );
}

function Marker({ item }) {
  const { naver } = window;
  const { mapState, mapDispatch } = useMap();
  const { map, selectPathItem, pathData, selectItem, selectDailyItem } = mapState;
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (!marker) {
      setMarker(new naver.maps.Marker());
    }

    // map이 언마운트될때 삭제
    return () => {
      if (marker) {
        marker.eventTarget.parentNode.classList.remove("active");
        marker.setMap(null);
      }
    };
  }, [marker, naver]);

  useEffect(() => {
    if (map && marker) {
      marker.setOptions({
        position: new naver.maps.LatLng(item.latitude, item.longitude),
        map: map,
        icon: {
          content: `
                    <div class="path-marker ${code.indexOf(item.event_code) === -1 ? 'event' : getIconNumber(selectDailyItem ? selectDailyItem.icon_code : selectItem.icon_code)}">
                    <div class="marker-name">${item.row}</div>
                </div>
                `,
          size: new naver.maps.Size(24, 24),
          anchor: new naver.maps.Point(12, 12),
        },
        markerId: item.idx,
      });
      marker.eventTarget.parentNode.classList.add("size");

      naver.maps.Event.addListener(marker, "click", async (e) => {
        const result = await axios.post(
          `/web/map/tracking/coords/detail`,
          { idx: item.idx }
        );

        mapDispatch({
          type: "SET_SELECT_PATH_ITEM",
          payload: result.data,
        });
      });
    }
  }, [map, marker, naver, item, mapDispatch, selectItem]);

  useEffect(() => {
    if (marker && map) {
      if (selectPathItem && selectPathItem.idx === item.idx) {
        // map.setZoom(20, false);
        map.panTo(
          {
            x: marker.position.x,
            y: marker.position.y - 0.00012, //약간 위로 보이게 보정
          },
          {
            duration: 300,
            easing: "easeOutCubic",
          }
        );
        marker.eventTarget.parentNode.classList.add("active");
      } else {
        marker.eventTarget.parentNode.classList.remove("active");
      }
    }
  }, [map, item, marker, selectPathItem, pathData]);

  return null;
}

function PolyLine({ item }) {
  const { naver } = window;
  const { mapState, mapDispatch } = useMap();
  const { map } = mapState;
  const [poly, setPoly] = useState(null);

  useEffect(() => {
    if (!poly) {
      setPoly(new naver.maps.Polyline());
    }

    return () => {
      if (poly) {
        poly.setMap(null);
      }
    };
  }, [naver, poly, map]);

  useEffect(() => {
    if (map && poly) {
      poly.setOptions({
        map: map,
        path: item.map((item) => ({ y: item.latitude, x: item.longitude })),
        strokeColor: "#7168E4",
        strokeOpacity: 1,
        strokeWeight: 5,
        filter: "drop-shadow( 3px 10px 2px rgba(0, 0, 0, .7))",
        startIcon: naver.maps.PointingIcon.BLOCK_ARROW,
        startIconSize: 20,
        endIcon: naver.maps.PointingIcon.CIRCLE,
        endIconSize: 20,
      });

      poly._shape._shapeElement.parentNode.classList.add("path-shadow");
    }
  }, [map, poly, naver, item]);

  return null;
}