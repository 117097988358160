import { useState, useEffect } from "react";
import { useMap } from "../../../context/MapContext";
import { code } from "../../../lib/eventData";

export default function HistoryMarker() {
  const { naver } = window;
  const { mapState, mapDispatch } = useMap();
  const { map, selectPathItem, pathData } = mapState;
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    if (!marker) {
      setMarker(new naver.maps.Marker());
    }

    // map이 언마운트될때 삭제
    return () => {
      if (marker) {
        marker.eventTarget.parentNode.classList.remove("active");
        marker.setMap(null);
      }
    };
  }, [marker, naver]);

  useEffect(() => {
    if (map && marker) {
      marker.setOptions({
        position: new naver.maps.LatLng(
          selectPathItem.latitude,
          selectPathItem.longitude
        ),
        map: map,
        icon: {
          content: `
                    <div class="marker ${code.indexOf(selectPathItem.event_code) === -1 ? 'event' : 'history'}">
                        <div class="marker-icon">
                            <span class="icon">
                            <i class="${code.indexOf(selectPathItem.event_code) === -1 ? 'ri-alert-fill' :'ri-focus-3-line'}"></i>
                            </span>
                        </div>
                    </div>
                `,
          size: new naver.maps.Size(54, 54),
          anchor: new naver.maps.Point(27, 54),
        },
        markerId: selectPathItem.idx,
      });
      marker.eventTarget.parentNode.classList.add("size");
    }
  }, [map, marker, naver, selectPathItem, mapDispatch]);

  useEffect(() => {
    //selected state변경이되면 마커위치로 이동하고 클래스 토글
    if (marker) {
      map.setZoom(17, false);
      map.panTo(
        {
          ...marker.position,
          y: marker.position.y - 0.00012, //약간 위로 보이게 보정
        },
        {
          duration: 300,
          easing: "easeOutCubic",
        }
      );
      marker.eventTarget.parentNode.classList.add("active", "temp");
    }
  }, [map, marker, selectPathItem, pathData]);

  return null;
}
