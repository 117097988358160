import React, { useEffect, useRef, useState } from 'react';
import TypeSelector from './selector/TypeSelector';
import CriterionSelector from './selector/CriterionSelector';
import DeviceSelector from './selector/DeviceSelector';
import { ISODateToDateTime } from '../../lib/common_func';
import { getMarkerType, getState, getStateKOR } from '../../lib/mapHelper';
import { getGPSState, getStateENG } from '../../lib/map_common';
import { useLang } from '../../context/LanguageContext';
import { useMap } from '../../context/MapContext';

export default function TotalList({ data, typeData, tab }) {
    const { language } = useLang();
    const { mapState, mapDispatch } = useMap();
    const checkedRef = useRef(null);

    const [isOpen, setIsOpen] = useState(false); // 정렬 옵션 박스 관리
    const [originData, setOriginData] = useState(null);
    const [viewData, setViewData] = useState(null);
    const [filterOptions, setFilterOptions] = useState({
        unit_type: "",
        unit_nm: "",
        unitid: "",
        keywordType: "unit_nm",
        keyword: "",
    }); // 검색 옵션
    const [orderOptions, setOrderOptions] = useState({
        type: "unit_nm",
        direction: "desc",
    }); // 정렬 옵션
    const [isSearch, setIsSearch] = useState(false); // 검색 감지
    
    // 통신 데이터
    useEffect(() => {
        setOriginData(data);
    }, [data]);
    // 렌더링 데이터
    useEffect(() => {
        let data = originData;
        if (data) {
            // 필터링
            data = makeFilter(data);
            // 정렬
            data = makeOrder(data);
            setViewData(data);
        }
    }, [originData, orderOptions, isSearch]);
    // 아이템 활성화
    useEffect(() => {
        checkedRef?.current?.scrollIntoView({ block: "center" });
    }, [mapState.selectItem, viewData]);

    // 단말 클릭
    const handleListClick = (item) => {
        mapDispatch({ type: "BLUR" });
        mapDispatch({ type: "SET_SELECT_ITEM", payload: item });
        mapDispatch({ type: "SET_TOTAL_EVENT_MODE", payload: false });
        mapDispatch({ type: "SET_IS_MARKER_CLICKED", payload: true });
    };
    // 검색 옵션 핸들러
    const optionHandler = (e) => {
        e.preventDefault();
        const option = e.target.id;
        const value = e.target.value;
        switch (option) {
            case "unit_type":
                setFilterOptions((prev) => ({
                    ...prev,
                    unit_type: value,
                }));
                break;
            case "unitid":
                setFilterOptions((prev) => ({
                    ...prev,
                    unitid: value === "All" ? "" : value,
                }));
                break;
            case "keywordType":
                setFilterOptions((prev) => ({ ...prev, keywordType: value }));
                break;
            case "keyword":
                setFilterOptions((prev) => ({ ...prev, keyword: value }));
                break;
            default:
                break;
        }
    };    
    // 검색
    const submitHandler = (e) => {
        e.preventDefault();
        setIsSearch((prev) => !prev);
    };
    // 정렬
    const makeOrder = (data) => {
        const result = data.sort(
            (a, b) =>
                (orderOptions.direction === "asc" ? 1 : -1) *
                (a[orderOptions.type] > b[orderOptions.type] ? 1 : -1)
        );
        return result;
    };
    // 필터링
    const makeFilter = (d) => {
        return d
            .filter((data) => {
                if (filterOptions.unit_type != "") {
                    return data.iconnum.includes(filterOptions.unit_type);
                } else {
                    return data;
                }
            })
            .filter((data) => {
                if (filterOptions.keyword != "") {
                    switch (filterOptions.keywordType) {
                        case "unit_nm":
                            return data.unit_nm.toLowerCase().includes(filterOptions.keyword.toLowerCase());
                        case "unitid":
                            return data.unitid.includes(filterOptions.keyword);
                        default:
                            return;
                    }
                }
                return data;
            })
            .filter((data) => {
                if (filterOptions.unitid != "") {
                    return data.unitid === filterOptions.unitid;
                }
                return data;
            });
    };
    // 정렬 옵션 변경
    const changeOption = (o) => {
        setIsOpen(false);
        setOrderOptions(o);
    };
    // 정렬 옵션 문구
    const handleOptionText = (v) => {
        if (language === "KOR") {
            if (v.type === "unit_nm") {
                return v.direction === "asc"
                    ? "단말기명 오름차순"
                    : "단말기명 내림차순";
            } else if (v.type === "makedate_cmbd") {
                return v.direction === "asc"
                    ? "최근수신 오름차순"
                    : "최근수신 내림차순";
            }
        } else if (language === "ENG") {
            if (v.type === "unit_nm") {
                return v.direction === "asc"
                    ? "Dvc. name Asc."
                    : "Dvc. name Desc.";
            } else if (v.type === "makedate_cmbd") {
                return v.direction === "asc"
                    ? "Last Received Asc."
                    : "Last Received Desc.";
            }
        }
    };

    return (
        <>
            <div
                className={`tab-panel ${
                    tab === "all" ? "on" : ""
                }`}
            >
                <form onSubmit={submitHandler}>
                    <div className="search-module">
                        <div className="row">
                            <TypeSelector
                                data={typeData}
                                onChange={optionHandler}
                            />
                            <CriterionSelector
                                onChange={optionHandler}
                            />
                            <DeviceSelector
                                data={data}
                                onChange={optionHandler}
                            />
                        </div>
                        <div className="row">
                            <div className="custom-input">
                                <input
                                    id="keyword"
                                    type="text"
                                    placeholder={
                                        language === "KOR"
                                            ? "검색어를 입력하세요"
                                            : "Enter a search keyword"
                                    }
                                    onChange={optionHandler}
                                />
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="submit-btn"
                        >
                            {language === "KOR"
                                ? "검색"
                                : "Search"}
                        </button>
                    </div>
                </form>
                <div className="track-list total">
                    <div className="list-header">
                        <div className="total">
                            <span className="label">
                                {language === "KOR"
                                    ? "전체목록"
                                    : "Total List"}
                            </span>
                            <span className="count">
                                {viewData?.length || 0}
                            </span>
                        </div>
                        <div className="filter-module">
                            <div
                                className={`filter-btn cursor`}
                                onClick={() => setIsOpen(true)}
                            >
                                <i
                                    className={
                                        orderOptions.direction ===
                                        "desc"
                                            ? "ri-arrow-down-line blue icon"
                                            : "ri-arrow-up-line red icon"
                                    }
                                ></i>
                                {handleOptionText(orderOptions)}
                            </div>
                            <div
                                className={`filter-option ${
                                    isOpen ? "on" : ""
                                }`}
                            >
                                <div
                                    className="filter-option-btn cursor"
                                    onClick={() =>
                                        changeOption({
                                            type: "unit_nm",
                                            direction: "asc",
                                        })
                                    }
                                >
                                    <i className="ri-arrow-up-line red icon"></i>
                                    {handleOptionText({
                                        type: "unit_nm",
                                        direction: "asc",
                                    })}
                                </div>
                                <div
                                    className="filter-option-btn cursor"
                                    onClick={() =>
                                        changeOption({
                                            type: "unit_nm",
                                            direction: "desc",
                                        })
                                    }
                                >
                                    <i className="ri-arrow-down-line blue icon"></i>
                                    {handleOptionText({
                                        type: "unit_nm",
                                        direction: "desc",
                                    })}
                                </div>
                                <div
                                    className="filter-option-btn cursor"
                                    onClick={() =>
                                        changeOption({
                                            type: "makedate_cmbd",
                                            direction: "asc",
                                        })
                                    }
                                >
                                    <i className="ri-arrow-up-line red icon"></i>
                                    {handleOptionText({
                                        type: "makedate_cmbd",
                                        direction: "asc",
                                    })}
                                </div>
                                <div
                                    className="filter-option-btn cursor"
                                    onClick={() =>
                                        changeOption({
                                            type: "makedate_cmbd",
                                            direction: "desc",
                                        })
                                    }
                                >
                                    <i className="ri-arrow-down-line blue icon"></i>
                                    {handleOptionText({
                                        type: "makedate_cmbd",
                                        direction: "desc",
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="list-body">
                        {viewData &&
                            viewData.map((item) => {
                                const {
                                    unit_nm,
                                    unitid,
                                    iconnum,
                                    day_dist,
                                    makedate_cmbd,
                                    gps_sv,
                                } = item;
                                const markerType =
                                    getMarkerType(iconnum);

                                return (
                                    <li
                                        key={unitid}
                                        onClick={() =>
                                            handleListClick(
                                                item
                                            )
                                        }
                                    >
                                        <div
                                            className={`item-card ${
                                                mapState
                                                    .selectItem
                                                    ?.unitid ===
                                                unitid
                                                    ? "on"
                                                    : ""
                                            }`}
                                            data-marker-id={
                                                unitid
                                            }
                                            ref={
                                                item?.unitid ===
                                                mapState
                                                    ?.selectItem
                                                    ?.unitid
                                                    ? checkedRef
                                                    : null
                                            }
                                        >
                                            <div
                                                className={
                                                    "marker " +
                                                    markerType
                                                }
                                            >
                                                <div
                                                    className={`marker-icon`}
                                                >
                                                    <span className="icon">
                                                        <i></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="item-info">
                                                <div
                                                    className={
                                                        unit_nm.length >
                                                        13
                                                            ? "item-name-s"
                                                            : unit_nm.length >
                                                                10
                                                            ? "item-name-m"
                                                            : "item-name"
                                                    }
                                                    title={
                                                        unit_nm
                                                    }
                                                >
                                                    {unit_nm}
                                                </div>
                                                <div className="item-distance">
                                                    {day_dist.toFixed(
                                                        2
                                                    )}{" "}
                                                    km
                                                </div>
                                                <div className={getGPSState(gps_sv)}>
                                                    {ISODateToDateTime(
                                                        makedate_cmbd
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                className="item-status"
                                                data-num="999"
                                            >
                                                <span
                                                    className={getState(
                                                        iconnum.substring(
                                                            4,
                                                            5
                                                        )
                                                    )}
                                                >
                                                    {language ===
                                                    "KOR"
                                                        ? getStateKOR(
                                                                iconnum.substring(
                                                                    4,
                                                                    5
                                                                )
                                                            )
                                                        : getStateENG(
                                                                iconnum.substring(
                                                                    4,
                                                                    5
                                                                )
                                                            )}
                                                </span>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
        </>
    );
}

