import React from "react";
interface StatProps {
  speed: string;
  innerBattery: number;
  vehicleBattery: number;
  gpsSv: string;
  battery: number;
  statSize: string;
}
export default function Stat({
  speed,
  innerBattery,
  vehicleBattery,
  gpsSv,
  battery,
  statSize,
}: StatProps) {
  function getGPSState(val: number) {
    let GPSState;
    if (val > 9) {
      GPSState = "icon-gps green";
    } else if (val > 7) {
      GPSState = "icon-gps yellow";
    } else if (val > 5) {
      GPSState = "icon-gps orange";
    } else if (val > 0) {
      GPSState = "icon-gps red";
    } else {
      GPSState = "icon-gps grey";
    }
    return GPSState;
  }

  function getBattState(val: any) {
    let BattState;
    if (val > 37) {
      BattState = "battery green";
    } else if (val > 35) {
      BattState = "battery orange";
    } else if (val > 34) {
      BattState = "battery red";
    } else {
      BattState = "battery grey";
    }
    return BattState;
  }
  return (
    <div className="map-box-stat-wrap">
      <div className={`map-box-stat ${statSize}`}>
        <i className={getGPSState(parseInt(gpsSv))}></i>
        <p className={statSize}>{gpsSv}개</p>
      </div>
      <div className={`map-box-stat ${statSize}`}>
        <i className="battery car"></i>
        <p className={statSize}>{vehicleBattery}v</p>
      </div>
      <div className={`map-box-stat ${statSize}`}>
        <i className={getBattState(battery)}></i>
        <p className={statSize}>{innerBattery}v</p>
      </div>
      <div className={`map-box-stat ${statSize}`}>
        <i className="icon-speed"></i>
        <div className="speed">
          <p className={statSize}>{`${speed === "000" ? "0" : speed} km/h`}</p>
        </div>
      </div>
    </div>
  );
}
