import React, { useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { useMap } from "../../../context/MapContext";

export default function EventBtn({ eventCount }) {
    const { language } = useContext(LanguageContext);
    const { mapState, mapDispatch } = useMap();
    const { totalEventMode, totalEventCount } = mapState;
    const eventBtn = () => {
        mapDispatch({ type: "BLUR" });
        mapDispatch({
            type: "SET_TOTAL_EVENT_MODE",
            payload: !totalEventMode,
        });
    };
    return (
        <button
            type="button"
            className={`control-btn event ${totalEventMode ? "on" : ""}`}
            onClick={eventBtn}
        >
            <span className="label">
                {language === "KOR" ? "이벤트" : "Event"}
            </span>
            <span className="content blue">{totalEventCount ?? 0}</span>
        </button>
    );
}
