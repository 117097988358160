import React from "react";
import PanelCloseBtn from "../../components/map/buttons/PanelCloseBtn";
import { useMap } from "../../context/MapContext";
export default function GeofencePanel() {
  const { mapState } = useMap();
  const { selectGeoItem } = mapState;
  return (
    <div className={`right-info-panel geofence-info-panel on`}>
      <div className="detail-inner">
        <div className="detail-top">
          <div className="detail-header">
            <div className="header-title">
              <i className="ri-information-fill icon"></i>진입/이탈 상세정보
            </div>
            <div className="header-control">
              <PanelCloseBtn />
            </div>
          </div>
          <div className="detail-body">
            <div className="detail-content">
              <div className="col">
                <div className="detail-item">
                  <div className="item-label">지역명</div>
                  <div className="item-content">{selectGeoItem?.name}</div>
                </div>
                <div className="detail-item">
                  <div className="item-label">반경</div>
                  <div className="item-content">{selectGeoItem?.radius}m</div>
                </div>
                <div className="detail-item">
                  <div className="item-label">진입/이탈</div>
                  <div className="item-content">{selectGeoItem?.status}</div>
                </div>
                <div className="detail-item">
                  <div className="item-label">도로명</div>
                  <div className="item-content">{selectGeoItem?.road_addr}</div>
                </div>
                <div className="detail-item">
                  <div className="item-label">지번</div>
                  <div className="item-content">
                    {selectGeoItem?.number_addr}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
