import React from "react";
interface Props {
  name: string;
  val: string;
  title: string;
  change?: (e: any) => void;
  check?: boolean;
  type?: string;
}
export default function Radio({
  name,
  val,
  title,
  check,
  change,
  type,
}: Props) {
  return (
    <>
      <label className="custom-check">
        <input
          type={type ? type : "radio"}
          name={name}
          value={val}
          onChange={(e) => change && change(e)}
          checked={check}
        />
        <div className="label">{title}</div>
      </label>
    </>
  );
}
