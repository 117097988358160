import { RouterProvider, createBrowserRouter } from "react-router-dom";
// import "./assets/css/style.min.css";
import "./assets/scss/style.scss";
import { LoginProvider } from "./context/LoginContext";
import Intro from "./pages/intro/Intro";
import Privacy from "./pages/intro/Privacy";
import Control from "./pages/map/Control";

import Event from "./components/run/pages/Event";
import DLog from "./pages/DLog";

import DeviceLog from "./pages/DLog/DeviceLog";
import Root from "./root/root";

import GeoFence from "./components/term/pages/GeoFence";
import GeoFenceAdd from "./components/term/pages/GeoFenceAdd";
import GeoFenceList from "./components/term/pages/GeoFenceList";
import GeoFenceListDevice from "./components/term/pages/GeoFenceListDevice";
import TermBlueTooth from "./components/term/pages/TeminalBlueTooth";
import TermEdit from "./components/term/pages/TerminalEdit";
import Term from "./pages/Terminal";
import TermPage from "./pages/Terminal/Terminal";

import "./assets/scss/style.scss";
import ProtectedRoute from "./components/route/ProtectedRoute";
import Daily from "./components/run/pages/DayLog";
import Terminal from "./components/run/pages/Term";
import TermBlueToothEdit from "./components/term/pages/BlueToothEdit";
import CertifyPage from "./pages/certify/CertifyPage";
import { LanguageProvider } from "./context/LanguageContext";

import ManagerIndex from "./pages/Manager/ManagerIndex";
import Manager from "./pages/Manager/Manager";
import BranchAdd from "./components/manager/pages/BranchAdd";
import BranchEdit from "./components/manager/pages/BranchEdit";
import AgentMain from "./components/manager/pages/AgentMain";
import AgentAdd from "./components/manager/pages/AgentAdd";
import AgentEdit from "./components/manager/pages/AgentEdit";
import UserMain from "./components/manager/pages/UserMain";
import UserAdd from "./components/manager/pages/UserAdd";
import UserEdit from "./components/manager/pages/UserEdit";

import VLogIndex from "./pages/VLog/VLogIndex";
import VLog from "./pages/VLog/VLog";
import Cal from "./components/Vlog/cal/App.jsx";
import CalEdit from "./components/Vlog/cal/components/page/EditEvent.jsx";

import SetIndex from "./pages/Settings/SetIndex";
import Settings from "./pages/Settings/Settings";
import SetEdit from "./components/Settings/pages/SetEdit";
import Custom from "../src/components/Settings/pages/Custom";
import Alarm from "../src/components/Settings/pages/Alarm";
import AlarmAdd from "../src/components/Settings/pages/AlarmAdd";
import AlarmEdit from "../src/components/Settings/pages/AlarmEdit";
import Push from "../src/components/Settings/pages/Push";
import Sms from "../src/components/Settings/pages/Sms";
import Lost from "../src/pages/lost/Lost";

import DasIndex from "./pages/das/DasIndex.jsx";
import Das from "./pages/das/Dashboard.tsx";

// Admin page
import AdmIndex from "./pages/Admin/AdmIndex";
import Adm from "./pages/Admin/Adm";
import UserTable from "./components/Admin/pages/Manager/User/Table";
import UserAdmEdit from "./components/Admin/pages/Manager/User/Form";
import UserAdmAdd from "./components/Admin/pages/Manager/User/FormNew";
import VLogDetail from "./components/Vlog/pages/VLogDetail";
import OrgTable from "./components/Admin/pages/Manager/Org/Table";
import OrgAdminEdit from "./components/Admin/pages/Manager/Org/Form";
import OrgAdminNew from "./components/Admin/pages/Manager/Org/FormNew";
import DepTable from "./components/Admin/pages/Manager/Depart/Table";
import DepAdminEdit from "./components/Admin/pages/Manager/Depart/Form";
import DepAdminNew from "./components/Admin/pages/Manager/Depart/Form";
import DevAdminEdit from "./components/Admin/pages/Gadget/Device/Form";
import DevAdminNew from "./components/Admin/pages/Gadget/Device/FormNew";
import DevControl from "./components/Admin/pages/Gadget/Device/Control";

import DeviceTable from "./components/Admin/pages/Gadget/Device/Table";

import SystemTable from "./components/Admin/pages/System/Table";
import SysForm from "./components/Admin/pages/System/Form";
import DefTable from "./components/Admin/pages/Defect/Table";
import DefForm from "./components/Admin/pages/Defect/Form";
import MenuTable from "./components/Admin/pages/Menu/Table";
import MenuForm from "./components/Admin/pages/Menu/Form";
import MenuBasic from "./components/Admin/pages/Menu/Basic";
import MenuPerm from "./components/Admin/pages/Menu/Perm";
import MenuManage from "./components/Admin/pages/Menu/Manage";
import LogTable from "./components/Admin/pages/Log/Table";
import BasicTable from "./components/Admin/pages/Basic/Table";
import BasicNew from "./components/Admin/pages/Basic/FormNew";
import BasicForm from "./components/Admin/pages/Basic/Form";
import BasicCode from "./components/Admin/pages/Basic/FormCode";
import ItemTable from "./components/Admin/pages/Gadget/Item/Table";
import ItemForm from "./components/Admin/pages/Gadget/Item/Form";

import Table from "./components/Vlog/pages/Table";
import Sso from "./pages/Sso/Sso.jsx";
import OrderIndex from "./pages/order/OrderIndex.jsx";
import Order from "./pages/order/Order.jsx";
import OrderInfo from "./pages/order/OrderInfo.jsx";
import Demo from "./pages/demo/Demo.jsx";
import FAQ from "./pages/Faq/index.jsx";
import Wire from "./pages/Faq/Wire.jsx";
import Wireless from "./pages/Faq/Wireless.jsx";

// test pages

import ScreenIndex from "./pages/MultiScreen/ScreenIndex";
import ScreenMain from "./pages/MultiScreen/ScreenMain";
import ErrorMain from "./pages/err/ErrorMain";

function App() {
  const router = createBrowserRouter([
    {
      path: "/error",
      element: <ErrorMain />,
      label: "Error",
    },
    {
      path: "/Certify",
      element: <CertifyPage />,
      label: "Certify",
    },
    {
      path: "/SSO",
      element: <Sso />,
      label: "SSO",
    },
    {
      path: "/demo",
      element: <Demo />,
      label: "DEMO",
    },
    {
      path: "/FAQ",
      element:<FAQ />,
      children: [
        {
          index: true,
          element: <Wire />,
          label: "Wire device FAQ"
        },
        {
          path: "wireless",
          element: <Wireless />,
          label: "Wireless device FAQ"
        }
      ]
    },
    {
      path: "/",
      element: <Root />,
      children: [
        {
          // path: "/",
          index: true,
          element: <Intro />,
          label: "Intro Page",
        },
        {
          path: "/Map",
          element: (
            <ProtectedRoute>
              <Control />
            </ProtectedRoute>
          ),
          label: "Map Page",
        },
        {
          path: "/Dlog",
          element: (
            <ProtectedRoute>
              <DLog />
            </ProtectedRoute>
          ),
          label: "Device Log Page",
          children: [
            { index: true, element: <DeviceLog /> },
            { path: "Term", element: <Terminal /> },
            { path: "Daily", element: <Daily /> },
          ],
        },
        {
          path: "/Vlog",
          element: (
            <ProtectedRoute>
              <VLogIndex />
            </ProtectedRoute>
          ),
          label: "Device Log Page",
          children: [
            { index: true, element: <Table /> },
            { path: "Detail", element: <VLogDetail /> },
            { path: "Cal", element: <Cal /> },
            { path: "Cal/write", element: <Cal /> },
            { path: "Cal/edit", element: <CalEdit /> },
          ],
        },
        {
          path: "/Das",
          element: (
            <ProtectedRoute>
              <DasIndex />
            </ProtectedRoute>
          ),
          label: "Dashboard Page",
          children: [{ index: true, element: <Das /> }],
        },
        {
          path: "/Set",
          element: (
            <ProtectedRoute>
              <SetIndex />
            </ProtectedRoute>
          ),
          label: "Settings Page",
          children: [
            { index: true, element: <Settings /> },
            { path: "Edit", element: <SetEdit /> },
            { path: "Alarm", element: <Alarm /> },
            { path: "Alarm/Add", element: <AlarmAdd /> },
            { path: "Alarm/Edit", element: <AlarmEdit /> },
            { path: "Push", element: <Push /> },
            { path: "Sms", element: <Sms /> },
            { path: "Custom", element: <Custom /> },
          ],
        },
        {
          path: "/Trm",
          element: (
            <ProtectedRoute>
              <Term />
            </ProtectedRoute>
          ),
          label: "Device Log Page",
          children: [
            { index: true, element: <TermPage /> },
            { path: "Edit", element: <TermEdit /> },
            { path: "BlueTooth", element: <TermBlueTooth /> },
            { path: "BlueTooth/Detail", element: <TermBlueToothEdit /> },
            { path: "GeoFence", element: <GeoFence /> },
            { path: "GeoFence/List", element: <GeoFenceList /> },
            { path: "GeoFence/ListDevice", element: <GeoFenceListDevice /> },
            { path: "GeoFence/Add", element: <GeoFenceAdd /> },
          ],
        },
        {
          path: "DLog/Event",
          element: (
            <ProtectedRoute>
              <Event />
            </ProtectedRoute>
          ),
          label: "Event List Page",
        },
        {
          path: "/Lost",
          element: (
            <ProtectedRoute>
              <Lost />
            </ProtectedRoute>
          ),
          label: "Lost page",
        },

        {
          path: "/Mng",
          element: (
            <ProtectedRoute>
              <ManagerIndex />
            </ProtectedRoute>
          ),
          label: "Manager Page",
          children: [
            { index: true, element: <Manager /> },

            {
              path: "Add",
              element: <BranchAdd />,
            },
            {
              path: "Edit",
              element: <BranchEdit />,
            },
            {
              path: "Agent",
              element: <AgentMain />,
            },
            {
              path: "Agent/Add",
              element: <AgentAdd />,
            },
            {
              path: "Agent/Edit",
              element: <AgentEdit />,
            },
            {
              path: "/Mng/User",
              element: <UserMain />,
            },
            {
              path: "User/Add",
              element: <UserAdd />,
            },
            {
              path: "User/Edit",
              element: <UserEdit />,
            },

            // { path: "Event", element: <Event /> },
          ],
        },
        {
          path: "/Adm",
          element: (
            <ProtectedRoute>
              <AdmIndex />
            </ProtectedRoute>
          ),
          label: "Admin Page",
          children: [
            { index: true, element: <Adm /> },
            { path: "u-table", element: <UserTable /> },
            { path: "u-table/edit", element: <UserAdmEdit /> },
            { path: "u-table/new", element: <UserAdmAdd /> },
            { path: "o-table", element: <OrgTable /> },
            { path: "o-table/edit", element: <OrgAdminEdit /> },
            { path: "o-table/new", element: <OrgAdminNew /> },
            { path: "d-table", element: <DepTable /> },
            { path: "d-table/edit", element: <DepAdminEdit /> },
            { path: "d-table/new", element: <DepAdminNew /> },
            { path: "dev-table", element: <DeviceTable /> },
            { path: "dev-table/edit", element: <DevAdminEdit /> },
            { path: "dev-table/new", element: <DevAdminNew /> },
            { path: "dev-table/control", element: <DevControl /> },
            { path: "item-table", element: <ItemTable /> },
            { path: "item-table/new", element: <ItemForm /> },
            { path: "item-table/edit", element: <ItemForm /> },
            { path: "sys-table", element: <SystemTable /> },
            { path: "sys-table/edit", element: <SysForm /> },
            { path: "sys-table/new", element: <SysForm /> },
            { path: "def-table", element: <DefTable /> },
            { path: "def-table/edit", element: <DefForm /> },
            { path: "def-table/new", element: <DefForm /> },
            { path: "menu-table", element: <MenuTable /> },
            { path: "menu-table/new", element: <MenuForm /> },
            { path: "menu-table/basic", element: <MenuBasic /> },
            { path: "menu-table/perm", element: <MenuPerm /> },
            { path: "menu-table/manage", element: <MenuManage /> },
            { path: "log-table", element: <LogTable /> },
            { path: "bas-table", element: <BasicTable /> },
            { path: "bas-table/new", element: <BasicNew /> },
            { path: "bas-table/edit", element: <BasicForm /> },
            { path: "bas-table/edit/code", element: <BasicCode /> },
          ],
        },
        {
          path: "/Privacy",
          element: <Privacy />,
          label: "Privacy",
        },
        {
          path: "/Order",
          element: (
            <ProtectedRoute>
              <OrderIndex />
            </ProtectedRoute>
          ),
          label: "Order Manage Page",
          children: [
            { index: true, element: <Order /> },
            { path: "Detail", element: <OrderInfo /> },
          ],
        },
        {
          path: "/Screen",
          element: (
            <ProtectedRoute>
              <ScreenIndex />
            </ProtectedRoute>
          ),
          label: "Screen Manage Page",
          children: [{ index: true, element: <ScreenMain /> }],
        },
      ],
    },
  ]);
  return (
    <>
      <LanguageProvider>
        <LoginProvider>
          <RouterProvider router={router} />
        </LoginProvider>
      </LanguageProvider>
    </>
  );
}

export default App;
