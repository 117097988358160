import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "../../../lib/axios.js";
import { ISODateToDateTime, add_hyphen, trimType } from "../../../lib/common_func";

import { useNavigate } from "react-router-dom";
import CommonControl from "../../Atomic/organism/CommonControl";

import Form from "../../Atomic/organism/FormAtom";
import Grid from "@toast-ui/react-grid";
import TuiGrid from "tui-grid";
import tableStyle from "../../run/organism/RunStyledTable.jsx";
import VLogMain from "./VLogMain.jsx";
import { useLang } from "../../../context/LanguageContext.jsx";
import { useAuth } from "../../../context/LoginContext.jsx";

export default function Table() {
    const { language } = useLang();
    const { user } = useAuth();
    const navi = useNavigate();
    const inputRef = React.createRef();
    const gridRef = useRef();
    const header = {
        //tui grid
        height: 50,
    };
    const [columns, setColumns] = useState([
        {
        header: language === "KOR" ? "소속 조직" : "Org name",
        name: "org_nm",
        align: "center",
        sortable: true,
        },
        {
        header: language === "KOR" ? "단말 번호" : "USIM num",
        name: "unitid",
        align: "center",
        sortable: true,
        formatter: (item) => add_hyphen(item.value, language),
        },
        {
        header: language === "KOR" ? "단말기명" : "Device name",
        name: "unit_nm",
        align: "center",
        sortable: true,
        },
        {
        header: language === "KOR" ? "일일 주행거리(km)" : "Today(km)",
        name: "day_dist",
        align: "right",
        sortable: true,
        formatter: (item) => item.value.toFixed(2),
        },
        {
        header: language === "KOR" ? "누적 주행거리(km)" : "Total(km)",
        name: "total_dist",
        align: "right",
        sortable: true,
        formatter: (item) => item.value.toFixed(2),
        },
        {
        header: language === "KOR" ? "최종 기록일시" : "Last received",
        name: "last_ed_time",
        align: "center",
        sortable: true,
        formatter: (item) =>
            item.value === null ? "" : ISODateToDateTime(item.value),
        },
        {
        header: language === "KOR" ? "상세" : "Detail",
        name: "btn1",
        align: "center",
        width: 100,
        formatter: () =>
            `<div class='link cursor'>${
            language === "KOR" ? "조회" : "look"
            }</div>`,
        },    
    ]);  

    // Hook
    TuiGrid.applyTheme(null, tableStyle);

    // State definition
    //==================================================================
    const [rawData, setRawData] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [keyword, setKeyword] = React.useState("");
    const bodyHeight = data.length < 10 ? data.length * 50 : 520;
    // Function definition
    //==================================================================
    async function submitFun(e) {
        e.preventDefault();
        await getData();
    }
    async function getData() {
        await axios.post(`/web/driving/list`, {type:user.type,org_idx:user.org_idx,grade:user.grade,num:user.idx}).then((res) => {
        setRawData(res.data);
        });
    }
    function clearInput() {
        if (inputRef.current) {
        inputRef.current.value = "";
        }
        setKeyword("");
    }
    function inputChnage(key) {
        setKeyword(key);
        filterData(rawData, key);
    }

    const filterData = useCallback(
        (data, key) => {
        const filterCallback = () => {
            const result = data.filter((item) => {
            // Check if any key in the object includes the search key
            return Object.values(item).some(
                (value) => typeof value === "string" && value.includes(key)
            );
            });
            setData(result);
        };

        filterCallback();
        },
        [setData]
    );

    const move = (e) => {
        const i = e.instance.store.focus.rowIndex;
        const unitid = trimType(e.instance.store.data.rawData[i].unitid);
        const unit_nm = e.instance.store.data.rawData[i].unit_nm;
        if (e.targetType != "columnHeader") {
            switch (e.columnName) {
            case "btn1":
                // console.log(unitid);
                navi(`/Vlog/Detail`, { state: { unitid, unit_nm } });
                break;
            default:
                break;
            }
        }
    };

    // UseEffect definition
    //==================================================================
    useEffect(() => {
        axios.post(`/web/driving/list`, {type:user.type,org_idx:user.org_idx,grade:user.grade,num:user.idx}).then((res) => {
        setRawData(res.data);
        });
    }, [user]);

    useEffect(() => {
        if (rawData && keyword === "") {
        setData(rawData);
        } else if (rawData && keyword !== "") {
        filterData(rawData, keyword);
        }
    }, [rawData, keyword, filterData]);

    useEffect(() => {
        setColumns([
            {
            header: language === "KOR" ? "소속 조직" : "Org name",
            name: "org_nm",
            align: "center",
            sortable: true,
            },
            {
            header: language === "KOR" ? "단말 번호" : "USIM num",
            name: "unitid",
            align: "center",
            sortable: true,
            formatter: (item) => add_hyphen(item.value, language),
            },
            {
            header: language === "KOR" ? "단말기명" : "Device name",
            name: "unit_nm",
            align: "center",
            sortable: true,
            },
            {
            header: language === "KOR" ? "일일 주행거리(km)" : "Today(km)",
            name: "day_dist",
            align: "right",
            sortable: true,
            formatter: (item) => item.value.toFixed(2),
            },
            {
            header: language === "KOR" ? "누적 주행거리(km)" : "Total(km)",
            name: "total_dist",
            align: "right",
            sortable: true,
            formatter: (item) => item.value.toFixed(2),
            },
            {
            header: language === "KOR" ? "최종 기록일시" : "Last received",
            name: "last_ed_time",
            align: "center",
            sortable: true,
            formatter: (item) =>
                item.value === null ? "" : ISODateToDateTime(item.value),
            },
            {
            header: language === "KOR" ? "상세" : "Detail",
            name: "btn1",
            align: "center",
            width: 100,
            formatter: () =>
                `<div class='link cursor'>${
                language === "KOR" ? "조회" : "look"
                }</div>`,
            },    
        ])
    }, [language])

    // Data definition
    //==================================================================
    const subDataList = [
        {
        path: "/VLog/Log/Auto",
        labelKR: "자동 운행상세",
        labelEN: "Auto Driving Log",
        },
        {
        path: "/VLog/Log/Passive",
        labelKR: "수동 운행상세",
        labelEN: "Passive Driving Log",
        },
    ];
    const formData = {
        lan: language,
        inputId: "keyword",
        type: "text",
        placeKR: "검색어를 입력하세요",
        placeEN: "Please enter a search term",
        submitHandler: submitFun,
        filter: (e) => inputChnage(e),
        myref: inputRef,
        value: keyword,
    };
    const buttonData = [
        {
        labelKR: "전체목록",
        labelEN: "View all",
        classN: "purple",
        clickFun: () => {
            clearInput();
        },
        position: "left",
        icon: false,
        iconClass: "",
        }
    ];

    return (
        <>
            <VLogMain>
                <Form formData={formData} inputRef={inputRef} />
                {/* <InputFile />
                <InputFile /> */}
                <div className="common-table-wrapper">
                    <div className="common-grid">
                    <Grid
                        ref={gridRef}
                        data={data}
                        columns={columns}
                        rowHeight={50}
                        rowHeaders={[{ type: "rowNum" }]}
                        scrollX={false}
                        scrollY={true}
                        bodyHeight={bodyHeight}
                        header={header}
                        onClick={move}
                    />
                    </div>
                </div>
                <CommonControl buttonData={buttonData} language={language} />
            </VLogMain>
        </>
    );
}