import Button from "../atom/Button";
import PropTypes from "prop-types";
export default function ButtonWrap({ btnArray }) {
  const style = {
    marginTop: "20px",
    justifyContent: "end",
  };
  return (
    <>
      <div style={style} className="flex">
        {btnArray.map((btn, idx) => {
          return (
            <Button
              key={idx}
              buttonName={btn.buttonName}
              colorBTN={btn.colorBTN}
              clickFun={btn.clickFun}
            />
          );
        })}
      </div>
    </>
  );
}
ButtonWrap.propTypes = {
  btnArray: PropTypes.array.isRequired,
};
