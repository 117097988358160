import React, { useEffect, useState } from "react";
//@ts-ignore
import { getMarkerType } from "../../../lib/mapHelper";

interface MarkerProps {
  iconnum: number;
  direction: any;
  unit_nm: string;
}
export default function MarkerAtom({
  iconnum,
  direction,
  unit_nm,
}: MarkerProps) {
  const [state, setState] = useState<any>("");
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const styleTitle = {
    bottom: `${isSafari ? "-70px" : "-50px"}`,
    left: `${isSafari ? "100%" : "50%"}`,
  };
  const styleMarker = {
    transform: `rotate(${180 + parseInt(direction)}deg)`,
  };
  const styleIcon = {
    transform: `rotate(${180 - parseInt(direction)}deg)`,
  };

  useEffect(() => {
    if (unit_nm.length > 6) {
      setState("marker-name-s  w-ellipsis");
    } else {
      setState("marker-name");
    }
  }, [unit_nm]);
  return (
    <>
      <div className={`marker ${getMarkerType(iconnum)}`}>
        <div className="marker-icon" style={styleMarker}>
          <span className="icon" style={styleIcon}>
            <i></i>
          </span>
        </div>
        <div title={unit_nm} className={state} style={styleTitle}>
          {unit_nm}
        </div>
      </div>
    </>
  );
}
