import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../assets/css/join.css";
import PostCode1 from "../etc/PostCodeJoin1";
import PostCode2 from "../etc/PostCodeJoin2";

export default function Certify({ data }) {
  // 오늘 날짜 관련 변수
  const today = new Date();
  let localToday = today.toLocaleDateString();
  localToday = localToday.split(".");
  const localYear = localToday[0];
  const localMonth = localToday[1].trim();
  const localDay = localToday[2].trim();

  const [isAgree, setIsAgree] = useState(false);
  const [user, setUser] = useState({
    name: "",
    hp: "",
    birth: "",
    addr: "",
    postCode: "",
    addr_dt: "",
    same: "Y",
    rv_name: "",
    rv_hp1: "",
    rv_hp2: "",
    rv_hp3: "",
    rv_addr: "",
    rv_postCode: "",
    rv_addr_dt: "",
    fromYear: localYear,
    fromMonth: localMonth,
    fromDay: localDay,
    toYear: null,
    toMonth: null,
    toDay: null,
    prod_type: null,
  });

  const [yearArr, setYearArr] = useState(null);
  const [monthArr, setMonthArr] = useState(null);
  const [dayArr, setDayArr] = useState(null);

  // user state set
  const handleUser = (e) => {
    setUser((prev) => {
      if (user.same === "Y" && e.target.name === "addr_dt") {
        return {
          ...prev,
          [e.target.name]: e.target.value,
          rv_addr_dt: e.target.value,
        };
      }
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  // phone value check
  const handlePhone = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    } else {
      setUser((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };
  const handleAgree = (e) => {
    setIsAgree((prev) => !prev);
  };
  // value check
  const valueCheck = () => {
    const checkKey = [
      "addr",
      "addr_dt",
      "postCode",
      "rv_addr",
      "rv_addr_dt",
      "rv_postCode",
      "same",
      "rv_name",
      "rv_hp1",
      "rv_hp2",
      "rv_hp3",
      "fromYear",
      "fromMonth",
      "fromDay",
      "toYear",
      "toMonth",
      "toDay",
    ];
    let result = true;
    // 1. 빈 값 체크
    for (let key of checkKey) {
      if (user[key] === "" || user[key] === undefined || user[key] === null) {
        if (
          user.same === "Y" &&
          (key === "rv_name" ||
            key === "rv_hp1" ||
            key === "rv_hp2" ||
            key === "rv_hp3")
        ) {
          result = true;
        } else {
          result = false;
          break;
        }
      }
    }
    return result;
  };
  // submit handler
  const submitHandler = async (e) => {
    e.preventDefault();

    const name = document.querySelector("#name").value;
    const birth =
      document.querySelector("#selectYear").value +
      "-" +
      document.querySelector("#selectMonth").value +
      "-" +
      document.querySelector("#selectDay").value;
    const hp =
      document.querySelector("#ph1").value +
      "-" +
      document.querySelector("#ph2").value +
      "-" +
      document.querySelector("#ph3").value;
    if (!isAgree) {
      alert("확인사항에 동의해주세요");
      return;
    }
    if (name === "" || birth === "" || hp === "") {
      alert("인증을 하여 정보를 입력해주세요");
      return;
    }
    if (user.addr === "" || user.postCode === "") {
      alert("주소를 검색해주세요");
      document.querySelector("#addSearch").click();
      return false;
    } else if (
      user.same === "N" &&
      (user.rv_addr === "" || user.rv_postCode === "")
    ) {
      alert("택배 받을 주소를 검색해주세요");
      document.querySelector("#addSearch2").click();
      return false;
    }
    if (!valueCheck()) {
      alert("정보를 모두 입력해주세요");
      return false;
    } else {
      if (user.same === "Y") {
        const res = await axios.post("/api/order/edit", {
          ...user,
          rv_addr: user.addr,
          rv_postCode: user.postCode,
          rv_addr_dt: user.addr_dt,
          rv_hp: hp,
          rv_name: name,
          name: name,
          birth: birth,
          hp: hp,
          idx: data.data.idx,
        });
        if (res.status === 200) {
          alert("인증되었습니다");
          window.location.href = "https://4guard.co.kr/";
        } else {
          alert("등록되지 않았습니다.");
        }
      } else if (user.same === "N") {
        const res = await axios.post("/api/order/edit", {
          ...user,
          name: name,
          birth: birth,
          hp: hp,
          rv_hp: user.rv_hp1 + "-" + user.rv_hp2 + "-" + user.rv_hp3,
          idx: data.data.idx,
        });
        if (res.status === 200) {
          window.location.href = "https://4guard.co.kr/";
        } else {
          alert("등록되지 않았습니다.");
        }
      }
    }
  };
  // 본인 인증 요청
  const mok_popup = () => {
    window.MOBILEOK.process(
      "https://iotlbs.com/mok/mok_std_request",
      "MB",
      "result"
    );
  };
  // 주소찾기 버튼 클릭
  const searchAddr = (e) => {
    if (e.target.value === "") {
      document.querySelector("#addSearch").click();
    }
  };
  const searchAddr2 = (e) => {
    if (e.target.value === "") {
      document.querySelector("#addSearch2").click();
    }
  };
  // 본인인증 버튼 클릭
  const certify = (e) => {
    if (e.target.value === "") {
      mok_popup();
    }
  };

  // 본인인증 결과 리턴받기
  useEffect(() => {
    const script = document.createElement("script");
    const callBackFunc = document.createTextNode(
      "function result(result) {" +
        "try {" +
        "result = JSON.parse(result);" +
        "console.log(result);" +
        "if(result.errorCode === '2000'){" +
        "document.querySelector('#name').value = result.data.userName;" +
        "document.querySelector('#selectYear').value = result.data.userBirthday.substr(0,4);" +
        "document.querySelector('#selectMonth').value = result.data.userBirthday.substr(4,2);" +
        "document.querySelector('#selectDay').value = result.data.userBirthday.substr(6,2);" +
        "document.querySelector('#ph1').value = result.data.userPhone.substr(0,3);" +
        "if(result.data.userPhone.length === 11) {" +
        "document.querySelector('#ph2').value = result.data.userPhone.substr(3,4);" +
        "document.querySelector('#ph3').value = result.data.userPhone.substr(7,4);" +
        "} else {" +
        "document.querySelector('#ph2').value = result.data.userPhone.substr(3,3);" +
        "document.querySelector('#ph3').value = result.data.userPhone.substr(6,4);" +
        "}" +
        "}" +
        "} catch (error) {" +
        "console.log(result);" +
        "}" +
        "}"
    );
    script.appendChild(callBackFunc);
    document.body.appendChild(script);
  }, []);
  // 수령정보 element 표기 및 state 변경
  useEffect(() => {
    const newAddBox = document.querySelector("#newAdrBox");
    const address2 = document.querySelector("#address2");
    const room2 = document.querySelector("#room2");
    const nameADR2 = document.querySelector("#nameR");
    const postCode2 = document.querySelector("#postCode2");
    const phMain1R = document.querySelector("#ph1R2");
    const phMain2R = document.querySelector("#ph2R2");
    const phMain3R = document.querySelector("#ph3R2");
    if (user.same === "Y") {
      // element 비활성화
      address2.disabled = true;
      postCode2.disabled = true;
      room2.disabled = true;
      nameADR2.disabled = true;
      phMain1R.disabled = true;
      phMain2R.disabled = true;
      phMain3R.disabled = true;
      // state 수정
      setUser((prev) => {
        return {
          ...prev,
          rv_name: user.name,
          rv_addr: user.addr,
          rv_postCode: user.postCode,
          rv_addr_dt: user.addr_dt,
        };
      });
      // element 숨김
      newAddBox.classList.add("c-hidden");
    } else if (user.same === "N") {
      // element 활성화
      address2.disabled = false;
      postCode2.disabled = false;
      room2.disabled = false;
      nameADR2.disabled = false;
      phMain1R.disabled = false;
      phMain2R.disabled = false;
      phMain3R.disabled = false;
      // element value 초기화 및 필수 속성 추가
      address2.value = "";
      address2.setAttribute("required", true);
      postCode2.value = "";
      postCode2.setAttribute("required", true);
      room2.value = "";
      room2.setAttribute("required", true);
      nameADR2.value = "";
      nameADR2.setAttribute("required", true);
      phMain1R.value = "";
      phMain1R.setAttribute("required", true);
      phMain2R.value = "";
      phMain2R.setAttribute("required", true);
      phMain3R.value = "";
      phMain3R.setAttribute("required", true);
      // element 노출
      newAddBox.classList.remove("c-hidden");
      // state 수정
      setUser((prev) => {
        return {
          ...prev,
          rv_name: "",
          rv_hp1: "",
          rv_hp2: "",
          rv_hp3: "",
          rv_addr: "",
          rv_postCode: "",
          rv_addr_dt: "",
        };
      });
    }
  }, [user.same]);
  // 혜택 적용기간 가능년도, 월 목록
  useEffect(() => {
    let tempYearArr = [];
    let tempMonthArr = [];

    for (let i = parseInt(localYear) - 1; i < parseInt(localYear) + 2; i++) {
      tempYearArr.push(i);
    }
    for (let i = 1; i < 13; i++) {
      tempMonthArr.push(i);
    }
    setYearArr(tempYearArr);
    setMonthArr(tempMonthArr);
  }, []);
  // 해당하는 혜택기간 년,월의 일 수 구하기
  useEffect(() => {
    let tempDayArr = [];
    if (user.fromYear && user.fromMonth) {
      // 해당 년 ,월 마지막 일자 구하기
      const lastDay =
        32 - new Date(user.fromYear, user.fromMonth - 1, 32).getDate();
      for (let i = 1; i < parseInt(lastDay) + 1; i++) {
        tempDayArr.push(i);
      }
    }
    setDayArr(tempDayArr);
  }, [user.fromYear, user.fromMonth]);
  // 만료일 구하기
  useEffect(() => {
    if (user.fromYear && user.fromMonth && user.fromDay) {
      let date = new Date(
        user.fromYear,
        parseInt(user.fromMonth) - 1,
        user.fromDay
      );
      if (user.prod_type === 1) {
        date.setDate(date.getDate() + 183);
      } else if (user.prod_type === 2) {
        date.setFullYear(date.getFullYear() + 1);
        date.setDate(date.getDate() - 1);
      } else {
        return;
      }
      date = date.toLocaleDateString();
      date = date.split(".");
      setUser((prev) => {
        return {
          ...prev,
          toYear: date[0],
          toMonth: date[1].trim(),
          toDay: date[2].trim(),
        };
      });
    }
  }, [user.fromYear, user.fromMonth, user.fromDay, user.prod_type]);
  // 해당 코드에 맞는 제품 타입 set
  useEffect(() => {
    if (data !== undefined) {
      setUser((prev) => {
        return { ...prev, prod_type: data.data.prod_type };
      });
    }
  }, [data]);
  
  return (
    <>
      <div className="c-body">
        <header className="c-header">
          <h1 className="c-h-title">포가드울트라S 무선형 GPS 주문서</h1>
          <p className="c-h-subtitle">
            국내 유일 6無(무약정,무가입,무서류,무개통,무요금,무위약금) 실시간
            초정밀 무선형 위치추적기 포가드울트라S
          </p>
        </header>
        <main className="c-main">
          <div className="c-form-box">
            <form
              action="#"
              id="devForm"
              className="c-form"
              method="POST"
              onSubmit={submitHandler}
            >
              <div className="c-inputs">
                <div className="c-input-group">
                  <label htmlFor="name">구매자 성함</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="구매자 성함"
                    onChange={handleUser}
                    onClick={certify}
                    required
                    readOnly
                  />
                  <div className="c-error-message">
                    <p className="c-hidden c-error-text" id="nameError">
                      구매자 성함
                    </p>
                    <p className="c-hidden c-no-error" id="nameSuccess">
                      성공
                    </p>
                  </div>
                </div>
                <div className="c-input-group">
                  <label htmlFor="year">생년월일</label>
                  <div className="c-inputs-box-3">
                    <input
                      name="year"
                      id="selectYear"
                      placeholder="년"
                      required
                      readOnly
                      onClick={certify}
                    />
                    <input
                      name="month"
                      id="selectMonth"
                      placeholder="월"
                      required
                      readOnly
                      onClick={certify}
                    />
                    <input
                      name="year"
                      id="selectDay"
                      placeholder="일"
                      required
                      readOnly
                      onClick={certify}
                    />
                  </div>
                  <div className="c-error-message">
                    <p className="c-hidden c-error-text" id="birthError">
                      생년월일
                    </p>
                    <p className="c-hidden c-no-error" id="yearSuccess">
                      성공
                    </p>
                  </div>
                </div>
                <div className="c-input-group">
                  <label htmlFor="ph1">연락처</label>
                  <div className="c-inputs-box-3">
                    <input
                      className="c-phone-2"
                      type="number"
                      id="ph1"
                      name="ph1"
                      placeholder="010"
                      required
                      readOnly
                      onClick={certify}
                    />
                    <input
                      className="c-phone-3"
                      type="number"
                      id="ph2"
                      name="ph2"
                      placeholder="0000"
                      required
                      readOnly
                      onClick={certify}
                    />
                    <input
                      className="c-phone-3"
                      type="number"
                      id="ph3"
                      name="ph3"
                      placeholder="0000"
                      required
                      readOnly
                      onClick={certify}
                    />
                  </div>
                  <div className="c-error-message">
                    <p className="c-hidden error-text" id="phoneError">
                      연락처
                    </p>
                    <p className="c-hidden no-error" id="phoneSuccess">
                      성공
                    </p>
                  </div>
                  <button className="c-mt-2" type="button" onClick={mok_popup}>
                    인증하기
                  </button>
                </div>
                <div className="c-input-group">
                  <div className="c-input-group">
                    <label htmlFor="c-address">주소</label>
                    <div className="c-inputs-box">
                      <input
                        type="text"
                        id="address"
                        name="address"
                        placeholder="주소"
                        required
                        onClick={searchAddr}
                        readOnly
                      />
                      <input
                        type="text"
                        id="postCode"
                        name="postCode"
                        placeholder="우편번호"
                        required
                        onClick={searchAddr}
                        readOnly
                      />
                    </div>
                    <div className="c-inputs-box">
                      <input
                        type="text"
                        id="room"
                        name="addr_dt"
                        placeholder="상세주소"
                        onChange={handleUser}
                        required
                      />
                      <PostCode1
                        type={"button"}
                        id={"addSearch"}
                        handleUser={handleUser}
                        same={user.same}
                      />
                    </div>
                  </div>
                  <div className="c-error-message">
                    <p className="c-hidden c-error-text" id="addressError">
                      주소
                    </p>
                    <p className="c-hidden c-no-error" id="addressSuccess">
                      성공
                    </p>
                  </div>
                </div>
                <div className="c-input-group">
                  <label htmlFor="">택배받을 주소</label>
                  <div className="c-radio-box">
                    <div className="c-radio-group">
                      <input
                        type="radio"
                        id="sameAdr"
                        name="same"
                        value="Y"
                        onChange={handleUser}
                        checked={user.same === "Y" ? true : false}
                      />
                      <label htmlFor="sameAdr">위 주소와 동일</label>
                    </div>
                    <div className="c-radio-group">
                      <input
                        type="radio"
                        id="newAdr"
                        name="same"
                        value="N"
                        onChange={handleUser}
                        checked={user.same === "N" ? true : false}
                      />
                      <label htmlFor="newAdr">직접 입력</label>
                    </div>
                  </div>
                </div>
                <div className="c-input-group c-hidden" id="newAdrBox">
                  <div className="c-input-group">
                    <label htmlFor="nameR">수령인 성함</label>
                    <input
                      type="text"
                      id="nameR"
                      name="rv_name"
                      onChange={handleUser}
                      placeholder="택배받을 성함"
                    />
                  </div>
                  <div className="c-error-message">
                    <p className="c-hidden c-error-text" id="nameErrorR">
                      구매자 성함
                    </p>
                    <p className="c-hidden c-no-error" id="nameSuccessR">
                      성공
                    </p>
                  </div>
                  <label htmlFor="ph2R2">수령인 연락처</label>
                  <div className="c-inputs-box-3">
                    <input
                      className="c-phone-2r"
                      type="number"
                      id="ph1R2"
                      name="rv_hp1"
                      placeholder="010"
                      onInput={handlePhone}
                      maxLength={3}
                    />
                    <input
                      className="c-phone-3r"
                      type="number"
                      id="ph2R2"
                      name="rv_hp2"
                      placeholder="0000"
                      onInput={handlePhone}
                      maxLength={4}
                    />
                    <input
                      className="c-phone-3r"
                      type="number"
                      id="ph3R2"
                      name="rv_hp3"
                      placeholder="0000"
                      onInput={handlePhone}
                      maxLength={4}
                    />
                  </div>
                  <div className="c-error-message">
                    <p className="c-hidden c-error-text" id="phoneReciveError">
                      주소
                    </p>
                    <p className="c-hidden c-no-error" id="phoneReciveSuccess">
                      성공
                    </p>
                  </div>
                  <label htmlFor="address">배송받을 주소</label>
                  <div className="c-inputs-box">
                    <input
                      type="text"
                      id="address2"
                      name="rv_addr"
                      placeholder="배송받을 주소"
                      onClick={searchAddr2}
                      readOnly
                    />
                    <input
                      type="text"
                      id="postCode2"
                      name="rv_postCode"
                      placeholder="배송받을 우편번호"
                      onClick={searchAddr2}
                      readOnly
                    />
                  </div>
                  <div className="c-inputs-box">
                    <input
                      type="text"
                      id="room2"
                      name="rv_addr_dt"
                      placeholder="배송받을 상세주소"
                      onChange={handleUser}
                    />
                    <PostCode2
                      type="button"
                      id="addSearch2"
                      handleUser={handleUser}
                    />
                  </div>
                  <div className="c-error-message">
                    <p
                      className="c-hidden c-error-text"
                      id="addressReciveError"
                    >
                      주소
                    </p>
                    <p
                      className="c-hidden c-no-error"
                      id="addressReciveSuccess"
                    >
                      성공
                    </p>
                  </div>
                </div>
              </div>
              <div className="c-terms c-flex-col c-gap-5 c-mb-5">
                <div className="c-input-group">
                  <label htmlFor="c-addressDel">제품 선택</label>
                  <div className="c-radio-box">
                    {user.prod_type === 1
                      ? "무선형 6無혜택 6개월"
                      : "무선형 6無혜택 12개월"}
                  </div>
                </div>
                <div className="c-form-text">
                  <h3 className="c-mb-2">확인사항</h3>
                  <p style={{wordBreak:'break-word'}}>
                    {/* 본 상품의 6無혜택기간은 적용기간이 명시된 제품으로 제품 출고
                    익일부터 선택한 개월 수 (6개월 or 12개월)까지 6無혜택
                    적용기간으로 간주한다. 6無혜택 기간(선택한 개월 수)이 지나면
                    해지 또는 연장사용이 가능하다. 연장사용 시 구매자 명의로
                    개통이 되며, 16,500원 부가세포함가로 월 통신비가
                    발생된다.상품을 구매한 이후부터 단말기는 구매자 소유이며,
                    법에 위반되는 용도로 사용 시 모든 책임은 구매자 또는
                    사용자에게 있습니다. 무상 A/S기간은 3년이며, 구매처로 문의
                    주시면 됩니다. 침수, 파손, 분실 등 구매자 과실로 인한
                    A/S접수는 무상 적용되지 않습니다. */}
                    • 본 상품은 적용 기간이 명시된 제품으로 출고 익일부터 구매한 상품의 개월 수만큼 혜택 적용 기간으로 간주합니다.
                  </p>
                  <p style={{wordBreak:'break-word'}}>
                    • 선택한 혜택 기간이 지나면 해지 또는 연장 사용이 가능하며, 연장 사용 시 구매자 명의로 개통 처리 및 월 16,500원(부가세 포함)의 통신비가 발생합니다.
                  </p>
                  <p style={{wordBreak:'break-word'}}>
                    • 구매 이후 단말기는 구매자 소유이므로 법에 위반되는 용도로 제품을 사용하였을 때 발생하는 모든 책임은 구매자 또는 사용자에게 있습니다.
                  </p>
                  <p style={{wordBreak:'break-word'}}>
                    • 무상 A/S 기간은 3년이며 침수, 파손, 분실 등 구매자 과실로 인한 A/S 접수 시 비용이 발생할 수 있습니다.
                  </p>
                </div>
                <div className="c-flex-row c-gap-2">
                  <div className="c-input-group" style={{ width: "100%" }}>
                    <label htmlFor="">혜택 적용 기간</label>
                    <div>
                      <div className="c-inputs-box-3">
                        <div className="c-flex-row c-gap-2">
                          <select
                            id="cYear"
                            name="fromYear"
                            onChange={handleUser}
                            value={user.fromYear}
                            required
                          >
                            <option value="">년도</option>
                            {yearArr?.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="">년</label>
                        </div>
                        <div className="c-flex-row c-gap-2">
                          <select
                            id="cMonth"
                            name="fromMonth"
                            onChange={handleUser}
                            value={user.fromMonth}
                            required
                          >
                            <option value="">월</option>
                            {monthArr?.map((month) => (
                              <option key={month} value={month}>
                                {month}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="">월</label>
                        </div>
                        <div className="c-flex-row c-gap-2">
                          {/* <input type="number" id="cDay" name="fromDay" placeholder="일" required /> */}
                          <select
                            id="cDay"
                            name="fromDay"
                            onChange={handleUser}
                            value={user.fromDay}
                            required
                          >
                            <option value="">일</option>
                            {dayArr?.map((day) => (
                              <option key={day} value={day}>
                                {day}
                              </option>
                            ))}
                          </select>
                          <label htmlFor="">일 </label>
                        </div>
                      </div>
                    </div>
                    <div className="c-error-message">
                      <p className="c-hidden c-error-text" id="contractError">
                        Contract end date
                      </p>
                      <p className="c-hidden c-no-error" id="contractSuccess">
                        성공
                      </p>
                    </div>
                  </div>
                </div>
                <div className="c-flex-col c-input-group" id="cDate">
                  <label htmlFor="">만료일</label>
                  <div className="c-form-text c-flex-row c-gap-2">
                    <p>
                      {" "}
                      <span id="cDateYear"></span>
                      {user.toYear}년
                    </p>
                    <p>
                      {" "}
                      <span id="cDateMonth"></span>
                      {user.toMonth}월
                    </p>
                    <p>
                      {" "}
                      <span id="cDateDay"></span>
                      {user.toDay}일
                    </p>
                  </div>
                  <input type="hidden" id="toYear" name="toYear" />
                  <input type="hidden" id="toMonth" name="toMonth" />
                  <input type="hidden" id="toDay" name="toDay" />
                </div>
                <div className="c-form-text c-flex-row c-gap-2">
                  <p>위 내용을 확인하였으며, 이에 동의합니다.</p>
                  <input
                    type="checkbox"
                    checked={isAgree}
                    onChange={handleAgree}
                    required
                  />
                </div>
                <div className="c-input-group">
                  <button className="c-btn" type="submit">
                    주문서 제출
                  </button>
                </div>
              </div>
            </form>
          </div>
        </main>
        <footer className="c-footer">
          <p> (주)아이오티플렉스 </p>
          <p> 대표이사 : 박문수</p>
          <p> 사업자 등록번호 : 256-81-00086</p>
          <p>
            {" "}
            주소 : 부산광역시 해운대구 센텀동로 99, 601호(재송동,
            벽산이센텀클래스원)
          </p>
          <p> 고객센터 : 1599-2737</p>
        </footer>
      </div>
    </>
  );
}
