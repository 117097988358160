import React, { useEffect } from "react";
import { LanguageContext } from "../../../context/LanguageContext.jsx";
import { useNavigate } from "react-router-dom";
import Header from "../../include/AppHeader";
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import CommonControl from "../../Atomic/organism/CommonControl";
import { dataList } from "./SetMain";
import { useAuth } from "../../../context/LoginContext";
import Swal from "sweetalert2";
import axios from "../../../lib/axios.js";
import { useLocation } from "react-router-dom";
import { subDataList } from "./SetMain";
import SubNavi from "../../Atomic/molecules/SubNavi";
export default function AlarmEdit() {
  const navi = useNavigate();
  const { language } = React.useContext(LanguageContext);
  const { user } = useAuth();
  const location = useLocation();

  // State definition
  //==================================================================
  const [rawData, setRawData] = React.useState({
    kind: "",
    user0: "",
    evecd_07: "N",
    evecd_09: "N",
    evecd_10: "N",
    evecd_11: "N",
    evecd_16: "N",
    evecd_17: "N",
    evecd_18: "N",
    evecd_20: "N",
    evecd_21: "N",
  });
  
  // Function definition
  //==================================================================
  function sendData() {
    axios.post("/web/settings/alarm/user/edit", rawData).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          title: "수정되었습니다",
        }).then((result) => {
          navi("/Set/Alarm");
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "실패하였습니다.",
        });
      }
    });
  }
  function submitFun() {
    Swal.fire({
      title: "수정하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        sendData();
      }
    });
  }
  function inputChnage(e) {
    setRawData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }
  function handleCheckboxChange(e) {
    const { name, checked } = e.target;
    setRawData((prev) => ({
      ...prev,
      [name]: checked ? "Y" : "N",
    }));
  }
  // UseEffect definition
  //==================================================================
  useEffect(() => {
    if (location.state) {
      axios
        .get(
          `/web/settings/alarm/detail?idx=${location.state.idx}&org_idx=${user.org_idx}`
        )
        .then((res) => {
          setRawData(res.data);
        });
    }
  }, [location]);
  // Data definition
  //==================================================================

  const buttonData = [
    {
      labelKR: "취소",
      labelEN: "Cancel",
      classN: "white",
      clickFun: () => {
        navi(`/Set/Alarm`);
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "수정",
      labelEN: "Save",
      classN: "purple",
      icon: true,
      clickFun: () => {
        submitFun();
      },
      position: "right",
    },
    {
      labelKR: "삭제",
      labelEN: "Delete",
      classN: "purple",
      icon: true,
      clickFun: () => {
        Swal.fire({
          title: "삭제하시겠습니까?",
          showCancelButton: true,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
        }).then((result) => {
          if (result.isConfirmed) {
            axios
              .post("/web/settings/alarm/user/del", {
                idx: location.state.idx,
              })
              .then((res) => {
                if (res.status === 200) {
                  Swal.fire({
                    title: "삭제되었습니다.",
                  }).then((result) => {
                    navi("/Set/Alarm");
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "삭제에 실패하였습니다.",
                  });
                }
              });
          }
        });
      },
      // position: "right",
    },
  ];

  // Log definition
  //==================================================================


  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Set/Alarm"} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "Push/Sms 관리" : "Push/Sms management"}
                </div>
              </div>
              <div className="sub-page-body">
                <SubNavi
                  subDataList={subDataList}
                  language={language}
                  navi={navi}
                  classPath="/Set/Alarm"
                />
                <table className="common-view-table">
                  <colgroup>
                    <col style={{ width: "19rem" }} />
                    <col />
                    <col style={{ width: "19rem" }} />
                    <col />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>{language === "KOR" ? "유의사항" : "Notes"}</th>
                      <td colSpan={3}>
                        <label>
                          <p>
                            - Push : 수신자가 어플리케이션 설치 및 1번이상
                            로그인
                          </p>
                          <br />
                          <p>- SMS : 등록자의 SMS개수에서 차감</p>
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <th>{language === "KOR" ? "Push/Sms" : "Push/Sms"}</th>
                      <td colSpan={3}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <label>{rawData.kind}</label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>{language === "KOR" ? "수신자" : "Recipient"}</th>
                      <td colSpan={3}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <label>
                            {rawData.org_nm} - {rawData.u_nm} ({rawData.u_id})
                          </label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>{language === "KOR" ? "이벤트" : "Event"}</th>
                      <td colSpan={3}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <label htmlFor="evecd_07">
                            <input
                              type="checkbox"
                              id="evecd_07"
                              name="evecd_07"
                              value="Y"
                              checked={rawData.evecd_07 === "Y"}
                              onChange={(e) => handleCheckboxChange(e)}
                            />{" "}
                            제한속도 보고
                          </label>
                          <label htmlFor="evecd_09">
                            <input
                              type="checkbox"
                              id="evecd_09"
                              name="evecd_09"
                              value="Y"
                              checked={rawData.evecd_09 === "Y"}
                              onChange={(e) => handleCheckboxChange(e)}
                            />{" "}
                            긴급보고
                          </label>
                          <label htmlFor="evecd_10">
                            <input
                              type="checkbox"
                              id="evecd_10"
                              name="evecd_10"
                              value="Y"
                              checked={rawData.evecd_10 === "Y"}
                              onChange={(e) => handleCheckboxChange(e)}
                            />{" "}
                            지역진입보고
                          </label>
                          <label htmlFor="evecd_11">
                            <input
                              type="checkbox"
                              id="evecd_11"
                              name="evecd_11"
                              value="Y"
                              checked={rawData.evecd_11 === "Y"}
                              onChange={(e) => handleCheckboxChange(e)}
                            />{" "}
                            지역이탈보고
                          </label>
                          <label htmlFor="evecd_16">
                            <input
                              type="checkbox"
                              id="evecd_16"
                              name="evecd_16"
                              value="Y"
                              checked={rawData.evecd_16 === "Y"}
                              onChange={(e) => handleCheckboxChange(e)}
                            />{" "}
                            주전원 off-12V
                          </label>
                          <label htmlFor="evecd_17">
                            <input
                              type="checkbox"
                              id="evecd_17"
                              name="evecd_17"
                              value="Y"
                              checked={rawData.evecd_17 === "Y"}
                              onChange={(e) => handleCheckboxChange(e)}
                            />{" "}
                            내장배터리 저전압
                          </label>
                          <label htmlFor="evecd_18">
                            <input
                              type="checkbox"
                              id="evecd_18"
                              name="evecd_18"
                              value="Y"
                              checked={rawData.evecd_18 === "Y"}
                              onChange={(e) => handleCheckboxChange(e)}
                            />{" "}
                            차량배터리 저전압
                          </label>
                          <label htmlFor="evecd_20">
                            <input
                              type="checkbox"
                              id="evecd_20"
                              name="evecd_20"
                              value="Y"
                              checked={rawData.evecd_20 === "Y"}
                              onChange={(e) => handleCheckboxChange(e)}
                            />{" "}
                            차량 문 열림
                          </label>
                          <label htmlFor="evecd_21">
                            <input
                              type="checkbox"
                              id="evecd_21"
                              name="evecd_21"
                              value="Y"
                              checked={rawData.evecd_21 === "Y"}
                              onChange={(e) => handleCheckboxChange(e)}
                            />{" "}
                            차량 문 닫힘
                          </label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
