import React, { useEffect, useRef } from "react";

import { useState } from "react";
import Title from "../atom/Title";

import moment from "moment";
import DatePicker from "vanillajs-datepicker/Datepicker";

export default function DatePickerForm({
  value,
  changeFun,
  title,
  titleName,
  language,
}) {
  const fromRef = useRef(null);
  const handleDate = (amount) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + amount);
    setDate(moment(newDate).format("YYYY-MM-DD"));
  };
  const [fromPicker, setFromPicker] = useState(null);
  const [date, setDate] = useState("");

  // use effect for datepicker
  useEffect(() => {
    if (fromPicker) {
      fromPicker.destroy();
    }
    const from = new DatePicker(fromRef.current, {
      language: language === "KOR" ? "ko" : "en",
      autohide: true,
      todayBtn: true,
      todayBtnMode: 1,
      format: "yyyy-mm-dd",
    });

    setFromPicker(from);
  }, [language]);
  
  useEffect(() => {
    changeFun(title, date);
  }, [date]);

  useEffect(() => {
    if (value) {
      setDate(value);
    }
  }, [value]);

  return (
    <div className="form-row">
      <Title title={titleName} />
      <div className="history-control ">
        <div className="input-wrapper full time-inputs ">
          <button
            type="button"
            className="arrow"
            onClick={() => handleDate(-1)}
          >
            <i className="ri-arrow-drop-left-line icon"></i>
          </button>
          <div className="input-date">
            <input
              type="text"
              ref={fromRef}
              value={moment(date).format("YYYY-MM-DD")}
              className="input-date"
              readOnly
            />
          </div>
          <button type="button" className="arrow" onClick={() => handleDate(1)}>
            <i className="ri-arrow-drop-right-line icon"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
