import React, { useEffect } from "react";
import axios from "../../../lib/axios";
import { trimType } from "../../../lib/common_func";
import { useNavigate } from "react-router-dom";
import Header from "../../include/AppHeader";
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import CommonControl from "../../Atomic/organism/CommonControl";
import Table from "../../Atomic/organism/AtomTable";
export const dataList = [
  {
    permission: "G100",
    path: "/Set",
    labelKR: "공지사항",
    labelEN: "Notice",
  },
  {
    permission: "G200",
    path: "/Set/Custom",
    labelKR: "개인 설정",
    labelEN: "Customize",
  },
  {
    permission: "G300",
    path: "/Set/Alarm",
    labelKR: "Push/Sms 관리",
    labelEN: "Push/Sms",
  },
];
export const subDataList = [
  {
    path: "/Set/Alarm",
    labelKR: "Push/Sms 설정",
    labelEN: "Push/Sms",
  },
  {
    path: "/Set/Push",
    labelKR: "Push 전송내용",
    labelEN: "Push Alarm",
  },
  {
    path: "/Set/Sms",
    labelKR: "Sms 전송내용",
    labelEN: "Sms Alarm",
  },
];
export function label() {
  return;
}
export default function SetMain({ language, user }) {
  const navi = useNavigate();
  const inputRef = React.createRef();

  // State definition
  //==================================================================
  const [rawData, setRawData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [keyword, setKeyword] = React.useState("");
  // Function definition
  //==================================================================
  async function submitFun(e) {
    e.preventDefault();
    // await getData();
  }
  async function getData() {
    await axios.get(`/web/settings/notice/list`).then((res) => {
      if (res.status === 200) {
        setRawData(res.data);
        return;
      }
      if (res.status === 202) {
        setRawData([]);
        return;
      }
    });
  }
  function inputChnage(key) {
    // setKeyword(key);
    onChangeFilter({ target: { value: key } });
  }

  function onChangeFilter(e) {
    const searchText = e.target.value;
    setKeyword(searchText);
    if (searchText === "") {
      setData(rawData);
      return;
    }
    filterData(searchText);
  }

  function filterData(searchText) {
    if (searchText === "") {
      setData(rawData);
    } else {
      const filteredData = rawData.filter((item) => {
        for (const key in item) {
          if (item.hasOwnProperty(key) && typeof item[key] === "string") {
            if (item[key].toLowerCase().includes(searchText.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
      setData(filteredData);
    }
  }
  function clearFilter() {
    setKeyword("");
    setData(rawData);
    document.getElementById("keyword").value = "";
  }
  const move = (e) => {
    const i = e.instance.store.focus.rowIndex;
    if (i !== null) {
      const idx = trimType(e.instance.store.data.rawData[i].idx);
      // const cmp_nm = trimType(e.instance.store.data.rawData[i].cmp_nm);
      // const cmp_cd = trimType(e.instance.store.data.rawData[i].cmp_cd);
      if (e.columnName === "btn" && e.targetType != "columnHeader") {
        navi(`/Set/Edit`, {
          state: {
            idx: idx,
            // cmp_nm: cmp_nm,
            // cmp_cd: cmp_cd,
          },
        });
      }
    }
  };
  // UseEffect definition
  //==================================================================
  useEffect(() => {
    axios
      .get(`/web/settings/notice/list`)
      .then((res) => {
        setRawData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  useEffect(() => {
    if (rawData !== undefined) {
      setData(rawData);
    }
  }, [rawData]);
  // Data definition

  //==================================================================
  const label = `<span style="background: red; color:white; padding:1rem; border-radius: 12px">긴급</span>`;
  const buttonData = [
    {
      labelKR: "전체목록",
      labelEN: "View all",
      classN: "white",
      clickFun: () => {
        clearFilter();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
  ];
  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e) => inputChnage(e),
    myref: inputRef,
    //option: (),
    value: keyword,
  };
  const columns = [
    {
      header: language === "KOR" ? "번호" : "Number",
      name: "rowKey" + 1,
      width: 50,
      align: "center",
      formatter: (name) => name.row.rowKey + 1,
    },

    {
      header: language === "KOR" ? "제목" : "Title",
      name: `tit`,
      sortable: true,
      align: "center",
      formatter: (name) =>
        `${name.row.eme_yn === "Y" ? `${label}` : ""} ${name.row.tit}`,
    },
    {
      header: language === "KOR" ? "작성자" : "Writer",
      name: "writer",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "등록일" : "Make date",
      name: "date",
      sortable: true,
      align: "center",
    },

    {
      header: language === "KOR" ? "보기" : "View",
      name: "btn",
      resizeable: true,
      align: "center",
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "보기" : "View "
        }</div>`,
    },
  ];
  // Log definition
  //==================================================================

  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Set"} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "공지사항" : "Notice"}
                </div>
              </div>
              <div className="sub-page-body">
                <Table data={data} columns={columns} move={move} />
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
