import PropTypes from "prop-types";

export default function AtomBTN({ children, onClick, value, classN }) {
  return (
    <>
      <button value={value} onClick={onClick} className={` ${classN}`}>
        {children}
      </button>
    </>
  );
}

AtomBTN.propTypes = {
  classN: PropTypes.string.isRequired,
  children: PropTypes.node,
  onClick: PropTypes.func,
  value: PropTypes.string,
};
