import React from "react";

interface RecentModal {
  data: any;
  isOpen: boolean;
  closeNews: (event: any) => void;
}

export default function Modal({ data, isOpen, closeNews }: RecentModal) {
  return (
    <>
      <div
        id="recent-panel"
        className={`common-modal recent ${isOpen === true ? "on" : ""}`}
      >
        <div
          className="modal-inner"
          style={{
            padding: "15rem 2rem",
          }}
        >
          <div className="modal-page md">
            <div className="page-header">
              <div className="page-title">
                <span>{data.tit}</span>
              </div>
              <button
                type="button"
                className="modal-close"
                onClick={(e) => closeNews(e)}
              >
                <i className="ri-close-fill icon"></i>
              </button>
            </div>
            <div className="page-body">
              <div className="recent-view">
                <div className="view-meta">
                  <div className="meta-item">
                    <div className="item-label">작성일</div>
                    <div className="item-content">{data.date}</div>
                  </div>
                  <div className="meta-item">
                    <div className="item-label">작성자</div>
                    <div className="item-content">관리자</div>
                  </div>
                </div>
                <div className="view-content">
                  {/* {data.ctnt &&
                    data.ctnt.split("").map((line: string, index: number) => {
                      return (
                        <span key={index}>
                          {line}
                          <br />
                          <br />
                        </span>
                      );
                    })} */}
                  <div className="item-content-recent">{data.ctnt}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
