import React from "react";
import List from "../molecules/List";
interface Props {
  language: string;
  menu: string;
  dataList: any[];
}
export default function RunNav({ language, menu, dataList }: Props) {
  if (dataList.length === 0) return <></>;
  return (
    <nav className="sub-nav">
      <List language={language} menu={menu} dataList={dataList} />
    </nav>
  );
}
