import React, { useEffect } from "react";
import Swal from "sweetalert2";
import axios from "../../../lib/axios";
import { add_hyphen, trimType } from "../../../lib/common_func";
import { useLocation } from "react-router-dom";
import { LanguageContext } from "../../../context/LanguageContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/LoginContext";
// Atoms
import Footer from "../../include/AppFooter";
import Header from "../../include/AppHeader";
import Nav from "../../Atomic/organism/Nav";
import Table from "../../Atomic/organism/AtomTable";
import CommonControl from "../../Atomic/organism/CommonControl";
import { dataList } from "./TerminalMain";
export default function GeoFenceListDevice() {
  const user = useAuth();
  const navi = useNavigate();
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  // State definition
  //==================================================================
  const [rawData, setRawData] = React.useState([]);
  const [detaiData, setDetaiData] = React.useState([]);
  const [unitId, setUnitId] = React.useState("");
  const [fence, setFence] = React.useState({});
  //==================================================================

  const columns = [
    {
      header: "No",
      name: "rowKey",
      resizable: false,
      width: 50,
      formatter: (name) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "단말번호" : "USIM num",
      name: "unitid",
      resizable: true,
      align: "center",
      sortable: true,
      formatter: (item) => add_hyphen(item.value, language),
    },
    {
      header: language === "KOR" ? "단말명" : "Unit Name",
      name: "unit_nm",
      resizable: true,
      align: "center",
      sortable: true,
    },
    {
      header: language === "KOR" ? "연결" : "Connection",
      name: "conn",
      resizable: true,
      align: "center",
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "연결" : "Connect"
        }</div>`,
    },
    {
      header: language === "KOR" ? "연결해제" : "disconnection",
      name: "disconn",
      resizable: true,
      align: "center",
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "해제" : "Disconnect"
        }</div>`,
    },
  ];
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",

      clickFun: () => {
        navi("/Trm/GeoFence");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
  ];
  //==================================================================
  useEffect(() => {
    if (location.state.unitid) {
      setUnitId(location.state.unitid);
    }
  }, [location.state.unitid]);

  useEffect(() => {
    if (unitId) {
      fetchData(unitId).then((res) => setFence(res.data));
    }
  }, [unitId]);

  useEffect(() => {
    if (unitId) {
      async function getData() {
        try {
          await axios
            .get(
              `/web/region/terminal/list?idx=${user.user.idx}&org_idx=${user.user.org_idx}`
            )
            .then((res) => setRawData(res.data));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      getData();
    }
  }, [unitId, user.user.idx, user.user.org_idx]);

  useEffect(() => {
    setDetaiData(rawData);
  }, [rawData]);
  // Function definition
  //==================================================================
  async function connect(idx, uID) {
    let obj = {
      idx: fence.idx,
      in_out_type: fence.in_out_type,
      lat: fence.lat,
      lng: fence.lng,
      radius: fence.radius,
      zone_id: fence.zone_id,
      unitid: uID,
      utype: user.user.type,
      uidx: user.user.idx,
      d_idx: idx,
      gio_con_flag: "C",
    };

    axios.post("/web/region/terminal/conn", obj).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "연결 신호를 전송하였습니다.",
        }).then((result) => {
          if (result.isConfirmed) {
            navi("/Trm/GeoFence");
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "연결 시도에 실패하였습니다.",
        });
      }
    });
  }
  async function dis(idx, uID) {
    let obj = {
      idx: fence.idx,
      in_out_type: fence.in_out_type,
      lat: fence.lat,
      lng: fence.lng,
      radius: fence.radius,
      zone_id: fence.zone_id,
      unitid: uID,
      utype: user.user.type,
      uidx: user.user.idx,
      d_idx: idx,
      gio_con_flag: "D",
    };

    axios.post("/web/region/terminal/conn", obj).then((res) => {
      if (res.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "해제 신호를 전송하였습니다.",
        }).then((result) => {
          if (result.isConfirmed) {
            navi("/Trm/GeoFence");
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "연결 해제에 실패하였습니다.",
        });
      }
    });
  }
  async function sumbitFun(fun) {
    Swal.fire({
      title:
        language === "KOR" ? "설정 하시겠습니까?" : "Would you like to set?",
      confirmButtonText: language === "KOR" ? "확인" : "OK",
      showCancelButton: true,
      cancelButtonText: language === "KOR" ? "취소" : "Cancel",
      width: "400px",
      padding: "20px",
      heightAuto: false,
      customClass: {        
        cancelButton: "swal-cancel-btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        fun();
      }
    });
  }
  const fetchData = async (unitId) => {
    try {
      if (unitId) {
        const response = await axios.get(`/web/region/detail?idx=${unitId}`);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const move = (e) => {
    const i = e.instance.store.focus.rowIndex;
    if (i !== null) {
      const idx = trimType(e.instance.store.data.rawData[i].idx);
      if (e.columnName === "conn") {
        sumbitFun(() => connect(idx, e.instance.store.data.rawData[i].unitid));
      }

      if (e.columnName === "disconn") {
        sumbitFun(() => dis(idx, e.instance.store.data.rawData[i].unitid));
      }
    }
  };

  // Log Debugging
  //==================================================================

  return (
    <>
      <Header pages="4" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav dataList={dataList} menu="/Trm/GeoFence" language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR"
                    ? `${fence.region_nm} (${fence.radius}m) 지역 설정 가능한 단말 목록`
                    : `List of units connected to ${fence.region_nm} (${fence.radius}m) area`}
                </div>
              </div>
              <div className="sub-page-body">
                <div className="common-table-wrapper">
                  <Table
                    language={language}
                    data={rawData}
                    columns={columns}
                    move={move}
                  />
                  <CommonControl buttonData={buttonData} language={language} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
