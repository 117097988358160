import React from 'react';
import { Link } from 'react-router-dom';

export default function ErrorMain() {
    return (
        <>
            <header className="errorcon">
                <img src="../assets/img/login/logo-klbs.svg" alt="" width="180px" className="errorlogo" />
                <h1 className="errortitle">
                    죄송합니다.<br />요청하신 페이지를 찾을 수 없습니다.
                </h1>
                <div className="errorsub">
                    찾으시는 페이지의 주소가 잘못 입력되었거나<br />
                    주소의 변경 혹은 삭제로 인해 확인되지 않습니다.<br />
                    입력하신 주소가 정확한지 다시 확인해주시기 바랍니다.
                </div>
            </header>
            <main className="errorlink">
                <Link to="/">
                    <button className="errorbtn">
                        KLBS 홈페이지로 이동
                    </button>
                </Link>
            </main>
            <footer className="errorft">PAGE NOT FOUND</footer>   
        </>
    );
}

