import React, { useContext } from "react";
import { LanguageContext } from "../../context/LanguageContext";
//import { btocYn, orgType } from "../../lib/common_func";
import axios from "../../lib/axios";
import { useEffect } from "react";
import { useState } from "react";
export default function AppFooter({ onClickFun, isOpen }) {
  const { language } = useContext(LanguageContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // https://vscode.dev/tunnel/desktop-bi7ss9v/e:/Data/react web dev
  useEffect(() => {
    if (isOpen) {
      axios
        .post("/web/admin/session/list")
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [isOpen]);

  return (
    <div id="crew-search-panel" className="common-modal">
      <div className="modal-inner">
        <div className="modal-page">
          <div className="page-header">
            <div className="page-title">
              <span>{language === "KOR" ? "조직검색" : "Org.Search"}</span>
            </div>
            <button
              type="button"
              className="modal-close"
              data-modal-id="crew-search-panel"
            >
              <i className="ri-close-fill icon">close</i>
            </button>
          </div>
          <div className="page-body">
            <div className="flex-container">
              <form className="search-bar">
                <div className="custom-input">
                  <input
                    type="text"
                    placeholder="사용자명, 사용자명, 회사코드, 사용자가 속한 소속명"
                  />
                </div>
                <button type="button" className="submit-btn">
                  {language === "KOR" ? "검색" : "Search"}
                </button>
              </form>
              <div className="common-table-wrapper">
                <table className="common-table">
                  <colgroup>
                    <col style={{ width: "8%" }} />
                    <col style={{ width: "8%" }} />
                    <col style={{ width: "8%" }} />
                    <col style={{ width: "12%" }} />
                    <col />
                    <col />
                    <col style={{ width: "8%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th>{language === "KOR" ? "번호" : "No"}</th>
                      <th>{language === "KOR" ? "구분" : "Type"}</th>
                      <th>{language === "KOR" ? "등급" : "Grade"}</th>
                      <th>{language === "KOR" ? "회사코드" : "Com code"}</th>
                      <th>{language === "KOR" ? "소속" : "Org"}</th>
                      <th>
                        {language === "KOR"
                          ? "사용자명(아이디)"
                          : "Username(ID)"}
                      </th>
                      <th>{language === "KOR" ? "차량수" : "Device"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading === false ? (
                      <tr>
                        <td>Loading</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>No Data</td>
                      </tr>
                    )}
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.orgType}</td>
                        <td>{item.orgGrade}</td>
                        <td>{item.comCode}</td>
                        <td>{item.orgName}</td>
                        <td>{item.userName}</td>
                        <td>{item.deviceCount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="common-pager">
                  <ul>
                    <li>
                      <button type="button" className="first">
                        <span className="a11y">처음</span>
                      </button>
                    </li>
                    <li>
                      <button type="button" className="prev">
                        <span className="a11y">이전</span>
                      </button>
                    </li>
                    <li>
                      <button type="button" className="pager-item on">
                        1
                      </button>
                    </li>
                    <li>
                      <button type="button" className="pager-item">
                        2
                      </button>
                    </li>
                    <li>
                      <button type="button" className="pager-item">
                        3
                      </button>
                    </li>
                    <li>
                      <button type="button" className="pager-item">
                        4
                      </button>
                    </li>
                    <li>
                      <div className="omit"></div>
                    </li>
                    <li>
                      <button type="button" className="pager-item">
                        9
                      </button>
                    </li>
                    <li>
                      <button type="button" className="pager-item">
                        10
                      </button>
                    </li>
                    <li>
                      <button type="button" className="next">
                        <span className="a11y">다음</span>
                      </button>
                    </li>
                    <li>
                      <button type="button" className="last">
                        <span className="a11y">마지막</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
