import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/LoginContext";

export default function ProtectedRoute({ children }) {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/" replace />;
  }
  return children;
}
