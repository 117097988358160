import React, { useEffect, useState } from "react";

// setState
// setData

export default function InputFilter({
  language,
  setState,
  nameShow,
  value,
  companyData,
  onClickFilter,
  placeholder,
  isHead,
  customStyle,
  resetEvent,
}) {
  const [searchText, setSearchText] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isChanged, setIsChanged] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  // function definition
  //===========================================================================
  function onChangeFilter(e) {
    setState(e.target.value);
    handleFilterChange(e);
  }
  function handleFilterChange(e) {
    const searchText = e.target.value;
    setSearchText(searchText);
    if (searchText === "") {
      setFilteredData([]);
      return;
    }
    // const filteredData = companyData.filter((item) => {
    //   return item.org_nm.toLowerCase().includes(searchText.toLowerCase());
    // });
    if(companyData && companyData.length > 0) {
      const filteredData =  companyData.filter((item) => {
        for (const key in item) {
          if (item.hasOwnProperty(key) && typeof item[key] === "string") {
            if (item[key].toLowerCase().includes(searchText.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
      setFilteredData(filteredData);
    } else {
      setFilteredData([]);
    }
  }
  useEffect(() => {
    if (isReadOnly) {
      setIsChanged(false);
    }
  }, [isReadOnly]);
  function editCPN(e) {
    if (!isReadOnly) {
      setIsChanged(true);
    }
  }
  function clearinput(e) {
    resetEvent && resetEvent();
    setState("");
    setIsReadOnly(false);
    setIsChanged(false);
  }
  useEffect(() => {
    if (isHead) {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  }, [isHead]);
  const placeholderText = placeholder();
  // useEffect definition
  //===========================================================================
  useEffect(() => {
    if (searchText === "") {
      setFilteredData("");
    }
  }, [searchText]);
  // data definitions
  const style = {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "40px",
    background: "white",
    border: "1px solid gray",
    padding: "10px",
    zIndex: "100",
    flexDirection: "column",
    maxHeight: "200px",
    overflow: "auto",
  };
  const liStyle = {
    padding: "10px",
    width: "100%",
    cursor: "pointer",
    borderBottom: "1px solid gray",
  };
  const dis = {
    position: "relative",
  };
  return (
    <>
      <div className="flex" style={dis}>
        <div className="custom-input">
          <input
            name="org_nm"
            type="text"
            placeholder={placeholderText}
            onChange={onChangeFilter}
            onFocus={(e) => editCPN(e)}
            readOnly={isReadOnly}
            value={value}
            autoComplete="off"
            style={{...customStyle}}
          />
        </div>
        <button
          name="org_nm"
          type="button"
          className="common-btn white sm"
          onClick={(e) => clearinput(e)}
        >
          <>{language === "KOR" ? "초기화" : "Reset"}</>
        </button>
        {isChanged && filteredData.length > 0 ? (
          <ul className="search-list" style={style}>
            {filteredData.map((item, index) => (
              <li
                style={liStyle}
                key={index}
                onClick={() => {
                  onClickFilter(item);
                  setIsReadOnly(true);
                }}
              >
                {nameShow(item)}
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
