import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";

export default function DeviceSelector({ data, onChange }) {
  const { language } = useContext(LanguageContext);
  return (
    <div className="custom-select">
      <select id="unitid" onChange={onChange}>
        <option value="All">{language === "KOR" ? "전체목록" : "All"}</option>
        {data?.map((v) => {
          return (
            <option value={v.unitid} key={v.unitid}>
              {v.unit_nm}
            </option>
          );
        })}
      </select>
    </div>
  );
}
