import React, { useEffect, useState } from "react";
import axios from "../../../lib/axios";
import { useAuth } from "../../../context/LoginContext";
import { LanguageContext } from "../../../context/LanguageContext.jsx";
import { useNavigate } from "react-router-dom";
import Header from "../../include/AppHeader";
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import CommonControl from "../../Atomic/organism/CommonControl";
import Table from "../../Atomic/organism/AtomTable";
import { dataList } from "./SetMain";
import moment from "moment";
import { useRef } from "react";
import DatePicker from "vanillajs-datepicker/Datepicker";
import { subDataList } from "./SetMain";
import SubNavi from "../../Atomic/molecules/SubNavi";
export default function Sms() {
  const navi = useNavigate();
  const { language } = React.useContext(LanguageContext);
  const { user } = useAuth();
  // State definition
  //==================================================================
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const defalueDate = moment().format("YYYY-MM-DD");
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState({ from: defalueDate, to: defalueDate });
  const inputRef = useRef(null);
  // Function definition
  //==================================================================

  async function submitFun(e) {
    e.preventDefault();
    await getData();
  }

  async function getData() {
    await axios
      .get(
        `/web/settings/alarm/sms/list?type=${user.type}&org_idx=${user.org_idx}&from=${date.from}&to=${date.to}`
      )
      .then((res) => {
        if (res.status === 200) {
          setRawData(res.data);
          return;
        }
        if (res.status === 202) {
          setRawData([]);
          return;
        }
      });
  }

  function inputChnage(key) {
    // setKeyword(key);
    onChangeFilter({ target: { value: key } });
  }

  function onChangeFilter(e) {
    const searchText = e.target.value;
    setKeyword(searchText);
    if (searchText === "") {
      setData(rawData);
      return;
    }
    filterData(searchText);
  }

  function filterData(searchText) {
    if (searchText === "") {
      setData(rawData);
    } else {
      const filteredData = rawData.filter((item) => {
        for (const key in item) {
          if (item.hasOwnProperty(key) && typeof item[key] === "string") {
            if (item[key].toLowerCase().includes(searchText.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
      setData(filteredData);
    }
  }
  const move = (e) => {
    
  };
  function clearFilter() {
    setKeyword("");
    setData(rawData);
    document.getElementById("keyword").value = "";
  }
  // UseEffect definition
  //==================================================================

  useEffect(() => {
    axios
      .get(
        `/web/settings/alarm/sms/list?type=${user.type}&org_idx=${user.org_idx}&from=${date.from}&to=${date.to}`
      )
      .then((res) => {
        if (res.status === 200) {
          setRawData(res.data);
          return;
        }
        if (res.status === 202) {
          setRawData([]);
          return;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, date]);

  useEffect(() => {
    if (rawData) {
      setData(rawData);
    }
  }, [rawData]);

  useEffect(() => {
    function changeFun(name, value) {
      setDate({ ...date, [name]: value });
    }
    const detailPicker = document.querySelectorAll(".custom-datepicker input");

    detailPicker.forEach((item) => {
      DatePicker.locales.ko = {
        days: [
          "일요일",
          "월요일",
          "화요일",
          "수요일",
          "목요일",
          "금요일",
          "토요일",
        ],
        daysShort: ["일", "월", "화", "수", "목", "금", "토"],
        daysMin: ["일", "월", "화", "수", "목", "금", "토"],
        months: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        monthsShort: [
          "1월",
          "2월",
          "3월",
          "4월",
          "5월",
          "6월",
          "7월",
          "8월",
          "9월",
          "10월",
          "11월",
          "12월",
        ],
        today: "오늘",
        clear: "삭제",
        format: "yyyy-mm-dd",
        titleFormat: "yyyy년mm월",
        weekStart: 0,
      };
      
      new DatePicker(item, {
        language: language === "KOR" ? "ko" : "en",
        autohide: true,
        todayBtn: true,
        todayBtnMode: 1,
        format: "yyyy-mm-dd",
      });
      item.addEventListener("changeDate", (e) => {
        changeFun(e.target.name, e.target.value);
      });
    });
  }, [language]);

  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
    }
  }, [rawData, keyword]);
  // Data definition
  //==================================================================

  const buttonData = [
    {
      labelKR: "전체목록",
      labelEN: "View all",
      classN: "white",
      clickFun: () => {
        clearFilter();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
  ];
  const columns = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 50,
      align: "center",
      formatter: (name) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "수신자" : "Recipients",
      width: 200,
      name: "u_nm",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "전송내용" : "Transmission details",
      name: "msg",
      sortable: true,
      align: "center",
    },
    {
      header: language === "KOR" ? "전송일시" : "Transmission date",
      name: "makedate",
      sortable: true,
      align: "center",
    },
  ];

  // Log definition
  //==================================================================

  return (
    <>
      <Header pages="3" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav menu={"/Set/Alarm"} dataList={dataList} language={language} />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "Push/Sms 관리" : "Push/Sms management"}
                </div>
              </div>
              <div className="sub-page-body">
                <form className="search-bar" onSubmit={submitFun}>
                  <div className="custom-input">
                    <input
                      id="keyword"
                      type="text"
                      placeholder={
                        language === "KOR"
                          ? "단말번호 텍스트 검색"
                          : "enter a USIM num"
                      }
                      ref={inputRef}
                      onChange={(e) => {
                        inputChnage(e.target.value);
                      }}
                    />
                  </div>

                  <div className="period-group">
                    <div className="custom-datepicker">
                      <input
                        type="text"
                        name="from"
                        value={date.from}
                        readOnly
                      />
                    </div>
                    <span className="devider">~</span>
                    <div className="custom-datepicker">
                      <input type="text" name="to" value={date.to} readOnly />
                    </div>
                  </div>

                  <button type="submit" className="submit-btn">
                    {language === "KOR" ? "검색" : "Search"}
                  </button>
                </form>
                <SubNavi
                  subDataList={subDataList}
                  language={language}
                  navi={navi}
                  classPath="/Set/Sms"
                />
                <Table data={data} columns={columns} move={move} />
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
