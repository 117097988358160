import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LanguageContext } from "../../../context/LanguageContext";
import Footer from "../../include/AppFooter";
import Header from "../../include/AppHeader";
import Nav from "../../Atomic/organism/Nav";
import axios from "../../../lib/axios";
import Form from "../BlueToothForm";
import { dataList } from "./TerminalMain";
export default function TerminalEdit() {
  // State definition
  const { language } = useContext(LanguageContext);
  const location = useLocation();

  const [detaiData, setDetaiData] = useState([]);
  const [unitId, setUnitId] = useState("");

  //===========================================================

  // Data definition

  //===========================================================
  // Function definition

  //===========================================================
  // useEffect definition

  useEffect(() => {
    if (unitId) {
      async function getData() {
        try {
          await axios
            .get(`/web/bluetooth/detail?idx=${unitId}`)
            .then((res) => setDetaiData(res));
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
      getData();
    }
  }, [unitId]);

  useEffect(() => {
    if (location.state) {
      setUnitId(location.state.unitid);
    }
  }, [location.state]);

  //===========================================================
  if (detaiData.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header pages="4" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav
              dataList={dataList}
              menu="/Trm/BlueTooth"
              language={language}
            />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "블루투스 설정" : "Bluetooth Settings"}
                </div>
              </div>
              <div className="sub-page-body">
                <div className="common-table-wrapper">
                  <Form data={detaiData.data} language={language} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
