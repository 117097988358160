import React, { useContext } from "react";
import { LanguageContext } from "../../../context/LanguageContext";
import { useMap } from "../../../context/MapContext";

export default function PathBtn({ path }) {
  const { language } = useContext(LanguageContext);
  const { mapState, mapDispatch } = useMap();
  const { pathMode } = mapState;

  const handleShowPath = () => {
    mapDispatch({ type: "SET_PATH_MODE", payload: !pathMode });
  };

  return (
    <button type="button" className="showpath-btn" onClick={handleShowPath}>
      <i className="ri-route-fill icon"></i>
      {language === "KOR" ? "경로보기" : "View Path"}
    </button>
  );
}
