import React from 'react';
import { useMap } from '../../../context/MapContext';

export default function ZoomButton() {
    
    const {mapState, mapDispatch} = useMap();
    const {map} = mapState;
    //zoom 버튼
    const handleZoomIn = () => {
        if(map.getZoom() !== 21) {
            mapDispatch({type:'SET_ZOOM', payload: map.getZoom() + 1});
            map.setZoom(map.getZoom() + 1, false);
        }
    }
    const handleZoomOut = () => {
        mapDispatch({type:'SET_ZOOM', payload: map.getZoom() - 1});
        map.setZoom(map.getZoom() - 1, false);
    }
    return (
        <div className="btn-group">
                <div className="group-item">
                    <button type="button" className="control-btn zoom-in" onClick={handleZoomIn}>
                        <i className="ri-add-fill icon"></i>
                    </button>
                </div>
                <div className="group-item">
                    <button type="button" className="control-btn zoom-out" onClick={handleZoomOut}>
                        <i className="ri-subtract-fill icon"></i>
                    </button>
                </div>
            </div>
    );
}

