import React, { useEffect, useState } from "react";
import { useMap } from "../../context/MapContext";
// map
import GeofencePanel from "../../pages/map/GeofencePanel";
import LeftListPanel from "../../pages/map/LeftListPanel";
import RightDetailPanel from "../../pages/map/RightDetailPanel";
import RightEventPanel from "../../pages/map/RightEventPanel";
import MapComponents from "./MapComponents";
import EventMarker from "./map/EventMarker";
import GeoMarker from "./map/GeoMarker";
import HistoryMarker from "./map/HistoryMarker";
import Marker from "./map/Marker";
import NaverMap from "./map/NaverMap";
import NaverPanoMap from "./map/NaverPanoMap";
import PanoControls from "./map/PanoControls";
import PathMarker from "./map/PathMarker";
import RightDailyPanel from "../../pages/map/RightDailyPanel";

export default function ControlMap({ data, geoData, typeData }) {
    const { naver } = window;
    const { mapState, mapDispatch } = useMap();
    const {
        map,
        trackingList,
        selectItem,
        selectPathItem,
        selectGeoItem,
        pathData,
        panoItem,
        totalEventMode,
        pathMode,
        streetMode,
        geofenceList,
        selectEventItem,
        dailyMode,
    } = mapState;

    const [cur, setCur] = useState({ x: 0, y: 0 });
    const [circle, setCircle] = useState({ c1: null, c2: null });
    const [geoCircle, setGeoCircle] = useState({ g1: null });

    const [latitude, setLatitude] = useState([]);
    const [longitude, setLongitude] = useState([]);

    useEffect(() => {
        if (latitude.length != 0) {
            let def = new naver.maps.LatLngBounds(
                new naver.maps.LatLng(
                    Math.min.apply(null, latitude),
                    Math.min.apply(null, longitude)
                ),
                new naver.maps.LatLng(
                    Math.max.apply(null, latitude),
                    Math.max.apply(null, longitude)
                )
            );
            if (map) {
                map.fitBounds(def);
            }
        }
    }, [map, latitude, longitude]);
    useEffect(() => {
        circle.c1?.setMap(null);
        circle.c2?.setMap(null);
        //geoCircle.g1?.setMap(null);
    }, [pathMode, dailyMode]);

    const handleCursorMove = (e) => {
        streetMode && !panoItem && setCur({ x: e.clientX, y: e.clientY });
    };

    useEffect(() => {
        mapDispatch({
            type: "SET_STREET_LAYER",
            payload: new naver.maps.StreetLayer(),
        });
    }, [naver, mapDispatch]);
    useEffect(() => {
        if (data) {
            mapDispatch({ type: "SET_TRACKING_LIST", payload: data.data });
            mapDispatch({
                type: "SET_TOTAL_EVENT_COUNT",
                payload: data.eventCount,
            });
            // 최초 맵 로드시 줌 바운딩
            if (latitude.length === 0) {
                const arrLat = [];
                const arrLng = [];
                data.data.map((item) => {
                    arrLat.push(item.latitude);
                    arrLng.push(item.longitude);
                });
                setLatitude(arrLat);
                setLongitude(arrLng);
            }
        }
    }, [data, mapDispatch]);
    useEffect(() => {
        if (geoData) {
            mapDispatch({ type: "SET_GEOFENCE_LIST", payload: geoData });
        }
    }, [geoData, mapDispatch]);

    return (
        // <div onMouseMove={(e)=>handleCursorMove(e)} style={{height:'100%'}}>
        <>
            <NaverMap handleCursorMove={handleCursorMove}>
                {!pathMode &&
                    trackingList?.map((item, index) => {
                        return (
                            <Marker
                                key={item.unitid}
                                item={item}
                                index={index}
                                circle={circle}
                                setCircle={setCircle}
                            />
                        );
                    })}
                {!pathMode &&
                    geofenceList?.map((item) => {
                        return (
                            <GeoMarker
                                key={item.idx}
                                item={item}
                                geoCircle={geoCircle}
                                setGeoCircle={setGeoCircle}
                            />
                        );
                    })}
                {pathMode && pathData && <PathMarker />}
                {selectEventItem && <EventMarker />}
                {selectGeoItem && <GeofencePanel />}
                {!pathMode && selectPathItem && <HistoryMarker />}
                <MapComponents cur={cur} />
            </NaverMap>
            {map && <LeftListPanel data={data?.data} typeData={typeData} />}
            {!dailyMode && selectItem && <RightDetailPanel mapData={data} />}
            {!dailyMode && totalEventMode && <RightEventPanel />}
            {dailyMode && <RightDailyPanel />}

            {panoItem && streetMode && (
                <NaverPanoMap>
                    <PanoControls />
                </NaverPanoMap>
            )}
        </>
        // {/* </div> */}
    );
}
