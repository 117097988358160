import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Link from "../atoms/Link";
//@ts-ignore
import { useAuth } from "../../../context/LoginContext";

interface Props {
  language: string;
  menu: string;
  dataList: Array<any>;
}

const List: React.FC<Props> = ({ language, dataList, menu }: Props) => {
  const location = useLocation();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    redirectIfUnauthorized();
  }, [location.pathname, user.auth, dataList]);

  const redirectIfUnauthorized = () => {
    const unauthorizedData = dataList.find(
      (data) =>
        data.path === location.pathname && user.auth[data.permission] === "N"
    );

    if (unauthorizedData) {
      console.log("Redirecting to /Login");
      navigate("/Map");
    }
  };

  return (
    <ul className="sub-nav-list">
      {dataList
        .filter((data) => user.auth[data.permission] === "Y")
        .map((data, index) => (
          <li className={`list-item`} key={index}>
            <Link
              path={data.path}
              classN={`nav-btn ${data.path === menu ? "on" : ""}`}
            >
              {language === "KOR" ? data.labelKR : data.labelEN}
            </Link>
          </li>
        ))}
    </ul>
  );
};

export default List;
