import { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import axios from "../lib/axios";

export default function useInfinite(
    url,
    size,
    org_idx,
    id,
    from,
    to,
    day,
    isSearch,
    type,
    searchType,
    searchKeyword
) {
    const [data, setData] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [ref, inView] = useInView();
    const [isLoading, setIsLoading] = useState(false);
    const [befrom, setFrom] = useState();
    const [beto, setTo] = useState();
    const [beday, setDay] = useState();
    const [beType, setBeType] = useState();
    const [beKeyword, setBeKeyword] = useState();
    const [selectItem, setSelectItem] = useState();
    const [beSearch, setBeSearch] = useState(false); 
    const page = useRef(0);

    useEffect(() => {
        const fetch = async () => {
            setIsLoading(true);
            setFrom(from);
            setTo(to);
            setDay(day);
            setBeType(searchType);
            setBeKeyword(searchKeyword);
            setBeSearch(isSearch);

            if (url !== null && from !== undefined && to !== undefined) {
                try {
                    const myUrl = `${url}?size=${size}&page=${
                        page.current
                    }&org_idx=${org_idx}${id ? `&unitid=${id}` : ""}${
                        from ? `&from=${from}` : "&from=0"
                    }${to ? `&to=${to}` : "&to=0"}${day ? `&date=${day}` : ""}${
                        type ? `&type=${type}` : ""
                    }${
                        searchType ? `&searchType=${searchType}` : ""
                    }${`&keyword=${searchKeyword}`}
          `;

                    const { data } = await axios.get(myUrl);

                    if (data) {
                        // 데이터가 있는 경우
                        setData(data.lists);
                        setHasNextPage(
                            data.lists.length % size === 0 && !data.isLastPage
                        ); 
                        setTotalCount(data.totalCount);
                        if (data.lists.length > 0) {
                            page.current = page.current + 1;
                        }
                    } else {
                        setHasNextPage(false);
                    }
                    // setIsSearch(false);
                    setIsLoading(false);
                } catch (err) {
                    alert(err);
                    console.error(err);
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
            }
        };
        if (
            beday !== day ||
            befrom !== from ||
            beto !== to ||
            selectItem !== id ||
            beType !== searchType ||
            beKeyword !== searchKeyword ||
            beSearch !== isSearch
        ) {
            page.current = 0;
            setData([]);
            fetch();
        }

        if (inView && hasNextPage && !isLoading) {
            fetch();
        }
    }, [
        hasNextPage,
        inView,
        isLoading,
        size,
        url,
        from,
        to,
        day,
        searchType,
        searchKeyword,
        isSearch,
    ]);

    useEffect(() => {
        setSelectItem(id);
        setHasNextPage(true);
    }, [id]);

    return {
        data,
        hasNextPage,
        isLoading,
        totalCount,
        ref,
        inView,
        page,
    };
}
