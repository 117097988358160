import PropTypes from "prop-types";
export default function AtomLine({ eventList, currentDate }) {
  const eventsOnDate = eventList.filter(
    (event) => event.fromDate <= currentDate && event.toDate >= currentDate
  );

  return (
    <>
      {eventsOnDate.map((event, index) => (
        <div
          key={index}
          className={`event-line ${event.color} ${
            event.fromData === currentDate ? "start" : ""
          } ${event.toDate === currentDate ? "end" : ""} ${
            event.fromDate === currentDate && event.end === currentDate
              ? "single"
              : ""
          }`}
        >
          {" "}
        </div>
      ))}
    </>
  );
}

AtomLine.propTypes = {
  eventList: PropTypes.arrayOf(PropTypes.object),
  currentDate: PropTypes.string,
};
