import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../lib/axios";
//@ts-ignore
import { dataList, subDataListMenuFull } from "../../../Admin/AdmMain";
import CommonControl from "../../../Atomic/organism/CommonControl";
import Page from "../../../Atomic/template/PageTable";
import InputForm from "../../../Atomic/atoms/InputForm";
import Radio from "../../../Atomic/atoms/Radio";
import SubNavi from "../../../Atomic/molecules/SubNavi";
import { DeleteFun } from "../../../Atomic/exFunc";

export default function Basic() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const navi = useNavigate();
  const { user } = useAuth();
  // State defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [rawData, setRawData] = useState<any>({});

  //=======================================================================
  // Function defines
  //=======================================================================

  function inputHandler(e: any) {
    const { name, value } = e.target;
    setRawData((pre: any) => ({ ...pre, [name]: value }));
  }
  function createNew() {
    if (rawData.cd === "") {
      Swal.fire({
        title: `${translate(
          "권한그룹 코드 입력해주세요",
          "Please enter the Code"
        )}`,
      });
      return;
    }
    if (rawData.nm_txt === "") {
      Swal.fire({
        title: `${translate(
          "그룹설명  입력해주세요",
          "Please enter the Name"
        )}`,
      });
      return;
    }
    if (rawData.nm === "") {
      Swal.fire({
        title: `${translate(
          "권한그룹 명 입력해주세요",
          "Please enter the Name"
        )}`,
      });
      return;
    }

    Swal.fire({
      title: `${translate("저장하시겠습니까?", "Do you want to save?")}`,
      showCancelButton: true,
      confirmButtonText: `${translate("예", "Yes")}`,
      cancelButtonText: `${translate("아니오", "No")}`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(`/web/admin/menu/edit`, rawData).then((res: any) => {
          if (res.status === 200) {
            Swal.fire({
              title: `${translate("저장되었습니다", "Saved successfully")}`,
              showConfirmButton: false,
              timer: 1500,
            }).then(() => {
              navi(`/Adm/menu-table`, {
                state: {
                  idx: res.data.idx,
                },
              });
            });
          } else {
            Swal.fire({
              title: `${translate("저장에 실패하였습니다", "Failed to save")}`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
      }
    });
  }

  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        navi('../menu-table')
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "삭제",
      labelEN: "Delete",
      classN: "white",
      icon: true,
      clickFun: () => {
        DeleteFun(`/web/admin/menu/del`, { idx: idx, type: "ADM" }, navi);
      },
      position: " right",
    },
    {
      labelKR: "수정",
      labelEN: "Edit",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
      },
      position: "",
    },
  ];

  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
  }, [location]);
  useEffect(() => {
    if (idx !== "") {
      axios
        .get(
          `/web/admin/menu/detail?idx=${idx}&type=${user.type}`
        )
        .then((res: any) => {
          setRawData(res.data);
          setRawData((pre: any) => ({ ...pre, type: user.type }));
          setIsAdd(false);
        })
        .catch((err: any) => {
          console.log(`Error Link  ${err.config.url}`);
        });
    } else {
      setIsAdd(true);
      setRawData({});
    }
  }, [idx, user.type]);

  // Log defines
  //=======================================================================

  // console.log("rawData", rawData);
  // console.log("state", location.state);
  // console.log("idx", idx);
  // console.log("CompanyData", companyData);
  // console.log("user", user.type);
  // console.log("refId", refId.current?.value);

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "메뉴 권한 관리" : "Menu Auth"}`}
        menu="/Adm/menu-table"
      >
        <SubNavi
          subDataList={subDataListMenuFull}
          language={language}
          navi={navi}
          idx={idx}
          classPath="/Adm/menu-table/basic"
        />
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <InputForm
                label={translate("권한그룹 코드", "Auth Group Code")}
                name="cd"
                val={rawData.cd}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
              <InputForm
                label={translate("권한그룹 명", "Auth Group Name")}
                name="nm"
                val={rawData.nm}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
            </tr>
            <tr>
              <InputForm
                label={translate("그룹설명	", "Group Description")}
                name="nm_txt"
                val={rawData.nm_txt}
                auto="off"
                type="text"
                changeFun={inputHandler}
                required="required"
                readonly={false}
              />
              <th>{translate("접속허가", "Access permission")}</th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"all_yn"}
                    val={"Y"}
                    title={translate("예", "Yes")}
                    change={(e) => inputHandler(e)}
                    check={rawData.all_yn === "Y" ? true : false}
                  />
                  <Radio
                    name={"all_yn"}
                    val={"N"}
                    title={translate("아니오", "Not use")}
                    change={(e) => inputHandler(e)}
                    check={rawData.all_yn === "N" ? true : false}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
