import { React, useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../lib/axios";
import "tui-grid/dist/tui-grid.css";
import { LanguageContext } from "../../context/LanguageContext.jsx";
import { useAuth } from "../../context/LoginContext";
import AppHeader from "../../components/include/AppHeader";
import AppFooter from "../../components/include/AppHeader";
import Table from "../../components/Atomic/organism/AtomTable.jsx";
import CommonControl from "../../components/Atomic/organism/CommonControl";
import Form from "../../components/Atomic/organism/FormAtom";
import { add_hyphen } from "../../lib/common_func";
export default function Lost() {
  const { language } = useContext(LanguageContext);
  const { user } = useAuth();
  const inputRef = useRef();
  const navigate = useNavigate();
  // State definition
  //==================================================================
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState("");
  // function definition
  //==================================================================
  async function submitFun(e) {
    e.preventDefault();
    await getData();
  }
  async function getData() {
    await axios
      .get(`/web/longterm/list?org_idx=${user.org_idx}&type=${user.type}`)
      .then((res) => {
        if (res.status === 200) {
          setRawData(res.data);
          return;
        }
        if (res.status === 202) {
          setRawData([]);
          return;
        }
      });
  }
  function inputChnage(key) {
    // setKeyword(key);
    onChangeFilter({ target: { value: key } });
  }
  function onChangeFilter(e) {
    const searchText = e.target.value;
    setKeyword(searchText);
    if (searchText === "") {
      setData(rawData);
      return;
    }
    filterData(searchText);
  }
  function filterData(searchText) {
    if (searchText === "") {
      setData(rawData);
    } else {
      const filteredData = rawData.filter((item) => {
        for (const key in item) {
          if (item.hasOwnProperty(key) && typeof item[key] === "string") {
            if (item[key].toLowerCase().includes(searchText.toLowerCase())) {
              return true;
            }
          }
        }
        return false;
      });
      setData(filteredData);
    }
  }
  const move = (e) => {
    console.log(e);
  };
  // UseEffect definition
  //==================================================================
  useEffect(() => {
    axios
      .get(`/web/longterm/list?org_idx=${user.org_idx}&type=${user.type}`)
      .then((res) => {
        setRawData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);
  useEffect(() => {
    if (rawData) {
      setData(rawData);
    }
  }, [rawData]);
  // Data definition
  //==================================================================
  const columns = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 50,
      align: "center",
      formatter: (name) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "회사명" : "Company name",
      name: "cmp_nm",
      resizable: false,
      align: "center",
    },
    {
      header: language === "KOR" ? "단말번호" : "Device number",
      name: "unitid",
      resizable: false,
      formatter: (item) => add_hyphen(item.value, language),
    },
    {
      header: language === "KOR" ? "단말기명" : "Device name",
      name: "unit_nm",
      resizable: false,
    },
    {
      header: language === "KOR" ? "지점명" : "Branch",
      name: "brc_nm",
      resizable: false,
    },
    {
      header: language === "KOR" ? "대리점명" : "Agent",
      name: "agt_nm",
      resizable: false,
      className: "bold",
    },
    {
      header: language === "KOR" ? "최종수신일시" : "Last reported",
      name: "last_makedate",
      resizable: false,
      sortable: true,
    },
    {
      header: language === "KOR" ? "수신일시차이" : "Unattended time",
      name: "gps_time",
      resizable: false,
    },
  ];
  const buttonData = [];
  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e) => inputChnage(e),
    myref: inputRef,
    //option: (),
    value: keyword,
  };
  // console.log(rawData);
  // console.log(data);
  if (user.auth.H000 === "N") {
    navigate("/Map");
  }
  return (
    <>
      <AppHeader pages="8" />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <nav className="sub-nav">
              <ul className="sub-nav-list">
                <li className="list-item">
                  <Link to="/Lost" className="nav-btn on">
                    {language === "KOR" ? "미관제조회" : "Unreported"}
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "미관제조회" : "Unreported"}
                </div>
              </div>
              <Form formData={formData} inputRef={inputRef} />
              <Table data={data} columns={columns} move={move} />
              <CommonControl buttonData={buttonData} language={language} />
            </div>
          </div>
        </div>
      </div>
      <AppFooter />
    </>
  );
}
