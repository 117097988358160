import React, { useState, useEffect } from "react";
//@ts-ignore
import { LanguageContext } from "../../../../context/LanguageContext.jsx";
//@ts-ignore
import { dataList } from "../../../Admin/AdmMain";
import Page from "../../../Atomic/template/PageTable";
//@ts-ignore
import { add_hyphen } from "../../../../lib/common_func.js";

//@ts-ignore
import axios from "../../../../lib/axios";
//@ts-ignore
import { useAuth } from "../../../../context/LoginContext.jsx";
//@ts-ignore
import TableAtom from "../../../Atomic/organism/AtomTable";
import { filterData, inputChnage } from "../../../Atomic/exFunc";
import CommonControl from "../../../Atomic/organism/CommonControl";
import Form from "../../../Atomic/organism/FormAtom";
import Select from "../../../Atomic/molecules/Select";
import moment from "moment";
//@ts-ignore
export default function Table() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);

  const [rawData, setRawData] = useState<any>([]);
  const { user } = useAuth();
  const inputRef = React.createRef<HTMLInputElement>();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState<any>([]);
  const [date, setDate] = useState<any>(moment().format("YYYY-MM"));
  const [year, setYear] = useState<any>(moment().format("YYYY"));
  const [month, setMonth] = useState<any>(moment().format("MM"));
  // Function defines
  //===============================================================

  function move(e: any) {
    return;
  }

  function submitFun(e: any) {
    e.preventDefault();
    filterData(rawData, keyword, setData);
  }

  // Data define
  //===============================================================

  const columns: any = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },
    {
      header: language === "KOR" ? "회사명" : "Company Name",
      name: "cmp_nm",
      align: "center",
    },
    {
      header: language === "KOR" ? "지점명" : "Branch Name",
      name: "brc_nm",
      align: "center",
    },
    {
      header: language === "KOR" ? "대리점명" : "Agency Name",
      name: "agt_nm",
      align: "center",
    },
    {
      header: language === "KOR" ? "단말기명" : "Device Name",
      name: "unit_nm",
      align: "center",
    },
    {
      header: language === "KOR" ? "제품명" : "Product Name",
      name: "prod_nm",
      align: "center",
    },
    {
      header: language === "KOR" ? "운행일수" : "Driving Days",
      name: "date_count",
      align: "center",
      sortable: true,
    },
    {
      header: language === "KOR" ? "통신사" : "Telecom",
      name: "make_cp",
      align: "center",
    },
    {
      header: language === "KOR" ? "디바이스번호" : "Device Number",
      name: "unitid",
      align: "center",
      formatter: (name: any) => (name.value ? add_hyphen(name.value) : "-"),
    },
  ];

  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e: any) => inputChnage(e, rawData, setData, setKeyword),
    myref: inputRef,
    option: (
      <>
        <Select
          id="keywordType"
          short="N"
          name="keywordType"
          options={[
            { name: "2024", value: "2024" },
            { name: "2023", value: "2023" },
          ]}
          changeFun={(e: any) => {
            setYear(e.target.value);
          }}
          def={year}
        />
        <Select
          id="keywordType"
          short="N"
          name="keywordType"
          options={[
            { name: "1월", value: "01" },
            { name: "2월", value: "02" },
            { name: "3월", value: "03" },
            { name: "4월", value: "04" },
            { name: "5월", value: "05" },
            { name: "6월", value: "06" },
            { name: "7월", value: "07" },
            { name: "8월", value: "08" },
            { name: "9월", value: "09" },
            { name: "10월", value: "10" },
            { name: "11월", value: "11" },
            { name: "12월", value: "12" },
          ]}
          changeFun={(e: any) => {
            setMonth(e.target.value);
          }}
          def={month}
        />
      </>
    ), // Make option undefined
    value: keyword,
  };

  const buttonData = [
    {
      labelKR: "전체목록",
      labelEN: "View All",
      classN: "white",
      clickFun: () => {
        setKeyword("");
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
  ];
  // useEffect define
  //===============================================================

  useEffect(() => {
    axios
      .get(
        `/web/admin/drive/log?type=${user.type}&date=${date}`
      )
      .then((res: any) => {
        // console.log("res.data", res.data);
        setRawData(res.data.reverse());
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [user, date]);

  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
      filterData(rawData, keyword, setData);
    }
  }, [rawData, keyword]);

  useEffect(() => {
    setDate(`${year}-${month}`);
  }, [year, month]);
  // logs define
  //===============================================================
  // console.log("rawData", rawData);
  // console.log(date);

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "메뉴 권한 관리" : "Menu Auth"}`}
        menu="/Adm/log-table"
      >
        <Form formData={formData} inputRef={inputRef} />
        <TableAtom data={data} columns={columns} move={move} />
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
