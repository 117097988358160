import React, { useState } from "react";
import { event } from "../../../lib/eventData";
import moment from "moment";
import { add_hyphen } from "../../../lib/common_func";
import { useLang } from "../../../context/LanguageContext";
import EventTableExcel from "./EventTableExcel";
export default function EventTable({ data, excelData, tableRef, excelRef, excelLoading, viewRef, hasNext }) {
  const { language } = useLang();
  
  const [column, setcolumn] = useState({
    no:{key:'no',ko:'번호',eng:'No',width:'90px'},
    unit_nm:{key:'unit_nm',ko:'단말기명',eng:'Device Name',width:'200px'},
    unitid:{key:'unitid',ko:'단말번호',eng:'USIM num',width:'200px'},
    time:{key:'time',ko:'시간',eng:'Time',width:'200px'},
    event:{key:'event',ko:'이벤트명',eng:'Event name',width:'230px'},
    addr:{key:'address',ko:'주소',eng:'Address'},
  })

  return (
    <>
      <div
        ref={tableRef}
        className="common-table-wrapper scroll-table"
        style={{ maxHeight: "33em" }}
      >
        <table className="common-table">
          <thead>
            <tr>
              {Object.values(column).map((item, index) => {
                return (
                <th key={index} style={{width:(item.width || 'auto')}}>
                  {language === "KOR" ? item.ko : item.eng}
                </th>)
              })}
            </tr>
          </thead>
          <tbody>
            {(!data || excelLoading) ? (
              <tr>
                <td colSpan={Object.values(column).length}>
                  <div className="empty">
                      {language === "KOR"
                        ? "로딩 중입니다."
                        : "Loading..."}
                    </div>
                </td>
              </tr>
            ) :
            data.length === 0 ? (
              <tr>
                <td colSpan={Object.values(column).length}>
                  <div className="empty">
                    {language === "KOR"
                      ? "검색된 데이터가 없습니다."
                      : "No data."}
                  </div>
                </td>
              </tr>
            ) : (
              data && (
                <>
                  {
                    data.map((item, index) => {
                      const { row, unit_nm, unitid, date, event_code, addr } = item;
                      return (
                        <tr key={index}>
                          <td>{row}</td>
                          <td>{unit_nm}</td>
                          <td>{add_hyphen(unitid, language)}</td>
                          <td>{moment.utc(date).format("YYYY-MM-DD HH:mm")}</td>
                          <td>{event(event_code, language)}</td>
                          <td>{addr}</td>
                        </tr>
                      );
                    })
                  }
                  <div ref={viewRef} style={{height:'3px'}}></div>
                </>
              )
            )}
          </tbody>
        </table>
        <EventTableExcel data={excelData} excelRef={excelRef}/>
      </div>
    </>
  );
}
