import React, { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import {
  ISODateToDateTime,
  add_hyphen,
  eventcodeENG,
  eventcodeKOR,
  eventcodeStatus,
  gpsStatusENG,
  gpsStatusKOR,
} from "../../lib/common_func";
import { useLang } from "../../context/LanguageContext";
import { useMap } from "../../context/MapContext";
import axios from "axios";
import { code, event } from "../../lib/eventData";

export default function RecordItem({
  item,
  selecData,
  total,
  state,
  close,
}) {
  const { language } = useLang();
  const { mapState, mapDispatch } = useMap();
  const { selectPathItem, pathMode, selectItem } = mapState;
  const {
    row,
    idx,
    event_code,
    speed,
    makedate,
    gps_sv,
    inner_battery,
    vehicle_battery,
  } = item;
  
  const [addr, setAddr] = useState("");
  const handleItem = () => {
    if (state.state && state.idx === idx) {
      close();
    } else {
      mapDispatch({ type: "SET_SELECT_PATH_ITEM", payload: {...item, addr: addr} });
    }
  };

  useEffect(() => {
    const getAddress = async () => {
      if (item.latitude) {
        const position = {
          latitude: item.latitude,
          longitude: item.longitude,
          totalCount: total,
          row: item.row,
        };

        const addr = await axios.post(`/web/api/addr`, position);

        setAddr(addr.data.addr);
      }
    };
    if (addr === "") {
      getAddress();
    }
  }, [item]);

  return (
    <>
      <li key={v4()}>
        <div
          className={`card ${state.state && state.idx == idx ? "on" : ""} ${pathMode? 'path' : ''}`}
          data-num={idx}
          data-lat="35.1234"
          data-lng="129.1234"
          onClick={() => handleItem()}
        >
          <div className={"card-status " + (code.indexOf(event_code) === -1 ? 'event' : eventcodeStatus(event_code, speed))}>
            <div className="top">
              <div className="item-num">{row}</div>
              <div className="item-status">
                {code.indexOf(event_code) === -1 ? 
                  (language === "KOR"
                    ? '이벤트 발생'
                    : 'Event'
                  ) :
                  (language === "KOR"
                    ? eventcodeKOR(event_code, speed)
                    : eventcodeENG(event_code, speed)
                  )
                }
              </div>
              <div className="item-time">{ISODateToDateTime(makedate)}</div>
            </div>
            <div className="bottom">
              <div className="item-speed">{speed}km/h</div>
              <div className="item-signal">
                {language === "KOR"
                  ? gpsStatusKOR(parseInt(gps_sv))
                  : gpsStatusENG(parseInt(gps_sv))}
              </div>
              <div className="item-addr">{addr}</div>
            </div>
          </div>
          <div className="card-detail">            
            <div className="row">
              <div className="item-label">
                {language === "KOR" ? "GPS위성개수" : "GPS Count"}
              </div>
              <div className="item-content">
                {parseInt(gps_sv)}
                {language === "KOR" ? "개" : ""}
              </div>
            </div>            
            <div className="row">
              <div className="item-label">
                {language === "KOR" ? "단말전압" : "Device Battery"}
              </div>
              <div className="item-content">{(inner_battery / 10).toFixed(1)}v</div>
            </div>
            {selecData?.wire === "Y" && (
              <div className="row">
                <div className="item-label">
                  {language === "KOR" ? "차량전압" : "Vehicle Battery"}
                </div>
                <div className="item-content">{(vehicle_battery / 10).toFixed(1)}v</div>
              </div>
            )}
            {selecData && (code.indexOf(item.event_code) === -1) && (
              <div className="row">
                <div className="item-label">
                  {language === 'KOR' ? '이벤트' : 'Event'}
                </div>
                <div className="item-content">
                  {event(item.event_code, language)}
                </div>
              </div>)
            }
            {selecData?.bt_yn === 'Y' && (
              <div className="row">
                <div className="item-label">
                  Bluetooth
                </div>
                <div className="item-content">
                  {item?.bt_nm || (language === 'KOR' ? '블루투스 사용자 없음' : 'No Access')}
                </div>
              </div>)
            }            
          </div>
        </div>
      </li>
    </>
  );
}