import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../../lib/axios.js";
//@ts-ignore
import { dataList } from "../../../AdmMain";
import CommonControl from "../../../../Atomic/organism/CommonControl";
import Page from "../../../../Atomic/template/PageTable";
import InputForm from "../../../../Atomic/atoms/InputForm";
import InputGroup from "../../../../Atomic/molecules/InputGroup";
// @ts-ignore
import { add_hyphen } from "../../../../../lib/common_func.js";
// @ts-ignore
import Select from "../../../../Atomic/molecules/Select";
export default function Control() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const navi = useNavigate();
  const { user } = useAuth();

  // State defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const [rawData, setRawData] = useState<any>({
    msi_f: "118.67.143.32",
    msi_t: "81",
    mbl_f: "0",
  });
  const [unitId, setUnitId] = useState<any>("");

  // Function defines
  //=======================================================================
  function reset() {
    Swal.fire({
      title: `${translate(
        "시스템 리셋을 하시겠습니까?",
        "Do you want to reset the system?"
      )}`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post("/web/admin/terminal/control", {
            idx: user.idx,
            command: "RST",
            unitid: unitId,
            msg_f: "",
            msg_s: "",
            type: user.type,
          })
          .then((res: any) => {
            if (res.status === 200) {
              Swal.fire({
                title: "Success",
                text: `${translate("전송되었습니다.", "Sent.")}`,
              });
            } else {
              Swal.fire({
                title: "Error",
                text: `${translate(
                  "전송에 실패하였습니다.",
                  "Failed to send."
                )}`,
              });
            }
          });
      }
    });
  }

  function inputHandler(e: any) {
    const { name, value } = e.target;
    setRawData((pre: any) => ({ ...pre, [name]: value }));
  }
  function commnadHandler(
    idx: number,
    command: string,
    unitId: string,
    msg_f: string,
    msg_s: string
  ) {
    axios
      .post("/web/admin/terminal/control", {
        idx: idx,
        command: command,
        unitid: unitId,
        msg_f: msg_f,
        msg_s: msg_s,
        type: user.type,
      })
      .then((res: any) => {
        if (res.status === 200) {
          Swal.fire({
            title: "Success",
            text: `${translate("전송되었습니다.", "Sent.")}`,
          });
        } else {
          Swal.fire({
            title: "Error",
            text: `${translate("전송에 실패하였습니다.", "Failed to send.")}`,
          });
        }
      });
  }
  function onClickHandler(command: string, msg_f: string, msg_s: string) {
    Swal.fire({
      title: translate("전송하시겠습니까?", "Do you want to send?"),
      showCancelButton: true,
      confirmButtonText: translate("전송", "Send"),
      cancelButtonText: translate("취소", "Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        commnadHandler(user.idx, command, unitId, msg_f, msg_s);
      }
    });
  }
  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        navi('../dev-table')
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
  ];
  // UseEffect defines
  //=======================================================================
  useEffect(() => {
    if (location.state) {
      setUnitId(location.state.unitid);
    }
  }, [location.state]);

  // Log defines
  //=======================================================================

  // console.log("unitId", user.idx);
  // console.log("unitId", unitId);
  // console.log("rawData", rawData);
  // console.log("prodList", prodList);
  // console.log("state", location.state);
  // console.log("idx", idx);
  // console.log("CompanyData", companyData);
  // console.log("user", user.type);
  // console.log("refId", refId.current?.value);

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "단말기 관리" : "Gadget management"}`}
        menu="/Adm/dev-table"
      >
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <InputForm
                required={""}
                label={translate("단말기 ID", "Device ID")}
                auto="none"
                type="text"
                val={add_hyphen(unitId) || ""}
                changeFun={inputHandler}
                name="unitid"
                readonly={true}
              />
              <InputGroup
                title={translate("서버/ IP/ PORT", "Server/ IP/ PORT")}
                btnName="전송"
                changeFunClick={() => {
                  commnadHandler(
                    user.idx,
                    "MSI",
                    unitId,
                    rawData.msi_f,
                    rawData.msi_t
                  );
                }}
                inputs={[
                  {
                    changeFun: (e: any) => inputHandler(e),
                    read: false,
                    val: rawData.msi_f || "",
                    auto: "none",
                    type: "text",
                    name: "msi_f",
                    place: "IP",
                    valType: "",
                  },
                  {
                    changeFun: (e: any) => inputHandler(e),
                    read: false,
                    val: rawData.msi_t || "",
                    name: "msi_t",
                    place: translate("포트", "Port"),
                    auto: "none",
                    type: "text",
                  },
                ]}
              />
            </tr>
            <tr>
              <InputGroup
                title={translate("주기보고", "Periodic reporting")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("MIT", rawData.mit_f, rawData.mit_s);
                }}
                inputs={[
                  {
                    changeFun: (e: any) => inputHandler(e),
                    read: false,
                    val: rawData.mit_f || "",
                    name: "mit_f",
                    place: translate("30", "Time"),
                    valType: "초",
                    auto: "none",
                    type: "text",
                  },
                  {
                    changeFun: (e: any) => inputHandler(e),
                    read: false,
                    val: rawData.mit_s || "",
                    name: "mit_s",
                    place: translate("1", "Time"),
                    valType: "번",
                    auto: "none",
                    type: "text",
                  },
                ]}
              />
              <InputGroup
                title={translate("거리설정(m)", "Distance setting(m)")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("MIO", rawData.mio_f, "");
                }}
                inputs={[
                  {
                    changeFun: (e: any) => inputHandler(e),
                    read: false,
                    val: rawData.mio_f || "",
                    name: "mio_f",
                    place: translate("1000", "1000"),
                    valType: "m",
                    auto: "none",
                    type: "text",
                  },
                ]}
              />
            </tr>
            <tr>
              <InputGroup
                title={translate("제한속도", "Speed limit")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("SPD", rawData.spd_f, "");
                }}
                inputs={[
                  {
                    changeFun: (e: any) => inputHandler(e),
                    read: false,
                    val: rawData.spd_f || "",
                    name: "spd_f",
                    place: "60",
                    valType: "km/h",
                    auto: "none",
                    type: "text",
                  },
                ]}
              />
              <InputGroup
                title={translate("차량 전원 모드", "Vehicle power mode")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("MBL", rawData.mbl_f, rawData.mbl_t);
                }}
                inputs={[
                  {
                    changeFun: (e: any) => inputHandler(e),
                    read: false,
                    val: rawData.mbl_t || "",
                    name: "mbl_t",
                    place: translate("300", "300"),
                    valType: "V",
                    auto: "none",
                    type: "text",
                  },
                ]}
              >
                <Select
                  name="mbl_f"
                  changeFun={(e) => inputHandler(e)}
                  id="mbl_f"
                  short="N"
                  options={[
                    { name: "-----", value: "-----" },
                    { name: "12V", value: 0 },
                    { name: "24V", value: 1 },
                  ]}
                  def={rawData.mbl_f || ""}
                />
              </InputGroup>
            </tr>
            <tr>
              <InputGroup
                title={translate("시동보고", "Ignition report")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("ACC", rawData.acc_f, rawData.acc_t);
                }}
                inputs={[
                  {
                    changeFun: (e: any) => inputHandler(e),
                    read: false,
                    val: rawData.acc_t || "",
                    name: "acc_t",
                    place: translate("", ""),
                    valType: "",
                    auto: "none",
                    type: "text",
                  },
                ]}
              >
                <Select
                  name="acc_f"
                  changeFun={(e) => inputHandler(e)}
                  id="acc_f"
                  short="N"
                  options={[
                    { name: "-----", value: "-----" },
                    { name: "시동 OFF 경우 단말기 Power OFF", value: 0 },
                    {
                      name: "시동 OFF 시에 Interval Time 주기로 보고",
                      value: 1,
                    },
                    {
                      name: "시동 OFF 시에 단말기 Sleep",
                      value: 2,
                    },
                  ]}
                  def={rawData.acc_f || ""}
                />
              </InputGroup>
              <th>{translate("지오펜스 초기화", "Geofance Init")}</th>
              <td>
                <div className="flex">
                  <div className="custom-input">
                    <button
                      className="common-btn purple sm"
                      onClick={() => {
                        onClickHandler("GIO", "", "");
                      }}
                      value={`${rawData.gfi_f}`}
                      type="button"
                      name="gfi_f"
                    >
                      <span>전송</span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <InputGroup
                title={translate("정차경보", "Parking alarm")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("CSS", rawData.css_f, "");
                }}
                inputs={[
                  {
                    changeFun: (e: any) => inputHandler(e),
                    read: false,
                    val: rawData.css_f || "",
                    name: "css_f",
                    place: "30",
                    valType: "초",
                    auto: "none",
                    type: "text",
                  },
                ]}
              ></InputGroup>
              <InputGroup
                title={translate("주차경보", "Parking alarm")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("CPS", rawData.cps_f, "");
                }}
                inputs={[
                  {
                    changeFun: (e: any) => inputHandler(e),
                    read: false,
                    val: rawData.cps_f || "",
                    name: "cps_f",
                    place: translate("1000", "1000"),
                    valType: "초",
                    auto: "none",
                    type: "text",
                  },
                ]}
              ></InputGroup>
            </tr>
            <tr>
              <InputGroup
                title={translate("재시동", " Restart")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("OP1", rawData.op1_f, "");
                }}
                inputs={[]}
              >
                <Select
                  name="op1_f"
                  changeFun={(e) => inputHandler(e)}
                  id="op1_f"
                  short="N"
                  options={[
                    { name: "-----", value: "-----" },
                    { name: "Off", value: 0 },
                    {
                      name: "On",
                      value: 1,
                    },
                  ]}
                  def={rawData.op1_f || ""}
                />
              </InputGroup>
              <InputGroup
                title={translate("Door Lock", "Door Lock")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("OP2", rawData.op2_f, "");
                }}
                inputs={[]}
              >
                <Select
                  name="op2_f"
                  changeFun={(e) => inputHandler(e)}
                  id="op2_f"
                  short="N"
                  options={[
                    { name: "-----", value: "-----" },
                    { name: "Off", value: 0 },
                    {
                      name: "On",
                      value: 1,
                    },
                  ]}
                  def={rawData.op2_f || ""}
                />
              </InputGroup>
            </tr>
            <tr>
              <InputGroup
                title={translate("Door Unlock	", "Door Unlock	")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("OP3", rawData.op3_f, "");
                }}
                inputs={[]}
              >
                <Select
                  name="op3_f"
                  changeFun={(e) => inputHandler(e)}
                  id="op3_f"
                  short="N"
                  options={[
                    { name: "-----", value: "-----" },
                    { name: "Off", value: 0 },
                    {
                      name: "On",
                      value: 1,
                    },
                  ]}
                  def={rawData.op3_f || ""}
                />
              </InputGroup>
              <InputGroup
                title={translate("Door 모드	", "Door Mode	")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("VDM", rawData.vdm_f, rawData.vdm_t);
                }}
                inputs={[]}
              >
                <Select
                  name="vdm_f"
                  changeFun={(e) => inputHandler(e)}
                  id="vdm_f"
                  short="N"
                  options={[
                    { name: "-----", value: "-----" },
                    { name: "Off", value: 0 },
                    {
                      name: "On",
                      value: 1,
                    },
                  ]}
                  def={rawData.vdm_f || ""}
                />
                <Select
                  name="vdm_t"
                  changeFun={(e) => inputHandler(e)}
                  id="vdm_t"
                  short="N"
                  options={[
                    { name: "-----", value: "-----" },
                    { name: "Low", value: 0 },
                    {
                      name: "High",
                      value: 1,
                    },
                  ]}
                  def={rawData.vdm_t || ""}
                />
              </InputGroup>
            </tr>
            <tr>
              <InputGroup
                title={translate("즉시보고", "Immediate reporting")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("CPO", rawData.cpo_f, rawData.cpo_t);
                }}
                inputs={[
                  {
                    changeFun: (e: any) => inputHandler(e),
                    read: false,
                    val: rawData.cpo_f || "",
                    name: "cpo_f",
                    place: translate("20", "20"),
                    valType: "번 ",
                    auto: "none",
                    type: "text",
                  },
                  {
                    changeFun: (e: any) => inputHandler(e),
                    read: false,
                    val: rawData.cpo_t || "",
                    name: "cpo_t",
                    place: translate("1", "1"),
                    valType: "초",
                    auto: "none",
                    type: "text",
                  },
                ]}
              ></InputGroup>
              <InputGroup
                title={translate("저장데이터 삭제	", "Delete saved data")}
                btnName="전송"
                changeFunClick={() => {
                  onClickHandler("DEL", rawData.del_f, "");
                }}
                inputs={[]}
              >
                <Select
                  name="del_f"
                  changeFun={(e) => inputHandler(e)}
                  id="del_f"
                  short="N"
                  options={[
                    { name: "-----", value: "-----" },
                    { name: "삭제", value: 1 },
                  ]}
                  def={rawData.del_f || ""}
                />
              </InputGroup>
            </tr>
            <tr>
              <th>{translate("시스템 리셋	", "Resetting the system")}</th>
              <td>
                <div className="flex">
                  <div className="custom-input">
                    <button
                      className="common-btn purple sm"
                      onClick={() => {
                        reset();
                      }}
                      type="button"
                      name="gfi_f"
                    >
                      <span>전송</span>
                    </button>
                  </div>
                </div>
              </td>
              <th>{translate("푸쉬 이벤트 테스트", "Push Event Test")}</th>
              <td>
                <div className="flex">
                  <div className="custom-input">
                    <button
                      className="common-btn purple sm"
                      onClick={() => {
                        onClickHandler("PSH", "", "");
                      }}
                      type="button"
                      name="gfi_f"
                    >
                      <span>전송</span>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
