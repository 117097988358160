import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
// @ts-ignore
import { useAuth } from "../../../../../context/LoginContext.jsx";
//@ts-ignore
import { LanguageContext } from "../../../../../context/LanguageContext.jsx";
//@ts-ignore
import axios from "../../../../../lib/axios.js";
//@ts-ignore
import { dataList } from "../../../AdmMain";
import CommonControl from "../../../../Atomic/organism/CommonControl";
import Page from "../../../../Atomic/template/PageTable";
// @ts-ignore
import Select from "../../../../Atomic/molecules/Select";
import Radio from "../../../../Atomic/atoms/Radio";
export default function Form() {
  //@ts-ignore
  const { language } = React.useContext(LanguageContext);
  const location = useLocation();
  const [idx, setIdx] = useState<any>("");
  const navi = useNavigate();
  const { user } = useAuth();
  const liStyle = {
    padding: "10px",
    width: "100%",
    cursor: "pointer",
    borderBottom: "1px solid gray",
  };
  // State defines
  //=======================================================================
  function translate(kr: string, en: string) {
    return language === "KOR" ? kr : en;
  }
  //=======================================================================
  const [rawData, setRawData] = useState<any>({
    idx: user.idx,
    act_yn: "Y",
    buy: "",
    editdate: null,
    icon: "차량",
    icon_code: "IC01",
    kind: 1,
    make_cp: "LG",
    make_uname: "",
    makedate: "",
    no_view: 72,
    org_nm: "",
    org_type: "",
    prod_legal_nm: "",
    term: "3년",
    unit_nm: "",
    unit_sn: "",
    type: user.type,
    matter: "정상",
  });
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [CPN, setCPN] = useState<any>("");
  const [CPNData, setCPNData] = useState<any>([]);
  const [isChanged, setIsChanged] = useState<boolean>(true);
  const [companyData, setCompanyData] = useState<any>([]);
  const [prodList, setProdList] = useState<any>([]);
  const [itemList, setItemList] = useState<any>([
    {
      [`unitid`]: "",
      [`unit_sn`]: "",
      [`unit_nm`]: "",
      idx: 0,
    },
  ]);
  const [classList, setClassList] = useState<any>();
  const [deviceValid, SetDeviceValid] = useState<boolean>(false);
  const prodListVar = [
    {
      name: translate("단말기 종류", "Device type"),
      value: "",
    },
    ...prodList.map((item: any) => ({
      name: item.prod_legal_nm,
      value: item.idx,
    })),
  ];

  // Function defines
  //=======================================================================
  function addNew() {
    SetDeviceValid(false);
    setItemList((prev: any) => {
      const lastIdx = prev.length > 0 ? prev[prev.length - 1].idx : 0;
      const newIndex = lastIdx + 1;
      return [
        ...prev,
        {
          [`unitid`]: "",
          [`unit_sn`]: "",
          [`unit_nm`]: "",
          idx: newIndex,
        },
      ];
    });
  }

  function lengthCheck() {
    const uniqueUnitIds = new Set();
    let hasError = false;

    itemList.forEach((item: any) => {
      if (item.unitid.length !== 11) {
        Swal.fire({
          title: `${translate(
            "단말기 번호는 11자리 입니다.",
            "The device number is 11 digits."
          )}`,
        });
        SetDeviceValid(false);
        hasError = true;
        return;
      }
      if (item.unit_sn === "") {
        Swal.fire({
          title: `${translate(
            "S/N 번호를 입력해주세요.",
            "Please enter the S/N number."
          )}`,
        });
        SetDeviceValid(false);
        hasError = true;
        return;
      }
      if (uniqueUnitIds.has(item.unitid)) {
        Swal.fire({
          title: `${translate(
            "단말기 번호가 중복되었습니다.",
            "The device number is duplicated."
          )}`,
        });
        SetDeviceValid(false);
        hasError = true;
        return;
      }

      uniqueUnitIds.add(item.unitid);
    });

    if (!hasError) {
      SetDeviceValid(true);
    }
  }

  //=======================================================================
  function inputHandlerList(idx: number, name: string, value: string) {
    setItemList((prev: any) => {
      const updatedData = prev.map((item: any) => {
        if (item.idx === idx) {
          return { ...item, [name]: value };
        }
        return item;
      });
      return updatedData;
    });
    SetDeviceValid(false);
  }
  function inputHandlerListSN(
    idx: number,
    nameA: string,
    nameB: string,
    value: string
  ) {
    setItemList((prev: any) => {
      const updatedData = prev.map((item: any) => {
        if (item.idx === idx) {
          return { ...item, [nameA]: value, [nameB]: value };
        }
        return item;
      });
      return updatedData;
    });
  }

  function inputHandler(e: any) {
    const { name, value } = e.target;

    if (name === "kind" || name === "no_view") {
      setRawData((pre: any) => ({ ...pre, [name]: parseInt(value) }));
      return;
    }

    setRawData((pre: any) => ({ ...pre, [name]: value }));
  }
  function createNew() {
    console.log("click");

    if (CPN === "") {
      Swal.fire({
        title: `${translate(
          "회사/지점/대리점명	 선택해주세요.",
          "Please select a company/branch/agency name."
        )}`,
      });
      return;
    }
    if (rawData.org_nm === "") {
      Swal.fire({
        title: `${translate(
          "회사/지점/대리점명	 선택해주세요.",
          "Please select a company/branch/agency name."
        )}`,
      });
      return;
    }
    if (rawData.prod_legal_nm === "") {
      Swal.fire({
        title: `${translate(
          "단말기 종류를 선택해주세요.",
          "Please select a device type."
        )}`,
      });
      return;
    }
    if (rawData.no_view === "") {
      Swal.fire({
        title: `${translate(
          "미관제 설정 시간을 선택해주세요.",
          "Please select a uncontrolled setting time."
        )}`,
      });
      return;
    }

    console.log("click 2");

    Swal.fire({
      title: `${translate(
        "단말기를 추가하시겠습니까?",
        "Do you want to add a device?"
      )}`,
      showCancelButton: true,
      confirmButtonText: `${translate("확인", "Confirm")}`,
      cancelButtonText: `${translate("취소", "Cancel")}`,
    }).then((result: any) => {
      if (result.isConfirmed) {
        axios
          .post(`/web/admin/terminal/add`, rawData)
          .then((res: any) => {
            if (res.status === 200) {
              Swal.fire({
                title: `${translate(
                  "단말기가 추가되었습니다.",
                  "The device has been added."
                )}`,
                icon: "success",
                confirmButtonText: `${translate("확인", "Confirm")}`,
              }).then(() => {
                navi("/Adm/dev-table");
              });
            } else if (
              res.status !== 200 &&
              res?.data?.result === "duplication"
            ) {
              Swal.fire({
                title: `${translate(
                  "중복된 단말정보가 있습니다.",
                  "There is duplicate terminal information."
                )}`,
                icon: "error",
                confirmButtonText: `${translate("확인", "Confirm")}`,
              });
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    });
  }
  function onChangeFilter(e: any) {
    setCPN(e.target.value);
    handleFilterChange(e);
  }
  function handleFilterChange(e: any) {
    const searchText = e.target.value;

    if (searchText === "") {
      setCPNData([]);
      return;
    }
    const filteredData = companyData.filter((item: any) => {
      return ((item.cmp_nm && item.cmp_nm.toLowerCase().includes(searchText.toLowerCase()))
              || (item.brc_nm && item.brc_nm.toLowerCase().includes(searchText.toLowerCase()))
              || (item.agt_nm && item.agt_nm.toLowerCase().includes(searchText.toLowerCase()))
            );
    });

    setCPNData(filteredData);
  }
  function editCPN(e: any) {
    if (!isReadOnly) {
      setIsChanged(true);
    }
  }
  function clearinput() {
    setIsReadOnly(false);
    setCPN("");
    setCPNData([]);
  }
  function deleteItem(index: number) {
    setItemList((prev: any) => {
      return prev.filter((item: any) => item.idx !== index);
    });
  }

  // Example usage:
  // deleteItem(indexToDelete);
  // console.log("itemList", itemList);

  // Data definition
  //=======================================================================
  const buttonData = [
    {
      labelKR: "목록",
      labelEN: "List",
      classN: "purple",
      clickFun: () => {
        window.history.back();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },

    {
      labelKR: "등록",
      labelEN: "Create",
      classN: "purple",
      icon: true,
      clickFun: () => {
        createNew();
        lengthCheck();
      },
      position: "",
    },
  ];
  const addButton = [
    {
      labelKR: "추가",
      labelEN: "Add",
      classN: "white",
      clickFun: () => {
        addNew();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
  ];
  // UseEffect defines
  //=======================================================================

  useEffect(() => {
    if (location.state !== null) {
      setIdx(location.state.idx);
    }
    axios.get(`/web/admin/prod/list?type=${user.type}`).then((res: any) => {
      setProdList(res.data);
    });
  }, [location, user]);

  useEffect(() => {
    function getCompanyData() {
      axios
        .get(`/web/admin/org/name?type=ADM`)
        .then((res: any) => {
          setCompanyData(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    function getClassList() {
      axios
        .get(`/web/admin/terminal/category?type=ADM`)
        .then((res: any) => {
          setClassList(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }

    getClassList();
    getCompanyData();
  }, [user]);

  useEffect(() => {
    if (itemList.length !== 0) {
      setRawData((prev: any) => ({
        ...prev,
        device: itemList,
      }));
    }
  }, [itemList, user]);

  useEffect(() => {
    if (rawData.kind !== "" || rawData.kind !== undefined) {
      classList?.map((item: any) => {
        if (item.idx === rawData.kind) {
          setRawData((prev: any) => ({
            ...prev,
            kind_nm: item.nm_kor,
          }));
        }
        return null;
      });
    }
  }, [rawData.kind, classList]);
  // Log defines
  //=======================================================================
  // console.log("rawData", rawData);
  // console.log(classList);
  // console.log(itemList);
  // console.log(itemListData);
  // console.log("prodList", prodList);
  // console.log("state", location.state);
  // console.log("idx", idx);
  // console.log("CompanyData", companyData);
  // console.log("user", user.type);
  // console.log("refId", refId.current?.value);
  // console.log(CPNData);

  return (
    <>
      <Page
        language={language}
        dataList={dataList}
        title={`${language === "KOR" ? "단말기 관리" : "Gadget management"}`}
        menu="/Adm/dev-table"
      >
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <th className="required">
                {translate("회사/지점/대리점명", "Company/Branch/Agency name")}
              </th>
              <td
                colSpan={3}
                style={{
                  position: "relative",
                }}
              >
                <div className="flex">
                  <div className="custom-input wide">
                    <input
                      name="org_nm"
                      type="text"
                      placeholder={translate(
                        "회사/지점/대리점명을 입력해주세요",
                        "enter com/branch/agent name"
                      )}
                      onChange={onChangeFilter}
                      onFocus={(e) => editCPN(e)}
                      readOnly={isReadOnly}
                      value={CPN || ""}
                      autoComplete="off"
                    />
                  </div>
                  <button
                    type="button"
                    className="common-btn white sm"
                    onClick={() => clearinput()}
                  >
                    <span>{translate("초기화", "Reset")}</span>
                  </button>
                </div>
                {isChanged && CPNData.length > 0 ? (
                  <ul
                    className="search-list"
                    style={{
                      position: "absolute",
                      backgroundColor: "white",
                      zIndex: 100,
                      width: "80%",
                      marginTop: "5px",
                      maxHeight: "200px",
                      overflow: "scroll",
                      border: "1px solid #cccc",
                      overflowX: "hidden",
                    }}
                  >
                    {CPNData.map((item: any, index: any) => (
                      <li
                        style={liStyle}
                        key={index}
                        onClick={() => {
                          setCPN(
                            `${
                              item.org_type === "C"
                                ? translate("[회사]", "[Company]")
                                : item.org_type === "B"
                                ? translate("[지점]", "[Branch]")
                                : item.org_type === "A"
                                ? translate("[대리점]", "[Agent]")
                                : ""
                            } ${item.cmp_nm}${
                              item.brc_nm === null ? "" : " > "
                            }${item.brc_nm === null ? "" : item.brc_nm}${
                              item.agt_nm === null ? "" : " > "
                            }${item.agt_nm === null ? "" : item.agt_nm
                            } (회사코드 : ${item.org_cd})`
                          );
                          setRawData((prev: any) => ({
                            ...prev,
                            org_type: item.org_type,
                            btoc_yn: item.btoc_yn,
                            org_idx: item.idx,
                            agt_idx: item.agt_idx,
                            brc_idx: item.brc_idx,
                            cmp_idx: item.cmp_idx,
                            u_type: item.org_type,
                            sso_yn: item.sso_yn === "Y" ? "Y" : "N",
                            org_nm: item.cmp_nm,
                          }));
                          setIsChanged(false);
                          setIsReadOnly(true);
                        }}
                      >
                        {item.org_type === "C"
                          ? translate("[회사]", "[Company]")
                          : item.org_type === "B"
                          ? translate("[지점]", "[Branch]")
                          : item.org_type === "A"
                          ? translate("[대리점]", "[Agent]")
                          : ""}
                        {` ${item.cmp_nm}`}
                        {item.brc_nm === null ? "" : ` > ${item.brc_nm}`}
                        {item.agt_nm === null ? "" : ` > ${item.agt_nm}`}
                        {` (회사코드 : ${item.org_cd})`}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </td>
            </tr>
            <tr>
              <th className="required">
                {translate("단말기 종류", "Device type")}
              </th>
              <td>
                <Select
                  name="prod_legal_nm"
                  changeFun={(e: any) => inputHandler(e)}
                  id=""
                  short="N"
                  options={prodListVar.length > 0 ? prodListVar : []}
                  def={
                    rawData.prod_legal_nm === "" ? "---" : rawData.prod_legal_nm
                  }
                />
              </td>
              <th className="required">
                {translate("미관제 설정 시간", "Confirmation set time")}
              </th>
              <td>
                <Select
                  name="no_view"
                  changeFun={(e: any) => inputHandler(e)}
                  id="no_view"
                  short="N"
                  options={[
                    { name: "--", value: null },
                    { name: "24", value: 24 },
                    { name: "48", value: 48 },
                    { name: "72", value: 72 },
                  ]}
                  def={rawData.no_view === null ? "--" : rawData.no_view}
                />
              </td>
            </tr>
            <tr>
              <th>{translate("통신망 제조사", "Network")}</th>
              <td>
                <Select
                  name="make_cp"
                  changeFun={(e) => inputHandler(e)}
                  id="make_cp"
                  short="N"
                  options={[
                    { name: "---", value: "---" },
                    { name: "KT", value: "KT" },
                    { name: "SKT", value: "SKT" },
                    { name: "LG", value: "LG" },
                  ]}
                  def={rawData.make_cp}
                />
              </td>
              <th>{translate("약정", "Contracts")}</th>
              <td>
                <Select
                  name="term"
                  changeFun={(e) => inputHandler(e)}
                  id="term"
                  short="N"
                  options={[
                    { name: "---", value: "---" },
                    { name: "이벤트", value: "이벤트" },
                    { name: "무약정", value: "무약정" },
                    { name: "1년", value: "1년" },
                    { name: "2년", value: "2년" },
                    { name: "3년", value: "3년" },
                  ]}
                  def={rawData.term}
                />
              </td>
            </tr>
            <tr>
              <th>{translate("아이콘 설정", "Icon Settings")}</th>
              <td colSpan={3}>
                <div className="custom-check-group">
                  <Radio
                    name={"icon_code"}
                    val={"IC01"}
                    title={translate("차량", "Car")}
                    change={(e) => inputHandler(e)}
                    check={rawData.icon_code === "IC01" ? true : false}
                  />
                  <Radio
                    name={"icon_code"}
                    val={"IC02"}
                    title={translate("오토바이", "Bike")}
                    change={(e) => inputHandler(e)}
                    check={rawData.icon_code === "IC02" ? true : false}
                  />
                  <Radio
                    name={"icon_code"}
                    val={"IC03"}
                    title={translate("사람", "Human")}
                    change={(e) => inputHandler(e)}
                    check={rawData.icon_code === "IC03" ? true : false}
                  />
                  <Radio
                    name={"icon_code"}
                    val={"IC04"}
                    title={translate("자전거", "Bicycle")}
                    change={(e) => inputHandler(e)}
                    check={rawData.icon_code === "IC04" ? true : false}
                  />
                  <Radio
                    name={"icon_code"}
                    val={"IC05"}
                    title={translate("트럭", "Track")}
                    change={(e) => inputHandler(e)}
                    check={rawData.icon_code === "IC05" ? true : false}
                  />
                  <Radio
                    name={"icon_code"}
                    val={"IC06"}
                    title={translate("폐기물차량", "Trash car")}
                    change={(e) => inputHandler(e)}
                    check={rawData.icon_code === "IC06" ? true : false}
                  />
                  <Radio
                    name={"icon_code"}
                    val={"IC07"}
                    title={translate("통학버스", "School bus")}
                    change={(e) => inputHandler(e)}
                    check={rawData.icon_code === "IC07" ? true : false}
                  />
                  <Radio
                    name={"icon_code"}
                    val={"IC08"}
                    title={translate("항공", "Plane")}
                    change={(e) => inputHandler(e)}
                    check={rawData.icon_code === "IC08" ? true : false}
                  />
                  <Radio
                    name={"icon_code"}
                    val={"IC09"}
                    title={translate("선박", "Ship")}
                    change={(e) => inputHandler(e)}
                    check={rawData.icon_code === "IC09" ? true : false}
                  />
                  <Radio
                    name={"icon_code"}
                    val={"IC10"}
                    title={translate("크레인", "Crane")}
                    change={(e) => inputHandler(e)}
                    check={rawData.icon_code === "IC10" ? true : false}
                  />
                  <Radio
                    name={"icon_code"}
                    val={"IC11"}
                    title={translate("반려동물", "Pet")}
                    change={(e) => inputHandler(e)}
                    check={rawData.icon_code === "IC11" ? true : false}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>{translate("판매처", "Sales shop")}</th>
              <td>
                <div className="custom-input">
                  <input
                    name="buy"
                    type="text"
                    onChange={inputHandler}
                    value={rawData.buy === undefined ? "" : rawData.buy}
                  />
                </div>
              </td>
              <th>{translate("단말기 분류", "Terminal classification")}</th>
              <td>
                <Select
                  name="kind"
                  changeFun={(e) => inputHandler(e)}
                  id="kind"
                  short="N"
                  options={
                    classList
                      ? classList.map((item: any, index: any) => {
                          return {
                            name: `${translate(item.nm_kor, item.nm_eng)}`,
                            value: parseInt(item.idx),
                          };
                        })
                      : []
                  }
                  def={rawData.kind}
                />
              </td>
            </tr>
            <tr>
              <th>{translate("특이", "Unusual")}</th>
              <td>
                <Select
                  name="matter"
                  changeFun={(e) => inputHandler(e)}
                  id="matter"
                  short="N"
                  options={[
                    { name: "---", value: "---" },
                    { name: "정상", value: "정상" },
                    { name: "차량고장", value: "차량고장" },
                    { name: "점검", value: "점검" },
                    { name: "미운행", value: "미운행" },
                  ]}
                  def={rawData.matter}
                />
              </td>
              <th>{translate("사용여부", "Use")}</th>
              <td>
                <div className="custom-check-group">
                  <Radio
                    name={"act_yn"}
                    val={"Y"}
                    title={translate("사용", "Use")}
                    change={(e) => inputHandler(e)}
                    check={rawData.act_yn === "Y" ? true : false}
                  />
                  <Radio
                    name={"act_yn"}
                    val={"N"}
                    title={translate("미사용", "Not use")}
                    change={(e) => inputHandler(e)}
                    check={rawData.act_yn === "N" ? true : false}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          className="sub-page-header"
          style={{
            marginTop: "2rem",
          }}
        >
          <div className="header-title">단말기 추가</div>
        </div>
        <table className="common-view-table">
          <colgroup>
            <col style={{ width: "19rem" }} />
            <col />
            <col style={{ width: "19rem" }} />
            <col />
          </colgroup>
          <tbody>
            {itemList.map((item: any, index: any) => (
              <tr key={index}>
                <th className="required">단말기 번호</th>
                <td>
                  <div className="custom-input">
                    <input
                      name={`unitid`}
                      type="text"
                      maxLength={11}
                      onChange={(e) =>
                        inputHandlerList(
                          item.idx,
                          e.target.name,
                          e.target.value
                        )
                      }
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      value={item[`unitid`] === undefined ? "" : item[`unitid`]}
                    />
                  </div>
                </td>
                <th className="required">S/N 번호</th>
                <td>
                  <div className="custom-input">
                    <input
                      name={`unit_sn`}
                      type="text"
                      id={`unit_nm`}
                      onChange={(e) =>
                        inputHandlerListSN(
                          item.idx,
                          e.target.name,
                          e.target.id,
                          e.target.value
                        )
                      }
                      value={
                        item[`unit_sn`] === undefined ? "" : item[`unit_sn`]
                      }
                    />
                  </div>
                </td>
                <th className="ruquired">단말기명칭</th>
                <td>
                  <div className="custom-input">
                    <input
                      name={`unit_sn`}
                      id={`unit_nm`}
                      type="text"
                      onChange={(e) =>
                        inputHandlerListSN(
                          item.idx,
                          e.target.name,
                          e.target.id,
                          e.target.value
                        )
                      }
                      value={
                        item[`unit_sn`] === undefined ? "" : item[`unit_sn`]
                      }
                      readOnly={true}
                    />
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      type="button"
                      className="common-btn white sm"
                      onClick={() => {
                        deleteItem(item.idx);
                      }}
                    >
                      <span>{translate("삭제", "Delete")}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <CommonControl buttonData={addButton} language={language} />
        <CommonControl buttonData={buttonData} language={language} />
      </Page>
    </>
  );
}
