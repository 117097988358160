import React from "react";
import Title from "../Atom/Title";
import Value from "../Atom/Value";

interface Props {
  title: string;
  value: string;
  sub?: string;
}
export default function Info({ title, value, sub }: Props) {
  return (
    <>
      <div className="das-info">
        <Title title={title} sub={sub} />
        <Value value={value} />
      </div>
    </>
  );
}
