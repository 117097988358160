import React, { useEffect } from "react";

import Page from "../Atomic/template/PageTable";
import Card from "./Molecule/Card";
import "./dash.sass";
// import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
//@ts-ignore
import axios from "../../lib/axios.js";
//@ts-ignore
import { event } from "../../lib/eventData";
//@ts-ignore
import { add_hyphen } from "../../lib/common_func";
import Modal from "./Organism/Modal";
import moment from "moment";
// @ts-ignore
import FullBTN from "../map/buttons/FullBtn";
import Swal from "sweetalert2";
export const dataList = [];
interface Props {
  language: string;
  data?: any;
  isLoading?: boolean;
  user: any;
  count?: any;
}
export default function DasMain({
  language,
  data,
  isLoading,
  user,
  count,
}: Props) {
  const [rawData, setRawData] = React.useState<any>([]);
  const [isLoadingData, setIsLoadingData] = React.useState<boolean>(true);
  const [series, setSeries] = React.useState<any>([]);
  const [options, setOptions] = React.useState<any>([]);
  const [sortedData, setSortedData] = React.useState<any>([]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [modalData, setModalData] = React.useState<any>([]);
  const [columns, setColumns] = React.useState<any>([]);
  const [title, setTitle] = React.useState<string>("");
  const [VoltageModal, setVoltageModal] = React.useState<boolean>(false);

  const [Volatage, setVolatage] = React.useState<any>([]);
  const [VolatageCard, setVolatageCard] = React.useState<any>([]);

  const state = {
    options: {
      chart: {
        id: "basic-bar",
        height: 80,
        width: "100%",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          horizontal: false,
          // columnWidth: "40%",
          // borderRadiusApplication: "end", // Change to "round" or "ellipse" based on your preference
        },
      },
      xaxis: {
        categories: options,
      },
    },
    series: [
      {
        name: "운행거리",
        data: series,
      },
    ],
  };
  const radar = {
    series: [count?.drive, count?.pause, count?.end],
    options: {
      chart: {
        // type: "pie",
      },
      labels: [
        `주행 ${count?.drive}`,
        `정차 ${count?.pause}`,
        `종료 ${count?.end}`,
      ],
      stroke: {
        colors: ["#fff"],
      },
      fill: {
        opacity: 0.8,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  function toggleModal() {
    setIsOpen(!isOpen);
  }
  async function getData(kind: string) {
    axios
      .post(`/web/dashboard/data/detail`, {
        type: user.type,
        org_idx: user.org_idx,
        kind: kind,
      })
      .then((res: any) => {
        setModalData(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
    await toggleModal();
  }

  function traslate(kor: string, eng: string) {
    if (language === "KOR") {
      return kor;
    } else {
      return eng;
    }
  }
  function onClickFun(path: string, columns: any) {
    getData(path);
    setColumns(columns);
  }

  function sendConfig() {
    Swal.fire({
      title: "설정을 저장하시겠습니까?",
      showCancelButton: true,
      confirmButtonText: "확인",
      cancelButtonText: "취소",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `/web/dashboard/config/edit`,
            {
              org_idx: user.org_idx,
              vehicle_battery_12: highVoltage(Volatage.vehicle_battery_12),
              vehicle_battery_24: highVoltage(Volatage.vehicle_battery_24),
              inner_battery: highVoltage(Volatage.inner_battery),
              speed: Volatage.speed,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res: any) => {
            Swal.fire({
              title: "저장되었습니다.",
              icon: "success",
              confirmButtonText: "확인",
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    });
  }

  function lowVoltage(e: any) {
    return e / 10;
  }
  function highVoltage(e: any) {
    return e * 10;
  }
  useEffect(() => {
    if (Array.isArray(rawData.weekDist)) {
      const sorted = [...rawData.weekDist].sort(
        (b, a) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      setSortedData(sorted);
    }
  }, [rawData.weekDist]);
  useEffect(() => {
    if (sortedData) {
      const weekDist = sortedData.map((item: any) => {
        return item.dist;
      });
      setSeries(weekDist);
      const weekDate = sortedData.map((item: any) => {
        return item.date;
      });
      setOptions(weekDate);
    }
  }, [sortedData]);
  useEffect(() => {
    if (data) {
      setRawData(data);
      return;
    }
  }, [data]);
  useEffect(() => {
    axios
      .get(`/web/dashboard/config?org_idx=${user.org_idx}`)
      .then((res: any) => {
        setVolatage({
          vehicle_battery_12: lowVoltage(res.data.vehicle_battery_12),
          vehicle_battery_24: lowVoltage(res.data.vehicle_battery_24),
          inner_battery: lowVoltage(res.data.inner_battery),
          speed: res.data.speed,
        });
        setVolatageCard({
          vehicle_battery_12: lowVoltage(res.data.vehicle_battery_12),
          vehicle_battery_24: lowVoltage(res.data.vehicle_battery_24),
          inner_battery: lowVoltage(res.data.inner_battery),
          speed: res.data.speed,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [user]);

  const colGps: any = [
    {
      header: language === "KOR" ? "번호" : "Number",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },

    {
      header: language === "KOR" ? "단말번호" : "USIM num",
      name: "unitid",
      align: "center",
      sortable: true,
      formatter: (name: any) => add_hyphen(name.row.unitid),
    },
    {
      header: language === "KOR" ? "단말기명" : "Unit Name",
      name: "unit_nm",
      align: "center",
      sortable: true,
    },
    {
      header: language === "KOR" ? "오늘" : "Today",
      name: "to_dist",
      align: "center",
      sortable: true,
    },
    {
      header: language === "KOR" ? "전일" : "Yesterday",
      name: "ye_dist",
      align: "center",
      sortable: true,
    },
  ];
  const colTime: any = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },

    {
      header: language === "KOR" ? "단말번호" : "USIM Num",
      name: "unitid",
      align: "center",
      sortable: true,
      formatter: (name: any) => add_hyphen(name.row.unitid),
    },
    {
      header: language === "KOR" ? "단말기명" : "Unit Name",
      name: "unit_nm",
      align: "center",
      sortable: true,
    },
    {
      header: language === "KOR" ? "오늘" : "Today",
      name: "to_time",
      align: "center",
      sortable: true,
    },
    {
      header: language === "KOR" ? "전일" : "Yesterday",
      name: "ye_time",
      align: "center",
      sortable: true,
    },
  ];
  const colLost: any = [
    {
      header: language === "KOR" ? "번호" : "Number",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },

    {
      header: language === "KOR" ? "단말번호" : "USIM Num",
      name: "unitid",
      align: "center",
      sortable: true,
      formatter: (name: any) => add_hyphen(name.row.unitid, language),
    },
    {
      header: language === "KOR" ? "단말기명" : "Unit Name",
      name: "unit_nm",
      align: "center",
      sortable: true,
    },
    {
      header: language === "KOR" ? "수신일시차이" : "Unreported Time",
      name: "gps_time",
      align: "center",
      sortable: true,
    },
    {
      header: language === "KOR" ? "최종수신일시" : "Last Received",
      name: "last_makedate",
      align: "center",
      sortable: true,
    },
  ];
  const colInnerB: any = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },

    {
      header: language === "KOR" ? "단말번호" : "USIM Num",
      name: "unitid",
      align: "center",
      sortable: true,
      formatter: (name: any) => add_hyphen(name.row.unitid),
    },
    {
      header: language === "KOR" ? "단말기명" : "Unit Name",
      name: "unit_nm",
      align: "center",
      sortable: true,
    },
    {
      header: language === "KOR" ? "배터리 전압" : "Battery Voltage",
      name: "inner_battery",
      align: "center",
      sortable: true,
      formatter: (name: any) => name.row.inner_battery / 10 + "V",
    },
    // {
    //   header: language === "KOR" ? "전일" : "Yesterday",
    //   name: "last_makedate",
    //   align: "center",
    //   sortable: true,
    // },
  ];
  const colCarB: any = [
    {
      header: language === "KOR" ? "번호" : "Number",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },

    {
      header: language === "KOR" ? "단말번호" : "USIM Num",
      name: "unitid",
      align: "center",
      sortable: true,
      formatter: (name: any) => add_hyphen(name.row.unitid),
    },
    {
      header: language === "KOR" ? "단말기명" : "Unit Name",
      name: "unit_nm",
      align: "center",
      sortable: true,
    },
    {
      header: language === "KOR" ? "배터리 전압" : "Battery Voltage",
      name: "vehicle_battery",
      align: "center",
      sortable: true,
      formatter: (name: any) => name.row.vehicle_battery / 10 + "V",
    },
    // {
    //   header: language === "KOR" ? "YesteYesterdayrday" : "",
    //   name: "last_makedate",
    //   align: "center",
    //   sortable: true,
    // },
  ];
  const colEvent: any = [
    {
      header: language === "KOR" ? "번호" : "Num",
      name: "rowKey" + 1,
      width: 70,
      align: "center",
      formatter: (name: any) => name.row.rowKey + 1,
    },

    {
      header: language === "KOR" ? "단말번호" : "USIM Num",
      name: "unitid",
      align: "center",
      sortable: true,
      formatter: (name: any) => add_hyphen(name.row.unitid),
    },
    {
      header: language === "KOR" ? "이벤트" : "Event",
      name: "event_code",
      align: "center",
      sortable: true,
      formatter: (
        name: any //console.log(event_code),
      ) => event(name.row.event_code, language),
    },
    {
      header: language === "KOR" ? "발생시각" : "Date",
      name: "makedate_cmbd",
      align: "center",
      sortable: true,
      formatter: (name: any) =>
        moment.utc(name.row.makedate_cmbd).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  return (
    <>
      <Page
        menu="/Das"
        title={language === "KOR" ? "대시보드" : "Dashboard"}
        language={language}
        dataList={dataList}
      >
        {!isLoading && rawData.length !== 0 ? (
          <>            
            <div className="das-row">
              <div className="col-8">
                <div className="card-chart">
                  <div className="das-chart-title">
                    <span>{`${traslate(
                      "전체 차량 상태",
                      "Total Device"
                    )}`}</span>
                  </div>
                  <Chart
                    options={radar.options}
                    series={radar.series}
                    // type="bar"
                    width="600"
                    height="200"
                    type="pie"
                  />
                </div>
              </div>
              <div className="col-4">
                <Card
                  icon="icon_all"
                  title={`${traslate("전체 차량 수", "Total Device")}`}
                  value={`${count.all} ${traslate("대", "unit")}`}
                  sub=""
                />
                <Card
                  icon="icon_un"
                  title={`${traslate("미관제 차량 수", "Lost Connect")}`}
                  value={`${rawData.longTerm} ${traslate("대", "unit")}`}
                  sub=""
                  onClick={() => {
                    onClickFun(`LT`, colLost);
                    setTitle(traslate("미관제 차량 수", "Lost Connect"));
                  }}
                />
              </div>
              <div className="col-4">
                <Card
                  icon="gps"
                  title={`${traslate("당일 총 운행거리", "Daily distance")}`}
                  value={`${rawData.totalDist.to_dist}km`}
                  sub={`${traslate("전일", "Yesterday")} ${
                    rawData.totalDist.ye_dist
                  }km`}
                  onClick={() => {
                    onClickFun(`TD`, colGps);
                    setTitle(
                      traslate(
                        "당일 총 운행거리(km)",
                        "Total distance of the day(km)"
                      )
                    );
                  }}
                />
                <Card
                  icon="clock"
                  title={`${traslate("당일 총 운행시간", "Total time")}`}
                  value={`${rawData.totalTime.to_time} ${traslate(
                    "분",
                    "min"
                  )}`}
                  sub={`${traslate("전일", "Yesterday")} ${
                    rawData.totalTime.ye_time
                  } ${traslate("분", "min")}
              `}
                  onClick={() => {
                    onClickFun(`TT`, colTime);
                    setTitle(
                      traslate("당일 총 운행시간(분)", "Total time(min)")
                    );
                  }}
                />
              </div>
            </div>
            <div className="das-row">
              <div className="col-8">
                <div className="card-chart">
                  <div className="das-chart-title">
                    <span>{`${traslate(
                      "일별 운행거리 (일주일)",
                      "Driving Distance"
                    )}`}</span>
                  </div>
                  <Chart
                    options={state.options}
                    series={state.series}
                    type="bar"
                    width="600"
                    height="200"
                  />
                </div>
              </div>
              <div className="col-4">
                <Card
                  icon="4battery"
                  title={`${traslate("단말기 충전 필요", "Charging Alert")}`}
                  value={`${rawData.innerBattery} ${traslate("대", "unit")}`}
                  sub={`${traslate(
                    `단말기 전압 ${VolatageCard.inner_battery}v 이하`,
                    `Voltage ${VolatageCard.inner_battery}v or less`
                  )}`}
                  onClick={() => {
                    onClickFun(`IB`, colInnerB);
                    setTitle(traslate("단말기 충전 필요", "Charging required"));
                  }}
                />
                <Card
                  icon="car_battery"
                  title={`${traslate("배터리 점검 필요", "Check required")}`}
                  value={`${rawData.vehicleBattery} ${traslate("대", "unit")}`}
                  sub={`${traslate(
                    `차량 배터리 전압 ${VolatageCard.vehicle_battery_12}v / ${VolatageCard.vehicle_battery_24}v 이하`,
                    `Voltage ${VolatageCard.vehicle_battery_12}v / ${VolatageCard.vehicle_battery_24}v or less`
                  )}`}
                  onClick={() => {
                    onClickFun(`VB`, colCarB);
                    setTitle(
                      traslate("배터리 점검 필요", "Battery check required")
                    );
                  }}
                />
              </div>
              <div className="col-4">
                <Card
                  icon="event"
                  title={`${traslate("이벤트 발생", "Event occurred")}`}
                  value={`${rawData.eventCount} ${traslate("건", "unit")}`}
                  sub=""
                  onClick={() => {
                    onClickFun(`EV`, colEvent);
                    setTitle(traslate("이벤트 발생", "Event occurred"));
                  }}
                />
                <Card
                  icon="speeding"
                  title={`${traslate("과속", "Over Speed")}`}
                  value={`${rawData.speedy} ${traslate("대", "unit")}`}
                  sub={`${traslate(
                    `속도 ${VolatageCard.speed}km/h 초과`,
                    `Speed ${VolatageCard.speed}km/h or over`
                  )}`}
                />
              </div>
            </div>
            <div
              className="map-control"
              style={{ gap: "4px", transform: "translateX(0%)", position: "static", justifyContent: 'flex-end' }}
            >
              <div className="control-group">
                <button
                  type="button"
                  className="control-btn refresh"
                  onClick={() => window.location.reload()}
                >
                  <i className="ri-refresh-line icon"></i>
                </button>
              </div>
              <div className="control-group map">
                {/* <FullBTN /> */}
                <button
                  onClick={() => {
                    setVoltageModal(!VoltageModal);
                  }}
                  type="button"
                  className="control-btn street"
                >
                  <div className="label">{`${traslate(
                    "항목설정",
                    "Dashboard Settings"
                  )}`}</div>
                </button>
              </div>
            </div>
          </>
        ) : (
          <div>loading</div>
        )}
      </Page>
      {isOpen ? (
        <Modal
          columns={columns}
          title={title}
          onClick={toggleModal}
          data={modalData}
        />
      ) : (
        ""
      )}
      {VoltageModal ? (
        <div className="common-modal on">
          <div
            className="modal-inner"
            style={{
              minHeight: "370px",
            }}
          >
            <div className="modal-page">
              <div className="page-header">
                <div className="page-title">
                  <span>{traslate("항목설정", "Item Settings")}</span>
                </div>
                <button
                  onClick={() => setVoltageModal(!VoltageModal)}
                  type="button"
                  className="modal-close"
                >
                  <i className="ri-close-fill icon"></i>
                </button>
              </div>
              <div className="page-body">
                <div className="flex-container">
                  <div className="common-table-wrapper">
                    <div className="v-block">
                      <div className="v-title">
                        <p className="v-text">
                          {traslate(
                            "최저 단말기 배터리 전압",
                            "Minimum terminal battery voltage"
                          )}
                        </p>
                        <span>
                          {traslate(
                            "※ 무선형 단말기 전압 3.3V 시 방전, 전원을 끄고 충전하시기 바랍니다.",
                            "※ When the wireless terminal voltage is 3.3V, please discharge, turn off the power and charge it."
                          )}
                        </span>
                      </div>
                      <div className="v-input">
                        <input
                          value={Volatage.inner_battery}
                          type="range"
                          min={3.3}
                          max={3.9}
                          step={0.1}
                          onChange={(e) => {
                            setVolatage({
                              ...Volatage,
                              inner_battery: Number(e.target.value),
                            });
                          }}
                        />
                        <span>{Volatage.inner_battery} V</span>
                      </div>
                    </div>
                    <div className="v-block">
                      <div className="v-title">
                        <p className="v-text">
                          {traslate(
                            "최저 차량 배터리 전압 12V",
                            "Minimum vehicle battery voltage 12v"
                          )}
                        </p>
                      </div>
                      <div className="v-input">
                        <input
                          value={Volatage.vehicle_battery_12}
                          type="range"
                          min={10.8}
                          max={13.8}
                          step={0.1}
                          onChange={(e) => {
                            setVolatage({
                              ...Volatage,
                              vehicle_battery_12: Number(e.target.value),
                            });
                          }}
                        />
                        <span>{Volatage.vehicle_battery_12} V</span>
                      </div>
                    </div>
                    <div className="v-block">
                      <div className="v-title">
                        <p className="v-text">
                          {traslate(
                            "최저 차량 배터리 전압 24V",
                            "Minimum vehicle battery voltage 24v"
                          )}
                        </p>
                      </div>
                      <div className="v-input">
                        <input
                          value={Volatage.vehicle_battery_24}
                          type="range"
                          min={22.4}
                          max={24.8}
                          step={0.1}
                          onChange={(e) => {
                            setVolatage({
                              ...Volatage,
                              vehicle_battery_24: Number(e.target.value),
                            });
                          }}
                        />
                        <span>{Volatage.vehicle_battery_24} V</span>
                      </div>
                    </div>
                    <div className="v-block">
                      <div className="v-title">
                        <p className="v-text">
                          {traslate("과속 기준", "Speed Standard")}
                        </p>
                      </div>
                      <div className="v-input">
                        <input
                          value={Volatage.speed}
                          type="range"
                          min={50}
                          max={160}
                          step={1}
                          onChange={(e) => {
                            setVolatage({
                              ...Volatage,
                              speed: Number(e.target.value),
                            });
                          }}
                        />
                        <span>{Volatage.speed} Km/h</span>
                      </div>
                    </div>

                    <button
                      className="v-button"
                      onClick={() => {
                        sendConfig();
                      }}
                    >
                      {traslate("설정 저장", "Save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}