import React from 'react';
import Outer from './Outer';

export default function Wireless() {
    window.scrollTo({top:0});
    return (
        <>
            <Outer type={'wireless'}>
                <div className="content-part">
                    {/* <!-- nav bar --> */}
                    <aside className="nav-container">
                        <div className="nav-lists">
                            <h3 className="nav-title">무선형 FAQ</h3>
                            <ul>
                                <li>
                                    <a href="#wireless" className="nav-link">
                                        무선형 제품 안내
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer1" className="nav-link">
                                        위치가 변경되지 않을 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer2" className="nav-link">
                                        각 LED의 의미
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer3" className="nav-link">
                                        A/S 택배 보내는 주소
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer4" className="nav-link">
                                        관제가 안 보일 때(이전)
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer5" className="nav-link">
                                        단말기는 정상, 관제가 이상할 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer6" className="nav-link">
                                        유심을 교체할 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer7" className="nav-link">
                                        단말기 전원이 안 들어올 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer8" className="nav-link">
                                        GPS 위치가 다를 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer9" className="nav-link">
                                        km 단위로 차이가 날 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer10" className="nav-link">
                                        장기 미관제 알림이 뜰 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer11" className="nav-link">
                                        운행 기록 보관 기간
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer12" className="nav-link">
                                        충전이 되지 않을 때
                                    </a>
                                </li>
                                <li>
                                    <a href="#answer13" className="nav-link">
                                        무선형 설치 방법 및 위치
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    {/* <!-- content --> */}
                    <section className="main-container">
                        {/* <!-- 문의 0 --> */}
                        <article id="wireless">
                            <div className="article-title">
                                무선형 제품 안내
                            </div>
                            <div className="article-img">
                                <img src="../../assets/img/Faq/device_bat_front_led.png" alt="iotplex 4guard gps wireless model front image" className="img-50"/>
                                <img src="../../assets/img/Faq/device_bat_back_gps.png" alt="iotplex 4guard gps wireless model back image" className="img-50"/>
                            </div>
                            <div className="article-point">
                                <p className="red-700">
                                    유심 커넥터, 유심
                                </p>
                                <p className="normal-500">
                                    유심 카드를 통해 LTE 통신을 하고 이를 이용하여 SMS 신호를 보내 원격으로 제어가 가능합니다.
                                </p>
                                <p className="blue-700">
                                    전원 스위치
                                </p>
                                <p className="normal-500">
                                    단말기 전원을 제어합니다.
                                </p>
                                <p className="green-700">
                                    LED
                                </p>
                                <p className="normal-500">
                                    3개의 LED가 있습니다. 각 동작 상태를 나타냅니다.
                                </p>
                                <p className="blue-700">
                                    전원 커넥터
                                </p>
                                <p className="normal-500">
                                    전원 커넥터를 통해 차량에서 전원을 공급받아 단말기를 동작시킵니다.
                                </p>
                                <p className="red-700">
                                    USB 5핀
                                </p>
                                <p className="normal-500">
                                    충전 시 사용합니다.
                                </p>
                                <p className="green-700">
                                    GPS 안테나
                                </p>
                                <p className="normal-500">
                                    GPS 위성 신호를 받는 GPS 안테나입니다. GPS 안테나가 차량 바깥쪽을 보게 해야 GPS 성능이 올라갑니다.
                                </p>
                            </div>
                            <div className="article-img">
                                <img src="../../assets/img/Faq/device_bat_bottom.png" alt="iotplex 4guard gps wireless model" className="img-50"/>
                                <img src="../../assets/img/Faq/device_bat_box_open.png" alt="iotplex 4guard gps wireless model box open image" className="img-50"/>
                            </div>
                        </article>
                        {/* <!-- 문의 1 --> */}
                        <article id="answer1">
                            <div className="article-title">
                                Q1. 위치가 변경되지 않을 때
                            </div>
                            <div className="h2-bold">
                                통신료 미납으로 인한 정지인지 확인 후, 미납이 아닐 때
                            </div>
                            <div className="article-img">
                                <img src="../../assets/img/Faq/device-bat-gps.png" alt="iotplex 4guard gps wireless" className="img-50"/>
                                <img src="../../assets/img/Faq/device-bat-gps-log.png" alt="iotplex 4guard gps wireless" className="img-50"/>
                            </div>
                            <p className="article-bold">
                                1. 날짜가 일치하는 경우
                            </p>
                            <div className="article-txt">
                                <p>GPS 안테나 위치를 확인합니다.</p>
                                <p>위성을 통해 GPS 신호를 수신 받으므로 GPS 안테나가 차량 바깥쪽을 향하도록 단말기를 설치해야 합니다.</p>
                            </div>
                            <p className="article-bold">
                                2. 날짜가 일치하지 않는 경우
                            </p>
                            <div className="article-txt">
                                <p>단말기 LED 동작 상태를 확인합니다.</p>
                                <p>날짜가 일치하지 않는 경우 통신 문제일 확률이 높으며, LED 중간의 노란불이 계속 깜빡이거나
                                불이 안 들어오는 경우 통신이 되지 않아 데이터를 확인하지 못하는 경우입니다.</p>
                            </div>
                            <p className="article-bold">
                                3. 통신 모뎀에 이상이 있는 경우
                            </p>
                            <div className="article-txt">
                                <p>단말기 내부 통신 모뎀에 문제가 생겨 통신이 안되는 상황입니다.</p>
                            </div>
                            <p className="article-bold">
                                4. 유심이 휘어진 경우
                            </p>
                            <div className="article-txt">
                                <p>유심 금속면이 단말기 바닥면에 접촉되어야 통신이 가능하나, 유심이 아치 모양으로 휘어져 바닥면에 접촉이 되지 않아 통신이 불가한 경우입니다.</p>
                            </div>                 
                            <div className="article-point">
                                <p className="normal-500">유심이 휘어졌을 경우 유심을 발송하여 교체하는 방법과 단말기를 보내셔서 점검과 함께 유심을 교체하는 방법 2가지로 나뉩니다. 통신 모뎀에 이상이 있을 경우 무조건 A/S를 보내 통신 모뎀 교체가 필요합니다.</p>
                                <div className="article-point-img">
                                    <figure>
                                        <img src="../../assets/img/Faq/device-bat-sim-broken.png" alt="iotplex 4guard gps usim image"/>
                                        <figcaption>유심이 휘어진 경우</figcaption>
                                    </figure>
                                    <figure>
                                        <img src="../../assets/img/Faq/device-bat-sim-ok.png" alt="iotplex 4guard gps usim image"/>
                                        <figcaption>유심이 정상인 경우</figcaption>
                                    </figure>
                                </div>
                                <p className="normal-500">유심이 휘어진 경우 접촉이 불안정해져 통신이 되지 않거나 통신이 되더라도 연결이 끊어졌다 되었다 할 수 있습니다.</p>
                            </div>
                            <p className="article-bold">
                                💎유심 교체 방법
                            </p>
                            <div className="article-point">
                                <p className="blue-700">1) 공유심(정보를 인식하기 전 유심)을 고객님께 발송, 원격으로 개통 및 정보 인식</p>
                                <p className="normal-500">🔔 특징 : 공유심을 받기 전까지 기존에 사용하던 유심을 이어서 사용하므로 관제에서 운행 기록을 지속적으로 확인할 수 있습니다. 다만 기존 유심에 문제가 있을 경우 통신이 끊어질 수 있어 큰 효과는 없습니다.</p>
                                <p className="normal-500">🔔 방법 : 전화로 주소를 불러주시면 해당 주소로 공유심을 보내드리며, 공유심 수령 후 단말기에 유심을 꽂아 전원을 켜고 전화주시면 원격으로 개통 처리가 가능하며 원격 개통은 약 10분 정도 소요됩니다.</p>
                                <p className="blue-700">2) 유심 자체를 변경한 후 고객님께 발송</p>
                                <p className="normal-500">🔔 특징 : 유심을 아예 변경한 후 발송, 수령 후 단말기에 유심을 꽂기 전까지는 운행 기록을 확인할 수 없습니다. 유심 교 체 후 테스트를 진행하여 보내드리므로 단말기 위치가 부산(본사 위치)으로 표시됩니다.</p>
                            </div>
                        </article>
                        <article id="answer2">
                            <div className="article-title">Q2. 각 LED의 의미</div>
                            <div className="article-img">
                                <img src="../../assets/img/Faq/device-bat-led.png" alt="iotplex 4guard gps wireless model led image" className="img-70"/>
                            </div>
                            <div className="article-txt">
                                <p>정상적이라면 2개의 LED 깜빡임이 멈추고 20초 가량이 지나면 LED 불이 모두 꺼집니다. </p>
                                <p>LED 불이 꺼지지 않고 계속 깜빡이는 경우 LED가 켜진 부분에서 이상이 있는 경우입니다.</p>
                                <p>약 90초 간 진동이 느껴지지 않을 경우 단말기는 슬립 모드로 들어가며 다시 진동을 감지하게 되면 동작합니다.</p>
                            </div>
                            <div className="article-point">
                                <p className="red-700">PWR : 빨간 불</p>
                                <p className="normal-500">충전 상태를 확인하실 수 있습니다. 충전 중에는 빨간 불이 깜빡이며, 충전이 다 되면 깜빡임이 멈춥니다.</p>
                                <p className="green-700">MODEM : 초록 불</p>
                                <div className="normal-500">
                                    <p>LTE 통신 상태를 나타냅니다.</p>
                                    <p>깜빡이는 경우 서버로 데이터 전송이 되지 않아 운행 기록 확인이 불가한 경우입니다.</p>
                                </div>
                                <p className="blue-700">GPS : 파란 불</p>
                                <p className="normal-500">GPS 상태를 나타냅니다. 깜빡이는 경우 GPS와 연결이 되지 않은 상태입니다.</p>
                            </div>
                        </article>
                        <article id="answer3">
                            <div className="article-title">
                                Q3. A/S 택배 보내는 주소
                            </div>
                            <div className="h2-bold">회사 위치 및 주소</div>
                            <div className="article-img">
                                <img src="../../assets/img/Faq/map.png" alt="iotplex 4guard company map image" className="img-70"/>
                            </div>
                            <div className="article-point">
                                <p className="normal-500">📫 주소 : 부산광역시 해운대구 센텀동로 99 벽산E센텀클래스원 601호 아이오티플렉스</p>
                                <p className="normal-500">🔗 수신인 : 아이오티플렉스</p>
                                <p className="normal-500">📞 연락처 : 010-2581-3333</p>
                            </div>
                        </article>
                        <article id="answer4">
                            <div className="article-title">
                                Q4. 관제가 안 보일 때(이전)
                            </div>
                            <div className="article-img">
                                {/* <img src="../../assets/img/Faq/dash_old.png" alt="iotplex 4guard gps old site image" className="img-50"/> */}
                                <img src="../../assets/img/Faq/dash_new.png" alt="iotplex 4guard gps new site image" />
                            </div>    
                            <div className="article-txt">
                                <p>단말기에 원격으로 신호를 보내서 신규 관제로 변경이 가능합니다.</p>
                                <p>원격으로 신호를 보낼 때는 반드시 단말기 전원이 켜져 있어야 하며, 간혹 오래된 단말기는 인식이 불가한 경우가 있습니다. 그럴 때는 제품을 보내주시면 제품 점검 및 관제 변경이 가능합니다.</p>
                            </div>
                        </article>
                        <article id="answer5">
                            <div className="article-title">
                                Q5. 단말기는 정상, 관제가 이상할 때
                            </div>
                            <div className="article-txt">
                                <p>택배 주소를 잘못 입력하면 택배가 엉뚱한 곳에 가는 것처럼, 단말기도 데이터를 엉뚱한 곳으로 보내면 단말기는 정상이더라도 운행 내역이 확인되지 않는 경우가 있습니다. 이러한 경우 원격으로 신호를 보내 정상적으로 사용할 수 있습니다. 만약 원격 신호를 보냈음에도 상태 변화가 없다면 제품을 보내셔서 A/S 및 점검을 받으시기 바랍니다.</p>
                            </div>
                        </article>
                        
                        <article id="answer6">
                            <div className="article-title">
                                Q6. 유심을 교체할 때
                            </div>
                            <div className="article-img">
                                <figure>
                                    <img src="../../assets/img/Faq/usim-take-out.png" alt="iotplex 4guard gps usim introduce" className="img-70"/>
                                    <figcaption>눌러서 딸깍 하고 빼는 형식이 아닙니다.</figcaption>
                                </figure>
                            </div>
                            <div className="article-point">
                                <div className="normal-500">
                                    <p>🔔 유심을 꽂으실 때는 홈이 파인 부분이 삽입부 왼쪽을 향하게 넣어야 하며, 금속칩이 하단을 향하게 꽂아야 정상적으로 인식됩니다. 유심을 너무 강하게 꽂으실 경우 유심이 안쪽으로 들어가 인식이 안되는 것은 물론 내부 보드가 손상되어 유상 수리가 발생할 수 있습니다.</p>
                                </div>
                            </div>
                            <div className="article-img">
                                <img src="../../assets/img/Faq/usim-put-in-1.png" alt="iotplex 4guard gps usim image" className="img-50"/>
                                <img src="../../assets/img/Faq/usim-put-in-2.png" alt="iotplex 4guard gps usim image" className="img-50"/>
                            </div>
                            <div className="article-img">
                                <img src="../../assets/img/Faq/usim-put-in-3.png" alt="iotplex 4guard gps usim image" className="img-50"/>
                                <img src="../../assets/img/Faq/usim-put-in-4.png" alt="iotplex 4guard gps usim image" className="img-50"/>
                            </div>
                        </article>
                        <article id="answer7">
                            <div className="article-title">
                                Q7. 단말기 전원이 안 들어올 때
                            </div>
                            <div className="h2-bold">
                                전원이 켜져있는 지 확인
                            </div>
                            <div className="article-img">
                                <img src="../../assets/img/Faq/device-bat-switch.png" alt="iotplex 4guard gps image" className="img-70"/>
                            </div>
                            <div className="article-point">
                                <p className="normal-500">🔔 스위치 왼쪽 : 꺼짐(off)</p>
                                <p className="normal-500">🔔 스위치 오른쪽 : 켜짐(on)</p>
                                <p className="normal-700">스위치가 켜져 있는데도 반응이 없다면 왼쪽/오른쪽을 번갈아 움직여서 껐다가 켜주시면 됩니다.</p>
                            </div>
                        </article>
                        
                        <article id="answer8">
                            <div className="article-title">
                                Q8. GPS 위치가 다를 때
                            </div>
                            <div className="article-txt">
                                GPS 신호는 원래 오차가 조금씩 발생하므로 위치 차이가 발생할 수 있습니다. 단말기 기본 신호 주기는 1분으로 되어 있어 GPS를 잡고 신호를 보내고 1분 이내에 아파트 단지 또는 지하주차장 등 GPS 음영 지역으로 이동할 경우 마지막으로 신호를 받았던 위치를 보여주기 때문에 실제 마지막 위치와 차이가 날 수 있습니다.
                            </div>
                            <div className="article-img">
                                <img src="../../assets/img/Faq/map-11.png" alt="iotplex 4guard gps image" className="img-30"/>
                            </div>
                            <div className="article-point">
                                <p className="normal-500">위의 사진처럼 마지막으로 GPS가 잡혔던 위치가 사거리에 있고, 실제 위치는 지하주차장인 경우 관제 상에서는 다른 위치처럼 보입니다.</p>
                            </div>
                        </article>
                        <article id="answer9">
                            <div className="article-title">
                                Q9. km 단위로 차이가 날 때
                            </div>
                            <div className="article-txt">
                                GPS 신호를 보내는 주기는 기본 1분이므로 실제 운행 거리와 다소 차이가 발생할 수 있습니다. 중간에 통신이나 GPS가 잠시 끊겼다가 연결되는 경우 그 사이 거리를 일직선으로 연결하여 거리를 적산합니다.
                            </div>
                            <div className="article-point-img">
                                <figure>
                                    <img src="../../assets/img/Faq/map-gps-red.png" alt="iotplex 4guard gps image"/>
                                    <figcaption>일직선으로 적산</figcaption>
                                </figure>
                                <figure>
                                    <img src="../../assets/img/Faq/map-red-2.png" alt="iotplex 4guard gps image"/>
                                    <figcaption>10km 이하인 경우 적산</figcaption>
                                </figure>   
                            </div>
                            <div className="article-txt">
                                운행을 시작한 후 GPS 신호를 잡는 데 까지 보통 약 2~3분 정도 소요됩니다. 만약 GPS 신호를 잡지 못한 채 운행 시 마지막으로 GPS 신호를 잡았던 위치에서 현재 연결된 위치로 일직선으로 연결되는데, 이 때 거리가 10km 이하라면 적산 되고, 10km 초과인 경우 적산이 되지 않습니다. 이로 인해 실제 운행 km와 관제 상 km 차이가 발생할 수 있습니다.                    
                            </div>
                        </article>
                        <article id="answer10">
                            <div className="article-title">
                                Q10. 장기 미관제 알림이 뜰 때
                            </div>
                            <div className="article-img">
                                <img src="../../assets/img/Faq/gps-alert.png" alt="iotplex 4guard gps image" className="img-70"/>
                            </div>
                            <div className="article-txt">
                                3개월 간 운행 기록이 없으면 장기 미관제 차량으로 표시됩니다. 다시 정상적으로 운행할 경우 장기 미관제 팝업은 사라집니다. 오랜 기간 사용하지 않으셨다면 제품을 A/S 보내서 점검 받은 후 사용하시길 권장합니다.
                            </div>
                        </article>
                        <article id="answer11">
                            <div className="article-title">
                                Q11. 운행 기록 보관 기간
                            </div>
                            <div className="article-txt">
                                <p>운행 기록 데이터는 3개월까지 확인할 수 있습니다.</p>
                                <p>3개월 이후에는 데이터가 삭제되니 그 전에 필요한 기록을 확인하시기 바랍니다.</p>
                            </div>
                        </article>
                        <article id="answer12">
                            <div className="article-title">
                                Q12. 충전이 되지 않을 때
                            </div>
                            <div className="article-txt">
                                충전기와 단말기를 연결했을 때 충전기에 빨간 불/녹색 불이 뜨지 않으면 충전기나 단말기의 충전 단자에 문제가 생겼을 수 있습니다. 이런 경우 충전기와 단말기를 함께 A/S 보내주시면 조치 가능합니다. 될 수 있으면 전원을 끈 상태에서 충전해야 빠르게 충전할 수 있습니다.
                            </div>
                            <div className="article-point-img">
                                <figure>
                                    <img src="../../assets/img/Faq/device-bat-ch-off.png" alt="iotplex 4guard gps image"/>
                                    <figcaption>배터리가 없으면 빨간 불</figcaption>
                                </figure>
                                <figure>
                                    <img src="../../assets/img/Faq/device-bat-ch-on.png" alt="iotplex 4guard gps image"/>
                                    <figcaption>충전 완료 시 녹색 불</figcaption>
                                </figure>   
                            </div>
                            <div className="article-point">
                                <p className="normal-500">일반 충전기로 충전하는 경우 단말기 전원을 켜서 단말기에 빨간 LED가 나오는지 확인하면 됩니다.</p>
                                <p className="normal-500">빨간 LED가 깜빡이는 경우 충전 중, 빨간 LED가 깜빡이지 않으면 충전이 완료되었음을 뜻합니다.</p>
                            </div>
                        </article>
                        <article id="answer13" style={{paddingBottom:'10em'}}>
                            <div className="article-title">
                                Q13. 무선형 설치 방법 및 위치
                            </div>
                            <div className="h2-bold">
                                트렁크 양 사이드에 GPS 안테나가 바깥쪽으로 향하게 하여 부착
                            </div>
                            <div className="article-img">
                                <img src="../../assets/img/Faq/car.png" alt="iotplex 4guard gps image" className="img-70"/>
                            </div>
                            <div className="article-point">
                                <p className="normal-500">차량 차체 아래에 부착하는 경우 방수 작업 후 부착해야 합니다. 방수 작업 없이 부착 후 단말기에 물이 들어가면 고장의 원인이 되며 유상 A/S 진행됩니다.</p>
                            </div>
                            <div className="article-bold">
                                📌 GPS 안테나 바깥쪽을 향하게 하여 부착하는 방법
                            </div>
                            <div className="article-point-img">
                                <figure>
                                    <img src="../../assets/img/Faq/device-bat-gps-up.png" alt="iotplex 4guard gps image"/>
                                    <figcaption>하늘을 향하도록 부착하는 것이 필수</figcaption>
                                </figure>
                                <figure>
                                    <img src="../../assets/img/Faq/device-bat-gps-down.png" alt="iotplex 4guard gps image"/>
                                    <figcaption>지면을 향할 경우 감도 하락</figcaption>
                                </figure>   
                            </div>
                        </article>
                    </section>
                </div>
            </Outer>
        </>
    );
}

