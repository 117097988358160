import React from 'react';
import { useLang } from '../../context/LanguageContext';

export default function Loading({ container = '3rem', spinner = '50px' }) {
    const { language } = useLang();

    return (
        <div className='m-loading' style={{padding:`${container} 0`}}>
            <img src="/assets/img/common/loading-spinner.svg" alt="loading" style={{width: spinner}}/>
            <span className='text'>
                {language === "KOR" ? "데이터를 로딩중입니다." : "Loading Data."}
            </span>
        </div>
    );
}