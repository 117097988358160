import React from "react";
import axios from "../../../lib/axios.js";
import Swal from "sweetalert2";
import { LanguageContext } from "../../../context/LanguageContext";
import { useEffect } from "react";
import { useAuth } from "../../../context/LoginContext";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { trimType } from "../../../lib/common_func";
import { MapProvider } from "../../../context/MapContext.jsx";
//Atoms
import Header from "../../include/AppHeader";
import Footer from "../../include/AppFooter";
import Nav from "../../Atomic/organism/Nav";
import Form from "../../Atomic/organism/FormAtom";
import CommonControl from "../../Atomic/organism/CommonControl";
import Table from "../../Atomic/organism/AtomTable.jsx";
import Modal from "./GeoFenceAdd.jsx";
import ModalEdit from "./GeoFenceEdit.jsx";
import { dataList } from "./TerminalMain";
export default function GeoFence() {
  const { language } = React.useContext(LanguageContext);
  const navi = useNavigate();
  const inputRef = React.createRef();
  const user = useAuth();
  // State definition
  //==================================================================
  const [rawData, setRawData] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState();
  const [data, setData] = React.useState([]);
  const [keyword, setKeyword] = React.useState("");
  const [modal, setModal] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);

  // Function definition
  //==================================================================
  async function submitFun(e) {
    e.preventDefault();
    await getData();
  }
  async function getData() {
    await axios
      .get(
        `/web/region/list?type=${user.user.type}&org_idx=${user.user.org_idx}`
      )
      .then((res) => {
        setRawData(res.data);
      });
  }
  function clearInput() {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    setKeyword("");
  }
  function inputChnage(key) {
    setKeyword(key);
    filterData(rawData, key);
  }
  function openClose() {
    setModal(!modal);
  }
  function openCloseEdit() {
    setModalEdit(!modalEdit);
  }

  const filterData = useCallback(
    (data, key) => {
      const filterCallback = () => {
        const result = data.filter((item) => {
          return item.region_nm.includes(key);
        });
        setData(result);
      };

      filterCallback();
    },
    [setData]
  );

  async function deleteFun(idx, zone_id) {
    axios
      .post(`/web/region/del`, {
        idx: idx,
        user: user,
        zone_id: zone_id,
      })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            title: language === "KOR" ? "삭제되었습니다." : "Deleted",
            confirmButtonText: language === "KOR" ? "확인" : "OK",
            width: "400px",
            padding: "20px",
            heightAuto: false,
            customClass: {
              confirmButton: "submit-btn",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      });
  }
  const move = (e) => {
    const i = e.instance.store.focus.rowIndex;
    if (i !== null) {
      const idx = trimType(e.instance.store.data.rawData[i].idx);
      if (e.columnName === "btn" && e.targetType != "columnHeader") {
        openCloseEdit();
        setSelectedData(e.instance.store.data.rawData[i].idx);
      }
      if (e.columnName === "viewList" && e.targetType != "columnHeader") {
        navi(`/Trm/GeoFence/List`, {
          state: {
            unitid: idx,
          },
        });
      }
      if (e.columnName === "view" && e.targetType != "columnHeader") {
        navi(`/Trm/GeoFence/ListDevice`, {
          state: {
            unitid: idx,
          },
        });
      }
      if (e.columnName === "delete" && e.targetType != "columnHeader") {
        Swal.fire({
          title: language === "KOR" ? "삭제하시겠습니까?" : "Are you sure?",
          text:
            language === "KOR"
              ? "삭제된 데이터는 복구할 수 없습니다."
              : "Deleted data cannot be recovered.",

          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
        }).then((result) => {
          if (result.isConfirmed) {
            deleteFun(
              e.instance.store.data.rawData[i].idx,
              e.instance.store.data.rawData[i].zone_id
            );
          }
        });
      }
    }
  };
  // UseEffect definition
  //==================================================================
  useEffect(() => {
    axios
      .get(
        `/web/region/list?type=${user.user.type}&org_idx=${user.user.org_idx}`
      )
      .then((res) => {
        setRawData(res.data);
      });
  }, [user.user.type, user.user.org_idx]);

  useEffect(() => {
    if (rawData && keyword === "") {
      setData(rawData);
    } else if (rawData && keyword !== "") {
      filterData(rawData, keyword);
    }
  }, [rawData, keyword, filterData]);
  // Data definition

  //==================================================================

  const formData = {
    lan: language,
    inputId: "keyword",
    type: "text",
    placeKR: "검색어를 입력하세요",
    placeEN: "Please enter a search term",
    submitHandler: submitFun,
    filter: (e) => inputChnage(e),
    myref: inputRef,
    //option: (),
    value: keyword,
  };
  const buttonData = [
    {
      labelKR: "전체보기",
      labelEN: "View all",
      classN: "purple",
      // clickFun: clearInput(inputRef, () => {
      //   setKeyword("");
      // }),
      clickFun: () => {
        clearInput();
      },
      position: "left",
      icon: false,
      iconClass: "",
    },
    {
      labelKR: "등록",
      labelEN: "Registration",
      classN: "purple",
      icon: true,
      clickFun: () => {
        openClose();
        setSelectedData("");
      },
      position: "right",
    },
  ];
  const columns = [
    {
      header: language === "KOR" ? "번호" : "Num", // Updated header for row number column
      name: "rowKey" + 1,
      width: 50, // Adjust the width as needed
      align: "center", // Center-align the row numbers
      formatter: (name) => name.row.rowKey + 1, // Pass the row number
    },
    {
      header: language === "KOR" ? "지역명" : "Region name",
      name: "region_nm",
      sortable: true,
      resizeable: true,
    },
    {
      header: language === "KOR" ? "반경" : "Radius",
      name: "radius",
      sortable: true,
      resizeable: true,
      width: 100,
      formatter: (name) => name.row.radius + "m",
    },
    {
      header: language === "KOR" ? "주소" : "Address",
      name: "addr",
      sortable: true,
      resizeable: true,
      width: 400,
    },
    {
      header: language === "KOR" ? "진입/이탈 설정" : "In/Out setting",
      name: "in_out_type",
      align: "center",
      sortable: true,
      resizeable: true,
      width: 150,
      formatter: (name) => {
        if (name.row.in_out_type === "B") {
          return language === "KOR" ? "진입/이탈 설정" : "Entry/Exit";
        }
        if (name.row.in_out_type === "E") {
          return language === "KOR" ? "진입 설정" : "Entry Settings";
        }
        if (name.row.in_out_type === "D") {
          return language === "KOR" ? "이탈 설정" : "Leave Settings";
        }
        if (name.row.in_out_type === "N") {
          return language === "KOR" ? "설정 해제" : "Unsetting";
        }
      },
    },
    {
      header: language === "KOR" ? "단말연결" : "Terminal access",
      name: "view",
      align: "center",
      width: 110,
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "연결" : "Connect"
        }</div>`,
    },
    {
      header: language === "KOR" ? "연결목록" : "View List",
      name: "viewList",
      align: "center",
      width: 110,
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "조회" : "View"
        }</div>`,
    },
    {
      header: language === "KOR" ? "수정" : "Edit",
      name: "btn",
      align: "center",
      width: 110,
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "수정" : "Edit"
        }</div>`,
    },
    {
      header: language === "KOR" ? "삭제" : "Delete",
      name: "delete",
      align: "center",
      width: 110,
      formatter: () =>
        `<div class="link cursor">${
          language === "KOR" ? "삭제" : "Delete"
        }</div>`,
    },
  ];
  // Log definition
  //==================================================================

  return (
    <>
      <Header />
      <div className="app-body">
        <div className="app-page run">
          <div className="sub-page-wrapper">
            <Nav
              menu={"/Trm/GeoFence"}
              dataList={dataList}
              language={language}
            />
            <div className="sub-page">
              <div className="sub-page-header">
                <div className="header-title">
                  {language === "KOR" ? "지역설정" : "Geofence"}
                </div>
              </div>
              <div className="sub-page-body">
                <Form formData={formData} inputRef={inputRef} />
                <Table
                  language={language}
                  data={data}
                  columns={columns}
                  move={move}
                />
                <CommonControl buttonData={buttonData} language={language} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {modal && (
        <MapProvider>
          <Modal
            openClose={openClose}
            language={language}
            rawData={rawData}
            user={user}
          />
        </MapProvider>
      )}
      {modalEdit && (
        <MapProvider>
          <ModalEdit
            openClose={openCloseEdit}
            language={language}
            rawData={rawData}
            selectedData={selectedData}
            user={user}
          />
        </MapProvider>
      )}
    </>
  );
}
