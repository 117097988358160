import React, { ReactNode } from "react";
import CustomInput from "../molecules/CustomInput";
interface Props {
  language: string;
  id: string;
  type: string;
  option: ReactNode;
  placeholderKR: string;
  placeholderEN: string;
  submitHandler: (e: any) => void;
  onChange: (e: any) => void;
  filter: () => void;
  inputRef: React.RefObject<HTMLInputElement>;
  value?: string;
}
export default function Form({
  language,
  id,
  type,
  placeholderKR,
  placeholderEN,
  option,
  submitHandler,
  onChange,
  filter,
  inputRef,
  value
}: Props) {
  return (
    <>
      <form className="search-bar" onSubmit={submitHandler}>
        <div className="custom-group">{option}</div>
        <CustomInput
          id={id}
          type={type}
          placeHolder={language === "KOR" ? placeholderKR : placeholderEN}
          changeFun={(e) => {
            onChange(e);
          }}
          inputRef={inputRef}
          value={value}
        />
        <button type="submit" className="submit-btn" onClick={filter}>
          {language === "KOR" ? "검색" : "Search"}
        </button>
      </form>
    </>
  );
}
